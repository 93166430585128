import { Reducer } from 'redux';

import { GlobalSettingsActions, KnownAction } from './Actions';

export interface IGlobalSettingsState {
    minMappingDate?: Date;
}

const defaultState: IGlobalSettingsState = {
}

export const reducer: Reducer<IGlobalSettingsState, KnownAction> = (state: IGlobalSettingsState | undefined, action: KnownAction): IGlobalSettingsState => {
    let newState: IGlobalSettingsState | undefined = undefined;

    switch (action.type) {
        case GlobalSettingsActions.InitializeMinMappingDate: 
            newState = {
                ...(state ? state : defaultState),
            }
            newState.minMappingDate = action.minMappingDate;
            break;
    }

    if (newState) {
        return newState;
    } else if (state) {
        return state;
    } else {
        let defaultCopy: IGlobalSettingsState = {
            ...defaultState,
        };
        return defaultCopy;
    }
}