import { RiskCalcResponse } from '../Models/Api/strongbox.financialportal';

import { FetchError, FetchMethod, fetchWrapper } from '../Utils/FetchWrapper';

import { LogException, LogMessage, SeverityLevel } from '../Utils/Logging';

export async function GetRiskScore(
    entityId: string,
    financialRecordId: string | undefined = undefined,
    naicsCode: string | undefined = undefined,
    postalCode: string | undefined = undefined
): Promise<RiskCalcResponse> {
    LogMessage(
        `GetRiskScore`,
        SeverityLevel.Information,
        {
            workspaceId: entityId,
            financialRecordId,
            naicsCode,
            postalCode
        }
    );
    let url = `/api/riskcalc/${entityId}`;

    const queryParams: { key: string, value: string }[] = [];

    if (financialRecordId) {
        queryParams.push({
            key: 'financialRecordId',
            value: financialRecordId,
        });
    }

    if (naicsCode) {
        queryParams.push({
            key: 'naicsCode',
            value: naicsCode,
        });
    }

    if (postalCode) {
        queryParams.push({
            key: 'postalCode',
            value: postalCode,
        });
    }

    if (queryParams.length > 0) {
        url += '?';
        // map doesn't work because it puts a , between each element it returns for some unhelpful reason.
        queryParams.forEach((kvp, index) => {
            url += `${kvp.key}=${kvp.value}`;
            if (index < queryParams.length - 1) {
                url += '&';
            }
        });
    }

    try {
        const res = await fetchWrapper(url,
            {
                method: FetchMethod.Get,
            },
            true,
            true
        );

        if (!res.ok) {
            const msg = 'Unable to generate financial score for business. Please try again later';

            LogException(
                `GetRiskScore failed`,
                new FetchError(msg, msg, res.status),
                {
                    workspaceId: entityId,
                    financialRecordId,
                    naicsCode,
                    postalCode
                }
            );

            throw new FetchError(msg, msg, res.status);
        }

        const result: RiskCalcResponse = await res.json();
        return result;
    } catch (exception) {
        LogException(
            `GetRiskScore failed`,
            exception,
            {
                workspaceId: entityId,
                financialRecordId,
                naicsCode,
                postalCode
            }
        );
        throw exception;
    }
}
