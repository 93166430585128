import * as React from 'react';
import { connect } from 'react-redux';

import '../Main.scss';

import {
    Col,
    Container,
    Label,
    Row,
} from 'reactstrap'

import { Metadata, Submission, SubmissionRequestSummary } from '../../Models/Api/strongbox.financialportal';
import { PortalSubmissionRequestFromSubmissionRequestSummary } from '../../Models/PortalCreateSubmissionRequestOptions';
import { knownAccountingGroupIds } from '../../Models/AccountingCollectionGroup';

import { ApplicationState } from '../../Store';
import { GetUserById, LoadingUsers } from '../../Store/User';
import {
    GetDisableAdditionalDocUpload,
    GetDisableProvideuserCopy,
    GetDisableAnonymizeResults,
    GetDisableBasisOfAccounting,
    GetDefaultSubmissionOptions,
} from '../../Store/AppSettings';

import { ConnectionSourceText } from '../../Utils/Constants';
import { SubmissionMetaBorrowerBusinessEmail, SubmissionMetaBorrowerBusinessName } from '../../Utils/Constants';
import { AccountingPackageFriendlyNameFromStringDescriptor } from '../../Utils/LinkUtils';
import { GetBasisOfAccountingDescription, GetCollectionRangeSummary, GetTypicalAccountingPkgImportDuration } from '../../Utils/GlobalMessageUtils';

import { CheckedSummaryItem } from './CheckedSummaryItem';

import {
    PortalCreateSubmissionRequestOptions
} from '../../Models/PortalCreateSubmissionRequestOptions';

type InjectedReduxState = {
    loadingUsers: boolean;
    userDisplayName?: string;
    fileUploadDisabled: boolean;
    provideUserCopyDisabled: boolean;
    anonymizeResultsDisabled: boolean;
    basisOfAccountingDisabled: boolean;
    defaultSubmissionOptions: PortalCreateSubmissionRequestOptions
};

type InjectedActionCreators = {};

type RequestSummaryProps = {
    className?: string;
    requestSummary: SubmissionRequestSummary;
    loadedUserDisplayName?: string;
    submission?: Submission;
    submissionMetadata?: Metadata[];
    showTypicalImportTime?: boolean;
};

type Props = RequestSummaryProps & InjectedReduxState & InjectedReduxState;

export const RequestSummaryComponent: React.FC<Props> = (props): React.ReactElement => {
    const {
        anonymizeResultsDisabled,
        basisOfAccountingDisabled,
        className,
        fileUploadDisabled,
        loadingUsers,
        provideUserCopyDisabled,
        showTypicalImportTime,
        requestSummary,
        submission,
        userDisplayName,
        defaultSubmissionOptions,
    } = props;

    const loadingUsersContent = (): React.ReactElement => {
        return (
            <Row className={'header-content'}>
                <Col>
                    <h3>{`Loading...`}</h3>
                </Col>
            </Row>
        )
    }

    const collectingPeriodText = (): string | undefined => {
        let result = undefined;

        if ((requestSummary.mostRecentPeriodMonth !== undefined) &&
            (requestSummary.mostRecentPeriodYear !== undefined)) {
            const rs = PortalSubmissionRequestFromSubmissionRequestSummary(requestSummary);
            result = GetCollectionRangeSummary(rs);
        }
        return result;
    }

    const basisOfAccounting = React.useMemo(() => {
        if (!!(requestSummary)) {
            return GetBasisOfAccountingDescription(PortalSubmissionRequestFromSubmissionRequestSummary(requestSummary));
        }
        return 'Not available';
    }, [requestSummary]);

    const estimatedTimeContent = React.useMemo(() => {
        if (!(submission && submission.datasourceNameId)) {
            return undefined;
        }
        const estimatedTimeRange = GetTypicalAccountingPkgImportDuration(submission.datasourceNameId);
        if (!estimatedTimeRange) {
            return undefined;
        }

        return `Importing from ${AccountingPackageFriendlyNameFromStringDescriptor(submission.datasourceNameId)} typically takes ${estimatedTimeRange}.`

        // requestSummary is the only dependency I want
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestSummary]);

    const borrowerProvidedMetadata = React.useMemo(() => {
        if ((!props.submissionMetadata) || (props.submissionMetadata.length < 2)) {
            return undefined;
        }

        let businessName: string | undefined = undefined;
        let businessEmail: string | undefined = undefined;

        props.submissionMetadata.forEach(m => {
            if (m.name === SubmissionMetaBorrowerBusinessName) {
                businessName = m.value;
            } else if (m.name === SubmissionMetaBorrowerBusinessEmail) {
                businessEmail = m.value;
            }
        });

        if (!(businessEmail && businessName)) {
            return undefined;
        }

        return (
            <Row style={{ marginBottom: '15px' }}>
                <Col>
                    <Row>
                        <Col>
                            <h1>Borrower provided information:</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{ offset: 1, size: 11 }} md={{ offset: 1, size: 8 }} lg={{ offset: 1, size: 6 }} xl={{ offset: 1, size: 5 }}>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>Business name:</td>
                                        <td>{businessName}</td>
                                    </tr>
                                    <tr>
                                        <td>Business email:</td>
                                        <td>{businessEmail}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }, [props.submissionMetadata]);

    const accountingDataCollected = React.useMemo(() => {
        const result: React.ReactElement[] = [];

        defaultSubmissionOptions.accountingCollectionGroups.forEach(group => {
            let checked = false;

            switch (group.id) {
                case knownAccountingGroupIds.financialStatements:
                    checked = !!requestSummary.financialStatementsYearsToCollect && requestSummary.financialStatementsYearsToCollect > 0
                    break;
                case knownAccountingGroupIds.transactions:
                    checked = !!requestSummary.transactionsYearsToCollect && requestSummary.transactionsYearsToCollect > 0
                    break;
                case knownAccountingGroupIds.accountsPayable:
                    checked = !!requestSummary.payablesYearsToCollect && requestSummary.payablesYearsToCollect > 0
                    break;
                case knownAccountingGroupIds.accountsReceivable:
                    checked = !!requestSummary.receivablesYearsToCollect && requestSummary.receivablesYearsToCollect > 0
                    break;
            }
            result.push((
                <CheckedSummaryItem
                    key={`accounting-data-collection-group-${group.id}`}
                    checked={checked}
                    inputKey={group.id}
                    label={group.name || ''}
                />
            ));
        });

        return result;
    }, [defaultSubmissionOptions, requestSummary])

    const regularContent = (): React.ReactElement => {
        const collectedThrough = collectingPeriodText();

        return (
            <>
                <Row style={{ marginBottom: '0.75em' }} className={'header-content request-summary-row'}>
                    <Col xs={'12'} md={'auto'}>
                        <h1>Originating from: {requestSummary.hasShareableLink ? ConnectionSourceText.SecureUrlText : ConnectionSourceText.DirectConnectionText} </h1>
                    </Col>
                    <Col>
                        <h2><strong>Requested by portal user: </strong>
                            {!!userDisplayName && (
                                <span>{`${userDisplayName}`}</span>
                            )}
                            {!userDisplayName && (
                                <span>No user associated with this submission.</span>
                            )}
                        </h2>
                    </Col>
                </Row>
                {collectedThrough && (
                    <Row className={'header-content requst-summary-row'}>
                        <Col>
                            <h2>{collectedThrough}</h2>
                        </Col>
                    </Row>
                )}
                { borrowerProvidedMetadata }
                <Row>
                    <Col>
                        {!anonymizeResultsDisabled && (
                            <CheckedSummaryItem
                                checked={requestSummary.anonymizeCustomersAndVendors}
                                inputKey={'anonymizeCustomersAndVendors'}
                                label={'Customer and Vendor names are anonymized'}
                            />
                        )}
                        {
                            (requestSummary.hasShareableLink) && (
                                <React.Fragment>
                                    {!fileUploadDisabled && (
                                        <CheckedSummaryItem
                                            checked={requestSummary.allowUserUpload}
                                            inputKey={'allowUserUpload'}
                                            label={'User may upload additional files'}
                                        />
                                    )}
                                    {!provideUserCopyDisabled && (
                                        <CheckedSummaryItem
                                            checked={requestSummary.provideUserCopy}
                                            inputKey={'provideUserCopy'}
                                            label={'User given option to download customer copy of workbook'}
                                        />
                                    )}
                                </React.Fragment>
                            )
                        }
                        {!basisOfAccountingDisabled && (
                            <Label style={{ marginTop: '15px' }}>{`Preferred basis of accounting: ${basisOfAccounting}`}</Label>
                        )}
                    </Col>
                </Row>
                <Row style={{marginTop: '15px'}}>
                    <Col>
                        <Label>Accounting data collected</Label>
                        {
                            accountingDataCollected.map(ad => ad)
                        }
                    </Col>
                </Row>
                {
                    showTypicalImportTime && !!estimatedTimeContent && (
                        <Row
                            style={{
                                marginTop: '10px'
                            }}
                        >
                            <Col>
                                <span>{estimatedTimeContent}</span>
                            </Col>
                        </Row>
                    )
                }
            </>
        )
    }

    return (
        <Container className={`data-container bordered ${className}`} fluid>
            {loadingUsers && loadingUsersContent()}
            {!loadingUsers && regularContent()}
        </Container>
    );
}

export const RequestSummary = connect<InjectedReduxState, InjectedActionCreators, RequestSummaryProps, ApplicationState>(
    (appState: ApplicationState, props: RequestSummaryProps) => {

        let userDisplayName = props.loadedUserDisplayName;
        if (!userDisplayName) {
            if (!!props.requestSummary.requestingUserId) {
                const temp = GetUserById(appState, props.requestSummary.requestingUserId);
                if (!!temp) {
                    userDisplayName = temp.displayName;
                }
            }
        }

        const result = {
            loadingUsers: LoadingUsers(appState),
            userDisplayName: userDisplayName,
            fileUploadDisabled: GetDisableAdditionalDocUpload(appState),
            provideUserCopyDisabled: GetDisableProvideuserCopy(appState),
            anonymizeResultsDisabled: GetDisableAnonymizeResults(appState),
            basisOfAccountingDisabled: GetDisableBasisOfAccounting(appState),
            defaultSubmissionOptions: GetDefaultSubmissionOptions(appState, 'portal', false),
        };

        return result;
    },
)(RequestSummaryComponent);

