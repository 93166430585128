import { store } from '../../index';

import { GetUserRoles } from '../../Services/UserRolesService';

import {
    UserRolesActions,
    ILoadRolesAction,
    ILoadRolesActionComplete,
} from './Actions';

import { RoleResponse } from '../../Models/Api/strongbox.financialportal';

function LoadRoles(): ILoadRolesAction {
    return {
        type: UserRolesActions.LoadRoles,
    };
}

function LoadRolesComplete(roles: RoleResponse[]): ILoadRolesActionComplete {
    return {
        type: UserRolesActions.LoadRolesComplete,
        roles: roles,
    };
}

export async function LoadUserRoles(errMsgHandler: (msg: string) => void): Promise<RoleResponse[]> {
    store.dispatch(LoadRoles());

    const userRoles = await GetUserRoles();

    store.dispatch(LoadRolesComplete(userRoles));

    return userRoles;
}

export const actionCreators = {
    LoadRoles: LoadRoles,
    LoadRolesComplete: LoadRolesComplete,
};
