import * as React from 'react';

import '../../Main.scss';

import {
    Tooltip
} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';

import { ConnectionDescriptor, UserCapability } from '../../../Models/Api/strongbox.financialportal';
import { GetAccountingImageClass, AccountingPackageFriendlyNameFromStringDescriptor } from '../../../Utils/LinkUtils';

import { FinancialsRelinkButton } from '../../FinancialsImport/FinancialsRelinkButton';

import { AccountingPackageFromDatasourceNameId, DatasourceMayDirectLink } from '../../../Models/Api/AccountingPackages';

import { ErrorBanner, ErrorState } from '../../ErrorBanner/ErrorBanner';
import { LoadingMessageWithWaitIndicator } from '../../LoadingMessageWithIndicator/LoadingMessageWithIndicator';

import { DeleteConnectionFromWorkspace } from '../../../Store/Workspaces';
import { RunningImport } from '../../../Store/RunningImports';
import { BrandConfig } from '../../../Store/Tenant';

import { TitleBox } from '../../TitleBox/TitleBox';

import { RunningImportsList } from '../../RunningImportsList/RunningImportsList';

import { ConfirmModal } from '../../ConfirmModal/ConfirmModal';
import { LogMessage, SeverityLevel } from '../../../Utils/Logging';
import { GetStdThemeColors } from '../../../Utils/Style';
import { FileUploadDataSourceNameId } from '../../../Utils/Constants';

type Props = {
    connections: ConnectionDescriptor[];
    capabilities: UserCapability[];
    workspaceId: string;
    workspaceDisplayName: string;
    runningImports: RunningImport[]
    onRunningJobDismiss?: (financialRecordId: string, workspaceId: string, fromNav: boolean) => void;
    brandConfig: BrandConfig;
}

export const WorkspaceConnections: React.FC<Props> = (props): React.ReactElement => {
    const {
        brandConfig,
        capabilities,
        connections,
        runningImports,
        workspaceId,
        workspaceDisplayName,
        onRunningJobDismiss
    } = props;

    const [working, setWorking] = React.useState<boolean>(false);
    const [errorState, setErrorState] = React.useState<ErrorState | undefined>(undefined);

    const [confirmDelete, setConfirmDelete] = React.useState<{ cxnId: string, cxnDescription: string } | undefined>(undefined);

    const connectionsInUse = React.useMemo(() => {
        return connections.filter(c => c.datasourceNameId !== FileUploadDataSourceNameId);
    }, [connections]);

    const actionButtonStyle: React.CSSProperties = {
        padding: 8,
        marginRight: 10,
    };

    const getActions = (connection: ConnectionDescriptor, connectionIndex: number): React.ReactElement[] => {
        const actions: React.ReactElement[] = [];
        const stdColors = GetStdThemeColors(brandConfig);

        if (capabilities.some(c => c === 'CanDeleteWorkspaceConnection')) {
            actions.push((
                <Tooltip
                    key={`delete-connection-tip-${connectionIndex}`}
                    title={'Delete connection'}
                >
                    <span>
                        <button
                            key={`delete-connection-${connectionIndex}`}
                            type="button"
                            className={'secondary-button'}
                            style={{
                                ...actionButtonStyle
                            }}
                            onClick={() => {
                                setConfirmDelete({
                                    cxnId: connection.id,
                                    cxnDescription: connection.datasetName || 'Unknown'
                                });
                            }}
                            disabled={working}
                        >
                            <DeleteIcon style={{ backgroundColor: stdColors.secondaryColor, margin: 'auto' }} />
                        </button>
                    </span>
                </Tooltip>

            ));
        }

        const accountingPkg = AccountingPackageFromDatasourceNameId(connection.datasourceNameId);
        const supportsDirect = DatasourceMayDirectLink(connection.datasourceNameId);

        if (!!accountingPkg) {
            actions.push((
                <div key={`financials-relink-button-${connectionIndex}` }>
                    <FinancialsRelinkButton
                        connection={connection}
                        datasourceNameId={connection.datasourceNameId}
                        disabled={working || !supportsDirect}
                        onSetErrorState={setErrorState}
                        onSetImporting={setWorking}
                        workspaceId={workspaceId}
                        workspaceDisplayName={workspaceDisplayName}
                    />
                </div>
            ))
        } else {
            LogMessage(
                `Cannot get accounting package from datasourceNameId in WorkspaceConnections ${connection.datasourceNameId}`,
                SeverityLevel.Warning,
                {
                    datasourceNameId: connection.datasourceNameId,
                    workspaceId,
                    connectionId: connection.id,
                }
            );
        }

        return actions;
    }
    // render
    const noConnections = (
        <p>
            No connections
        </p>
    );

    const jobIsRunning = (): boolean => {
        return !!runningImports.find(job => job.workspaceId === workspaceId);
    }

    const connectionsList = connectionsInUse && (connectionsInUse.length > 0) && (
        <div className={`workspace-details-container workspace-list`}>
            {working && (
                <LoadingMessageWithWaitIndicator
                    msg={'Working...'}
                />
            )}
            <ErrorBanner
                errorState={errorState}
                onDefaultActionButton={() => {
                    setErrorState(undefined);
                }}
            />
            {
                jobIsRunning() && (
                    <div>
                        <TitleBox
                            title={'Pending data collections'}
                            containerStyle={{ marginBottom: '3em' }}
                        >
                            <RunningImportsList
                                scopeWorkspaceId={workspaceId}
                                hideWorkspaceInfo={true}
                                onJobDismiss={onRunningJobDismiss}
                            />
                        </TitleBox>
                    </div>
                )
            }
            {
                !!confirmDelete && (
                    <ConfirmModal
                        onTerminalButton={(yes?: boolean): void => {
                            if (!!yes) {
                                DeleteConnectionFromWorkspace(workspaceId, confirmDelete.cxnId)
                                    .then(success => {
                                        if (!success) {
                                            setErrorState({
                                                summaryMessage: 'There was an error deleting the connection. The connection is still active'
                                            });
                                        }
                                    })
                                    .finally(() => {
                                        setWorking(false);
                                    });
                                setWorking(true);
                                }
                            setConfirmDelete(undefined);
                        }}
                        msg={`Please confirm that you would like to delete connection ${confirmDelete.cxnDescription}.`}
                        title={`Delete connection`}
                    />
                )
            }
            <table style={{ width: '100%', marginTop: '25px' }}>
                <thead>
                    <tr className={ 'column-headers-unbordered' } key={'43434343'}>
                        <th>Company name</th>
                        <th>Accounting System</th>
                        <th style={{ textAlign: 'center' }}>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        connectionsInUse.map((connection, connectionIndex) => {
                            return (
                                <tr className={'normal-rows'} key={connection.id}>
                                    <td>{connection.datasetName}</td>
                                    <td>
                                        <div className={'accounting-image-container'}>
                                            <span className={GetAccountingImageClass(connection.datasourceNameId)} />
                                            {AccountingPackageFriendlyNameFromStringDescriptor(connection.datasourceNameId)}
                                        </div>
                                    </td>
                                    <td>
                                        <div style={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}>
                                            {getActions(connection, connectionIndex)}
                                        </div>
                                    </td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>
        </div>
    );

    return connectionsList || noConnections;
}

