import * as React from 'react';
import { connect } from 'react-redux';

import {
    Col,
    Container,
    Row,
} from 'reactstrap';

import '../Main.scss';

import { ApplicationState } from '../../Store';

import { GroupedFileDropdown } from './GroupedFileDropdown';
import { FileGroupDescription } from '../FileGroup/FileGroupDescription';
import { DownloadableFile, GroupedFiles } from '../../Utils/FileUtils';

import {
    actionCreators as SubmissionHistoryActions,
    DownloadableFileState,
    GetTempSubmissionDetailsDownloads
} from '../../Store/SubmissionHistory';

import {
    SubmissionSearchResult
} from '../../Models/Api/strongbox.financialportal';

type InjectedReduxState = {
    selections: DownloadableFileState[];
};

type InjectedActionCreators = typeof SubmissionHistoryActions;

type GroupedFileContainerWithDownloadProps = {
    files: GroupedFiles;
    fileCount?: number;
    ssr: SubmissionSearchResult;
    onDetailClicked?: (filename: string, fileId: string) => void;
    onDownloadStart?: () => void;
    onDownloadComplete?: () => void;
    closeMeText?: string;
    onCloseMe?: () => void;
}

type Props = GroupedFileContainerWithDownloadProps & InjectedReduxState & InjectedActionCreators;

const GroupedFileContainerWithDownloadComponent: React.FC<Props> = (props): React.ReactElement => {
    const {
        closeMeText,
        onCloseMe,
        files,
        fileCount,
        onDetailClicked,
        onDownloadStart,
        onDownloadComplete,
        selections,
        ssr,
        SetTempSubmissionDetailsFiles,
        ToggleSubmissionDetailsSelectedFile,
    } = props;

    const [fileGroupExpanded, setFileGroupExpanded] = React.useState<boolean>(true);

    const getFileId = (file: DownloadableFile): string => {
        return file.id || file.filename;
    }

    const downloadComplete = (): void => {
        SetTempSubmissionDetailsFiles(ssr.submission.id, selections.map(sel => { return { ...sel, selected: false }}));
        onDownloadComplete && onDownloadComplete();
    }

    const submitFiles = (files: DownloadableFile[], i: number): void => {
        if (i >= files.length) {
            downloadComplete();
            return;
        }

        // Shouldn't have url's undefined here but just in case, don't crash.
        while (!(files[i].url)) {
            i++;
            if (i >= files.length) {
                downloadComplete();
                return;
            }
        }

        var a = document.createElement('a');

        // ts-ignore is okay below, see the while loop above where we skip any url's that are undefined.
        // @ts-ignore
        a.href = files[i].url;
        a.target = '_parent';
        // Use a.download if available, it prevents plugins from opening.
        if ('download' in a) {
            a.download = files[i].filename;
        }
        // Add a to the doc for click to work.
        (document.body || document.documentElement).appendChild(a);
        if (a.click) {
            a.click(); // The click method is supported by most browsers.
        }
        // Delete the temporary link.
        if (a.parentNode) {
            a.parentNode.removeChild(a);
        }

        // Download the next file with a small timeout. The timeout is necessary
        // for IE, which will otherwise only download the first file.
        setTimeout(() => {
            submitFiles(files, i + 1);
        }, 500);
    }

    React.useEffect(() => {
        // Set the temp submission detail files but preserve the selected state if there is
        // already an entry for the file. The last parameter 'true' preserves the selected
        // state.  If the file isn't there, it will be set to the default value provided in
        // the array of files (false)

        SetTempSubmissionDetailsFiles(
            ssr.submission.id,
            files.supportingDocuments.map(file => { return { fileId: getFileId(file), selected: false } }),
            true
        );

        // I want this to run only on the first iteration like componentDidMount so there are
        // no dependencies in the list.
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [])

    const hasSelections = React.useMemo(() => {
        return selections.findIndex(selected => selected.selected) !== -1;
    }, [selections]);

    return (
        <Container fluid>
            <Row>
                <Col>
                    <GroupedFileDropdown
                        hideBorder={true}
                        fileDescriptor={'workbooks'}
                        key={'workbooks'}
                        retrievingFiles={false}
                        noFilesAvailableMsg={''}
                        summaryDescription={(<FileGroupDescription groupedFiles={files} fileCount={fileCount} />)}
                        files={files.supportingDocuments.map((file) => {
                            let selected = false;
                            const index = selections.findIndex(sel => getFileId(file) === sel.fileId);
                            if (index !== -1) {
                                selected = selections[index].selected;
                            }
                            return {
                                file: file,
                                selected: selected,
                                id: index,
                            }
                        })}
                        onSelection={(file, index) => {
                            ToggleSubmissionDetailsSelectedFile(ssr.submission.id, getFileId(file.file))
                            onDetailClicked && onDetailClicked(file.file.filename, file.file.id || '');
                        }}
                        expanded={fileGroupExpanded}
                        onContractSummary={() => setFileGroupExpanded(false)}
                        onExpandSummary={() => setFileGroupExpanded(true)}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className={'right-aligned-col'}>
                        {!!closeMeText && (
                            <button
                                className={'small'}
                                onClick={() => {
                                    onCloseMe && onCloseMe();
                                }}
                                style={{
                                    marginLeft: '15px'
                                }}
                            >
                                {closeMeText}
                            </button>
                        )}
                        <button
                            className={'small'}
                            disabled={!hasSelections}
                            onClick={() => {
                                onDownloadStart && onDownloadStart();
                                submitFiles(
                                    files.supportingDocuments.filter((file, index) => {
                                        const selection = selections.find(sel => getFileId(file) === sel.fileId)
                                        return !!selection && selection.selected;
                                    }),
                                    0
                                );
                            }}
                        >
                            Download Files
                        </button>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export const GroupedFileContainerWithDownload = connect<InjectedReduxState, InjectedActionCreators, GroupedFileContainerWithDownloadProps, ApplicationState>(
    (appState: ApplicationState, props: GroupedFileContainerWithDownloadProps) => {
        return {
            selections: GetTempSubmissionDetailsDownloads(appState, props.ssr.submission.id ),
        };
    },
    {
        ...SubmissionHistoryActions,
    }
)(GroupedFileContainerWithDownloadComponent);
