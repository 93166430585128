import { ConnectionsList, EntityDetailResponse, EntityResponse, UserResponse } from '../../Models/Api/strongbox.financialportal';

import { WorkspaceWithMeta, WorkspacePageInfo, WorkspacePlusUser } from './Reducer';

export enum WorkspacesActions {
    LoadWorkspaces = 'WORKSPACES/LoadWorkspaces',
    LoadWorkspacesComplete = 'WORKSPACES/LoadWorkspacesComplete',
    DeleteWorkspace = 'WORKSPACES/DeleteWorkspace',
    DeleteWorkspaceComplete = 'WORKSPACES/DeleteWorkspaceComplete',
    AddLoadedWorkspace = 'WORKSPACES/AddWorkspace',
    LoadWorkspaceDetails = 'WORKSPACES/LoadWorkspaceDetails',
    LoadWorkspaceDetailsComplete = 'WORKSPACES/LoadWorkspaceDetailsComplete',
    ChangeWorkspaceUserStatus = 'WORKSPACES/ChangeWorkspaceUserStatus',
    ChangeWorkspaceUserStatusComplete = 'WORKSPACES/ChangeWorkspaceUserStatusComplete',
    BulkRemoveAllWorkspaceUsers = 'WORKSPACES/BulkRemoveAllWorkspaceUsers',
    BulkRemoveAllWorkspaceUsersComplete = 'WORKSPACES/BulkRemoveAllWorkspaceUsersComplete',
    BulkAddWorkspaceUsers = 'WORKSPACES/BulkAddWorkspaceUsers',
    BulkAddWorkspaceUsersComplete = 'WORKSPACES/BulkAddWorkspaceUsersComplete',
    SaveWorkspaceUsers = 'WORKSPACES/SaveWorkspaceUsers',
    SaveWorkspaceUsersComplete = 'WORKSPACES/SaveWorkspaceUsersComplete',
    ResetWorkspaceUsers = 'WORKSPACES/ResetWorkspaceUsers',
    UpdateLoadedWorkspaceDetails = 'WORKSPACES/UpdateLoadedWorkspaceDetails',
    DeleteWorkspaceConnection = 'WORKSPACES/DeleteWorkspaceConnection',
    DeleteWorkspaceConnectionComplete = 'WORKSPACES/DeleteWorkspaceConnectionComplete',
    LoadWorkspaceConnections = 'WORKSPACES/LoadConnections',
    LoadWorkspaceConnectionsComplete = 'WORKSPACES/LoadConnectionsComplete',
    OverrideWorkspaceAccessibilityForUser = 'WORKSPACES/OverrideUserAccess',
}

export interface ILoadWorkspaces {
    readonly type: WorkspacesActions.LoadWorkspaces;
}

export interface ILoadWorkspacesComplete {
    readonly type: WorkspacesActions.LoadWorkspacesComplete;
    readonly workspaces: WorkspaceWithMeta[];
    readonly pageInfo: WorkspacePageInfo;
}

export interface IDeleteWorkspace {
    readonly type: WorkspacesActions.DeleteWorkspace;
    readonly workspaceId: string;
}

export interface IDeleteWorkspaceComplete {
    readonly type: WorkspacesActions.DeleteWorkspaceComplete;
    readonly workspaceId: string;
}

export interface IAddLoadedWorkspace {
    readonly type: WorkspacesActions.AddLoadedWorkspace;
    readonly workspace: EntityResponse;
}

export interface ILoadWorkspaceDetails {
    readonly type: WorkspacesActions.LoadWorkspaceDetails;
    readonly id: string;
}

export interface ILoadWorkspaceDetailsComplete {
    readonly type: WorkspacesActions.LoadWorkspaceDetailsComplete;
    readonly id: string;
    readonly userDetails?: WorkspacePlusUser;
    readonly workspaceDetails?: EntityDetailResponse;
}

export interface IChangeWorkspaceUserStatus {
    readonly type: WorkspacesActions.ChangeWorkspaceUserStatus;
    readonly id: string;
    readonly user: UserResponse;
    readonly include: boolean;
}

export interface IChangeWorkspaceUserStatusComplete {
    readonly type: WorkspacesActions.ChangeWorkspaceUserStatusComplete;
    readonly id: string;
    readonly user: UserResponse;
    readonly include: boolean;
}

export interface ISaveWorkspaceUsers {
    readonly type: WorkspacesActions.SaveWorkspaceUsers;
    readonly id: string;
}

export interface ISaveWorkspaceUsersComplete {
    readonly type: WorkspacesActions.SaveWorkspaceUsersComplete;
    readonly id: string;
}

export interface IBulkRemoveAllWorkspaceUsers {
    readonly type: WorkspacesActions.BulkRemoveAllWorkspaceUsers;
    readonly id: string;
    readonly exclude?: UserResponse[];
}

export interface IBulkRemoveAllWorkspaceUsersComplete {
    readonly type: WorkspacesActions.BulkRemoveAllWorkspaceUsersComplete;
    readonly id: string;
    readonly exclude?: UserResponse[];
}

export interface IBulkAddWorkspaceUsers {
    readonly type: WorkspacesActions.BulkAddWorkspaceUsers;
    readonly id: string;
    readonly users: UserResponse[];
}

export interface IBulkAddWorkspaceUsersComplete {
    readonly type: WorkspacesActions.BulkAddWorkspaceUsersComplete;
    readonly id: string;
    readonly users: UserResponse[];
}

export interface IResetWorkspaceUsers {
    readonly type: WorkspacesActions.ResetWorkspaceUsers;
    readonly id: string;
}

export interface IUpdateLoadedWorkspaceDetails {
    readonly type: WorkspacesActions.UpdateLoadedWorkspaceDetails;
    readonly id: string;
    readonly newName: string;
    readonly newEngagementCode?: string;
}

export interface ILoadWorkspaceConnections {
    readonly type: WorkspacesActions.LoadWorkspaceConnections;
    readonly id: string;
}

export interface ILoadWorkspaceConnectionsComplete {
    readonly type: WorkspacesActions.LoadWorkspaceConnectionsComplete;
    readonly id: string;
    readonly connections: ConnectionsList | undefined;
}

export interface IDeleteWorkspaceConnection {
    readonly type: WorkspacesActions.DeleteWorkspaceConnection;
    readonly id: string;
    readonly connectionId: string;
}

export interface IDeleteWorkspaceConnectionComplete {
    readonly type: WorkspacesActions.DeleteWorkspaceConnectionComplete;
    readonly id: string;
    readonly connectionId: string;
    readonly success: boolean;
}

export interface IOverrideUserWorkspaceAccess {
    readonly type: WorkspacesActions.OverrideWorkspaceAccessibilityForUser;
    readonly id: string;
    readonly userId: string;
    readonly allow: boolean;
}

export type KnownAction =
    ILoadWorkspaces |
    ILoadWorkspacesComplete |
    IDeleteWorkspace |
    IDeleteWorkspaceComplete |
    IAddLoadedWorkspace |
    ILoadWorkspaceDetails |
    ILoadWorkspaceDetailsComplete |
    IChangeWorkspaceUserStatus |
    IChangeWorkspaceUserStatusComplete |
    ISaveWorkspaceUsers |
    ISaveWorkspaceUsersComplete |
    IBulkRemoveAllWorkspaceUsers |
    IBulkRemoveAllWorkspaceUsersComplete |
    IBulkAddWorkspaceUsers |
    IBulkAddWorkspaceUsersComplete |
    IResetWorkspaceUsers |
    IUpdateLoadedWorkspaceDetails |
    ILoadWorkspaceConnections |
    ILoadWorkspaceConnectionsComplete |
    IDeleteWorkspaceConnection |
    IDeleteWorkspaceConnectionComplete |
    IOverrideUserWorkspaceAccess;
