import * as React from 'react';

import '../Main.scss';

import {
    Col,
    Container,
    Row
} from 'reactstrap';

import HexImage from '../../images/grey-hex-noborder.png';
import StrongboxLock from '../../images/StrongboxLock.svg';

type Props = {
};

export const StrongboxLogo: React.FC<Props> = (props): React.ReactElement => {
    return (
        <Container fluid>
            <Row>
                <Col className={'welcome-content-container-column '}>
                    <span className={'welcome-image'}>
                        <img
                            src={HexImage}
                            className={'hex-background'}
                            alt={'hexagon background'}
                        />
                        <img
                            src={StrongboxLock}
                            className={'strongbox-logo'}
                            alt={'strongbox logo'}
                        />
                    </span>
                    <div className={'brand-text-container'}>
                        <span>Welcome to the</span>
                        <div className={'separator-line'} />
                        <span>Strongbox Portal</span>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}
