export const assetBlobContainer = 'https://portal.strongbox.link';
// Direct link in azure blob:   https://sbxfdppublicstgncus.blob.core.windows.net

export const strongboxPrimary = '#f05a2b';
export const strongboxSecondary = '#747474';

export const strongboxPrimaryBorrower = '#303030';
export const strongboxSecondaryBorrower = '#303030';

export const sbxCheckboxPrimary = '#ff7348';
export const sbxCheckboxSecondary = '#f05a2b';

export const deleteOrErrorButtonColor = '#F94141';


export const jsonHeaders = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
};

export const headerConstants = {
    /**
     * Header key for the user's timezone offset. Corresponds to a value in
     * RequestConstants.cs.
     */
    timezoneOffsetHeaderKey: 'Timezone-Offset',
};

export const black = '#000000';
export const white = '#FFFFFF';
export const flightLightGreen = '#c1d72e';
export const clientBlue = '#69AEDF';
// TODO 6601: This should be calculated from muiTheme color
export const clientHover = '#2ca9f1';
export const orange = '#EA8A1F';
export const darkGray = '#E1E1E1';
export const raspberry = '#a31444';
export const cashGuardianPurple = '#610ca1';

export const inflowColor = '#89bb40';
export const outflowColor = '#d39454';

/** Display string for the business owner. */
export const businessOwnerString = 'business manager';
export const desktopLinkerName = 'Finagraph Connect';
export const fullProductName = 'CashFlowTool.com';
export const updateFinancialsString = 'Update financials';

export const flightWarning = '#f5bb00';

export const mobileResponsiveBreakpoint = 768;

export const contactEmail = 'team@cashflowtool.com';

export const dataSetNameDescriptor = 'Company name';

export const chartStyles = {
    historicalCashInPrimary: '#40bb50',
    historicalCashInSecondary: '#d9f1dc',
    historicalCashOutPrimary: '#d35454',
    historicalCashOutSecondary: 'rgb(251, 237, 237)',
    predictedCashInPrimary: '#88bb40',
    predictedCashInSecondary: '#e7f1d9',
    predictedCashOutPrimary: '#d39454',
    predictedCashOutSecondary: '#fbf4ed',
    radius: Object.freeze([2, 2, 0, 0]) as number[],
    radiusStr: '2 2 0 0',
    strokeDasharray: '5 5',
};

export const dateConstants = {
    weeksInYear: 52,
    monthsInYear: 12,
};

export type SubmissionDetailsTabNames = 'overview' | 'financialstatements' | 'riskanalysis';

export const routeConstants = {
    home: 'home/workspacelist',

    workspaceSubmissionDetails: '/workspacesubmissiondetails',
    workspaceSubmissionDetailsOverview: '/workspacesubmissiondetails/overview',
    workspaceSubmissionDetailsFinancialStatements: '/workspacesubmissiondetails/financialstatements',
    workspaceSubmissionDetailsRiskAnalysis: '/workspacesubmissiondetails/riskanalysis',
    allSubmissionDetails: '/submissiondetails',
    allSubmissionDetailsOverview: '/submissiondetails/overview',
    allSubmissionDetailsFinancialStatements: '/submissiondetails/financialstatements',
    allSubmissionDetailsRiskAnalysis: '/submissiondetails/riskanalysis',
    submissionDetailsOverview: 'overview',
    submissionDetailsFinancialStatements: 'financialstatements',
    submissionDetailsRiskAnalysis: 'riskanalysis',
    submissionIdParam: ':submissionId',

    workspaceDetails: '/workspacedetails',
    workspaceDetailsSubmissionList: '/workspacedetails/submissionlist',
    workspaceDetailsSubmissions: '/workspacedetails/submissions',
    workspaceDetailsConnections: '/workspacedetails/connections',
    workspaceDetailsUsers: '/workspacedetails/users',
    workspaceIdParam: ':workspaceId',

    settings: 'settings/*',
    settingsUserManagement: 'usermanagement',
    settingsNotifications: 'usernotifications',
    settingsAdminNotifications: 'adminnotificationsettings',

    dataCollectionReport: 'dcr',

    authTimeout: 'authtimeout',
    slidingWindowTimeout: 'slidingtimeout',
    login: 'login',
    notFound: 'notfound',
    tenant: 'tenant',
    brand: 'brand',
    tenantLenderDisabled: 'ldisabled',
    tenantBorrowerDisabled: 'bdisabled',

    portal: 'portal',
    submission: 'submission',
    linked: 'linked',
    progress: 'progress',
    submissionSansFileUpload: 'submission-l',
    documents: 'documents',
    confirm: 'confirm',
    confirming: 'confirming',
    downloadUserCopy: 'downloadusercopy',

    shareableLinkExpired: 'shareablelinkexpired',
    shareableLinkDeleted: 'shareablelinkdeleted',
};

export const pathConstants = {
    auth0LoginRedirect: '/businesses',

    home: '/home/workspacelist',

    workspaceSubmissionDetails: '/workspacesubmissiondetails',
    workspaceSubmissionDefault: '/workspacesubmissiondetails/overview',
    workspaceSubmissionDetailsOverview: '/workspacesubmissiondetails/overview',
    workspaceSubmissionDetailsFinancialStatements: '/workspacesubmissiondetails/financialstatements',
    workspaceSubmissionDetailsRiskAnalysis: '/workspacesubmissiondetails/riskanalysis',
    allSubmissionDetails: '/submissiondetails',
    allSubmissionDefault: '/submissiondetails/overview',
    allSubmissionDetailsOverview: '/submissiondetails/overview',
    allSubmissionDetailsFinancialStatements: '/submissiondetails/financialstatements',
    allSubmissionDetailsRiskAnalysis: '/submissiondetails/riskanalysis',

    workspaceDetails: '/workspacedetails',
    workspaceDetailsDefault: '/workspacedetails/submissions',
    workspaceDetailsSubmissionList: '/workspacedetails/submissionlist',
    workspaceDetailsUsers: '/workspacedetails/users',
    workspaceDetailsConnections: '/workspacedetails/connections',

    settings: '/settings',
    settingsDefault: '/settings/usermanagement',
    settingsUserManagement: '/settings/usermanagement',
    settingsNotifications: '/settings/usernotifications',
    settingsAdminNotifications: '/settings/adminnotificationsettings',

    dataCollectionReport: '/dcr',

    authTimeout: '/authtimeout',
    slidingWindowTimeout: '/slidingtimeout',
    login: '/login',
    notFound: '/notfound',
    tenant: '/tenant',
    brand: '/brand',
    tenantLenderDisabled: '/ldisabled',
    tenantBorrowerDisabled: '/bdisabled',

    linked: '/linked',
    portal: '/portal',
    progress: '/progress',
    confirm: '/confirm',
    documents: '/documents',
    downloadUserCopy: '/downloadusercopy',
    submission: '/submission',
    submissionSansFileUpload: '/submission-l',

    shareableLinkExpired: '/shareablelinkexpired',
    shareableLinkDeleted: '/shareablelinkdeleted',

    queryParamInactivityTimeout: 'inactivitytimeout',
    autoNavToLogin: 'autologin'
};

export const breadCrumbContent = {
    workspaceList: 'Workspace List',
}

export const reinviteMessage = 'The user has been re-invited. Please let them to know to be on the lookout for an invitation in their email.'
export const noWorkbooksForSubmissionMsg = 'There are no workbooks available for this submission. You may need to try re-importing financial data for this company.';

export const retryConnectForImport = 'Retry';

export const maxDisplayNameLength = 256;
export const maxEmailAddressLength = 256;
export const maxFirstOrLastNameLength = 128;
export const maxEngagementCodeLength = 64;

export const auls_info_cookie_name = 'AULS_INFO';

export const NotificationStyleOptIn = 'OptIn';
export const NotificationStyleOptOut = 'OptOut';
export const NotificationStyleDisabled = 'Disabled';

export const fixedNotificationType = 'Email';

export const DefaultTOULink = 'https://sbxfdppublicstgncus.blob.core.windows.net/docs/Strongbox.TermsOfService.pdf';
export const DefaultStrongboxInfoLink = 'https://sbxfdppublicstgncus.blob.core.windows.net/docs/What%20is%20Strongbox%20(Security%20FAQ).pdf';

export const idStrongboxPortalHeaderWindow = 'strongbox-financial-portal-header-window-container';

export const ConnectionSourceText = {
    SecureUrlText: 'Secure url',
    DirectConnectionText: 'Direct connection'
}

export const RefreshPendingJobsTimeout = 15000;   // 15000 milliseconds, 15 seconds.

export const SubmissionMetaBorrowerBusinessName = 'borrowerBusinessName';
export const SubmissionMetaBorrowerBusinessEmail = 'borrowerBusinessEmail';

export const ImportMetadataTags = {
    borrowerBusinessName: 'borrowerBusinessName',
    borrowerBusinessEmail: 'borrowerBusinessEmail',
    sharedLinkCompanyName: 'sharedLinkCompanyName',
    sharedLinkApplicationId: 'sharedLinkApplicationId',
}

export const StgKeyOverrideAppSettings = 'overrideAppSettings';

export const AppDataCookieNames = {
    appData: 'AppData',
    featureEnablement: 'FeatureEnablement',
    portalConfiguration: 'PortalConfiguration',
    defaultSubmissionOptions: 'DefaultSubmissionOptions',
}

export const AdminUserRole = '10000000-0000-0000-0000-000000000006';

export const DefaultLookBackMonths = 120;

export const UserMetadataConstants = {
    MaxValueLength: 300,
}

export const EmptyGuid = '00000000-0000-0000-0000-000000000000';

export const FileUploadDataSourceNameId = 'fileupload';
