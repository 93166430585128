import * as React from 'react';

import '../Main.scss';

import {
    Button,
    Col,
    Container,
    Input,
    Label,
    Row
} from 'reactstrap';

import { LinkMode } from '../../Store/ImportFinancials';
import { LogMessage, SeverityLevel } from '../../Utils/Logging';

type Props = {
    containerStyle?: React.CSSProperties;
    id: string;
    selected: boolean;
    disabled?: boolean;
    onSelected?: () => void;
    linkType: LinkMode;
    hideSelectionMultiLine?: boolean;
    hideSelectionSingleLine?: boolean;
};

export const ImportModeSelector: React.FC<Props> = (props): React.ReactElement => {
    const {
        hideSelectionSingleLine,
        hideSelectionMultiLine,
        linkType,
        onSelected,
        disabled,
        containerStyle,
        selected,
        id,
    } = props;

    const buttonParameters = React.useMemo(() => {
        switch (linkType) {
            case LinkMode.direct:
                return {
                    label: 'Connect directly',
                    description: 'You have access to the accounting system and can connect to the accounting system directly.',
                    imageClass: 'connect-direct-image', 
                };
            case LinkMode.shareable:
                return {
                    label: 'Send secure URL',
                    description: 'Create a URL you can share with the business, guiding them through the accounting system connection process.',
                    imageClass: 'connect-shareable-link-image',
                };
            case LinkMode.excel:
                return {
                    label: 'Upload an Excel template',
                    description: 'Use a Strongbox template to upload ending balance monthly data.',
                    imageClass: 'connect-excel-image',
                };
        }

        const msg = `Unknown linkType passed to ImportModeSelect = ${linkType}`;
        console.error(msg);
        LogMessage(msg, SeverityLevel.Error, { linkType });

        return {
            label: '',
            description: '',
            imageClass: '',
        };
    }, [linkType]);

    const contentFull = (): React.ReactElement => {
        return (
            <Row className={'hex-button-action-container'}>
                <Col xs={2} className={'hex-button-col hex-button-col-multi-line'}>
                    <Button
                        onClick={() => onSelected && onSelected()}
                        disabled={false}
                        className={`small-hex-button ${!onSelected && 'hex-button-no-click'}`}
                        tabIndex={0}
                        autoFocus={false}
                    >
                        <span
                            className={`icon-container small-icon-container ${buttonParameters.imageClass}`}
                        />
                    </Button>
                </Col>
                <Col>
                    <Row>
                        <Col xs={'auto'}>
                            <div className={'bolded-selector-row'}>
                                <Input
                                    type={'radio'}
                                    checked={selected}
                                    id={id}
                                    key={id}
                                    onChange={() => onSelected && onSelected()}
                                    disabled={disabled}
                                />
                                <Label
                                    className={`${(disabled && 'disabled') || (!!onSelected && 'clickable-element')}`}
                                    check
                                    for={id}
                                    onClick={() => onSelected && onSelected()}
                                >
                                    {buttonParameters.label}
                                </Label>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <Col>
                            <p className={`${(disabled && 'disabled') || ''}`}>{buttonParameters.description}</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }

    const contentSmall = (): React.ReactElement => {
        return (
            <Row className={'hex-button-action-container'}>
                <Col className={`hex-button-col ${hideSelectionSingleLine ? 'hex-button-col-single-line' : 'hex-button-col-multi-line'}`}>
                    <Button
                        onClick={() => onSelected && onSelected()}
                        disabled={false}
                        className={`single-line-hex-button ${!onSelected && 'hex-button-no-click'}`}
                        tabIndex={0}
                        autoFocus={false}
                    >
                        <span
                            className={`icon-container single-line-icon-container ${buttonParameters.imageClass}`}
                        />
                    </Button>
                    <div className={'bolded-selector-row'}>
                        <Label
                            className={`${(disabled && 'disabled') || (!!onSelected && 'clickable-element')}`}
                            check
                            for={id}
                            onClick={() => onSelected && onSelected()}
                        >
                            {buttonParameters.label}
                        </Label>
                    </div>
                </Col>
            </Row>
        )
    }

    return (
        <Container
            fluid
            className={'lender-region'}
            style={containerStyle}
        >
            {!(hideSelectionSingleLine || hideSelectionMultiLine) && contentFull()}
            {!!(hideSelectionSingleLine || hideSelectionMultiLine) && contentSmall()}
        </Container>
    );
}
