import * as React from 'react';

import FormulaIcon from '@mui/icons-material/Functions';

import {
    Tooltip
} from '@mui/material';

import { FSModels } from '@finagraph/financial-statement-editor';

type Props = {
    lineItem: FSModels.FinancialStatementLineItemViewModel;
    formulasById: Map<string, FSModels.FinancialStatementFormula>;
    editable: boolean;
    onToggleReportedAs: (sectionId: string, updatedValue: FSModels.CreditOrDebit) => void;
}

export const LineItemProps: React.FC<Props> = (props): React.ReactElement => {
    const {
        editable,
        formulasById,
        lineItem,
        onToggleReportedAs
    } = props;

    const renderData: {
        reportedAsIconTitle: JSX.Element;
        reportedAsCaption: string;
    } = React.useMemo(() => {
        const reportedAsIconTitle = lineItem.reportedAs ===  FSModels.CreditOrDebit.Credit
            ? (<span>Credits are being reported as positive numbers for <b>{lineItem.captionText}</b></span>)
            : (<span>Debits are being reported as positive numbers for <b>{lineItem.captionText}</b></span>);
        const reportedAsCaption = lineItem.reportedAs === FSModels.CreditOrDebit.Credit ? 'CR' : 'DR';

        return {
            reportedAsCaption,
            reportedAsIconTitle
        };
    }, [lineItem])

    return (
        <div className={`line-item-props line-item-props-${editable ? 'editable' : 'readonly'}`}>    
            {lineItem.canViewFormula && (
                <Tooltip title={formulasById.get(lineItem.formulaId!)!.renderedExpression}>
                    <FormulaIcon
                        className={`line-item-props-${lineItem.canViewFormula ? 'visible' : 'hidden'}`}
                    />
                </Tooltip>
            )}   
            {!lineItem.canViewFormula && (
                <span className={'empty-action'} />
            )}
            {lineItem.canEditReportedAs && (
                <Tooltip title={renderData.reportedAsIconTitle}>
                    <span
                        onClick={() => onToggleReportedAs(lineItem.sectionId, lineItem.reportedAs === FSModels.CreditOrDebit.Credit ? FSModels.CreditOrDebit.Debit : FSModels.CreditOrDebit.Credit)}
                        style={{color: '#666', fontWeight: 'bold'}} 
                        className={'hidden-without-hover-item'}>
                        {renderData.reportedAsCaption}
                    </span>
                </Tooltip>
            )}
            {!lineItem.canEditReportedAs && (
                <span className={'empty-action'} />
            )}        
        </div>
    )
}
