import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ApplicationState } from '../../Store';

import {
    Button,
    Col,
    Container,
    Row
} from 'reactstrap';

import { RunningImport } from '../../Store/RunningImports';
import { actionCreators as SubmissionActions, GetSubmission } from '../../Store/Submission';
import {
    GetPortalBusyState,
} from '../../Store/UIState';

import { pathConstants, retryConnectForImport } from '../../Utils/Constants';
import { LinkMode } from '../../Store/ImportFinancials';
import { Submission } from '../../Models/Api/strongbox.financialportal';

import CheckIcon from '@mui/icons-material/CheckCircleRounded';
import ErrorIcon from '@mui/icons-material/ErrorRounded';

import { BuildQueryString } from '../../Utils/WorkspaceRouteInformation';
import { AccountingPackageFriendlyNameFromStringDescriptor, GetAccountingImageClass } from '../../Utils/LinkUtils';

import { LoadingIndicator } from '../LoadingIndicator/LoadingIndicator';
import { DropDownCaret } from '../DropDownCaret/DropDownCaret';
import { RequestSummary } from '../FileGroup/RequestSummary';
import { LoadingMessageWithWaitIndicator } from '../LoadingMessageWithIndicator/LoadingMessageWithIndicator';

import { SubmittedByIcon } from '../SubmittedByIcon/SubmittedByIcon';

import UsersIcon from '@mui/icons-material/GroupAdd';
import {
    Tooltip
} from '@mui/material';

type InjectedReduxState = {
    loadingSubmission: boolean;
    rowExpanded: boolean;
    entrySubmission?: Submission;
    failedRetrievingSubmission?: string;
    failedRetrievingException?: string;
    portalBusy: boolean;
};

type InjectedActionCreators = typeof SubmissionActions;

type RunningImportsJobItemProps = {
    job: RunningImport;
    onDismiss?: (financialRecordId: string, workspaceId: string, fromNavigation: boolean, redirectLink?: string) => void;
    onRetryConnect?: (financialRecordId: string, workspaceId: string) => void;
    onManageWorkspaceUsers?: (workspaceId: string, submissionId: string) => void;
    hideWorkspaceInfo?: boolean;
};

type Props = RunningImportsJobItemProps & InjectedReduxState & InjectedActionCreators;

const expandedMetadataKey = 'runningSubmissionJobItemExpanded';

export const RunningImportsJobItemComponent: React.FC<Props> = (props): React.ReactElement => {
    const outcomeSuccess = 'Success';
    const outcomePending = 'Pending';
    const outcomeError = 'Error';

    const disconnectedStatus = 'Disconnected';

    const importingText = 'Importing';
    const errorText = 'Error';
    const successText = 'Successfully completed';

    const {
        datasourceNameId,
        status,
        financialRecordId,
        submissionId,
        workspaceId,
        workspaceName,
        canManageWorkspaceUsers,
        canViewWorkspace,
        hasShareableLink,
        submissionRequestId,
    } = props.job;

    const {
        onDismiss,
        onRetryConnect,
        hideWorkspaceInfo,
        entrySubmission,
        loadingSubmission,
        failedRetrievingSubmission,
        LoadSubmission,
        rowExpanded,
        UpdateSubmissionMetadata,
        portalBusy,
        onManageWorkspaceUsers,
    } = props;

    const defaultStatusText = importingText;

    const getStatusText = React.useCallback(() => {
        if (!!status) {
            switch (status.outcome) {
                case outcomePending:
                    return importingText;
                case outcomeSuccess:
                    return successText;
                case outcomeError:
                    return errorText;
            }
        }
        return undefined;
    }, [status])

    const [statusText, setStatusText] = React.useState<string>(() => {
        if (status) {
            return getStatusText() || defaultStatusText;
        } else {
            return defaultStatusText;
        }
    });

    React.useEffect(() => {
        setStatusText(getStatusText() || defaultStatusText);
    }, [defaultStatusText, status, getStatusText]);

    React.useEffect(() => {
        // User could have had access to the workspace removed while the row is expanded.
        // Since they no longer have access they shouldn't be able to see the data for the row.
        if (!canViewWorkspace && rowExpanded) {
            const newMetadata = new Map<string, any>();
            newMetadata.set(expandedMetadataKey, false);
            UpdateSubmissionMetadata(
                workspaceId,
                submissionId,
                newMetadata,
            );
        }

        // canViewWorkspace is the only dependency that really matters here.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canViewWorkspace])

    const jobIcon = React.useMemo(() => {
        const spinnerSize = 16;
        const spinnerTopMargin = 4;

        if (!status) {
            return {
                placement: 'before',
                component: (
                    <LoadingIndicator
                        active={true}
                        size={spinnerSize}
                        style={{
                            marginTop: `${spinnerTopMargin}px`,
                        }}
                    />
                )
            }
        }
        switch (status.outcome) {
            case outcomePending:
                return {
                    placement: 'before',
                    component: (
                        <LoadingIndicator
                            active={true}
                            size={spinnerSize}
                            style={{
                                marginTop: `${spinnerTopMargin}px`,
                            }}
                        />
                    )
                }
            case outcomeSuccess:
                return {
                    placement: 'before',
                    component: (<CheckIcon color={'primary'} className={'job-icon'} />)
                }
            case outcomeError:
                return {
                    placement: 'before',
                    component: (<ErrorIcon color={'primary'} className={'job-icon'} />)
                }
            default:
                return {
                    placement: 'before',
                    component: (
                        <LoadingIndicator
                            active={true}
                            size={spinnerSize}
                            style={{
                                marginTop: `${spinnerTopMargin}px`,
                            }}
                        />
                    )
                }
        }
    }, [status]);

    const statusColumn = React.useMemo(() => {
        if ((status) && (status.outcome === outcomeError)) {
            return (
                <Col
                    className={'job-status job-status-error error-text'}
                >
                    {statusText}<br/>
                    {status.errorDescription}
                </Col>
            );
        } else {
            if (jobIcon.placement === 'before') {
                return (
                    <Col
                        className={'job-status'}
                    >
                        {statusText}
                    </Col>
                );
            } else {
                return (
                    <Col
                        className={'job-status job-status-after'}
                    >
                        {statusText}
                        {jobIcon.component}
                    </Col>
                );
            }
        }
    }, [jobIcon, status, statusText]);

    const actionButton = React.useMemo(() => {
        const multiButtonStyle = {
            marginLeft: '10px'
        }
        return (
            <>
                <Col>
                    <Button
                        className={'extrasmall'}
                        onClick={() => {
                            onDismiss && onDismiss(financialRecordId, workspaceId, false);
                        }}
                        disabled={portalBusy}
                    >
                        Dismiss
                    </Button>
                    {
                        (status) && (status.errorCode === disconnectedStatus) && (status.outcome === outcomeError) && (
                            <Button
                                className={'extrasmall'}
                                style={multiButtonStyle}
                                onClick={() => {
                                    onRetryConnect && onRetryConnect(financialRecordId, workspaceId);
                                }}
                                disabled={portalBusy}
                            >
                                {retryConnectForImport}
                            </Button>
                        )
                    }
                </Col>
            </>
        )

        // This is the dependency list I actually want.
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [financialRecordId, status, workspaceId, onDismiss, onRetryConnect]);

    const workspaceIcon = React.useMemo(() => {
        const containerSpanStyle: React.CSSProperties = {
            marginRight: '10px'
        }

        if (!canManageWorkspaceUsers || canViewWorkspace || !onManageWorkspaceUsers) {
            return (
                <span
                    className={'job-icon'}
                    style={containerSpanStyle}
                />
            )
        } else {
            return (
                <Tooltip title={'Manage user access to this workspace'}>
                    <span
                        className={'button-container'}
                        style={containerSpanStyle}
                    >
                        <button
                            type="button"
                            onClick={() => onManageWorkspaceUsers && onManageWorkspaceUsers(workspaceId, submissionId)}
                            disabled={portalBusy}
                            className={'job-icon'}
                        >
                            <UsersIcon style={{ margin: 'auto' }} />
                        </button>
                    </span>
                </Tooltip>
            )
        }

        // canManageWorkspaceUsers is the only dependency that really matters here.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canManageWorkspaceUsers])

    const getWorkspaceContent = (withLink: boolean): React.ReactElement => {
        if (withLink) {
            const refLink = `${pathConstants.workspaceDetailsSubmissionList}/${BuildQueryString({ workspaceId: workspaceId, workspaceName: workspaceName || '' })}`;
            return (
                <React.Fragment>
                    {workspaceIcon}
                    <Tooltip title={workspaceName}>
                        <button
                            onClick={(e) => {
                                onDismiss && onDismiss(financialRecordId, workspaceId, true, refLink);
                            }}
                            className={'extrasmall'}
                            disabled={portalBusy}
                        >
                            <span className={'button-text'}>{workspaceName}</span>
                        </button>
                    </Tooltip>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    {workspaceIcon}
                    <span className={'anchor-text-small'}>{workspaceName}</span>
                </React.Fragment>
            );

        }
    }

    const retrieveSubmission = async (expansionState: boolean): Promise<void> => {
        const metadata = new Map<string, any>();
        metadata.set(expandedMetadataKey, expansionState);
        await LoadSubmission(workspaceId, submissionId, metadata);
    }

    return (
        <Container>
            <Row className={'job-item-row'}>
                <Col xs={5} md={3} className={'job-column'}>
                    <DropDownCaret
                        expanded={rowExpanded}
                        contractDescriptor={`less information`}
                        expandDescriptor={`more information`}
                        clicked={() => {
                            if (!rowExpanded && !entrySubmission) {
                                retrieveSubmission(true);
                            } else {
                                const newMetadata = new Map<string, any>();
                                newMetadata.set(expandedMetadataKey, !rowExpanded);
                                UpdateSubmissionMetadata(
                                    workspaceId,
                                    submissionId,
                                    newMetadata,
                                );
                            }
                        }}
                        style={{ marginRight: '10px' }}
                        hidden={!canViewWorkspace}
                    />
                    <SubmittedByIcon
                        linkType={submissionRequestId === -1 ? LinkMode.anonymous : hasShareableLink ? LinkMode.shareable : LinkMode.direct }
                        iconId={`${submissionId}`}
                        iconStyle={{ marginRight: '10px' }}
                    />
                    <span
                        className={`job-icon ${GetAccountingImageClass(datasourceNameId)}`}
                        style={{
                            marginRight: '10px'
                        }}
                    />
                    <span>{AccountingPackageFriendlyNameFromStringDescriptor(datasourceNameId)}</span>
                </Col>
                {
                    !hideWorkspaceInfo && (
                        <Col
                            className={'job-column'}
                            xs={3}
                        >
                            {getWorkspaceContent((!!status) && (status.outcome === outcomeSuccess))}
                        </Col>
                    )
                }
                <Col
                    className={'job-column'}
                    xs={3} lg={4}
                >
                    {(jobIcon.placement === 'before') && (
                        <React.Fragment>
                            {jobIcon.component}
                            <div style={{marginRight: '10px'}} />
                            {statusColumn}
                        </React.Fragment>
                    )}
                    {(jobIcon.placement === 'after') && statusColumn}
                </Col>
                {actionButton}
            </Row>
            {
                rowExpanded && (
                    <Row className={'data-container'}>
                        <Col>
                            {loadingSubmission && (
                                <LoadingMessageWithWaitIndicator
                                    msg={'Retrieving data collection details...'}
                                />
                            )}
                            {!loadingSubmission && !!failedRetrievingSubmission && (
                                <p>{failedRetrievingSubmission}</p>
                            )}
                            {!loadingSubmission && !!entrySubmission && !!entrySubmission.submissionRequest && (
                                <RequestSummary
                                    requestSummary={entrySubmission.submissionRequest}
                                    submission={entrySubmission}
                                    showTypicalImportTime={true}
                                />
                            )}
                        </Col>
                    </Row>
                )
            }
        </Container>
    );
}

export const RunningImportsJobItem = connect<InjectedReduxState, InjectedActionCreators, RunningImportsJobItemProps, ApplicationState>(
    (appState: ApplicationState, props: RunningImportsJobItemProps) => {
        const submissionRecord = GetSubmission(appState, props.job.submissionId);
        const portalBusy = GetPortalBusyState(appState);

        if (!submissionRecord) {
            return {
                loadingSubmission: false,
                rowExpanded: false,
                portalBusy,
            }
        } else {
            const isRowExpanded: boolean = submissionRecord.metadata.get(expandedMetadataKey);
            return {
                loadingSubmission: submissionRecord.loading,
                entrySubmission: submissionRecord.submission,
                failedRetrievingSubmission: submissionRecord.msg,
                failedRetrievingException: submissionRecord.exceptionMsg,
                rowExpanded: isRowExpanded,
                portalBusy,
            };
        }
    },
    dispatch => bindActionCreators(
        {
            ...SubmissionActions,
        },
        dispatch
    )
)(RunningImportsJobItemComponent);
