import * as React from 'react';
import {
    Dropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    Col,
    Container,
    Row
} from 'reactstrap';

import { CustomRangeDropdown } from './CustomRangeDropdown';

import {
    customRange,
    priorMonthRange,
    ytdRange,
    GetPriorMonthSubmissionHistoryRange,
    GetYtdSubmissionHistoryRange,
    currentMonthRange,
    allRange,
    GetCurrentMonthSubmissionHistoryRange,
    GetAllSubmissionHistoryRange,
    SubmissionHistoryRange,
    SubmissionHistoryRangeSelectionType
} from '../../Utils/SubmissionHistoryRange';

type Props = {
    submissionHistoryRange: SubmissionHistoryRange;
    selectionType: SubmissionHistoryRangeSelectionType;
    searchButtonText: string;
    onRangeTypeChanged?: (newRangeType: SubmissionHistoryRangeSelectionType, newRange: SubmissionHistoryRange) => void;
    onSetSubmissionHistoryRange?: (range: SubmissionHistoryRange) => void;
    onExecuteSearch?: (range: SubmissionHistoryRange) => void;
    rangeSelectionOpen: boolean;
    toggleRangeSelectionOpen: () => void;
    closeRangeSelection: () => void;
};

export const SubmissionHistoryRangeSelection: React.FC<Props> = (props): React.ReactElement => {
    const {
        onRangeTypeChanged,
        onSetSubmissionHistoryRange,
        onExecuteSearch,
        searchButtonText,
        selectionType,
        submissionHistoryRange,
        rangeSelectionOpen,
        toggleRangeSelectionOpen,
        closeRangeSelection
    } = props;

    const [typeSelectionOpen, setTypeSelectionOpen] = React.useState<boolean>(false);

    const toggleTypeSelection = (): void => { setTypeSelectionOpen(!typeSelectionOpen); }

    const newSubmissionRange = (newRange: SubmissionHistoryRange): SubmissionHistoryRange => {
        if (!!newRange.end) {
            newRange.end.setHours(23, 59, 59, 999);
        }
        onSetSubmissionHistoryRange && onSetSubmissionHistoryRange(newRange);
        return newRange;
    }

    const newSelectionType = (newSelectionType: SubmissionHistoryRangeSelectionType): void => {
        if (newSelectionType === selectionType) {
            return;
        }

        let newRange: SubmissionHistoryRange = {
            ...submissionHistoryRange
        }
        switch (newSelectionType) {
            case allRange:
                newRange = GetAllSubmissionHistoryRange();
                break;
            case currentMonthRange:
                newRange = GetCurrentMonthSubmissionHistoryRange();
                break;
            case priorMonthRange:
                newRange = GetPriorMonthSubmissionHistoryRange();
                break;
            case ytdRange:
                newRange = GetYtdSubmissionHistoryRange();
                break;
            case customRange:
                if (selectionType === allRange) {
                    newRange = GetCurrentMonthSubmissionHistoryRange();
                }
                break;
        }

        onRangeTypeChanged && onRangeTypeChanged(newSelectionType, newRange);

        if (newSelectionType !== customRange) {
            onExecuteSearch && onExecuteSearch(newRange);
        }
    }

    return (
        <Container fluid>
            <Row>
                <Col xs={'auto'}>
                    <Row>
                        <Col>
                            <Dropdown id={'selectionType'} isOpen={typeSelectionOpen} toggle={toggleTypeSelection}>
                                <DropdownToggle caret>
                                    {selectionType}<span className={'spacer'} />
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => newSelectionType(allRange)}>{allRange}</DropdownItem>
                                    <DropdownItem onClick={() => newSelectionType(currentMonthRange)}>{currentMonthRange}</DropdownItem>
                                    <DropdownItem onClick={() => newSelectionType(priorMonthRange)}>{priorMonthRange}</DropdownItem>
                                    <DropdownItem onClick={() => newSelectionType(ytdRange)}>{ytdRange}</DropdownItem>
                                    <DropdownItem onClick={() => newSelectionType(customRange)}>{customRange}</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </Col>
                        {
                            selectionType === customRange && (
                                <Col xs={'auto'}>
                                    <CustomRangeDropdown
                                        searchButtonText={searchButtonText}
                                        submissionHistoryRange={submissionHistoryRange}
                                        onNewRange={range => newSubmissionRange(range)}
                                        onExecute={range => onExecuteSearch && onExecuteSearch(range)}
                                        rangeSelectionOpen={rangeSelectionOpen}
                                        toggleRangeSelectionOpen={toggleRangeSelectionOpen}
                                        closeRangeSelection={closeRangeSelection}
                                    />
                                </Col>
                            )
                        }
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}
