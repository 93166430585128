import * as React from 'react';

import '../Main.scss';

type Props = {
    title: string;
    containerStyle?: React.CSSProperties;
    titleStyle?: React.CSSProperties;
    children?: React.ReactNode;
};

export const TitleBox: React.FC<Props> = (props): React.ReactElement => {
    const {
        containerStyle,
        children,
        title,
        titleStyle,
    } = props;
    return (
        <div
            className={'title-box-container'}
            style={containerStyle}
        >
            <span
                className={'title-box-title'}
                style={titleStyle}
            >
                {title}
            </span>
            <div>{children}</div>
        </div>
    );
}
