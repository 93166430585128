import * as React from 'react';

import '../Main.scss';

import {
    Input,
    Row,
    Col
} from 'reactstrap';

import { GetUnsafeContentMessage } from '../../Utils/FormUtils';
import { DangerousInput } from './DangerousInputForm';

type Props = {
    id: string;
    name: string;
    nameForInvalidMsg: string;
    value?: string;
    maxLength?: number;
    clearFieldWithDangerousContent: () => void;
    updateField: (newValue: string) => void;
    fieldWithDangerousContent?: DangerousInput;
    required?: boolean;
    autoFocus?: boolean;
};

export const PotentiallyDangerousInput: React.FC<Props> = (props): React.ReactElement => {
    const {
        id,
        name,
        value,
        clearFieldWithDangerousContent,
        updateField,
        maxLength,
        fieldWithDangerousContent,
        nameForInvalidMsg,
        required,
        autoFocus
    } = props;

    const invalidMsg = React.useMemo(() => {
        return GetUnsafeContentMessage(nameForInvalidMsg);
    }, [nameForInvalidMsg]);

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Input
                        type={'text'}
                        id={id}
                        name={name}
                        value={value}
                        onChange={(e) => {
                            clearFieldWithDangerousContent();
                            updateField(e.currentTarget.value);
                        }}
                        required={!!required}
                        autoFocus={!!autoFocus}
                        maxLength={maxLength}
                    />
                </Col>
                {
                    !!fieldWithDangerousContent && (fieldWithDangerousContent.fieldId === id) && (
                        <Row>
                            <Col>
                                <span className={'error-text'}>{invalidMsg}</span>
                            </Col>
                        </Row>
                    )
                }
            </Row>
        </React.Fragment>
    );
}
