import * as React from 'react';
import { useLocation } from 'react-router-dom';

import '../Main.scss';

import { UserManagement } from './Users/UserManagement';
import { pathConstants } from '../../Utils/Constants';

type Props = {
}

export const ActiveSettingsContainer: React.FC<Props> = (props): React.ReactElement => {
    const location = useLocation();

    return (
        <div
            className={'active-settings-container'}
        >
            {(location && location.pathname === pathConstants.settingsUserManagement) &&
                (<UserManagement />)
            }
        </div>
    );
}

