import * as React from 'react';

import {
    Col,
    Row
} from 'reactstrap'

type Props = {
    businessName: string;
    pageHeaderPrefix: string;
}

export const SubmissionDetailHeader: React.FC<Props> = (props): React.ReactElement => {
    const {
        businessName,
        pageHeaderPrefix,
    } = props;

    return (
        <>
            <Row>
                <Col>
                    <h1>{`${pageHeaderPrefix} for: ${businessName}`}</h1>
                </Col>
            </Row>
        </>
    )
}
