import * as React from 'react';

import '../../Main.scss';
import './FinancialStatements.scss';

import {
    Button
} from 'reactstrap';

import {
    Tooltip
} from '@mui/material';

import BoldIcon from '@mui/icons-material/FormatBold';
import ItalicIcon from '@mui/icons-material/FormatItalic';
import UnderlineIcon from '@mui/icons-material/FormatUnderlined';
import InfoIcon from '@mui/icons-material/Info';

import { FinancialStatementRenderData } from './UtilTypes';

import { SanitizeDOMSelector } from '../../../Utils/DOMUtils';

import { FSModels } from '@finagraph/financial-statement-editor';

export type CaptionEditType = 'none' | 'single' | 'double';

export const lineItemCaptionId = (lineItem: FSModels.FinancialStatementLineItemViewModel, statementType: FSModels.FinancialStatementType): string => {
    return `${statementType}-financial-statement-line-item-caption-${SanitizeDOMSelector(lineItem.id)}`;
}

export const lineItemAnchorId = (lineItem: FSModels.FinancialStatementLineItemViewModel, statementType: FSModels.FinancialStatementType): string => {
    return `${statementType}-financial-statement-line-item-anchor-${SanitizeDOMSelector(lineItem.id)}`;
}

export const retrictedInfoIconId = (lineItem: FSModels.FinancialStatementLineItemViewModel, statementType: FSModels.FinancialStatementType): string => {
    return `${statementType}-financial-statement-restricted-li-anchor-${SanitizeDOMSelector(lineItem.id)}`;
}

type Props = {
    lineItem: FSModels.FinancialStatementLineItemViewModel;
    formatting: FSModels.CaptionFormatting;
    stylesBelowEdit: boolean;
    statementType: FSModels.FinancialStatementType;
    financialStatement: FSModels.FinancialStatementViewModel;
    renderData: FinancialStatementRenderData;
    onEditMe: (lineItem: FSModels.FinancialStatementLineItemViewModel, targetId: string) => void;
}

export const FinancialStatementLineItemCaption: React.FC<Props> = (props): React.ReactElement => {
    const {
        lineItem,
        formatting,
        statementType,
        onEditMe
    } = props;

    const [formatStyle, setFormatStyle] = React.useState<FSModels.CaptionFormatting>(formatting);

    const boldButtonId = `style-by-side-bold-button-${SanitizeDOMSelector(lineItem.id)}`;
    const italicButtonId = `style-by-side-italic-button-${SanitizeDOMSelector(lineItem.id)}`;
    const underlineButtonId = `style-by-side-underline-button-${SanitizeDOMSelector(lineItem.id)}`;

    const captionClassName = React.useMemo(() => {
        const sectionId = lineItem.sectionId.toLowerCase();

        let result = 'detail-account';

        if ((sectionId !== 'cash') && (sectionId !== 'accountsreceivable') && (sectionId !== 'accountspayable')) {
            if (lineItem.lineItemType !== FSModels.FinancialStatementLineItemType.DetailAccount) {
                result = 'line-item';
            }
        }

        if (!!formatting.bold) {
            result += ' bolded'
        }
        if (!!formatting.italic) {
            result += ' italic'
        }
        switch (formatting.underlineStyle) {
            case FSModels.UnderlineStyle.Single:
                result += ' single-underline';
                break;
            case FSModels.UnderlineStyle.Double:
                result += ' double-underline';
                break;
        }
        return result;

        // This is in fact the dependency list I want
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formatting, lineItem]);

    const spanClickHandler = React.useMemo(() => {
        const sectionId = lineItem.sectionId.toLowerCase();

        if ((sectionId === 'cash') || (sectionId === 'accountsreceivable') || (sectionId === 'accountspayable')) {
            return undefined;
        }
        return (lineItem.lineItemType === FSModels.FinancialStatementLineItemType.DetailAccount) ?
            undefined :
            () => { onEditMe(lineItem, lineItemAnchorId(lineItem, statementType)) };

        // These are the dependencies I really want.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lineItem, statementType]);

    const captionTipComponentProps = React.useMemo(() => {
        if (lineItem.lineItemType !== FSModels.FinancialStatementLineItemType.SectionFooter) {
            return undefined;
        }

        const sectionId = lineItem.sectionId.toLowerCase();
        if ((sectionId !== 'cash') && (sectionId !== 'accountsreceivable') && (sectionId !== 'accountspayable')) {
            return undefined;
        }

        return {
            tooltip: {
                sx: {
                    bgcolor: 'white',
                    color: '#f05a2b',
                    border: 2,
                    borderColor: 'primary',
                    fontWeight: 'bold',
                    fontSize: 16
                }
            }
        };
    }, [lineItem]);

    const captionTipText = React.useMemo(() => {
        if (lineItem.lineItemType !== FSModels.FinancialStatementLineItemType.SectionFooter) {
            return lineItem.captionText;
        }

        const sectionId = lineItem.sectionId.toLowerCase();
        if ((sectionId !== 'cash') && (sectionId !== 'accountsreceivable') && (sectionId !== 'accountspayable')) {
            return lineItem.captionText;
        }

        return `Attention: The '${lineItem.captionText}' section is not currently editable so that ratios, cash flow analysis and other metrics remain consistent.`
    }, [lineItem]);

    const restrictedInfoContent = React.useMemo(() => {
        if (lineItem.lineItemType !== FSModels.FinancialStatementLineItemType.SectionFooter) {
            return undefined;
        }

        const sectionId = lineItem.sectionId.toLowerCase();
        if ((sectionId !== 'cash') && (sectionId !== 'accountsreceivable') && (sectionId !== 'accountspayable')) {
            return undefined;
        }

        return (
            <Tooltip
                title={captionTipText}
                componentsProps={captionTipComponentProps}
            >
                <InfoIcon
                    className={'basic-list-icon'}
                    color={'primary'}
                    style={{
                        marginLeft: '15px'
                    }}
                />
            </Tooltip>
        );
    }, [lineItem, captionTipComponentProps, captionTipText]);

    return (
        <div className={'inline-style-editor'}>
            <div
                className={'popover-anchor'}
                id={lineItemAnchorId(lineItem, statementType)}
            />
            <Tooltip
                title={captionTipText}
                componentsProps={captionTipComponentProps}
            >
                <span
                    className={captionClassName}
                    onClick={spanClickHandler}
                    id={lineItemCaptionId(lineItem, statementType)}
                >
                    {lineItem.captionText}
                </span>
            </Tooltip>
            {restrictedInfoContent}
            {
                !props.stylesBelowEdit && (
                    <div className={'style-button-container style-button-container-right'}>
                        <Button
                            id={boldButtonId}
                            className={`${!!formatStyle.bold ? 'pressed' : ''}`}
                            onClick={() => {
                                const newFormatStyle = {
                                    ...formatStyle,
                                    bold: !!formatStyle.bold ? false : true,
                                }
                                setFormatStyle(newFormatStyle);
                            }}
                        >
                            <BoldIcon />
                        </Button>
                        <Button
                            id={italicButtonId}
                            className={`${!!formatStyle.italic ? 'pressed' : ''}`}
                            onClick={() => {
                                const newFormatStyle = {
                                    ...formatStyle,
                                    italic: !!formatStyle.italic ? false : true,
                                }
                                setFormatStyle(newFormatStyle);
                            }}
                        >
                            <ItalicIcon />
                        </Button>
                        <Button
                            id={underlineButtonId}
                            className={`${(FSModels.UnderlineStyle.Single || formatting.underlineStyle === FSModels.UnderlineStyle.Double) ? 'pressed' : ''}`}
                            onClick={() => {
                                const newFormatStyle = {
                                    ...formatStyle
                                };

                                newFormatStyle.underlineStyle = !!formatStyle.underlineStyle ? undefined : FSModels.UnderlineStyle.Single;

                                setFormatStyle(newFormatStyle);
                            }}
                        >
                            <UnderlineIcon />
                        </Button>
                    </div>
                )
            }
        </div>
    );
}
