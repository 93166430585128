import '../Main.scss';

import * as React from 'react';


export type ErrorMsgSeverity = 'Error' | 'Notification';

export type ErrorAction = {
    text: string;
    id: string;
    onAction: (id: string) => void;
}

export type ErrorState = {
    summaryMessage: string;
    extraInformation?: string;
    severity?: ErrorMsgSeverity;
    actions?: ErrorAction[];
};

/**
 * There are two ways to handle actions associated with the error banner. The simplest use
 * is just to provide a handler in onDefaultActionButton.  A single button with "OK" is presented
 * and when the user clicks that button, onDefaultActionButton is called, if provided.
 * 
 * To override text or provide multiple responses for the banner provide a value for actions.  
 * If actions is present, its content is used for populating the action button(s) shown on the 
 * error banner and each has its own individual action.
 * 
 * @member(onDefaultActionButton) if actions is not present, this method is called when the
 * user presses the "OK" button in the error banner
 * @member(defaultActionText) if present will be the text that goes on the default button when actions
 * is not present.  Default value is "OK"
 * @member(actions) if present will override the default action button. Each element in actions
 * defines action text and a handler for the action
 * 
 * */

type Props = {
    errorState?: ErrorState;
    onDefaultActionButton?: () => void;
    defaultActionText?: string;
    smallText?: boolean;
    noDismiss?: boolean;
};

export const stdErrorAction = 'Click the button below to return to the login page.';

export const ErrorBanner: React.FC<Props> = (props): React.ReactElement => {
    const {
        errorState,
        onDefaultActionButton,
        defaultActionText,
        smallText,
        noDismiss
    } = props;

    let textClass = '';

    if (!errorState) {
        return (<></>);
    } else {
        if ((props.errorState!.severity === undefined) || (props.errorState!.severity === 'Error')) {
            textClass = 'error-text';
        }
    }

    if (!!smallText) {
        textClass += ' small-error-message';
    }

    // toString() on summaryMessage and extraInformation is a sanity check.  If a consumer passes a non-string
    // object for either of these values which could happen e.g. if a fetcherror is passed instead of converting
    // it first to a string, this will prevent a crash.

    return (
        <div className={'error-banner'}>
            <h2 className={textClass}>{errorState.summaryMessage.toString()}</h2>
            {errorState.extraInformation &&
                (<p style={{ marginTop: '20px' }}>{errorState.extraInformation.toString()}</p>)
            }
            {
                !!errorState.actions &&  (
                    <div className={'actions'}>
                        {
                            errorState.actions.map((action, i) => (
                                <button
                                    key={`error_action_button_${i}`}
                                    style={{
                                        width: 'fit-content',
                                        marginRight: '15px',
                                    }}
                                    onClick={() => action.onAction(action.id)}
                                    className={'small'}
                                >
                                    {action.text}
                                </button>
                            ))
                        }
                    </div>
                )
            }
            {
                !errorState.actions && (!noDismiss) && (
                    <button
                        style={{
                            marginTop: '20px',
                            width: 'fit-content',
                        }}
                        onClick={() => onDefaultActionButton && onDefaultActionButton()}
                        className={'small'}
                    >
                        {defaultActionText || 'OK'}
                    </button>
                )
            }
        </div>
    );
}
