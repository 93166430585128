import * as React from 'react';
import {
    Table
} from 'reactstrap';

import '../Main.scss';

import { Theme, ThemeProvider } from '@mui/material/styles';

import { CreateStdTheme } from '../../Utils/Style';

import { BrandConfig } from '../../Store/Tenant';

import SortDescendingIcon from '@mui/icons-material/ArrowDropDownOutlined';
import SortAscendingIcon from '@mui/icons-material/ArrowDropUpOutlined';

export type SortableTableSortType = 'ascending' | 'descending' | 'none';

export type SortableTableSortInformation = {
    sortColumn: number;
    sortType: SortableTableSortType;
}

export type SortableTableColumn<T> = {
    headerTitle: string;
    defaultSortOrder: SortableTableSortType;
    compare: (compare1: T, compare2: T) => number;
    getRowData: (rowData: T) => React.ReactElement;
};

type Props<T> = {
    brandConfig: BrandConfig;
    columns: SortableTableColumn<T>[];
    data: T[];
    sortInformation?: SortableTableSortInformation;
    overrideSort?: (sortFunc: (data: T[]) => T[]) => T[];
    onSort?: (sortedResults: T[]) => void;
    onSortParametersChanged?: (sortParameters: SortableTableSortInformation) => void;
    addRowContent?: (rowData: T) => React.ReactElement | undefined;
}

export const SortableTable = <T extends any>(props: Props<T>): React.ReactElement => {
    const {
        addRowContent,
        columns,
        data,
        onSort,
        overrideSort,
        onSortParametersChanged,
        sortInformation
    } = props;

    const getFirstSortableColumn = (): number => {
        return columns.findIndex(column => column.defaultSortOrder !== 'none');
    }

    const [sortInformationInternal, setSortInformationInternal] = React.useState<SortableTableSortInformation>(() => {
        const sortColumn = getFirstSortableColumn();
        return {
            sortColumn: sortColumn,
            sortType: sortColumn !== -1 ? columns[sortColumn].defaultSortOrder : 'ascending',
        }
    });

    const executeSort = (
        sortType: SortableTableSortType,
        sortFunc: (c1: T, c2: T) => number
    ): T[] => {
        if (!!overrideSort) {
            return overrideSort((data) => {
                return data.sort((c1, c2) => {
                    const multiplier = (sortType === 'ascending') ? 1 : -1;
                    return sortFunc(c1, c2) * multiplier;
                });
            });
        } else {
            return data.sort((c1, c2) => {
                const multiplier = (sortType === 'ascending') ? 1 : -1;
                return sortFunc(c1, c2) * multiplier;
            });
        }
    }

    const sortOnCurrentColumn = (sortType: SortableTableSortType): void => {
        const sortFunc = columns[useSortInformation.sortColumn].compare;
        const sortedData = executeSort(sortType, sortFunc);
        onSort && onSort(sortedData);
    }

    React.useEffect(() => {
        if (!sortInformation) {
            sortOnCurrentColumn(sortInformationInternal.sortType);
        }
        // I want this to execute equivalent to componentDidMount so this is appropriate
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (!sortInformation) {
            const sortColumn = getFirstSortableColumn();

            setSortInformationInternal({
                ...sortInformationInternal,
                sortColumn,
                sortType: sortColumn !== -1 ? columns[sortColumn].defaultSortOrder : 'descending',
            });
        }
        // getFirstSortableColumn should not be moved in here and it's not going to effect the 
        // execution of this effect.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const changeSortParameters = (parameters: SortableTableSortInformation): void => {
        if (!!onSortParametersChanged) {
            onSortParametersChanged(parameters);
        } else {
            setSortInformationInternal(parameters);
        }
    }

    const [muiTheme] = React.useState<Theme>(() => {
        return CreateStdTheme(props.brandConfig);
    });

    const useSortInformation = React.useMemo(() => {
        return !!sortInformation ? sortInformation : sortInformationInternal;
    }, [sortInformation, sortInformationInternal])

    const toggleSort = (): void => {
        const sortType = useSortInformation.sortType === 'ascending' ? 'descending' : 'ascending';

        sortOnCurrentColumn(sortType);

        changeSortParameters({
            ...useSortInformation,
            sortType,
        });
    }

    const setSortColumn = (index: number): void => {
        const sortFunc = columns[index].compare;
        const sortOrder = columns[index].defaultSortOrder;

        const sortedSubmissions = executeSort(sortOrder, sortFunc);

        onSort && onSort(sortedSubmissions);

        changeSortParameters({
            sortColumn: index,
            sortType: sortOrder,
        });
    }

    const getAdditionalRowContent = (item: T, rowIndex: number): React.ReactElement | undefined => {
        if (!addRowContent) {
            return undefined;
        }
        const content = addRowContent(item);
        if (!content) {
            return undefined;
        }
        return (
            <tr key={`tablerowadditionalcontent${rowIndex}`}>
                <td colSpan={columns.length}>{content}</td>
            </tr>
        );
    }

    return (
        <ThemeProvider theme={muiTheme}>
            <Table key={'sortableTable'} className={'sortable-table'}>
                <thead>
                    <tr key={'headerrow'}>
                        {
                            columns.map((column, index) => {
                                if (useSortInformation.sortColumn === index) {
                                    return (
                                        <th
                                            className={'sortColumn sortable'}
                                            onClick={() => toggleSort()}
                                            key={`${column.headerTitle}${index}`}
                                        >
                                            <div>
                                                {column.headerTitle}
                                                {useSortInformation.sortType === 'descending' && (
                                                    <SortDescendingIcon
                                                        color={'primary'}
                                                        className={'sort-icon'}
                                                    />
                                                )}
                                                {useSortInformation.sortType === 'ascending' && (
                                                    <SortAscendingIcon
                                                        color={'primary'}
                                                        className={'sort-icon'}
                                                    />
                                                )}
                                            </div>
                                        </th>
                                    );
                                } else if (column.defaultSortOrder !== 'none') {
                                    return (
                                        <th
                                            className={'sortable'}
                                            onClick={() => setSortColumn(index)}
                                            key={`${column.headerTitle}${index}`}
                                        >
                                            <div>
                                                <span>
                                                    {column.headerTitle}
                                                </span>
                                            </div>
                                        </th>
                                    );
                                } else {
                                    return (
                                        <th
                                            key={`${column.headerTitle}${index}`}
                                        >
                                            <div>
                                                <span>
                                                    {column.headerTitle}
                                                </span>
                                            </div>
                                        </th>
                                    );
                                }
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((item, index) => (
                            <React.Fragment key={`tableRowContainer${index}`}>
                                <tr key={`tablerow${index}`}>
                                    {
                                        columns.map((columnInfo, columnIndex) =>
                                            (columnIndex === 0) ?
                                                (<th key={`sortableTableItem_row${index}column${columnIndex}`} scope={'row'}>{columnInfo.getRowData(item)}</th>) :
                                                (<td key={`sortableTableItem_row${index}column${columnIndex}`}>{columnInfo.getRowData(item)}</td>)
                                        )
                                    }
                                </tr>
                                {
                                    getAdditionalRowContent(item, index)
                                }
                            </React.Fragment>
                        ))
                    }
                </tbody>
            </Table>
        </ThemeProvider>
    );
}
