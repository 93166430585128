import DOMPurify from 'dompurify';

/**
 * This is a fairly simplistic test to see whether an email is well-formed.  It
 * does not mean the email is valid and there are probably corner cases it allows 
 * through.
 * 
 * @param email  The string to test
 * @returns true if email is a well-formed email address.  This means that it is
 * basically a string of the form '<email>@<host>.<tld>'
 */
export function TestEmail(email: string): boolean {
    const re = RegExp('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$');
    return re.test(email);
}

/**
 * Determines if a string contains any potentially unsafe content such as javascript
 * 
 * @parameter inputString is the value to be tested
 * @returns true if inputString should not be sent to the database for storage
 */

export function InputStringContainsDangerousContent(inputString: string): boolean {
    const sanitizedString = DOMPurify.sanitize(inputString);

    return (sanitizedString !== inputString);
}

export function GetUnsafeContentMessage(inputStringName: string): string {
    return `The ${inputStringName} you entered contains potentially unsafe content`;
}

export function GetRenderingContext(fontPropertyName: string): CanvasRenderingContext2D | null {
    const r = document.querySelector(':root') as HTMLElement;
    const computedStyle = window.getComputedStyle(r);

    let primaryFont = computedStyle.getPropertyValue('--font-family-primary');
    let fontSize = computedStyle.getPropertyValue(fontPropertyName);
    if (!primaryFont) {
        primaryFont = 'sofia-pro, sans-serif';
    }
    if (!fontSize) {
        fontSize = '12px';
    }

    const fontDescriptor = `bold ${fontSize} ${primaryFont}`;
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    if (!!context) {
        context.font = fontDescriptor;
    }
    return context;
}