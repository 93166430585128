import * as React from 'react';
import {
    Container,
} from 'reactstrap';

import { BasicUserErrorMessages } from './BasicUserDetails';

import { TestEmail } from '../../../Utils/FormUtils';

import {
    CreateUserRequest,
    TenantUserMetadataSetting,
} from '../../../Models/Api/strongbox.financialportal';

import { EditRequiredUserInfo, CollectUserInfoPermissionsProps } from './EditRequiredUserInfo';
import { EditUserMetadata } from './EditUserMetadata';
import { AddUsersMode, AddUsersMetaDataItem } from './CommonUser';
import { AddUserInfoButtonsRow } from './AddUserInfoButtonsRow';

type Props = {
    user: CreateUserRequest;
    errorMessages?: BasicUserErrorMessages;
    onUpdateUser: (user: CreateUserRequest) => void;
    onInviteUser: () => void;
    permissionProps?: CollectUserInfoPermissionsProps;
    emailDomainsForTenant: string[];
    userMetadataSettingsForTenant: TenantUserMetadataSetting[];
    editMode: AddUsersMode;
    onChangeEditMode: (newEditMode: AddUsersMode) => void;
    metadataValues: Map<string, AddUsersMetaDataItem>;   // keyed on metadata value name
    onMetadataValueChange: (name: string, value: string) => void;
}

export const CollectNewUserInfo: React.FC<Props> = (props): React.ReactElement => {
    const {
        emailDomainsForTenant,
        errorMessages,
        onInviteUser,
        onUpdateUser,
        permissionProps,
        user,
        userMetadataSettingsForTenant,
        editMode,
        onChangeEditMode,
        metadataValues,
        onMetadataValueChange,
    } = props;

    const [emailErrorMessage, setEmailErrorMessage] = React.useState<boolean>(false);
    const [emailWellFormed, setEmailWellFormed] = React.useState<boolean>(false);
    const [emailValid, setEmailValid] = React.useState<boolean>(false);

    const focusRef = React.useRef<any>();

    const hasInvalidMetadataContent = React.useMemo(() => {
        let result = false;

        metadataValues.forEach(v => {
            if (!v.contentIsValid) {
                result = true;
            }
        });

        return result;
    }, [metadataValues]);

    React.useEffect(() => {
        const emailParts = user.emailAddress.split('@');

        if (!TestEmail(user.emailAddress)) {
            setEmailErrorMessage(emailParts.length > 1);  // Show the error message when there's an '@' character and either not totally well formed or not a valid domain
            setEmailWellFormed(false);
            setEmailValid(false);
            return;
        }

        setEmailWellFormed(true);

        let emailIsValid = false;

        // Shouldn't really be possible to be less than 2 as TestEmail should handle that case
        if (emailParts.length >= 2) {
            const emailDomain = emailParts[1].trim();

            emailIsValid = !!emailDomainsForTenant.find(domain => domain.toLowerCase() === emailDomain.toLowerCase());
            setEmailErrorMessage(!emailIsValid);
        }
        setEmailValid(emailIsValid)

        // users is the only dependency that really matters here.
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [user]);

    const fieldsReady = React.useMemo(() => {
        return !!user.firstName && !!user.lastName && emailValid && !hasInvalidMetadataContent;
    }, [hasInvalidMetadataContent, user, emailValid])

    return (
        <Container className={'edit-user-info-region'}>
            {editMode === 'editrequired' && (
                <EditRequiredUserInfo
                    user={user}
                    errorMessages={errorMessages}
                    onUpdateUser={onUpdateUser}
                    permissionProps={permissionProps}
                    emailDomainsForTenant={emailDomainsForTenant}
                    updateUserProps={onUpdateUser}
                    emailWellFormed={emailWellFormed}
                    emailErrorMessage={emailErrorMessage}
                    focusRef={focusRef}
                />
            )}
            {editMode === 'editmetadata' && (
                <EditUserMetadata
                    userMetadataSettingsForTenant={userMetadataSettingsForTenant}
                    metadataValues={metadataValues}
                    onValueChange={onMetadataValueChange}
                />
            )}

            <AddUserInfoButtonsRow
                onInviteUser={() => {
                    focusRef && focusRef.current && focusRef.current!.focus();
                    onInviteUser();
                }}
                addEnabled={fieldsReady}
                allowMetadata={!!userMetadataSettingsForTenant && (userMetadataSettingsForTenant.length > 0)}
                invalidUserMetadata={hasInvalidMetadataContent}
                changeEditMode={(newMode) => onChangeEditMode(newMode)}
                mode={editMode}
            />
        </Container>
    );
}
