import * as React from 'react';

import '../../Main.scss';
import './FinancialStatements.scss';

import {
    Row,
    Col
} from 'reactstrap';

import { FSModels } from '@finagraph/financial-statement-editor';

import { FinancialStatementRenderData } from './UtilTypes';

/*
import BoldIcon from '@mui/icons-material/FormatBold';
import ItalicIcon from '@mui/icons-material/FormatItalic';
import UnderlineIcon from '@mui/icons-material/FormatUnderlined';
*/

import {
    DangerousInputFormChildProps,
    PotentiallyDangerousInput,
    DangerousInput
} from '../../DangerousInputForm';

export const DangerousInputItems: DangerousInput[] = [
    {
        fieldId: 'name-style-editor-footer-input',
        formDataName: 'footerText'
    },
    {
        fieldId: 'name-style-editor-header-input',
        formDataName: 'headerText'
    },
    {
        fieldId: 'name-style-editor-caption-input',
        formDataName: 'headerText'
    }
]

export type NameAndStyleEditorInputFields = {
    headerText: string;
    footerText?: string;
}

type Props = {
    formatting: FSModels.CaptionFormatting;
    lineItem: FSModels.FinancialStatementLineItemViewModel;
    uniqueId: string;
    formData: NameAndStyleEditorInputFields;
    onCancel: () => void;
    onContentChange: (newHeader: string, newFooter: string | undefined) => void;
    rightAlign: boolean;
    renderData: FinancialStatementRenderData;
    financialStatement: FSModels.FinancialStatementViewModel;
    dangerousInputFormProps: DangerousInputFormChildProps;
}

export const NameAndStyleEditor: React.FC<Props> = (props): React.ReactElement => {
    const {
        formData,
        uniqueId,
        onContentChange,
        dangerousInputFormProps
    } = props;

    const [editHeaderText, setEditHeaderText] = React.useState<string>(formData.headerText);
    const [editFooterText, setEditFooterText] = React.useState<string | undefined>(formData.footerText);
    const [needsFocus, setNeedsFocus] = React.useState<boolean>(true);
    /*
    const [formatStyle, setFormatStyle] = React.useState<FSModels.CaptionFormatting>(formatting);
    const [selectedStyleId, setSelectedStyleId] = React.useState<string | undefined>(
        !!lineItem && lineItem.captionStyleClasses.length > 0
            ? lineItem.captionStyleClasses[0]
            : undefined
    );
    const [styleSelectionDropped, setStyleSelectionDropped] = React.useState<boolean>(false);
    */

    const [deltaHeaderFooter, setDeltaHeaderFooter] = React.useState<{ before: string; after: string } | undefined>(undefined);

    const controlIds: {
        footerInputId: string;
        headerInputId: string;
        captionInputId: string;
        boldButtonId: string;
        italicButtonId: string;
        underlineButtonId: string;
        styleNameDropdownId: string;
        containerId: string;
    } = React.useMemo(() => {
        return {
            footerInputId: `name-style-editor-footer-input`,
            headerInputId: `name-style-editor-header-input`,
            captionInputId: `name-style-editor-caption-input`,
            boldButtonId: `name-style-editor-bold-button-${uniqueId}`,
            italicButtonId: `name-style-editor-italic-button-${uniqueId}`,
            underlineButtonId: `name-style-editor-underline-button-${uniqueId}`,
            styleNameDropdownId: `name-style-editor-style-name-dropdown-${uniqueId}`,
            containerId: `name-style-editor-${uniqueId}`
        }
    }, [uniqueId]);

    React.useEffect(() => {
        if (editFooterText !== undefined) {
            // If we found the header in the footer we should be able to take the components in front of and around
            // header and use those for auto-populating a new version of the footer when the header changes.
            // If we find some stuff before and optionally some stuff after we'll use it.  If it's more complicated
            // than that we'll punt.

            const footerComponents = editFooterText.split(editHeaderText);
            if ((footerComponents.length > 0) && (footerComponents.length < 3)) {
                const iHeader = editFooterText.indexOf(editHeaderText);

                let before = footerComponents[0];
                let after = '';

                // Determine if the header shows up at the beginning or the end.  Could conceivably 
                // be at the end.  So if we found it starting at index 0 then it'd necessarily be at 
                // the end.
                if (iHeader === 0) {
                    before = '';
                    after = '';
                    footerComponents.forEach(s => after += s);
                } else {
                    if (footerComponents.length > 1) {
                        after = footerComponents[1];
                    }
                }

                setDeltaHeaderFooter({
                    before,
                    after
                });
            }
        }
        // This is in fact the dependency list I want,just want it to run once as in componentDidMount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (needsFocus) {
            const input = document.getElementById(controlIds.headerInputId);
            if (input !== null) {
                input.focus();
                if (input instanceof (HTMLInputElement)) {
                    // Second parameter to setSelectionRange is the index of the character after the last character.
                    // Selecting the entire string, this is the length.
                    input.setSelectionRange(0, formData.headerText.length);
                }
            }
            setNeedsFocus(false);
        }
        // This is in fact the dependency list I want
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needsFocus]);

    const headerChanged = (newValue: string): void => {
        setEditHeaderText(newValue);

        let newFooter: string | undefined = undefined;

        if (!!deltaHeaderFooter) {
            newFooter = `${deltaHeaderFooter.before}${newValue}${deltaHeaderFooter.after}`
            setEditFooterText(newFooter);
        }

        onContentChange(newValue, newFooter);
    }

    return (
        <React.Fragment>
            {!!formData.footerText && (
                <Row>
                    <Col
                        xs={2}
                        style={{
                            paddingTop: '10px'
                        }}
                    >
                        <label htmlFor={controlIds.headerInputId}>Header:</label>
                    </Col>
                    <Col>
                        <PotentiallyDangerousInput
                            id={controlIds.headerInputId}
                            name={controlIds.headerInputId}
                            value={editHeaderText}
                            nameForInvalidMsg={'Header'}
                            clearFieldWithDangerousContent={dangerousInputFormProps.clearFieldWithDangerousContent}
                            fieldWithDangerousContent={dangerousInputFormProps.fieldWithDangerousContent}
                            updateField={(newValue) => {
                                headerChanged(newValue);
                            }}
                        />
                    </Col>
                </Row>
            )}
            {!formData.footerText && (
                <Row>
                    <Col
                        xs={2}
                        style={{
                            paddingTop: '10px'
                        }}
                    >
                        <label htmlFor={controlIds.captionInputId}>Caption:</label>
                    </Col>
                    <Col>
                        <PotentiallyDangerousInput
                            id={controlIds.headerInputId}
                            name={controlIds.headerInputId}
                            value={editHeaderText}
                            nameForInvalidMsg={'Caption'}
                            clearFieldWithDangerousContent={dangerousInputFormProps.clearFieldWithDangerousContent}
                            fieldWithDangerousContent={dangerousInputFormProps.fieldWithDangerousContent}
                            updateField={(newValue) => {
                                headerChanged(newValue);
                            }}
                        />
                    </Col>
                </Row>
            )}
            {
                !!editFooterText && (
                    <Row>
                        <Col
                            xs={2}
                            style={{
                                paddingTop: '10px'
                            }}
                        >
                            <label htmlFor={controlIds.footerInputId}>Footer:</label>
                        </Col>
                        <Col>
                            <PotentiallyDangerousInput
                                id={controlIds.footerInputId}
                                name={controlIds.footerInputId}
                                value={editFooterText}
                                nameForInvalidMsg={'Footer'}
                                clearFieldWithDangerousContent={dangerousInputFormProps.clearFieldWithDangerousContent}
                                fieldWithDangerousContent={dangerousInputFormProps.fieldWithDangerousContent}
                                updateField={(newValue) => {
                                    // If the footer is manually changed then we should no longer update
                                    // it automatically when the header changes.
                                    onContentChange(formData.headerText, newValue);
                                    setEditFooterText(newValue);
                                    setDeltaHeaderFooter(undefined);
                                }}
                            />
                        </Col>
                    </Row>
                )
            }
        </React.Fragment>
    );
}


/*
 * 
 * Just commenting this out for the time being.  Will be added back in the future.
 * 
            <Row
                style={{
                    marginTop: '15px'
                }}
            >
                <Col className={`style-button-container ${rightAlign ? 'style-button-container-right style-button-container-shifted' : ''}`}>
                    <Button
                        id={controlIds.boldButtonId}
                        className={`style-button${!!formatStyle.bold ? ' pressed' : ''}`}
                        onClick={() => {
                            const newFormatStyle = {
                                ...formatStyle,
                                bold: !!formatStyle.bold ? false : true,
                            }
                            setFormatStyle(newFormatStyle);
                        }}
                    >
                        <BoldIcon />
                    </Button>
                    <Button
                        id={controlIds.italicButtonId}
                        className={`style-button${!!formatStyle.italic ? ' pressed' : ''}`}
                        onClick={() => {
                            const newFormatStyle = {
                                ...formatStyle,
                                italic: !!formatStyle.italic ? false : true,
                            }
                            setFormatStyle(newFormatStyle);
                        }}
                    >
                        <ItalicIcon />
                    </Button>
                    <Button
                        id={controlIds.underlineButtonId}
                        className={`style-button right-margin-button ${(formatStyle.underlineStyle === FSModels.UnderlineStyle.Single || formatting.underlineStyle === FSModels.UnderlineStyle.Double) ? 'pressed' : ''}`}
                        onClick={() => {
                            const newFormatStyle = {
                                ...formatStyle
                            };

                            newFormatStyle.underlineStyle = !!formatStyle.underlineStyle ? undefined : FSModels.UnderlineStyle.Single;

                            setFormatStyle(newFormatStyle);
                        }}
                    >
                        <UnderlineIcon />
                    </Button>
                    <Label htmlFor={controlIds.styleNameDropdownId}>Style:</Label>
                    <Dropdown
                        id={controlIds.styleNameDropdownId}
                        value={selectedStyleId}
                        isOpen={styleSelectionDropped}
                        toggle={() => setStyleSelectionDropped(!styleSelectionDropped)}
                    >
                        <DropdownToggle caret>{selectedStyleId}</DropdownToggle>
                        <DropdownMenu>
                            {financialStatement.captionStyleClasses.map((s, i) => (
                                <DropdownItem
                                    key={`name-style-editor-style-menu-item-${i}`}
                                    value={s.nameId}
                                    onClick={() => { setSelectedStyleId(s.nameId) }}
                                >
                                    {s.nameId}
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    </Dropdown>
                </Col>
            </Row>
 */