import * as React from 'react';

import MoreOptionsIcon from '@mui/icons-material/MoreVert';
import AddSubsectionIcon from '@mui/icons-material/AddCircleOutline';
import SendToIcon from '@mui/icons-material/LowPriority';
import IncrementSortOrderIcon from '@mui/icons-material/ArrowDownward';
import DecrementSortOrderIcon from '@mui/icons-material/ArrowUpward';

import {
    Tooltip
} from '@mui/material';

import { LineItemHasMoreOptions } from './LineItemMoreOptions';

import { SanitizeDOMSelector } from '../../../Utils/DOMUtils';

import { FSModels } from '@finagraph/financial-statement-editor';

import { fsSectionPresentationName, fsSubSectionPresentationName } from './FSConstants';

interface MoreActionsMenuState {
    anchorElement: Element,
    lineItem: FSModels.FinancialStatementLineItemViewModel | undefined;
}

type Props = {
    lineItem: FSModels.FinancialStatementLineItemViewModel;
    detailAccountDescriptor: FSModels.DetailAccountDescriptor | undefined;
    onMoveLineItemToNewSection: (lineItem: FSModels.FinancialStatementLineItemViewModel) => void;
    onAddSubsection: (lineItem: FSModels.FinancialStatementLineItemViewModel) => void;
    onLineItemMoreOptions: (lineItem: FSModels.FinancialStatementLineItemViewModel, targetId: string) => void;
    onDecrementLineItemSortOrder: (lineItem: FSModels.FinancialStatementLineItemViewModel) => void;
    onIncrementLineItemSortOrder: (lineItem: FSModels.FinancialStatementLineItemViewModel) => void;
    showTooltips: boolean;
    isMultiSelection: boolean;
}

export const FinancialStatementLineItemActions: React.FC<Props> = (props): React.ReactElement => {
    const {
        lineItem,
        detailAccountDescriptor,
        onDecrementLineItemSortOrder,
        onIncrementLineItemSortOrder,
        onMoveLineItemToNewSection,
        onAddSubsection,
        onLineItemMoreOptions,
        showTooltips,
        isMultiSelection,
    } = props;

    const hasMoreOptions = React.useMemo(() => {
        return LineItemHasMoreOptions(lineItem);
    }, [lineItem])

    const noun = lineItem.lineItemType === FSModels.FinancialStatementLineItemType.DetailAccount
        ? 'Detail Account'
        : fsSectionPresentationName;

    const allowMove = React.useMemo(() => {
        const sectionId = lineItem.sectionId.toLowerCase();

        return ((sectionId !== 'cash') && (sectionId !== 'accountsreceivable') && (sectionId !== 'accountspayable'));
    }, [lineItem]);

    return (
        <div className={'button-bar'}>
            {lineItem.canIncrementSortOrder && !isMultiSelection && (
                <Tooltip title={showTooltips ? `Move this ${noun} down` : ''}>
                    <button
                        onClick={(e) => onIncrementLineItemSortOrder(lineItem)}
                    >
                        <IncrementSortOrderIcon />
                    </button>
                </Tooltip>
            )}
            {(!lineItem.canIncrementSortOrder || isMultiSelection) && (
                <span className={'empty-action'}/>
            )}            
            {lineItem.canDecrementSortOrder &&!isMultiSelection && (
                <Tooltip title={showTooltips ? `Move this ${noun} up` : ''}>
                    <button
                        onClick={(e) => onDecrementLineItemSortOrder(lineItem)}
                    >
                        <DecrementSortOrderIcon />
                    </button>
                </Tooltip>
            )}
            {(!lineItem.canDecrementSortOrder || isMultiSelection) && (
                <span className={'empty-action'}/>
            )}
            {allowMove && (
                <Tooltip title={showTooltips ? `Send ${isMultiSelection ? 'these line items' : 'this line item'} to another location in the report` : ''}>
                    <button
                        onClick={(e) => onMoveLineItemToNewSection(lineItem)}
                    >
                        <SendToIcon />
                    </button>
                </Tooltip>
            )}
            {lineItem.canAddSubsection && !isMultiSelection && (
                <Tooltip title={showTooltips ? `Add ${fsSubSectionPresentationName.toLowerCase()}` : ''}>
                    <button
                        onClick={(e) => onAddSubsection(lineItem)}
                    >
                        <AddSubsectionIcon />
                    </button>
                </Tooltip>
            )}
            {(!lineItem.canAddSubsection || isMultiSelection) && (
                <span className={'empty-action'}/>
            )}
            {hasMoreOptions && !isMultiSelection &&  (
                <React.Fragment>
                    <Tooltip title={showTooltips ? 'More Options' : ''}>
                        <button
                            id={`financial-statement-line-item-more-options-${SanitizeDOMSelector(lineItem.id)}`}
                            onClick={e => onLineItemMoreOptions(lineItem, `financial-statement-line-item-more-options-${SanitizeDOMSelector(lineItem.id)}`)}
                        >
                            <MoreOptionsIcon/>
                        </button>
                    </Tooltip>
                </React.Fragment>
            )}
            {(!hasMoreOptions || isMultiSelection) && (
                <span className={'empty-action'} />
            )}
            {(detailAccountDescriptor?.isNewlyReferenced ?? false) && (
                <Tooltip title={showTooltips ? 'This detail account didn\'t exist, or wasn\'t referenced, the last time you connected this company' : ''}>
                    <span className={'status-label status-label-green always-visible-item'}>New</span>
                </Tooltip>
            )}
            {!(detailAccountDescriptor?.isNewlyReferenced ?? false) && (
                <span className={'status-label-empty'} />
            )}
        </div>
    )
}
