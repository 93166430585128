// Some useful information:  This code relies on window.analytics existing.  Window.analytics is set in
// App.tsx in a function called InitializedAnalytics using settings passedd in through the cookie. Two factors go 
// into that potentially not being set. The first is the segment feature being enabled in appsettings.json (or
// appsettings.development.json). Make sure that "FeatureEnablement:SegmentAnalytics:Enabled" is set to true.
//
// Second is that the segment analytics writekey also needs to be set.  The actual value is found in 
// the configuration settings on the segment site.  The value needs to be present in appsettings.json under 
// "FeatureEnablement:SegmentAnalytics:WriteKey"

type IdentifyTraits = {
    name?: string;
    id?: string;
};

type SegmentAnalytics = {
    identify(accountId: string, traits?: IdentifyTraits): unknown;
    page(category?: string, name?: string): unknown;
    track(eventName: string, properties?: any): unknown;
};

type AugmentedWindow = Window & typeof globalThis & {
    analytics: SegmentAnalytics;
};
const augmentedWindow = window as AugmentedWindow;

export const SegmentAnalytics = {
    identifyUser: (accountId: string, traits?: IdentifyTraits): void => {
        if (augmentedWindow.analytics && augmentedWindow.analytics.identify) {
            augmentedWindow.analytics.identify(accountId, traits);
        }
    },

    track: (eventName: string, properties?: any): void => {
        if (augmentedWindow.analytics && augmentedWindow.analytics.track) {
            augmentedWindow.analytics.track(eventName, properties);
        }
    },

    trackPageView: (): void => {
        if (augmentedWindow.analytics && augmentedWindow.analytics.page) {
            augmentedWindow.analytics.page();
        }
    },
};
