import { RoleResponse } from '../../Models/Api/strongbox.financialportal';

export enum UserRolesActions {
    LoadRoles = 'USERROLES/LoadRoles',
    LoadRolesComplete = 'USERROLES/LoadRolesComplete',
}

export interface ILoadRolesAction {
    readonly type: UserRolesActions.LoadRoles;
}

export interface ILoadRolesActionComplete {
    readonly type: UserRolesActions.LoadRolesComplete;
    readonly roles: RoleResponse[];
}

export type KnownAction =
    ILoadRolesAction | 
    ILoadRolesActionComplete;
