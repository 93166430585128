import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ApplicationState } from '../Store';
import { GetBasicSettings } from '../Store/Tenant';

import { DefaultTOULink } from '../Utils/Constants';

import './Main.scss';

type InjectedReduxState = {
    TOULink: string;
};

type InjectedActionCreators = {};

type StrongboxTOUProps = {
    className?: string;
};

type Props = InjectedReduxState & InjectedActionCreators & StrongboxTOUProps;


const StrongboxTOUComponent: React.FC<Props> = (props): React.ReactElement => {
    return (
        <div className={props.className}>
            <span>By using this site you agree to the <a className={'normal-link-color'} href={props.TOULink} rel={'noopener noreferrer'} target="_blank">Strongbox Terms of Service.</a></span>
        </div>
    )
}

export const StrongboxTOU = connect<InjectedReduxState, InjectedActionCreators, StrongboxTOUProps, ApplicationState>(
    (appState: ApplicationState) => {
        const basicSettings = GetBasicSettings(appState);

        const result = {
            TOULink: (!!basicSettings && basicSettings.TOULink) || DefaultTOULink
        };

        return result;
    },
    dispatch => bindActionCreators(
        {
        },
        dispatch
    )
)(StrongboxTOUComponent);
