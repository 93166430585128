import * as React from 'react';

import './RiskAnalysis.scss';

import {
    Col,
    Container,
    Row
} from 'reactstrap'

import { RiskCalcResponseGraph } from './RiskCalcResponseGraph';
import { RiskCalcInputs } from './RiskCalcInputs';
import { RiskCalcResponseDetails } from './RiskCalcResponseDetails';
import { RiskCalcResponse } from '../../../Models/Api/strongbox.financialportal';

type Props = {
    riskCalcResponse: RiskCalcResponse;
};

export const RiskCalcResults: React.FC<Props> = (props): React.ReactElement => {
    const {
        riskCalcResponse
    } = props;

    const inputsPrompt = React.useMemo(() => {
        return ['Financial Statement Inputs:', ''];
    }, [])

    return (
        <Container fluid>
            <Row className={'fin-risk-result-row'}>
                <Col className={'fin-risk-result-col'} xs={{ size: 10 }} lg={{ size: 6 }} key={'risk-calc-results-inputs-col'}>
                    <h2>{inputsPrompt[0]}</h2>
                    <h4>{inputsPrompt[1]}</h4>
                    <RiskCalcInputs
                        riskCalcResponse={riskCalcResponse}
                    />
                </Col>
                <Col className={'fin-risk-result-col'} xs={{ size: 10 }} lg={{ size: 6 }} key={'risk-calc-results-prob-of-default-col'}>
                    <h2>% probability of default:</h2>
                    <RiskCalcResponseGraph
                        riskCalcResponse={riskCalcResponse}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} key={'risk-calc-results-graph-col'}>
                    <RiskCalcResponseDetails
                        riskCalcResponse={riskCalcResponse}
                    />
                </Col>
            </Row>
        </Container>
    );
}
