import {
    ISetBorrowerId,
    ISetBorrowerSubmissionId,
    BorrowerActions,
    ISetBorrowerAnonymousState,
    ISetAcceptedBusinessInfo,
} from './Actions';

import { EntitySafeDetailResponse } from '../../Models/Api/strongbox.financialportal';

function SetBorrowerId(id: string, details?: EntitySafeDetailResponse): ISetBorrowerId {
    return {
        type: BorrowerActions.BorrowerSetId,
        details,
        id,
    };
}

function SetBorrowerSubmissionId(id: string): ISetBorrowerSubmissionId {
    return {
        type: BorrowerActions.BorrowerSetSubmissionId,
        id,
    };
}

function SetBorrowerAnonymous(anonymous: boolean): ISetBorrowerAnonymousState {
    return {
        type: BorrowerActions.BorrowerAnonymousState,
        anonymous,
    };
}

function SetBorrowerAcceptedBusinessInfo(name: string, email: string): ISetAcceptedBusinessInfo {
    return {
        type: BorrowerActions.BorrowerSetAcceptedBusinessInfo,
        businessName: name,
        businessEmail: email,
    };
}

export const BorrowerActionCreators = {
    SetBorrowerAnonymous,
    SetBorrowerId,
    SetBorrowerSubmissionId,
    SetBorrowerAcceptedBusinessInfo,
};
