export function ExtractUrlParameters(path: string, setStateValue: (key: string, value: string) => void): void {
    const pathComponents = path.split('?');
    if (pathComponents.length >= 2) {
        const parameters = pathComponents[1].split('&');
        parameters.forEach(parameter => {
            const kvp = parameter.split('=');
            if (kvp.length === 2) {
                setStateValue(kvp[0].toLowerCase(), kvp[1]);
            }
        });
    }
}

export function CombinePathComponents(...components: string[]): string {
    let result = '';
    const numComponents = components.length;

    components.forEach((pathComponent, componentIndex) => {
        let toAppend = '/';

        if (componentIndex !== numComponents - 1) {
            pathComponent.trimRight();
            let iEnd = pathComponent.length - 1;
            while ((iEnd >= 0) && (pathComponent[iEnd] === '/')) {
                iEnd--;
            }
            // substring goes up to but does not include the end index so we add 1 because we are on the first character
            // from the end that we want.   It could theoretically be 0 in which case you'd get an empty string.
            toAppend = pathComponent.substring(0, iEnd + 1);
        } else {
            toAppend = pathComponent;
        }
        if ((componentIndex !== 0) && (toAppend[0] !== '/')) {
            result = result.concat('/');
        }
        result = result.concat(toAppend);
    });
    return result;
}

/*
 * Returns a full path relative to the current window for a specific relative path.
 *
 * @param {string} - relativePath is the relative path to navigatge to.  It may or may not contain a starting '/'
 *
 * For instance, if the current window is https://dev.strongbox.link:5000/businesses and you call this
 * function with 'login', you will receive as a result 'https://dev.strongbox.link:5000/login'.  If there
 * is no port, it will be 'https://dev.strongbox.link/login'.
 */

export function GetFullPathRelativeToCurrentWindow(relativePath: string): string {
    const localUrl = new URL(window.location.href);

    let localHost = `${localUrl.protocol}//${localUrl.hostname}`
    if (localUrl.port) {
        localHost += `:${localUrl.port}`;
    }

    let usePath = relativePath.trim();
    if (!usePath.startsWith('/')) {
        usePath = `/${usePath}`
    }

    return`${localHost}${usePath}`;
}
