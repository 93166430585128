import * as React from 'react';

import {
    Container,
    Col,
    Popover,
    PopoverBody,
    PopoverHeader,
    Row,
} from 'reactstrap';

import ReactHtmlParser from 'react-html-parser';

import { RoleResponse } from '../../../Models/Api/strongbox.financialportal';

import CloseIcon from '@mui/icons-material/Close';

export type PermissionsPopoverTarget = {
    target: string;
    role: RoleResponse;
}

type Props = {
    target: PermissionsPopoverTarget;
    onToggle: (on: boolean) => void;
}

export const PermissionsDetailPopover: React.FC<Props> = (props): React.ReactElement => {
    const {
        onToggle,
        target
    } = props;

    return (
        <div className={`control-region control-region-lender`}>
            <Popover
                placement={'right'}
                isOpen={true}
                className={'naics-popover'}
                target={target.target}
                key={'permissionDetailsPopover'}
                toggle={() => onToggle && onToggle(false)}
                trigger={'legacy'}
            >
                <PopoverHeader>
                    <div className={'portal-header-region spaced-row'}>
                        <span>
                            Permissions
                        </span>
                        <CloseIcon
                            color={'primary'}
                            className={'basic-list-icon actionable-icon'}
                            onClick={() => onToggle && onToggle(false)}
                            id={'closepopover'}
                        />
                    </div>
                </PopoverHeader>
                <PopoverBody className={'naics-body'}>
                    <Container fluid
                        className={'naics-primary-description'}
                        key={'naics-descriptions-container'}
                    >
                        <Row>
                            <Col>
                                {
                                    // warning! react-html-parser is vulnerable to XSS attacks. usage of react-html-parser deemed safe here because the html description of roles is not user defined
                                    ReactHtmlParser(target.role.description || '<p>No description provided</p>')
                                }
                            </Col>
                        </Row>
                    </Container>
                </PopoverBody>
            </Popover>
        </div>
    );
}
