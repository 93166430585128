import * as React from 'react';
import { connect } from 'react-redux';
import {
    Button,
    Col,
    Container,
    Modal,
    ModalBody,
    ModalFooter,
    Row
} from 'reactstrap';

import '../../Main.scss';

import { ApplicationState } from '../../../Store';
import {
    GetUserMetadataSettings,
} from '../../../Store/Tenant';

import { DetailedUserResponse, RoleResponse, TenantUserMetadataSetting, UserMetadataItem } from '../../../Models/Api/strongbox.financialportal';

import { GetUnsafeContentMessage, InputStringContainsDangerousContent } from '../../../Utils/FormUtils';

import { UserDetails } from './UserDetails';
import { AddUsersMetaDataItem, IsMetadataInputValid } from './CommonUser';

type InjectedReduxState = {
    userMetadataSettingsForTenant: TenantUserMetadataSetting[];
};

type InjectedActionCreators = {}

type EditUserModalProps = {
    onDismissModal: (operation: 'save' | 'cancel', user?: DetailedUserResponse) => void;
    user: DetailedUserResponse;
    availableRoles: RoleResponse[];
    loggedInUserExternalId: string;
};

type Props = EditUserModalProps & InjectedActionCreators & InjectedReduxState;

const buttonMargin = '15px';

export const EditUserModalComponent: React.FC<Props> = (props): React.ReactElement => {
    const {
        onDismissModal,
        user,
        availableRoles,
        loggedInUserExternalId,
        userMetadataSettingsForTenant,
    } = props;

    const [userUnderEdit, setUserUnderEdit] = React.useState<DetailedUserResponse>({ ...user });
    const [inputErrorMessages, setInputErrorMessages] = React.useState<{ firstName?: string, lastName?: string }>({});
    const [userMetadata, setUserMetadata] = React.useState<Map<string, AddUsersMetaDataItem>>(new Map<string, AddUsersMetaDataItem>());
    const [userFieldsValid, setUserFieldsValid] = React.useState<boolean>(true);

    React.useEffect(() => {
        const result = new Map<string, AddUsersMetaDataItem>();

        if (!user.metadata) {
            setUserMetadata(result);
        }

        user.metadata.forEach(m => {
            result.set(m.name, {
                metaDataItem: {
                    ...m
                },
                contentIsValid: true
            });
        });

        setUserMetadata(result);

        // I want this to execute equivalent to componentDidMount so this is appropriate
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const metadataValueChanged = (name: string, newValue: string): void => {
        const setting = userMetadataSettingsForTenant.find(m => m.name === name);

        if (!setting) {
            console.error(`Unexpected error in EditUserModal:metadataValueChanged, name {name} is not a valid setting`);
            return;
        }

        const contentIsValid = IsMetadataInputValid(newValue, setting.type);
        let existingItem = userMetadata.get(name);

        if (!existingItem) {
            existingItem = {
                metaDataItem: {
                    name: setting.name,
                    value: newValue,
                    type: setting.type,
                },
                contentIsValid: contentIsValid,
            }
        }

        const modifiedMetadata = new Map<string, AddUsersMetaDataItem>(userMetadata);

        modifiedMetadata.set(name, {
            metaDataItem: {
                name,
                value: newValue,
                type: existingItem.metaDataItem.type,
            },
            contentIsValid
        });

        setUserMetadata(modifiedMetadata);
    }

    const metadataIsValid = React.useMemo(() => {
        let result = true;

        userMetadata.forEach(m => {
            if (!m.contentIsValid) {
                result = false;
            }
        });

        return result;
    }, [userMetadata]);

    React.useEffect(() => {
        let result = true;
        let lastNameError: string | undefined = undefined;
        let firstNameError: string | undefined = undefined;

        if (!!userUnderEdit.firstName && InputStringContainsDangerousContent(userUnderEdit.firstName)) {
            firstNameError = GetUnsafeContentMessage('first name');
            result = false;
        }
        if (!!userUnderEdit.lastName && InputStringContainsDangerousContent(userUnderEdit.lastName)) {
            lastNameError = GetUnsafeContentMessage('last name');
            result = false;
        }
        setInputErrorMessages({
            firstName: firstNameError,
            lastName: lastNameError,
        });
        if (!(userUnderEdit.firstName && userUnderEdit.lastName)) {
            result = false;
        }

        setUserFieldsValid(result);
    }, [userUnderEdit]);

    return (
        <>
            <Modal
                className={`flight-modal`}
                isOpen={true}
                backdrop={'static'}
            >
                <ModalBody>
                    <Container fluid className={`basic-layout-container control-region control-region-lender`}>
                        <Row>
                            <Col width={12}>
                                <UserDetails
                                    errorMessages={{ lastName: inputErrorMessages.lastName, firstName: inputErrorMessages.firstName }}
                                    availableRoles={availableRoles}
                                    user={userUnderEdit}
                                    loggedInUserExternalId={loggedInUserExternalId}
                                    onUserChanged={(newValue: DetailedUserResponse) => {
                                        setUserUnderEdit(newValue);
                                    }}
                                    userMetadataSettingsForTenant={userMetadataSettingsForTenant}
                                    metadataValues={userMetadata}
                                    onMetadataValueChanged={metadataValueChanged}
                                />
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Row className={`button-row`}>
                        <Col xs={'auto'}>
                            <Button style={{marginRight: buttonMargin}} color="secondary" onClick={() => onDismissModal('cancel', undefined)}>Cancel</Button>
                            <Button
                                color="primary"
                                onClick={() => {
                                    const metadata: UserMetadataItem[] = [];

                                    userMetadata.forEach(m => metadata.push(m.metaDataItem));

                                    onDismissModal(
                                        'save',
                                        {
                                            ...userUnderEdit,
                                            metadata,
                                        }
                                    );
                                }}
                                disabled={!(metadataIsValid && userFieldsValid)}
                            >
                                Save changes
                            </Button>
                        </Col>
                    </Row>
                </ModalFooter>
            </Modal>
        </>
    )
}

export const EditUserModal = connect<InjectedReduxState, InjectedActionCreators, EditUserModalProps, ApplicationState>(
    (appState: ApplicationState) => {
        const result: InjectedReduxState = {
            userMetadataSettingsForTenant: GetUserMetadataSettings(appState),
        };

        return result;
    }
)(EditUserModalComponent);
