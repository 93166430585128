import { FetchMethod, fetchWrapper } from '../Utils/FetchWrapper';

import {
    CreatedSubmissionRequest,
    CreateSubmissionRequestOptions,
    SubmissionRequest
} from '../Models/Api/strongbox.financialportal';

import { PortalCreateSubmissionRequestOptions } from '../Models/PortalCreateSubmissionRequestOptions';
import { knownAccountingGroupIds } from '../Models/AccountingCollectionGroup';

import { LogMessage, LogException, SeverityLevel } from '../Utils/Logging';

export async function FetchSubmissionRequest(
    entityId: string,
    submissionRequestId: number,
): Promise<SubmissionRequest | undefined> {
    LogMessage(
        `FetchSubmissionRequest`,
        SeverityLevel.Information,
        {
            workspaceId: entityId,
            submissionRequestId
        }
    );

    const url = `/api/${entityId}/SubmissionRequests/${submissionRequestId}`;

    const result = await fetchWrapper(url,
        {
            method: FetchMethod.Get,
        },
        true,
        true
    );

    if (!result.ok) {
        LogException(
            `FetchSubmissionRequest failed`,
            new Error(`${result.statusText} ${result.status}`),
            {
                workspaceId: entityId,
                submissionRequestId
            }
        );
        throw new Error(`${result.statusText} ${result.status}`);
    }

    return await result.json();
}

export async function CreateSubmissionRequest(entityId: string, portalRequest: PortalCreateSubmissionRequestOptions | undefined): Promise<CreatedSubmissionRequest> {
    LogMessage(
        `CreateSubmissionRequest`,
        SeverityLevel.Information,
        {
            workspaceId: entityId,
            portalRequest,
        }
    );

    if (!entityId) {
        LogException(
            `CreateSubmissionRequest failed`,
            new Error('entityId required'),
            {
                workspaceId: entityId,
                portalRequest,
            }
        );
        throw new Error(`entityId required`);
    }

    let request: CreateSubmissionRequestOptions | undefined = undefined;

    if (!!portalRequest) {
        const txns = portalRequest.accountingCollectionGroups.get(knownAccountingGroupIds.transactions);
        const payables = portalRequest.accountingCollectionGroups.get(knownAccountingGroupIds.accountsPayable);
        const receivables = portalRequest.accountingCollectionGroups.get(knownAccountingGroupIds.accountsReceivable);
        const financialStatements = portalRequest.accountingCollectionGroups.get(knownAccountingGroupIds.financialStatements);

        if (!(txns && payables && receivables && financialStatements)) {
            request = undefined;
        } else {
            request = {
                basisOfAccountingPreference: portalRequest.basisOfAccountingPreference,
                mostRecentPeriodMonth: portalRequest.mostRecentPeriodMonth,
                mostRecentPeriodYear: portalRequest.mostRecentPeriodYear,
                mostRecentPeriodDay: portalRequest.mostRecentPeriodDay,
                anonymizeCustomersAndVendors: portalRequest.anonymizeCustomersAndVendors,
                provideUserCopy: portalRequest.provideUserCopy,
                allowUserUpload: portalRequest.allowUserUpload,
                hasShareableLink: !!portalRequest.hasShareableLink,
                financialStatementsYearsToCollect: financialStatements.collect ? portalRequest.fullFiscalYearsToCollect + 1 : undefined,
                payablesYearsToCollect: payables.collect ? portalRequest.fullFiscalYearsToCollect + 1 : undefined,
                payablesGranularity: payables.collect ? 'Annual' : undefined,
                receivablesYearsToCollect: receivables.collect ? portalRequest.fullFiscalYearsToCollect + 1 : undefined,
                receivablesGranularity: receivables.collect ? 'Annual' : undefined,
                transactionsYearsToCollect: txns.collect ? portalRequest.fullFiscalYearsToCollect + 1 : undefined,
            }
        }
    }

    const url = `/api/${entityId}/SubmissionRequests`;

    try {
        const res = await fetchWrapper(url,
            {
                method: FetchMethod.Post,
                body: JSON.stringify(request),
            },
            true,
            true
        );

        return await res.json();
    } catch (exception) {
        LogException(
            `CreateSubmissionRequest failed`,
            exception,
            {
                workspaceId: entityId,
                request
            }
        );

        throw exception;
    }
}
