import { UIRedirectSearchParams } from './Reducer';

export enum UIStateActions {
    SetAddUsers = 'UISTATE/AddUsers',
    SetHomeNavigate = 'UISTATE/AllowHomeNavigate',
    ChangePortalBusy = 'UISTATE/ChangePortalBusy',
    SetRedirectAfterLogin = 'UISTATE/RedirectAfterLogin',
}

export interface ISetAddUsers {
    readonly type: UIStateActions.SetAddUsers;
    readonly isActive: boolean;
}

export interface IAllowHomeNavigate {
    readonly type: UIStateActions.SetHomeNavigate;
    readonly allow: boolean;
}

export interface IChangePortalBusy {
    readonly type: UIStateActions.ChangePortalBusy;
    readonly increment: number;
    readonly source: string;
}

export interface ISetRedirectAfterLogin {
    readonly type: UIStateActions.SetRedirectAfterLogin;
    readonly path?: string;
    readonly searchParams?: UIRedirectSearchParams;
}

export type KnownAction =
    ISetAddUsers |
    IAllowHomeNavigate |
    IChangePortalBusy |
    ISetRedirectAfterLogin;
