import {
    AppThunkAction
} from '../index';

import {
    SubmissionActions,
    KnownAction,
    IUpdateMetadata,
} from './Actions';

import { Submission } from '../../Models/Api/strongbox.financialportal';

import { LogMessage, LogException } from '../../Utils/Logging';

import { GetSubmissionDetail } from '../../Services/SubmissionService';

/**
 * Load a submission 
 * @param workspaceId identifies the workspace to which the submission belongs.
 * @param submissionId identifies the submission
 * @param metadata additional data to store with the entry.  Meaning of this is defined by the consumer
 */
const LoadSubmission = (
    workspaceId: string,
    submissionId: string,
    metadata?: Map<string, any>
): AppThunkAction<Submission | undefined, unknown, KnownAction> =>
    async (dispatch, getState): Promise<Submission | undefined> => {
        try {
            LogMessage(`LoadSubmission, submissionId=${submissionId}`);

            dispatch({
                type: SubmissionActions.LoadSubmission,
                workspaceId,
                submissionId,
                metadata,
            });

            const submissionDetails = await GetSubmissionDetail(workspaceId, submissionId);
            const submission: Submission = {
                ...submissionDetails,
            }

            dispatch({
                type: SubmissionActions.LoadSubmissionComplete,
                workspaceId,
                submissionId,
                submission,
                metadata,
            })

            return submission;
        } catch (e) {
            const errorMsg = 'LoadSubmission action creator failed';

            console.error(errorMsg);
            console.error(e);

            LogException(errorMsg, e, {
                submissionId
            });

            dispatch({
                type: SubmissionActions.LoadSubmissionComplete,
                workspaceId,
                submissionId,
                msg: 'Failed loading submission',
                exceptionMsg: e,
                metadata,
            });

            return undefined;
        }
    }

function UpdateSubmissionMetadata(workspaceId: string, submissionId: string, metadata: Map<string, any>): IUpdateMetadata {
    return {
        type: SubmissionActions.UpdateSubmissionMetadata,
        workspaceId,
        submissionId,
        metadata
    };
}


export const actionCreators = {
    LoadSubmission,
    UpdateSubmissionMetadata,
};
