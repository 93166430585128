import { assetBlobContainer } from './Constants';
import { fetchWrapper } from './FetchWrapper';

import { BorrowerBackgroundSettings, BorrowerBackgroundDirectory, defaultBorrowerBackground } from '../Models/BorrowerBackground';

export async function GetBasicBackgroundSettings(): Promise<BorrowerBackgroundSettings> {
    const url = `${assetBlobContainer}/backgroundassets/directoryinfo.json`;

    try {
        const response = await fetchWrapper(url, {}, true, false);
        if (!response.ok) {
            return defaultBorrowerBackground;
        }

        const directoryInfo: BorrowerBackgroundDirectory = await response.json();

        const randomIndex = Math.floor(Math.random() * directoryInfo.backgroundConfigFiles.length);

        const fileResponse = await fetchWrapper(`${assetBlobContainer}/backgroundassets/${directoryInfo.backgroundConfigFiles[randomIndex]}`);
        if (!fileResponse.ok) {
            return defaultBorrowerBackground;
        }

        return await fileResponse.json();
    } catch (exception) {
        return defaultBorrowerBackground;
    }
}
