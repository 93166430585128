import {
    UserSettingsActions,
} from './Actions';
import {
    GlobalUserSettings,
    UserNotificationTypes
} from './Reducer';

import { store } from '../../index';

import {
    GetNotificationSubscriptionStyles,
    GetWorkspaceNotificationSubscriptions,
    UpdateSubscriptionStyles,
    UpdateWorkspaceNotificationSubscriptions,
    GetTenantAdminSettings,
    AppendTenantEmailRecipient,
    RemoveTenantEmailRecipient,
    UpdateTenantSettingAction,
} from '../../Services/WorkspaceNotifications';

import { LogException } from '../../Utils/Logging';
    
import {
    BatchEditWorkspaceNotificationSubscriptions,
    EditUserNotificationSetting,
    EditUserNotificationSettings,
    EditWorkspaceNotificationSubscription,
    NotificationMethod,
    AdministrativeTenantNotificationSettings,
    AdministrativeNotificationSetting,
    NotificationDefaultRecipientAction,
} from '../../Models/Api/strongbox.financialportal';

const defaultNotificationTypes: UserNotificationTypes[] = [
    {
        type: 'Email',
        description: 'Email',
    }
];

/**
 * Load the global user settings. 
 * @param onError callback in the event of an error. 
 * @returns If there's an error the return value will be undefined
 */

export async function LoadGlobalUserSettings(onError?: (msg: string) => void): Promise<GlobalUserSettings | undefined> {
    store.dispatch({
        type: UserSettingsActions.LoadGlobalUserSettings,
    });

    try {
        // This could be converted eventually to have multiple subscription style types.
        // The front end model supports this so we tweak things a little in the front end.
        const subscriptionStyles = await GetNotificationSubscriptionStyles();

        const result: GlobalUserSettings = {
            notificationData: subscriptionStyles,
            notificationTypes: defaultNotificationTypes.slice(),
        }

        store.dispatch({
            type: UserSettingsActions.LoadGlobalUserSettingsComplete,
            settings: result,
        });

        return result;
    } catch (exception) {
        const errMsg = `An error occurred loading notification settings for the logged in user`;
        onError && onError(errMsg);

        LogException(
            errMsg,
            exception
        );

        store.dispatch({
            type: UserSettingsActions.LoadGlobalUserSettingsComplete
        });

        return undefined;
    } 
}

/**
 * Load the administrative settings for the tenant. 
 * @param onError callback in the event of an error. 
 * @returns If there's an error the return value will be undefined
 */

export async function LoadTenantAdminSettings(onError?: (msg: string) => void): Promise<AdministrativeTenantNotificationSettings | undefined> {
    store.dispatch({
        type: UserSettingsActions.LoadTenantAdminSettings,
    });

    try {
        const settings = await GetTenantAdminSettings();

        store.dispatch({
            type: UserSettingsActions.LoadTenantAdminSettingsComplete,
            settings,
        });

        return settings;
    } catch (exception) {
        const errMsg = `An error occurred loading tenant admin notification settings`;
        onError && onError(errMsg);

        LogException(
            errMsg,
            exception
        );

        store.dispatch({
            type: UserSettingsActions.LoadTenantAdminSettingsComplete
        });

        return undefined;
    }
}

/**
 * Load the workspace settings for a specific user. 
 * @param onError callback in the event of an error. 
 * 
 * @returns true or false depending on success
 */
export async function LoadWorkspaceUserSettings(userId: string, onError?: (msg: string) => void): Promise<boolean> {
    store.dispatch({
        type: UserSettingsActions.LoadWorkspaceUserSettings,
        userId,
    });

    try {
        const workspaceSubscriptions = await GetWorkspaceNotificationSubscriptions()

        store.dispatch({
            type: UserSettingsActions.LoadWorkspaceUserSettingsComplete,
            subscriptions: workspaceSubscriptions,
            userId,
            successful: true
        });

        return true;
    } catch (exception) {
        const errMsg = `An error occurred loading notification settings for the logged in user`;
        onError && onError(errMsg);

        console.error(errMsg);
        console.error(exception);

        LogException(
            errMsg,
            exception
        );

        store.dispatch({
            type: UserSettingsActions.LoadWorkspaceUserSettingsComplete,
            userId,
            successful: false
        });

        return false;
    }
}

/**
 * Updates a global notification/type setting to be on or off
 * 
 * @param notificationId
 * @param notificationType
 * @param turnedOn
 * 
 * @returns true if successful.
 */

export async function UpdateGlobalUserSetting(notificationId: number, notificationType: NotificationMethod, turnedOn: boolean, onError?: (msg: string) => void): Promise<boolean> {
    store.dispatch({
        type: UserSettingsActions.UpdateGlobalSetting,
        notificationId,
        notificationType,
        turnedOn,
    });

    try {
        const userSetting = store.getState().userSettingsState;
        if (!(
            userSetting &&
            userSetting.global &&
            userSetting.global.notificationData &&
            userSetting.global.notificationData.settings
        )) {
            // All the error handling and cleanup will occur in the exception handler below.
            // This exception will get logged.
            throw new Error('Unexpected state in UpdateGlobalUserSetting, required parameter is undefined');
        }

        const subStyles = userSetting.global.notificationData.settings.slice();

        const iSub = subStyles.findIndex(sub => sub.id === notificationId);
        let iNotificationType = -1;
        if (
            (iSub !== -1) &&
            (!!subStyles[iSub].notificationMethods)
        ) {
            iNotificationType = subStyles[iSub].notificationMethods!.findIndex(notifyType => {
                return notifyType.type === notificationType;
            });
        }
        if ((iSub === -1) || (iNotificationType === -1)) {
            // All the error handling and cleanup will occur in the exception handler below.
            // This exception will get logged.

            throw new Error(`Unexpected state in UpdateGlobalUserSetting, couldn't find notification or notification type`);
        }

        subStyles[iSub].notificationMethods![iNotificationType].style = turnedOn ? "OptOut" : "OptIn";

        const update: EditUserNotificationSettings = {
            settings: subStyles.map(userNotificationSetting => {
                const newValue: EditUserNotificationSetting = {
                    notificationId: userNotificationSetting.id,
                    notificationMethods:
                        userNotificationSetting.notificationMethods ? userNotificationSetting.notificationMethods.slice() : []
                }
                return newValue;
            })
        }

        await UpdateSubscriptionStyles(update);

        store.dispatch({
            type: UserSettingsActions.UpdateGlobalSettingComplete,
            settings: update,
            successful: true,
        });

        return true;
    } catch (exception) {
        const errMsg = `An error occurred updating the notification setting for the logged in user`;
        onError && onError(errMsg);

        console.error(errMsg);
        console.error(exception);

        LogException(
            errMsg,
            exception,
            {
                notificationId,
                notificationType,
                turnedOn
            }
        );

        store.dispatch({
            type: UserSettingsActions.UpdateGlobalSettingComplete,
            successful: false
        });

        return false;
    }
}

/**
 * Updates a notification/type setting to be on or off for a specific user id and workspace
 *
 * @param turnedOn
 * @param workspaceId
 * @param notificationId
 * @param notificationType
 *
 * @returns true if successful.
 */

export async function UpdateWorkspaceUserSetting(userId: string, turnedOn: boolean, workspaceId: string, notificationId: number, notificationType: NotificationMethod, onError?: (msg: string) => void): Promise<boolean> {
    store.dispatch({
        type: UserSettingsActions.UpdateWorkspaceUserSetting,
        userId,
        turnedOn,
        workspaceId,
        notificationId,
        notificationType
    });

    try {
        const settingUpdate: EditWorkspaceNotificationSubscription = {
            notificationId,
            workspaceId,
            notificationMethods: [{
                type: notificationType,
                enabled: turnedOn
            }]
        }
        const updateArray: EditWorkspaceNotificationSubscription[] = [settingUpdate];
        const input: BatchEditWorkspaceNotificationSubscriptions = {
            subscriptions: updateArray
        }

        await UpdateWorkspaceNotificationSubscriptions(input);

        store.dispatch({
            type: UserSettingsActions.UpdateWorkspaceUserSettingComplete,
            userId,
            turnedOn,
            workspaceId,
            notificationId,
            notificationType,
            successful: true,
        });

        return true;
    } catch (exception) {
        const errMsg = `Unable to update workspace notification setting at this time`;
        onError && onError(errMsg);

        console.error(errMsg);
        console.error(exception);

        LogException(
            errMsg,
            exception,
            {
                workspaceId,
                notificationId,
                notificationType,
                userId,
            }
        );

        store.dispatch({
            type: UserSettingsActions.UpdateWorkspaceUserSettingComplete,
            userId,
            turnedOn,
            workspaceId,
            notificationId,
            notificationType,
            successful: false,
        });

        return false;
    }
}

/**
 * Updates a notification/type setting to be on or off for a specific user id and set of workspaces
 *
 * @param turnedOn
 * @param workspaceId
 * @param notificationId
 * @param notificationType
 *
 * @returns true if successful.
 */

export async function BatchUpdateWorkspaceUserSetting(userId: string, turnedOn: boolean, workspaceIds: string[], notificationId: number, notificationType: NotificationMethod, onError?: (msg: string) => void): Promise<boolean> {
    store.dispatch({
        type: UserSettingsActions.BatchUpdateWorkspaceUserSetting,
        userId,
        turnedOn,
        workspaceIds,
        notificationId,
        notificationType
    });

    try {
        const updateArray: EditWorkspaceNotificationSubscription[] = workspaceIds.map(workspaceId => {
            return {
                notificationId,
                workspaceId,
                notificationMethods: [{
                    type: notificationType,
                    enabled: turnedOn
                }]
            }
        });

        const input: BatchEditWorkspaceNotificationSubscriptions = {
            subscriptions: updateArray
        }

        await UpdateWorkspaceNotificationSubscriptions(input);

        store.dispatch({
            type: UserSettingsActions.BatchUpdateWorkspaceUserSettingComplete,
            userId,
            turnedOn,
            workspaceIds,
            notificationId,
            notificationType,
            successful: true,
        });

        return true;
    } catch (exception) {
        const errMsg = `Unable to update workspace notification setting at this time`;
        onError && onError(errMsg);

        console.error(errMsg);
        console.error(exception);

        LogException(
            errMsg,
            exception,
            {
                notificationId,
                notificationType,
                userId,
            }
        );

        store.dispatch({
            type: UserSettingsActions.BatchUpdateWorkspaceUserSettingComplete,
            userId,
            turnedOn,
            workspaceIds,
            notificationId,
            notificationType,
            successful: false,
        });

        return false;
    }
}

/**
 * Add a global admin tenant notification recipient for a specific notification/method combination.
 * 
 * @param onError callback in the event of an error. 
 * @returns If there's an error the return value will be undefined
 */

export async function AddAdminNotificationRecipient(
    recipient: string,
    notificationId: number,
    notificationMethod: NotificationMethod,
    onError?: (msg: string) => void
): Promise<AdministrativeNotificationSetting | undefined> {
    store.dispatch({
        type: UserSettingsActions.AddTenantAdminNotificationRecipient,
        recipient,
        notificationId,
        notificationMethod
    });

    try {
        const setting = await AppendTenantEmailRecipient(recipient, notificationId, notificationMethod);

        store.dispatch({
            type: UserSettingsActions.AddTenantAdminNotificationRecipientComplete,
            setting,
        });

        return setting;
    } catch (exception) {
        const errMsg = `Operation failed, unable to add recipient.`;
        onError && onError(errMsg);

        LogException(
            errMsg,
            exception,
            {
                notificationId,
                notificationMethod
            }
        );

        store.dispatch({
            type: UserSettingsActions.AddTenantAdminNotificationRecipientComplete
        });

        return undefined;
    }
}


/**
 * Update the action taken for a notification's list of recipients.
 * 
 * @param onError callback in the event of an error. 
 * @returns If there's an error the return value will be undefined
 */

export async function UpdateNotificationSettingAction(
    newAction: NotificationDefaultRecipientAction,
    notificationId: number,
    notificationMethod: NotificationMethod,
    onError?: (msg: string) => void
): Promise<AdministrativeNotificationSetting | undefined> {
    store.dispatch({
        type: UserSettingsActions.UpdateNotificationSettingAction,
        action: newAction,
        notificationId,
        notificationMethod,
    });

    try {
        const setting = await UpdateTenantSettingAction(newAction, notificationId, notificationMethod);

        store.dispatch({
            type: UserSettingsActions.UpdateNotificationSettingActionComplete,
            setting,
        });

        return setting;
    } catch (exception) {
        const errMsg = `Operation failed, unable to change setting action.`;
        onError && onError(errMsg);

        LogException(
            errMsg,
            exception,
            {
                newAction,
                notificationId,
                notificationMethod
            }
        );

        store.dispatch({
            type: UserSettingsActions.UpdateNotificationSettingActionComplete
        });

        return undefined;
    }
}

/**
 * Remove a recipient from a global admin tenant notification for a specific notification/method combination.
 * 
 * @param onError callback in the event of an error. 
 * @returns If there's an error the return value will be undefined
 */

export async function RemoveAdminNotificationRecipient(
    recipient: string,
    setting: AdministrativeNotificationSetting,
    onError?: (msg: string) => void
): Promise<AdministrativeNotificationSetting | undefined> {
    store.dispatch({
        type: UserSettingsActions.RemoveTenantAdminNotificationRecipient,
        recipient,
        setting,
    });

    try {
        const modifiedSetting = await RemoveTenantEmailRecipient(recipient, setting);

        store.dispatch({
            type: UserSettingsActions.RemoveTenantAdminNotificationRecipientComplete,
            setting: modifiedSetting,
        });

        return modifiedSetting;
    } catch (exception) {
        const errMsg = `Operation failed, unable to remove recipient.`;
        onError && onError(errMsg);

        LogException(
            errMsg,
            exception,
            {
                notificationId: setting.id,
                notificationMethod: setting.type,
            }
        );

        store.dispatch({
            type: UserSettingsActions.RemoveTenantAdminNotificationRecipientComplete
        });

        return undefined;
    }
}
