import { Theme } from '@finagraph/strongbox-finconnect-react';
import { BrandConfig } from '../../Store/Tenant';

export function BuildModalThemeForTenant(config: BrandConfig): Theme | undefined {
    const result = {
        palette: {
            ...config.themeSettings.palette,
        },
        font: {
            ...config.themeSettings.font,
        }
    };

    return result;
}
