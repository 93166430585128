import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Col,
    Container,
    Row
} from 'reactstrap';

import { UserList } from './UserList';

import { ErrorMsgSeverity } from '../../ErrorBanner/ErrorBanner';

import {
    DetailedUserResponse,
    RoleResponse,
    TenantUserMetadataSetting,
} from '../../../Models/Api/strongbox.financialportal';

import { DeleteUser, ReinviteUser, UpdateUser } from '../../../Services/UserService';

import { ApplicationState } from '../../../Store';
import {
    BrandConfig,
    GetBrandConfig,
    TenantActionCreators,
    GetUserMetadataSettings,
} from '../../../Store/Tenant';
import { actionCreators as UIStateActionCreators } from '../../../Store/UIState';
import { GetDetailedUsers, GetLoggedInUser, GetLoggedInUserExternalId, LoadAllDetailedUsers, LoadingUsers } from '../../../Store/User';
import { actionCreators as UserRolesActionCreators, GetRoles, GetRolesLoading, LoadUserRoles } from '../../../Store/UserRoles';

import { ErrorBanner } from '../../ErrorBanner/ErrorBanner';

import { LoadingIndicator } from '../../LoadingIndicator/LoadingIndicator';

import '../../Main.scss';

type InjectedReduxState = {
    users: DetailedUserResponse[];
    brandConfig: BrandConfig;
    userRoles: RoleResponse[];
    loadingUserRoles: boolean;
    loadingUsers: boolean;
    loggedInUserExternalId: string;
    loggedInUserId: string;
    tenantMetadataSettings: TenantUserMetadataSetting[];
};

type InjectedActionCreators = typeof UIStateActionCreators & typeof UserRolesActionCreators & typeof TenantActionCreators;

type UserManagementProps = {
}

type Props = UserManagementProps & InjectedActionCreators & InjectedReduxState;

type UserMsg = {
    msg?: string;
    severity?: ErrorMsgSeverity;
}

const UserManagementComponent: React.FC<Props> = (props): React.ReactElement => {
    const {
        brandConfig,
        GetEmailDomains,
        GetUserMetadataSettings,
        loadingUsers,
        loadingUserRoles,
        loggedInUserId,
        loggedInUserExternalId,
        SetAddUsers,
        userRoles,
        users,
        tenantMetadataSettings,
    } = props;

    const [deletingUser, setDeletingUser] = React.useState<boolean>(false);
    const [updatingUser, setUpdatingUser] = React.useState<boolean>(false);
    const [reinvitingUser, setReinvitingUser] = React.useState<boolean>(false);
    const [userMsg, setUserMsg] = React.useState<UserMsg>({});

    const loadingIndicator = (
        <LoadingIndicator
            overlayStyle={{
                position: 'absolute',
                left: '49%',
                top: '35%'
            }}
            active={true}
        />
    );

    const loading = (): boolean => {
        return loadingUserRoles || loadingUsers;
    }

    const working = (): boolean => {
        return deletingUser || updatingUser || reinvitingUser;
    }

    const loadRequiredResources = (): void => {
        // TODO: Trigger reload of users and roles.
        LoadAllDetailedUsers();
        LoadUserRoles(() => { });
        GetEmailDomains();
        GetUserMetadataSettings();
    }

    React.useEffect(() => {
        loadRequiredResources();

        // I want this to take effect on mount and loadRequiredResources won't change
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);

    const addUsers = (): void => {
        SetAddUsers(true);
    }

    const deleteUser = (user: DetailedUserResponse, onComplete?: (errorMsg?: string) => void): void => {
        setDeletingUser(true);
        DeleteUser(user.id)
            .then(success => {
                setDeletingUser(false);
                if (success) {
                    loadRequiredResources();
                    onComplete && onComplete();
                } else {
                    const errorMsg = 'There was an error deleting the user. Please try again later.';
                    if (!!onComplete) {
                        onComplete(errorMsg);
                    } else {
                        setUserMsg({ msg: errorMsg });
                    }
                }
            });
    }

    const updateUser = (user: DetailedUserResponse): void => {
        setUpdatingUser(true);
        UpdateUser(user, user.id !== loggedInUserId)
            .then(success => {
                setUpdatingUser(false);
                if (success) {
                    loadRequiredResources();
                } else {
                    setUserMsg({ msg: 'There was an error saving changes for this user.  Please try again later.' });
                }
            });
    }

    const reinviteUser = async (user: DetailedUserResponse, onComplete: (errorMsg?: string) => void): Promise<void> => {
        setReinvitingUser(true);
        ReinviteUser(user.id)
            .then(success => {
                setReinvitingUser(false);
                if (!success) {
                    onComplete('There was an error re-inviting this user. Please try again later.')
                } else {
                    onComplete();
                }
            });
    }

    const loadedContent = (): React.ReactElement => {
        return (
            <Row className={'full-height-settings-row'}>
                <Col
                    className={'resource-overview resource-overview-list'}
                >
                    <Row className={'header-row'}>
                        <Col xs={'auto'}>
                            <h2>Users</h2>
                        </Col>
                        <Col className={'right-aligned-col'}>
                            <button
                                className={'small'}
                                onClick={addUsers}
                            >
                                Add
                            </button>
                        </Col>
                    </Row>
                    <Row className={'content-row'}>
                        <Col className={'column-list-container'}>
                            <UserList
                                availableRoles={userRoles}
                                users={users}
                                brandConfig={brandConfig}
                                loggedInUserExternalId={loggedInUserExternalId}
                                onUpdateUser={user => updateUser(user)}
                                onDeleteUser={(user, onComplete) => deleteUser(user, onComplete)}
                                onReinviteUser={(user, onComplete) => reinviteUser(user, onComplete)}
                                tenantUserMetadataSettings={tenantMetadataSettings}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }

    return (
        <Container fluid className={'settings-active-child-container'}>
            {
                userMsg.msg && (
                    <div style={{ paddingLeft: '15px', paddingTop: '25px' }}>
                        <ErrorBanner
                            errorState={{
                                summaryMessage: userMsg.msg,
                                severity: userMsg.severity,
                            }}
                            onDefaultActionButton={() => {
                                setUserMsg({});
                            }}
                        />
                    </div>
                )
            }
            {
                working() && loadingIndicator
            }
            {!loading() && loadedContent()}
            {loading() && loadingIndicator}
        </Container>
    );
}

export const UserManagement = connect<InjectedReduxState, InjectedActionCreators, UserManagementProps, ApplicationState>(
    (appState: ApplicationState) => {
        const result: InjectedReduxState = {
            users: GetDetailedUsers(appState),
            brandConfig: GetBrandConfig(appState),
            userRoles: GetRoles(appState),
            loadingUserRoles: GetRolesLoading(appState),
            loadingUsers: LoadingUsers(appState),
            loggedInUserExternalId: GetLoggedInUserExternalId(appState),
            loggedInUserId: GetLoggedInUser(appState)?.id || '',
            tenantMetadataSettings: GetUserMetadataSettings(appState),
        };

        return result;
    },
    dispatch => bindActionCreators(
        {
            ...UIStateActionCreators,
            ...UserRolesActionCreators,
            ...TenantActionCreators,
        },
        dispatch
    )
)(UserManagementComponent);
