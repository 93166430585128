import {
    UserMetadataItem,
    MetadataType,
} from '../../../Models/Api/strongbox.financialportal';

import { InputStringContainsDangerousContent } from '../../../Utils/FormUtils';

export type AddUsersMode = 'editrequired' | 'editmetadata';

export type AddUsersMetaDataItem = {
    metaDataItem: UserMetadataItem;
    contentIsValid: boolean;
};

export function IsMetadataInputValid(value: string, type: MetadataType): boolean {
    return !InputStringContainsDangerousContent(value);
}
