import * as React from 'react';

import './RiskAnalysis.scss';

import {
    Col,
    Container,
    Row,
    Table
} from 'reactstrap'

import { DropDownCaret } from '../../DropDownCaret/DropDownCaret';
import { RiskCalcDescriptiveOutput } from '../../../Models/Api/strongbox.financialportal';

import { LogMessage, SeverityLevel } from '../../../Utils/Logging';

export type RiskCalcOutputGroup = {
    title: string;
    expanded: boolean;
    outputs: RiskCalcDescriptiveOutput[];
};

type Props = {
    group: RiskCalcOutputGroup;
    currencySymbol?: string;
    onToggle: () => void;
    className?: string;
    groupingRow: number;
};

export const RiskCalcOutputGrouping: React.FC<Props> = ({
    group,
    onToggle,
    currencySymbol = '$',
    className = '',
    groupingRow
}): React.ReactElement => {
    const numericDisplayTypes: Set<string> = new Set([
        'Number',
        'Percentage',
        'Percentile',
        'Currency'
    ]);

    const getTermInYearsForTitle = (): string => {
        if (!(group.outputs[0].termInYears) || (group.outputs[0].termInYears < 1)) {
            return '';
        }
        const baselineTerm = group.outputs[0].termInYears;
        return group.outputs.find(output => output.termInYears !== baselineTerm) ? '' : `: ${baselineTerm} Year`;
    }

    const getDisplayValue = (content: RiskCalcDescriptiveOutput): { value: string, className: string }  => {
        if (!content.value) {
            return { value: '', className: '' };
        }
        if (!content.metricType) {
            return { value: content.value, className: '' };
        }

        if (numericDisplayTypes.has(content.metricType)) {
            const numericVal = parseFloat(content.value);
            if (isNaN(numericVal)) {
                LogMessage(
                    `Unexpected state in RiskCalc detailed results, unable to parse expected numeric value content type: ${content.type || ''} value: ${content.value}`,
                    SeverityLevel.Warning,
                    {
                        ...content
                    }
                );
                console.error(`Unable to parse expected numeric value in RiskCalc detailed results ${content.type || ''} value:${content.value}`);
                return { value: content.value, className: '' };
            }
            const displayValue = new Intl.NumberFormat('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Math.abs(numericVal));
            let result = displayValue;
            switch (content.metricType) {
                case 'Percentage':
                    result = `${displayValue}%`;
                    break;
                case 'Currency':
                    result = `${currencySymbol}${displayValue}`;
                    break;
            }
            let valueClass = '';
            if (numericVal < 0) {
                valueClass = 'negative-value';
                result = `(${result})`
            }
            return { value: result, className: valueClass }
        }

        // numeric values have all been taken care of

        switch (content.metricType) {
            case 'MoodysRating':
                // Should be in the form 'x.y' where x is the moodys rating and y is the type of rating, probably 'edf'
                // standing for expected default frequency.
                const parts = content.value.split('.');
                // if no . is found, an array of one value will be returned which is either the fallback (the entire string)
                // otherwise the first element will be the rating.
                return { value: parts[0], className: '' };
            default:
                // Something like a string at this point and we can just return the value.
                return { value: content.value, className: '' };
        }
    }

    const getDescription = (content: RiskCalcDescriptiveOutput): string => {
        const ratioSeparator = 'to';
        let tiy = '';

        if ((!!content.termInYears) && (content.termInYears > 0)) {
            tiy = `${content.termInYears} ${content.termInYears === 1 ? 'Year' : 'Years'}`;
        }

        switch (content.subCategory) {
            case 'CurrLiabilitiesOverSales':
                return `Current Liabilities ${ratioSeparator} Sales`;
            case 'InventoryOverSales':
                return `Inventory ${ratioSeparator} Sales`;
            case 'ChangeInWCOverSales':
                return `Change in Working Capital ${ratioSeparator} Sales`;
            case 'EbitdaOverInterestExp':
                return `EBITDA ${ratioSeparator} Interest Expense`;
            case 'SalesGrowth':
                return `Sales Growth`;
            case 'ChangeinROA':
                return `Change in Return On Assets`;
            case 'ReOverCurrLiabilities':
                return `Retained Earnings ${ratioSeparator} Current Liabilities`;
            case 'LtDebtOverLTDebtAndNetWorth':
                return `Long Term Debt ${ratioSeparator} Long Term Debt and Net Worth`;
            case 'CashOverAssets':
                return `Cash ${ratioSeparator} Assets`;
            case 'Roa':
                return `Return On Assets`;
            case 'Size':
                return `Size`;
        }

        switch (content.category) {
            case 'CumulativeEDF':
            case 'AnnualizedEDF':
            case 'Forward1YrEDF':
                return `${tiy}`;
        }

        switch (content.type) {
            case 'Percentile1YrEDF':
                return '1 Yr. Percentile Expected Default Frequency';
            case 'Percentile5YrEDF':
                return '1 Yr. Percentile Expected Default Frequency';
            case 'EstimatedAssetVolatility':
                return 'Estimated Asset Volatility';
            case 'ImpliedAssetValue':
                return 'Implied Asset Value';
            case '1YrBondDefaultRateMapping':
                return '1 Yr. Bond Default Rate Mapping';
            case '5YrBondDefaultRateMapping':
                return '5 Yr. Bond Default Rate Mapping';
        }

        return content.type || '';
    }

    return (
        <Container className={className} fluid key={`risk-calc-output-grouping-${groupingRow}`}>
            <Row className={'fin-risk-category-header'} key={`risk-calc-results-grouping-header-${groupingRow}`}>
                <Col xs={9}>
                    <h3>{`${group.title}${getTermInYearsForTitle()}`}</h3>
                </Col>
                <Col xs={3}>
                    <DropDownCaret
                        expanded={group.expanded}
                        contractDescriptor={'Hide category content'}
                        expandDescriptor={'Show category content'}
                        key={`category${Math.random() * 100000}`}
                        clicked={() => {
                            onToggle();
                        }}
                        style={{ marginLeft: '1.5em' }}
                    />
                </Col>
            </Row>
            {
                group.expanded && (
                    <Row key={`risk-calc-results-grouping-expansion-${groupingRow}`}>
                        <Col>
                            <Table className={'std-number-field'} key={`risk-calc-expanded-results-table-${groupingRow}`}>
                                <thead>
                                    <tr key={`risk-calc-expanded-results-header-${groupingRow}`}>
                                        <th key={'detailedValueTitleKey'}>Description</th>
                                        <th key={'detailedValueValueKey'}>Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                       group.outputs.map((output, index) => {
                                            if (!(output.value)) {
                                                return <></>
                                            }
                                            const desc = getDescription(output);
                                            const displayValue = getDisplayValue(output);
                                            return (
                                                <tr key={`risk-calc-expanded-results-${desc}-${index}`}>
                                                    <td>{desc}</td>
                                                    <td className={displayValue.className}>{displayValue.value}</td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                )
            }
        </Container>
    );
}
