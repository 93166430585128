import * as React from 'react';

import './RiskAnalysis.scss';

import {
    Col,
    Container,
    Row
} from 'reactstrap'

import { RiskCalcCategory, RiskCalcResponse } from '../../../Models/Api/strongbox.financialportal';
import { RiskCalcOutputGroup, RiskCalcOutputGrouping } from './RiskCalcOutputGrouping';

type Props = {
    riskCalcResponse: RiskCalcResponse;
    currencySymbol?: string;
};

export const RiskCalcResponseDetails: React.FC<Props> = ({
    riskCalcResponse,
    currencySymbol = '$'
}): React.ReactElement => {

    const getCategoryTitle = (category: RiskCalcCategory): string => {
        switch (category) {
            case 'RelativeContribution':
                return 'Relative Contribution';
            case 'RelativeSensitivity':
                return 'Relative Sensitivity';
            case 'AnnualizedEDF':
                return 'Expected Default Frequency: Annualized';
            case 'CumulativeEDF':
                return 'Expected Default Frequency: Cumulative';
            case 'Forward1YrEDF':
                return 'Expected Default Frequency: 1 Yr. Forward';
            case 'Percentile':
                return 'Percentile';
            case 'Ratio':
                return 'Ratio';
        }
        return 'General';
    }

    const [groups, setGroups] = React.useState<Record<string, RiskCalcOutputGroup>>({})

    React.useEffect(() => {
        const result: Record<string, RiskCalcOutputGroup> = {};

        if (riskCalcResponse && riskCalcResponse.result && riskCalcResponse.result.outputs) {
            riskCalcResponse.result.outputs.forEach(output => {
                let categoryDescriptor = !!output.category ? output.category.toString() : 'Unknown';
                if ((output.subCategory !== 'Unspecified') && (output.termInYears !== undefined) && (output.termInYears > 0)) {
                    categoryDescriptor = `${output.termInYears}${categoryDescriptor}`;
                }

                if (!result[categoryDescriptor]) {
                    result[categoryDescriptor] = {
                        title: getCategoryTitle(output.category || 'Unknown'),
                        expanded: false,
                        outputs: [],
                    };
                }
                result[categoryDescriptor].outputs.push(output);
            });
        }

        setGroups(result);
    }, [riskCalcResponse]);

    if (!(riskCalcResponse && riskCalcResponse.result && riskCalcResponse.result.outputs)) {
        return (<></>);
    }

    const getGroups = (): React.ReactElement[] => {
        const result = [];
        let rowCount = 0;

        for (let key in groups) {
            const group = groups[key]
            result.push((
                <div key={`risk-calc-output-grouping-${rowCount}`}>
                    <RiskCalcOutputGrouping
                        onToggle={() => {
                            const newGroups = {
                                ...groups
                            };
                            newGroups[key] = {
                                ...group,
                                expanded: !group.expanded,
                            }
                            setGroups(newGroups);
                        }}
                        currencySymbol={currencySymbol}
                        group={group}
                        className={rowCount % 2 === 0 ? `fin-risk-category-container` : `fin-risk-category-container odd`}
                        groupingRow={rowCount}
                    />
                </div>
            ))
            rowCount++;
        }
        return result;
    }

    return (
        <Container fluid>
            <Row className={'fin-risk-details'}>
                <Col xs={'12'}>
                    <h2>Result details</h2>
                </Col>
                <Col>
                    {
                        getGroups()
                    }
                </Col>
            </Row>
        </Container>
    );
}
