import * as React from 'react';
import { useCookies } from 'react-cookie';

import { Auth0Provider } from '@auth0/auth0-react';

import { GetFullPathRelativeToCurrentWindow } from './Utils/PathUtils';

import App from './App';

import { defaultAuth0RedirectLogon, defaultAuth0Scope } from './Store/User';
import { GetSettingFromLocalStorage } from './Store/AppSettings';
import { AppDataCookieNames } from './Utils/Constants';

type AuthorizedAppProps = {
}

type Props = AuthorizedAppProps;

const AuthorizedAppComponent: React.FC<Props> = (props): React.ReactElement => {
    const [cookies] = useCookies([AppDataCookieNames.appData]);
    const appData = cookies[AppDataCookieNames.appData];

    const loginRedirect = GetFullPathRelativeToCurrentWindow(defaultAuth0RedirectLogon);

    const [redirectTo, setRedirectTo] = React.useState<string | undefined>(undefined);

    const sessionMaxLifetimeInSeconds = React.useMemo(() => {
        const features = cookies[AppDataCookieNames.featureEnablement];
        const dtsEnabled = features['dynamicTenantSelection'];

        let overriddenSetting = undefined;

        if (!!dtsEnabled) {
            overriddenSetting = GetSettingFromLocalStorage(true, 'sessionMaxLifetimeInSeconds');
        }

        if (!(appData || overriddenSetting)) {
            console.error(`appData cookie is unavailable, defaulting max session lifetime to 86400 (1 day)`);

            return 86400;
        }

        return !!overriddenSetting ? overriddenSetting : appData.sessionMaxLifeTimeMilliseconds / 1000

        // I want this to take effect on mount.
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);

    // Have to get stuff straight from the cookie here, the redux store won't have been
    // initialized and the Auth0Provider doesn't seem to respond well to having some 
    // parameters change once initialized.
    return (
        <Auth0Provider
            domain={appData.auth0Domain}
            clientId={appData.auth0ClientId}
            redirectUri={loginRedirect}
            audience={appData.auth0Audience}
            scope={defaultAuth0Scope}
            maxAge={sessionMaxLifetimeInSeconds}
            onRedirectCallback={(state) => {
                if (!!state) {
                    const redirectPath = window.localStorage.getItem(state.nonce);

                    if (!!redirectPath) {
                        window.localStorage.removeItem(state.nonce);
                        setRedirectTo(redirectPath);
                    }
                }
            }}
        >
            <App
                redirectPath={redirectTo}
                clearRedirectPath={() => setRedirectTo(undefined)}
            />
        </Auth0Provider>
    );
};

export const AuthorizedApp = AuthorizedAppComponent;
