import { Reducer } from 'redux';

import { Submission } from '../../Models/Api/strongbox.financialportal';
import { LogMessage, SeverityLevel } from '../../Utils/Logging';

import {
    KnownAction,
    SubmissionActions,
} from './Actions';

export type SubmissionRecord = {
    workspaceId: string;
    submissionId: string;
    loading: boolean;
    msg?: string;
    exceptionMsg?: string;
    submission?: Submission;
    metadata: Map<string, any>;
};

export interface ISubmissionState {
    /*
     * The 'map' doesn't really change when it's updated so we bump this on every update to make redux think
     * the submission state has changed     
     */
    version: number;
    /*
     * index (string) is submission id
     */
    submissions: Map<string, SubmissionRecord>;
}

export const defaultUserState: ISubmissionState = {
    version: 0,
    submissions: new Map<string, SubmissionRecord>(),
}

function CopyMetadata(submissionRecord: SubmissionRecord, newMetadata?: Map<string, any>): Map<string, any> {
    if (!newMetadata) {
        return submissionRecord.metadata
    }
    newMetadata.forEach((value, key, metadata) => {
        submissionRecord.metadata.set(key, value);   // update or add new value
    });
    return submissionRecord.metadata;
}

export const reducer: Reducer<ISubmissionState, KnownAction> = (state: ISubmissionState | undefined, action: KnownAction): ISubmissionState => {
    let newState: ISubmissionState | undefined = undefined;

    switch (action.type) {
        case SubmissionActions.LoadSubmission:
            newState = {
                ...(state ? state : defaultUserState),
            }
            if (!newState.submissions.has(action.submissionId)) {
                newState.submissions.set(
                    action.submissionId,
                    {
                        workspaceId: action.workspaceId,
                        submissionId: action.submissionId,
                        loading: true,
                        metadata: action.metadata || new Map<string, any>(),
                    }
                );
            } else {
                const existingSub = newState.submissions.get(action.submissionId);
                newState.submissions.set(
                    action.submissionId,
                    {
                        ...existingSub!,
                        loading: true,
                        msg: undefined,
                        exceptionMsg: undefined,
                        metadata: CopyMetadata(existingSub!, action.metadata),
                    }
                )
            }
            break;
        case SubmissionActions.LoadSubmissionComplete:
        { 
            newState = {
                ...(state ? state : defaultUserState),
            };
            if (!newState.submissions.has(action.submissionId)) {
                newState = undefined;
                const errMsg = `LoadSubmissionComplete action called on non-existant submission ID ${action.submissionId}`;
                LogMessage(errMsg, SeverityLevel.Error);
                console.error(errMsg);
            } else {
                const existingSub = newState.submissions.get(action.submissionId);
                newState.submissions.set(
                    action.submissionId,
                    {
                        ...existingSub!,
                        loading: false,
                        msg: action.msg,
                        exceptionMsg: action.exceptionMsg,
                        submission: action.submission,
                        metadata: CopyMetadata(existingSub!, action.metadata),
                    }
                )
            }
            break;
            }
        case SubmissionActions.UpdateSubmissionMetadata:
            {
                newState = {
                    ...(state ? state : defaultUserState),
                };
                if (!newState.submissions.has(action.submissionId)) {
                    newState = undefined;
                    const errMsg = `UpdateSubmissionMetadata action called on non-existant submission ID ${action.submissionId}`;
                    LogMessage(errMsg, SeverityLevel.Error);
                    console.error(errMsg);
                } else {
                    const existingSub = newState.submissions.get(action.submissionId);
                    newState.submissions.set(
                        action.submissionId,
                        {
                            ...existingSub!,
                            metadata: CopyMetadata(existingSub!, action.metadata),
                        }
                    )
                }
                break;
            }
    }

    if (newState) {
        newState.version += 1;
        return newState;
    } else if (state) {
        return state;
    } else {
        let defaultCopy: ISubmissionState = {
            ...defaultUserState,
        };
        return defaultCopy;
    }
}
