import {
    BatchEditWorkspaceNotificationSubscriptions, 
    EditUserNotificationSettings,
    ListWorkspaceNotificationSubscriptions,
    UserNotificationSettings,
    AdministrativeTenantNotificationSettings,
    AdministrativeNotificationSetting,
    NotificationMethod,
    Operation,
    NotificationDefaultRecipientAction,
} from '../Models/Api/strongbox.financialportal';

import { fetchWrapper, FetchMethod } from '../Utils/FetchWrapper';

import { BasicGet, BasicPost } from './SimpleFetchCall';

export async function GetNotificationSubscriptionStyles(): Promise<UserNotificationSettings> {
    return await BasicGet<UserNotificationSettings>(
        `/api/notifications/usernotificationsettings`,
        'GetNotificationSubscriptionStyle'
    );
}

export async function UpdateSubscriptionStyles(subStyles: EditUserNotificationSettings): Promise<void> {
    await BasicPost(
        '/api/notifications/usernotificationsettings',
        'UpdateSubscriptionStyles',
        {
            ...subStyles
        }
    );
}

export async function GetWorkspaceNotificationSubscriptions(): Promise<ListWorkspaceNotificationSubscriptions> {
    return await BasicGet<ListWorkspaceNotificationSubscriptions>(
        `/api/notifications/workspaces/notificationsubscriptions`,
        'GetWorkspaceNotificationSubscriptions'
    );
}

export async function GetTenantAdminSettings(): Promise<AdministrativeTenantNotificationSettings> {
    return await BasicGet<AdministrativeTenantNotificationSettings>(
        `/api/notifications/tenantadministrativesettings`,
        'GetTenantAdminSettings'
    );
}

export async function AppendTenantEmailRecipient(
    recipient: string,
    notificationId: number,
    notificationMethod: NotificationMethod
): Promise<AdministrativeNotificationSetting | undefined> {
    // Valid values for op are 'add', 'remove', 'replace', 'move', 'copy' and 'test'
    const url = `/api/notifications/${notificationId}/${notificationMethod}`;

    // Not totally obvious, add doesn't just add an array element. If no additional path
    // is specified it's the same as replace. Zero based indexes are used to access array 
    // values, passing a - appends the new value to the end.
    const requestBody: Operation[] = [
        {
            value: recipient,
            path: 'defaultRecipients/-',
            op: 'add'
        }
    ]
    try {
        const res = await fetchWrapper(url,
            {
                method: FetchMethod.Patch,
                body: JSON.stringify(requestBody),
            },
            false,
            true,
            // for nocache
            true,
            // for jsonpatchheaders
            true
        );
        if (!res.ok) {
            console.error('failure updating admin notification recipients');
            console.error(res);
            return undefined;
        }
        return await res.json();
    } catch (e) {
        console.error('Error updating admin notification recipients');
        return undefined;
    }
}

export async function UpdateTenantSettingAction(
    action: NotificationDefaultRecipientAction,
    notificationId: number,
    notificationMethod: NotificationMethod
): Promise<AdministrativeNotificationSetting | undefined> {
    // Valid values for op are 'add', 'remove', 'replace', 'move', 'copy' and 'test'
    const url = `/api/notifications/${notificationId}/${notificationMethod}`;

    // Not totally obvious, add doesn't just add an array element. If no additional path
    // is specified it's the same as replace. Zero based indexes are used to access array 
    // values, passing a - appends the new value to the end.
    const requestBody: Operation[] = [
        {
            value: action,
            path: 'defaultAction',
            op: 'replace'
        }
    ]
    try {
        const res = await fetchWrapper(url,
            {
                method: FetchMethod.Patch,
                body: JSON.stringify(requestBody),
            },
            false,
            true,
            // for nocache
            true,
            // for jsonpatchheaders
            true
        );
        if (!res.ok) {
            console.error('Failure updating notification action');
            console.error(res);
            return undefined;
        }
        return await res.json();
    } catch (e) {
        console.error('Failure updating notification action');
        return undefined;
    }
}

export async function RemoveTenantEmailRecipient(
    recipient: string,
    setting: AdministrativeNotificationSetting
): Promise<AdministrativeNotificationSetting | undefined> {
    if (!setting.defaultRecipients) {
        return setting;
    }
    const iRecipient = setting.defaultRecipients.findIndex(r => r.toLowerCase() === recipient.toLowerCase());
    if (iRecipient < 0) {
        return setting;
    }

    const newRecipients = setting.defaultRecipients.slice(0, iRecipient).concat(setting.defaultRecipients.slice(iRecipient + 1));

    // Valid values for op are 'add', 'remove', 'replace', 'move', 'copy' and 'test'
    const url = `/api/notifications/${setting.id}/${setting.type}`;

    // Not totally obvious, add doesn't just add an array element. If no additional path
    // is specified it's the same as replace. Zero based indexes are used to access array 
    // values, passing a - appends the new value to the end.
    const requestBody: Operation[] = [
        {
            value: newRecipients,
            path: 'defaultRecipients',
            op: 'replace'
        }
    ];

    try {
        const res = await fetchWrapper(url,
            {
                method: FetchMethod.Patch,
                body: JSON.stringify(requestBody),
            },
            false,
            true,
            // for nocache
            true,
            // for jsonpatchheaders
            true
        );
        if (!res.ok) {
            console.error('failure removing admin notification recipient');
            console.error(res);
            return undefined;
        }
        return await res.json();
    } catch (e) {
        console.error('Failure removing admin notification recipient');
        return undefined;
    }

}


export async function UpdateWorkspaceNotificationSubscriptions(updates: BatchEditWorkspaceNotificationSubscriptions): Promise<void> {
    await BasicPost(
        `/api/notifications/workspaces/notificationsubscriptions`,
        'UpdateWorkspaceNotificationSubscriptions',
        {
            ...updates
        }
    );
}