import { Reducer } from 'redux';

import {
    BorrowerActions,
    KnownAction,
} from './Actions';

import { EntitySafeDetailResponse } from '../../Models/Api/strongbox.financialportal';

export interface IBorrowerState {
    anonymous: boolean;
    id: string;
    submissionId: string;
    acceptedBusinessName?: string;
    acceptedBusinessEmail?: string;
    wsDetails?: EntitySafeDetailResponse;
}

const defaultState: IBorrowerState = {
    anonymous: false,
    id: '',
    submissionId: '',
}

const sessionStorageBorrowerIdKey = 'borrowerId';
const sessionStorageBorrowerSubmissionIdKey = 'borrowerSubmissionId';
const sessionStorageBorrowerAnonymousKey = 'borrowerAnonymous';

export const reducer: Reducer<IBorrowerState, KnownAction> = (state: IBorrowerState | undefined, action: KnownAction): IBorrowerState => {
    let newState: IBorrowerState | undefined = undefined;

    switch (action.type) {
        case BorrowerActions.BorrowerAnonymousState: {
            newState = {
                ...(state ? state : defaultState),
                anonymous: action.anonymous,
            }
            // Keeping anonymous and submissionId in session storage is important since it prevents multiple
            // confirmation emails from being generated to a single user.   There are reasonably common scenarios
            // where the redux store gets reset (hard refresh for instance) so we want to make sure this is corectly
            // saved in a non-volatile location.
            try {
                sessionStorage.setItem(sessionStorageBorrowerAnonymousKey, !!action.anonymous ? 'true' : 'false');
            } catch {
                // empty.  If this happens it's not really of import.  Saving state here guards against 
                // losing the value if the page is hard refreshed and the redux store resets. This value
                // with the submission id has an impact on whether confirmation emails are sent, so it's 
                // important to preserve these. That's a fairly unlikely event and the user would just need to start over 
                // with their shareable link.
            }
            break;
        }

        case BorrowerActions.BorrowerSetId: {
            newState = {
                ...(state ? state : defaultState),
                id: action.id,
                wsDetails: action.details,
            }
            try {
                sessionStorage.setItem(sessionStorageBorrowerIdKey, action.id);
            } catch {
                // empty.  If this happens it's not really of import.  Saving state here guards against 
                // losing the value if the page is hard refreshed and the redux store resets.  That's a
                // fairly unlikely event and the user would just need to start over with their shareable
                // link.
            }
            break;
        }
        case BorrowerActions.BorrowerSetSubmissionId: {
            newState = {
                ...(state ? state : defaultState),
                submissionId: action.id,
            }
            // Keeping anonymous and submissionId in session storage is important since it prevents multiple
            // confirmation emails from being generated to a single user.   There are reasonably common scenarios
            // where the redux store gets reset (hard refresh for instance) so we want to make sure this is corectly
            // saved in a non-volatile location.
            try {
                sessionStorage.setItem(sessionStorageBorrowerSubmissionIdKey, action.id);
            } catch {
                // empty.  If this happens it's not really of import.  Saving state here guards against 
                // losing the value if the page is hard refreshed and the redux store resets.  That's a
                // fairly unlikely event and the user would just need to start over
            }
            break;
        }
        case BorrowerActions.BorrowerSetAcceptedBusinessInfo: {
            newState = {
                ...(state ? state : defaultState),
                acceptedBusinessEmail: action.businessEmail,
                acceptedBusinessName: action.businessName,
            }
            break;
        }
    }

    // yes, this is written weird, could be ternary operator. But for some reason Vs isn't 
    // smart enough to understand that an if statement checking for newState or state and the
    // returning a ternary with newState ? newState : state means that one of those is necessarily
    // not undefined.  So this prevents a stupid red line.

    if (newState) {
        return newState;
    } else if (state) {
        return state;
    } else {
        let defaultCopy: IBorrowerState = {
            ...defaultState,
        };

        try {
            // See comments above relating to anonymous and submissionId in particular.
            const borrowerId = sessionStorage.getItem(sessionStorageBorrowerIdKey);
            const anonymous = sessionStorage.getItem(sessionStorageBorrowerAnonymousKey);
            const submissionId = sessionStorage.getItem(sessionStorageBorrowerSubmissionIdKey);

            defaultCopy.id = (borrowerId !== null) ? borrowerId : '';
            defaultCopy.submissionId = (submissionId !== null) ? submissionId : '';
            defaultCopy.anonymous = (anonymous !== null) ? anonymous.toLowerCase() === 'true' : false;
        } catch {
            defaultCopy.id = '';
        }

        return defaultCopy;
    }
}