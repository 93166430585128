import * as React from 'react';

import '../../Main.scss';
import './FinancialStatements.scss';

import {
    Tooltip
} from '@mui/material';

import { FSModels } from '@finagraph/financial-statement-editor';

import { SanitizeDOMSelector } from '../../../Utils/DOMUtils';

// import IndentLeft from '@mui/icons-material/FormatIndentDecrease';
// import IndentRight from '@mui/icons-material/FormatIndentIncrease';
import Info from '@mui/icons-material/Info';

type Props = {
    lineItem: FSModels.FinancialStatementLineItemViewModel;
    indentLevel: number;
    onIndentChange: (newLevel: number) => void;
    onInfo: () => void;
    onMultiSelect: (selected: boolean) => void;
    onHoverMultiSelect: (entering: boolean) => void;
    selected: boolean;
}

export const LineItemCaptionOptions: React.FC<Props> = (props): React.ReactElement => {
    const {
        lineItem,
        onInfo,
        onMultiSelect,
        onHoverMultiSelect,
        selected,
    } = props;

    const allowMove = React.useMemo(() => {
        const sectionId = lineItem.sectionId.toLowerCase();

        return ((sectionId !== 'cash') && (sectionId !== 'accountsreceivable') && (sectionId !== 'accountspayable'));
    }, [lineItem]);

    // If the mouse is in the checkbox for selecting an item and shift is pressed, this is (should be) true

    return (
        <div className={'button-bar'}>
            {lineItem.lineItemType === FSModels.FinancialStatementLineItemType.DetailAccount && (
                <React.Fragment>
                    {allowMove && (
                        <div
                            className={selected ? 'always-visible-item' : ''}
                            onClick={(e) => {
                                onMultiSelect(!selected);
                            }}
                            onMouseEnter={(e) => {
                                onHoverMultiSelect(true);
                            }}
                            onMouseLeave={(e) => {
                                onHoverMultiSelect(false)
                            }}
                        >
                            <input
                                className={selected ? 'always-visible-item' : ''}
                                type={'checkbox'}
                                id={`financial-statement-li-option-multi-select-${SanitizeDOMSelector(lineItem.id)}`}
                                onChange={(e) => {
                                    onMultiSelect(e.currentTarget.checked)
                                }}
                                checked={selected}
                                onMouseEnter={(e) => {
                                    onHoverMultiSelect(true);
                                }}
                                onMouseLeave={(e) => {
                                    onHoverMultiSelect(false)
                                }}
                            />
                        </div>
                    )}
                    <Tooltip title={'Show detail account information'}>
                        <button
                            id={`financial-statement-li-option-info-${SanitizeDOMSelector(lineItem.id)}`}
                            onClick={() => {
                                onInfo();
                            }}
                        >
                            <Info />
                        </button>
                    </Tooltip>
                </React.Fragment>
            )}
            {lineItem.lineItemType !== FSModels.FinancialStatementLineItemType.DetailAccount && (
                <span className={'empty-action'} />
            )}
        </div>
    );
}

/*
 * Keeping this code in  here for it's likely return at some point in the future.
 * 
            <Tooltip title={'Decrease indent'}>
                <button
                    id={`financial-statement-li-option-decrease-indent-${SanitizeDOMSelector(lineItem.id)}`}
                    disabled={indentLevel <= 0}
                    onClick={() => {
                        onIndentChange(indentLevel - 1);
                    }}
                >
                    <IndentLeft />
                </button>
             </Tooltip>
            <Tooltip title={'Increase indent'}>
                <button
                    id={`financial-statement-li-option-increase-indent-${SanitizeDOMSelector(lineItem.id)}`}
                    onClick={() => {
                        onIndentChange(indentLevel + 1);
                    }}
                >
                    <IndentRight />
                </button>
            </Tooltip>
 */