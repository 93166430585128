import { ApplicationState } from '../index';

import {
    DefaultSubmissionOptionType,
    IAppSettingsState,
    ReadOverriddenAppSettingsValues,
} from './Reducer';

import {
    PortalSubmissionRequestDefaultFullFiscalYears,
    PortalDefaultBasisOfAccounting,
    PortalCreateSubmissionRequestOptions
} from '../../Models/PortalCreateSubmissionRequestOptions';
import { DefaultAccountingCollectionGroup } from '../../Models/AccountingCollectionGroup';

export function GetShareableLinkParamsFlowSwitch(state: ApplicationState): boolean {
    return !!(state && state.appSettings && state.appSettings.shareableLinkParamsFlow);
}

export function GetDynamicTenantSelectionSwitch(state: ApplicationState): boolean {
    return !!(state && state.appSettings && state.appSettings.dynamicTenantSelection);
}

export function GetStrongboxUrl(state: ApplicationState): string | undefined {
    return (state && state.appSettings) ? state.appSettings.strongboxUrl : undefined;
}

export function GetRiskCalcEnabled(state: ApplicationState): boolean {
    return state && state.appSettings && state.appSettings.riskCalcEnabled;
}

export function GetShowDeletedSubmissions(state: ApplicationState): boolean {
    return state && state.appSettings && state.appSettings.showDeletedSubmissions;
}

export function GetStrongboxSupportEmail(state: ApplicationState): string | undefined {
    return state && state.appSettings && state.appSettings.strongboxSupportEmail;
}

export function GetStrongboxHelpCenterLink(state: ApplicationState): string | undefined {
    return state && state.appSettings && state.appSettings.strongboxHelpCenterLink;
}

export function GetFinancialStatementsEnabled(state: ApplicationState): boolean {
    return state && state.appSettings && state.appSettings.financialStatementsReleaseEnabled;
}

export function GetFinancialStatementsReleaseEnabled(state: ApplicationState): boolean {
    return state && state.appSettings && state.appSettings.financialStatementsReleaseEnabled;
}

export function GetNumMonthsInImportYTDDropdown(state: ApplicationState): number {
    return state && state.appSettings && state.appSettings.numMonthsInImportYTDDropdown;
}

export function GetMaxYearsInAdditionToFYTDForCollection(state: ApplicationState): number {
    return state && state.appSettings && state.appSettings.maxYearsInAdditionToFYTDForCollection;

}

export function GetDisableAdditionalDocUpload(state: ApplicationState, isAnonymousFlow?: boolean): boolean {
    if (!(state && state.appSettings)) {
        return false;
    }

    return !!isAnonymousFlow ?
        (state.appSettings.disabledDataCollectionOptions.disableAdditionalDocUpload || state.appSettings.disabledDataCollectionOptions.disableAnonymousDocUpload) :
        state.appSettings.disabledDataCollectionOptions.disableAdditionalDocUpload;
}

export function GetNewWorkspacesDisabled(state: ApplicationState): boolean {
    return !!state && !!state.appSettings && state.appSettings.disableNewWorkspaces;
}

export function GetDisableProvideuserCopy(state: ApplicationState): boolean {
    return !!state && !!state.appSettings && state.appSettings.disabledDataCollectionOptions.disableProvideUserCopy;
}

export function GetDisableAnonymizeResults(state: ApplicationState): boolean {
    return !!state && !!state.appSettings && state.appSettings.disabledDataCollectionOptions.disableAnonymizeResults;
}

export function GetDisableBasisOfAccounting(state: ApplicationState): boolean {
    return !!state && !!state.appSettings && state.appSettings.disabledDataCollectionOptions.disableBasisOfAccounting;
}

export function GetAllUserOptionsDisabled(state: ApplicationState): boolean {
    return !!state && !!state.appSettings &&
        state.appSettings.disabledDataCollectionOptions.disableAdditionalDocUpload &&
        state.appSettings.disabledDataCollectionOptions.disableAnonymizeResults &&
        state.appSettings.disabledDataCollectionOptions.disableProvideUserCopy;
}

export function GetAppInsightsConfig(state: ApplicationState): { disabled: boolean, instrumentationKey: string } {
    if (!!state && !!state.appSettings) {
        return {
            disabled: state.appSettings.disableAppInsights,
            instrumentationKey: state.appSettings.appInsightsInstrumentationKey,
        };
    } else {
        return {
            disabled: true,
            instrumentationKey: '',
        };
    }
}

export function DirectLinkingDisabled(state: ApplicationState): boolean {
    return !!state && !!state.appSettings && state.appSettings.disableDirectLinking;
}

export function GetFilteredAccountingPackages(state: ApplicationState): string[] {
    if (!(state && state.appSettings)) {
        return [];
    }
    const fullList = state.appSettings.accountingPackages;
    const blackList = state.appSettings.accountingPkgBlackList;

    // Now filter out anything in the black list and we're good.
    return fullList.filter(pkg => !blackList.find(bl => bl.toLowerCase() === pkg.toLowerCase()));
}

/**
 * Retrieve the default settings configured for this tenant.  This is generally different than the options that 
 * are used in the New Data Collection modal.  Those are saved separately.
 * 
 * @param state
 * @param type
 * @param isShareable
 */

export function GetDefaultSubmissionOptions(
    state: ApplicationState,
    type: DefaultSubmissionOptionType,
    isShareable: boolean,
): PortalCreateSubmissionRequestOptions {
    let result: PortalCreateSubmissionRequestOptions | undefined;

    if (!!state && !!state.appSettings) {
        switch (type) {
            case 'portal':
                result = state.appSettings.defaultPortalSubmissionOptions;
                break;
            case 'shareableanonymous':
                result = state.appSettings.defaultShareableAnonymousSubmissionOptions;
                break;
        }
    }

    const today = new Date();

    if (!result) {
        const accountingCollectionGroups = DefaultAccountingCollectionGroup();

        result = {
            anonymizeCustomersAndVendors: false,
            provideUserCopy: false,
            allowUserUpload: true,
            basisOfAccountingPreference: PortalDefaultBasisOfAccounting,
            accountingCollectionGroups,
            fullFiscalYearsToCollect: PortalSubmissionRequestDefaultFullFiscalYears,
            endOfImport: 'Today',
            mostRecentPeriodMonth: today.getMonth(),
            mostRecentPeriodYear: today.getFullYear(),
            mostRecentPeriodDay: today.getDate(),
            hasShareableLink: isShareable,
        }
    }

    return result;
}

export function GetMultipleBorrowerImportsAllowed(state: ApplicationState): boolean {
    return state && state.appSettings && state.appSettings.allowMultipleBorrowerImports;
}

export function GetShowBorrowerIntroBanner(state: ApplicationState): boolean {
    return state && state.appSettings && state.appSettings.showBorrowerIntroBanner;
}

export function GetAlwaysCollectBorrowerBusinessInfo(state: ApplicationState): boolean {
    return state && state.appSettings && state.appSettings.alwaysCollectBorrowerBusinessInfo;
}

export function GetRawAppSettingsState(state: ApplicationState): IAppSettingsState | undefined {
    return state && state.appSettings;
}

export function NoTimeRestrictionForDataCollection(state: ApplicationState): boolean {
    return !!state && !!state.appSettings && state.appSettings.noTimeRestrictionForDataCollection;
}

/** This will pull a named setting from local storage if there is an override in local storage or
 * return undefined if local storage isn't present or the setting doesn't exist.
 * 
 * @param dynamicTenantSelectionEnabled comes ultimately from the features cookie and is true if the /tenant page is available. 
 * That page is restricted to a handful of test and development sites.
 * @param settingName the name of any member in IAppSettingsState defined in Reducer.ts
 */

export function GetSettingFromLocalStorage(dynamicTenantSelectionEnabled: boolean, settingName: string): any | undefined {
    const overrides = ReadOverriddenAppSettingsValues(dynamicTenantSelectionEnabled);
    if (!overrides) {
        return undefined;
    }
    return overrides[settingName];
}

/**
 * Is a setting identified by a string name enabled. 
 * @param state
 * @param settingNames Each of these must be a member with IAppSettingsState.  Will return essentially
 * IAppSettingsState[x] for each element in settingNames.
 * 
 * @returns true if each setting is enabled or false if any settingNames are invalid or any of them are false
 */

export function IsGenericSettingEnabled(state: ApplicationState, settingNames: string[]): boolean {
    if (!(state && state.appSettings)) {
        return false;
    }

    for (let i = 0; i < settingNames.length; i++) {
        // Perhaps a little confusing, but "isNotSetting" will be set if this is a NOT setting, i.e.
        // it's prefixed with a '!'.

        let isNotSetting = false;
        let trimmedString = settingNames[i].trim();
        if (trimmedString.indexOf('!') === 0) {
            isNotSetting = true;
            trimmedString = trimmedString.substr(1);
        }

        if (state.appSettings[trimmedString] === undefined) {
            console.error(`GenericSettingEnabled: attempt to check undefine setting ${settingNames[i]}`);
            return false;
        }

        const settingEnabled = !!state.appSettings[trimmedString];

        // I'm sure I could have a "more efficient" but entirely confusing set of boolean
        // statements here but I prefer it to be clear.

        if (isNotSetting) {
            // If this "is a NOT" setting then if the setting is
            // enabled we should return false because tenant doesn't meet
            // the requirements.

            if (settingEnabled) {
                return false;
            }
        } else {
            // It's not a "NOT" setting so if the setting is not enabled 
            // we return false because the tenant doesn't meet the requirements.

            if (!settingEnabled) {
                return false;
            }
        }
    }

    return true;
}

export function FileTemplateUploadEnabled(state: ApplicationState): boolean {
    return !!state && !!state.appSettings && !!state.appSettings.allowExcelFileUpload;
}

