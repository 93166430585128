import * as React from 'react';
import { PropsWithChildren } from 'react';

import { LoadingMessageWithWaitIndicator } from './LoadingMessageWithIndicator';

type Props = {
    portalBusy: boolean;
}

export const BusyWindowWrapper: React.FC<Props & PropsWithChildren> = (props): React.ReactElement => {
    const {
        portalBusy
    } = props;

    const [showWorkingIndicator, setShowWorkingIndicator] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (portalBusy) {
            if (!showWorkingIndicator) {
                const idTimeout = setTimeout(() => { setShowWorkingIndicator(true); }, 1000);
                return (() => { clearTimeout(idTimeout); });
            } 
        } else {
            setShowWorkingIndicator(false);
        }

        // showWorkingIndicator only gets set in this hook.
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [portalBusy])

    return (
        <>
            {
                showWorkingIndicator && (
                    <div
                        style={{ marginBottom: '35px' }}
                        className={'fixed-message'}
                    >
                        <LoadingMessageWithWaitIndicator
                            msg={'Working...'}
                        />
                    </div>
                )
            }
            {props.children}
        </>
    )
}