import { AppCookieData } from '../../Models/CookieData';

import { IAppSettingsState } from './Reducer';

export enum AppSettingsActions {
    Initialize = 'AppSettings/INIT',
    SetShowDeletedSubmissions = 'AppSettings/SHOW_DELETED_SUBMISSIONS',
    OverrideSettings = 'AppSettings/OVERRIDE_SETTINGS',
    SetAccountingPackage = 'AppSettings/SET_ACCOUNTING_PACKAGE',
    SetAccountingPackageBlacklist = 'AppSettings/SET_ACCOUNTING_PACKAGE_BLACKLIST',
    AddAccountingPackageToBlacklist = 'AppSettings/ADD_ACCOUNTING_PACKAGE_BLACKLIST',
}

export interface IInitAppSettingsAction {
    readonly type: AppSettingsActions.Initialize;
    readonly appData: AppCookieData;
}

export interface ISetShowDeletedSubmissions {
    readonly type: AppSettingsActions.SetShowDeletedSubmissions;
    readonly show: boolean;
}

// Only use this for value type parameters like a boolean or a number.
// Use the more explicit versions for arrays and structures.
export interface IOverrideSettings {
    type: AppSettingsActions.OverrideSettings;
    overrideState: Partial<IAppSettingsState>;
}

export interface ISetAccountingPackage {
    type: AppSettingsActions.SetAccountingPackage;
    package: string;
    on: boolean;
}

export interface ISetAccountingPackageBlacklist {
    type: AppSettingsActions.SetAccountingPackageBlacklist;
    blacklist: string;
    include: boolean;
}

export interface IAddAccountingPackageToBlacklist {
    type: AppSettingsActions.AddAccountingPackageToBlacklist;
    pkg: string;
}

export type KnownAction =
    IInitAppSettingsAction |
    ISetShowDeletedSubmissions |
    IOverrideSettings |
    ISetAccountingPackage |
    ISetAccountingPackageBlacklist |
    IAddAccountingPackageToBlacklist;
