import * as React from 'react';
import {
    DocumentResponse,
    Metadata,
    ResourceRetentionPolicySummary,
    Submission,
    SubmissionRequestSummary
} from '../../Models/Api/strongbox.financialportal';

import '../Main.scss';

import { GroupedFileDropdown } from '../GroupedFileDropdown/GroupedFileDropdown';
import { DownloadableFile, GroupedFiles } from '../../Utils/FileUtils';
import { formatStringMDYFullMonth, FormatDate } from '../../Utils/DateUtils';
import { RequestSummary } from './RequestSummary';
import { FileGroupDescription } from './FileGroupDescription';

import { SubmissionVerification } from '../SubmissionVerification/SubmissionVerification';

/**
 * @member {submittedBy} is the email address (if present) of the actual human who directed the financial import
 * to occur.  In the shareable link scenario, this will be the email address of the person who received and used
 * the shareable link.  This is different than the person who requested the submission to be generated, i.e. the
 * logged in portal user who created the shareable link.
 * @member {submissioneRequestSummary} contains information about the options selected when the submission request
 * or shareable link was created including the person initiating the request, this would be the id of the user 
 * logged into the portal.
 * @member {containerClass} will be the last class listed on the className for the container. This can override
 * default settings like padding
 * */
type Props = {
    businessId: string;
    uploadDate: string;
    documents: DownloadableFile[];
    documentGroups: GroupedFiles[];
    retrievingFiles: boolean;
    noFilesAvailableMsg: string;
    verified: boolean;
    submittedBy: string | undefined;
    containerClass?: string;
    submission?: Submission;
    submissionMetadata?: Metadata[];
    submissionRequestSummary?: SubmissionRequestSummary;
    loadedUserDisplayName?: string;
    onDownload?: (files: DownloadableFile[], data: any | undefined) => void;
    onEmptyFile?: (file: DownloadableFile) => void;
    data?: any; // passed to onDownload
    retentionPolicySummary?: ResourceRetentionPolicySummary;
    onContractSummary?: () => void;
    onExpandSummary?: () => void;
    augmentWithLatestRevision?: boolean;
};

type FileSelectionGroup = {
    summarySelected: boolean;
    selections: boolean[];
}

// const xlsxContentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export type FinancialDocument = DocumentResponse & { downloadUrl: string };

export const FileGroup: React.FC<Props> = (props): React.ReactElement => {
    // If a submission is scheduled for automatic deletion, we'll show it as being in
    // a warning state if the number of seconds remaining to deletion is less than 
    // deletionDateWarning.  86400 is the number of seconds in a day.

    const deletionDateWarning = (86400 * 7);

    const downloadFilesPrompt = 'Download Files';

    const [fileGroupSelections, setFileGroupSelections] = React.useState<FileSelectionGroup[]>([]);
    const [userUploadedIndex, setUserUploadedIndex] = React.useState<number>(-1);

    // This just tracks the singular group of 'extra' documents.  
    // these are user uploaded documents.  They're all grouped together
    // in a single group.
    // This is as opposed to documentGroupsExpanded
    const [documentsExpanded, setDocumentsExpanded] = React.useState<boolean>(false);

    // documentGroupsExpanded tracks the expansion state of the documents as grouped.  
    // Different than documentsExpanded as described above.
    const [documentGroupsExpanded, setDocumentGroupsExpanded] = React.useState<boolean[]>([]);

    const ValidIndexSet = (groupIndex: number, fileIndex: number): boolean => {
        return ((groupIndex >= 0) && (groupIndex < fileGroupSelections.length) && (fileIndex >= 0) && (fileIndex < fileGroupSelections[groupIndex].selections.length));
    }

    const createFileGroupSelectionsCopy = (): FileSelectionGroup[] => {
        return fileGroupSelections.map(groupSelections => {
            return {
                summarySelected: groupSelections.summarySelected,
                selections: groupSelections.selections.slice(),
            }
        });
    }

    React.useEffect(() => {
        const newSelections: FileSelectionGroup[] = props.documentGroups.map((group) => {
            return {
                summarySelected: false,
                selections: group.supportingDocuments.map(doc => false)
            }
        });
        if (props.documents) {
            newSelections.push({
                summarySelected: false,
                selections: props.documents.map(doc => false),
            });
            setUserUploadedIndex(newSelections.length - 1);
        } else {
            setUserUploadedIndex(-1);
        }
        setFileGroupSelections(newSelections);
    }, [props.documentGroups, props.documents]);

    const downloadSelectedGroupFiles = (groupIndex: number): void => {
        if ((!props.onDownload) || (groupIndex >= fileGroupSelections.length)) {
            return;
        }

        const group = fileGroupSelections[groupIndex];
        if (!group) {
            return;
        }

        const result: DownloadableFile[] = [];

        // This isn't just a sanity check.  fileGroupSelections includes an entry at the end
        // for non workbook documents so it will have one more entry than 
        // props.documentGroups.
        if (groupIndex < props.documentGroups.length) {
            const summaryDoc = props.documentGroups[groupIndex].summaryDocument;

            if ((!!summaryDoc) && (group.summarySelected)) {
                result.push(summaryDoc);
            }
        }
        group.selections.forEach((selected, selectionIndex) => {
            if (selected) {
                if (groupIndex >= props.documentGroups.length) {
                    result.push(props.documents[selectionIndex]);
                } else {
                    result.push(props.documentGroups[groupIndex].supportingDocuments[selectionIndex]);
                }
            }
        });

        props.onDownload(result, props.data);
    }

    const policySummaryContent = (): React.ReactElement => {
        // shouldn't be called if this is undefined but sanity check.
        if (!(props.retentionPolicySummary && props.retentionPolicySummary.autoDeleteAt)) {
            return (<></>);
        }

        const deleteDate = new Date(props.retentionPolicySummary.autoDeleteAt);

        if (props.retentionPolicySummary.isDeleted) {
            return (
                <div className={'header-row'} style={{ marginTop: '0.75em' }}>
                    <h3 className={'auto-delete-deleted'}>
                        {`This submission was automatically deleted based on current retention policy at ${FormatDate(deleteDate, formatStringMDYFullMonth)}`}
                    </h3>
                </div>
            );
        }

        const currentDate = new Date();

        // getTime returns milliseconds from epoch.  deltaToDelete will be negative if
        // somehow current date is greater than the deletion date.  Would expect it to be
        // less if we get here.
        const deltaToDelete = (deleteDate.getTime() - currentDate.getTime()) / 1000;

        let dateClass = 'auto-delete';

        if ((deltaToDelete >= 0) && (deltaToDelete < deletionDateWarning)) {
            dateClass += ' auto-delete-warning';
        }

        return (
            <div className={'header-row'} style={{ marginTop: '0.75em' }}>
                <h3>This data collection is scheduled for deletion: <span className={dateClass}>{FormatDate(deleteDate, formatStringMDYFullMonth)}</span></h3>
            </div>
        );
    }

    React.useEffect(() => {
        const newExpansionArray: boolean[] = new Array<boolean>(props.documentGroups.length);

        for (let i = 0; i < props.documentGroups.length; i++) {
            newExpansionArray[i] = (i < documentGroupsExpanded.length) ? documentGroupsExpanded[i] : (i === 0);
        }

        setDocumentGroupsExpanded(newExpansionArray);

        // documentGroups is really the only thing I care about changing here.
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [props.documentGroups])

    const hasUserFiles = (): boolean => {
        return ((props.documents) && (!!props.documents.length) && (userUploadedIndex !== -1));
    }

    const renderUserFiles = (): React.ReactElement => {
        return (
            <GroupedFileDropdown
                title={'User uploaded files'}
                fileDescriptor={'files'}
                key={'user_files'}
                retrievingFiles={props.retrievingFiles}
                noFilesAvailableMsg={props.noFilesAvailableMsg}
                files={props.documents.map((document, index) => {
                    return {
                        file: document,
                        selected: !!((index >= 0) && (index < fileGroupSelections[userUploadedIndex].selections.length) && (fileGroupSelections[userUploadedIndex].selections[index])),
                        id: index,
                    }
                })}
                onSelection={(file) => {
                    if (ValidIndexSet(userUploadedIndex, file.id)) {
                        const newSelections = createFileGroupSelectionsCopy();
                        newSelections[userUploadedIndex].selections[file.id] = file.selected;
                        setFileGroupSelections(newSelections);
                    }
                }}
                expanded={documentsExpanded}
                onContractSummary={() => setDocumentsExpanded(false)}
                onExpandSummary={() => setDocumentsExpanded(true)}
                actionButton={userUploadedIndex === -1 ? undefined :
                    {
                        prompt: downloadFilesPrompt,
                        onClick: () => downloadSelectedGroupFiles(userUploadedIndex),
                    }
                }
            />
         )
    }

    const renderDocGroupings = (): React.ReactElement => {
        const fileGroups: React.ReactElement[] = [];

        // There is kind of an off chance that we would have no document groups and user files so catch that.
        if ((props.documentGroups.length <= 0) && hasUserFiles()) {
            fileGroups.push(renderUserFiles());
        } else {
            for (let groupIndex = 0; groupIndex < props.documentGroups.length; groupIndex++) {
                const documentGroup = props.documentGroups[groupIndex];

                fileGroups.push((
                    <GroupedFileDropdown
                        title={`Excel Workbook Revision`}
                        highlightTitleContent={((!!props.augmentWithLatestRevision) && (groupIndex === 0)) ? 'New workbook versions from regenerating' : undefined}
                        fileDescriptor={'workbooks'}
                        key={`workbook-revision-grouping-${groupIndex}`}
                        retrievingFiles={props.retrievingFiles}
                        noFilesAvailableMsg={props.noFilesAvailableMsg}
                        onExpandSummary={() => {
                            var newDocGroups = documentGroupsExpanded.slice();
                            newDocGroups[groupIndex] = true;
                            setDocumentGroupsExpanded(newDocGroups);
                            props.onExpandSummary && props.onExpandSummary();
                        }}
                        onContractSummary={() => {
                            var newDocGroups = documentGroupsExpanded.slice();
                            newDocGroups[groupIndex] = false;
                            setDocumentGroupsExpanded(newDocGroups);
                            props.onContractSummary && props.onContractSummary();
                        }}
                        expanded={documentGroupsExpanded[groupIndex]}
                        summaryDescription={(<FileGroupDescription groupedFiles={documentGroup} />)}
                        files={documentGroup.supportingDocuments.map((file, index) => {
                            return {
                                file: file,
                                selected: ValidIndexSet(groupIndex, index) ? fileGroupSelections[groupIndex].selections[index] : false,
                                id: index,
                            }
                        })}
                        onSelection={(file) => {
                            let newSelections: FileSelectionGroup[] = [];

                            if (file.id === -1) {
                                if ((groupIndex >= 0) && (groupIndex < fileGroupSelections.length)) {
                                    newSelections = createFileGroupSelectionsCopy();
                                    newSelections[groupIndex].summarySelected = file.selected;
                                }
                            } else if (ValidIndexSet(groupIndex, file.id)) {
                                newSelections = createFileGroupSelectionsCopy();
                                newSelections[groupIndex].selections[file.id] = file.selected;
                            }
                            if (!!newSelections) {
                                setFileGroupSelections(newSelections);
                            }
                        }}
                        actionButton={{
                            prompt: downloadFilesPrompt,
                            onClick: () => downloadSelectedGroupFiles(groupIndex),
                        }}
                    />
                ))

                if ((groupIndex === 0) && hasUserFiles()) {
                    fileGroups.push(renderUserFiles());
                }
            }
        }

        return (
            <React.Fragment>
                {fileGroups}
            </React.Fragment>
        )
    }

    return (
        <div className={`content-summary-container ${props.containerClass}`}>
            <div className={'header-row'}>
                <div className={'header-content'}>
                    <h2 style={{ marginTop: '8px' }}>{`Collected ${new Date(props.uploadDate).toLocaleDateString()}`}</h2>
                </div>
                <div className={'header-content'}>
                    {
                        //If we have an email for who was the actual submitter of this submission (see the comments above
                        // for props.submittedBy) and from a high level if the request came from a shareable link, then
                        // we show the submitter's email.  If there's a request summary then it's a shareable link if 
                        // hasShareableLink is true.  If there's no request summary, it happened a long time ago and we
                        // will assume that if there's an email for who submitted it that it was in fact a shareable link
                        !!props.submittedBy &&
                        ((!!props.submissionRequestSummary && props.submissionRequestSummary.hasShareableLink) ||
                            (!props.submissionRequestSummary)) &&
                        (
                            <h3 style={{ marginLeft: '10px', marginTop: '8px' }}><strong>Submitter's email:</strong> {props.submittedBy}</h3>
                        )
                    }
                    <SubmissionVerification submission={props.submission} style={{ marginLeft: '24px' }} />
                </div>
            </div>
            { props.retentionPolicySummary && policySummaryContent() }
            {
                props.submissionRequestSummary && (
                    <RequestSummary
                        requestSummary={props.submissionRequestSummary}
                        loadedUserDisplayName={props.loadedUserDisplayName}
                        submission={props.submission}
                        submissionMetadata={props.submissionMetadata}
                    />
                )
            }
            { renderDocGroupings() }
        </div>
    )
}
