import * as React from 'react';

export type IndeterminateStates = 'checked' | 'unchecked' | 'indeterminate';

type Props = {
    status: IndeterminateStates;
}

export const IndeterminateCheckBox: React.FC<Props & any> = (props): React.ReactElement => {
    const {
        status,
        ...otherProps
    } = props;

    const checkRef = React.useRef<any>();

    React.useEffect(() => {
        checkRef.current.checked = (status === 'checked');
        checkRef.current.indeterminate = (status === 'indeterminate');
    }, [status])

    return (
        <input
            type={'checkbox'}
            ref={checkRef}
            {...otherProps}
        />
    )
}
