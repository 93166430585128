import '../Main.scss';

import {
    Row,
    Col,
} from 'reactstrap';

import * as React from 'react';
import { connect } from 'react-redux';

import {
    ResourceName,
    RetrieveResource,
    TenantParagraphSet,
    GetBasicSettings
} from '../../Store/Tenant';

import { BorrowerPortalChildProps } from './BorrowerPortal';

import { ApplicationState } from '../../Store';

type InjectedReduxState = {
    tenantDeletedMessages: TenantParagraphSet;
    tenantVisibleName: string;
};

type InjectedActionCreators = {};

type Props = InjectedReduxState & InjectedActionCreators & BorrowerPortalChildProps;

export const ShareableLinkDeletedComponent: React.FC<Props> = (props): React.ReactElement => {
    const {
        tenantDeletedMessages,
        tenantVisibleName,
    } = props;

    return (
        <Row
            className={'shareable-link-error-msg-row'}
            style={{
                marginTop: '30px'
            }}
        >
            <Col>
                {
                    tenantDeletedMessages.paragraphs.map((p, i) => {
                        // lenderName is replaced at runtime so this is correct
                        // eslint-disable-next-line no-template-curly-in-string
                        const outputParagraph = p.replace('${lenderName}', tenantVisibleName);
                        return (
                            <p
                                key={`shared-link-deleted-paragraph-${i}`}
                            >
                                {outputParagraph}
                            </p>
                        )
                    })
                }
            </Col>
        </Row>
    )
}

export const ShareableLinkDeleted = connect<InjectedReduxState, InjectedActionCreators, BorrowerPortalChildProps, ApplicationState>(
    (appState: ApplicationState) => {
        const basicSettings = GetBasicSettings(appState);

        const result = {
            tenantDeletedMessages: RetrieveResource(appState, ResourceName.tenantShareableLinkDeletedParagraphs) || { paragraphs: [] },
            tenantVisibleName: (basicSettings && basicSettings.visibleName) || '',
        };

        return result;
    }
)(ShareableLinkDeletedComponent);
