import '../Main.scss';

import * as React from 'react';

import {
    Button,
    Col,
    Container,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from 'reactstrap';

/**
 * ConfirmModalType basically determines what buttons will be in the footer
 * 
 * @member {yesno} - yes/no type modal.  There will be a yes and no button in the footer.  When the user
 * presses either, onTerminalButton will fire.  The yes parameter will be true if the user pressed yes.
 * The yes parameter will be explicitly no if the user presses no.
 * @member {yesnocancel} - yes/no type modal. Includes a cancel button. Works as yes no except that the 
 * yes parameter will be undefined if the user presses cancel.
 * @member {ok} - ok modal. There will be an OK button.  When the user presses it, onTerminalButton will fire 
 * with the yes value set to false.
 * 
 * */

export enum ConfirmModalType {
    yesno,
    ok,
    yesnocancel,
};

type Props = {
    msg: string | React.ReactElement;
    title?: string;
    onTerminalButton: (yes?: boolean) => void;
    modalType?: ConfirmModalType;
};

export const ConfirmModal: React.FC<Props> = (props): React.ReactElement => {
    const {
        modalType,
        onTerminalButton
    } = props;

    const footerContent = React.useMemo(() => {
        if ((modalType === undefined) || (modalType === ConfirmModalType.yesno)) {
            return (
                <>
                    <Button color="secondary" onClick={() => onTerminalButton(false)}>No</Button>
                    <Button color="primary" onClick={() => onTerminalButton(true)}>Yes</Button>
                </>
            );
        } else if (modalType === ConfirmModalType.yesnocancel) {
            return (
                <>
                    <Button color="secondary" onClick={() => onTerminalButton()}>Cancel</Button>
                    <Button color="secondary" onClick={() => onTerminalButton(false)}>No</Button>
                    <Button color="primary" onClick={() => onTerminalButton(true)}>Yes</Button>
                </>
            );
        } else if (modalType === ConfirmModalType.ok) {
            return (
                <>
                    <Button color="primary" onClick={() => onTerminalButton(false)}>OK</Button>
                </>
            )
        }
    }, [modalType, onTerminalButton])

    return (
        <Modal
            className={`flight-modal`}
            isOpen={true}
            backdrop={'static'}
        >
            {
                props.title &&
                (
                    <ModalHeader toggle={() => props.onTerminalButton(false)}>{props.title}</ModalHeader>
                )
            }
            <ModalBody>
                <Container className={`control-region control-region-lender`}>
                    <Row>
                        <Col>
                            {props.msg}
                        </Col>
                    </Row>
                </Container>
            </ModalBody>
            <ModalFooter>
                {footerContent}
            </ModalFooter>
        </Modal>
    );
}
