import { FinancialStatementFacade, FSModels } from '@finagraph/financial-statement-editor';
import { Reducer } from 'redux';

import { FinancialStatementsActions, KnownAction } from './Actions';

import {
    FinancialStatementTemplatesList,
    WorkbookRevisionStatus
} from '../../Models/Api/strongbox.financialportal';

import numfmt from 'numfmt';

export type NumberFormattingOption = {
    prompt: string;
    checked: boolean;
    style: FSModels.PredefinedMonetaryNumberStyle;
}

export type FinancialStatementError = {
    errorCaption: string;
    errorMessage: string;
}

export type FinancialStatementLineItemFilter = {
    type: FSModels.FinancialStatementLineItemType;
    description: string;
    state: boolean;
}

export type FinancialStatementWorkbookRegenerationTracking = {
    revisionNumber: number;
    // Workbook regeneration is indepdendent of the statement type, i.e. when we start regenerating a workbook
    // it does all of the financial statements.  This is more reference.
    financialStatementType: FSModels.FinancialStatementType;
    startedAt: Date;
    status?: WorkbookRevisionStatus;
}

export type IFinancialStatementsState = {
    workspaceId: string | undefined
    financialRecordId: string | undefined;

    /* An interface for interacting with the financial statements */
    financialStatementFacade: FinancialStatementFacade | undefined;

    /* The current rendered view of the finacial statements */
    currentView: FSModels.FinancialStatementViewModel | undefined;
    /* Has the current view been modified */
    dirty: boolean;

    financialStatementType: FSModels.FinancialStatementType | undefined;
    reportingPeriod: FSModels.ReportingPeriodTypes;
    reportingPeriodSortOrder: FSModels.ChronologicalSortOrder;

    lineItemFilterState: Map<FSModels.FinancialStatementType, FinancialStatementLineItemFilter[]>;
    numberFormattingOptions: NumberFormattingOption[];

    excelNumberFormatters: Map<string, (n: number) => string>;

    financialStatementTemplates?: FinancialStatementTemplatesList;

    errorState?: FinancialStatementError;

    /* When generate workbook is started, add the revision number to this array. When financialRecordId
     * is changed, it's reset, thus it isn't persistent
     */

    trackingRevisionNumbers: FinancialStatementWorkbookRegenerationTracking[];
};

const defaultState: IFinancialStatementsState = {
    workspaceId: undefined,
    financialRecordId: undefined,
    financialStatementFacade: undefined,
    currentView: undefined,
    dirty: false,

    financialStatementType: undefined,
    reportingPeriod: FSModels.ReportingPeriodTypes.FiscalYearsAndYTD,
    reportingPeriodSortOrder: FSModels.ChronologicalSortOrder.OldestFirst,
    
    lineItemFilterState: new Map([
        [FSModels.FinancialStatementType.BalanceSheet, [
            { type: FSModels.FinancialStatementLineItemType.SectionHeader, description: 'Section Headers', state: true },
            { type: FSModels.FinancialStatementLineItemType.SectionFooter, description: 'Section Footers', state: true },
            { type: FSModels.FinancialStatementLineItemType.DetailAccount, description: 'Detail Accounts', state: true }
        ]],
        [FSModels.FinancialStatementType.IncomeStatement, [
            { type: FSModels.FinancialStatementLineItemType.SectionHeader, description: 'Section Headers', state: true },
            { type: FSModels.FinancialStatementLineItemType.SectionFooter, description: 'Section Footers', state: true },
            { type: FSModels.FinancialStatementLineItemType.DetailAccount, description: 'Detail Accounts', state: true }
        ]],
    ]),

    numberFormattingOptions: [
        { checked: false, prompt: 'In thousands', style: FSModels.PredefinedMonetaryNumberStyle.Thousands },
        { checked: false, prompt: 'Actuals (without decimals)', style: FSModels.PredefinedMonetaryNumberStyle.ActualsDisplayedAsWholeNumber},
        { checked: true, prompt: 'Actuals', style: FSModels.PredefinedMonetaryNumberStyle.Actuals }
    ],

    excelNumberFormatters: new Map(),

    trackingRevisionNumbers: []
};

export const reducer: Reducer<IFinancialStatementsState, KnownAction> = (currentState: IFinancialStatementsState | undefined, action: KnownAction): IFinancialStatementsState => {
    let newState: IFinancialStatementsState | undefined = undefined;

    switch (action.type) {
        case FinancialStatementsActions.LoadFinancialStatementStarted: {
            newState = {
                ...(currentState || defaultState)
            };

            newState.trackingRevisionNumbers = [];
            newState.financialStatementFacade = undefined;
            newState.currentView = undefined;

            if (currentState?.financialStatementFacade) {
                currentState?.financialStatementFacade?.dispose();
            }

            break;
        }

        case FinancialStatementsActions.InitializeFinancialStatement: {
            newState = {
                ...(currentState || defaultState)
            };

            // If we're switching between tabs, i.e. income statement and balance sheet, keep existing 
            // revision numbers that we're tracking. If the financialRecordId is changing then we have a
            // new context, clear out the revision numbers we're tracking.

            if (newState.financialRecordId !== action.financialRecordId) {
                newState.trackingRevisionNumbers = [];
            }

            newState.workspaceId = action.workspaceId;
            newState.financialRecordId = action.financialRecordId;
            newState.financialStatementType = action.financialStatementType;
            newState.financialStatementFacade = action.financialStatementFacade;

            newState.financialStatementFacade.setFinancialStatementType(action.financialStatementType);
            newState.financialStatementFacade.setLineItemTypes(currentState!.lineItemFilterState.get(action.financialStatementType)!.map(s => s.type));
            newState.financialStatementFacade.setReportingPeriod(currentState!.reportingPeriod);
            newState.financialStatementFacade.setChronologicalSortOrder(currentState!.reportingPeriodSortOrder);

            newState.currentView = newState.financialStatementFacade?.renderFinancialStatement();

            updateNumberFormattingState(newState);

            if (currentState?.financialStatementFacade && newState?.financialStatementFacade !== currentState?.financialStatementFacade) {
                currentState?.financialStatementFacade?.dispose();
            }

            if (action.dirty !== undefined) {
                newState.dirty = action.dirty;
            } else {
                newState.dirty = !!currentState && currentState.dirty && currentState.workspaceId === newState.workspaceId && currentState.financialRecordId === newState.financialRecordId && currentState.financialStatementFacade === newState.financialStatementFacade;
            }
            break;
        }

        case FinancialStatementsActions.UsePredefinedNumberStyle: {
            newState = {
                ...(currentState || defaultState)
            };

            newState.financialStatementFacade?.usePredefinedNumberStyle(action!.predefinedNumberStyle);
            newState.currentView = newState?.financialStatementFacade?.renderFinancialStatement();
            newState.dirty = true;

            updateNumberFormattingState(newState);
            break;
        }

        case FinancialStatementsActions.ChangeReportingPeriod: {
            newState = {
                ...(currentState || defaultState)
            };

            newState.reportingPeriod = action.reportingPeriod;
            newState.financialStatementFacade?.setReportingPeriod(action!.reportingPeriod);
            newState.currentView = newState?.financialStatementFacade?.renderFinancialStatement();
            //newState.dirty = true; superficial change only, not saved on server
            break;
        }
        
        case FinancialStatementsActions.ChangeReportingPeriodSortOrder: {
            newState = {
                ...(currentState || defaultState)
            };

            newState.reportingPeriodSortOrder = action.reportingPeriodSortOrder;
            newState.financialStatementFacade?.setChronologicalSortOrder(action!.reportingPeriodSortOrder);
            newState.currentView = newState?.financialStatementFacade?.renderFinancialStatement();
            //newState.dirty = true; superficial change only, not saved on server
            break;
        }  

        case FinancialStatementsActions.SetFilterState: {
            newState = {
                ...(currentState || defaultState)
            };

            const current = newState.lineItemFilterState.get(action.financialStatementType);
            if (!!current) {
                const newFilters = current.slice();
                const iFilter = newFilters.findIndex(f => {
                    return f.type === action.filterType;
                });
                if (iFilter !== -1) {
                    newFilters[iFilter].state = action.state;
                }
                newState.lineItemFilterState.set(action.financialStatementType, newFilters);
            }

            newState.financialStatementFacade?.setLineItemTypes(newState!.lineItemFilterState.get(action.financialStatementType)!.filter(s => s.state).map(s => s.type));
            newState.currentView = newState?.financialStatementFacade?.renderFinancialStatement();

            //newState.dirty = true; superficial change only, not saved on server
            break;
        }

        case FinancialStatementsActions.UpdateLineItemCaptions: {
            newState = {
                ...(currentState || defaultState)
            };

            newState.financialStatementFacade?.editCaptionText(action.request);
            newState.currentView = newState?.financialStatementFacade?.renderFinancialStatement();
            newState.dirty = true;
            break;
        }

        case FinancialStatementsActions.AddClassificationSection: {
            newState = {
                ...(currentState || defaultState)
            };

            newState.financialStatementFacade?.addClassificationSection(action.request);
            newState.currentView = newState?.financialStatementFacade?.renderFinancialStatement();
            newState.dirty = true;
            break;
        }

        case FinancialStatementsActions.DeleteClassificationSection: {
            newState = {
                ...(currentState || defaultState)
            };

            newState.financialStatementFacade?.deleteClassificationSection(action.sectionId, action.relocateContentsToSectionId);
            newState.currentView = newState?.financialStatementFacade?.renderFinancialStatement();
            newState.dirty = true;
            break;
        }  

        case FinancialStatementsActions.SetReportedAs: {
            newState = {
                ...(currentState || defaultState)
            };

            newState.financialStatementFacade?.setReportedAs(action.sectionId, action.reportedAs);
            newState.currentView = newState?.financialStatementFacade?.renderFinancialStatement();
            newState.dirty = true;
            break;
        }  

        case FinancialStatementsActions.DecrementLineItemSortOrder: {
            newState = {
                ...(currentState || defaultState)
            };

            newState.financialStatementFacade?.decrementSortOrder(action.lineItemId);
            newState.currentView = newState?.financialStatementFacade?.renderFinancialStatement();
            newState.dirty = true;
            break;
        }  

        case FinancialStatementsActions.IncrementLineItemSortOrder: {
            newState = {
                ...(currentState || defaultState)
            };

            newState.financialStatementFacade?.incrementSortOrder(action.lineItemId);
            newState.currentView = newState?.financialStatementFacade?.renderFinancialStatement();
            newState.dirty = true;
            break;
        } 

        case FinancialStatementsActions.MoveLineItems: {
            newState = {
                ...(currentState || defaultState)
            };

            newState.financialStatementFacade?.moveLineItems(action.request);
            newState.currentView = newState?.financialStatementFacade?.renderFinancialStatement();
            newState.dirty = true;
            break;
        }

        case FinancialStatementsActions.SortLineItems: {
            newState = {
                ...(currentState || defaultState)
            };

            newState.financialStatementFacade?.sortLineItems(action.orderBy, action.orderSubsections, action.sectionId, action.accountingPeriodId);
            newState.currentView = newState?.financialStatementFacade?.renderFinancialStatement();
            newState.dirty = true;
            break;
        }         
        
        case FinancialStatementsActions.DeleteLineItems: {
            newState = {
                ...(currentState || defaultState)
            };

            newState.financialStatementFacade?.deleteLineItems(action.request);
            newState.currentView = newState?.financialStatementFacade?.renderFinancialStatement();
            newState.dirty = true;
            break;
        }

        case FinancialStatementsActions.LoadFinancialStatementTemplatesListCompleted: {
            newState = {
                ...(currentState || defaultState)
            };
            newState.financialStatementTemplates = action.templatesList;
            break;
        }

        case FinancialStatementsActions.SetFinancialStatementErrorState: {
            newState = {
                ...(currentState || defaultState),
            };
            if (!!action.errorState) {
                newState.errorState = {
                    ...action.errorState
                }
            } else {
                newState.errorState = undefined;
            }
            break;
        }

        case FinancialStatementsActions.SaveFinancialStatementComplete: {
            newState = {
                ...(currentState || defaultState),
            };

            newState.dirty = false;

            break;
        }

        case FinancialStatementsActions.RegeneratingWorkbooksStarted: {
            newState = {
                ...(currentState || defaultState),
            };

            // financialStatementType could conceivably be undefined.  While it shouldn't really
            // be possible, guard against it.
            if (!!newState.financialStatementType) {
                newState.trackingRevisionNumbers = newState.trackingRevisionNumbers.slice().concat([
                    {
                        revisionNumber: action.workbookRevisionNumber,
                        financialStatementType: newState.financialStatementType!,
                        startedAt: new Date(Date.now()),
                    }
                ]);
            }
            break;
        }

        case FinancialStatementsActions.UpdateWorkbookRegenerationStatuses: {
            newState = {
                ...(currentState || defaultState),
            };

            newState.trackingRevisionNumbers = newState.trackingRevisionNumbers.slice();
            action.statuses.forEach(status => {
                const iExisting = newState!.trackingRevisionNumbers.findIndex(existingStatus => existingStatus.revisionNumber === status.revisionNumber);
                if (iExisting !== -1) {
                    newState!.trackingRevisionNumbers[iExisting].status = status;
                }
            });
            break;
        }

        case FinancialStatementsActions.ClearCompletedRevisionTracking: {
            // If there's no current state, there's nothing to clear
            if (!!currentState) {
                newState = {
                    ...currentState,
                };

                newState.trackingRevisionNumbers = [];
                currentState.trackingRevisionNumbers.forEach(tracking => {
                    if (!!tracking.status && (tracking.status.outcome === 'Pending')) {
                        newState!.trackingRevisionNumbers.push(tracking);
                    }
                })
            }
            break;
        }

        case FinancialStatementsActions.DeletedFinancialStatementTemplate: {
            if (!!currentState && !!currentState.financialStatementTemplates) {
                newState = { ...currentState };

                const templateList = newState.financialStatementTemplates!.financialStatementTemplates;
                newState.financialStatementTemplates!.financialStatementTemplates =
                    templateList.filter(template => template.id !== action.templateId);
            }
            break;
        }
    }

    if (newState) {
        return newState;
    } else if (currentState) {
        return currentState;
    } else {
        let defaultCopy: IFinancialStatementsState = {
            ...defaultState,
        };
        return defaultCopy;
    }
}
function updateNumberFormattingState(newState: IFinancialStatementsState) {

    const predefinedNumberStyleInUse = newState.financialStatementFacade?.getPredefinedNumberStyleInUse();

    newState.numberFormattingOptions.forEach(o => {
        o.checked = o.style === predefinedNumberStyleInUse;
    });

    newState.currentView?.monetaryDataStyleClasses.forEach(f => {
        if (!!f.excelNumberFormat && !newState!.excelNumberFormatters.get(f.excelNumberFormat)) {
            newState!.excelNumberFormatters.set(f.excelNumberFormat, numfmt(f.excelNumberFormat));
        }
    });
}

