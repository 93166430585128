import {
    AppSettingsActions,
    IInitAppSettingsAction,
    ISetShowDeletedSubmissions,
    IOverrideSettings,
    ISetAccountingPackage,
    ISetAccountingPackageBlacklist,
    IAddAccountingPackageToBlacklist,
} from './Actions';

import { IAppSettingsState } from './Reducer';

import { AppCookieData } from '../../Models/CookieData';

function Initalize(appData: AppCookieData): IInitAppSettingsAction {
    return {
        type: AppSettingsActions.Initialize,
        appData: appData,
    };
}

function SetShowDeletedSubmissions(showDeletedSubmissions: boolean): ISetShowDeletedSubmissions {
    return {
        type: AppSettingsActions.SetShowDeletedSubmissions,
        show: showDeletedSubmissions,
    };
}

function OverrideAppSettings(overrideState: Partial<IAppSettingsState>): IOverrideSettings {
    return {
        type: AppSettingsActions.OverrideSettings,
        overrideState,
    };
}

function SetAccountingPackage(accountingPackage: string, on: boolean): ISetAccountingPackage {
    return {
        type: AppSettingsActions.SetAccountingPackage,
        package: accountingPackage,
        on,
    };
}

function SetAccountingPackageBlacklist(blacklist: string, include: boolean): ISetAccountingPackageBlacklist {
    return {
        type: AppSettingsActions.SetAccountingPackageBlacklist,
        include,
        blacklist,
    };
}

function AddAccountingPackageToBlacklist(pkg: string): IAddAccountingPackageToBlacklist {
    return {
        type: AppSettingsActions.AddAccountingPackageToBlacklist,
        pkg,
    };
}

export const actionCreators = {
    AddAccountingPackageToBlacklist: AddAccountingPackageToBlacklist,
    Initialize: Initalize,
    SetShowDeletedSubmissions,
    OverrideAppSettings,
    SetAccountingPackage,
    SetAccountingPackageBlacklist,
};
