import { NaicsCodeEntity } from '../Models/Api/strongbox.financialportal';

import { FetchMethod, fetchWrapper } from '../Utils/FetchWrapper';

import { LogMessage, LogException, SeverityLevel } from '../Utils/Logging';

export async function GetNaicsCodes(
    query: string
): Promise<NaicsCodeEntity[]> {
    LogMessage(
        `GetNaicsCode`,
        SeverityLevel.Information,
        {
            query
        }
    );
    const url = `/api/Industries/NaicsCodes?query=${query}`;

    try {
        const res = await fetchWrapper(url,
            {
                method: FetchMethod.Get,
            },
            true,
            true
        );
        if (!res.ok) {
            LogException(
                `GetNaicsCode failed`,
                new Error(res.statusText),
                {
                    query
                }
            );

            console.error(`Failure retrieving Naics codes for ${query} status=${res.status}`);
            return [];
        }
        return await res.json();
    } catch (fetchException) {
        LogException(
            `GetNaicsCode failed`,
            fetchException,
            {
                query
            }
        );

        console.error(`Catastrophic failure retrieving Naics codes for ${query}`);
        console.error(fetchException);

        return [];
    }
}
