import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router-dom';

import { ApplicationState } from '../../Store';

import {
    Tooltip
} from '@mui/material';

import MappingIcon from '@mui/icons-material/Public';
import DetailsIcon from '@mui/icons-material/PlagiarismOutlined';
import RiskAnalysisIcon from '@mui/icons-material/QueryStats';

import {
    GetFinancialStatementsEnabled,
    GetRiskCalcEnabled,
} from '../../Store/AppSettings';

import { pathConstants } from '../../Utils/Constants';

import { GetNewestSubmissionIdForWorkspace } from '../../Services/SubmissionService';
import { InfoPopover } from '../InfoPopover/InfoPopover';

import {
    actionCreators as UIStateActions,
} from '../../Store/UIState';

type InjectedReduxState = {
    financialStatementsEnabled: boolean;
    riskCalcEnabled: boolean;
};

type InjectedActionCreators = typeof UIStateActions;

type NewestSubmissionActionsProps = {
    workspaceId: string;
    onError: (msg: string) => void;
    onLockVisibility: (lock: boolean) => void;
};

type Props = NewestSubmissionActionsProps & InjectedActionCreators & InjectedReduxState;

const NewestSubmissionActionsComponent: React.FC<Props> = (props): React.ReactElement => {
    const {
        workspaceId,
        financialStatementsEnabled,
        riskCalcEnabled,
        onError,
        SetPortalWorking,
        SetPortalIdle,
        onLockVisibility,
    } = props;

    const navigate = useNavigate();

    // If not undefined, will be the id of the infoPopover target
    const [noNewestSubmission, setNoNewestSubmission] = React.useState<string | undefined>(undefined);
    const [keepPopoverOpen, setKeepPopoverOpen] = React.useState<boolean>(false);
    const [closePopover, setClosePopover] = React.useState<boolean>(false);

    const containerId = React.useMemo(() => {
        return `newest-submission-actions-container-${workspaceId}`;
    }, [workspaceId]);

    React.useEffect(() => {
        if (!!noNewestSubmission) {
            // We're showing an error about no newest data collection being available, make sure it stays up for a reasonable amount of
            // time, 4 seconds.
            setKeepPopoverOpen(true);
            onLockVisibility(true);
            setClosePopover(false);

            const idTimer = setTimeout(() => {
                setKeepPopoverOpen(false);
            }, 4000);

            return () => { clearTimeout(idTimer) }
        }

        // noNewestSubmission is the only dependency I want here
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [noNewestSubmission]);

    React.useEffect(() => {
        // Because of the way constant functions such as that used in setTimeout above work, we need to
        // check the state of closePopover here.  If keepPopoverOpen changes to false, then it expired above.
        // If closePopover is true, then it was set during the run of that timer. So it should be closed now.
        if (!keepPopoverOpen) {
            if (closePopover) {
                setNoNewestSubmission(undefined);
                onLockVisibility(false);
            }
            setClosePopover(false);
        }
        // keepPopoverOpen is all I want here
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [keepPopoverOpen])

    const goToDetails = async (detailsPath: string, idPostfix: string): Promise<void> => {
        const opName = 'NewestSubmissionActions:GetNewestWorkspaceSubmissionId';

        await SetPortalWorking(opName);
        try {
            const idNewest = await GetNewestSubmissionIdForWorkspace(workspaceId);

            if (!idNewest) {
                setNoNewestSubmission(`${containerId}-${idPostfix}`);
            } else {
                navigate(`${detailsPath}/${workspaceId}/${idNewest}`);
            }
        }
        catch (exception) {
            console.error('Unexpected exception retrieving newest workspace submission id');
            console.error(exception);

            // There won't really be anything useful we can say here.  The operation shouldn't be forbidden by
            // this point.  GetNewestSubmissionIdForWorkspace returns undefined if there is no newest submission
            // id so this really is a situation where we're hosed.

            onError('An error has occurred retrieving information about the workspace. Unable to continue.');
        }
        finally {
            await SetPortalIdle(opName);
        }
    }

    const getTooltipTitle = (content: string): string => {
        return noNewestSubmission ? '' : content;
    }

    return (
        <React.Fragment>
            <div
                className={'newest-submission-actions-container'}
                id={containerId}
                onMouseLeave={() => {
                    if (!!noNewestSubmission) {
                        if (!keepPopoverOpen) {
                            setNoNewestSubmission(undefined);
                        } else {
                            setClosePopover(true);
                        }
                    }
                }}
            >
                <button
                    type="button"
                    id={`${containerId}-details`}
                    style={{
                        marginRight: '10px',
                    }}
                    onClick={() => goToDetails(pathConstants.workspaceSubmissionDetailsOverview, 'details')}
                >
                    <Tooltip title={getTooltipTitle('Show details of the newest submission')}>
                        <DetailsIcon
                            color={'primary'}
                        />
                    </Tooltip>
                </button>
                {financialStatementsEnabled && (
                    <button
                        type="button"
                        id={`${containerId}-mapping`}
                        style={{
                            marginRight: '10px',
                        }}
                        onClick={() => goToDetails(pathConstants.workspaceSubmissionDetailsFinancialStatements, 'mapping')}
                    >
                        <Tooltip title={getTooltipTitle('Edit financial statements for the newest submission (Mapping)')}>
                            <MappingIcon
                                color={'primary'}
                            />
                        </Tooltip>
                    </button>
                )}
                {riskCalcEnabled && (
                    <button
                        type="button"
                        id={`${containerId}-risk`}
                        style={{
                            marginRight: '10px',
                        }}
                        onClick={() => goToDetails(pathConstants.workspaceSubmissionDetailsRiskAnalysis, 'risk')}
                    >
                        <Tooltip title={getTooltipTitle('Evaluate business risk based on the newest submission')}>
                            <RiskAnalysisIcon
                                color={'primary'}
                            />
                        </Tooltip>
                    </button>
                )}
            </div>
            {noNewestSubmission && (
                <InfoPopover
                    target={noNewestSubmission}
                    onToggle={(on) => !on && setNoNewestSubmission(undefined)}
                    rows={[{
                        title: 'No Data Collections',
                        value: 'There are no complete data collections for this workspace. If you recently started one, please wait a moment for it to complete'
                    }]}
                    title={'Error'}
                    placement={'top-start'}
                    noHeader={true}
                    hideArrow={true}
                />
            )}
        </React.Fragment>
    );
}

export const NewestSubmissionActions = connect<InjectedReduxState, InjectedActionCreators, NewestSubmissionActionsProps, ApplicationState>(
    (appState: ApplicationState) => {
        const result: InjectedReduxState = {
            financialStatementsEnabled: GetFinancialStatementsEnabled(appState),
            riskCalcEnabled: GetRiskCalcEnabled(appState),
        };

        return result;
    },
    dispatch => bindActionCreators(
        {
            ...UIStateActions,
        },
        dispatch
    )
)(NewestSubmissionActionsComponent);
