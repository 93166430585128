import {
    Submission,
    CreateSubmissionParameters,
    Metadata,
    PendingSubmissionResult,
    SubmissionDescriptor,
    SubmissionSearchResult,
    SubmissionDetail,
    EntityNewestSubmissionResponse,
} from '../Models/Api/strongbox.financialportal';
import { FetchMethod, fetchWrapper } from '../Utils/FetchWrapper';

import { LogMessage, LogException, SeverityLevel } from '../Utils/Logging';
import { TestEmail } from '../Utils/FormUtils';
import { EmptyGuid } from '../Utils/Constants';

/**
 * Creates a submission
 *
 * @param entityId  entityId of the thing this submission will belong to.
 * @param submissionMeta  additional metadata to tag to the submission. 
 * @param verificationEmail if provided ensures the submission is in the unverified
 * state until verification is completed from an email sent to this address
 *
 * @result id of the submission.  
 * @throws on error.  FetchError from the fetch call or Error
 */

export async function CreateSubmission(
    entityId: string,
    submissionRequestId?: number,
    submissionMeta?: Map<string, string>,
    verificationEmail?: string
): Promise<string> {
    LogMessage(
        `CreateSubmission`,
        SeverityLevel.Information,
        {
            entityId,
            submissionRequestId,
        }
    );

    const url = `/api/Submissions`;

    if (!!verificationEmail) {
        if (!TestEmail(verificationEmail)) {
            throw new Error('The email provided for submission verification is invalid');
        }
    }

    let submissionMetadata: Metadata[] | undefined = undefined;
    if (!!submissionMeta) {
        submissionMetadata = [];
        submissionMeta.forEach((value, key) => {
            submissionMetadata!.push({
                name: key,
                value,
                type: 'None'
            });
        });
    }

    const request: CreateSubmissionParameters = {
        entityId,
        requestId: submissionRequestId,
        submissionMetadata,
        verificationEmail: verificationEmail,
    }

    try {
        const res = await fetchWrapper(url,
            {
                method: FetchMethod.Post,
                body: JSON.stringify(request),
            },
            true,
            true
        );
        if (!res.ok) {
            LogException(
                'CreateSubmission failed',
                new Error(res.statusText),
                {
                    entityId,
                    submissionRequestId,
                }
            );
            console.error('Failure creating new submission');
            console.error(`Attempted id ${entityId}`)
            console.error(res.statusText);

            throw new Error(res.statusText);
        }

        const responseObject: SubmissionDescriptor = await res.json();

        return responseObject.id;
    } catch (error) {
        LogException(
            'CreateSubmission failed',
            error,
            {
                entityId,
                submissionRequestId,
            }
        );

        console.error('Failure creating new submission');
        console.error(`Attempted id ${entityId}`)
        console.error(error);

        throw error;
    }
}

/**
 * Get the details of a submission from entityId and submissionId
 *
 * @param entityId  entityId for which submissions are being retrieved.
 *
 * @param submissionId submissionId for which submission details are being retrieved.
 *  
 * @result The submission details for this entity.  Will be undefined if an error occurs.
 */

export async function GetSubmissionDetail(entityId: string, submissionId: string): Promise<SubmissionDetail> {

    if (!entityId) throw new Error(`entityId is required.`)
    if (!submissionId) throw new Error(`submissionId is required.`)

    LogMessage(
        `GetSubmissionDetail`,
        SeverityLevel.Information,
        {
            entityId,
            submissionId,
        }
    );

    const url = `/api/${entityId}/Submissions/${submissionId}`;

    try {
        const res = await fetchWrapper(url,
            {
                method: FetchMethod.Get,
            },
            true,
            true
        );
        if (!res.ok) {
            console.error('Failed to retrieve submission detail');
            console.error(res.statusText);

            throw new Error(`${res.statusText} ${res.status}`);
        }

        const result: SubmissionDetail = await res.json();

        return result;
    } catch (error) {
        LogException(
            'GetSubmissionDetail failed',
            error,
            {
                entityId,
                submissionId,
            }
        );

        console.error('Failed to retrieve submission detail');
        console.error(error);

        throw error;
    }
}


/**
 * Get the list of submissions for an entity
 *
 * @param entityId  entityId for which submissions are being retrieved.
 *
 * @result List of submissions for this entity.  Will be undefined if an error occurs.
 */

export async function GetSubmissionList(entityId: string): Promise<Submission[] | undefined> {
    LogMessage(
        'GetSubmissionList',
        SeverityLevel.Information,
        {
            entityId,
        }
    );

    const url = `/api/Submissions?entityId=${entityId}`;

    try {
        const res = await fetchWrapper(url,
            {
                method: FetchMethod.Get,
            },
            true,
            true
        );
        if (!res.ok) {
            LogException(
                'GetSubmissionList failed',
                new Error(res.statusText),
                {
                    entityId,
                }
            );

            console.error('Failed to retrieve list of submissions');
            console.error(res.statusText);

            return undefined;
        }

        const result: Submission[] = await res.json();

        return result;
    } catch (error) {
        LogException(
            'GetSubmissionList failed',
            error,
            {
                entityId,
            }
        );

        console.error('Failed to retrieve list of submissions');
        console.error(error);

        return undefined;
    }
}

/**
 * Assigns an email address to a submission. This enables an email containing
 * a link to a summary of the borrower's submitted data being sent to the address
 * when the data is ready.
 *
 * @param entitId id of the organization associated with the submission.
 * @param submissionId id of the submission being modified.
 * @param emailAddress email address to which the link should be sent.
 *
 * @throws either FetchError from fetch request or Error
 */

export async function SetSubmissionEmail(
    entityId: string,
    submissionId: string,
    emailAddress: string
): Promise<void> {
    if (!entityId) throw new Error(`entityId is required.`)
    if (!submissionId) throw new Error(`submissionId is required.`)
    if (!emailAddress) throw new Error(`emailAddress is required.`)

    LogMessage(
        'SetSubmissionEmail',
        SeverityLevel.Information,
        {
            entityId,
            submissionId
        }
    );

    const url = `/api/${entityId}/Submissions/${submissionId}/customerdatarequest`;

    try {
        const requestBody = {
            emailAddress
        };

        const res = await fetchWrapper(url,
            {
                method: FetchMethod.Post,
                body: JSON.stringify(requestBody),
            },
            true,
            true
        );
        if (!res.ok) {
            LogException(
                'SetSubmissionEmail failed',
                new Error(res.statusText),
                {
                    entityId,
                    submissionId
                }
            );

            console.error('Failed to set submission email address');
            console.error(res.statusText);

            throw new Error(res.statusText);
        }
    } catch (error) {
        LogException(
            'SetSubmissionEmail failed',
            error,
            {
                entityId,
                submissionId
            }
        );

        console.error('Failed to set submission email address');
        console.error(error);

        throw error;
    }
}

export async function SearchSubmissions(
    startDate: Date,
    endDate: Date,
    businessId?: string,
): Promise<SubmissionSearchResult[]> {
    LogMessage(
        'SearchSubmissions',
        SeverityLevel.Information,
        {
            startDate: startDate.toString(),
            endDate: endDate.toString(),
            businessId
        }
    );

    let url = `/api/submissions/search?startCreationDate=${startDate.toISOString()}&endCreationDate=${endDate.toISOString()}`;
    if (!!businessId) {
        url += `&entityId=${businessId}`;
    }

    try {
        const res = await fetchWrapper(url,
            {
                method: FetchMethod.Get
            },
            true,
            true
        );
        if (!res.ok) {
            LogException(
                'SearchSubmissions failed',
                new Error(res.statusText),
                {
                    startDate: startDate.toString(),
                    endDate: endDate.toString(),
                    businessId
                }
            );

            console.error('Failed to search submissions');
            console.error(res.statusText);

            throw new Error(res.statusText);
        }

        const submissions: SubmissionSearchResult[] = await res.json();

        return submissions;
    } catch (error) {
        LogException(
            'SearchSubmissions failed',
            error,
            {
                startDate: startDate.toString(),
                endDate: endDate.toString(),
                businessId
            }
        );

        console.error('Failed to search submissions');
        console.error(error);

        throw error;
    }
}


/**
 * Lists submissions that are in the pending state.  These can be direct connections or also those created
 * from a shared link.
 * 
 * @param returnAll - optional. Should all submissions be returned regardless of whether the current user 
 * is granted access to the workspace to which they belong. Default is false. If this is true, the user
 * must be an admin or the call to the controller will fail.
 * @param startDate - optional, specifies the earliest date to include submissions from.  If not provided 
 * all submissions in the pending state are returned.
 */

export async function ListPendingSubmissions(
    returnAll?: boolean,
    startDate?: Date,
): Promise<PendingSubmissionResult[]> {
    LogMessage(
        'ListPendingSubmissions',
        SeverityLevel.Information,
        {
            startDate: startDate?.toString() || 'Not provided'
        }
    );

    let url = `/api/submissions/listpending`;
    let needsAmpersand = false;

    if (!!startDate || !!returnAll) {
        url += '?'
        if (!!startDate) {
            url += `startCreationDate=${startDate.toISOString()}`;
            needsAmpersand = true;
        }
        if (!!returnAll) {
            if (needsAmpersand) {
                url += '&';
            }
            url += `returnAll=true`;
            needsAmpersand = true;
        }
    }

    try {
        const res = await fetchWrapper(url,
            {
                method: FetchMethod.Get
            },
            true,
            true
        );
        if (!res.ok) {
            LogException(
                'ListPendingSubmissions failed',
                new Error(res.statusText),
                {
                    startDate: startDate?.toString() || 'Not provided'
                }
            );

            console.error('Failed to search ListPendingSubmissions');
            console.error(res.statusText);

            throw new Error(res.statusText);
        }

        const submissions: PendingSubmissionResult[] = await res.json();

        return submissions;
    } catch (error) {
        LogException(
            'ListPendingSubmissions failed',
            error,
            {
                startDate: startDate?.toString() || 'Not provided'
            }
        );

        console.error('Failed to list pending submissions');
        console.error(error);

        throw error;
    }
}

/**
 * Get the id of the newest complete submission in a workspace
 * 
 * @param workspaceId - The workspace in question or undefined if there are no complete submissions
 */

export async function GetNewestSubmissionIdForWorkspace(
    workspaceId: string
): Promise<string | undefined> {
    LogMessage(
        'GetNewestSubmissionIdForWorkspace',
        SeverityLevel.Information,
        {
            workspaceId,
        }
    );

    let url = `/api/submissions/newest?entityId=${workspaceId}`;

    try {
        const res = await fetchWrapper(url,
            {
                method: FetchMethod.Get
            },
            true,
            true
        );
        if (!res.ok) {
            LogException(
                'GetNewestSubmissionIdForWorkspace failed',
                new Error(res.statusText),
                {
                    workspaceId,
                }
            );

            console.error('GetNewestSubmissionIdForWorkspace failed');
            console.error(res.statusText);

            throw new Error(res.statusText);
        }

        const result: EntityNewestSubmissionResponse = await res.json();

        if (result.submissionId === EmptyGuid) {
            return undefined;
        }

        return result.submissionId;
    } catch (error) {
        LogException(
            'GetNewestSubmissionIdForWorkspace failed',
            error,
            {
                workspaceId,
            }
        );

        console.error('GetNewestSubmissionIdForWorkspace failed');
        console.error(error);

        throw error;
    }
}
