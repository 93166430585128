import * as React from 'react';

import {
    Col,
    Row,
    Input,
    Label,
} from 'reactstrap'

type Props = {
    inputKey: string;
    checked: boolean;
    label: string;
};

export const CheckedSummaryItem: React.FC<Props> = (props): React.ReactElement => {
    return (
        <Row className={'header-content request-summary-row'}>
            <Col xs={'auto'}>
                <Input
                    type="checkbox"
                    disabled
                    name={props.inputKey}
                    key={props.inputKey}
                    checked={props.checked}
                />
            </Col>
            <Col>
                <Label for={props.inputKey}>{props.label}</Label>
            </Col>
        </Row>
    )
}
