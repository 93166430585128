import '../Main.scss';

import IconButton from '@mui/material/IconButton';

import ArrowLeftIcon from '@mui/icons-material/ChevronLeft';
import ArrowRightIcon from '@mui/icons-material/ChevronRight';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';

import * as React from 'react';

type Props = {
    elementsOnPage: number;
    elementsPerPage: number;
    totalElements: number;
    pageNumber: number;
    pageLeft: () => void;
    pageRight: () => void;
    goFirstPage: () => void;
    goLastPage: () => void;
    isOnLastPage: () => boolean;
    /**
     * This control fills it's container's full-width, but the visual part of the component is potentially small.
     * Specify how you want to align the visual elements of the pagination control. The default is 'left'.
     */
    horizontalAligment?: 'left' | 'center' | 'right';
    defaultContainerStyle?: React.CSSProperties;
};

export const PaginationControls: React.FC<Props> = (props): React.ReactElement => {
    const {
        defaultContainerStyle,
        isOnLastPage,
        pageNumber,
    } = props;

    const containerStyle: React.CSSProperties = defaultContainerStyle || {};

    switch (props.horizontalAligment) {

        case 'right':
            containerStyle.justifyContent = 'flex-end';
            break;

        case 'center':
            containerStyle.justifyContent = 'center';
            break;

        default:
            containerStyle.justifyContent = 'flex-start';
            break;
    }

    const onLastPage = React.useMemo(() => {
        return isOnLastPage();
    // Current page number very much affects isOnLastPage so it should be in the dependencies list
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOnLastPage, pageNumber]);

    return (
        <div style={containerStyle} className={`pagination-controls-container`}>
            <IconButton
                className={'pagination-button'}
                aria-label={'first page'}
                onClick={props.goFirstPage}
            >
                <FirstPageIcon
                    color={props.pageNumber === 1 ? 'disabled' : 'primary'}
                />
            </IconButton>

            <IconButton
                className={'pagination-button'}
                aria-label={'page left'}
                onClick={props.pageLeft}
            >
                <ArrowLeftIcon
                    color={props.pageNumber === 1 ? 'disabled' : 'primary'}
                />
            </IconButton>
            <span>{` Page ${props.pageNumber}`}</span>
            <IconButton
                className={'pagination-button'}
                aria-label={'page right'}
                onClick={props.pageRight}
            >
                <ArrowRightIcon
                    color={onLastPage ? 'disabled' : 'primary'}
                />
            </IconButton>

            <IconButton
                className={'pagination-button'}
                aria-label={'last page'}
                onClick={props.goLastPage}
            >
                <LastPageIcon
                    color={onLastPage ? 'disabled' : 'primary'}
                />
            </IconButton>

        </div>
    );
}
