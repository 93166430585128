import * as React from 'react';

import '../../Main.scss';
import './FinancialStatements.scss';

import {
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Col,
    Label
} from 'reactstrap';

import {
    DangerousInputForm,
    DangerousInputFormChildProps,
    PotentiallyDangerousInput,
    PotentiallyDangerousTextArea
} from '../../DangerousInputForm';

const MaxDisplayNameLength = 128;
const MaxDescriptionLength = 512;

export type EditTemplateParameters = {
    displayName: string;
    description: string;
}

type Props = {
    onClose: (newParameters?: EditTemplateParameters) => void;
}

export const EditTemplateParametersModal: React.FC<Props> = (props): React.ReactElement => {
    const {
        onClose
    } = props;

    const [formData, setFormData] = React.useState<EditTemplateParameters>({
        displayName: '',
        description: ''
    });

    const IdDisplayName = 'edit-template-display-name';
    const IdDescription = 'edit-template-description';

    return (
        <Modal
            className={`flight-modal add-financial-statement-subsection`}
            isOpen={true}
            toggle={(e: React.KeyboardEvent) => onClose(undefined)}
            backdrop={'static'}
        >
            <ModalHeader toggle={(e) => onClose(undefined)}>Save As A Template</ModalHeader>
            <ModalBody>
                <div>
                    <DangerousInputForm
                        onClose={(contentAccepted) => onClose(contentAccepted ? formData : undefined)}
                        submitText={'Save Template'}
                        formData={formData}
                        dangerousInputFields={[
                            {
                                formDataName: 'displayName',
                                fieldId: IdDisplayName
                            },
                            {
                                formDataName: 'description',
                                fieldId: IdDescription
                            }
                        ]}
                    >
                        {(childProps: DangerousInputFormChildProps) => (
                            <React.Fragment>
                                <Row>
                                    <Col xs={3} className={'label-column'}>
                                        <Label htmlFor={IdDisplayName}>Name:</Label>
                                    </Col>
                                    <Col>
                                        <PotentiallyDangerousInput
                                            id={IdDisplayName}
                                            name={IdDisplayName}
                                            value={formData.displayName}
                                            maxLength={MaxDisplayNameLength}
                                            nameForInvalidMsg={'Name'}
                                            clearFieldWithDangerousContent={childProps.clearFieldWithDangerousContent}
                                            fieldWithDangerousContent={childProps.fieldWithDangerousContent}
                                            updateField={(newValue) => {
                                                setFormData({
                                                    ...formData,
                                                    displayName: newValue
                                                });
                                            }}
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={3} className={'label-column'}>
                                        <Label htmlFor={IdDescription}>Description:</Label>
                                    </Col>
                                    <Col>
                                        <PotentiallyDangerousTextArea
                                            id={IdDescription}
                                            name={IdDescription}
                                            value={formData.description}
                                            maxLength={MaxDescriptionLength}
                                            nameForInvalidMsg={'Description'}
                                            clearFieldWithDangerousContent={childProps.clearFieldWithDangerousContent}
                                            fieldWithDangerousContent={childProps.fieldWithDangerousContent}
                                            updateField={(newValue) => {
                                                setFormData({
                                                    ...formData,
                                                    description: newValue
                                                });
                                            }}
                                            cols={80}
                                            rows={Math.ceil(MaxDescriptionLength / 80)}
                                            style={{
                                                width: '100%'
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </React.Fragment>
                        )}
                    </DangerousInputForm>
                </div>
            </ModalBody>
        </Modal>
    );
}
