import * as React from 'react';

import '../Main.scss';

import { InputStringContainsDangerousContent } from '../../Utils/FormUtils';

import {
    Button,
    Container,
    Row,
    Col
} from 'reactstrap';

/**
 * Defines parameters for a field that might contain dangerous input
 *
 * @parameter formDataName is the name of the field within props.formData that 
 * contains the content that might be dangerous.  The content will be evaluated
 * with formData[formDataName] to get the value
 */

export type DangerousInput = {
    formDataName: string;
    fieldId: string;
}

export type DangerousInputFormChildProps = {
    fieldWithDangerousContent?: DangerousInput;
    clearFieldWithDangerousContent: () => void;
}

type Props = {
    children: (props: DangerousInputFormChildProps) => JSX.Element;
    dangerousInputFields: DangerousInput[];
    onClose: (contentAccepted: boolean) => void;
    hasCancel?: boolean;
    submitText?: string;
    controlButtonClassName?: string;
    formData: any;
}

export const DangerousInputForm: React.FC<Props> = (props): React.ReactElement => {
    const {
        children,
        dangerousInputFields,
        formData,
        onClose,
        submitText,
        hasCancel,
        controlButtonClassName
    } = props;

    const [fieldWithDangerousContent, setFieldWithDangerousContent] = React.useState<DangerousInput | undefined>(undefined);

    const childProps: DangerousInputFormChildProps = React.useMemo(() => {
        return {
            fieldWithDangerousContent,
            clearFieldWithDangerousContent: () => setFieldWithDangerousContent(undefined)
        };
    }, [fieldWithDangerousContent]);

    return (
        <form
            onSubmit={(e) => {
                const failingField = dangerousInputFields.find(f => {
                    const inputContent = formData[f.formDataName];
                    if (!!inputContent && InputStringContainsDangerousContent(inputContent)) {
                        return true;
                    }
                    return false;
                });

                if (!!failingField) {
                    setFieldWithDangerousContent(failingField);
                    e.preventDefault();
                } else {
                    onClose(true);
                }
            }}
        >
            <Container fluid>
                <Row>
                    <Col>
                        {!!children && children(childProps)}
                    </Col>
                </Row>
                <Row className={'top-bordered-region'}>
                    <Col xs={12} className={'right-aligned-col'}>
                        <Button
                            color="primary"
                            className={`small ${controlButtonClassName}`}
                            type={'submit'}
                            style={{
                                marginTop: '15px'
                            }}
                        >
                            {submitText || 'OK'}
                        </Button>
                        {
                            !!hasCancel && (
                                <Button
                                    color="secondary"
                                    className={`small ${controlButtonClassName}`}
                                    style={{
                                        marginTop: '15px'
                                    }}
                                    onClick={() => onClose(false)}
                                >
                                    Cancel
                                </Button>
                            )
                        }
                    </Col>
                </Row>
            </Container>
        </form>
    );
}
