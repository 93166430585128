import {
    AppThunkAction
} from '../index';

import * as actions from './Actions';

import {
    JobUpdateRecord,
    KnownAction,
    RunningImportsStateActions,
} from './Actions';

import {
    PendingSubmissionResult,
} from '../../Models/Api/strongbox.financialportal';

import { LogException } from '../../Utils/Logging';

import { ListPendingSubmissions } from '../../Services/SubmissionService';

function UpdateJobs(updates: JobUpdateRecord[]): actions.IUpdateJobStatus {
    return {
        type: actions.RunningImportsStateActions.UpdateJobStatus,
        updates,
    };
}

function DismissJob(financialRecordId: string, workspaceId: string): actions.IDismissJob {
    return {
        type: actions.RunningImportsStateActions.DismissJob,
        financialRecordId,
        workspaceId,
    };
}

function DismissMultipleJobs(jobs: { financialRecordId: string, workspaceId: string }[]): actions.IDismissMultipleJobs {
    return {
        type: actions.RunningImportsStateActions.DismissMultipleJobs,
        jobs,
    };
}

const ListPendingJobs = (returnAll?: boolean): AppThunkAction<void, unknown, KnownAction> =>
    async (dispatch, getState): Promise<void> => {
        let submissions: PendingSubmissionResult[] = [];

        dispatch({
            type: RunningImportsStateActions.ListPendingJobs,
            returnAll: !!returnAll,
        });

        try {
            submissions = await ListPendingSubmissions(returnAll);
            dispatch({
                type: RunningImportsStateActions.ListPendingJobsComplete,
                pendingSubmissionResults: submissions,
                returnAll: !!returnAll,
            });
        } catch (ex) {
            const errorMsg = 'Failure in ListPendingJobs action creator';

            console.error(errorMsg);
            console.error(ex);

            LogException(errorMsg, ex);

            dispatch({
                type: RunningImportsStateActions.ListPendingJobsComplete,
                returnAll: !!returnAll,
            });
        }
    }

function UpdateLoggedInUserAccess(workspaceId: string, hasAccess: boolean): actions.IUpdateLoggedInUserAccess {
    return {
        type: RunningImportsStateActions.UpdateLoggedInUserAccess,
        hasAccess,
        workspaceId
    };
}

export const actionCreators = {
    DismissJob: DismissJob,
    DismissMultipleJobs: DismissMultipleJobs,
    UpdateJobs: UpdateJobs,
    ListPendingJobs: ListPendingJobs,
    UpdateLoggedInUserAccess: UpdateLoggedInUserAccess,
};
