import { StrongboxImportRequest } from '@finagraph/strongbox-finconnect-react';
import { AccountingPackage } from '../../Models/Api/AccountingPackages';

import {
    ImportFinancialsActions,
    ISetImportCustomerFinancialsAction,
    IToggleImportCustomerFinancialsAction,
    ISetBorrowerParametersAction,
    IToggleBorrowerParametersAction,
    IImportHasStarted,
    ISetConnectionOptions,
    ISaveImportParameters,
    ISetRetryImportRequest,
    IClearRetryImportRequest,
    ISetBorrowerFlowAccountingPkg,
} from './Actions';

import {
    ConnectionOptions,
    CustomerFinancialsTarget,
    DirectLinkParameters,
    ImportMap
} from './Reducer';

function SetImportCustomerFinancialsActive(
    isActive: boolean,
    target: CustomerFinancialsTarget,
    importingDirect?: DirectLinkParameters
): ISetImportCustomerFinancialsAction {
    return {
        type: ImportFinancialsActions.SetImportCustomerFinancials,
        isActive,
        target,
        importingDirect
    };
}

function ToggleImportCustomerFinancialsActive(): IToggleImportCustomerFinancialsAction {
    return {
        type: ImportFinancialsActions.ToggleImportCustomerFinancials,
    };
}

function SetBorrowerParametersActive(isActive: boolean): ISetBorrowerParametersAction {
    return {
        type: ImportFinancialsActions.SetBorrowerParameters,
        isActive,
    };
}

function ToggleBorrowerParametersActive(): IToggleBorrowerParametersAction {
    return {
        type: ImportFinancialsActions.ToggleBorrowerParameters,
    };
}

function ImportHasStarted(): IImportHasStarted {
    return {
        type: ImportFinancialsActions.ImportHasStarted,
    }
}

function SetConnectionOptions(options?: ConnectionOptions): ISetConnectionOptions {
    return {
        type: ImportFinancialsActions.SetConnectionOptions,
        connectionOptions: options
    }
}

function SaveImportParameters(workspaceId: string, accountingPackage: AccountingPackage, options: StrongboxImportRequest): ISaveImportParameters {
    return {
        type: ImportFinancialsActions.SaveImportParameters,
        workspaceId,
        accountingPackage,
        options
    };
}

function RetryImportRequest(importMap: ImportMap): ISetRetryImportRequest {
    return {
        type: ImportFinancialsActions.SetRetryImportRequest,
        importMap,
    }
}

function ClearRetryImportRequest(): IClearRetryImportRequest {
    return {
        type: ImportFinancialsActions.ClearRetryImportRequest,
    }
}

function SetBorrowerFlowAccountingPkg(accountingPkg: string): ISetBorrowerFlowAccountingPkg {
    return {
        type: ImportFinancialsActions.SetBorrowerFlowAccountingPkg,
        pkg: accountingPkg,
    }
}

export const actionCreators = {
    SetImportCustomerFinancialsActive: SetImportCustomerFinancialsActive,
    ToggleImportCustomerFinancialsActive: ToggleImportCustomerFinancialsActive,
    SetBorrowerParametersActive: SetBorrowerParametersActive,
    ToggleBorrowerParametersActive: ToggleBorrowerParametersActive,
    ImportHasStarted: ImportHasStarted,
    SetConnectionOptions: SetConnectionOptions,
    SaveImportParameters: SaveImportParameters,
    RetryImportRequest: RetryImportRequest,
    ClearRetryImportRequest: ClearRetryImportRequest,
    SetBorrowerFlowAccountingPkg: SetBorrowerFlowAccountingPkg,
};
