import * as React from 'react';

import '../../Main.scss';
import './FinancialStatements.scss';

import {
    Popover,
    PopoverBody,
    Container,
    Row,
    Col,
    Input,
    FormGroup,
    Label
} from 'reactstrap';

import { FSModels } from '@finagraph/financial-statement-editor';
import { NumberFormattingOption } from '../../../Store/FinancialStatements';

type Props = {
    onToggle: (active: boolean) => void;
    targetId: string;
    statementType: FSModels.FinancialStatementType
    onStateChange: (option: NumberFormattingOption) => void;
    options: NumberFormattingOption[];
}

export const EditNumberFormattingPopover: React.FC<Props> = (props): React.ReactElement => {
    const {
        onToggle,
        statementType,
        targetId,
        onStateChange,
        options
    } = props;

    const getSelectionRow = (rowKey: FSModels.PredefinedMonetaryNumberStyle, option: NumberFormattingOption): React.ReactElement => {
        const buttonId = `${option.style}-radio-button`;

        return (
            <Row key={`financial-statement-edit-number-format-${statementType}-${rowKey}`}>
                <Col xs={2}>
                    <Input
                        className={'clickable'}
                        type={'radio'}
                        checked={option.checked}
                        onChange={e => {/* React logs an error unless this is defined */}}
                        onClick={(e) => {
                            onStateChange(option);
                        }}
                        id={buttonId}
                        name={option.style as any}
                    />
                </Col>
                <Col>
                    <Label
                        className={'clickable'}
                        onClick={(e) => {
                            onStateChange(option);
                        }}
                        check
                        for={buttonId}
                    >
                        {option.prompt}
                    </Label>
                </Col>
            </Row>
        )
    }

    return (
        <div className={`control-region control-region-lender`}>
            <Popover
                placement={'bottom'}
                hideArrow={true}
                isOpen={true}
                className={'financial-statement-popover'}
                target={targetId}
                key={'fs-edit-number-formatting-popover'}
                toggle={() => onToggle && onToggle(false)}
                trigger={'legacy'}
            >
                <PopoverBody className={'popover-body menu-style'}>
                    <Container 
                        fluid
                        style={{
                            minWidth: '350px'
                        }}
                    >
                        <FormGroup check>
                            {options.map(opt => getSelectionRow(opt.style, opt))}
                        </FormGroup>
                    </Container>
                </PopoverBody>
            </Popover>
        </div>
    )
}
