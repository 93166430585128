import * as React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';

import '../Main.scss';

/**
 * @member{leftButtonMargin} is the margin between the provided content and the delete button.  Default is 15px
 */

type Props = {
    content: React.ReactElement;
    onDelete: () => void;
    leftButtonMargin?: string;
    hideDelete?: boolean;
}

export const RowWithDelete: React.FC<Props> = (props): React.ReactElement => {
    const {
        content,
        onDelete,
        leftButtonMargin,
        hideDelete,
    } = props;

    const leftButtonMarginInUse = React.useMemo(() => {
        return leftButtonMargin || '15px';
    }, [leftButtonMargin]);

    const rowClassName = React.useMemo(() => {
        return `row-with-delete${hideDelete === true ? '-always-hide' : ''}`;
    }, [hideDelete]);

    return (
        <div
            className={rowClassName}
        >
            {content}
            <div className={'placeholder'} />
            <button
                style={{ marginLeft: leftButtonMarginInUse }}
                onClick={(e) => onDelete()}
            >
                <DeleteIcon />
            </button>
        </div>
    );
}
