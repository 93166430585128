import { FetchMethod, fetchWrapper } from '../Utils/FetchWrapper';

import { RoleResponse } from '../Models/Api/strongbox.financialportal';

import { LogMessage, LogException, SeverityLevel } from '../Utils/Logging';

/**
 * Retrieve the available user roles from the back end.
 * 
 * @result undefined if all users creatd successfully. Otherwise an array of results containing individual user
 * results.
 */

export async function GetUserRoles(
    onError?: (msg: string) => void
): Promise<RoleResponse[]> {
    LogMessage(
        'GetUserRoles',
        SeverityLevel.Information
    );

    const url = `/api/roles`;

    try {
        const res = await fetchWrapper(url,
            {
                method: FetchMethod.Get,
            },
            true,
            true
        );

        if (!res.ok) {
            LogException(
                'GetUserRoles failed',
                new Error(res.statusText)
            );
            onError && onError(res.statusText);

            // Todo handle bad response
            return [];
        }

        return await res.json();
    } catch (error) {
        LogException(
            'GetUserRoles failed',
            error
        );

        // Todo handle bad response

        onError && onError(error);
    }

    return [];
}
