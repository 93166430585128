import React from "react";
import { connect } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";

import { ApplicationState } from '../../Store';
import { actionCreators as AppActions } from '../../Store/AppSettings';
import { actionCreators as UserActions } from '../../Store/User';

type InjectedReduxState = {
}

type InjectedActionCreators = typeof AppActions & typeof UserActions;

type LogoutButtonProps = {
    buttonText?: string;
    style?: any;
}

type Props = InjectedReduxState & InjectedActionCreators & LogoutButtonProps;

const LogoutButtonComponent: React.FC<Props> = (props): React.ReactElement => {
    const { logout } = useAuth0();

    return (
        <button
            type="button"
            style={props.style}
            className={'small'}
            onClick={() => {
                props.LogUserOut(logout);
            }}
        >
            { props.buttonText || 'Log Out'}
        </button>
    );
};

const LogoutButton = connect<InjectedReduxState, InjectedActionCreators, LogoutButtonProps, ApplicationState>(
    (appState: ApplicationState) => {
        const result = {
        };

        return result;
    },
    {
        ...AppActions,
        ...UserActions,
    }
)(LogoutButtonComponent);

export default LogoutButton;
