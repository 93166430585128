import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { useNavigate } from 'react-router-dom';

import {
    Tooltip
} from '@mui/material';

import { EntityDetailResponse, EntityAccessResponse, UserResponse } from '../../../Models/Api/strongbox.financialportal';

import { GetShareableLinkParamsFlowSwitch, GetStrongboxUrl, GetNewWorkspacesDisabled } from '../../../Store/AppSettings';
import { actionCreators as RunningImportsActions, GetRunningJobsVersion, GetRunningJobs, RunningImport } from '../../../Store/RunningImports';
import { actionCreators as NewDataCollectionParametersActions } from '../../../Store/NewDataCollectionParameters';
import { actionCreators as SubmissionHistoryActions } from '../../../Store/SubmissionHistory';
import {
    actionCreators as ImportFinancialsActions,
    CustomerFinancialsTarget,
    GetImportCustomerFinancialsTarget
} from '../../../Store/ImportFinancials';
import {
    GetLoggedInUser,
    GetUsers,
    LoggedInUserHasAccess,
    LoadAllUsers
} from '../../../Store/User';
import {
    actionCreators as UIStateActions,
    GetPortalBusyState,
} from '../../../Store/UIState';

import { LogException, LogMessage, SeverityLevel } from '../../../Utils/Logging';
import { FullUserName } from '../../../Utils/UserUtils';

import {
    GetWorkspaceInfo,
    actionCreators as WorkspacesActions,
    LoadWorkspaceDetails,
    LoadWorkspaces,
    LoadingWorkspaces,
    WorkspaceDelete,
    WorkspaceWithMeta,
    WorkspacePageInfo
} from '../../../Store/Workspaces';

import { PaginationControls } from '../../PaginationControls/PaginationControls';

import { pathConstants } from '../../../Utils/Constants';
import { BuildQueryString } from '../../../Utils/WorkspaceRouteInformation';
import { InputStringContainsDangerousContent } from '../../../Utils/FormUtils';
import { GetStdThemeColors } from '../../../Utils/Style';

import { NewestSubmissionActions } from '../../NewestSubmissionActions/NewestSubmissionActions';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import LockIcon from '@mui/icons-material/Lock';
import UsersIcon from '@mui/icons-material/GroupAdd';

import { AddModifyWorkspace, AddModifyWorkspaceErrorState } from '../../AddModifyWorkspace/AddModifyWorkspace';
import { RequestWorkspaceAccess, UpdateWorkspaceUsers, WorkspaceActionEnabled, UpsertWorkspaceEntity } from '../../../Services/WorkspaceService';

import { TitleBox } from '../../TitleBox/TitleBox';
import { RunningImportsList } from '../../RunningImportsList/RunningImportsList';

import {
    Theme as StrongboxTheme
} from '@finagraph/strongbox-finconnect-react';

import { BuildModalThemeForTenant } from '../../Brands/BrandThemes';

import { EventCategory, MetricsService } from '../../../Utils/Metrics';

import { ErrorBanner, ErrorState } from '../../ErrorBanner/ErrorBanner';
import { ConfirmModal, ConfirmModalType } from '../../ConfirmModal/ConfirmModal';

import {
    BrandConfig,
    GetBasicSettings,
    GetBrandConfig,
    TenantBasicSettings,
} from '../../../Store/Tenant';

import { ApplicationState } from '../../../Store';

type InjectedReduxState = {
    allUsers: UserResponse[];
    strongboxModalThemes: StrongboxTheme | undefined;
    strongboxUrl: string;
    customerFinancialsTarget: CustomerFinancialsTarget | undefined;
    tenantVisibleName: string;
    jobs: RunningImport[];
    importsListKey: string;
    workspaceListKey: string;
    linkParamsActive: boolean;
    userCanAddWorkspace: boolean;
    basicTenantSettings?: TenantBasicSettings;
    workspacesAreLoading: boolean;
    workspaces: WorkspaceWithMeta[];
    workspacePageInfo: WorkspacePageInfo | undefined;
    loggedInUser?: UserResponse;
    portalBusy: boolean;
    brandConfig: BrandConfig;
    disableNewWorkspaces: boolean;
};

type InjectedActionCreators =
    typeof ImportFinancialsActions &
    typeof NewDataCollectionParametersActions &
    typeof RunningImportsActions &
    typeof WorkspacesActions &
    typeof SubmissionHistoryActions &
    typeof UIStateActions;

type WorkspaceListProps = {
};

type Props = WorkspaceListProps & InjectedReduxState & InjectedActionCreators;

type ConfirmingWorkspaceOp = {
    confirmingWorkspaceId: string;  // either the id of the workspace or undefined meaning the window shouldn't be shown.
    confirmingWorkspaceName: string;
};

export type AddModifyWorkspaceData = {
    displayName?: string;
    engagementCode?: string;
}

type AddWorkspaceState = {
    addWorkspaceModalOpen: boolean;
    showNameRequired: boolean;
    showUnsecureWorkspaceName: boolean;
    showUnsecureEngagementCode: boolean;
    addModifyWorkspaceData: AddModifyWorkspaceData;
};

const WorkspaceListComponent: React.FC<Props> = (props): React.ReactElement => {
    const searchFieldId = 'searchTermInput';

    const [addWorkspace, setAddWorkspace] = React.useState<AddWorkspaceState>({
        addModifyWorkspaceData: {
            displayName: undefined,
            engagementCode: undefined
        },
        showNameRequired: false,
        showUnsecureWorkspaceName: false,
        showUnsecureEngagementCode: false,
        addWorkspaceModalOpen: false,
    });

    const [errorState, setErrorState] = React.useState<ErrorState | undefined>(undefined);
    const [searchWord, setSearchWord] = React.useState<string>('');
    const [confirmingWorkspace, setConfirmingWorkspace] = React.useState<ConfirmingWorkspaceOp | undefined>(undefined);
    const [failedToLoadUsers, setFailedToLoadUsers] = React.useState<boolean>(false);
    const [queryConfirmAddToWorkspace, setQueryConfirmAddToWorkspace] = React.useState<WorkspaceWithMeta | undefined>(undefined);
    const [modifyWorkspaceUsers, setModifyWorkspaceUsers] =
        React.useState<{
            workspace: EntityDetailResponse;
            users: UserResponse[];
        } | undefined>(undefined);
    const [accessRequested, setAccessRequested] =
        React.useState<
            {
                workspace: WorkspaceWithMeta;
                response: EntityAccessResponse;
            } | undefined>(undefined);

    const [initialized, setInitialized] = React.useState<boolean>(false);

    // Will be undefined or a workspace id
    const [workspaceActionMsgLocked, setWorkspaceActionMsgLocked] = React.useState<string | undefined>(undefined);

    const navigate = useNavigate();

    const loadWorkspacesForCurrentSettings = (hard?: boolean): void => {
        if ((!props.workspacePageInfo) || (props.workspacePageInfo.currentPage === -1)) {
            props.SetPortalWorking('WorkspaceList:loadWorkspacesForCurrentSettings:unscoped');
            LoadWorkspaces(1, '')
                .finally(() => {
                    props.SetPortalIdle('WorkspaceList:loadWorkspacesForCurrentSettings:unscoped');
                });
        } else {
            // Just use what we already have loaded, assuming there's something actually loaded
            if (searchWord !== props.workspacePageInfo.searchTerm) {
                setSearchWord(props.workspacePageInfo.searchTerm);
            }
            if (!props.workspaces || props.workspaces.length <= 0 || hard === true) {
                props.SetPortalWorking('WorkspaceList:loadWorkspacesForCurrentSettings:scoped');
                LoadWorkspaces(props.workspacePageInfo.currentPage, props.workspacePageInfo.searchTerm)
                    .finally(() => {
                        props.SetPortalIdle('WorkspaceList:loadWorkspacesForCurrentSettings:scoped');
                    });
            }
        }
    }

    React.useEffect(() => {
        // The parameter to loadWorkspacesForCurrentSettings was added as a performance optimization when things
        // were really slow.  Better performance now so generally it's not needed (or more accurately passing true for
        // a hard refresh is okay) but I'd prefer not to remove that behavior altogether so it's easy to restore.
        loadWorkspacesForCurrentSettings(true);

        // We need a list of users to add a workspace so the user may select who has access to the new workspace
        // Users aren't likely to change very much, this call to LoadAllUsers is required if, e.g. the user 
        // refreshes the page and the redux store was cleared.  
        props.SetPortalWorking('WorkspaceList:useEffect');
        LoadAllUsers((msg) => {
            setErrorState({
                summaryMessage: msg,
                extraInformation: 'You will be unable to add new workspaces',
            });
            setFailedToLoadUsers(true);
        })
            .finally(() => {
                props.SetPortalIdle('WorkspaceList:useEffect');
            })

        setInitialized(true);

        // I want this to execute equivalent to componentDidMount so this is appropriate
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const addModifyErrorState: AddModifyWorkspaceErrorState | undefined = React.useMemo(() => {
        if (addWorkspace.showNameRequired) {
            return 'nameRequired';
        } else if (addWorkspace.showUnsecureWorkspaceName) {
            return 'unsecureWorkspaceName';
        } else if (addWorkspace.showUnsecureEngagementCode) {
            return 'unsecureEngagementCode';
        }
        return undefined;
    }, [addWorkspace]);

    React.useEffect(() => {
        if (!initialized) {
            return;
        }
        const debounceTimer = setTimeout(() => {
            LoadWorkspaces(1, searchWord)
                .then(() => {
                    const searchField = document.getElementById(searchFieldId);
                    if (!!searchField) {
                        searchField.focus();
                    }
                });
        }, 1000);
        return () => {
            clearTimeout(debounceTimer);
        }

        // lint will complain about initialized not in dependency list, but based on how I use that, I do not want that.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchWord]);

    const searchOnChange = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        if (e.target.value !== '') {
            setSearchWord(e.target.value);
        } else {
            setSearchWord('');
        }
    }

    const pageLeft = (): void => {
        if ((!props.workspacePageInfo) || (props.workspacePageInfo.currentPage <= 1)) {
            return;
        }

        props.SetPortalWorking('WorkspaceList:pageLeft');
        LoadWorkspaces(props.workspacePageInfo.currentPage - 1, searchWord)
            .finally(() => {
                props.SetPortalIdle('WorkspaceList:pageLeft');
            });
    }

    const goFirstPage = (): void => {
        if ((!props.workspacePageInfo) || (props.workspacePageInfo.currentPage <= 1)) {
            return;
        }

        props.SetPortalWorking('WorkspaceList:goFirstPage');
        LoadWorkspaces(1, searchWord)
            .finally(() => {
                props.SetPortalIdle('WorkspaceList:goFirstPage');
            });
    }

    const totalPageCount = (): number => {
        if (!props.workspacePageInfo) {
            return 0;
        }

        let numPages = Math.floor((props.workspacePageInfo.totalWorkspaces / props.workspacePageInfo.workspacesPerPage));

        if (props.workspacePageInfo.totalWorkspaces % props.workspacePageInfo.workspacesPerPage) {
            numPages++;
        }

        return numPages;
    }

    const isOnLastPage = (): boolean => {
        if (!props.workspacePageInfo) {
            return true;
        }
        if ((props.workspacePageInfo.totalWorkspaces === 0) || (props.workspacePageInfo.workspacesPerPage === 0)) {
            return true;
        }

        const numPages = totalPageCount();

        return (props.workspacePageInfo.currentPage === numPages);
    }

    const pageRight = (): void => {
        if (isOnLastPage()) {
            return;
        }

        // pageInfo can't be undefined if isOnLastPage doesn't return true.
        props.SetPortalWorking('WorkspaceList:pageRight');
        LoadWorkspaces(props.workspacePageInfo!.currentPage + 1, searchWord)
            .finally(() => {
                props.SetPortalIdle('WorkspaceList:pageRight');
            });
    }

    const goLastPage = (): void => {
        if (!props.workspacePageInfo || isOnLastPage()) {
            return;
        }

        const numPages = totalPageCount();

        props.SetPortalWorking('WorkspaceList:goLastPage');
        LoadWorkspaces(numPages, searchWord)
            .finally(() => {
                props.SetPortalIdle('WorkspaceList:goLastPage');
            });
    }

    const upsertWorkspaceEntity = async (
        userIds: string[],
        onComplete: (failed?: 'workspaceNotNamed' | 'unsecureWorkspaceName' | 'unsecureEngagementCode') => void,
        workspace?: EntityDetailResponse
    ): Promise<void> => {
        let workspaceId: string = '';

        props.SetPortalWorking('WorkspaceList:upsertWorkspaceEntity');

        if (!!workspace) {
            workspaceId = workspace.id;
        } else {
            const { displayName, engagementCode } = addWorkspace.addModifyWorkspaceData;

            if (!displayName) {
                onComplete('workspaceNotNamed');
                props.SetPortalIdle('WorkspaceList:upsertWorkspaceEntity');
                return;
            }

            if (InputStringContainsDangerousContent(displayName)) {
                onComplete('unsecureWorkspaceName');
                props.SetPortalIdle('WorkspaceList:upsertWorkspaceEntity');
                return;
            }

            if (engagementCode && InputStringContainsDangerousContent(engagementCode)) {
                onComplete('unsecureEngagementCode');
                props.SetPortalIdle('WorkspaceList:upsertWorkspaceEntity');
                return;
            }

            workspaceId = await UpsertWorkspaceEntity(displayName, engagementCode) || '';
        }

        try {
            if (!!workspaceId) {
                try {
                    await UpdateWorkspaceUsers(workspaceId, userIds);
                } catch (exception) {
                    LogException(
                        `Failed adding users to newly created workspace`,
                        new Error(`Failed adding users to newly created workspace`),
                        {
                            workspaceId,
                            users: userIds
                        }
                    );
                }

                MetricsService.tryTrackEvent(EventCategory.Workspace, 'AddComplete', { workspaceId });

                onComplete();

                // If a workspace was added (workspace parameter is undefined) then load the workspaces to refresh the list
                if (!workspace) {
                    LoadWorkspaces(1);
                } else {
                    // Override the state of the user to be able to view the workspace in the redux store
                    // Also, check to see if the logged in user still has access to this workspace by 
                    // looking to see if this user is still in the list of workspace users.   If
                    // not then they can no longer be here, navigate home.
                    if (!!props.loggedInUser) {
                        const loggedInUserHasAccess = !!userIds.find(userId => userId === props.loggedInUser!.id);
                        props.OverrideUserWorkspaceAccess(workspaceId, props.loggedInUser.id, loggedInUserHasAccess);
                        props.UpdateLoggedInUserAccess(workspaceId, loggedInUserHasAccess);
                    }
                }
            }
        }
        finally {
            props.SetPortalIdle('WorkspaceList:upsertWorkspaceEntity');
        }
    }

    const renderTopContent = (): React.ReactNode => {
        if (errorState) {
            return (<></>);
        }

        return (
            <>
                <div className={'spaced-row'}>
                    <h1>Workspace list</h1>
                    <div className={'spaced-row'} style={{ marginBottom: '20px' }}>
                        {props.userCanAddWorkspace && (
                            <button
                                type="button"
                                className={'small'}
                                style={{ marginRight: '15px' }}
                                onClick={() => {
                                    MetricsService.tryTrackEvent(EventCategory.Workspace, 'AddBegin');
                                    setAddWorkspace({
                                        addWorkspaceModalOpen: true,
                                        addModifyWorkspaceData: {},
                                        showNameRequired: false,
                                        showUnsecureWorkspaceName: false,
                                        showUnsecureEngagementCode: false,
                                    });
                                }}
                                disabled={props.portalBusy || failedToLoadUsers || props.disableNewWorkspaces}
                            >
                                <AddIcon style={{ marginRight: '10px' }} />Add Workspace
                            </button>
                        )}
                        <button
                            type={'button'}
                            className={'small'}
                            onClick={() => {
                                loadWorkspacesForCurrentSettings(true);
                            }}
                            disabled={props.portalBusy}
                        >
                            Refresh
                        </button>
                    </div>
                </div>
                <div className={'spaced-row'} style={{ marginTop: '10px' }}>
                    {!(props.workspacesAreLoading || props.portalBusy) && (
                        <input
                            type={'text'}
                            onChange={
                                (event) => { event.persist(); searchOnChange(event) }}
                            placeholder={'Search by name...'}
                            inputMode={'search'}
                            style={{ width: '250px' }}
                            value={searchWord}
                            id={searchFieldId}
                        >
                        </input>
                    )}
                    {(!!props.workspaces && props.workspaces.length > 0 && !props.workspacesAreLoading) && (
                        <PaginationControls
                            elementsOnPage={props.workspaces.length}
                            elementsPerPage={!!props.workspacePageInfo ? props.workspacePageInfo.workspacesPerPage : 0}
                            totalElements={!!props.workspacePageInfo ? props.workspacePageInfo.totalWorkspaces : 0}
                            pageNumber={!!props.workspacePageInfo ? props.workspacePageInfo.currentPage : 0}
                            pageLeft={pageLeft}
                            pageRight={pageRight}
                            goFirstPage={goFirstPage}
                            goLastPage={goLastPage}
                            isOnLastPage={isOnLastPage}
                            horizontalAligment={'right'}
                        />
                    )}
                </div>
            </>
        );
    }

    const getAccessibleWorkspaceActions = (workspace: WorkspaceWithMeta): JSX.Element[] => {
        let actionContent: JSX.Element[] = [];
        const canManageWorkspaceUsers = WorkspaceActionEnabled(workspace, 'CanManageWorkspaceUsers');

        const stdThemeColors = GetStdThemeColors(props.brandConfig);

        actionContent.push((
            <div
                key={'shareable-link-icon-fksfjkdfj'}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                <Tooltip title={'New Data Collection'}>
                    <span>
                        <button
                            type="button"
                            className={'text small'}
                            style={{
                                marginRight: '10px',
                            }}
                            onClick={() => {
                                const workspaceName = workspace.displayName || workspace.id;

                                props.SetImportCustomerFinancialsActive(
                                    true,
                                    { workspaceId: workspace.id, workspaceName, }
                                );
                            }}
                            disabled={props.portalBusy}
                        >
                            New Data Collection
                        </button>
                    </span>
                </Tooltip>
                <Tooltip title={'Manage user access to this workspace'}>
                    <span>
                        <button
                            type="button"
                            style={{
                                marginRight: '10px',
                            }}
                            onClick={() => {
                                loadWsDetailsAndModifyDetails(workspace.id);
                            }}
                            disabled={props.portalBusy || !canManageWorkspaceUsers}
                        >
                            <UsersIcon style={{ margin: 'auto' }} />
                        </button>
                    </span>
                </Tooltip>
                <Tooltip title={'Delete workspace'}>
                    <span>
                        <button
                            type="button"
                            style={{
                                backgroundColor: stdThemeColors.secondaryColor,
                                marginRight: '10px',
                            }}
                            onClick={() => {
                                setConfirmingWorkspace({
                                    confirmingWorkspaceId: workspace.id,
                                    confirmingWorkspaceName: workspace.displayName,
                                });
                            }}
                            disabled={props.portalBusy}
                        >
                                <DeleteIcon style={{ backgroundColor: stdThemeColors.secondaryColor, margin: 'auto' }} />
                        </button>
                    </span>
                </Tooltip>
            </div>
        ));

        return actionContent;
    }

    const getInaccessibleWorkspaceActions = (workspace: WorkspaceWithMeta): JSX.Element[] => {
        let actionContent: JSX.Element[] = [];

        actionContent.push((
            <div
                key={'shareable-link-icon-fksfjkdfj'}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                {WorkspaceActionEnabled(workspace, 'CanManageWorkspaceUsers') && (
                    <Tooltip title={'Manage user access to this workspace'}>
                        <span>
                            <button
                                type="button"
                                style={{
                                    marginRight: '10px',
                                }}
                                onClick={() => {
                                    requestWsAccess(workspace);
                                }}
                                disabled={props.portalBusy}
                            >
                                <UsersIcon style={{ margin: 'auto' }} />
                            </button>
                        </span>
                    </Tooltip>
                )}
                {!WorkspaceActionEnabled(workspace, 'CanManageWorkspaceUsers') && (
                    <Tooltip title={'Request access to this workspace'}>
                        <span>
                            <button
                                type="button"
                                style={{
                                    marginRight: '10px',
                                }}
                                onClick={() => {
                                    setQueryConfirmAddToWorkspace(workspace)
                                }}
                                disabled={props.portalBusy}
                            >
                                <LockIcon style={{ margin: 'auto' }} />
                            </button>
                        </span>
                    </Tooltip>
                )}
            </div>
        ));

        return actionContent;
    }

    const loadWsDetailsAndModifyDetails = async (workspaceId: string): Promise<void> => {
        props.SetPortalWorking('WorkspaceList:loadWsDetailsAndModifyDetails');
        try {
            const workspace = await LoadWorkspaceDetails(workspaceId, props.allUsers);
            if (!!workspace && !!workspace.entityDetails) {
                setModifyWorkspaceUsers({
                    workspace: workspace.entityDetails,
                    users: workspace.userInformation.users || []
                });
            } else {
                setErrorState({
                    summaryMessage: 'Failed retrieving details for workspace',
                });
                LogMessage(
                    `Error retrieving workspace details to allow user to add themselves to a workspace ${workspaceId}`,
                    SeverityLevel.Error,
                    {
                        workspaceId: workspaceId
                    }
                );
            }
        }
        catch (exception) {
            setErrorState({
                summaryMessage: 'Unable to retrieve existing list of users for the workspace',
                extraInformation: exception.toString(),
            });
            LogException(
                `Error retrieving list of users for a user to add themselves to a workspace ${workspaceId}`,
                exception,
                {
                    workspaceId: workspaceId
                }
            );
        }
        finally {
            props.SetPortalIdle('WorkspaceList:loadWsDetailsAndModifyDetails');
        }
    }

    const requestWsAccess = (workspace: WorkspaceWithMeta): void => {
        const userCanRequestAccess = WorkspaceActionEnabled(workspace, 'CanManageWorkspaceUsers');

        if (userCanRequestAccess) {
            loadWsDetailsAndModifyDetails(workspace.id);
        } else {
            RequestWorkspaceAccess(workspace.id)
                .then((requestResult) => {
                    setAccessRequested({
                        workspace,
                        response: requestResult
                    });
                })
                .catch((exception) => {
                    setErrorState({
                        summaryMessage: 'There was an error requesting access to the workspace',
                        extraInformation: exception.toString(),
                    });
                })
                .finally(() => {
                    props.SetPortalIdle('WorkspaceList:requestWsAccess');
                });

            props.SetPortalWorking('WorkspaceList:requestWsAccess');
        }
    }

    const getEmailSentMsg = (workspace: WorkspaceWithMeta, response: EntityAccessResponse): React.ReactElement => {
        let allRecipients: string[] = [];

        if (response.notificationsSentTo) {
            response.notificationsSentTo.forEach(sentTo => {
                const user = props.allUsers.find(user => user.id === sentTo);
                if (!!user) {
                    allRecipients.push(FullUserName(user));
                }
            })
        }
        if (response.adminRecipients) {
            response.adminRecipients.forEach(sentTo => {
                allRecipients.push(sentTo);
            })
        }

        return (
            <div>
                {((allRecipients.length > 0) && (
                    <div>
                        <p>A notification requesting that you be added to <b>{workspace.displayName}</b> has been sent to your team members below.</p>
                        <br />
                        <ul>
                            {(allRecipients.map((sentTo, index) => {
                                return (<li key={`user-name-listed-${index}`}>{sentTo}</li>);
                            }))}
                        </ul>
                    </div>
                ))}
                {((allRecipients.length <= 0) && (<p>Unable to send a request. All users for {workspace.displayName} have workspace access requested notifications turned off or that notification has been disabled by the admin.</p>))}
            </div>
        );
    }

    const actionColumnWidth = '150px';

    return (
        <div>
            <div>
                {(!!props.jobs) && (!!props.jobs.length) && (
                    <TitleBox
                        title={'Pending data collections'}
                        containerStyle={{ marginBottom: '3em' }}
                    >
                        <RunningImportsList
                            key={props.importsListKey}
                            onJobDismiss={(financialRecordId: string, workspaceId: string, fromNav: boolean) => {
                                // If fromNav is true, then a navigation is going to occur and this call won't complete
                                // (because of the navigation) failing randomly somewhere in the middle.  That causes
                                // random behavior.
                                // 
                                // There's no real reason to LoadWorkspaces as the destination of the nav will most likely
                                // do that.

                                if (!fromNav) {
                                    props.SetPortalWorking('WorkspaceList:loadWorkspacesOnJobDismiss');
                                    LoadWorkspaces(1)
                                        .finally(() => {
                                            props.SetPortalIdle('WorkspaceList:loadWorkspacesOnJobDismiss');
                                        });
                                }
                            }}
                            onManageWorkspaceUsers={(workspaceId, submissionId) => {
                                loadWsDetailsAndModifyDetails(workspaceId)
                            }}
                            showExplanatoryRow={true}
                        />
                    </TitleBox>
                )}
                <div
                    key={props.workspaceListKey}
                    style={{ height: '100%' }}
                >
                    {renderTopContent()}
                    <ErrorBanner
                        errorState={errorState}
                        onDefaultActionButton={() => {
                            setErrorState(undefined);
                        }}
                    />
                    {!errorState && (!props.workspaces || props.workspaces.length === 0) && !props.workspacesAreLoading && (
                        (<h3 style={{ marginTop: '35px' }}>No workspaces found...</h3>)
                    )}
                    {!errorState && props.workspaces && props.workspaces.length > 0 && !props.workspacesAreLoading && (<div className={`workspace-list`}>
                        <table>
                            <thead>
                                <tr className={'column-headers'} key={'0000'}>
                                    <th className={'name'}>Name</th>
                                    <th className={'newest-submission-actions'}></th>
                                    <th className={'date'}>Last Activity</th>
                                    <th className={'date'}>Last Data Collection</th>
                                    <th style={{ width: actionColumnWidth }}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    props.workspaces.map(workspace => {
                                        let actionContent: JSX.Element[] = [];

                                        const userHasAccess = WorkspaceActionEnabled(workspace, 'CanViewWorkspace');

                                        if (userHasAccess) {
                                            actionContent = getAccessibleWorkspaceActions(workspace);
                                        } else {
                                            actionContent = getInaccessibleWorkspaceActions(workspace);
                                        }

                                        /* Returns a workspace row */

                                        return (
                                            <tr className={`normal-rows normal-rows-action-container${workspaceActionMsgLocked === workspace.id ? '-lock-visible' : ''}`} key={workspace.id}>
                                                {userHasAccess && (
                                                    <>
                                                        <td className={'name'}>
                                                            <Tooltip title={workspace.displayName}>
                                                                <>
                                                                    <span
                                                                        className={props.portalBusy ? 'disabled' : 'link'}
                                                                        onClick={() => { navigate(`${pathConstants.workspaceDetailsSubmissionList}/${BuildQueryString({ workspaceId: workspace.id, workspaceName: workspace.displayName || '' })}`) }}
                                                                    >
                                                                        {workspace.displayName}
                                                                    </span>
                                                                    <span className='disabled' style={{ display: "block" }}>
                                                                        {workspace.engagementCode}
                                                                    </span>
                                                                </>
                                                            </Tooltip>
                                                        </td>
                                                        <td className={`newest-submission-actions`}>
                                                            {((!workspaceActionMsgLocked) || (workspaceActionMsgLocked === workspace.id)) && (
                                                                <NewestSubmissionActions
                                                                    workspaceId={workspace.id}
                                                                    onError={(msg) => {
                                                                        setErrorState({
                                                                            summaryMessage: msg
                                                                        })
                                                                    }}
                                                                    onLockVisibility={(lock) => {
                                                                        setWorkspaceActionMsgLocked(lock ? workspace.id : undefined);
                                                                    }}
                                                                />
                                                            )}
                                                        </td>
                                                        <td
                                                            className={'date'}
                                                        >
                                                            <Tooltip title={workspace.displayDateFull}>
                                                                <span>{workspace.displayDate}</span>
                                                            </Tooltip>
                                                        </td>
                                                        <td
                                                            className={'date'}
                                                        >
                                                            <Tooltip title={workspace.lastSyncDisplayDateFull}>
                                                                <span>{workspace.lastSyncDisplayDate}</span>
                                                            </Tooltip>
                                                        </td>
                                                    </>
                                                )}
                                                {!userHasAccess && (
                                                    <>
                                                        <td className={'name disabled'}>
                                                            <span
                                                                className={'link disabled-link'}
                                                                title={workspace.displayName}
                                                            >
                                                                {workspace.displayName}
                                                            </span>
                                                            <span style={{ color: "gray", display: "block" }}>
                                                                {workspace.engagementCode}
                                                            </span>
                                                        </td>
                                                        <td></td>
                                                        <td>---</td>
                                                        <td>---</td>
                                                    </>
                                                )}
                                                <td
                                                    className={'actions'}
                                                    style={{ width: actionColumnWidth }}
                                                >
                                                    {actionContent}
                                                </td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    </div>)}
                    {!errorState && (!!props.workspaces && props.workspaces.length > 0) && !props.workspacesAreLoading && (
                        <PaginationControls
                            elementsOnPage={props.workspaces.length}
                            elementsPerPage={!!props.workspacePageInfo ? props.workspacePageInfo.workspacesPerPage : 0}
                            totalElements={!!props.workspacePageInfo ? props.workspacePageInfo.totalWorkspaces : 0}
                            pageNumber={!!props.workspacePageInfo ? props.workspacePageInfo.currentPage : 0}
                            pageLeft={pageLeft}
                            pageRight={pageRight}
                            goFirstPage={goFirstPage}
                            goLastPage={goLastPage}
                            isOnLastPage={isOnLastPage}
                            horizontalAligment={'center'}
                            defaultContainerStyle={{ marginTop: '15px' }}
                        />
                    )}
                </div>
            </div>
            {
                !!modifyWorkspaceUsers && (
                    <AddModifyWorkspace
                        loggedOnUser={props.loggedInUser}
                        workspace={modifyWorkspaceUsers.workspace}
                        currentUsers={modifyWorkspaceUsers.users}
                        open={true}
                        allUsers={props.allUsers}
                        onCancel={() => {
                            setModifyWorkspaceUsers(undefined);
                        }}
                        onSubmit={async (users) => {
                            await upsertWorkspaceEntity(
                                users,
                                (failed) => {
                                    setModifyWorkspaceUsers(undefined);
                                },
                                modifyWorkspaceUsers.workspace
                            )
                        }}
                    />
                )}
            {
                addWorkspace.addWorkspaceModalOpen && (
                    <AddModifyWorkspace
                        loggedOnUser={props.loggedInUser}
                        workspaceData={addWorkspace.addModifyWorkspaceData}
                        open={true}
                        errorState={addModifyErrorState}
                        allUsers={props.allUsers}
                        onCancel={() => {
                            MetricsService.tryTrackEvent(EventCategory.Workspace, 'AddCanceled');
                            setAddWorkspace({
                                ...addWorkspace,
                                addWorkspaceModalOpen: false,
                            });
                        }}
                        onSubmit={async (users) => {
                            await upsertWorkspaceEntity(
                                users,
                                (failed) => {
                                    if (failed === 'workspaceNotNamed') {
                                        setAddWorkspace({
                                            ...addWorkspace,
                                            showNameRequired: true,
                                        });
                                    } else if (failed === 'unsecureWorkspaceName') {
                                        setAddWorkspace({
                                            ...addWorkspace,
                                            showUnsecureWorkspaceName: true,
                                        });
                                    } else if (failed === 'unsecureEngagementCode') {
                                        setAddWorkspace({
                                            ...addWorkspace,
                                            showUnsecureEngagementCode: true,
                                        });
                                    }
                                    else {
                                        setAddWorkspace({
                                            addWorkspaceModalOpen: false,
                                            addModifyWorkspaceData: {},
                                            showNameRequired: false,
                                            showUnsecureWorkspaceName: false,
                                            showUnsecureEngagementCode: false,
                                        });
                                    }
                                }
                            )
                        }}
                        onWorkspaceDataChange={(workspaceData: AddModifyWorkspaceData) => {
                            let { displayName, engagementCode} = workspaceData;
                            let nameToSet;
                            let showNameRequired = addWorkspace.showNameRequired;

                            if (displayName?.trim() !== '') {
                                nameToSet = displayName;
                                showNameRequired = false;
                            }

                            setAddWorkspace({
                                ...addWorkspace,
                                addModifyWorkspaceData: { ...addWorkspace.addModifyWorkspaceData, displayName: nameToSet, engagementCode: engagementCode },
                                showNameRequired,
                            });
                        }}                            
                    />
                )}
            {
                confirmingWorkspace &&
                <ConfirmModal
                    onTerminalButton={(yes?: boolean): void => {
                        if (!!yes) {
                            const workspaceId: string = confirmingWorkspace.confirmingWorkspaceId;
                            props.SetPortalWorking('WorkspaceList:ConfirmModal:OnTerminalButton');
                            WorkspaceDelete(workspaceId)
                                .finally(() => {
                                    props.SetPortalIdle('WorkspaceList:ConfirmModal:OnTerminalButton');
                                });
                        }
                        setConfirmingWorkspace(undefined);
                    }}
                    msg={`Please confirm that you would like to delete ${confirmingWorkspace.confirmingWorkspaceName}.`}
                    title={`Delete ${confirmingWorkspace.confirmingWorkspaceName}`}
                />
            }
            {
                !!accessRequested && (
                    <ConfirmModal
                        onTerminalButton={(yes?: boolean) => {
                            setAccessRequested(undefined);
                        }}
                        msg={getEmailSentMsg(accessRequested.workspace, accessRequested.response)}
                        title={`Access requested`}
                        modalType={ConfirmModalType.ok}
                    />
                )
            }
            {
                !!queryConfirmAddToWorkspace &&
                <ConfirmModal
                    onTerminalButton={(yes?: boolean): void => {
                        if (!!yes) {
                            requestWsAccess(queryConfirmAddToWorkspace);
                        }
                        setQueryConfirmAddToWorkspace(undefined);
                    }}
                    msg={
                        `Please confirm that you would like to request access to ${queryConfirmAddToWorkspace.displayName}.`
                    }
                    title={
                        `Request access to ${queryConfirmAddToWorkspace.displayName}`
                    }
                />
            }
        </div>
    );
}

export const WorkspaceList = connect<InjectedReduxState, InjectedActionCreators, WorkspaceListProps, ApplicationState>(
    (appState: ApplicationState) => {
        const basicSettings = GetBasicSettings(appState);
        const runningJobsVersion = GetRunningJobsVersion(appState);
        const workspaceInfo = GetWorkspaceInfo(appState);
        const brandConfig = GetBrandConfig(appState);

        const result = {
            allUsers: GetUsers(appState),
            customerFinancialsTarget: GetImportCustomerFinancialsTarget(appState),
            jobs: GetRunningJobs(appState),
            strongboxModalThemes: BuildModalThemeForTenant(brandConfig),
            strongboxUrl: GetStrongboxUrl(appState) || '',
            tenantVisibleName: (basicSettings && basicSettings.visibleName) || '',
            importsListKey: runningJobsVersion.toString(),
            workspaceListKey: (runningJobsVersion + 1).toString(),
            linkParamsActive: GetShareableLinkParamsFlowSwitch(appState),
            userCanAddWorkspace: LoggedInUserHasAccess(appState, 'CanCreateWorkspace'),
            basicTenantSettings: basicSettings,
            workspacesAreLoading: LoadingWorkspaces(appState),
            workspaces: (!!workspaceInfo) ? workspaceInfo.workspaces : [],
            workspacePageInfo: (!!workspaceInfo) ? workspaceInfo.pageInfo : undefined,
            loggedInUser: GetLoggedInUser(appState),
            portalBusy: GetPortalBusyState(appState),
            brandConfig,
            disableNewWorkspaces: GetNewWorkspacesDisabled(appState),
        };

        return result;
    },
    dispatch => bindActionCreators(
        {
            ...ImportFinancialsActions,
            ...NewDataCollectionParametersActions,
            ...RunningImportsActions,
            ...WorkspacesActions,
            ...SubmissionHistoryActions,
            ...UIStateActions,
        },
        dispatch
    )
)(WorkspaceListComponent);

