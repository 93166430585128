import * as React from 'react';
import { connect } from 'react-redux';

import './Main.scss';

import { ApplicationState } from '../Store';
import { GetStrongboxSupportEmail, GetStrongboxHelpCenterLink } from '../Store/AppSettings';

export const portalFooterId = 'strongboxPortalFooter';

type InjectedReduxState = {
    supportLink: string;
    helpCenterLink: string;
};

type InjectedActionCreators = {
};

type PortalFooterProps = {
    contentOverride?: React.ReactElement;
};

type Props = InjectedReduxState & InjectedActionCreators & PortalFooterProps;

const PortalFooterComponent: React.FC<Props> = (props): React.ReactElement => {
    const {
        contentOverride,
        helpCenterLink,
        supportLink,
    } = props;

    return (
        <div id={portalFooterId} className={`portal-footer-container`}>
            <div className={`portal-footer-region`}>
                {!contentOverride && (
                    <React.Fragment>
                        <span>
                            Need help? Contact us at&nbsp;
                            <a href={`mailto:${supportLink}?subject=Strongbox support request`}>{supportLink}</a>
                            &nbsp;or visit our&nbsp;
                            <a href={helpCenterLink} target={'_blank'} rel={'noopener noreferrer'}>Help Center</a>
                        </span>
                        <span>©Finagraph</span>
                    </React.Fragment>
                )}
                {!!contentOverride && contentOverride}
            </div>
        </div>
    );
}

export const PortalFooter = connect<InjectedReduxState, InjectedActionCreators, PortalFooterProps, ApplicationState>(
    (appState: ApplicationState) => {
        return {
            supportLink: GetStrongboxSupportEmail(appState) || '',
            helpCenterLink: GetStrongboxHelpCenterLink(appState) || '',
        };
    },
)(PortalFooterComponent);
