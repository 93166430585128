import * as React from 'react';

import '../../Main.scss';
import './FinancialStatements.scss';

import {
    Button,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Col,
    Label,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    ButtonGroup
} from 'reactstrap';

import { FSModels } from '@finagraph/financial-statement-editor';

import { FinancialStatementRenderData } from './UtilTypes';
import { fsSectionPresentationName, fsSectionVerb } from './FSConstants';

import {
    DangerousInputForm,
    DangerousInputFormChildProps,
    PotentiallyDangerousInput
} from '../../DangerousInputForm';

export type SubsectionOptions = {
    sectionId: string;
    label: string;
    reportedAs: FSModels.CreditOrDebit;
}

export type NewFinancialStatementSection = {
    header?: string;
    footer: string;
    subsectionOf: string | undefined;
    subsectionOfLabel: string;
    positiveTxn: FSModels.CreditOrDebit;
}

type Props = {
    lineItem?: FSModels.FinancialStatementLineItemViewModel;
    onClose: (newSectionInfo: NewFinancialStatementSection | undefined) => void;
    renderData: FinancialStatementRenderData;
};

const defaultCreditOrDebit = FSModels.CreditOrDebit.Credit;

export const AddNewSectionModal: React.FC<Props> = (props): React.ReactElement => {
    const {
        onClose,
        lineItem,
        renderData
    } = props;

    const defaultFooter = 'Total ';

    const idSectionHeader = 'add-new-fs-section-header';
    const idSectionFooter = 'add-new-fs-section-footer';

    const [formData, setFormData] = React.useState<NewFinancialStatementSection>({
        header: '',
        footer: defaultFooter,
        subsectionOf: lineItem?.sectionId,
        subsectionOfLabel: renderData.subsectionOptions.find(so => { return !!lineItem ? so.sectionId === lineItem.id : false })?.label || lineItem?.captionText || 'None',
        positiveTxn: lineItem?.sectionId && renderData.sectionsById.get(lineItem.sectionId) ? renderData.sectionsById.get(lineItem.sectionId)!.reportedAs : defaultCreditOrDebit,
    });

    const [subsectionDropped, setSubsectionDropped] = React.useState<boolean>(false);

    const [firstRender, setFirstRender] = React.useState<boolean>(false);

    const [autoGenerateHeader, setAutoGenerateHeader] = React.useState<boolean>(true);

    React.useEffect(() => {
        setFirstRender(true);
    }, [])

    React.useEffect(() => {
        // firstRender is a little weird.  On the above (useEffect with []) we won't actually have stuff on the screen
        // so we can't change focus. Change firstRender's value so that will trigger another render at which point the
        // control we want to set focus to will ACTUALLY exist.
        if (firstRender) {
            // User will be entering either just one piece of content representing the header or detailed line item caption
            // or a header and footer.  Header has default focus but if the footer is present it should actually have
            // the focus as opposed to the header but will not since it's rendered second.            
            const footer = document.getElementById('add-new-fs-section-footer');

            if (footer !== null) {
                footer.focus();
            }
            setFirstRender(false);
        }
    }, [firstRender])

    const footerChanged = (newValue: string): void => {
        let header = formData.header;

        if (autoGenerateHeader) {
            if (newValue.startsWith(defaultFooter)) {
                header = newValue.substring(defaultFooter.length);
            }
        }

        setFormData({
            ...formData,
            header,
            footer: newValue
        });
    }

    return (
        <Modal
            className={`flight-modal add-financial-statement-subsection`}
            isOpen={true}
            toggle={(e: React.KeyboardEvent) => onClose(undefined)}
            backdrop={'static'}
        >
            <ModalHeader toggle={(e) => onClose(undefined)}>{`Add New ${fsSectionPresentationName}`}</ModalHeader>
            <ModalBody>
                <div>
                    <DangerousInputForm
                        onClose={(contentAccepted) => onClose(contentAccepted ? formData : undefined)}
                        submitText={'Add'}
                        formData={formData}
                        dangerousInputFields={[
                            {
                                formDataName: 'header',
                                fieldId: idSectionHeader
                            },
                            {
                                formDataName: 'footer',
                                fieldId: idSectionFooter
                            }
                        ]}
                    >
                        {(childProps: DangerousInputFormChildProps) => (
                            <React.Fragment>
                                <Row>
                                    <Col xs={4} className={'label-column'}>
                                        <Label htmlFor={idSectionHeader}>Header:</Label>
                                    </Col>
                                    <Col>
                                        <PotentiallyDangerousInput
                                            id={idSectionHeader}
                                            name={idSectionHeader}
                                            value={formData.header}
                                            nameForInvalidMsg={'Header'}
                                            clearFieldWithDangerousContent={childProps.clearFieldWithDangerousContent}
                                            fieldWithDangerousContent={childProps.fieldWithDangerousContent}
                                            updateField={(newValue) => {
                                                setFormData({
                                                    ...formData,
                                                    header: newValue
                                                });
                                                setAutoGenerateHeader(false);
                                            }}
                                        />
                                    </Col>
                                </Row>
                                    <Row>
                                        <Col xs={4} className={'label-column'}>
                                            <Label htmlFor={idSectionFooter}>Footer*:</Label>
                                        </Col>
                                        <Col>
                                            <PotentiallyDangerousInput
                                                id={idSectionFooter}
                                                name={idSectionFooter}
                                                value={formData.footer}
                                                nameForInvalidMsg={'Footer'}
                                                clearFieldWithDangerousContent={childProps.clearFieldWithDangerousContent}
                                                fieldWithDangerousContent={childProps.fieldWithDangerousContent}
                                                updateField={footerChanged}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                    <Col xs={4} className={'label-column'}>
                                        <Label htmlFor={'add-new-fs-section-subsectionof'}>{`${fsSectionVerb} under:`}</Label>
                                        </Col>
                                        <Col>
                                            <Dropdown
                                                id={'add-new-fs-section-subsectionof'}
                                                isOpen={subsectionDropped}
                                                toggle={(e: React.KeyboardEvent) => setSubsectionDropped(!subsectionDropped)}
                                            >
                                                <DropdownToggle caret>
                                                    {formData.subsectionOfLabel}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem
                                                        value={''}
                                                        onClick={() => {
                                                            setFormData({ ...formData, subsectionOf: undefined, subsectionOfLabel: 'None' });
                                                        }}
                                                    >
                                                        <em>None</em>
                                                    </DropdownItem>
                                                    {renderData.subsectionOptions.map((o, i) => (
                                                        <DropdownItem
                                                            key={`add-financial-statement-section-subsectionof-${i}`}
                                                            value={o.sectionId}
                                                            onClick={() => {
                                                                const section = o.sectionId && renderData.sectionsById.get(o.sectionId);

                                                                setFormData({
                                                                    ...formData,
                                                                    subsectionOf: o.sectionId,
                                                                    subsectionOfLabel: o.label,
                                                                    positiveTxn: !!section ? section.reportedAs : formData.positiveTxn
                                                                });
                                                            }}
                                                        >
                                                            {o.label}
                                                        </DropdownItem>)
                                                    )}
                                                </DropdownMenu>
                                            </Dropdown>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginTop: '25px' }}>
                                        <Col xs={7} className={'label-column'}>
                                            <Label>Credit or Debits are positive?</Label>
                                        </Col>
                                        <Col>
                                            <ButtonGroup>
                                                <Button
                                                    color={formData.positiveTxn === FSModels.CreditOrDebit.Credit ? 'primary' : 'secondary'}
                                                    onClick={() => {
                                                        setFormData({
                                                            ...formData,
                                                            positiveTxn: FSModels.CreditOrDebit.Credit
                                                        });
                                                    }}
                                                >
                                                    Credit
                                                </Button>
                                                <Button
                                                    color={formData.positiveTxn === FSModels.CreditOrDebit.Debit ? 'primary' : 'secondary'}
                                                    onClick={() => {
                                                        setFormData({
                                                            ...formData,
                                                            positiveTxn: FSModels.CreditOrDebit.Debit
                                                        });
                                                    }}
                                                >
                                                    Debit
                                                </Button>
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                            </React.Fragment>
                        )}
                    </DangerousInputForm>                                                
                </div>
            </ModalBody>
        </Modal>
    );
}
