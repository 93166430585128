import * as React from 'react';

import '../../Main.scss';

import {
    Container,
    Col,
    Row
} from 'reactstrap';

import { UserResponse } from '../../../Models/Api/strongbox.financialportal';

import { WorkspacePlusUser } from '../../../Store/Workspaces';

import { UserAccessControl } from '../../UserAccessControl/UserAccessControl';

import { FullUserName } from '../../../Utils/UserUtils';
import { maxDisplayNameLength, maxEngagementCodeLength } from '../../../Utils/Constants';

export type WorkspaceEditContent = {
    name: string;
    nameIsBad: boolean;
    engagementCode: string;
    engagementCodeIsBad: boolean;
}

type Props = {
    allUsers: UserResponse[];
    detailsAreModified: boolean;
    loggedInUser?: UserResponse;
    workspaceName: string;
    workspaceId: string;
    workspaceDetails?: WorkspacePlusUser;
    onUserStatusChange?: (user: UserResponse, included: boolean) => void;
    onWorkspaceDetailsChange?: (editContent: WorkspaceEditContent) => void;
    onSaveChanges?: () => void;
    onReset?: () => void;
    onSelectAll?: (selected: boolean) => void;
    editContent: WorkspaceEditContent;
    editErrorContent?: string;
    disableEdit: boolean;
}

export const WorkspaceDetailsTab: React.FC<Props> = (props): React.ReactElement => {
    const {
        detailsAreModified,
        editContent,
        loggedInUser,
        workspaceDetails,
        allUsers,
        onUserStatusChange,
        onSelectAll,
        onSaveChanges,
        onReset,
        onWorkspaceDetailsChange,
        editErrorContent,
        disableEdit
    } = props;

    const [filteredUsers, setFilteredUsers] = React.useState<UserResponse[]>(allUsers.slice());

    const excludedUsers = React.useMemo(() => {
        const result: UserResponse[] = [];

        // Basically, the only time this will run is on initial load. Basically go through the 
        // list of all users to build the list of users that don't currently have access to it, i.e.
        // we don't find them in the list of users (workspaceDetails.users).

        if (!!workspaceDetails) {
            allUsers.forEach(user => {
                if (!workspaceDetails.users.find(currentUser => currentUser.id === user.id)) {
                    result.push(user);
                }
            })
        }

        return result;
    }, [allUsers, workspaceDetails]);

    const detailsNotAvailable = (): React.ReactElement => {
        return (
            <p>
                Details including users are unavailable for this workspace. Please try again later.
            </p>
        )
    }

    const updateEditContent = (newContent: WorkspaceEditContent): void => {
        onWorkspaceDetailsChange && onWorkspaceDetailsChange(newContent);
    }

    const detailsAvailable = (): React.ReactElement => {
        return (
            <Container
                className={`workspace-details-container content-summary-container`}
                fluid
            >
                <Row className={'data-container'}>
                    <Col xs={4}>
                        <label
                            htmlFor="workspaceName"
                            style={{ marginRight: '15px' }}
                        >
                            Workspace Name:
                        </label>
                        <input
                            onChange={(e) => {
                                updateEditContent({
                                    ...editContent,
                                    name: e.currentTarget.value
                                });
                            }}
                            name={'workspaceName'}
                            type={'text'}
                            autoFocus={true}
                            placeholder={'Enter workspace name'}
                            value={editContent.name}
                            maxLength={maxDisplayNameLength}
                            className={editContent.nameIsBad ? 'error-text' : ''}
                            disabled={disableEdit}
                        />
                    </Col>
                    <Col xs={4}>
                        <label
                            style={{ marginRight: '15px' }}
                            htmlFor="engagementCode"
                        >
                            Engagement Code:
                        </label>
                        <input
                            onChange={e => {
                                updateEditContent({
                                    ...editContent,
                                    engagementCode: e.currentTarget.value
                                });
                            }}
                            name={'engagementCode'}
                            type={'text'}
                            autoFocus={true}
                            placeholder={'Enter engagement code'}
                            value={editContent.engagementCode}
                            maxLength={maxEngagementCodeLength}
                            className={editContent.engagementCodeIsBad ? 'error-text' : ''}
                            disabled={disableEdit}
                        />
                    </Col>
                </Row>
                {editErrorContent && (
                    <Row className={'data-container'}>
                        <Col>
                            <span className={'error-text'}>{editErrorContent}</span>
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col className={'data-container'}>
                        {workspaceDetails && (
                            <>
                                <UserAccessControl
                                    includedUsers={workspaceDetails.users}
                                    excludedUsers={excludedUsers}
                                    allUsers={filteredUsers}
                                    onUserStatusChange={onUserStatusChange}
                                    onSelectAll={onSelectAll}
                                    loggedInUser={loggedInUser}
                                    filterUsers={(filter) => {
                                        if (!!allUsers) {
                                            if (filter.length <= 0) {
                                                setFilteredUsers(allUsers.slice());
                                            } else {
                                                setFilteredUsers( allUsers.filter(user =>
                                                        FullUserName(user).toLowerCase().indexOf(filter.toLowerCase()) !== -1
                                                    )
                                                )
                                            }
                                        }
                                    }}
                                />
                            </>
                        )}
                    </Col>
                    <Row>
                        <Col className={'right-aligned-col'}>
                            {
                                // right-aligned-col uses row-reverse so these buttons will actually be in reverse 
                                // order from what is shown here
                            }
                            <button
                                className={'small'}
                                disabled={!detailsAreModified || !workspaceDetails || workspaceDetails.users.length <= 0}
                                onClick={() => {
                                    onSaveChanges && onSaveChanges()
                                }}
                            >
                                Save changes
                            </button>
                            <button
                                className={'small'}
                                disabled={!detailsAreModified}
                                onClick={() => {
                                    onReset && onReset();
                                }}
                                style={{
                                    marginRight: '10px'
                                }}
                            >
                                Reset
                            </button>
                        </Col>
                    </Row>
                </Row>
            </Container>
        )
    }

    return !workspaceDetails ? detailsNotAvailable() : detailsAvailable();
}
