import * as React from 'react';

import {
    Tooltip
} from '@mui/material';

import { DetailedUserResponse, RoleResponse, TenantUserMetadataSetting } from '../../../Models/Api/strongbox.financialportal';
import { FullUserName } from '../../../Utils/UserUtils';

import { BrandConfig } from '../../../Store/Tenant';

import { SortableTable } from '../../SortableTable/SortableTable';

import { FormatDate, formatStringMDYFullMonth } from '../../../Utils/DateUtils';

import { EditUserModal } from './EditUserModal';
import { ConfirmModal, ConfirmModalType } from '../../ConfirmModal/ConfirmModal';

import EditIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import MailIcon from '@mui/icons-material/MailOutlined';

import { ErrorBanner, ErrorState } from '../../ErrorBanner/ErrorBanner';
import { reinviteMessage } from '../../../Utils/Constants';

import '../../Main.scss';

type Props = {
    availableRoles: RoleResponse[];
    users: DetailedUserResponse[];
    loggedInUserExternalId: string;
    brandConfig: BrandConfig;
    onUpdateUser: (user: DetailedUserResponse) => void;
    onDeleteUser: (user: DetailedUserResponse, onComplete?: (errorMsg?: string) => void) => void;
    onReinviteUser: (user: DetailedUserResponse, onComplete: (errorMsg?: string) => void) => void;
    tenantUserMetadataSettings: TenantUserMetadataSetting[];
}

const invitableStatuses = new Set(['Invited', 'PendingInvite']);

export const UserList: React.FC<Props> = (props): React.ReactElement => {
    const [editingUser, setEditingUser] = React.useState<DetailedUserResponse | undefined>(undefined);
    const [confirmDelete, setConfirmDelete] = React.useState<DetailedUserResponse | undefined>(undefined);
    const [errorMsg, setErrorMsg] = React.useState<ErrorState | undefined>(undefined);

    // Will be a mapping for name (id) to label
    const tenantSettingsLabelMap = React.useMemo(() => {
        const result = new Map<string, string>();
        props.tenantUserMetadataSettings.forEach(m => {
            result.set(m.name, m.label);
        });
        return result;
    }, [props.tenantUserMetadataSettings]);

    const getUserStatus = (user: DetailedUserResponse): string => {
        switch (user.status) {
            case 'PendingInvite':
                return 'Invitation pending';
            default:
                return user.status;
        }
    }

    const getRoleDescription = (user: DetailedUserResponse): string => {
        let roles: string[] = [];

        user.resourceRoles.forEach(role => {
            const roleDef = props.availableRoles.find(roleResponse => roleResponse.id === role.roleId);
            if (!!roleDef) {
                roles.push(roleDef.displayName);
            }
        });

        // Put the roles in alphabetical order so they show up the same for every user.  They're not
        // always in the same order in user.resourceRoles
        roles = roles.sort((role1, role2) => role1 === role2 ? 0 : role1 < role2 ? -1 : 1);

        let result = '';
        let needsComma = false;

        roles.forEach(role => {
            result += `${needsComma ? ', ' : ''}${role}`;
            needsComma = true;
        });

        return result;
    }

    const getUserNameCell = (user: DetailedUserResponse): React.ReactElement => {
        const userName = FullUserName(user);

        if (user.metadata.length <= 0) {
            return (<span>{userName}</span>)
        }

        return (
            <div className={'list-box'}>
                <span>{userName}</span>
                <div className={'list-contents'}>
                    {
                        user.metadata.map((m, i) => {
                            const map = tenantSettingsLabelMap.get(m.name);
                            const metadataText = `${map || m.name}: ${m.value}`;
                            return (
                                <span
                                    key={`${userName}-metadata-item-${m.name}-${i}`}
                                >
                                    {metadataText}
                                </span>
                            )
                        })
                    }
                </div>
            </div>
        )
    }

    return (
        <>
            {
                editingUser && (
                    <EditUserModal
                        user={editingUser}
                        availableRoles={props.availableRoles}
                        onDismissModal={(operation, user) => {
                            if (operation === 'save') {
                                user && props.onUpdateUser(user);
                            }
                            setEditingUser(undefined);
                        }}
                        loggedInUserExternalId={props.loggedInUserExternalId}
                    />
                )
            }
            {
                !!confirmDelete &&
                <ConfirmModal
                    msg={`Are you sure you want to delete ${confirmDelete.displayName}`}
                    title={'Warning'}
                    modalType={ConfirmModalType.yesno}
                    onTerminalButton={(yes?: boolean) => {
                        if (!!yes) {
                            props.onDeleteUser(
                                confirmDelete,
                                (errorMsg) => {
                                    if (!!errorMsg) {
                                        setErrorMsg({
                                            summaryMessage: errorMsg,
                                            severity: 'Error'
                                        });
                                    }
                                }
                            );
                        }
                        setConfirmDelete(undefined);
                    }}
                />
            }
            {
                !!errorMsg && (
                    <div className={'sticky-banner-container'}>
                        <ErrorBanner
                            errorState={errorMsg}
                            onDefaultActionButton={() => setErrorMsg(undefined) }
                        />
                    </div>
                )
            }
            <SortableTable<DetailedUserResponse>
                brandConfig={props.brandConfig}
                columns={[
                    {
                        defaultSortOrder: 'none',
                        headerTitle: '',
                        compare: (u1: DetailedUserResponse, u2: DetailedUserResponse) => {
                            return 0;
                        },
                        getRowData: (user: DetailedUserResponse): React.ReactElement => {
                            return (
                                <div
                                    style={{
                                        minWidth: '100px',
                                    }}
                                >
                                    <Tooltip title={`Edit user (${user.displayName})`}>
                                        <EditIcon
                                            color={'primary'}
                                            className={'basic-list-icon actionable-icon'}
                                            id={`editIcon${user.id}`}
                                            onClick={() => setEditingUser(user)}
                                        />
                                    </Tooltip>
                                    {
                                        invitableStatuses.has(user.status) && (user.externalAccountId !== props.loggedInUserExternalId) &&
                                        (
                                            <React.Fragment>
                                                <Tooltip title={`Re-invite user (${user.displayName})`}>
                                                    <MailIcon
                                                        id={`reinviteIcon${user.id}`}
                                                        color={'primary'}
                                                        className={'basic-list-icon actionable-icon'}
                                                        onClick={() => {
                                                            props.onReinviteUser(
                                                                user,
                                                                (errorMsg) => {
                                                                    if (!!errorMsg) {
                                                                        setErrorMsg({
                                                                            summaryMessage: errorMsg,
                                                                            severity: 'Error'
                                                                        });
                                                                    } else {
                                                                        setErrorMsg({
                                                                            summaryMessage: reinviteMessage,
                                                                            severity: 'Notification'
                                                                        });
                                                                    }
                                                                }
                                                            );
                                                        }}
                                                    />
                                                </Tooltip>
                                            </React.Fragment>
                                        )
                                    }
                                    {
                                        (!(invitableStatuses.has(user.status) && (user.externalAccountId !== props.loggedInUserExternalId))) &&
                                        (
                                            <MailIcon
                                                id={`reinviteIcon${user.id}`}
                                                className={'basic-list-icon-spacer'}
                                            />
                                        )
                                    }
                                    {
                                        (user.externalAccountId !== props.loggedInUserExternalId) &&
                                        (
                                            <Tooltip title={`Delete user (${user.displayName})`}>
                                                <DeleteIcon
                                                    color={'error'}
                                                    id={`deleteIcon${user.id}`}
                                                    className={'basic-list-icon actionable-icon'}
                                                    onClick={() => { setConfirmDelete(user) }}
                                                />
                                            </Tooltip>
                                        )
                                    }
                                </div>
                            )
                        }
                    },
                    {
                        defaultSortOrder: 'ascending',
                        headerTitle: 'Users',
                        compare: (u1: DetailedUserResponse, u2: DetailedUserResponse) => {
                            const fullUserName1 = FullUserName(u1).toLowerCase();
                            const fullUserName2 = FullUserName(u2).toLowerCase();

                            return fullUserName1 === fullUserName2 ? 0 : fullUserName1 < fullUserName2 ? -1 : 1;
                        },
                        getRowData: (user: DetailedUserResponse): React.ReactElement => {
                            return getUserNameCell(user);
                        }
                    },
                    {
                        defaultSortOrder: 'ascending',
                        headerTitle: 'Status',
                        compare: (u1: DetailedUserResponse, u2: DetailedUserResponse) => {
                            const status1 = getUserStatus(u1);
                            const status2 = getUserStatus(u2);

                            return status1 === status2 ? 0 : status1 < status2 ? -1 : 1;
                        },
                        getRowData: (user: DetailedUserResponse): React.ReactElement => {
                            return (<>{getUserStatus(user)}</>);
                        }
                    },
                    {
                        defaultSortOrder: 'ascending',
                        headerTitle: 'Primary email',
                        compare: (u1: DetailedUserResponse, u2: DetailedUserResponse) => {
                            const email1 = u1.emailAddress.toLowerCase() || '';
                            const email2 = u2.emailAddress.toLowerCase() || '';

                            return email1 === email2 ? 0 : email1 < email2 ? -1 : 1;
                        },
                        getRowData: (user: DetailedUserResponse): React.ReactElement => {
                            return (<>{user.emailAddress || ''}</>);
                        }
                    },
                    {
                        defaultSortOrder: 'descending',
                        headerTitle: 'Last login',
                        compare: (u1: DetailedUserResponse, u2: DetailedUserResponse) => {
                            const t1 = new Date(u1.lastActivityTime);
                            const t2 = new Date(u2.lastActivityTime);

                            return t1 === t2 ? 0 : t1 < t2 ? -1 : 1;
                        },
                        getRowData: (user: DetailedUserResponse): React.ReactElement => {
                            const d = new Date(user.lastActivityTime);

                            return (<>{FormatDate(d, formatStringMDYFullMonth)}</>);
                        }
                    },
                    {
                        defaultSortOrder: 'ascending',
                        headerTitle: 'Role(s)',
                        compare: (u1: DetailedUserResponse, u2: DetailedUserResponse) => {
                            const r1 = getRoleDescription(u1);
                            const r2 = getRoleDescription(u2);

                            if (r1 !== r2) {
                                return r1 < r2 ? -1 : 1;
                            }

                            const fullUserName1 = FullUserName(u1);
                            const fullUserName2 = FullUserName(u2);

                            return fullUserName1 === fullUserName2 ? 0 : fullUserName1 < fullUserName2 ? -1 : 1;
                        },
                        getRowData: (user: DetailedUserResponse): React.ReactElement => {
                            return (<>{getRoleDescription(user)}</>);
                        }
                    }
                ]}
                data={props.users}
            />
        </>
    );
}
