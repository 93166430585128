import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import '../Main.scss';
import './BreadCrumbs.scss';

import {
    Tooltip
} from '@mui/material';

import InfoIcon from '@mui/icons-material/Info';

export type BreadCrumb = {
    content: string;
    link?: string;
    navCount?: number;
}

type Props = {
    containerStyling?: string;
    crumbs: BreadCrumb[];
    showInformation?: {
        active: boolean;
        tipTitle?: string;
        cancelTip: boolean;
        onInfoClick: (idTarget: string) => void;
    }
}

export const BreadCrumbs: React.FC<Props> = (props): React.ReactElement => {
    const {
        containerStyling,
        crumbs,
        showInformation
    } = props;

    const infoIconId = 'bread-crumbs-info-icon';

    const navigate = useNavigate();

    const InfoIconContent = React.useMemo(() => {
        if (!showInformation) {
            return (<></>);
        }
        return (
            <button
                id={infoIconId}
                onClick={() => showInformation.onInfoClick(infoIconId)}
            >
                <InfoIcon
                    className={'basic-list-icon'}
                />
            </button>
        );

        // I want this to execute equivalent to componentDidMount so this is appropriate
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showInformation]);

    return (
        <div className={`crumb-container ${containerStyling}`}>
            {
                crumbs.map((crumb, index) => {
                    return (index !== 0) ?
                        (
                            <div key={`breadcrumb${index}`}>
                                &nbsp;&gt;&nbsp;
                                <span
                                    className={'link'}
                                    onClick={() => crumb.navCount !== undefined ? navigate(crumb!.navCount) : navigate(crumb.link || '')}
                                >
                                    {crumb.content}
                                </span>
                            </div>
                        ) :
                        (
                            <div key={`breadcrumb${index}`}>
                                <span
                                    className={'link'}
                                    onClick={() => crumb.navCount !== undefined ? navigate(crumb!.navCount) : navigate(crumb.link || '')}
                                >
                                    {crumb.content}
                                </span>
                            </div>
                        )
                })
            }
            {
                !!showInformation && showInformation.active && !!showInformation.tipTitle && (
                    <Tooltip title={showInformation.cancelTip ? '' : showInformation!.tipTitle}>
                        {InfoIconContent}
                    </Tooltip>
                )
            }
            {
                !!showInformation && showInformation.active && !showInformation.tipTitle && (
                    <>
                        {InfoIconContent}
                    </>
                )
            }
        </div>
    );
}
