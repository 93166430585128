import { Reducer } from 'redux';

import {
    ISetResource,
    KnownAction,
    KnownTenants,
    TenantActions,
    TenantBasicSettings,
    TenantResourceList
} from './Actions';

import {
    BannerMessage,
    TenantUserMetadataSetting,
} from '../../Models/Api/strongbox.financialportal';

export type BrandThemeSettings = {
    palette: {
        modalBackground?: string;
        modalTitle?: string;
        modalText?: string;
        controlBackground?: string;
        controlForeground?: string;
        primaryColor?: string;
        secondaryColor?: string;
    },
    font: {
        modalFamily?: string;
        modalTitleSize?: string;
        modalTitleWeight?: string;
        modalRegularSize?: string;
        modalRegularWeight?: string;
        modalSecuritySize?: string;
        modalSecurityWeight?: string;
    }
};

export type BrandConfig = {
    themeSettings: BrandThemeSettings;
    cssSettings?: any;
};

export const normalButtonColor = '01bbc8';
export const normalButtonText = 'white';

export const defaultBrandConfig: BrandConfig = {
    themeSettings: {
        palette: {
            controlBackground: normalButtonColor,
            controlForeground: normalButtonText,
        },
        font: {}
    }
}

export interface ITenantState {
    tenantName: KnownTenants;
    basicSettings: TenantBasicSettings | undefined;
    brandConfig: BrandConfig;
    resourceLists: TenantResourceList[];
    emailDomains: string[];  // the list of email domains that can be used for a member of the tenant space, e.g.
    // valid domains that can be used inviting a user.
    userMetadataSettings: TenantUserMetadataSetting[]; // The possible set of additional metadata that can be set for a user
    bannerMessages: BannerMessage[]; // Messages that show up at the top of the portal based on tenant, feature availability, etc.
}

const defaultState: ITenantState = {
    tenantName: KnownTenants.strongbox,
    basicSettings: undefined,
    resourceLists: [],
    brandConfig: defaultBrandConfig,
    emailDomains: [],
    userMetadataSettings: [],
    bannerMessages: [],
}

export const tenantOverrideKey = 'tenantNameOverride';

export const reducer: Reducer<ITenantState, KnownAction> = (state: ITenantState | undefined, action: KnownAction): ITenantState => {
    let newState: ITenantState | undefined = undefined;

    switch (action.type) {
        case TenantActions.TenantSetBasicSettings: {
            newState = {
                ...(state || defaultState),
                basicSettings: action.settings,
            };
            break;
        }

        case TenantActions.TenantSetResource: {
            const setResourceAction: ISetResource = action;

            let newList: TenantResourceList[];

            if (state) {
                let iExisting = state.resourceLists.findIndex(rl => rl.resourceName === action.list.resourceName);
                if (iExisting !== -1) {
                    newList = state.resourceLists.slice(0, iExisting).concat(state.resourceLists.slice(iExisting + 1));
                } else {
                    newList = state.resourceLists.slice();
                }
                newList.push({
                    ...setResourceAction.list,
                    resources: setResourceAction.list.resources.slice(),
                });

                newState = {
                    ...state,
                    resourceLists: newList,
                }
            }
            break;
        }

        case TenantActions.TenantSetActiveTenant: {
            newState = {
                ...(state || defaultState)
            };

            try {
                newState.tenantName = KnownTenants[action.tenant as keyof typeof KnownTenants]
                localStorage.setItem(tenantOverrideKey, newState.tenantName);
            } catch (exception) {
                newState = undefined;
            }
            break;
        }

        case TenantActions.TenantSetBrandConfig: {
            newState = {
                ...(state || defaultState),
                brandConfig: {
                    ...action.brandconfig
                }
            };
            break;
        }

        case TenantActions.TenantListEmailDomains: {
            // Nothing to do here now.
            break;
        }

        case TenantActions.TenantListEmailDomainsComplete: {
            newState = {
                ...(state || defaultState),
                emailDomains: action.domains.slice(),
            };
            break;
        }

        case TenantActions.TenantListUserMetadataSettings: {
            // Nothing to do here yet.
            break;
        }

        case TenantActions.TenantListUserMetadataSettingsComplete: {
            newState = {
                ...(state || defaultState),
                userMetadataSettings: action.metadataSettings.slice(),
            }
            break;
        }

        case TenantActions.TenantGetBannerMessages: {
            // Nothing to do here yet.
            break;
        }

        case TenantActions.TenantGetBannerMessagesComplete: {
            newState = {
                ...(state || defaultState),
                bannerMessages: action.messages.slice(),
            }
            break;
        }
    }
    return newState ? newState : state ? state : defaultState;
}
