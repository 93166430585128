import * as React from 'react';

import '../Main.scss';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CheckIcon from '@mui/icons-material/CheckCircleRounded';
import StrongboxLock from '../../images/StrongboxLock.svg';

import {
    Button,
    Row,
    Col
} from 'reactstrap';

import ReactInlineSvg from 'react-inlinesvg';

import { ErrorState } from '../ErrorBanner/ErrorBanner';

import { Bullet } from '../Bullet/Bullet';

import { SetSubmissionEmail } from '../../Services/SubmissionService';
import { GetBorrowerAnonymousState } from '../../Store/Borrower';
import { BorrowerActionCreators } from '../../Store/Borrower';
import { GetRequestedOptions } from '../../Store/NewDataCollectionParameters';
import { ResourceName, RetrieveResource, TenantParagraphSet, TenantAttributedParagraphSet } from '../../Store/Tenant';
import {
    GetDefaultSubmissionOptions,
    GetMultipleBorrowerImportsAllowed
} from '../../Store/AppSettings';
import { actionCreators as ImportFinancialsActions } from '../../Store/ImportFinancials';
import { ApplicationState } from '../../Store';
import { TestEmail } from '../../Utils/FormUtils';
import { ConfirmModal, ConfirmModalType } from '../ConfirmModal/ConfirmModal';

import { PortalCreateSubmissionRequestOptions } from '../../Models/PortalCreateSubmissionRequestOptions';

import { BorrowerPortalChildProps } from './BorrowerPortal';

import { ReceiveEmailedDocumentWidget } from './ReceiveEmailedDocumentWidget';
import { UploadAdditionalFilesWidget } from './UploadAdditionalFilesWidget';
import { NewImportWidget } from './NewImportWidget';
import { AccountingPkgPresentation } from '../SelectAccountingSystem/AccountingPkgPresentation';

type AdditionalBorrowerAction = {
    descriptionText: string;
    imageClass: string;
    disabled: boolean;
    renderIndex: number;
    selected: boolean;
    id: 'importAdditional' | 'uploadOtherDocs' | 'requestEmailedCopy';
};

type InjectedReduxState = {
    borrowerAnonymous: boolean;
    shareableLinkOptions: PortalCreateSubmissionRequestOptions | undefined;
    linkedText: TenantParagraphSet;
    linkedTextImportOnly: TenantParagraphSet;
    linkedTextNoActions: TenantParagraphSet;
    readyToUploadDocsText: TenantAttributedParagraphSet;
    needToSubmitMoreDocs: TenantAttributedParagraphSet;
    defaultAnonymousSubmissionOptions: PortalCreateSubmissionRequestOptions;
    allowMultipleBorrowerImports: boolean;
};

type InjectedActionCreators = typeof BorrowerActionCreators & typeof ImportFinancialsActions;

type Props = BorrowerPortalChildProps & InjectedActionCreators & InjectedReduxState;

const borrowerEmailSubmissionError = 'We\'re sorry, an error occurred saving your email address. Please try this again later.'

export const LinkedComponent: React.FC<Props> = (props): React.ReactElement => {
    const {
        borrowerId,
        defaultAnonymousSubmissionOptions,
        importAccountingPkg,
        shareableLinkOptions,
        SetBorrowerSubmissionId,
        submissionId,
        allowMultipleBorrowerImports
    } = props;

    const [borrowerEmailValid, setBorrowerEmailValid] = React.useState<boolean>(false);
    const [borrowerEmail, setBorrowerEmail] = React.useState<string>('');
    const [settingSubmissionEmail, setSettingSubmissionEmail] = React.useState<boolean>(false);
    const [submissionEmailError, setSubmissionEmailError] = React.useState<ErrorState | undefined>(undefined);
    const [currentEmailSubmitted, setCurrentEmailSubmitted] = React.useState<boolean>(false);
    const [showEmailConfirm, setShowEmailConfirm] = React.useState<string | undefined>(undefined);
    const [additionalBorrowerActions, setAdditionalBorrowerActions] = React.useState<AdditionalBorrowerAction[]>([]);
    const [activeBorrowerAction, setActiveBorrowerAction] = React.useState<AdditionalBorrowerAction | undefined>(undefined);
    const [disableBorrowerAction, setDisableBorrowerAction] = React.useState<boolean>(false);

    const [importWhenSubmissionIdClears, setImportWhenSubmissionIdClears] = React.useState<AccountingPkgPresentation | undefined>(undefined);

    React.useEffect(() => {
        props.ImportHasStarted();
        // I want this to execute equivalent to componentDidMount so this is appropriate
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const borrowerEmailChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const newEmail = event.currentTarget.value.trimLeft();

        const valid = (newEmail !== '') && TestEmail(newEmail);

        setBorrowerEmail(newEmail);
        setBorrowerEmailValid(valid);
        setCurrentEmailSubmitted(false);
        setShowEmailConfirm(undefined);
    }

    const submitBorrowerEmail = async (): Promise<void> => {
        // This is a total sanity check.  The button shouldn't be enabled if this is not true
        // so we should never arrive here if it's false.
        if(!borrowerEmailValid) {
            return;
        }

        setSettingSubmissionEmail(true);
        setDisableBorrowerAction(true);

        try {
            await SetSubmissionEmail(props.borrowerId, props.submissionId, borrowerEmail);

            setSettingSubmissionEmail(false);
            setSubmissionEmailError(undefined);
            setCurrentEmailSubmitted(true);
            setShowEmailConfirm(borrowerEmail);
        } catch (exception) {
            setSettingSubmissionEmail(false);
            setSubmissionEmailError({
                summaryMessage: borrowerEmailSubmissionError,
                extraInformation: exception.toString(),
            });
            setCurrentEmailSubmitted(false);
        } finally {
            setDisableBorrowerAction(false);
        }

    }

    const renderEmailDocWidget = (): React.ReactElement => {
        return (
            <ReceiveEmailedDocumentWidget
                borrowerEmail={borrowerEmail}
                borrowerEmailChanged={borrowerEmailChanged}
                disabled={!borrowerEmailValid || settingSubmissionEmail || currentEmailSubmitted}
                submitBorrowerEmail={submitBorrowerEmail}
                errorMsg={submissionEmailError}
            />
        );
    }

    const renderUploadFilesWidget = (): React.ReactElement => {
        return (
            <UploadAdditionalFilesWidget
                borrowerId={borrowerId}
                submissionId={submissionId}
                onWorking={(working) => setDisableBorrowerAction(working) }
            />
        )
    }

    React.useEffect(() => {
        if ((!submissionId) && (!!importWhenSubmissionIdClears)) {
            // Shouldn't have to worry about anonymous flow here.  To get past the initial screen the 
            // user had to submit a workspace name and email address at which point a workspace was
            // created and the ID for that workspace is in the store. We pass true in the second parameter
            // to override the default check for it not being an anonymous submission.
            importAccountingPkg(importWhenSubmissionIdClears, true);

            setImportWhenSubmissionIdClears(undefined);
        }

        // importAccountingPkg and setImportWhenSubmissionIdClears don't need to be in the dependency list
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [importWhenSubmissionIdClears, submissionId])

    const renderNewImportWidget = (): React.ReactElement => {
        return (
            <NewImportWidget
                borrowerPortalProps={props}
                importFinancials={(pkg) => {
                    // Clear out the existing submission id so we go forward with a new one.
                    SetBorrowerSubmissionId('');

                    // We need the submissionId to actually clear so we go into a temporary state where
                    // we're waiting for it to clear at which point we'll run a new import
                    setImportWhenSubmissionIdClears(pkg);
                }}
                tabIndexBase={additionalBorrowerActions.length + 1}
            />
        )
    }

    // This is a little weird. Due to the vagaries of the way 'const' things work during a render
    // having the activeBorrowerAction contain a function or lambda doesn't really work since
    // it doesn't get changed.  Rather, we create this array that references the methods and define
    // the index each of them lives at. 
    //
    // When we build the array of actionable items, we reference the index constants that reliably
    // map back to the correct method. Then during render, if there's an active action, we use its
    // index into the widgetRenders array to render the content.  This keeps everything up to date
    // and working well.

    const widgetRenderers = [
        renderUploadFilesWidget,
        renderEmailDocWidget,
        renderNewImportWidget
    ];

    const uploadFilesWidgetIndex = 0;
    const emailDocWidgetIndex = 1;
    const newImportWidgetIndex = 2;

    React.useEffect(() => {
        const newActions: AdditionalBorrowerAction[] = [];

        // We used to check to ensure that the disableFileUpload flag was not enabled, however,
        // it is conceivable and configuration supports that the tenant could choose to disable
        // the ability to CHANGE the allow additional file upload setting while also setting the
        // default value to true.   
        //
        // So the ability to see and change the setting is untethered from what the setting 
        // actually is.

        if (
            (((!shareableLinkOptions) && (defaultAnonymousSubmissionOptions.allowUserUpload)) ||
                ((!!shareableLinkOptions) && (shareableLinkOptions.allowUserUpload))) &&
            (!!borrowerId)
        ) {
            newActions.push({
                descriptionText: 'Upload additional documents',
                imageClass: 'upload-additional-image',
                disabled: disableBorrowerAction,
                renderIndex: uploadFilesWidgetIndex,
                selected: !!activeBorrowerAction && activeBorrowerAction.renderIndex === uploadFilesWidgetIndex,
                id: 'uploadOtherDocs'
            });
        }

        if (
            ((!shareableLinkOptions && defaultAnonymousSubmissionOptions.provideUserCopy) ||
             (!!shareableLinkOptions && shareableLinkOptions.provideUserCopy)) &&
            (!!borrowerId) &&
            (!!SetBorrowerSubmissionId)
        ) {
            newActions.push({
                descriptionText: 'Email yourself a copy of submitted files',
                imageClass: 'send-email-image',
                disabled: disableBorrowerAction,
                renderIndex: emailDocWidgetIndex,
                selected: !!activeBorrowerAction && activeBorrowerAction.renderIndex === emailDocWidgetIndex,
                id: 'requestEmailedCopy'
            });
        }

        if (allowMultipleBorrowerImports) {
            newActions.push({
                descriptionText: 'Import additional financials',
                imageClass: 'repeat-import-image',
                disabled: disableBorrowerAction,
                renderIndex: newImportWidgetIndex,
                selected: !!activeBorrowerAction && activeBorrowerAction.renderIndex === newImportWidgetIndex,
                id: 'importAdditional'
            })
        }

        setAdditionalBorrowerActions(newActions);

        // I want this to execute equivalent to componentDidMount so this is appropriate, 
        // having the actual dependencies results in circular rendering and an infinite loop
        // Except for disableBorrowerAction and activeBorrowerAction...then things are broken 
        // if we don't rerender on that dependency changing.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeBorrowerAction, disableBorrowerAction]);

    const mainMessage = React.useMemo(() => {
        if (additionalBorrowerActions.length === 0) {
            return props.linkedTextNoActions;
        }
        if (additionalBorrowerActions.length > 1) {
            return props.linkedText;
        }
        // At this point the list of actions has a length of 1.  
        return !!additionalBorrowerActions.find(action => action.id === 'importAdditional') ? props.linkedTextImportOnly : props.linkedText;

        // This is the actual list of dependencies I want
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [additionalBorrowerActions]);

    const bullets: number[] = [1, 2, 3];
    const imgWidthHeight = '60px';

    return (
        <>
            {!activeBorrowerAction && (
                <>
                    <Row style={{ marginTop: '45px' }}>
                        <Col xs={12} className={'centered-col'}>
                            <ReactInlineSvg
                                style={{
                                    width: imgWidthHeight,
                                    height: imgWidthHeight
                                }}
                                className={'ellipse-list-separator'}
                                src={StrongboxLock}
                            />
                            {
                                bullets.map((bulletNumber, i) => (
                                    <Bullet
                                        key={`bullet-linked-05982304938409-${i}`}
                                        className={'ellipse-list-separator'}
                                        bulletClassName={`bullet-small`}
                                        bulletText={' '}
                                        style={{}}
                                    />
                                ))
                            }
                            <CheckIcon className={'large-icon'} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className={'centered-col'}>
                            {
                                mainMessage.paragraphs.map((paragraph, index) => {
                                    if (index === 0) {
                                        return (
                                            <h1 key={`mmp-9238403840239-${index}`} className={'leading-paragraph-text-grouping'}>{paragraph}</h1>
                                        );
                                    } else {
                                        return (
                                            <h2 key={`mmp-9238403840239-${index}`} className={'regular-paragraph-text-grouping'}>{paragraph}</h2>
                                        );
                                    }
                                })
                            }
                        </Col>
                    </Row>
                </>
            )}
            {props.borrowerAnonymous && (
                <Row>
                    <Col xs={12} className={'centered-col'}>
                        <h2 className={'normal leading-paragraph-text-grouping'}>Please check your inbox for an email from our team to verify your account.</h2>
                    </Col>
                </Row>
            )}
            <Row className={'hex-button-action-container additional-actions-container'}>
                {
                    additionalBorrowerActions.map((borrowerAction, index) => {
                        return (
                            <Col
                                className={'additional-action-col'}
                                key={`additional-borrower-action-col-${index}`}
                            >
                                <Button
                                    onClick={() => setActiveBorrowerAction(borrowerAction)}
                                    disabled={borrowerAction.disabled}
                                    className={`basic-vertical-spacing-thin ${!activeBorrowerAction ? 'regular-hex-button' : 'small-hex-button'} ${borrowerAction.selected ? 'selected' : ''}`}
                                    tabIndex={index + 1}
                                    autoFocus={index === 0}
                                >
                                    <span
                                        className={`icon-container ${!activeBorrowerAction ? 'regular-icon-container' : 'small-icon-container'} ${borrowerAction.imageClass}`}
                                        aria-label={borrowerAction.descriptionText}
                                    />
                                </Button>
                                <span>{borrowerAction.descriptionText}</span>
                            </Col>
                        );
                    })
                }
            </Row>
            {
                !!activeBorrowerAction && (
                    <Row className={'active-action-container'}>
                        <Col>
                            {widgetRenderers[activeBorrowerAction.renderIndex]()}
                        </Col>
                    </Row>
                )
            }
            {showEmailConfirm && (
                <ConfirmModal
                    msg={`You will receive an email shortly at ${borrowerEmail}.`}
                    onTerminalButton={(yes?: boolean) => {
                        setShowEmailConfirm(undefined);
                    }}
                    title={'Email submitted'}
                    modalType={ConfirmModalType.ok}
                />
            )}
        </>
    );
}

export const Linked = connect<InjectedReduxState, InjectedActionCreators, BorrowerPortalChildProps, ApplicationState>(
    (appState: ApplicationState) => {
        const result = {
            borrowerAnonymous: GetBorrowerAnonymousState(appState),
            linkedText: RetrieveResource(appState, ResourceName.tenantLinkComplete),
            linkedTextImportOnly: RetrieveResource(appState, ResourceName.tenantLinkCompleteImportOnly),
            linkedTextNoActions: RetrieveResource(appState, ResourceName.tenantLinkCompleteNoActions),
            shareableLinkOptions: GetRequestedOptions(appState),
            readyToUploadDocsText: RetrieveResource(appState, ResourceName.tenantLinkReadyToUploadMoreDocs),
            needToSubmitMoreDocs: RetrieveResource(appState, ResourceName.tenantLinkSubmitMoreDocs),
            defaultAnonymousSubmissionOptions: GetDefaultSubmissionOptions(appState, 'shareableanonymous', true),
            allowMultipleBorrowerImports: GetMultipleBorrowerImportsAllowed(appState),
        };

        if (!result.linkedText) {
            result.linkedText = {
                paragraphs: [
                    'Success! You may now close this window or choose from the following:',
                ]
            }
        }

        if (!result.linkedTextNoActions) {
            result.linkedTextNoActions = {
                paragraphs: [
                    'Success! You may now close this window.'
                ]
            }
        }

        if (!result.linkedTextImportOnly) {
            result.linkedTextImportOnly = {
                paragraphs: [
                    'Success! You may now close this window or import additional financials:',
                ]
            }
        }

        if (!result.readyToUploadDocsText) {
            result.readyToUploadDocsText = {
                paragraphs: [
                    {
                        attributes: [],
                        content: 'Now you\'re ready to upload documents.'
                    }
                ]
            }
        }
        if (!result.needToSubmitMoreDocs) {
            result.needToSubmitMoreDocs = {
                paragraphs: [
                    {
                        attributes: [],
                        content: 'Need to submit more documents to our team?'
                    }
                ]
            }
        }

        return result;
    },
    dispatch => bindActionCreators(
        {
            ...BorrowerActionCreators,
            ...ImportFinancialsActions,
        },
        dispatch
    )
)(LinkedComponent);

