/**
 * Clean a string from stuff that can't go in an HTML DOM selector. The only valid characters are
 * alphanumeric, '_' and '-'.
 * 
 * @param selector the string to be sanitized
 * 
 * @returns a string that has any invalid characters replaced with '-' 
 *
 */

export function SanitizeDOMSelector(selector: string): string {
    // The regular expression here matches any and all (g - the global flag) characters that are NOT (^) alphanumeric or _  (\w) and - (\-)

    return selector.replace(/[^\w-]/g, '-');
}
