import {
    FinancialStatementError
} from './Reducer';

import { FinancialStatementFacade, FSModels } from '@finagraph/financial-statement-editor';

import {
    FinancialStatementTemplatesList,
    WorkbookRevisionStatus
} from '../../Models/Api/strongbox.financialportal';

export enum FinancialStatementsActions {
    LoadFinancialStatementStarted = 'FINANCIALSTATEMETNS/LoadFinancialStatementStarted',
    InitializeFinancialStatement = 'FINANCIALSTATEMENTS/InitializeFinancialStatement',
    ChangeReportingPeriod = 'FINANCIALSTATEMENTS/ChangeReportingPeriod',
    ChangeReportingPeriodSortOrder = 'FINANCIALSTATEMENTS/ChangeReportingPeriodSortOrder',
    UpdateLineItemCaptions = 'FINANCIALSTATEMENTS/UpdateLineItemCaptions',
    AddClassificationSection = 'FINANCIALSTATEMENTS/AddClassificationSection',
    DeleteClassificationSection = 'FINANCIALSTATEMENTS/DeleteClassificationSection',
    SetReportedAs = 'FINANCIALSTATEMENTS/SetReportedAs',
    DecrementLineItemSortOrder = 'FINANCIALSTATEMENTS/DecrementLineItemSortOrder',
    IncrementLineItemSortOrder = 'FINANCIALSTATEMENTS/IncrementLineItemSortOrder',
    MoveLineItems = 'FINANCIALSTATEMENTS/MoveLineItems',
    SortLineItems = 'FINANCIALSTATEMENTS/SortLineItems',
    DeleteLineItems = 'FINANCIALSTATEMENTS/DeleteLineItems',
    SetFilterState = 'FINANCIALSTATEMENTS/SetFilterState',
    SetFinancialStatementErrorState = 'FINANCIALSTATEMENTS/SetErrorState',
    SaveFinancialStatementComplete = 'FINANCIALSTATEMENTS/SaveFinancialStatementComplete',
    RegeneratingWorkbooksStarted = 'FINANCIALSTATEMENTS/RegeneratingWorkbooksStarted',
    LoadFinancialStatementTemplatesListCompleted = 'FINANCIALSTATEMENTS/ListFinancialStatementTemplates',
    UpdateWorkbookRegenerationStatuses = 'FINANCIALSTATEMENTS/UpdateWorkbookRegenerationStatus',
    UsePredefinedNumberStyle = 'FINANCIALSTATEMENTS/UsePredefinedNumberStyle',
    ClearCompletedRevisionTracking = 'FINANCIALSTATEMENTS/ClearCompletedRevisionTracking',
    DeletedFinancialStatementTemplate = 'FINANCIALSTATEMENTS/DeletedFinancialStatementTemplate'
}

export interface ISetFinancialStatementErrorState {
    type: FinancialStatementsActions.SetFinancialStatementErrorState;
    errorState: FinancialStatementError | undefined;
}

export interface IInitializeFinancialStatement {
    type: FinancialStatementsActions.InitializeFinancialStatement;
    workspaceId: string;
    financialRecordId: string;
    financialStatementType: FSModels.FinancialStatementType;
    financialStatementFacade: FinancialStatementFacade;
    dirty?: boolean;   // If provided will set dirty to this.
}

export interface ILoadFinancialStatementStarted {
    type: FinancialStatementsActions.LoadFinancialStatementStarted;
    workspaceId: string;
    financialRecordId: string;
    financialStatementType: FSModels.FinancialStatementType;
}

export interface IChangeReportingPeriod {
    type: FinancialStatementsActions.ChangeReportingPeriod;
    reportingPeriod: FSModels.ReportingPeriodTypes;
}

export interface IChangeReportingPeriodSortOrder {
    type: FinancialStatementsActions.ChangeReportingPeriodSortOrder;
    reportingPeriodSortOrder: FSModels.ChronologicalSortOrder;
}

export interface IUpdateLineItemCaptions {
    type: FinancialStatementsActions.UpdateLineItemCaptions;
    request: FSModels.EditLineItemCaptionAction[];
}

export interface IAddClassificationSection {
    type: FinancialStatementsActions.AddClassificationSection;
    request: FSModels.AddClassificationSectionAction;
}

export interface IDeleteClassificationSection {
    type: FinancialStatementsActions.DeleteClassificationSection;
    sectionId: string;
    relocateContentsToSectionId: string | undefined;
}

export interface ISetReportedAs {
    type: FinancialStatementsActions.SetReportedAs;
    sectionId: string;
    reportedAs: FSModels.CreditOrDebit;
}

export interface IMoveLineItems {
    type: FinancialStatementsActions.MoveLineItems;
    request: FSModels.MoveLineItemAction[];
}

export interface IDecrementLineItemSortOrder {
    type: FinancialStatementsActions.DecrementLineItemSortOrder;
    lineItemId: string;
}

export interface IIncrementLineItemSortOrder {
    type: FinancialStatementsActions.IncrementLineItemSortOrder;
    lineItemId: string;
}

export interface ISortLineItems {
    type: FinancialStatementsActions.SortLineItems;
    orderBy: FSModels.OrderLineItemsBy;
    orderSubsections: FSModels.OrderSubsections;
    sectionId?: string | undefined;
    accountingPeriodId?: string | undefined;
}

export interface IDeleteLineItems {
    type: FinancialStatementsActions.DeleteLineItems;
    request: FSModels.DeleteLineItemAction[];
}

export interface ISetFilterState {
    type: FinancialStatementsActions.SetFilterState;
    financialStatementType: FSModels.FinancialStatementType;
    filterType: FSModels.FinancialStatementLineItemType;
    state: boolean;
}

export interface ISaveFinancialStatementComplete {
    type: FinancialStatementsActions.SaveFinancialStatementComplete,
}

export interface IRegeneratingWorkbooksStarted {
    type: FinancialStatementsActions.RegeneratingWorkbooksStarted;
    workbookRevisionNumber: number;
}

export interface ILoadFinancialStatementTemplatesListCompleted {
    type: FinancialStatementsActions.LoadFinancialStatementTemplatesListCompleted;
    templatesList: FinancialStatementTemplatesList;
}

export interface IUpdateWorkbookRegenerationStatuses {
    type: FinancialStatementsActions.UpdateWorkbookRegenerationStatuses;
    workspaceId: string;
    financialRecordId: string;
    statuses: WorkbookRevisionStatus[];
}

export interface IUsePredefinedNumberStyle {
    type: FinancialStatementsActions.UsePredefinedNumberStyle;
    predefinedNumberStyle: FSModels.PredefinedMonetaryNumberStyle;
}

export interface IClearCompletedRevisionTracking {
    type: FinancialStatementsActions.ClearCompletedRevisionTracking;
}

export interface IDeletedFinancialStatementTemplate {
    type: FinancialStatementsActions.DeletedFinancialStatementTemplate;
    templateId: string;
}

export type KnownAction =
    IInitializeFinancialStatement |
    ILoadFinancialStatementStarted |
    IChangeReportingPeriod |
    IChangeReportingPeriodSortOrder |
    IAddClassificationSection |
    IDeleteClassificationSection |
    ISetReportedAs |
    IIncrementLineItemSortOrder |
    IDecrementLineItemSortOrder |
    IMoveLineItems |
    ISortLineItems |
    IDeleteLineItems |
    IUpdateLineItemCaptions |
    ISetFilterState |
    ISetFinancialStatementErrorState |
    ISaveFinancialStatementComplete |
    IRegeneratingWorkbooksStarted |
    ILoadFinancialStatementTemplatesListCompleted |
    IUpdateWorkbookRegenerationStatuses |
    IUsePredefinedNumberStyle |
    IClearCompletedRevisionTracking |
    IDeletedFinancialStatementTemplate;
