import '../Main.scss';

import * as React from 'react';

import {
    Button,
    Col,
    Container,
    Modal,
    ModalBody,
    ModalFooter,
    Row
} from 'reactstrap';

import { Theme, ThemeProvider } from '@mui/material/styles';
import { CreateStdTheme } from '../../Utils/Style';

import CheckIcon from '@mui/icons-material/CheckCircleRounded';
import DismissIcon from '@mui/icons-material/Close';

import { BrandConfig, TenantAttributedParagraphSet } from '../../Store/Tenant';
import { PortalCreateSubmissionRequestOptions } from '../../Models/PortalCreateSubmissionRequestOptions';

import { FormatDate, formatStringMDYFullMonth } from '../../Utils/DateUtils';

import { PortalHeader } from '../PortalHeader';

import { AccountingPkgPresentation } from '../SelectAccountingSystem/AccountingPkgPresentation';

type Props = {
    dismissModal: (cancelled: boolean) => void;
    lenderName: string;
    parameters: PortalCreateSubmissionRequestOptions;
    brandConfig: BrandConfig;
    reportsList: string[];
    accountingPkg?: AccountingPkgPresentation;
    securityBullets: TenantAttributedParagraphSet;
};

type BulletPoint = {
    bullet: string;
    bulletParagraphs: string[];
};

export const BorrowerParametersModal: React.FC<Props> = (props): React.ReactElement => {
    const {
        accountingPkg,
        securityBullets
    } = props;

    const basicRowMargin = '15px';
    const idContinueButton = 'continueWithAcccountingSystemButton'

    const [muiTheme] = React.useState<Theme>(() => {
        return CreateStdTheme(props.brandConfig);
    });

    const [setInitialFocus, setSetInitialFocus] = React.useState<boolean>(false);
    
    React.useEffect(() => {
        setSetInitialFocus(true);

        // I want this to execute equivalent to componentDidMount so this is appropriate
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // As this is a modal and the page isn't 'loading' unfortunately the autofocus attribute
    // on the button on its own doesn't appear to work.

    React.useEffect(() => {
        if (setInitialFocus) {
            const continueButton = document.getElementById(idContinueButton);
            if (continueButton !== null) {
                continueButton.focus();
            }
            setSetInitialFocus(false);
        }
    }, [setInitialFocus]);

    const securityBulletContent = React.useMemo(() => {
        const result: BulletPoint[] = [];
        let currentBullet: BulletPoint | undefined = undefined;

        securityBullets.paragraphs.forEach(pg => {
            if (pg.attributes.find(attr => attr.name === 'bullet')) {
                if (!!currentBullet) {
                    result.push(currentBullet);
                }
                currentBullet = {
                    bullet: pg.content,
                    bulletParagraphs: []
                }
            } else {
                if (!!currentBullet) {
                    let bulletContent = pg.content;

                    if (pg.attributes.find(attr => attr.name === 'replaceLenderName')) {
                        // replacing lenderName at runtime.  Not actually doing templated string here but I liked keeping a consistent mechanism in place.
                        // eslint-disable-next-line no-template-curly-in-string
                        bulletContent = bulletContent.replace('${lenderName}', props.lenderName);
                    }
                    if (pg.attributes.find(attr => attr.name === 'anonymizedOnly')) {
                        if (props.parameters.anonymizeCustomersAndVendors) {
                            currentBullet.bulletParagraphs.push(bulletContent);
                        }
                    } else {
                        currentBullet.bulletParagraphs.push(bulletContent);
                    }
                }
            }
        });

        if (!!currentBullet) {
            result.push(currentBullet);
        }

        return result;
        // This is the actual dependency list I want.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [securityBullets]);

    const getCollectionIntervalText = (): string[] => {
        let dateText;
        let throughText = '';

        // Check against undefined.  Because months start at 0, it can be valid but still fail on 'if (month)'.
        if ((props.parameters.mostRecentPeriodMonth !== undefined) &&
            (props.parameters.mostRecentPeriodYear !== undefined) &&
            (props.parameters.mostRecentPeriodDay !== undefined)) {
            const date = new Date(props.parameters.mostRecentPeriodYear, props.parameters.mostRecentPeriodMonth, props.parameters.mostRecentPeriodDay);
            dateText = FormatDate(date, formatStringMDYFullMonth);
            throughText = ` through ${dateText}`;
        }

        let result;
        const periodText = (props.parameters.fullFiscalYearsToCollect === 1) ? 'fiscal year.' : `${props.parameters.fullFiscalYearsToCollect} fiscal years.`;

        if (props.parameters.fullFiscalYearsToCollect === 0) {
            result = [`Financial information has been requested for the current fiscal year${throughText}.`];
        } else if (!!throughText) {
            result = [`Financial information has been requested for the current fiscal year${throughText} and the prior ${periodText}`];
        } else {
            result = [`Financial information has been requested for the last ${periodText}`];
        }

        return result;
    }

    const checkColStyle = {
        paddingRight: '0px',
    };

    const continueButtonContent = React.useMemo(() => {
        return !!accountingPkg ? `Continue with ${accountingPkg.descriptor}` : ''
    }, [accountingPkg]);

    return (
        <Modal
            className={`flight-modal`}
            style={{
                width: '75%',
            }}
            isOpen={true}
            backdrop={'static'}
            toggle={() => props.dismissModal(true)}
        >
            <ModalBody>
                <ThemeProvider theme={muiTheme}>
                    <Container fluid className={`control-region control-region-borrower`}>
                        <Row style={{ alignItems: 'center' }}>
                            <Col xs={11}>
                                <PortalHeader
                                    hideMenu={true}
                                    showLoggedInUser={false}
                                    containingStyle={{
                                        paddingLeft: '0px',
                                        paddingRight: '0px',
                                    }}
                                    blockNavigateHome={true}
                                />
                            </Col>
                            <Col xs={1}>
                                <Button
                                    className={'simple-icon-button icon-buttons btn-strongbox'}
                                    onClick={() => props.dismissModal(true)}
                                    name={'Cancel import'}
                                    tabIndex={2}
                                >
                                    <DismissIcon />
                                </Button>
                            </Col>
                        </Row>
                        <Row className={'top-bordered-region'} />
                        <Row style={{ marginTop: basicRowMargin }}>
                            <Col xs={12} md={6}>
                                <Row style={{ marginBottom: basicRowMargin }}>
                                    <Col>
                                        <h1>{`${props.lenderName} uses Strongbox for data collection and reporting.`}</h1>
                                    </Col>
                                </Row>

                                {securityBulletContent.map((bullet, i) => {
                                    return (
                                        <Row
                                            className={'bullet-row'}
                                            key={`security-bullet-row-${i.toString()}`}
                                        >
                                            <Col xs={'auto'} style={checkColStyle}>
                                                <CheckIcon className={'bullet-image'} color={'primary'} />
                                            </Col>
                                            <Col>
                                                <Row><span className={'heavy'}>{bullet.bullet}</span></Row>
                                                {
                                                    bullet.bulletParagraphs.map((pg, pgi) => {
                                                        return <Row key={`security-bullet-row-text-${i.toString()}-${pgi.toString()}`}><span>{pg}</span></Row>
                                                    })
                                                }
                                            </Col>
                                        </Row>
                                    )
                                })}
                            </Col>
                            <Col xs={12} md={6} className={'left-bordered-horizontal-separator '} style={{ paddingLeft: '30px' }}>
                                {
                                    getCollectionIntervalText().map((line, index) => {
                                        return (
                                            <Row key={`collectionInterval${index}`}>
                                                <Col>
                                                    <span>{line}</span>
                                                </Col>
                                            </Row>
                                        )
                                    })
                                }
                                {
                                    props.parameters.provideUserCopy &&
                                    (
                                        <>
                                            <Row style={{ marginTop: '15px' }}>
                                                <Col><span>{`You will be able to download a copy of the reports that have been shared with ${props.lenderName}.`}</span></Col>
                                            </Row>
                                        </>
                                    )
                                }
                                <Row style={{ marginTop: basicRowMargin }}>
                                    <Col>
                                        <h1>The following reports have been requested:</h1>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '5px' }}>
                                    <Col>
                                        <ul className={'small-bullets'}>
                                            {
                                                props.reportsList.map((report, index) => {
                                                    return (
                                                        <li key={`reportList${index}`}>{report}</li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </ThemeProvider>
            </ModalBody>
            <ModalFooter>
                <div className={'button-row-centered'}>
                    <Button
                        color="unset"
                        className={'btn-strongbox'}
                        onClick={() => props.dismissModal(false)}
                        tabIndex={1}
                        name={continueButtonContent}
                        id={idContinueButton}
                    >
                        {continueButtonContent}
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    );
}
