import * as React from 'react';

import '../Main.scss';

import CloseIcon from '@mui/icons-material/Close';

import {
    Container,
    Col,
    Popover,
    PopoverBody,
    PopoverHeader,
    Row,
} from 'reactstrap';

import { Placement } from '@popperjs/core'

export type InfoPopoverItem = {
    title: string;
    value: string;
}

type Props = {
    title: string;
    target: string;
    onToggle: (on: boolean) => void;
    rows: InfoPopoverItem[];
    placement?: Placement;
    additional?: {
        placement: 'top' | 'bottom';
        content: React.ReactElement;
    }
    noHeader?: boolean;
    hideArrow?: boolean;
}

export const InfoPopover: React.FC<Props> = (props): React.ReactElement => {
    const {
        target,
        onToggle,
        rows,
        title,
        placement,
        additional,
        noHeader,
        hideArrow,
    } = props;

    return (
        <div className={`control-region control-region-lender`}>
            <Popover
                placement={placement || 'right'}
                isOpen={true}
                className={'info-popover'}
                target={target}
                key={`${target}-info-popover`}
                toggle={() => onToggle && onToggle(false)}
                trigger={'legacy'}
                hideArrow={hideArrow}
            >
                {!noHeader && (
                    <PopoverHeader>
                        <div className={'portal-header-region spaced-row'}>
                            <span>
                                {title}
                            </span>
                            <CloseIcon
                                color={'primary'}
                                className={'basic-list-icon actionable-icon'}
                                onClick={() => onToggle(false)}
                                id={'closepopover'}
                            />
                        </div>
                    </PopoverHeader>
                )}
                <PopoverBody className={'popover-body'}>
                    <Container fluid
                        key={`line-item-info-container-${target}`}
                    >
                        {
                            !!additional && (additional.placement === 'top') && (
                                <Row>
                                    <Col>
                                        {additional.content}
                                    </Col>
                                </Row>
                            )
                        }
                        <Row>
                            <Col>
                                <table>
                                    <tbody>
                                        {
                                            rows.map((rowContent, index) => {
                                                return (
                                                    <tr key={`info-popover-line-item-${target}-${index}`}>
                                                        <td className={'header-column'}>{rowContent.title}</td>
                                                        <td className={'data-column'}>{rowContent.value}</td>
                                                    </tr>
                                                );
                                            })
                                        }
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                        {
                            !!additional && (additional.placement === 'bottom') && (
                                <Row>
                                    <Col>
                                        {additional.content}
                                    </Col>
                                </Row>
                            )
                        }
                    </Container>
                </PopoverBody>
            </Popover>
        </div>
    )
}