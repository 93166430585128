import { pathConstants } from '../../Utils/Constants';

export enum SettingsTabId {
    userManagement = 'userManagement',
    notificationSettings = 'notificationSettings',
    notificationAdminSettings = 'notificationAdminSettings',
}

export type SettingsTab = {
    tabLabel: string;
    tabId: SettingsTabId;
    path: string;
}

export const settingsTabs: SettingsTab[] = [
    {
        tabLabel: 'User Management',
        tabId: SettingsTabId.userManagement,
        path: pathConstants.settingsUserManagement,
    }
];
