import { ApplicationState } from '../index';

import { EntitySafeDetailResponse } from '../../Models/Api/strongbox.financialportal';

/**
 * Retrieve the current borrower id.  
 * 
 * @returns the current borrower id or an empty string if none has been set.  This 
 * can be empty if the user comes to the site anonymously.  With secure shareable
 * links this will be set on loading the page to which the shareable links points.
 */

export function GetBorrowerId(state: ApplicationState): string {
    return (state && state.borrower) ? state.borrower.id : '';
}

/**
 * Retrieve the current submission id for the borrower.  The borrower can have multiple
 * submissions each of which would correlate roughly to a single browser session.
 *
 * @returns the current submission id for the borrower in the session.  This will be empty
 * until the borrower attempts to either link with an accounting package or chooses to 
 * go straight from the linking page to the documents upload page at which point a submission
 * id is created and stored.
 */

export function GetBorrowerSubmissionId(state: ApplicationState): string {
    return (state && state.borrower) ? state.borrower.submissionId : '';
}

/**
 * Did the borrower come here anonymously.
 *
 * @returns true if the borrower is here anonymously.
 */

export function GetBorrowerAnonymousState(state: ApplicationState): boolean {
    return (state && state.borrower) ? state.borrower.anonymous : false;
}

export function GetAcceptedBusinessInfo(state: ApplicationState): { name?: string, email?: string } {
    if (!(state && state.borrower)) {
        return {
        };
    }
    return {
        name: state.borrower.acceptedBusinessName,
        email: state.borrower.acceptedBusinessEmail,
    };
}

export function GetBorrowerSafeWSDetails(state: ApplicationState): EntitySafeDetailResponse | undefined {
    return state && state.borrower && state.borrower.wsDetails;
}