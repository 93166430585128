import * as React from 'react';

import '../Main.scss';

import {
    Row,
    Col,
    Popover,
    PopoverBody
} from 'reactstrap';

import { formatStringMDYShort, FormatDate } from '../../Utils/DateUtils';

import { CustomRangeSelection } from './CustomRangeSelection';
import { DropDownCaret } from '../DropDownCaret/DropDownCaret';

import {
    SubmissionHistoryRange,
} from '../../Utils/SubmissionHistoryRange';

type Props = {
    searchButtonText: string;
    submissionHistoryRange: SubmissionHistoryRange;
    onNewRange: (newRange: SubmissionHistoryRange) => void;
    onExecute: (range: SubmissionHistoryRange) => void;
    rangeSelectionOpen: boolean;
    toggleRangeSelectionOpen: () => void;
    closeRangeSelection: () => void;
}

export const CustomRangeDropdown: React.FC<Props> = (props): React.ReactElement => {
    const {
        onNewRange,
        onExecute,
        searchButtonText,
        submissionHistoryRange,
        rangeSelectionOpen,
        toggleRangeSelectionOpen,
        closeRangeSelection,
    } = props;

    const clickRef = React.useRef<any>();

    const handleClick = (e: MouseEvent): void => {
        if (!!clickRef && !!clickRef.current) {
            if (!clickRef.current.contains(e.target)) {
                closeRangeSelection();
            }
        }
    }

    React.useEffect(() => {
        // add when mounted
        document.addEventListener("mousedown", handleClick);
        // return function to be called when unmounted
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
        // I want this to execute equivalent to componentDidMount so this is appropriate
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const dateRangeText = React.useMemo(() => {
        if ((submissionHistoryRange.start == null) || (submissionHistoryRange.end == null)) {
            return '';
        }
        return `${FormatDate(submissionHistoryRange.start, formatStringMDYShort)}-${FormatDate(submissionHistoryRange.end, formatStringMDYShort)}`;
    }, [submissionHistoryRange])

    return (
        <div className={`dropdown`}>
            <Row>
                <Col xs={'auto'}>
                    <DropDownCaret
                        id={'range-selection-dropdown'}
                        expanded={rangeSelectionOpen}
                        expandDescriptor={dateRangeText}
                        contractDescriptor={dateRangeText}
                        clicked={toggleRangeSelectionOpen}
                        text={dateRangeText}
                    />
                </Col>
            </Row>
            {rangeSelectionOpen && (
                <Popover
                    placement={'bottom'}
                    isOpen={true}
                    target={'range-selection-dropdown'}
                    key={'range-selection-dropdown'}
                >
                    <PopoverBody>
                        <div
                            className={`control-region control-region-lender`}
                            ref={clickRef}
                            style={{
                                padding: '10px 0px'
                            }}
                        >
                            <CustomRangeSelection
                                searchButtonText={searchButtonText}
                                submissionHistoryRange={submissionHistoryRange}
                                onNewRange={range => onNewRange(range)}
                                onExecute={range => {
                                    onExecute(range);
                                }}
                            />
                        </div>
                    </PopoverBody>
                </Popover>
            )}
        </div>
    )
}
