import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';

import {
    Row,
    Col,
    Progress as ProgressBar
} from 'reactstrap';

import {
    ResourceName,
    RetrieveResource,
    TenantLineItemImageSet,
} from '../../Store/Tenant';
import {
    GetBorrowerFlowAccountingPkg
} from '../../Store/ImportFinancials';

import { ApplicationState } from '../../Store';
import { pathConstants } from '../../Utils/Constants';

import { BorrowerPortalChildProps } from './BorrowerPortal';

type InjectedReduxState = {
    progressText: TenantLineItemImageSet;
    accountingPkg?: string;
}

type InjectedActionCreators = {
};

type Props = BorrowerPortalChildProps & InjectedActionCreators & InjectedReduxState;

const delayPerTick = 100;
const stepIncrementPerTick = 0.05;
const finalDelay = 4000;

export const ProgressComponent: React.FC<Props> = (props): React.ReactElement => {
    const {
        progressText
    } = props;

    const [complete, setComplete] = React.useState<boolean>(false);
    const [step, setStep] = React.useState<number>(0.0);

    const progressComplete = (): void  => {
        // There shouldn't really be a case where lineItems is null but we add the check here anyway.
        // The primary thing we are checking on is where we have made all the line item images visible and should go 
        // to a complete state.
        if (!(progressText && progressText.lineItems) || (step + stepIncrementPerTick > progressText.lineItems.length)) {
            setComplete(true);
        } else {
            setStep(step + stepIncrementPerTick);
        }
    }

    React.useEffect(() => {
        let timeout = delayPerTick;

        if (!!progressText && !!progressText.lineItems && (step + stepIncrementPerTick > progressText.lineItems.length)) {
            timeout = finalDelay;
        }
        // On the last step, the set the delay to the final delay value, otherwise, just the normal delay between steps.

        const idTimeout = setTimeout(progressComplete, timeout);
        return (() => clearTimeout(idTimeout));

        // progressText and progressComplete won't actually change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step]);

    const pctComplete = React.useMemo(() => {
        if (!progressText || !progressText.lineItems) {
            return 0;
        }
        return (step / progressText.lineItems.length) * 100;

    }, [progressText, step])

    if (complete) {
        return <Navigate to={pathConstants.linked} />
    }

    return (
        <>
            <Row style={{ marginTop: '45px' }}>
                <Col xs={12}>
                    <ProgressBar
                        striped
                        value={pctComplete}
                        barClassName={'borrower-progress'}
                    />
                </Col>
            </Row>
            <Row style={{ marginTop: '15px' }}>
                <Col xs={12} className={'centered-col'}>
                    <table className={'line-items'}>
                        <tbody>
                            {progressText && progressText.lineItems && progressText.lineItems.map((lineItem, itemIndex) => {
                                const visibleClass = (itemIndex <= step) ? 'clear-image-fade-in-visible' : '';

                                return (
                                    <tr key={`progress-2934803294832048390-${itemIndex}`}>
                                        <td key="19483204832048" style={{
                                            width: '60px'
                                        }}>
                                            { lineItem.img(`clear-image-fade-in ${visibleClass}`) }
                                        </td>
                                        <td key="58924934923759">
                                            <div>
                                                <h2 style={{ marginBottom: '0px', alignSelf: 'left' }}>{lineItem.text}</h2>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </Col>
            </Row>
        </>
    );
}

export const Progress = connect<InjectedReduxState, InjectedActionCreators, BorrowerPortalChildProps, ApplicationState>(
    (appState: ApplicationState) => {
        const result = {
            progressText: RetrieveResource(appState, ResourceName.tenantProgressSteps),
            accountingPkg: GetBorrowerFlowAccountingPkg(appState),
        };

        return result;
    }
)(ProgressComponent);
