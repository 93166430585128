import '../Main.scss';

import * as React from 'react';
import { connect } from 'react-redux';

import {
    Col,
    Container,
    Row
} from 'reactstrap';

import { AccountingPackage } from '../../Models/Api/AccountingPackages';
import { GetFilteredAccountingPackages } from '../../Store/AppSettings';
import { ApplicationState } from '../../Store';
import { ResourceName, RetrieveResource } from '../../Store/Tenant';

import { AccountingSystemGroup } from './AccountingSystemGroup';
import { AccountingPkgPresentation } from './AccountingPkgPresentation';
import { ImportGraphic } from './ImportGraphic';

type InjectedReduxState = {
    tenantSelectPkgPrompt: string;
    accountingPackages: string[];
}

type InjectedActionCreators = {

}

type SelectAccountingSystemProps = {
    buttonsDisabled: boolean;
    onLink: (accountingPkg: AccountingPkgPresentation) => void;
    rowClass?: string;
    noBorder?: boolean;
    tabIndexBase: number;
};

type Props = SelectAccountingSystemProps & InjectedActionCreators & InjectedReduxState;

export const SelectAccountingSystemComponent: React.FC<Props> = (props): React.ReactElement => {
    const {
        accountingPackages,
        buttonsDisabled,
        onLink,
        noBorder,
        tenantSelectPkgPrompt,
        tabIndexBase,
    } = props;

    const availableAccountingPackages: AccountingPkgPresentation[] = [
        {
            featureName: AccountingPackage.QuickBooksOnline,
            descriptor: 'QuickBooks Online',
            onPkgSelected: onLink
        },
        {
            featureName: AccountingPackage.QuickBooksDesktop,
            descriptor: 'QuickBooks Desktop',
            onPkgSelected: onLink
        },
        {
            featureName: AccountingPackage.SageIntacct,
            descriptor: 'Sage Intacct',
            onPkgSelected: onLink
        },
        {
            featureName: AccountingPackage.Xero,
            descriptor: 'Xero',
            onPkgSelected: onLink
        },
        {
            featureName: AccountingPackage.NetSuite,
            descriptor: 'Oracle NetSuite',
            onPkgSelected: onLink
        },
        {
            featureName: AccountingPackage.MYOBBusiness,
            descriptor: 'MYOB Business',
            onPkgSelected: onLink,
            tag: 'Beta'
        },
        {
            featureName: AccountingPackage.Dynamics365,
            descriptor: 'Microsoft Dynamics 365',
            onPkgSelected: onLink,
            tag: 'Beta'
        },
        {
            featureName: 'Example',
            descriptor: 'DEX',
            onPkgSelected: onLink
        },
        {
            featureName: AccountingPackage.FreeAgent,
            descriptor: 'Free Agent',
            onPkgSelected: () => { }
        },
        {
            featureName: 'Reckon',
            descriptor: 'Reckon',
            onPkgSelected: () => { }
        }
    ]

    const activeAccountingPackages = React.useMemo(() => {
        return availableAccountingPackages.filter(availablePkg => {
            return accountingPackages.find(pkg => pkg.toLowerCase() === availablePkg.featureName.toLowerCase());
        })

        // accountingPackages is the only dependency that matters here.
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [accountingPackages])

    return (
        <Container
            fluid
            className={`accounting-button-container ${!noBorder ? 'basic-bordered-region' : ''} control-region`}
        >
            <Row>
                <Col xs={12} md={4} className={'accounting-button-summary-column'}>
                    <ImportGraphic />
                </Col>
                <Col xs={12} md={8} className={'accounting-button-group-column'}>
                    <AccountingSystemGroup
                        accountingSystems={activeAccountingPackages}
                        id={'accountingPackages'}
                        name={tenantSelectPkgPrompt}
                        disabled={buttonsDisabled}
                        tabIndexBase={tabIndexBase}
                    />
                </Col>
            </Row>
        </Container>
    );
}

export const SelectAccountingSystem = connect<InjectedReduxState, InjectedActionCreators, SelectAccountingSystemProps, ApplicationState>(
    (appState: ApplicationState) => {
        const result = {
            tenantSelectPkgPrompt: RetrieveResource(appState, ResourceName.connectSelectAccountingPkg) || '',
            accountingPackages: GetFilteredAccountingPackages(appState)
        };

        return result;
    }
)(SelectAccountingSystemComponent);


