import * as React from 'react';

import '../../Main.scss';
import './FinancialStatements.scss';

import { FSModels } from '@finagraph/financial-statement-editor';

import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import SortIcon from '@mui/icons-material/Sort';
import DeleteLineItemIcon from '@mui/icons-material/Delete';
import DeleteSectionIcon from '@mui/icons-material/DeleteSweep';

import { fsSectionPresentationName } from './FSConstants';
import { SanitizeDOMSelector } from '../../../Utils/DOMUtils';

import {
    Button,
    Popover,
    PopoverBody,
    Container,
    Row,
    Col
} from 'reactstrap';

type Props = {
    sectionsById: Map<string, FSModels.FinancialStatementClassificationSectionViewModel>;
    lineItem: FSModels.FinancialStatementLineItemViewModel;
    targetId: string;
    onToggle: (active: boolean) => void;
    onSortAlpha: () => void;
    onSortAccountNo: () => void;
    onDeleteSectionHeader: () => void;
    onDeleteSection: () => void;
}

export const LineItemHasMoreOptions = (lineItem: FSModels.FinancialStatementLineItemViewModel): boolean => {
    return lineItem.lineItemType !== FSModels.FinancialStatementLineItemType.DetailAccount;
}

export const LineItemMoreOptions: React.FC<Props> = (props): React.ReactElement => {
    const {
        lineItem,
        targetId,
        onToggle,
        onSortAlpha,
        onSortAccountNo,
        onDeleteSection,
        onDeleteSectionHeader,
        sectionsById
    } = props;

    const getRow = (icon: React.ReactElement, keyText: string, actionText: JSX.Element, action: () => void): React.ReactElement => {
        return (
            <Row
                className={'selectable-row'}
                key={`financial-statement-line-item-more-options-${keyText}-${SanitizeDOMSelector(lineItem.id)}`}
                onClick={(e) => action()}
            >
                <Col xs={3}>
                    <Button
                        onClick={(e) => action()}
                    >
                        {icon}
                    </Button>
                </Col>
                <Col>
                    <span
                        className={'clickable'}
                        onClick={(e) => action()}
                    >
                        {actionText}
                    </span>
                </Col>
            </Row>
        )
    }

    const lineItemMoreOptions = React.useMemo(() => {
        const lineItemMoreOptions: React.ReactElement[] = [];

        const section = sectionsById.get(lineItem.sectionId)!;

        const sectionName = section.footerCaptionText;

        if (!section.isEmpty &&
            (lineItem.lineItemType === FSModels.FinancialStatementLineItemType.SectionHeader || lineItem.lineItemType === FSModels.FinancialStatementLineItemType.SectionFooter)) {
            lineItemMoreOptions.push(getRow((<SortByAlphaIcon />), 'sort-alpha', (<>{`Sort detail accounts under this ${fsSectionPresentationName.toLowerCase()} by name`}</>), onSortAlpha));
            lineItemMoreOptions.push(getRow((<SortIcon />), 'sort-accountno', (<>{`Sort detail accounts under this ${fsSectionPresentationName.toLowerCase()} by Account No`}</>), onSortAccountNo));
        }
        
        if (lineItem.canDeleteLineItem) {
            lineItemMoreOptions.push(getRow(
                (<DeleteLineItemIcon />),
                'delete-section-header',
                (<>Delete this {lineItem.lineItemType === FSModels.FinancialStatementLineItemType.SectionHeader ? `${fsSectionPresentationName.toLowerCase()} header` : 'line item'}</>),
                 onDeleteSectionHeader));
        }

        const sectionId = lineItem.sectionId.toLowerCase();
        const protectedSection = ((sectionId === 'cash') || (sectionId === 'accountsreceivable') || (sectionId === 'accountspayable'));
        
        if ((lineItem.canDeleteSection) && (!protectedSection)) {
            lineItemMoreOptions.push(getRow((<DeleteSectionIcon />), 'delete-section', (<>{`Delete the ${fsSectionPresentationName.toLowerCase()} `} <b>{sectionName}</b></>), onDeleteSection));
        }

        return lineItemMoreOptions;

        // lineItem and sectionsById are the only important dependencies
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lineItem, sectionsById]);

    return (
        <div className={`control-region control-region-lender`}>
            <Popover
                placement={'bottom'}
                hideArrow={true}
                isOpen={true}
                className={'financial-statement-popover'}
                target={targetId}
                key={'fs-filter-line-item-more-options'}
                toggle={() => onToggle && onToggle(false)}
                trigger={'legacy'}
            >
                <PopoverBody className={'popover-body'}>
                    <Container fluid>
                        {lineItemMoreOptions}
                        {lineItemMoreOptions.length === 0 && (<>No additional options are available for this line item</>)}
                    </Container>
                </PopoverBody>
            </Popover>
        </div>
    )
}
