import {
    ApplicationState,
    AppThunkAction
} from '../index';

import {
    KnownAction,
    FinancialStatementsActions,
    ISetFilterState,
    ISetFinancialStatementErrorState,
    IDeleteLineItems,
    ISortLineItems,
    IMoveLineItems,
    IIncrementLineItemSortOrder,
    IDecrementLineItemSortOrder,
    IUpdateLineItemCaptions,
    ISetReportedAs,
    IDeleteClassificationSection,
    IAddClassificationSection,
    IChangeReportingPeriodSortOrder,
    IChangeReportingPeriod,
    IUsePredefinedNumberStyle,
    IClearCompletedRevisionTracking
} from './Actions';
import {
    FinancialStatementError
} from './Reducer';
import { KnownAction as uiKnownActions, actionCreators as uiStateActions } from '../UIState';

import { LogMessage, SeverityLevel, LogException } from '../../Utils/Logging';

import {
    GetFinancialStatements,
    PutFinancialStatements,
    ResetFinancialStatements as ResetFinancialStatementsWebApiCall,
    ApplyTemplateToFinancialStatements as ApplyTemplateToFinancialStatementsWebApiCall,
    ExtractFinancialStatementTemplate,
    RegenerateWorkbooks as RegenerateWorkbooksWebApiCall,
    ListFinancialStatementTemplates as ListFinancialStatementTemplatesWebApiCall,
    GetWorkbookRevisionStatus,
    DeleteFinancialStatementTemplate as DeleteFinancialStatementTemplateWebApiCall
} from '../../Services/FinancialRecordsService';

import {
    createFinancialStatementFacade,
    FinancialStatementFacade,
    FSModels
} from '@finagraph/financial-statement-editor';

import {
    FinancialStatementTemplatesList,
    WorkbookRevisionDescriptor,
    WorkbookRevisionStatus
} from '../../Models/Api/strongbox.financialportal';

const nameOfStatementType = (financialStatementType: FSModels.FinancialStatementType): string => {
    let result = 'financial statement';

    switch (financialStatementType) {
        case FSModels.FinancialStatementType.BalanceSheet:
            result = 'balance sheet';
            break;
        case FSModels.FinancialStatementType.IncomeStatement:
            result = 'income statement';
            break;
        case FSModels.FinancialStatementType.CashFlowStatement:
            result = 'cashflow statement';
            break;
    }
    return result;
}

const InitializeFinancialStatement = (
    workspaceId: string,
    financialRecordId: string,
    financialStatementType: FSModels.FinancialStatementType
): AppThunkAction<FinancialStatementFacade | undefined, unknown, KnownAction | uiKnownActions> =>
    async (dispatch, getState): Promise<FinancialStatementFacade | undefined> => {
    const workingName = `InitializeFinancialStatements`;

    dispatch(SetFinancialStatementErrorState(undefined));
    dispatch(uiStateActions.SetPortalWorking(workingName));

    try {
        var currentState = getState().financialStatements;

        let financialStatementFacade: FinancialStatementFacade;
        if (currentState.workspaceId === workspaceId && currentState.financialRecordId === financialRecordId && currentState.financialStatementFacade) {
            financialStatementFacade = currentState.financialStatementFacade!;
        } else {
            dispatch({
                type: FinancialStatementsActions.LoadFinancialStatementStarted,
                workspaceId: workspaceId,
                financialRecordId: financialRecordId,
                financialStatementType: financialStatementType
            });

            const webApiModel = await GetFinancialStatements(
                workspaceId,
                financialRecordId);

            financialStatementFacade = await createFinancialStatementFacade(webApiModel as any);
        }

        dispatch({
            type: FinancialStatementsActions.InitializeFinancialStatement,
            workspaceId: workspaceId,
            financialRecordId: financialRecordId,
            financialStatementType: financialStatementType,
            financialStatementFacade: financialStatementFacade,
        });

        return financialStatementFacade;
    } catch (e) {
        const errorMsg = 'InitializeFinancialStatement action creator failed';

        console.error(errorMsg);
        console.error(e);

        LogException(errorMsg, e);

        dispatch(SetFinancialStatementErrorState({
            errorCaption: `Failure loading ${nameOfStatementType(financialStatementType)}`,
            errorMessage: `There has been a failure loading the financial statement for this submission. Unable to continue at this time`
        }));

        return undefined;
    } finally {
        dispatch(uiStateActions.SetPortalIdle(workingName));
    }
}

function ChangeReportingPeriod(reportingPeriod: FSModels.ReportingPeriodTypes): IChangeReportingPeriod {
    return {
        type: FinancialStatementsActions.ChangeReportingPeriod,
        reportingPeriod: reportingPeriod,
    };
}

function UsePredefinedNumberStyle(predefinedNumberStyle: FSModels.PredefinedMonetaryNumberStyle): IUsePredefinedNumberStyle {
    return {
        type: FinancialStatementsActions.UsePredefinedNumberStyle,
        predefinedNumberStyle: predefinedNumberStyle,
    };
}

function ToggleReportingPeriodSortOrder() {

    return (dispatch: (a:IChangeReportingPeriodSortOrder) => void, getState: () => ApplicationState) => {
        const currentState = getState().financialStatements;

        dispatch({
            type: FinancialStatementsActions.ChangeReportingPeriodSortOrder,
            reportingPeriodSortOrder: currentState.reportingPeriodSortOrder === FSModels.ChronologicalSortOrder.OldestFirst 
                ?  FSModels.ChronologicalSortOrder.NewestFirst
                : FSModels.ChronologicalSortOrder.OldestFirst
        });
    };
}

function AddClassificationSection(request: FSModels.AddClassificationSectionAction): IAddClassificationSection {
    return {
        type: FinancialStatementsActions.AddClassificationSection,
        request: request,
    };
}

function DeleteClassificationSection(
    sectionId: string,
    relocateContentsToSectionId: string | undefined
): IDeleteClassificationSection {
    return {
        type: FinancialStatementsActions.DeleteClassificationSection,
        sectionId: sectionId,
        relocateContentsToSectionId: relocateContentsToSectionId,
    };
}

function SetReportedAs(
    sectionId: string,
    reportedAs: FSModels.CreditOrDebit): ISetReportedAs {
    return {
        type: FinancialStatementsActions.SetReportedAs,
        sectionId: sectionId,
        reportedAs: reportedAs,
    };
}

function UpdateLineItemCaptions(request: FSModels.EditLineItemCaptionAction[]): IUpdateLineItemCaptions {
    return {
        type: FinancialStatementsActions.UpdateLineItemCaptions,
        request: request,
    };
}

function DecrementLineItemSortOrder(lineItemId: string): IDecrementLineItemSortOrder {
    return {
        type: FinancialStatementsActions.DecrementLineItemSortOrder,
        lineItemId,
    };
}

function IncrementLineItemSortOrder(lineItemId: string): IIncrementLineItemSortOrder {
    return {
        type: FinancialStatementsActions.IncrementLineItemSortOrder,
        lineItemId,
    };
}

function MoveLineItems(request: FSModels.MoveLineItemAction[]): IMoveLineItems {
    return {
        type: FinancialStatementsActions.MoveLineItems,
        request,
    };
}

function SortLineItems(
    orderBy: FSModels.OrderLineItemsBy,
    orderSubsections: FSModels.OrderSubsections,
    sectionId?: string | undefined,
    accountingPeriodId?: string | undefined): ISortLineItems {
    return {
        type: FinancialStatementsActions.SortLineItems,
        orderBy: orderBy,
        orderSubsections: orderSubsections,
        sectionId:sectionId,
        accountingPeriodId: accountingPeriodId,
    };
}

function DeleteLineItems(request: FSModels.DeleteLineItemAction[]): IDeleteLineItems {
    return {
        type: FinancialStatementsActions.DeleteLineItems,
        request,
    };
}

const SaveFinancialStatements = (failed?: () => void): AppThunkAction<void, unknown, KnownAction | uiKnownActions> =>
    async (dispatch, getState): Promise<void> => {
        const workingName = `SaveFinancialStatement`;
        dispatch(uiStateActions.SetPortalWorking(workingName));

        try {
            var currentState = getState().financialStatements;

            if(currentState.financialStatementFacade && currentState.workspaceId && currentState.financialRecordId){
                await PutFinancialStatements(
                    currentState.workspaceId!, 
                    currentState.financialRecordId,
                    currentState.financialStatementFacade.getWebApiModel() as any);
            } else {
                console.warn('Cannot save financial statements. Invalid state.');
            }

            dispatch({
                type: FinancialStatementsActions.SaveFinancialStatementComplete,
            });

            dispatch(SetFinancialStatementErrorState(undefined));
        } catch (e) {
            const errorMsg = 'SaveFinancialStatement action creator failed';

            console.error(errorMsg);
            console.error(e);

            LogException(errorMsg, e);

            dispatch(SetFinancialStatementErrorState({
                errorCaption: 'Error saving financial statement',
                errorMessage: 'There has been an error saving your changes to the financial statement. Choosing Reset from the menu may resolve the issue.'
            }));

            if (!!failed) {
                failed();
            }
        } finally {
            dispatch(uiStateActions.SetPortalIdle(workingName));
        }
    }
    
const RegenerateWorkbooks = (): AppThunkAction<WorkbookRevisionDescriptor | undefined, unknown, KnownAction | uiKnownActions> =>
    async (dispatch, getState): Promise<WorkbookRevisionDescriptor | undefined> => {
        const workingName = `GenerateNewRevision`;
        dispatch(uiStateActions.SetPortalWorking(workingName));

        try {
            const currentState = getState().financialStatements;

            if(currentState && currentState.workspaceId && currentState.financialRecordId) {
                const result = await RegenerateWorkbooksWebApiCall(currentState.workspaceId, currentState.financialRecordId);

                if (result) {
                    dispatch({
                        type: FinancialStatementsActions.RegeneratingWorkbooksStarted,
                        workbookRevisionNumber: result.revisionNumber
                    });

                    dispatch(SetFinancialStatementErrorState(undefined));
                } else {
                    LogMessage(
                        `Unexpected result from CreateNewRevision, no result returned`,
                        SeverityLevel.Error,
                        {
                            workspaceId: currentState.workspaceId,
                            financialRecordId: currentState.financialRecordId,
                        }
                    );
                    dispatch(SetFinancialStatementErrorState({
                        errorCaption: 'Error generating new revision of workbooks',
                        errorMessage: 'There was an error attempting to generate new workbook version. Choosing Reset from the menu may resolve the issue.'
                    }));
                }

                return result;
            } else {
                console.warn('Cannot regenerate workbooks. Invalid state.');
            }
        } catch (e) {
            const errorMsg = 'CreateNewRevision action creator failed';

            console.error(errorMsg);
            console.error(e);

            LogException(errorMsg, e);

            dispatch(SetFinancialStatementErrorState({
                errorCaption: 'Error generating new revision of workbooks',
                errorMessage: 'There was an error attempting to generate new workbook version. Choosing Reset from the menu may resolve the issue.'
            }));
        } finally {
            dispatch(uiStateActions.SetPortalIdle(workingName));
        }
    }    

const SaveFinancialStatementsAsTemplate = (
    displayName: string,
    description: string
): AppThunkAction<void, unknown, KnownAction | uiKnownActions> =>
    async (dispatch, getState): Promise<void> => {
        const workingName = `SaveFinancialStatementAsTemplate`;
        dispatch(uiStateActions.SetPortalWorking(workingName));

        try {
            var currentState = getState().financialStatements;

            if (currentState.financialStatementFacade && currentState.workspaceId && currentState.financialRecordId) {
                await ExtractFinancialStatementTemplate(
                    displayName,
                    description,
                    currentState.financialStatementFacade.getWebApiModel() as any
                )

                dispatch(SetFinancialStatementErrorState(undefined));
            } else {
                console.warn('Error saving extracting template from financial statement. Invalid state.');

                dispatch(SetFinancialStatementErrorState({
                    errorCaption: 'Error generating financial statements template',
                    errorMessage: 'There was an error attempting to create a financial statements template. Choosing Reset from the menu may resolve the issue.'
                }));
            }
        } catch (e) {
            const errorMsg = 'SaveFinancialStatementAsTemplate action creator failed';

            console.error(errorMsg);
            console.error(e);

            LogException(errorMsg, e);

            dispatch(SetFinancialStatementErrorState({
                errorCaption: 'Error generating financial statements template',
                errorMessage: 'There was an error attempting to create a financial statements template. Choosing Reset from the menu may resolve the issue.'
            }));
        } finally {
            dispatch(uiStateActions.SetPortalIdle(workingName));
        }
    }

/**
 * It's possible in an initial load scenario that we'll want to override the workspaceId, financialRecordId
 * and financialStatementType in currentState because they weren't set.  Providing them as parameters overrides 
 * those default values. 
 * 
 * @parameter workspaceId if provided overrides the workspaceId in the current state.
 * @parameter financialRecordId if provided overrides the financialRecordId in the current state.
 * @parameter financialStatementType if provided overrides the financialStatementType in the current state.
 */

const ResetFinancialStatements = (workspaceId?: string, financialRecordId?: string, financialStatementType?: FSModels.FinancialStatementType): AppThunkAction<void | undefined, unknown, KnownAction | uiKnownActions> =>
    async (dispatch, getState): Promise<void | undefined> => {
    const workingName = `ResetFinancialStatements`;

    dispatch(uiStateActions.SetPortalWorking(workingName));

    try {
        var currentState = getState().financialStatements;

        const usingWorkspaceId = !!workspaceId ? workspaceId : currentState.workspaceId;
        const usingFinancialRecordId = !!financialRecordId ? financialRecordId : currentState.financialRecordId;
        const usingFinancialStatementType = !!financialStatementType ? financialStatementType : currentState.financialStatementType;

        if (usingWorkspaceId && usingFinancialRecordId && usingFinancialStatementType) {
            const webApiModel = await ResetFinancialStatementsWebApiCall(usingWorkspaceId, usingFinancialRecordId);

            const financialStatementFacade = await createFinancialStatementFacade(webApiModel as any);

            dispatch({
                type: FinancialStatementsActions.InitializeFinancialStatement,
                workspaceId: usingWorkspaceId,
                financialRecordId: usingFinancialRecordId,
                financialStatementType: usingFinancialStatementType,
                financialStatementFacade: financialStatementFacade,
            });

            dispatch(SetFinancialStatementErrorState(undefined));
        } else {
            console.error('Failed to reset the financial statements. invalid state');

            dispatch(SetFinancialStatementErrorState({
                errorCaption: 'Error resetting financial statement',
                errorMessage: 'There was an error resetting the financial statement.'
            }));
        }
    } catch (e) {
        const errorMsg = 'InitializeFinancialStatement action creator failed';

        console.error(errorMsg);
        console.error(e);

        LogException(errorMsg, e);

        dispatch(SetFinancialStatementErrorState({
            errorCaption: 'Error resetting financial statement',
            errorMessage: 'There was an error resetting the financial statement.'
        }));

        return undefined;
    } finally {
        dispatch(uiStateActions.SetPortalIdle(workingName));
    }
}    

const ListFinancialStatementTemplates = (): AppThunkAction<FinancialStatementTemplatesList | undefined, unknown, KnownAction | uiKnownActions> =>
    async (dispatch, getState): Promise<FinancialStatementTemplatesList | undefined> => {
        const workingName = `ListFinancialStatementTemplates`;
        dispatch(uiStateActions.SetPortalWorking(workingName));

        try {
            const templatesList = await ListFinancialStatementTemplatesWebApiCall();

            if (!!templatesList) {
                dispatch({
                    type: FinancialStatementsActions.LoadFinancialStatementTemplatesListCompleted,
                    templatesList
                });
            }

            dispatch(SetFinancialStatementErrorState(undefined));

            return templatesList;
        } catch (e) {
            const errorMsg = 'ListFinancialStatementTemplates action creator failed';

            console.error(errorMsg);
            console.error(e);

            LogException(errorMsg, e);

            dispatch(SetFinancialStatementErrorState({
                errorCaption: `Failure listing financial statement templates`,
                errorMessage: `Failure loading the list of financial statement templates. Choosing Reset from the menu may resolve the issue.`
            }));

            return undefined;
        } finally {
            dispatch(uiStateActions.SetPortalIdle(workingName));
        }
    }

const DeleteFinancialStatementTemplate = (templateId: string): AppThunkAction<boolean, unknown, KnownAction | uiKnownActions> =>
    async (dispatch, getState): Promise<boolean> => {
        const workingName = `DeleteFinancialStatementTemplate`;
        dispatch(uiStateActions.SetPortalWorking(workingName));

        try {
            await DeleteFinancialStatementTemplateWebApiCall(templateId);
            dispatch({
                type: FinancialStatementsActions.DeletedFinancialStatementTemplate,
                templateId
            });

            dispatch(SetFinancialStatementErrorState(undefined));

            return true;
        } catch (e) {
            const errorMsg = 'DeleteFinancialStatementTemplate action creator failed';

            console.error(errorMsg);
            console.error(e);

            LogException(errorMsg, e);

            dispatch(SetFinancialStatementErrorState({
                errorCaption: `Failure deleting financial statement template`,
                errorMessage: `Unable to delete the financial statement template.`
            }));

            return false;
        } finally {
            dispatch(uiStateActions.SetPortalIdle(workingName));
        }
    }

const ApplyTemplateToFinancialStatements = (
    templateId: string
): AppThunkAction<void, unknown, KnownAction | uiKnownActions> =>
    async (dispatch, getState): Promise<void> => {
        const workingName = `ApplyTemplateToFinancialStatements`;
        dispatch(uiStateActions.SetPortalWorking(workingName));

        try {
            var currentState = getState().financialStatements;

            if (currentState.workspaceId && currentState.financialRecordId && currentState.financialStatementType) {
                const webApiModel = await ApplyTemplateToFinancialStatementsWebApiCall(currentState.workspaceId, currentState.financialRecordId, templateId);

                const financialStatementFacade = await createFinancialStatementFacade(webApiModel as any);

                dispatch({
                    type: FinancialStatementsActions.InitializeFinancialStatement,
                    workspaceId: currentState.workspaceId,
                    financialRecordId: currentState.financialRecordId,
                    financialStatementType: currentState.financialStatementType,
                    financialStatementFacade: financialStatementFacade,
                    dirty: true
                });
            } else {
                const errorMsg = 'Internal error applying a template to a financial statement';

                console.error(errorMsg);

                LogMessage(errorMsg, SeverityLevel.Error, { templateId });

                dispatch(SetFinancialStatementErrorState({
                    errorCaption: 'Internal error switching templates',
                    errorMessage: 'There was an internal error attempting to use this template. Choosing Reset from the menu may resolve the issue.'
                }));
            }
        } catch (e) {
            const errorMsg = 'ApplyTemplateToFinancialStatements action creator failed';

            console.error(errorMsg);
            console.error(e);

            LogException(errorMsg, e);

            dispatch(SetFinancialStatementErrorState({
                errorCaption: `Failure applying financial statements template`,
                errorMessage: `Failure applying the selected template to these financial statements. Choosing Reset from the menu may resolve the issue.`
            }));
        } finally {
            dispatch(uiStateActions.SetPortalIdle(workingName));
        }
    }

/**
 * @param onJobCompletion is an optional method that is called if any job goes from an incomplete state to a 
 * completed state
 * */

const UpdateWorkbookRegenerationStatuses = (
    onJobCompletion?: () => void
): AppThunkAction<void, unknown, KnownAction | uiKnownActions> =>
    async (dispatch, getState): Promise<void> => {
        var currentState = getState().financialStatements;

        if (!(currentState && currentState.workspaceId && currentState.financialRecordId)) {
            return;
        }
        if (currentState.trackingRevisionNumbers.length <= 0) {
            return;
        }

        const statuses: WorkbookRevisionStatus[] = [];

        let functionResult = false;

        for (let i = 0; i < currentState.trackingRevisionNumbers.length; i++) {
            try {
                // The initial state of status will be undefined, so if the current states status for this revision number
                // is undefined or it's pending, then we go get it.  If it's undefined, then when we dispatch the results
                // below it should get set and will not be undefined on the next pass through here.
                if ((!currentState.trackingRevisionNumbers[i].status) || (currentState.trackingRevisionNumbers[i].status?.outcome === 'Pending')) {
                    const status = await GetWorkbookRevisionStatus(
                        currentState.workspaceId!,
                        currentState.financialRecordId!,
                        currentState.trackingRevisionNumbers[i].revisionNumber
                    );
                    statuses.push(status);
                    // We can only get here if the current status is pending.  If the new status
                    // is NOT pending then we should call onJobCompletion (if provided) indicating 
                    // that at least one job has finished and changed state.
                    if (status.outcome !== 'Pending') {
                        functionResult = true;
                    }
                }
            } catch (exception) {
                LogException(
                    'Failure retrieving revision status for workbook regeneration',
                    exception,
                    {
                        workspaceId: currentState.workspaceId,
                        financialRecordId: currentState.financialRecordId,
                        revisionNumber: currentState.trackingRevisionNumbers[i].revisionNumber
                    }
                );
                statuses.push({
                    outcome: 'Error',
                    revisionNumber: currentState.trackingRevisionNumbers[i].revisionNumber,
                    workbooks: []
                });
            }
        };

        if (statuses.length > 0) {
            dispatch({
                type: FinancialStatementsActions.UpdateWorkbookRegenerationStatuses,
                workspaceId: currentState.workspaceId,
                financialRecordId: currentState.financialRecordId,
                statuses
            });
        }

        if (!!onJobCompletion && functionResult) {
            onJobCompletion();
        }
    }

// function UpdateLineItemIndent(
//     orgId: string,
//     financialRecordId: string,
//     financialStatementType: FinancialStatementType,
//     reportingPeriod: ReportingPeriod,
//     lineItemId: string,
//     newIndent: number
// ): IUpdateLineItemIndent {
//     return {
//         type: FinancialStatementsActions.UpdateLineItemIndent,
//         orgId,
//         financialRecordId,
//         financialStatementType,
//         reportingPeriod,
//         lineItemId,
//         newIndent
//     };
// }

function SetFilterState(statementType: FSModels.FinancialStatementType, filterType: FSModels.FinancialStatementLineItemType, state: boolean): ISetFilterState {
    return {
        type: FinancialStatementsActions.SetFilterState,
        filterType,
        financialStatementType: statementType,
        state
    };
}

function SetFinancialStatementErrorState(errorState?: FinancialStatementError): ISetFinancialStatementErrorState {
    return {
        type: FinancialStatementsActions.SetFinancialStatementErrorState,
        errorState
    }
}

function ClearCompletedRevisionTracking(): IClearCompletedRevisionTracking {
    return {
        type: FinancialStatementsActions.ClearCompletedRevisionTracking
    }
}

export const actionCreators = {
    InitializeFinancialStatement,
    ChangeReportingPeriod,
    ToggleReportingPeriodSortOrder, 
    UpdateLineItemCaptions,
    // UpdateLineItemIndent,
    SetFilterState,
    DecrementLineItemSortOrder,
    IncrementLineItemSortOrder,
    MoveLineItems,
    SortLineItems,
    AddClassificationSection,
    DeleteClassificationSection,
    SetReportedAs,
    DeleteLineItems,
    SetFinancialStatementErrorState,
    SaveFinancialStatements,
    ResetFinancialStatements,
    SaveFinancialStatementsAsTemplate,
    RegenerateWorkbooks,
    ListFinancialStatementTemplates,
    ApplyTemplateToFinancialStatements,
    UpdateWorkbookRegenerationStatuses,
    UsePredefinedNumberStyle,
    ClearCompletedRevisionTracking,
    DeleteFinancialStatementTemplate
};
