export type SubmissionHistoryRange = {
    start: Date | null;
    end: Date | null;
}

export const allRange = 'All data collections'
export const customRange = 'Data collections from custom range';
export const priorMonthRange = 'Data collections from prior month';
export const currentMonthRange = 'Data collections from current month';
export const ytdRange = 'Data collections for year to date';

export type SubmissionHistoryRangeSelectionType = typeof customRange | typeof priorMonthRange | typeof currentMonthRange | typeof ytdRange | typeof allRange;

export function GetAllSubmissionHistoryRange(): SubmissionHistoryRange {
    const start = new Date();
    start.setDate(1);
    start.setHours(0, 0, 0, 0);

    const end = new Date(start);
    end.setMonth(start.getMonth() + 1);
    end.setDate(0);  // Will set the date to the last day of the preceding month.  We set the month in the 
    // prior line to one more than the current month so this will be the last day of
    // the current month.
    end.setHours(23, 59, 59, 999);

    start.setFullYear(1970, 0, 1);   // January 1, 1970

    return {
        start,
        end
    }
}

export function GetCurrentMonthSubmissionHistoryRange(): SubmissionHistoryRange {
    const start = new Date();
    start.setDate(1);
    start.setHours(0, 0, 0, 0);

    const end = new Date(start);
    end.setMonth(start.getMonth() + 1);
    end.setDate(0);  // Will set the date to the last day of the preceding month.  We set the month in the 
    // prior line to one more than the current month so this will be the last day of
    // the current month.
    end.setHours(23, 59, 59, 999);

    return {
        start,
        end
    }
}

export function GetPriorMonthSubmissionHistoryRange(): SubmissionHistoryRange {
    const end = new Date();
    end.setDate(0);  // Will set the date to the last day of the preceding month. end is initialized
    // to the current date so this is the last day of the prior month
    end.setHours(23, 59, 59, 999);

    const start = new Date(end);
    start.setDate(1);
    start.setHours(0, 0, 0, 0);

    return {
        start,
        end
    }
}

export function GetYtdSubmissionHistoryRange(): SubmissionHistoryRange {
    const end = new Date();
    end.setHours(23, 59, 59, 999);

    const start = new Date(end);
    start.setMonth(0, 1);
    start.setHours(0, 0, 0, 0);

    return {
        start,
        end
    }
}

