import { AppCookieData } from '../../Models/CookieData';
import { DetailedUserResponse, PostLoginUserResponse, UserResponse } from '../../Models/Api/strongbox.financialportal';

export enum UserActions {
    LoadUsers = 'USER/LoadUsers',
    LoadUsersComplete = 'USER/LoadUsersComplete',
    SetLoggedInUser = 'USER/SetLoggedInUser',
    Initialize = 'USER/Initialize',
    SetSessionInfo = 'USER/SetSessionInfo',
    LogUserOut = 'USER/LOGUSEROUT',
    LoadDetailedUsersComplete = 'USER/LoadDetailedUsersComplete'
}

export interface ILoadUsersAction {
    readonly type: UserActions.LoadUsers;
}

export interface ILoadUsersCompleteAction {
    readonly type: UserActions.LoadUsersComplete;
    readonly users: UserResponse[];
}

export interface ILoadDetailedUsersCompleteAction {
    readonly type: UserActions.LoadDetailedUsersComplete;
    readonly detailedUsers: DetailedUserResponse[];
}

export interface IInitializeAction {
    readonly type: UserActions.Initialize;
    readonly appData: AppCookieData;
}

export interface ISetLoggedInUser {
    readonly type: UserActions.SetLoggedInUser;
    readonly user?: PostLoginUserResponse;
}

export interface IAppSetSessionInfo {
    readonly type: UserActions.SetSessionInfo;
    readonly token?: string;
}

export interface IAppLogUserOut {
    readonly type: UserActions.LogUserOut;
}

export type KnownAction =
    ILoadUsersAction |
    ILoadUsersCompleteAction | 
    ISetLoggedInUser |
    IInitializeAction |
    IAppLogUserOut |
    IAppSetSessionInfo |
    ILoadDetailedUsersCompleteAction;
