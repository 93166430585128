import * as React from 'react';

import './RiskAnalysis.scss';

import { RiskCalcDescriptiveOutput, RiskCalcResponse } from '../../../Models/Api/strongbox.financialportal';

import { CartesianGrid, Legend, LineChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

type Props = {
    riskCalcResponse: RiskCalcResponse;
};

type GraphModel = {
    period: string;
    annualizedEDF?: number;
    forward1YrEDF?: number;
    cumulativeEDF?: number;
};

export const RiskCalcResponseGraph: React.FC<Props> = (props): React.ReactElement => {
    const {
        riskCalcResponse,
    } = props;

    const [graphModel, setGraphModel] = React.useState<GraphModel[]>([]);
    const [graphKey, setGraphKey] = React.useState<number>(1);

    const extractYearValues = (outputs: RiskCalcDescriptiveOutput[], yearSelector: string, period: string): GraphModel => {
        let result: GraphModel = {
            period,
            annualizedEDF: 0.0,
            cumulativeEDF: 0.0,
            forward1YrEDF: 0.0,
        };

        outputs.forEach(output => {
            const parts = output.type && output.type.split('_');
            if ((!!parts) && (parts.length >= 2) && (output.value !== undefined)) {
                // Some of the output names have multiple underscores up until the part
                // we care about.  Look at the last element in the array for the selector
                // and then combine the others into 1 for comparison against known selectors.

                if (parts[parts.length - 1].toLowerCase() === yearSelector) {
                    let compareTag = '';

                    parts.forEach((tagPart: string, index: number) => {
                        if (index < parts.length - 1) {
                            compareTag = compareTag + tagPart.toLowerCase();
                        }
                    })

                    const floatVal = parseFloat(output.value);
                    switch (compareTag) {
                        case 'annualizededf':
                            result.annualizedEDF = floatVal;
                            break;
                        case 'forward1yredf':
                            result.forward1YrEDF = floatVal;
                            break;
                        case 'cumulativeedf':
                            result.cumulativeEDF = floatVal;
                            break;
                    }
                }
            }
        })

        return result;
    }

    React.useEffect(() => {
        if (!(riskCalcResponse && riskCalcResponse.result && riskCalcResponse.result.outputs)) {
            setGraphModel([]);
            return;
        }

        const oneYear = extractYearValues(riskCalcResponse.result.outputs, '1yr', '1 Yr');
        const twoYear = extractYearValues(riskCalcResponse.result.outputs, '2yr', '2 Yrs');
        const threeYear = extractYearValues(riskCalcResponse.result.outputs, '3yr', '3 Yrs');
        const fourYear = extractYearValues(riskCalcResponse.result.outputs, '4yr', '4 Yrs');
        const fiveYear = extractYearValues(riskCalcResponse.result.outputs, '5yr', '5 Yrs');

        setGraphModel([oneYear, twoYear, threeYear, fourYear, fiveYear]);
        setGraphKey(graphKey + 1);

        // We're changing graphKey so we definitely do not want to add that as a dependency.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [riskCalcResponse])

    return (
        <div key={graphKey} className={'fin-risk-result-component graph-container'}>
            <ResponsiveContainer className={'chart-container'} >
                <LineChart data={graphModel} >
                    <Line name={'Annualized'} type={'monotone'} dataKey={'annualizedEDF'} stroke={'#00ff00'} />
                    <Line name={'1 Year Forward'} type={'monotone'} dataKey={'forward1YrEDF'} stroke={'#0000ff'} />
                    <CartesianGrid />
                    <YAxis  />
                    <XAxis dataKey={'period'} />
                    <Legend verticalAlign={'top'} />
                    <Tooltip
                        formatter={(value: string, name: React.ReactText) => {
                            const fpValue = parseFloat(value);
                            if (isNaN(fpValue)) {
                                return [value, name];
                            } else {
                                return [fpValue.toFixed(2), name];
                            }
                        }}
                    />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}
