import * as React from 'react';

import {
    Col,
    Container,
    Input,
    Label,
    Popover,
    PopoverHeader,
    PopoverBody,
    Row
} from 'reactstrap';

import '../Main.scss';
import './Naics.scss';

import { NaicsCodeEntity } from '../../Models/Api/strongbox.financialportal';

import { DropDownCaret } from '../DropDownCaret/DropDownCaret';
import { debounceStateChanges } from '../../Utils/Debounce';
import { GetNaicsCodes } from '../../Services/Industries';

type Props = {
    selectedNaicsCode?: NaicsCodeEntity;
    onCodeSelected: (selectedCode: NaicsCodeEntity) => void;
    disabled?: boolean;
    id?: string;
    onEditContentChanged: (content: string) => void;
    editContent: string;
};

type NaicsCodeEntityWithMeta = {
    codeEntity: NaicsCodeEntity;
    additionalExpanded: boolean;
}

export const NaicsAutoComplete: React.FC<Props> = (props): React.ReactElement => {
    const defaultInputId = 'industryDescription';

    const [matches, setMatches] = React.useState<NaicsCodeEntityWithMeta[]>([]);
    const [showingMatches, setShowingMatches] = React.useState<boolean>(false);

    const selectNaicsCode = (match: NaicsCodeEntity): void => {
        props.onCodeSelected(match);
    }

    const debouncedGetNaicsCode = React.useMemo(() => {
        const naicsTextHasChanged = (priorSearchText: string | undefined, nextSearchText: string | undefined): boolean => {
            const editContent = nextSearchText || '';
            props.onEditContentChanged(editContent);
            return priorSearchText === undefined || priorSearchText !== nextSearchText;
        }

        const getNaicsCode = (searchText: string | undefined): void => {
            if (!searchText) {
                return;
            }

            GetNaicsCodes(searchText)
                .then(codes => {
                    const mappedMatches = codes.map(codeEntity => { return { codeEntity, additionalExpanded: false }; });
                    setMatches(mappedMatches);
                    // While it's unlikely this will happen from the user just typing, it could happen from
                    // remembered values for the field.  In that case we can use the remembered value.
                    if ((codes.length === 1) && (!!codes[0].Description) && (codes[0].Description.toLowerCase() === searchText.toLowerCase())) {
                        selectNaicsCode(codes[0]);
                        setShowingMatches(false);
                    } else {
                        setShowingMatches(true);
                    }
                });
        }

        return debounceStateChanges<string>(
            getNaicsCode,
            500,
            naicsTextHasChanged);
        // Addressing this warning is meaningless.  Specifically, selectNaicsCode is a function
        // above that will not change as is onEditContentChanged.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggleMatchExpansion = async (match: NaicsCodeEntityWithMeta, matchIndex: number): Promise<void> => {
        const newMatches =
            matches.slice(0, matchIndex)
                .concat([{ codeEntity: match.codeEntity, additionalExpanded: !match.additionalExpanded }])
                .concat(matches.slice(matchIndex + 1));
        setMatches(newMatches);
    }

    return (
        <Container>
            <Row>
                <Col style={{ paddingLeft: '0px' }}>
                    <Input
                        type={'text'}
                        placeholder={'Search by keyword or NAICS code'}
                        id={!!props.id ? props.id : defaultInputId}
                        key={defaultInputId}
                        value={props.editContent}
                        onChange={(e: any) => {
                            debouncedGetNaicsCode(e.currentTarget.value);
                        }}
                        disabled={props.disabled}
                    />
                    <Popover
                        hideArrow={true}
                        placement={'bottom-end'}
                        isOpen={props.disabled !== true && !!matches.length && showingMatches}
                        target={!!props.id ? props.id : defaultInputId}
                        className={'naics-popover'}
                        key={'matchesPopover'}
                        toggle={() => setShowingMatches(!showingMatches)}
                    >
                        <PopoverHeader>Possible matches</PopoverHeader>
                        <PopoverBody className={'naics-body'}>
                            <Container fluid
                                className={'naics-primary-description'}
                                key={'naics-descriptions-container'}
                            >
                                {matches.map((match, matchIndex) => !!match.codeEntity.NaicsCode && (
                                    <React.Fragment key={`${match.codeEntity.NaicsCode}-container`}>
                                        <Row>
                                            <Col
                                                onClick={() => {
                                                    selectNaicsCode(match.codeEntity);
                                                    setShowingMatches(false);
                                                    props.onEditContentChanged(match.codeEntity.Description || '');
                                                }}
                                                xs={10}
                                            >
                                                <Label className={'naics-code-descriptor'}>{match.codeEntity.Description}</Label>
                                            </Col>
                                            {!!match.codeEntity.AdditionalDescriptions && (
                                                <Col xs={2}>
                                                    <DropDownCaret
                                                        expanded={match.additionalExpanded}
                                                        expandDescriptor={'additional descriptions...'}
                                                        contractDescriptor={'hide additional...'}
                                                        clicked={() => toggleMatchExpansion(match, matchIndex)}
                                                        style={{ padding: '0px' }}
                                                    />  
                                                </Col>
                                            )}
                                        </Row>
                                        {
                                            !!match.codeEntity.AdditionalDescriptions && match.additionalExpanded && (
                                                <Row key={`${match.codeEntity.NaicsCode}-description-container`}>
                                                    <Col className={'extra-descriptions'}>
                                                        {
                                                            match.codeEntity.AdditionalDescriptions.map(description => (
                                                                <Row key={`${match.codeEntity.NaicsCode}-${description}`}>
                                                                    <Col xs={{ size:10, offset: 2 }}>
                                                                        <Label>{description}</Label>
                                                                    </Col>
                                                                </Row>
                                                            ))
                                                        }
                                                    </Col>
                                                </Row>
                                            )
                                        }
                                    </React.Fragment>
                            ))}
                            </Container>
                        </PopoverBody>
                    </Popover>
                </Col>
            </Row>
        </Container>
    );
}
