import * as React from 'react';
import {
    Col,
    Row,
} from 'reactstrap';

import {
    TenantUserMetadataSetting,
} from '../../../Models/Api/strongbox.financialportal';

import { GetRenderingContext } from '../../../Utils/FormUtils';

import { AddUsersMetaDataItem } from './CommonUser';
import { UserMetadataInput } from './UserMetadataInput';

type Props = {
    userMetadataSettingsForTenant: TenantUserMetadataSetting[];
    metadataValues: Map<string, AddUsersMetaDataItem>;   // keyed on metadata value name
    onValueChange: (name: string, value: string) => void;
}

export const EditUserMetadata: React.FC<Props> = (props): React.ReactElement => {
    const {
        userMetadataSettingsForTenant,
        metadataValues,
        onValueChange,
    } = props;

    const labelColumnStyleHorzPadding = 10;

    // For labelColumnStyle, width is fairly arbitrary.  In practice if it doesn't get
    // set correctly in useLayoutEffect below something is really screwed up.
    const [labelColumnStyle, setLabelColumnStyle] = React.useState<React.CSSProperties>({
        width: '100px',
        paddingLeft: `${labelColumnStyleHorzPadding}px`,
        paddingRight: `${labelColumnStyleHorzPadding}px`,
    });

    React.useLayoutEffect(() => {
        const context = GetRenderingContext('--font-default-control-span-size');

        if (!!context) {
            let longestText = 0;

            userMetadataSettingsForTenant.forEach(s => {
                const textWidth = context.measureText(s.label).width;
                if (textWidth > longestText) {
                    longestText = textWidth;
                }
            });

            setLabelColumnStyle({
                ...labelColumnStyle,
                width: `${longestText + (2 * labelColumnStyleHorzPadding)}px`,
            })
        }
        // metadata settings are the only dependency actually needed
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [userMetadataSettingsForTenant]);

    return (
        <Row>
            <Col>
                <Row className={'user-metadata-container'}>
                    <Col>
                        {userMetadataSettingsForTenant.map((m, i) => {
                            return (
                                <Row
                                    key={`edit-user-metadata-row-label-column-${i}`}
                                    className={'user-metadata-row'}
                                >
                                    <Col xs={'auto'} style={labelColumnStyle}>
                                        <span>{m.label}</span>
                                    </Col>
                                    <Col>
                                        <UserMetadataInput
                                            userMetadataSetting={m}
                                            onValueChange={onValueChange}
                                            metadataValues={metadataValues}
                                        />
                                    </Col>
                                </Row>
                            );
                        })}
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
