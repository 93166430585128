import * as React from 'react';
import { connect } from 'react-redux';

import LogoutButton from './Login/LogoutButton';

import { actionCreators as AppSettingsActions } from '../Store/AppSettings';
import { actionCreators as UserActions, GetAuth0LoggingOut } from '../Store/User';

import {
    ResourceName,
    RetrieveResource,
    RetrieveStyle,
    TenantParagraphSet,
} from '../Store/Tenant';

import { ApplicationState } from '../Store';

type InjectedReduxState = {
    notificationParagraphs: TenantParagraphSet;
    h1Style: Object;
    loggingOut: boolean;
}

type InjectedActionCreators = typeof AppSettingsActions & typeof UserActions;

type AuthTimedOutProps = {
};

type Props = AuthTimedOutProps & InjectedActionCreators & InjectedReduxState;

const AuthTimedOutComponent: React.FC<Props> = (props): React.ReactElement => {
    const {
        h1Style,
        loggingOut,
        notificationParagraphs,
        SetSessionInfo,
        SetLoggedInUser
    } = props;

    React.useEffect(() => {
        // immediately call this to clear the auth0 token from redux store
        // and session storage.

        SetLoggedInUser();
        SetSessionInfo(undefined, []);

        // I want this to execute equivalent to componentDidMount so this is appropriate
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const paragraphs: React.ReactNode[] = React.useMemo(() => {
        const result: React.ReactNode[] = [];

        if (!!notificationParagraphs) {
            notificationParagraphs.paragraphs.forEach((paragraph, index) => {
                result.push((
                    <h3 key={`timedOutParagraph${index}`} style={{ marginTop: '20px' }}>{paragraph}</h3>
                ));
            });
        }

        return result;
    }, [notificationParagraphs])

    return (
        <>
            {
                !!loggingOut && (
                    <div style={{ position: 'absolute', width: '100%' }} className={`free-content-region control-region control-region-lender`}>
                        <div style={{ height: '100%' }} className={'contained-content'}>
                            <h1 style={h1Style}>{'Logging Out...'}</h1>
                        </div>
                    </div>
                )
            }
            {
                !loggingOut && (
                    <div className={`full-screen-region lender-region content-region control-region control-region-lender`}>
                        <div>
                            {paragraphs}
                            <LogoutButton style={{ marginTop: '40px' }} buttonText={'Return to Login'} />
                        </div>
                    </div>
                )
            }
        </>
    )
}

export const AuthTimedOut = connect<InjectedReduxState, InjectedActionCreators, AuthTimedOutProps, ApplicationState>(
    (appState: ApplicationState) => {
        const result = {
            notificationParagraphs: RetrieveResource(appState, ResourceName.tenantAuthorizationTimedOut),
            h1Style: RetrieveStyle(appState, 'h1-login'),
            loggingOut: GetAuth0LoggingOut(appState),
        };

        return result;
    }, 
    {
        ...UserActions,
        ...AppSettingsActions
    }
)(AuthTimedOutComponent);
