export const knownAccountingGroupIds = {
    financialStatements: 'financial-statements',
    accountsReceivable: 'accounts-receivable',
    accountsPayable: 'accounts-payable',
    transactions: 'transactions',
}

export type AccountingCollectionGroup = {
    collect: boolean;
    readOnly?: boolean;
    hidden?: boolean;
    name?: string;
    id: string;
}

/**
 * Creates a reasonable default accounting collection group set.  Generally this would mean that something
 * has gone wrong and we need to fall back to something okay.
 * */
export function DefaultAccountingCollectionGroup(): Map<string, AccountingCollectionGroup> {
    const accountingCollectionGroups = new Map<string, AccountingCollectionGroup>();
    accountingCollectionGroups.set(
        knownAccountingGroupIds.accountsPayable,
        {
            collect: true,
            id: knownAccountingGroupIds.accountsPayable,
            hidden: false,
            name: 'Accounts payable',
            readOnly: false,
        }
    );
    accountingCollectionGroups.set(
        knownAccountingGroupIds.accountsReceivable,
        {
            collect: true,
            id: knownAccountingGroupIds.accountsReceivable,
            hidden: false,
            name: 'Accounts receivable',
            readOnly: false,
        }
    );
    accountingCollectionGroups.set(
        knownAccountingGroupIds.transactions,
        {
            collect: true,
            id: knownAccountingGroupIds.transactions,
            hidden: false,
            name: 'Transactions',
            readOnly: false,
        }
    );
    accountingCollectionGroups.set(
        knownAccountingGroupIds.financialStatements,
        {
            collect: true,
            id: knownAccountingGroupIds.financialStatements,
            hidden: false,
            name: 'Financial statements',
            readOnly: false,
        }
    );

    return accountingCollectionGroups;
}