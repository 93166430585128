import * as React from 'react';
import {
    Button,
    Col,
    Row,
} from 'reactstrap';

import {
    Tooltip
} from '@mui/material';

import ErrorIcon from '@mui/icons-material/ErrorRounded';

import { AddUsersMode } from './CommonUser';

type Props = {
    onInviteUser: () => void;
    addEnabled: boolean;
    allowMetadata: boolean;
    invalidUserMetadata: boolean;
    changeEditMode: (newMode: AddUsersMode) => void;
    mode: AddUsersMode;
}

export const AddUserInfoButtonsRow: React.FC<Props> = (props): React.ReactElement => {
    const {
        addEnabled,
        allowMetadata,
        onInviteUser,
        invalidUserMetadata,
        changeEditMode,
        mode,
    } = props;

    const modeButtonStyle: React.CSSProperties = {
        marginRight: '15px'
    }

    return (
        <Row className={'user-metadata-button-row'}>
            <Col xs={12}>
                <Button
                    color="primary"
                    onClick={() => {
                        onInviteUser();
                    }}
                    disabled={!addEnabled}
                    className={'small'}
                >
                    Add
                </Button>
                {
                    allowMetadata && (mode === 'editrequired') && (
                        <Tooltip title={invalidUserMetadata ? 'One or more optional fields have invalid content.' : ''}>
                            <span>
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        changeEditMode('editmetadata');
                                    }}
                                    className={'small'}
                                    style={modeButtonStyle}
                                >
                                    Edit Optional User Data
                                    {
                                        invalidUserMetadata && (
                                            <ErrorIcon
                                                style={{
                                                    marginLeft: '10px'
                                                }}
                                            />
                                        )
                                    }
                                </Button>
                            </span>
                        </Tooltip>
                    )
                }
                {
                    allowMetadata && (mode === 'editmetadata') &&  (
                        <Button
                            color="primary"
                            onClick={() => {
                                changeEditMode('editrequired');
                            }}
                            className={'small'}
                            style={modeButtonStyle}
                        >
                            Edit Required Fields
                        </Button>
                    )
                }
            </Col>
        </Row>
    );
}
