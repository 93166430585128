import { Reducer } from 'redux';

import {
    NotificationMethod,
    UserNotificationSettings,
    WorkspaceNotificationSubscription,
    AdministrativeTenantNotificationSettings,
} from '../../Models/Api/strongbox.financialportal';

import { LogMessage, SeverityLevel } from '../../Utils/Logging';

import {
    KnownAction,
    UserSettingsActions
} from './Actions';

export type UserNotificationTypes = {
    type: NotificationMethod;
    description: string;
}

export type GlobalUserSettings = {
    notificationData: UserNotificationSettings;
    notificationTypes: UserNotificationTypes[];
}

export type WorkspaceNotificationsByWorkspace = {
    workspaceId: string;
    workspaceName: string;
    notifications: WorkspaceNotificationSubscription[]
}

export type UserWorkspaceSettings = {
    userId: string;
    settings: WorkspaceNotificationsByWorkspace[];
}

export interface IUserSettingsState {
    global?: GlobalUserSettings;
    workspaceSettings: UserWorkspaceSettings[];
    adminTenantSettings?: AdministrativeTenantNotificationSettings;
}

export const defaultUserSettingsState: IUserSettingsState = {
    global: undefined,
    workspaceSettings: []
}

function UpdateWorkspaceSetting(
    userId: string,
    workspaceId: string,
    notificationId: number,
    notificationType: string,
    turnedOn: boolean,
    newState: IUserSettingsState,
    userSettings: UserWorkspaceSettings[]
): boolean {
    let iUserSetting = newState.workspaceSettings.findIndex(userWsSettings => userWsSettings.userId === userId);
    let iWorkspaceSetting = -1;
    let iWorkspaceNotificationSetting = -1;
    let iNotifyMethodSetting = -1;

    if (iUserSetting !== -1) {
        iWorkspaceSetting = newState.workspaceSettings[iUserSetting].settings.findIndex(wsSetting => wsSetting.workspaceId === workspaceId);
        if (iWorkspaceSetting !== -1) {
            iWorkspaceNotificationSetting =
                newState.workspaceSettings[iUserSetting].settings[iWorkspaceSetting].notifications.findIndex(notifySetting => notifySetting.notificationId === notificationId);
            if (iWorkspaceNotificationSetting !== -1) {
                const methods = newState.workspaceSettings[iUserSetting].settings[iWorkspaceSetting].notifications[iWorkspaceNotificationSetting];
                if (!!methods.notificationMethods) {
                    iNotifyMethodSetting = methods.notificationMethods.findIndex(method => method.type === notificationType);
                    if (iNotifyMethodSetting !== -1) {
                        const workspaceSettings = userSettings[iUserSetting].settings[iWorkspaceSetting].notifications[iWorkspaceNotificationSetting];
                        if (!!workspaceSettings.notificationMethods) {
                            workspaceSettings.notificationMethods = workspaceSettings.notificationMethods.slice();
                            workspaceSettings.notificationMethods[iNotifyMethodSetting].enabled = turnedOn;
                            return true;
                        }
                    }
                }
            }
        }
    }
    return false;
}

export const reducer: Reducer<IUserSettingsState, KnownAction> = (state: IUserSettingsState | undefined, action: KnownAction): IUserSettingsState => {
    let newState: IUserSettingsState | undefined = undefined;

    switch (action.type) {
        case UserSettingsActions.LoadGlobalUserSettings:
            break;
        case UserSettingsActions.LoadGlobalUserSettingsComplete:
            newState = {
                ...(state ? state : defaultUserSettingsState),
                global: action.settings,
            }
            break;
        case UserSettingsActions.LoadTenantAdminSettings:
            break;
        case UserSettingsActions.LoadTenantAdminSettingsComplete:
            newState = {
                ...(state ? state : defaultUserSettingsState),
                adminTenantSettings: action.settings,
            }
            break;
        case UserSettingsActions.UpdateGlobalSetting: {
            break;
        }
        case UserSettingsActions.UpdateGlobalSettingComplete: {
            if (!(action.successful && action.settings && action.settings.settings)) {
                break;
            }
            newState = {
                ...(state ? state : defaultUserSettingsState)
            }
            if (!!newState &&
                !!newState.global &&
                !!newState.global.notificationData &&
                !!newState.global.notificationData.settings
            ) {
                newState.global.notificationData.settings = newState.global.notificationData.settings.slice();
                action.settings.settings.forEach(setting => {
                    // Sanity check, shouldn't be possible for notificationMethods to be undefined
                    if (!!setting.notificationMethods) {
                        const iTarget = newState!.global!.notificationData.settings!.findIndex(targetSetting => targetSetting.id === setting.notificationId);
                        if (iTarget !== -1) {
                            newState!.global!.notificationData.settings![iTarget].notificationMethods = setting.notificationMethods.slice();
                        }
                    }
                })
            } else {
                // Sanity check, shouldn't be possible.
                newState = undefined;
            }
            break;
        }
        case UserSettingsActions.LoadWorkspaceUserSettings:
            break;
        case UserSettingsActions.LoadWorkspaceUserSettingsComplete: {
            if (!(action.successful && action.subscriptions)) {
                break;
            }
            newState = {
                ...(state ? state : defaultUserSettingsState),
            };

            const settings: UserWorkspaceSettings = {
                userId: action.userId,
                settings: []
            };

            if (!!action.subscriptions.subscriptions) {
                action.subscriptions.subscriptions.forEach(sub => {
                    let workspaceSettings = settings.settings.find(subWs => subWs.workspaceId === sub.workspaceId);
                    if (!workspaceSettings) {
                        if (!(sub.workspaceId && sub.workspaceName)) {
                            LogMessage(
                                'LoadWorkspaceUserSettingsComplete, unexpected empty string(s)',
                                SeverityLevel.Warning,
                                {
                                    workspaceId: sub.workspaceId,
                                    workspaceName: sub.workspaceName,
                                }
                            );
                        }
                        workspaceSettings = {
                            workspaceId: sub.workspaceId || '',
                            workspaceName: sub.workspaceName || '',
                            notifications: []
                        }
                        settings.settings.push(workspaceSettings);
                    }
                    workspaceSettings.notifications.push(sub);
                })
            }

            const iUser = newState.workspaceSettings.findIndex(wsSetting => wsSetting.userId === action.userId);
            if (iUser === -1) {
                newState.workspaceSettings = newState.workspaceSettings.slice().concat(settings);
            } else {
                newState.workspaceSettings = newState.workspaceSettings.slice();
                newState.workspaceSettings[iUser] = settings;
            }
            break;
        }
        case UserSettingsActions.BatchUpdateWorkspaceUserSetting: {
            break;
        }
        case UserSettingsActions.UpdateWorkspaceUserSetting: {
            break;
        }
        case UserSettingsActions.UpdateWorkspaceUserSettingComplete: {
            if (!(action.successful)) {
                break;
            }
            newState = {
                ...(state ? state : defaultUserSettingsState),
            };

            let isStateUpdated = false;

            newState.workspaceSettings = newState.workspaceSettings.slice();
            isStateUpdated = UpdateWorkspaceSetting(
                action.userId,
                action.workspaceId,
                action.notificationId,
                action.notificationType,
                action.turnedOn,
                newState,
                newState.workspaceSettings
            )

            if (!isStateUpdated) {
                console.error(`failed updating state for workspace notification setting ${action.userId}:${action.workspaceId}:${action.notificationId}:${action.notificationType}`);

                LogMessage(
                    'Failed to update state for workspace notification setting',
                    SeverityLevel.Warning,
                    {
                        userId: action.userId,
                        workspaceId: action.workspaceId,
                        notificationId: action.notificationId,
                        notificationType: action.notificationType,
                        turnedOn: action.turnedOn
                    }
                )

                newState = undefined;
            }
            break;
        }
        case UserSettingsActions.BatchUpdateWorkspaceUserSettingComplete: {
            if (!(action.successful)) {
                break;
            }
            newState = {
                ...(state ? state : defaultUserSettingsState),
            };

            let isStateUpdated = false;

            newState.workspaceSettings = newState.workspaceSettings.slice();

            action.workspaceIds.forEach(workspaceId => {
                isStateUpdated = UpdateWorkspaceSetting(
                    action.userId,
                    workspaceId,
                    action.notificationId,
                    action.notificationType,
                    action.turnedOn,
                    newState!,
                    newState!.workspaceSettings
                ) || isStateUpdated;
            });

            if (!isStateUpdated) {
                console.error(`failed updating state for one or more workspace notification settings ${action.userId}:${action.notificationId}:${action.notificationType}`);

                LogMessage(
                    'Failed to update state for workspace notification setting',
                    SeverityLevel.Warning,
                    {
                        userId: action.userId,
                        workspaceIds: action.workspaceIds,
                        notificationId: action.notificationId,
                        notificationType: action.notificationType,
                        turnedOn: action.turnedOn
                    }
                )

                newState = undefined;
            }
            break;
        }
        case UserSettingsActions.AddTenantAdminNotificationRecipientComplete: {
            if (!action.setting) {
                break;
            }
            newState = {
                ...(state ? state : defaultUserSettingsState),
            };
            if ((!newState.adminTenantSettings) || (!newState.adminTenantSettings.settings)) {
                newState = undefined;
                break;
            }
            const iSetting = newState.adminTenantSettings.settings.findIndex(s =>
                s.id === action.setting!.id &&
                s.type === action.setting!.type
            );
            if (iSetting === -1) {
                newState.adminTenantSettings.settings = newState.adminTenantSettings.settings.slice();
                newState.adminTenantSettings.settings.push(action.setting);
            } else {
                newState.adminTenantSettings.settings =
                    newState.adminTenantSettings.settings.slice(0, iSetting)
                        .concat([action.setting])
                        .concat(newState.adminTenantSettings.settings.slice(iSetting + 1));
            }
            break;
        }
        case UserSettingsActions.RemoveTenantAdminNotificationRecipientComplete:
        case UserSettingsActions.UpdateNotificationSettingActionComplete: {
            if (!action.setting) {
                break;
            }
            newState = {
                ...(state ? state : defaultUserSettingsState),
            };
            if ((!newState.adminTenantSettings) || (!newState.adminTenantSettings.settings)) {
                newState = undefined;
                break;
            }
            const iSetting = newState.adminTenantSettings.settings.findIndex(s =>
                s.id === action.setting!.id &&
                s.type === action.setting!.type
            );
            if (iSetting !== -1) {
                newState.adminTenantSettings.settings = newState.adminTenantSettings.settings.slice();
                newState.adminTenantSettings.settings[iSetting] = action.setting;
            }
            break;
        }
    }

    if (newState) {
        return newState;
    } else if (state) {
        return state;
    } else {
        let defaultCopy: IUserSettingsState = {
            ...defaultUserSettingsState,
        };
        return defaultCopy;
    }
}
