import * as React from 'react';

import {
    Table
} from 'reactstrap';

import {
    FinancialStatementWorkbookRegenerationTracking,
} from '../../../Store/FinancialStatements';

import {
    WorkbookRevisionStatus
} from '../../../Models/Api/strongbox.financialportal';

import { FormatDate, simpleTime } from '../../../Utils/DateUtils';
import { GetStatementTypeTitle, GetTrackingStatusText } from './Utilities';

import { AnimatedEllipsis } from '../../AnimatedEllipsis/AnimatedEllipsis';

import CheckIcon from '@mui/icons-material/CheckCircleRounded';
import ErrorIcon from '@mui/icons-material/ErrorRounded';

type Props = {
    regenerationTrackingList: FinancialStatementWorkbookRegenerationTracking[];
    onNavToLatestRevision: () => void;
}

export const FinancialStatementWorkbookRegenerationStatusList: React.FC<Props> = (props): React.ReactElement => {
    const {
        onNavToLatestRevision,
        regenerationTrackingList,
    } = props;

    const iconStatusType: React.CSSProperties = {
        width: '5%'
    }

    const styleStatementType: React.CSSProperties = {
        width: '10%'
    }
    const styleStartedAt: React.CSSProperties = {
        width: '10%'
    }
    const styleStatus: React.CSSProperties = {
        width: '50%'
    }

    const hasErrors = React.useMemo(() => {
        return !!regenerationTrackingList.find(tracking => {
            return !!tracking.status && tracking.status.outcome === 'Error';
        })
    }, [regenerationTrackingList])

    const getErrorSummary = (tracking: FinancialStatementWorkbookRegenerationTracking): string => {
        if (!tracking.status) {
            return '';
        }
        if (tracking.status!.outcome !== 'Error') {
            return '';
        }
        if (!tracking.status!.workbooks) {
            return 'Failure generating one or more workbooks';
        }
        let result = '';
        tracking.status!.workbooks.forEach(workbook => {
            result += `${!!result ? ', ' : ''}${workbook.outcome === 'Error' ? workbook.errorDescription : ''}`;
        })
        return result;
    }

    const getTrackingStatusContent = (status: WorkbookRevisionStatus): React.ReactElement => {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row'
                }}
            >
                <span className={'vcentered'}>{GetTrackingStatusText(status)}</span>
                {status.outcome === 'Pending' && (
                    <div style={{
                        height: '4px',
                        marginTop: '7px'
                    }}>
                        <AnimatedEllipsis />
                    </div>
                )}
                {status.outcome === 'Success' && (
                    <button
                        className={'extrasmall'}
                        style={{
                            marginLeft: '20px'
                        }}
                        onClick={() => {
                            onNavToLatestRevision();
                        }}
                    >
                        Go to revision
                    </button>
                )}
            </div>
        )
    }

    const getTrackingStatusIcon = (status: WorkbookRevisionStatus): React.ReactElement | undefined => {
        const iconHeight = '24px';
        const iconWidth = '24px';

        if (status.outcome === 'Pending') {
            return undefined;
        }
        switch (status.outcome) {
            case 'Error':
                return (<ErrorIcon color={'primary'} style={{ width: iconWidth, height: iconHeight }} />);
            case 'Success':
                return (<CheckIcon color={'primary'} style={{ width: iconWidth, height: iconHeight }} />);
        }
    }

    return (
        <Table
            className={'normal-list'}
        >
            <thead>
                <tr>
                    <th style={styleStatementType} className={'left'}>
                        Started From
                    </th>
                    <th style={styleStartedAt} className={'left'}>
                        Started At
                    </th>
                    <th style={iconStatusType} />
                    <th style={styleStatus} className={'left'}>
                        Status
                    </th>
                    {
                        hasErrors && (
                            <th className={'remaining left'}>
                                Error Description
                            </th>
                        )
                    }
                </tr>
            </thead>
            <tbody>
                {
                    regenerationTrackingList.map((track, index) => {
                        return (
                            <tr
                                key={`workbook-regeneration-tracking-list-item-${index}`}
                            >
                                <td style={styleStatementType} className={'left'}>
                                    {GetStatementTypeTitle(track.financialStatementType, true)}
                                </td>
                                <td style={styleStartedAt} className={'left'}>
                                    {FormatDate(track.startedAt, simpleTime)}
                                </td>
                                <td style={iconStatusType}>
                                    {!!track.status && getTrackingStatusIcon(track.status)}
                                </td>
                                <td style={styleStatus} className={'left'}>
                                    {!!track.status ? getTrackingStatusContent(track.status) : ''}
                                </td>
                                {
                                    hasErrors && (
                                        <td className={'left remaining'}>
                                            {getErrorSummary(track)}
                                        </td>
                                    )
                                }
                            </tr>
                        )
                    })
                }
            </tbody>
        </Table>
    );
}