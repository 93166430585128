import { BasicGet } from './SimpleFetchCall';

import { BannerMessage, TenantUserMetadataSetting } from '../Models/Api/strongbox.financialportal';

export async function GetTenantEmailDomains(): Promise<string[]> {
    return await BasicGet<string[]>(
        `/api/tenant/emaildomains`,
        'GetTenantEmailDomains'
    );
}

export async function GetTenantUserMetadataSettings(): Promise<TenantUserMetadataSetting[]> {
    return await BasicGet<TenantUserMetadataSetting[]>(
        `/api/tenant/usermetadatasettings`,
        'GetTenantUserMetadataSettings'
    );
}

export async function GetTenantBannerMessages(): Promise<BannerMessage[]> {
    return await BasicGet<BannerMessage[]>(
        `/api/tenant/bannermessages`,
        'GetTenantBannerMessages'
    );
}