import * as React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ApplicationState } from '../../Store';
import { GetPortalBusyState } from '../../Store/UIState';
import { GetBannerMessages, GetTenantName, TenantActionCreators } from '../../Store/Tenant';
import { actionCreators as RunningImportsActions } from '../../Store/RunningImports';
import { WorkspaceList } from './WorkspaceList/WorkspaceList';

import { RefreshPendingJobsTimeout } from '../../Utils/Constants';

import { BusyWindowWrapper } from '../LoadingMessageWithIndicator/BusyWindowWrapper';

import { ConfigurableMessageBanner } from '../ConfigurableMessageBanner/ConfigurableMessageBanner';
import { BannerMessage } from '../../Models/Api/strongbox.financialportal';

type TabNames = 'workspacelist' | 'submissionlist';

type InjectedReduxState = {
    activeTab?: TabNames;
    portalBusy: boolean;
    bannerMessages: BannerMessage[];
    tenantName: string;
};

type InjectedActionCreators = typeof RunningImportsActions & typeof TenantActionCreators;

type LenderHomeProps = {};

type Props = LenderHomeProps & InjectedActionCreators & InjectedReduxState;

export const LenderHomeComponent: React.FC<Props> = (props): React.ReactElement => {
    const {
        bannerMessages,
        FetchBannerMessages,
        ListPendingJobs,
    } = props;

    function RefreshPendingJobsList(): void {
        ListPendingJobs(false);
    }

    React.useEffect(() => {
        FetchBannerMessages();
        
        const idTimer = setInterval(RefreshPendingJobsList, RefreshPendingJobsTimeout);
        return () => { clearInterval(idTimer); }

        // I want this to run as componentDidMount so no dependencies in the list
        // eslint-disable-next-line react-hooks/exhaustive-deps                                                                                                         
    }, [])

    return (
        <div style={{ position: 'absolute', width: '100%' }} className={`free-content-region`} >
            {
                bannerMessages.map((msg, msgIndex) => (
                    <ConfigurableMessageBanner
                        message={msg}
                        key={`configurable-message-container-outer-${msgIndex}`}
                        keyIndex={msgIndex}
                    />
                ))
            }
            <div style={{ height: '100%' }} className={`contained-content control-region control-region-lender`} >
                <BusyWindowWrapper
                    portalBusy={props.portalBusy}
                >
                    <WorkspaceList />
                </BusyWindowWrapper>
            </div>
        </div>
    );
}

export const LenderHome = connect<InjectedReduxState, InjectedActionCreators, LenderHomeProps, ApplicationState>(
    (appState: ApplicationState) => {
        return {
            portalBusy: GetPortalBusyState(appState),
            bannerMessages: GetBannerMessages(appState),
            tenantName: GetTenantName(appState)
        };
    },
    dispatch => bindActionCreators(
        {
            ...RunningImportsActions,
            ...TenantActionCreators
        },
        dispatch
    )
)(LenderHomeComponent);
