export enum GlobalSettingsActions {
    InitializeMinMappingDate = 'GlobalSettings/INIT_MINMAPPINGDATE',
}

export interface IInitializeMinMappingDate {
    type: GlobalSettingsActions.InitializeMinMappingDate;
    minMappingDate?: Date;
}

export type KnownAction =
    IInitializeMinMappingDate;
