import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import '../../Main.scss';
import './FinancialStatements.scss';

import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Container,
    Row,
    Col,
    Table
} from 'reactstrap';

import {
    Tooltip
} from '@mui/material';

import TemplateIcon from '@mui/icons-material/ListAlt';
import DeleteIcon from '@mui/icons-material/Delete';

import {
    actionCreators as FinancialStatementActions,
    GetTemplatesList
} from '../../../Store/FinancialStatements';
import {
    actionCreators as UIStateActions,
    GetPortalBusyState
} from '../../../Store/UIState';
import {
    LoggedInUserHasAccess
} from '../../../Store/User';
import { ApplicationState } from '../../../Store';

import { LoadingIndicator } from '../../LoadingIndicator/LoadingIndicator';

import {
    FinancialStatementTemplatesList,
    FinancialStatementTemplateDescriptor
} from '../../../Models/Api/strongbox.financialportal';

import { ConfirmModal, ConfirmModalType } from '../../ConfirmModal/ConfirmModal';

const MaxDescriptionDisplayLength = 40;

type InjectedReduxState = {
    portalBusy: boolean;
    templates?: FinancialStatementTemplatesList;
    isDeleteEnabled: boolean;
}

type InjectedActionCreators = typeof FinancialStatementActions & typeof UIStateActions;

type TemplateMgmtModalProps = {
    onClose: (templateId: string | undefined) => void;
};

type Props = TemplateMgmtModalProps & InjectedReduxState & InjectedActionCreators;

const TemplateMgmtModalComponent: React.FC<Props> = (props): React.ReactElement => {
    const {
        onClose,
        portalBusy,
        ListFinancialStatementTemplates,
        DeleteFinancialStatementTemplate,
        SetPortalWorking,
        SetPortalIdle,
        templates,
        isDeleteEnabled
    } = props;

    const [templateSelected, setTemplateSelected] = React.useState<string | undefined>(undefined);
    const [confirmDelete, setConfirmDelete] = React.useState<FinancialStatementTemplateDescriptor | undefined>(undefined);

    const loadTemplates = async (): Promise<void> => {
        if (!(await ListFinancialStatementTemplates())) {
            // Action creator above generates error message for display.  We should just bail.
            onClose(undefined);
        }
    }

    React.useEffect(() => {
        const workingName = 'loadingfinancialstatementtemplates';

        SetPortalWorking(workingName);
        loadTemplates()
            .then(() => {
            })
            .finally(() => {
                SetPortalIdle(workingName);
            })

        // I want this to execute equivalent to componentDidMount so this is appropriate
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const deleteTemplate = (template: FinancialStatementTemplateDescriptor): void => {
        DeleteFinancialStatementTemplate(template.id);
        if (template.id === templateSelected) {
            setTemplateSelected(undefined);
        }
    }

    const shortenedDescription = (descr: string): string => {
        if (descr.length <= MaxDescriptionDisplayLength) {
            return descr;
        }
        return `${descr.substring(0, MaxDescriptionDisplayLength - 1)}...`;
    }

    const renderEmptyTemplateList = (): React.ReactElement => {
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <span>There are no templates available. To create a template, choose 'Save as template' from this menu.</span>
                    </Col>
                </Row>
            </Container>
        )
    }

    const adminErrorMsg = 'You do not have permissions to delete financial statement templates. Only administrators can delete templates.';

    const renderTemplateList = (): React.ReactElement => {
        return (
            <form>
                <Container fluid>
                    <Row>
                        <Col>
                            <Table
                                className={'normal-list normal-list-selectable'}
                                style={{
                                    minWidth: '600px'
                                }}
                            >
                                <thead>
                                    <tr className={'litehilite column-headers unselectable'} key={'0000'}>
                                        <th
                                            className={'icon'}
                                        />
                                        <th
                                            className={'left'}
                                            style={{
                                                width: '45%'
                                            }}
                                        >
                                            Template name
                                        </th>
                                        <th className={'remainder'}>Description</th>
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !!templates && templates.financialStatementTemplates.map(t => {
                                            return (
                                                <tr
                                                    id={t.id}
                                                    key={t.id}
                                                    onClick={() => setTemplateSelected(t.id)}
                                                    className={templateSelected === t.id ? 'selected' : ''}
                                                >
                                                    <td
                                                        className={'icon'}
                                                    >
                                                        <TemplateIcon
                                                            color={'primary'}
                                                        />
                                                    </td>
                                                    <td
                                                        style={{
                                                            width: '45%'
                                                        }}
                                                        className={'left'}
                                                    >
                                                        {t.displayName}
                                                    </td>
                                                    <td className={'remainder'}>
                                                        {!!t.description && (
                                                            <Tooltip title={t.description}>
                                                                <span>{shortenedDescription(t.description)}</span>
                                                            </Tooltip>
                                                        )}
                                                    </td>
                                                    <td>
                                                        <div>
                                                            <Tooltip title={(<>{isDeleteEnabled ? 'Delete template' : adminErrorMsg}</>)}>
                                                                <span>
                                                                    <button
                                                                        disabled={!isDeleteEnabled}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            setConfirmDelete(t);
                                                                        }}
                                                                    >
                                                                        <DeleteIcon
                                                                            color={'primary'}
                                                                        />
                                                                    </button>
                                                                </span>
                                                            </Tooltip>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
            </form>
        );
    }

    return (
        <Modal
            className={`flight-modal add-financial-statement-subsection`}
            isOpen={true}
            toggle={(e: React.KeyboardEvent) => onClose(undefined)}
            backdrop={'static'}
        >
            <ModalHeader toggle={(e) => onClose(undefined)}>Financial Statement Templates</ModalHeader>
            <ModalBody className={'control-region'}>
                <div>
                    {portalBusy && (
                        <Container fluid>
                            <LoadingIndicator active={true} centerIndicator={true} />
                            <Row className={'spaced-row-centered'}>
                                <Col>
                                    <h1>Loading...</h1>
                                </Col>
                            </Row>
                        </Container>
                    )}
                    {
                        confirmDelete && (
                            <ConfirmModal
                                msg={`Are you sure you want to delete template ${confirmDelete.displayName}`}
                                title={'Confirm Delete'}
                                modalType={ConfirmModalType.yesno}
                                onTerminalButton={(yes) => {
                                    if (yes) {
                                        deleteTemplate(confirmDelete);
                                    }
                                    setConfirmDelete(undefined);
                                }}
                            />
                        )
                    }
                    {(!portalBusy) && (!!templates) && (templates.financialStatementTemplates.length <= 0) && renderEmptyTemplateList()}
                    {(!portalBusy) && (!!templates) && (templates.financialStatementTemplates.length > 0) && renderTemplateList()}
                </div>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="primary"
                    onClick={(e) => {
                        onClose(templateSelected)
                    }}
                    disabled={!templateSelected}
                    className={'small'}
                >
                    Use template
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export const TemplateMgmtModal = connect<InjectedReduxState, InjectedActionCreators, TemplateMgmtModalProps, ApplicationState>(
    (appState: ApplicationState) => {
        return {
            portalBusy: GetPortalBusyState(appState),
            templates: GetTemplatesList(appState),
            isDeleteEnabled: LoggedInUserHasAccess(appState, 'CanDeleteFinancialStatementTemplate'),
        }
    },
    dispatch => bindActionCreators(
        {
            ...FinancialStatementActions,
            ...UIStateActions
        },
        dispatch
    )
)(TemplateMgmtModalComponent);

