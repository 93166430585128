import { ApplicationState } from '../index';

import { RunningImport } from './Reducer';

export function GetRunningJobs(state: ApplicationState): RunningImport[] {
    if (state && state.runningImportsState) {
        return state.runningImportsState.runningImports.imports;
    }
    return [];
}

export function GetRunningJobsVersion(state: ApplicationState): number {
    if (state && state.runningImportsState) {
        return state.runningImportsState.runningImports.version;
    }
    return 1;
}
