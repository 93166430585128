/**
 * Converts all the values for a json object to strings.  This has uses, e.g. logging where
 * for some reason app insights doesn't like custom dimensions as numbers.
 * @param value value to convert
 * @returns value with all member values converted to strings
 */
export function JSONConvertAllMembersToString(value: any): any {
    const temp = JSON.stringify(value);
    return JSON.parse(temp, (key, value) => {
        return ((!!key) && (typeof value === 'number')) ? value.toString() : value;
    });
}
