import * as React from 'react';

import '../../Main.scss';
import './FinancialStatements.scss';

import {
    Table
} from 'reactstrap';

import { FSModels } from '@finagraph/financial-statement-editor';

import { FinancialStatementLineItemCaption } from './FinancialStatementLineItemCaption';
import { FinancialStatementLineItemActions } from './FinancialStatementLineItemActions';
import { LineItemProps } from './LineItemProps';
import { LineItemCaptionOptions } from './LineItemCaptionOptions';
import { FinancialStatementRenderData } from './UtilTypes';
import { FilterDropdownButton } from './FilterDropdown';
import { fsSectionPresentationName } from './FSConstants';

import { SanitizeDOMSelector } from '../../../Utils/DOMUtils';
import { GetRenderingContext } from '../../../Utils/FormUtils';

import FlipIcon from '@mui/icons-material/Flip';
import AddIcon from '@mui/icons-material/Add';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import SortIcon from '@mui/icons-material/Sort';

import {
    Tooltip
} from '@mui/material';

type CSSColumnInfo = {
    leftDescriptor: string;
    widthDescriptor?: string;
}

export type LineItemSelectionTracking = {
    lineItem: FSModels.FinancialStatementLineItemViewModel;
    lineItemIndex: number;
    selected: boolean;
    highlighted: boolean;
}

type Props = {
    financialStatement: FSModels.FinancialStatementViewModel;
    statementType: FSModels.FinancialStatementType;
    pxPerIndentLevel: number;
    captionPadding: number;
    stylesBelowEdit: boolean;
    renderData: FinancialStatementRenderData;
    onUpdateIndent: (lineItem: FSModels.FinancialStatementLineItemViewModel, indentLevel: number) => void;
    onShowInfo: (lineItem: FSModels.FinancialStatementLineItemViewModel) => void;
    onMultiSelect: (lineItem: FSModels.FinancialStatementLineItemViewModel, selected: boolean) => void;
    onHoverMultiSelect: (lineItem: FSModels.FinancialStatementLineItemViewModel, entering: boolean) => void;
    onMoveLineItemToNewSection: (lineItem: FSModels.FinancialStatementLineItemViewModel) => void;
    onTempLock: (lineItem: FSModels.FinancialStatementLineItemViewModel) => void;
    onDecrementLineItemSortOrder: (lineItem: FSModels.FinancialStatementLineItemViewModel) => void;
    onIncrementLineItemSortOrder: (lineItem: FSModels.FinancialStatementLineItemViewModel) => void;
    onAddSubsection: (lineItem: FSModels.FinancialStatementLineItemViewModel | undefined) => void;
    onEditLineItem: (lineItem: FSModels.FinancialStatementLineItemViewModel, targetId: string) => void;
    onFilterLineItems: () => void;
    onSortAlpha: () => void;
    onSortAccountNo: () => void;
    onSortByAccountingPeriod: (accountingPeriodId: string) => void;
    onToggleReportedAs: (sectionId: string, updatedValue: FSModels.CreditOrDebit) => void;
    onClearLineItemSelections: () => void;
    filterDropdownActive: boolean;
    lockedLineItem: FSModels.FinancialStatementLineItemViewModel | undefined;
    onLineItemMoreOptions: (lineItem: FSModels.FinancialStatementLineItemViewModel, targetId: string) => void;
    toggleColumnOrdering: () => void;
    onEditNumberFormatting: (targetId: string) => void;
    numberFormattingDropped: boolean;
    excelNumberFormatters: Map<string, (n: number) => string>;
    tableContainerId: string;
    selectedLineItems: Map<string, LineItemSelectionTracking> | undefined;
}

export const FinancialStatementLineItemsTable: React.FC<Props> = (props): React.ReactElement => {
    const {
        financialStatement,
        pxPerIndentLevel,
        captionPadding,
        statementType,
        onUpdateIndent,
        onShowInfo,
        onMultiSelect,
        onTempLock,
        onDecrementLineItemSortOrder,
        onIncrementLineItemSortOrder,
        onMoveLineItemToNewSection,
        onAddSubsection,
        onEditLineItem,
        onToggleReportedAs,
        renderData,
        onFilterLineItems,
        filterDropdownActive,
        lockedLineItem,
        onLineItemMoreOptions,
        onSortAccountNo,
        onSortAlpha,
        onSortByAccountingPeriod,
        toggleColumnOrdering,
        onEditNumberFormatting,
        numberFormattingDropped,
        excelNumberFormatters,
        tableContainerId,
        selectedLineItems,
        onHoverMultiSelect,
        onClearLineItemSelections,
    } = props;

    // These are both used for basically the same thing, ensuring that a newly moved line item (detailed account,
    // section header, section footer, whatever) remains visible after its moved.
    // 
    // See the comment below on the useEffect where they are used for more details.
    const [lineItemMoved, setLineItemMoved] = React.useState<FSModels.FinancialStatementLineItemViewModel | undefined>(undefined);
    const [scrollItemToView, setScrollItemToView] = React.useState<FSModels.FinancialStatementLineItemViewModel | undefined>(undefined);

    // cssColumnDescriptors is laid out in a very specific way based on the way it is used 
    // in React.useLayoutEffect.  It's purpose for being is to adjust the width of the
    // line item caption column such that it's basically as wide as the longest piece of text
    // in it.  Because of the way horizontal scrolling works, it's important to lay the
    // columns out at specific positions. 
    //
    // The first item in this array must be the variable values used for the caption column.  
    // While widthDescriptor MAY be undefined that is only the case for the LAST entry in
    // this array.  It'll throw an exception if that's not the case.  So don't do that.

    const cssColumnDescriptors: CSSColumnInfo[] = [
        {
            leftDescriptor: '--caption-left',
            widthDescriptor: '--caption-width'
        },
        {
            leftDescriptor: '--actions-left',
            widthDescriptor: '--actions-width'
        },
        {
            leftDescriptor: '--props-left'
        }
    ];

    const dataViewModel = financialStatement.data[0];
    const accountLookup = renderData.accountLookup;
    const captionStyleLookup = renderData.captionStyleLookup;
    const monetaryDataStyleLookup = renderData.monetaryDataStyleLookup;

    const pxValToNumeric = (val: string): number => {
        const components = val.split('px');
        return parseInt(components[0], 10);
    }

    const getIndent = (li: FSModels.FinancialStatementLineItemViewModel): number => {
        return captionPadding + (li.absoluteIndentationLevel * pxPerIndentLevel);
    }

    const LineItemsTableId = 'financial-statement-line-items-table';

    React.useLayoutEffect(() => {
        if (!!financialStatement.lineItems) {
            // This value is in px. Not starting at 0, we need at least a reasonable
            // width or the column header will be jacked.
            let longestCaptionLength = 200;

            const context = GetRenderingContext('--line-item-caption-font-size');

            const r = document.querySelector(':root') as HTMLElement;
            const computedStyle = window.getComputedStyle(r);

            const maxCaptionWidthS = computedStyle.getPropertyValue('--caption-max-width');
            let maxCaptionWidth = parseInt(maxCaptionWidthS);
            if (isNaN(maxCaptionWidth)) {
                maxCaptionWidth = 400;
            }

            if (!!context) {
                financialStatement.lineItems.forEach(li => {
                    const metrics = context.measureText(li.captionText);
                    const itemWidth = getIndent(li) + metrics.width;
                    if (itemWidth > longestCaptionLength) {
                        longestCaptionLength = Math.min(itemWidth, maxCaptionWidth);
                    }
                })
            }

            const internalErrMsg = 'internal error: useEffect in FinancialStatementLineItemsTable has invalid format for' +
                'cssColumnDescriptors. Only the last element can have an undefined widthDesctiptor';

            // Add 5% to the longest caption
            longestCaptionLength = Math.ceil(longestCaptionLength * 1.05);

            let left = 0;

            cssColumnDescriptors.forEach((descriptor, index) => {
                if (index === 0) {
                    const leftVal = pxValToNumeric(computedStyle.getPropertyValue(descriptor.leftDescriptor));
                    if (!!descriptor.widthDescriptor) {
                        r.style.setProperty(descriptor.widthDescriptor, `${longestCaptionLength}px`);
                    } else {
                        console.error(internalErrMsg);
                        throw internalErrMsg
                    }
                    left = leftVal + longestCaptionLength;
                } else {
                    r.style.setProperty(descriptor.leftDescriptor, `${left}px`);
                    if (!!descriptor.widthDescriptor) {
                        const widthVal = pxValToNumeric(computedStyle.getPropertyValue(descriptor.widthDescriptor));
                        left += widthVal;
                    } else if (index !== cssColumnDescriptors.length - 1) {
                        console.error(internalErrMsg);
                        throw internalErrMsg;
                    }
                }
            });
        }

        // This is in fact the dependency list I want
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [financialStatement.lineItems]);

    const getLineItemRowId = (lineItemId: string): string => {
        return `${statementType}-lineitem-${SanitizeDOMSelector(lineItemId)}`;
    }

    // A little weird here.  This is all about repositioning the scroll bar so a moved item is still visible.
    // 
    // When we move the line item in the data model, until after a render, its new
    // position won't be known.  On this useEffect or useLayoutEffect for that matter the NEW position isn't
    // known.  So we do one more set and when that one executes the moved line item will have been re-rendered
    // in its new location.
    //
    // Could also have done this with a counter but this is a little bit simpler.

    React.useEffect(() => {
        if (!!lineItemMoved) {
            setScrollItemToView(lineItemMoved);
            setLineItemMoved(undefined);
        }
    }, [lineItemMoved]);

    React.useEffect(() => {
        // Nothing to do unless scrollItemToView is defined.  Also, since we set it
        // to undefined below, this is pretty necessary to not end up in an endless
        // loop. Though that isn't likely as the value wouldn't actually be changing,
        // still good to do to be absolutely sure.
        if (!scrollItemToView) {
            return;
        }

        let actualItemToScrollTo = scrollItemToView;

        if (scrollItemToView.lineItemType === FSModels.FinancialStatementLineItemType.SectionFooter) {
            if (!!renderData.sectionsById) {
                const temp = renderData.sectionsById.get(scrollItemToView.sectionId);
                if (!!(temp)) {
                    const header = financialStatement.lineItems.find(li => li.id === temp.headerLineItemId);
                    if (!!header) {
                        actualItemToScrollTo = header;
                    }
                }
            }
        }

        const tableContainer = document.getElementById(tableContainerId);
        const rowHeader = document.getElementById(getLineItemRowId(actualItemToScrollTo.id));
        if (!(tableContainer && rowHeader)) {
            setScrollItemToView(undefined);
            return;
        }

        const rcTable = tableContainer.getBoundingClientRect();
        const tableTop = tableContainer.scrollTop;
        const tableBottom = tableTop + rcTable.height;

        const rowOffset = rowHeader.offsetTop;

        // These will both be relative to the container
        if ((rowOffset > tableBottom) || (rowOffset < tableTop)) {
            tableContainer.scrollTo({
                left: 0,
                top: rowOffset,
                behavior: 'smooth'
            });
        }

        onTempLock(actualItemToScrollTo);

        setScrollItemToView(undefined);

        // This is in fact the dependency list I want
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollItemToView]);

    const renderValueColumns = (lineItemData: FSModels.FinancialStatementDataCellViewModel[], lineItemIndex: number, cellClass: string, excelNumberFormat: string | undefined): React.ReactElement[] => {
        const result: React.ReactElement[] = [];

        const items = lineItemData?.slice() ?? [];

        items.forEach((lid, i) => {
            let renderedValue: string;

            if (lid.isEmpty || lid.value === null || lid.value === undefined) {
                renderedValue = '';
            } else {
                const formatter = excelNumberFormat && excelNumberFormatters && excelNumberFormatters.get(excelNumberFormat);
                renderedValue = formatter ? formatter(lid.value) : lid.value.toString();
            }

            result.push(
                <td
                    className={`data-table-cell ${cellClass}`}
                    key={`${statementType}-line-item-data-data-${lineItemIndex}.${i}`}
                >
                    {renderedValue}
                </td>
            );
        })

        return result;
    }

    return (
        <Table
            className={`financial-statement-table${!lockedLineItem ? ' financial-statement-table-dynamic-hover' : ''}`}
            id={LineItemsTableId}
        >
            <thead>
                <tr>
                    <th className={'horizontal-fixed-header col-line-item-account-no fixed-header-header-region'}>
                        <div className={'button-bar'}>
                            <Tooltip title={(<>Sort all detail accounts by Account No</>)}>
                                <button
                                    className={'header'}
                                    onClick={() => onSortAccountNo()}
                                >
                                    <SortIcon />
                                </button>
                            </Tooltip>
                            <span>Account No</span>
                        </div>
                    </th>
                    <th className={'horizontal-fixed-header col-line-item-caption-options fixed-header-header-region'}>
                        <div>
                            {!!selectedLineItems && (
                                <Tooltip title={(<>Unselect all line items</>)}>
                                    <input
                                        className={'header'}
                                        type={'checkbox'}
                                        onChange={(e) => {
                                            onClearLineItemSelections();
                                        }}
                                        checked={true}
                                    />
                                </Tooltip>
                            )}
                        </div>
                    </th>
                    <th className={'horizontal-fixed-header col-line-item-caption fixed-header-header-region'}>
                        <div className={'button-bar'}>
                            <Tooltip title={(<>Sort all detail accounts by name</>)}>
                                <button
                                    className={'header'}
                                    onClick={() => onSortAlpha()}
                                >
                                    <SortByAlphaIcon />
                                </button>
                            </Tooltip>
                            <FilterDropdownButton
                                dropdownActive={filterDropdownActive}
                                onFilterLineItems={onFilterLineItems}
                            />
                            <span style={{ marginLeft: '10px' }}>Line Items</span>
                        </div>
                    </th>
                    <th className={'horizontal-fixed-header col-line-item-actions fixed-header-header-region'}>
                        <div>
                            <button
                                className={'regular-header'}
                                onClick={(e) => {
                                    onAddSubsection(undefined);
                                }}
                            >
                                <AddIcon /><span style={{ marginLeft: '10px' }}>{`Add ${fsSectionPresentationName}`}</span>
                            </button>
                        </div>
                    </th>
                    <th className={'horizontal-fixed-header col-line-item-props fixed-header-header-region'}>
                        <div className={'align-spaced'}>
                            <div className={'button-bar'}>
                                    <Tooltip title={numberFormattingDropped ? '' : 'Edit the number formatting'}>
                                        <button
                                            id={`financial-statement-edit-number-formatting-${statementType}`}
                                            className={'header'}
                                            style={{width: 'auto'}}
                                            onClick={(e) => onEditNumberFormatting(`financial-statement-edit-number-formatting-${statementType}`)}
                                        >
                                            <span>{dataViewModel.currencyAndScalingLabel}</span>
                                        </button>
                                    </Tooltip>
                                </div>
                            <Tooltip title={'Reverse the ordering of reporting periods'}>
                                <button
                                    className={'header'}
                                    onClick={(e) => toggleColumnOrdering()}
                                >
                                    <FlipIcon />
                                </button>
                            </Tooltip>
                        </div>
                    </th>
                    {dataViewModel.columnHeaders.map((ch, index) => {
                        return (
                            <th
                                key={`financial-statement-data-table-data-header-${index}`}
                                className={'horizontal-fixed-header non-horizontal-fixed-header-header-region'}
                            >
                                    <div className={'button-bar'}>
                                    <Tooltip title={(<>Sort all detail accounts by the amounts for this accounting period</>)}>
                                        <button
                                            className={'header'}
                                            onClick={() => onSortByAccountingPeriod(ch.reportingPeriodId)}
                                        >
                                            <SortIcon />
                                        </button>
                                    </Tooltip>
                                    <span>{ch.label}</span>
                                </div>

                            </th>
                        )
                    })}
                </tr>
            </thead>
            <tbody>
                {
                    financialStatement.lineItems.map((li, index) => {
                        const lineItemRowId = getLineItemRowId(li.id);

                        const account = li.detailAccountReference ? accountLookup.get(li.detailAccountReference.accountId) : undefined;

                        const captionIndentLevel = li.absoluteIndentationLevel;
                        const captionCellStyle: React.CSSProperties = {
                            'paddingLeft': `${getIndent(li)}px`
                        }

                        const captionFormatting: FSModels.CaptionFormatting = captionStyleLookup.get(li.captionStyleClasses[0]) ?? {};

                        const lineItemData = index < dataViewModel.rowData.length ? dataViewModel.rowData[index].dataCells : [];
                        
                        let monetaryDataFormatting: FSModels.NumericStyleClass | undefined = monetaryDataStyleLookup.get(li.monetaryDataStyleClasses[0]);

                        let cellClass = '';

                        if (monetaryDataFormatting?.bold ?? false) {
                            cellClass += ' bolded';
                        }

                        if (monetaryDataFormatting?.italic ?? false) {
                            cellClass += ' italic'
                        }

                        switch (monetaryDataFormatting?.underlineStyle) {
                            case FSModels.UnderlineStyle.Single:
                                cellClass += ' single-underline';
                                break;

                            case FSModels.UnderlineStyle.Double:
                                cellClass += ' double-underline';
                                break;
                        }

                        const selectedRow = (selectedLineItems && selectedLineItems.get(li.id));

                        return (
                            <tr
                                key={`${statementType}-lineitem-account-${index}`}
                                className={`no-editor${(!!lockedLineItem && lockedLineItem.id === li.id) ? ' locked-line-item' : ''}`}
                                id={lineItemRowId}
                            >
                                <td
                                    key={`${statementType}-accountno-${index}`}
                                    className={`regular bolded col-line-item-account-no fixed-header${!!selectedRow?.highlighted ? ' is-selected' : ''}`}
                                >
                                    <div className={'left-border'}>
                                        {account?.accountNo}
                                    </div>
                                </td>
                                <td
                                    key={`${statementType}-caption-options-${index}`}
                                    className={`regular bolded col-line-item-caption-options fixed-header${!!selectedRow?.highlighted ? ' is-selected' : ''}`}
                                >
                                    <div className={'left-border'}>
                                        <LineItemCaptionOptions
                                            lineItem={li}
                                            indentLevel={captionIndentLevel}
                                            onIndentChange={(newIndentLevel) => {
                                                onUpdateIndent(li, newIndentLevel);
                                            }}
                                            onInfo={() => {
                                                onShowInfo(li);
                                            }}
                                            onMultiSelect={(selected) => {
                                                onMultiSelect(li, selected);
                                            }}
                                            onHoverMultiSelect={(entering) => {
                                                onHoverMultiSelect(li, entering);
                                            }}
                                            selected={!!selectedRow?.selected}
                                        />
                                    </div>
                                </td>
                                <td
                                    key={`${statementType}-accountcaption-${index}`}
                                    className={`regular left col-line-item-caption fixed-header${!!selectedRow?.highlighted ? ' is-selected-bordered' : ''}`}
                                >
                                    <div
                                        style={captionCellStyle}
                                    >
                                        <FinancialStatementLineItemCaption
                                            lineItem={li}
                                            formatting={captionFormatting}
                                            stylesBelowEdit={props.stylesBelowEdit}
                                            onEditMe={onEditLineItem}
                                            statementType={statementType}
                                            financialStatement={financialStatement}
                                            renderData={renderData}
                                        />
                                    </div>
                                </td>
                                <td
                                    key={`${statementType}-lineitemactions-${index}`}
                                    className={`line-item-actions-container col-line-item-actions fixed-header${!!selectedRow?.highlighted ? ' is-selected-bordered' : ''}`}
                                >
                                    <div>
                                        <FinancialStatementLineItemActions
                                            lineItem={li}
                                            detailAccountDescriptor={accountLookup.get(li.detailAccountReference?.accountId ?? '')}
                                            onIncrementLineItemSortOrder={(li) => {
                                                setLineItemMoved(li);
                                                onIncrementLineItemSortOrder(li);
                                            }}
                                            onDecrementLineItemSortOrder={(li) => {
                                                setLineItemMoved(li);
                                                onDecrementLineItemSortOrder(li);
                                            }}
                                            onMoveLineItemToNewSection={onMoveLineItemToNewSection}
                                            onAddSubsection={onAddSubsection}
                                            onLineItemMoreOptions={onLineItemMoreOptions}
                                            showTooltips={!lockedLineItem}
                                            isMultiSelection={!!selectedRow && selectedRow.selected}
                                        />
                                    </div>
                                </td>
                                <td
                                    key={`${statementType}-line-item-data-props-${index}`}
                                    className={`col-line-item-props fixed-header`}
                                >
                                    <div className={'left-border'}>
                                        <LineItemProps
                                            editable={true}
                                            formulasById={renderData.formulasById}
                                            lineItem={li}
                                            onToggleReportedAs={onToggleReportedAs}
                                        />
                                    </div>
                                </td>
                                {
                                    renderValueColumns(lineItemData, index, cellClass, monetaryDataFormatting?.excelNumberFormat)
                                }
                            </tr>
                        )
                    })
                }
            </tbody>
        </Table>
    )
}