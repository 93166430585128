import * as React from 'react';
import { connect } from 'react-redux';

import {
    Col,
    Container,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Row
} from 'reactstrap'

import { NaicsCodeEntity, RiskCalcResponse } from '../../../Models/Api/strongbox.financialportal';
import { ErrorBanner } from '../../ErrorBanner/ErrorBanner';

import { ConfirmModal, ConfirmModalType } from '../../ConfirmModal/ConfirmModal';
import { GetRiskScore } from '../../../Services/FinRiskService';
import { NaicsAutoComplete } from '../../Naics/NaicsAutoComplete';

import { RiskCalcResults } from './RiskCalcResults';

import { SubmissionDetailHeader } from '../SubmissionDetailHeader';

import {
    actionCreators as UIStateActions,
    GetPortalBusyState
} from '../../../Store/UIState';
import { ApplicationState } from '../../../Store';

const postalCodes = require('postal-codes-js');

type InjectedReduxState = {
    portalBusy: boolean;
};

type InjectedActionCreators = typeof UIStateActions;

/**
 * @member {postalCodeCountryCodes} array of country codes to check against for validity of postal codes.
 * Can be ISO 3166-1 alpha-2 and or ISO 3166-1 alpha-3 values.  Defaults to 'USA' and 'CAN'
 * */
type RiskAnalysisProps = {
    businessName: string;
    entityId: string;
    financialRecordId: string;
    postalCodeCountryCodes?: string[];
};

type Props = RiskAnalysisProps & InjectedReduxState & InjectedActionCreators;

type FormInput = {
    naicsCode?: NaicsCodeEntity;
    postalCode: string;
    postalCodeInvalid: boolean;
    postalCodeValid: boolean;
    formKey: number;
}

type ErrorInformation = {
    mainMessage: string;
    extraInformation?: string;
}

const RiskAnalysisComponent: React.FC<Props> = ({
    businessName,
    entityId,
    financialRecordId,
    postalCodeCountryCodes = ['USA', 'CAN'],
    portalBusy,
    SetPortalIdle,
    SetPortalWorking
}): React.ReactElement => {
    const [riskAnalysis, setRiskAnalysis] = React.useState<RiskCalcResponse | undefined>(undefined);
    const [formData, setFormData] = React.useState<FormInput>({
        formKey: 1,
        postalCode: '',
        postalCodeInvalid: false,
        postalCodeValid: false
    });
    const [rawNaicsCode, setRawNaicsCode] = React.useState<string>('');
    const [queryContinueWithoutNaics, setQueryContinueWithoutNaics] = React.useState<boolean>(false);
    const [errorInfo, setErrorInfo] = React.useState<ErrorInformation | undefined>(undefined);

    const defaultErrorDescription = `An error occurred retrieving the RiskCalc score. Please try again after re-importing financial information for this customer.`;

    /**
     * @param naicsCodeOverride if provided (not undefined) this will be the naics code passed down to the score generator
     */
    const getRiskScore = (financialRecordId: string, naicsCodeOverride?: string): void => {
        let useNaicsCode = naicsCodeOverride;

        if (!useNaicsCode) {
            useNaicsCode = !!(formData.naicsCode && formData.naicsCode.NaicsCode) ? formData.naicsCode.NaicsCode.toString() : undefined;
        }

        GetRiskScore(
            entityId,
            financialRecordId,
            useNaicsCode,
            formData.postalCodeValid ? formData.postalCode : undefined
        )
            .then(result => {
                if (!!result) {
                    if ((!!result.requestOutcome) && (result.requestOutcome.toLowerCase() === 'success')) {
                        setRiskAnalysis(result);
                        if (result.peerScore === null) {
                            setErrorInfo({
                                mainMessage: 'Unable to compare against peer companies.',
                                extraInformation: 'The NAICS code and/or Postal code supplied above are probably not valid.',
                            });
                        }
                    } else {
                        setErrorInfo({
                            mainMessage: !!result.errorDescription ? result.errorDescription : defaultErrorDescription,
                        });
                        setRiskAnalysis(undefined);
                    }
                }
            })
            .catch(exception => {
            })
            .finally(() => {
                SetPortalIdle('RiskAnalysis:getRiskScore');
            })
        SetPortalWorking('RiskAnalysis:getRiskScore');
    }

    /**
     * Use parameters entered by user to generate a risk calc analysis for this business.
     * If the user has selected a naics code from the popup that shows when they type a partial search in
     * that generates matches, it will use that code.  If there is content in the edit field and it has the
     * format of a valid code, i.e. 6 numeric characters, that naics code will be used.
     * 
     * If none of the above is true and if queryContinueWithoutNaics is false, meaning the query to continue 
     * without a naics code question is not showing, we set that to true and return.  That will load a
     * modal asking the user if they want to continue without a naics code. If they respond affirmatively to that
     * we'll go generate the score.
     * */
    const submitBusinessForAnalysis = (): void => {
        let rawNaicsCodeOverride: string | undefined = undefined;

        if (!formData.naicsCode) {
            if (rawNaicsCodeValid(rawNaicsCode)) {
                rawNaicsCodeOverride = rawNaicsCode;
            } else if (!queryContinueWithoutNaics) {
                setQueryContinueWithoutNaics(true);
                return;
            }
        }

        getRiskScore(financialRecordId, rawNaicsCodeOverride);
    }

    const rawNaicsCodeValid = (naicsCode: string): boolean => {
        // Really checking if it's empty here.
        if (!naicsCode) {
            return false;
        }

        // regex for matching a string containing exactly 6 numeric characters.

        const naicsRegEx = /^\d{6}$/;
        const result = naicsCode.match(naicsRegEx) !== null;

        return result;
    }

    return (
        <Container fluid>
            <SubmissionDetailHeader
                businessName={businessName}
                pageHeaderPrefix={'Risk analysis'}
            />
            <Row>
                <Col>
                    <Form key={formData.formKey.toString()} style={{paddingTop: '2.5em'}}>
                        <Row>
                            <Col>
                                <p>To get the most accurate risk score possible, you should enter a 6-digit numeric NAICS code and a postal code below. If you happen
                                    to know the correct NAICS code you may enter it directly in the edit field. You may also enter a partial description of the business to
                                    see a list of matching NAICS code descriptions from which you may select.</p>
                            </Col>
                        </Row>
                        <FormGroup row className={'naics-form-group'}>
                            <Col xs={6}>
                                <Row>
                                    <Col>
                                        <Label for={'naicsInput'}>{`Selected code: ${!!formData.naicsCode ? formData.naicsCode.NaicsCode : 'No code entered'}`}</Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <NaicsAutoComplete
                                            id={'naicsInput'}
                                            onCodeSelected={(code: NaicsCodeEntity) => {
                                                setFormData({
                                                    ...formData,
                                                    formKey: formData.formKey + 1,
                                                    naicsCode: code
                                                });
                                            }}
                                            onEditContentChanged={(content) => {
                                                setRawNaicsCode(content);
                                                rawNaicsCodeValid(content);
                                            }}
                                            editContent={rawNaicsCode}
                                            selectedNaicsCode={formData.naicsCode}
                                            disabled={!!errorInfo || portalBusy}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={6}>
                                <Row>
                                    <Col>
                                        <Label for={'PostalCode'}>Postal code:</Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{ position: 'relative' }}>
                                        <Input
                                            disabled={!!errorInfo || portalBusy}
                                            invalid={formData.postalCodeInvalid}
                                            valid={formData.postalCodeValid}
                                            type={'text'}
                                            id={'PostalCode'}
                                            value={formData.postalCode}
                                            onChange={(e) => {
                                                const pc = e.currentTarget.value;
                                                let formKey = formData.formKey;

                                                let valid = false;
                                                let invalid = false;
                                                if (!!pc) {
                                                    if (postalCodeCountryCodes.find(cc => (postalCodes.validate(cc, pc) === true))) {
                                                        valid = true;
                                                        formKey += 1;
                                                    } else {
                                                        invalid = true;
                                                    }
                                                }
                                                setFormData({
                                                    ...formData,
                                                    formKey,
                                                    postalCode: e.currentTarget.value,
                                                    postalCodeValid: valid,
                                                    postalCodeInvalid: invalid
                                                })
                                            }}
                                        />
                                        <FormFeedback tooltip>Invalid postal code</FormFeedback>
                                    </Col>
                                </Row>
                            </Col>
                        </FormGroup>
                        <Row style={{ marginTop: '1.5em' }}>
                            <Col>
                                <p>We will collect the rest of the information for you.</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {!portalBusy && (
                                    <button
                                        className={'small'}
                                        onClick={submitBusinessForAnalysis}
                                        disabled={!!errorInfo || formData.postalCodeInvalid}
                                    >
                                        {`${!riskAnalysis ? 'Analyze' : 'Re-analyze'} ${businessName}`}
                                    </button>
                                )}
                                {portalBusy && (
                                    <h2>Analyzing...</h2>
                                )}
                            </Col>
                        </Row>
                        </Form>
                    {errorInfo && (
                        <Row style={{ marginTop: '3em' }}>
                            <Col>
                                <ErrorBanner
                                    errorState={{
                                        summaryMessage: errorInfo.mainMessage,
                                        severity: 'Error',
                                        extraInformation: errorInfo.extraInformation !== undefined ? errorInfo.extraInformation : 'You may need to re-link financial information for this company or use a different submission',
                                    }}
                                    onDefaultActionButton={() => {
                                        setErrorInfo(undefined);
                                    }}
                                />
                            </Col>
                        </Row>
                    )}
                    {!!riskAnalysis && (
                        <Row style={{ marginTop: '1.5em' }}>
                            <Col>
                                <RiskCalcResults
                                    riskCalcResponse={riskAnalysis}
                                />
                            </Col>
                        </Row>
                    )}
                    {
                        queryContinueWithoutNaics && (
                            <ConfirmModal
                                msg={'You have not entered a NAICS code. Do you wish to continue?'}
                                onTerminalButton={(yes?: boolean) => {
                                    setQueryContinueWithoutNaics(false);
                                    if (!!yes) {
                                        getRiskScore(financialRecordId);
                                    }
                                }}
                                modalType={ConfirmModalType.yesno}
                            />
                        )
                    }
                </Col>
            </Row>
        </Container>
    )
}

export const RiskAnalysis = connect<InjectedReduxState, InjectedActionCreators, RiskAnalysisProps, ApplicationState>(
    (appState: ApplicationState) => {
        return {
            portalBusy: GetPortalBusyState(appState)
        }
    },
    {
        ...UIStateActions
    }
)(RiskAnalysisComponent);
