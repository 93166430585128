/**
 * Exports a component for showing the inputs that were used to create a risk calc score.
 * Those inputs are actually collected during the process of generating the score so this 
 * component is useful AFTER the score has been generated as opposed to what might seem
 * to make sense which would be collecting information to generate the score.
 */

import * as React from 'react';

import './RiskAnalysis.scss';

import {
    Table
} from 'reactstrap'

import { RiskCalcResponse } from '../../../Models/Api/strongbox.financialportal';

type Props = {
    riskCalcResponse: RiskCalcResponse;
    currencySymbol?: string;
};

type TableValue = {
    title: string;
    content: string[];
};

export const RiskCalcInputs: React.FC<Props> = ({
    riskCalcResponse,
    currencySymbol = '$'
}): React.ReactElement => {
    const [graphKey, setGraphKey] = React.useState<number>(1);
    const [tableContent, setTableContent] = React.useState<TableValue []>([]);

    React.useEffect(() => {
        const getValueContent = (value: number | undefined): string => {
            if (value === undefined) {
                return 'Unspecified';
            }
            return currencySymbol + new Intl.NumberFormat('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value)
        }

        const newValues: TableValue[] = [];

        if (!!riskCalcResponse.inputs) {
            newValues.push({
                title: 'Cash and equivalents',
                content: ['n/a', getValueContent(riskCalcResponse.inputs.cashAndMarketableSecurities)]
            });
            newValues.push({
                title: 'Accounts receivable',
                content: [getValueContent(riskCalcResponse.inputs.prevAccountsReceivable), getValueContent(riskCalcResponse.inputs.accountsReceivable)]
            });
            newValues.push({
                title: 'Inventory',
                content: [getValueContent(riskCalcResponse.inputs.prevInventories), getValueContent(riskCalcResponse.inputs.inventories)]
            });
            newValues.push({
                title: 'Total assets',
                content: [getValueContent(riskCalcResponse.inputs.prevTotalAssets), getValueContent(riskCalcResponse.inputs.totalAssets)]
            });
            newValues.push({
                title: 'Accounts payable',
                content: [getValueContent(riskCalcResponse.inputs.prevAccountsPayable), getValueContent(riskCalcResponse.inputs.accountsPayable)]
            });
            newValues.push({
                title: 'Total current liabilities',
                content: ['n/a', getValueContent(riskCalcResponse.inputs.currentLiabilities)]
            });
            newValues.push({
                title: 'Total long term debt',
                content: ['n/a', getValueContent(riskCalcResponse.inputs.totalLongTermDebt)]
            });
            newValues.push({
                title: 'Total liabilities',
                content: ['n/a', getValueContent(riskCalcResponse.inputs.totalLiabilities)]
            });
            newValues.push({
                title: 'Retained earnings',
                content: ['n/a', getValueContent(riskCalcResponse.inputs.retainedEarnings)]
            });
            newValues.push({
                title: 'Net sales',
                content: [getValueContent(riskCalcResponse.inputs.prevNetSales), getValueContent(riskCalcResponse.inputs.netSales)]
            });
            newValues.push({
                title: 'DD&A expenses',
                content: ['n/a', getValueContent(riskCalcResponse.inputs.amortizationAndDepreciation)]
            });
            newValues.push({
                title: 'Operating profit',
                content: ['n/a', getValueContent(riskCalcResponse.inputs.operatingProfit)]
            });
            newValues.push({
                title: 'Net income',
                content: [getValueContent(riskCalcResponse.inputs.prevNetIncome), getValueContent(riskCalcResponse.inputs.netIncome)]
            });
        }
        setTableContent(newValues);
        setGraphKey(graphKey + 1);
        // Compiler will complain about graphKey as a dependency, but I absolutely don't want to 
        // have graphKey as a trigger for this effect, see the line above.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currencySymbol, riskCalcResponse]);

    if (!riskCalcResponse.inputs) {
        return (<></>);
    }

    return (
        <div key={graphKey} className={'fin-risk-result-component'}>
            <Table key={`${graphKey}-table`} className={'std-number-field'}>
                <thead key={`${graphKey}-table-header`} >
                    <tr>
                        <th key={'valueTitleKey'}>Input parameter</th>
                        <th key={'rcsy'}>FY {riskCalcResponse.inputs.statementYear - 1}</th>
                        <th key={'scspy'}>FY {riskCalcResponse.inputs.statementYear}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        tableContent.map((tv, index) => {
                            return (
                                <tr key={`${tv.title}-${index}`}>
                                    <td>{tv.title}</td>
                                    {tv.content.map((c, contentIndex) => (<td key={`${tv.title}-content-${contentIndex}`}>{c}</td>))}
                                </tr>
                            );
                        })
                    }
                </tbody>
            </Table>
        </div>
    );
}
