import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';

import {
    Row,
    Col,
} from 'reactstrap';

import { ApplicationState } from '../../Store';
import { pathConstants } from '../../Utils/Constants';

import { FileSelectUpload } from '../FileSelectUpload/FileSelectUpload';

import { BorrowerPortalChildProps } from './BorrowerPortal';

type InjectedReduxState = {
};

type InjectedActionCreators = {
};

type Props = BorrowerPortalChildProps & InjectedActionCreators & InjectedReduxState;

type State = {
    redirectHome: boolean;
};

export const DocumentsComponent: React.FC<Props> = (props): React.ReactElement => {
    const [redirectHome, setRedirectHome] = React.useState<boolean>(false);

    if ((redirectHome) || (!props.borrowerId) || (props.borrowerId === '')) {
        return (<Navigate to={pathConstants.linked} />);
    }

    return (
        <>
            <Row>
                <Col>
                    <h1>Upload documents</h1>
                </Col>
            </Row>
            <Row>
                <Col className={'file-upload-container'}>
                    <FileSelectUpload
                        dark
                        submissionId={props.submissionId}
                        workspaceId={props.borrowerId}
                        onAllDone={() => {
                            setRedirectHome(true);
                        }}
                    />
                </Col>
            </Row>
        </>
    );
}

export const Documents = connect<InjectedReduxState, InjectedActionCreators, BorrowerPortalChildProps, ApplicationState>(
    (appState: ApplicationState) => {
        return {}
    }
)(DocumentsComponent);
