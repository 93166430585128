import '../Main.scss';

import * as React from 'react';

import { DropDownCaret } from '../DropDownCaret/DropDownCaret';
import { DownloadableFile } from '../../Utils/FileUtils';

import {
    Col,
    Container,
    Row,
    Input
} from 'reactstrap';

const summaryRowKey = 'summary_row';

export type SelectableDownloadFile = {
    file: DownloadableFile;
    selected: boolean;
    id: number;
}

type Props = {
    style?: any;
    title?: string
    summaryDescription?: React.ReactElement;
    files?: SelectableDownloadFile[];
    fileDescriptor: string;
    retrievingFiles: boolean;
    noFilesAvailableMsg: string;
    onSelection?: (file: SelectableDownloadFile, index: number) => void;
    onExpandSummary?: () => void;
    onContractSummary?: () => void;
    hideBorder?: boolean;
    expanded: boolean;
    highlightTitleContent?: string;
    actionButton?: {
        prompt: string;
        onClick: () => void;
    }
}

export const GroupedFileDropdown: React.FC<Props> = (props): React.ReactElement => {
    const [keyPrefix] = React.useState<string>((Math.random() * 10000).toString());

    let basicClassName = `workbook-grouping-container`;
    if (props.hideBorder === true) {
        basicClassName += ' workbook-grouping-container-borderless';
    }

    const RenderTitleRow = (): React.ReactElement | undefined => {
        if (!props.title) {
            return undefined;
        }
        return (
            <Row key={`${keyPrefix}-title_row`} style={{ marginBottom: '20px' }}>
                <Col><h1>{props.title}</h1></Col>
                {
                    !!props.highlightTitleContent && (
                        <Col>
                            <span className={'highlight-content'}>{props.highlightTitleContent}</span>
                        </Col>
                    )
                }
            </Row>
        );
    }

    const RenderSummaryRow = (): React.ReactElement => {
        // Sanity check, shouldn't be possible.
        if (!props.summaryDescription) {
            return (<></>);
        }

        const expandButton = !!props.summaryDescription && (
            <DropDownCaret
                expanded={props.expanded}
                contractDescriptor={`additional ${props.fileDescriptor}`}
                expandDescriptor={`all ${props.fileDescriptor}`}
                clicked={() => {
                    if (props.expanded) {
                        props.onContractSummary && props.onContractSummary();
                    } else {
                        props.onExpandSummary && props.onExpandSummary();
                    }
                }}
            />
        )

        return (
            <Row key={`${keyPrefix}-${summaryRowKey}`} className={'summary-row'}>
                <Col xs={'auto'}>
                    {expandButton}
                </Col>
                <Col
                    md={9}
                    xs={7}
                    className={'spaced-row'}
                >
                    {props.summaryDescription}
                </Col>
            </Row>
        );
    }

    const RenderRow = (file: SelectableDownloadFile, fileIndex: number, className?: string): React.ReactElement => {
        if (file.file.separator === true) {
            return (
                <Row
                    style={{
                        marginTop: '10px',
                        marginBottom: '5px'
                    }}
                    key={`${keyPrefix}-${fileIndex.toString()}`}
                    className={className}
                >
                    <Col md={9} xs={7}><span className={'underline'}>{`${file.file.filename}`}</span></Col>
                </Row>
            );
        } else {
            return (
                <Row key={`${keyPrefix}-${fileIndex.toString()}`} className={className}>
                    <Col xs={'auto'}>
                        <Input
                            id={file.id.toString()}
                            disabled={file.file.isDeleted}
                            name={file.id.toString()}
                            type={'checkbox'}
                            checked={file.selected}
                            onChange={() => {
                                const newFile = {
                                    ...file,
                                    selected: !file.selected,
                                }
                                props.onSelection && props.onSelection(newFile, fileIndex);
                            }}
                        />
                    </Col>
                    <Col md={8} xs={6}><h2>{`${file.file.filename}${file.file.isDeleted ? ' (deleted due to retention policy)' : ''}`}</h2></Col>
                    <Col md={3} xs={4}>
                        <Row>
                            <Col className={'right-aligned-col'}>
                                {`${file.file.fileSize ? (file.file.fileSize / 1024).toLocaleString(undefined, { maximumFractionDigits: 0 }) : '-'}`}
                            </Col>
                            <Col xs={'1'}>
                                KB
                            </Col>
                        </Row>
                    </Col>
                </Row>
            );
        }
    }

    const RenderActionButton = (): React.ReactElement => {
        if (!(props.actionButton && props.files)) {
            return (<></>);
        }

        const hasSelections = !!props.files.find(file => file.selected);

        return (
            <Row key={`${keyPrefix}-action-prompt`}>
                <Col className={'right-aligned-col extra-row-spacing-small'}>
                    <button
                        className={'small'}
                        disabled={!hasSelections}
                        onClick={() => props.actionButton!.onClick()}
                    >
                        {props.actionButton.prompt}
                    </button>
                </Col>
            </Row>
        );
    }

    const RenderContracted = (): React.ReactElement => {
        // Shouldn't be possible.
        if (!props.summaryDescription) {
            return (<></>);
        }

        return (
            <Container fluid style={props.style} className={basicClassName}>
                { RenderTitleRow()}
                { RenderSummaryRow() }
            </Container>
        );
    }

    const RenderExpanded = (): React.ReactElement => {
        // If we're rendering expanded and the owners of this are not in the process of going
        // out and retrieving files and props.files is undefined or has a length of 0 then 
        // no files were found.  Show an error message
        if ((!props.retrievingFiles) && ((!props.files) || (props.files.length === 0))) {
            return (
                <Container fluid style={props.style} className={basicClassName}>
                    { RenderTitleRow()}
                    { RenderSummaryRow()}
                    { props.noFilesAvailableMsg}
                </Container>
            );
        } else {
            return (
                <Container fluid style={props.style} className={basicClassName}>
                    { RenderTitleRow() }
                    { RenderSummaryRow() }
                    { props.files && props.files.map((file, index) => RenderRow(file, index)) }
                    { RenderActionButton() }
                </Container>
            );
        }
    }

    if ((!!props.summaryDescription) && (!props.expanded)) {
        return RenderContracted();
    } else {
        return RenderExpanded();
    }
}
