import { ApplicationState } from '../index';

import { DetailedUserResponse, PostLoginUserResponse, UserCapability, UserResponse } from '../../Models/Api/strongbox.financialportal';
import { Auth0UserSettings } from './Reducer';
import { AdminUserRole } from '../../Utils/Constants';


// The back end doesn't explicitly define user types, it defines roles.  Everyone has a default role, only admins
// have the admin role.  For purposes of 'do this only for admins' this isolates the notion of what being an admin
// is and will make it easier if we ever change that.
export type StrongboxUserType = 'default' | 'admin';

export function LoadingUsers(state: ApplicationState): boolean {
    return !!state && !!state.userState && state.userState.loading;
}

export function GetUsers(state: ApplicationState): UserResponse[] {
    return (state && state.userState && state.userState.users) || ([]);
}

export function GetDetailedUsers(state: ApplicationState): DetailedUserResponse[] {
    return (state && state.userState && state.userState.detailedUsers) || ([]);
}

export function GetUserById(state: ApplicationState, id: string): UserResponse | undefined {
    if (!(state && state.userState && state.userState.users)) {
        return undefined;
    }
    return state.userState.users.find(user => user.id === id);
}

export function LoggedInUserHasAccess(state: ApplicationState, operation: UserCapability): boolean {
    if (!(state.userState && state.userState.loggedInUser && state.userState.loggedInUser.userCapabilities)) {
        return false;
    }

    return state.userState.loggedInUser.userCapabilities.findIndex(cap => cap === operation) !== -1;
}

export function GetLoggedInUser(state: ApplicationState): PostLoginUserResponse | undefined {
    return state && state.userState && state.userState.loggedInUser;
}

export function GetLoggedInUserExternalId(state: ApplicationState): string {
    return (state && state.userState && state.userState.loggedInUser && state.userState.loggedInUser.externalAccountId) || '';
}

export function GetAuth0Configuration(state: ApplicationState): Auth0UserSettings | undefined {
    return state && state.userState && state.userState.auth0;
}

export function GetAuth0Token(state: ApplicationState): string | undefined {
    return state && state.userState && state.userState.auth0Token;
}

export function GetAuth0LoggingOut(state: ApplicationState): boolean {
    return state && state.userState && state.userState.auth0LoggingOut;
}

export function GetAuth0Id(state: ApplicationState): string {
    return (state && state.userState && state.userState.auth0Id) || '';
}

export function GetMaxSessionLifetimeMS(state: ApplicationState): number {
    if (!(state && state.userState)) {
        return 0;
    }
    return state.userState.maxSessionLifetimeMS;
}

export function GetLoggedInUserType(state: ApplicationState): StrongboxUserType {
    if (!(state && state.userState && state.userState.loggedInUser)) {
        return 'default';
    }

    // All users have the default role.  So we look for presence or absence of admin.  If it's there, it's an admin
    // Not there, it's a default user.

    return !!state.userState.loggedInUser.resourceRoles.find(role =>
        (role.resourceType.toLowerCase() === 'tenants') && (role.roleId.toLowerCase() === AdminUserRole.toLowerCase())
    ) ? 'admin' : 'default';
}
