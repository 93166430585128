import * as React from 'react';
import { connect } from 'react-redux';

import { PortalHeader } from '../PortalHeader';

import CheckCircle from '@mui/icons-material/CheckCircle';
import QuestionCircle from '@mui/icons-material/Help';
import { Theme, ThemeProvider } from '@mui/material/styles';
import { BrandConfig, GetBrandConfig } from '../../Store/Tenant';

import { CreateStdTheme } from '../../Utils/Style';

import {
    ResourceName,
    RetrieveResource,
    TenantParagraphSet,
} from '../../Store/Tenant';
import { ApplicationState } from '../../Store';
import { useSearchParams } from 'react-router-dom';

type InjectedReduxState = {
    confirmationText: TenantParagraphSet;
    brandConfig: BrandConfig;
};

type InjectedActionCreators = {
};

type EmailConfirmProps = {
    delayConfirm?: boolean
};

type Props = EmailConfirmProps & InjectedActionCreators & InjectedReduxState;

const EmailConfirmComponent: React.FC<Props> = (props): React.ReactElement => {
    const {
        confirmationText,
        delayConfirm
    } = props;

    const [searchParams] = useSearchParams();

    React.useEffect(() => {
        if (!!delayConfirm) {
            let submissionId = searchParams.get('submissionId');
            let confirmUrl = `/submissions/${submissionId}/confirm`;

            //navigate(confirmUrl, {replace: true});
            const idTimer = setTimeout(() => { window.location.replace(confirmUrl); }, 2000);

            return () => clearTimeout(idTimer);
        }

        // I want this to execute equivalent to componentDidMount so this is appropriate
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [muiTheme] = React.useState<Theme>(() => {
        return CreateStdTheme(props.brandConfig);
    });

    const imgForDisplay = React.useMemo(() => {
        const iconStyle: React.CSSProperties = {
            height: '100px',
            width: '100px'
        }

        return !!delayConfirm ?
            (
                <QuestionCircle color={'primary'} style={iconStyle} />
            ) :
            (
                <CheckCircle color={'primary'} style={iconStyle} />
            );
    }, [delayConfirm])

    const renderParagraphs = (tenantParagraphs: TenantParagraphSet): React.ReactNode => {
        const paragraphs: React.ReactNode[] = [];
        let first = true;

        if (tenantParagraphs && tenantParagraphs.paragraphs) {
            tenantParagraphs.paragraphs.forEach(paragraph => {
                if (first) {
                    paragraphs.push((
                        <div style={{ marginTop: '40px' }}>
                            <h1>{paragraph}</h1>
                        </div>
                    ));
                } else {
                    paragraphs.push((
                        <div style={{ marginTop: '20px' }}>
                            <h3>{paragraph}</h3>
                        </div>
                    ));
                }
                first = false;
            });
        }

        return (
            <>
                {paragraphs}
            </>
        );
    }

    return (
        <div>
            <PortalHeader hideMenu={true} />
            <ThemeProvider theme={muiTheme}>
                <div className={`full-screen-region borrower-region content-region`}>
                    <div>
                        {imgForDisplay}
                        {renderParagraphs(confirmationText)}
                    </div>
                </div>
            </ThemeProvider>
        </div>
    );
}

export const EmailConfirm = connect<InjectedReduxState, InjectedActionCreators, EmailConfirmProps, ApplicationState>(
    (appState: ApplicationState, props: EmailConfirmProps) => {
        const result = {
            confirmationText: RetrieveResource(appState, !!props.delayConfirm ? ResourceName.tenantConfirmingText : ResourceName.tenantConfirmationText),
            brandConfig: GetBrandConfig(appState),
        };

        return result;
    },

)(EmailConfirmComponent);
