import * as React from 'react';
import {
    Col,
    Row,
    Input,
    FormFeedback
} from 'reactstrap';

import { maxFirstOrLastNameLength, maxEmailAddressLength } from '../../../Utils/Constants';

export type BasicEditableUserDetails = {
    firstName?: string;
    lastName?: string;
    emailAddress: string;
};

export type BasicUserErrorMessages = {
    firstName?: string;
    lastName?: string;
    emailAddress?: string;
}

type Props = {
    errorMessages?: BasicUserErrorMessages;
    user: BasicEditableUserDetails;
    onChange: (newValue: BasicEditableUserDetails) => void;
    stackLabels?: boolean;
    focusRef?: React.MutableRefObject<any>;
    excludeEmail?: boolean;
    disableEdit?: boolean;
    emailDomainsForTenant?: string[];
    showEmailDomainError: boolean;
    emailValid: boolean;
}

export const BasicUserDetails: React.FC<Props> = (props): React.ReactElement => {
    const {
        disableEdit,
        emailDomainsForTenant,
        emailValid,
        showEmailDomainError,
        errorMessages,
        excludeEmail,
        focusRef,
        onChange,
        stackLabels,
        user,
    } = props;

    const emailErrorMessage = React.useMemo(() => {
        if (emailDomainsForTenant === undefined) {
            return '';
        }
        if (emailDomainsForTenant.length <= 0) {
            console.error('Unexpected: Supplied email domains in BasicUserDetails has no length');
            return '';
        }
        if (emailDomainsForTenant.length === 1) {
            return `Email domain must be ${emailDomainsForTenant[0]}`;
        } else {
            let domains = '';

            emailDomainsForTenant.forEach((domain, domainIndex) => {
                if (domainIndex > 0) {
                    if (domainIndex === emailDomainsForTenant.length - 1) {
                        domains += ' or ';
                    } else {
                        domains += ', ';
                    }
                }
                domains += domain;
            });
            return `Email domain must be one of ${domains}`;
        }
    }, [emailDomainsForTenant]);

    const labelColumnWidth = stackLabels === true ? 12 : 3;
    const labelClass = stackLabels === true ? 'left-aligned-label' : 'right-aligned-label';

    return (
        <React.Fragment>
            <Row className={'std-form-row'}>
                <Col xs={labelColumnWidth} className={labelClass}>
                    <label htmlFor={'firstname'}>First Name:</label>
                </Col>
                <Col>
                    <Row>
                        <input
                            ref={focusRef}
                            style={{ width: '100%' }}
                            type={'text'}
                            id={'firstname'}
                            placeholder={'First name'}
                            value={user.firstName}
                            key={'basicUserDetailsFirstName'}
                            maxLength={maxFirstOrLastNameLength}
                            onChange={(event) => {
                                onChange({
                                    ...user,
                                    firstName: event.target.value
                                });
                            }}
                        />
                    </Row>
                    {
                        !!errorMessages && !!errorMessages.firstName && (
                            <Row>
                                <span className={'error-text'}>{errorMessages.firstName}</span>
                            </Row>
                        )
                    }
                </Col>
            </Row>
            <Row className={'std-form-row'}>
                <Col xs={labelColumnWidth} className={labelClass}>
                    <label htmlFor={'lastname'}>Last Name:</label>
                </Col>
                <Col>
                    <Row>
                        <input
                            style={{ width: '100%' }}
                            type={'text'}
                            id={'lastname'}
                            placeholder={'Last name'}
                            value={user.lastName}
                            key={'basicUserDetailsLastName'}
                            maxLength={maxFirstOrLastNameLength}
                            onChange={(event) => {
                                onChange({
                                    ...user,
                                    lastName: event.target.value
                                });
                            }}
                        />
                    </Row>
                    {
                        !!errorMessages && !!errorMessages.lastName && (
                            <Row>
                                <span className={'error-text'}>{errorMessages.lastName}</span>
                            </Row>
                        )
                    }
                </Col>
            </Row>
            {
                (excludeEmail !== true) && (
                    <Row className={'std-form-row'}>
                        <Col xs={labelColumnWidth} className={labelClass}>
                            <label htmlFor={'email'}>Email address:</label>
                        </Col>
                        <Col>
                            <Row style={{position: 'relative'}}>
                                <Input
                                    style={{ width: '100%' }}
                                    type={'email'}
                                    id={'email'}
                                    placeholder={'Email address'}
                                    value={user.emailAddress}
                                    disabled={disableEdit === true}
                                    key={'basicUserDetailsEmailAddress'}
                                    maxLength={maxEmailAddressLength}
                                    valid={emailValid}
                                    invalid={!emailValid}
                                    onChange={(event) => {
                                        onChange({
                                            ...user,
                                            emailAddress: event.target.value,
                                        });
                                    }}
                                />
                                { !!showEmailDomainError && (
                                    <FormFeedback tooltip>{emailErrorMessage}</FormFeedback>
                                )}
                            </Row>
                            {
                                !!errorMessages && !!errorMessages.emailAddress && (
                                    <Row>
                                        <span className={'error-text'}>{errorMessages.emailAddress}</span>
                                    </Row>
                                )
                            }
                        </Col>
                    </Row>
                )
            }
        </React.Fragment>
    )
}
