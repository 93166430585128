import { SegmentAnalytics } from './SegmentAnalytics';

export enum EventCategory {
    Import = 'Import',
    User = 'User',
    Workspace = 'Workspace',
}

export const MetricsService = {

    // identify the current user
    // 
    // For the portal, user should be the tenant and id should be the id of the borrower 
    // (entityId to which imported data would be linked under.) 

    identifyUser(user: string, id: string) {
        SegmentAnalytics.identifyUser(user, { name: user, id });
    },

    /**
     *  This method allows for an arbitrary JSON value to be passed in as value.  
     *  
     *  For Segment, the entirety of that value will be available in Segment.
     */

    tryTrackEvent(
        category: EventCategory,
        name: string,
        value?: any
    ) {
        try {
            SegmentAnalytics.track(`${category}_${name}`, value ? value : {});
        } catch (e) { /* no action will be logged. */ }
    },
};
