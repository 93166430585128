// To query production events, start at the Azure portal
// portal.azure.com.   Go to StrongboxFinancialDataPortalAIProdSCUS
//
// The following query will find all the message/exceptions that come from the
// client portal:
// union * | where customDimensions.loggingSource == "client-portal"

import * as React from 'react';

import { connect } from 'react-redux';

import { ApplicationState } from '../Store';
import { GetAppInsightsConfig } from '../Store/AppSettings';

import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { AppInsightsContext, ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

import { JSONConvertAllMembersToString } from './JSONUtils';

const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();
let appInsights: ApplicationInsights | undefined = undefined;
let globalTenantName: string = 'unset';

export { reactPlugin, appInsights };
export { SeverityLevel };

type InjectedReduxState = {
    appInsightsDisabled: boolean;
    appInsightsInstrumentationKey: string;
};

type InjectedActionCreators = {}

type LoggingWrapperProps = {
    children?: React.ReactNode;
};

type Props = InjectedReduxState & InjectedActionCreators & LoggingWrapperProps;

/**
 * Logs an exception 
 *
 * @param severityLevel if not provided default level is SeverityLevel.Error
 */

export function LogException(message: string, exception: any, content?: any, severityLevel?: SeverityLevel): void {
    if (!appInsights) {
        return;
    }
    try {
        if (!!content) {
            // trackException custom dimensions doesn't like numbers so convert
            // everything to string.  The last value passed to the 'reviver'
            // in parse has no key and is the entire result so you don't want
            // to run toString on that.
            if (!!content) {
                content = JSONConvertAllMembersToString(content);
            }
        }
        appInsights.trackException(
            {
                exception,
                severityLevel: severityLevel || SeverityLevel.Error,
            },
            {
                path: window.location.pathname,
                loggingSource: 'client-portal',
                message,
                tenantName: globalTenantName,
                ...content,
            }
        );
    } catch (internalException) {
        // Just to make 100% certain the logging code doesn't destroy something.

        console.error('fatal error logging exception');
        console.error(internalException);
    }
}

/**
 * Logs a message
 * @param severityLevel if not provided default level of SeverityLevel.Information is used
 */

export function LogMessage(message: string, severityLevel?: SeverityLevel, content?: any): void {
    if (!appInsights) {
        return;
    }

    try {
        if (!!content) {
            // trackTrace custom dimensions doesn't like numbers so convert
            // everything to string.  The last value passed to the 'reviver'
            // in parse has no key and is the entire result so you don't want
            // to run toString on that.
            content = JSONConvertAllMembersToString(content);
        }
        appInsights.trackTrace(
            {
                message,
                severityLevel: severityLevel || SeverityLevel.Information
            },
            {
                path: window.location.pathname,
                loggingSource: 'client-portal',
                tenantName: globalTenantName,
                ...content,
            }
        );
    } catch (internalException) {
        // Just to make 100% certain the logging code doesn't destroy something.

        console.error('fatal error logging message');
        console.error(internalException);
    }
}

export function InitializeAppInsights(disabled: boolean, instrumentationKey: string, tenantName: string): void {
    if (!disabled) {
        appInsights = new ApplicationInsights({
            config: {
                instrumentationKey,
                extensions: [reactPlugin],
                extensionConfig: {
                    [reactPlugin.identifier]: { history: browserHistory }
                }
            } 
        });
        appInsights.loadAppInsights();
        globalTenantName = tenantName;
    }
}

const LoggingWrapperComponent: React.FC<Props> = (props): React.ReactElement => {
    const {
        appInsightsDisabled
    } = props;

    if (appInsightsDisabled) {
        return (<React.Fragment>{props.children}</React.Fragment>);
    } else {
        return (
            <AppInsightsContext.Provider value={reactPlugin}>
                {props.children}
            </AppInsightsContext.Provider>
        );
    }
}

export const LoggingWrapper = connect<InjectedReduxState, InjectedActionCreators, LoggingWrapperProps, ApplicationState>(
    (appState: ApplicationState) => {
        const appInsights = GetAppInsightsConfig(appState);

        return {
            appInsightsDisabled: appInsights.disabled,
            appInsightsInstrumentationKey: appInsights.instrumentationKey,
        };
    }
)(LoggingWrapperComponent);

