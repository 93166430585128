import * as React from 'react';

import '../../Main.scss';

import {
    Col,
    Row,
} from 'reactstrap';

import Toggle from 'react-toggle';
import 'react-toggle/style.css';

import { UserNotificationTypes } from '../../../Store/UserSettings';
import {
    NotificationMethod,
    UserNotificationMethodSubscriptionStyle,
    SubscriptionStyle
} from '../../../Models/Api/strongbox.financialportal';

import {
    NotificationStyleDisabled,
    NotificationStyleOptOut
} from '../../../Utils/Constants';

type Props = {
    containerKey?: string;
    notificationTitle: string;
    notificationDescription: string;
    notificationTypes: UserNotificationTypes[];
    notificationSettings: UserNotificationMethodSubscriptionStyle[];
    style?: any;
    onNotificationTypeChange?: (notificationType: NotificationMethod, newState: boolean) => void;
    containerClass?: string;
}

export const WorkspaceNotification: React.FC<Props> = (props): React.ReactElement => {
    const {
        containerClass,
        containerKey,
        notificationDescription,
        notificationSettings,
        notificationTitle,
        notificationTypes,
        onNotificationTypeChange,
        style,
    } = props;

    const getNotificationSetting = (notifyType: UserNotificationTypes, settings: UserNotificationMethodSubscriptionStyle[]): boolean => {
        const setting = settings.find(set => set.type === notifyType.type);
        return !!setting && setting.style === NotificationStyleOptOut;
    };

    const getNotificationStyle = (notifyType: UserNotificationTypes, settings: UserNotificationMethodSubscriptionStyle[]): SubscriptionStyle => {
        const setting = settings.find(set => set.type === notifyType.type);
        return (!!setting && !!setting.style) ? setting.style : NotificationStyleDisabled;
    }

    return (
        <Row key={containerKey} style={style} className={containerClass || ''}>
            <Col xs={'12'} lg={'7'} xl={'5'} style={{ marginBottom: '20px' }}>
                <h1>{notificationTitle}</h1>
                <span style={{ marginTop: '15px' }}>{notificationDescription}</span>
            </Col>
            <Col>
                {
                    notificationTypes.map((notify, index) => {
                        let isDisabled = false;

                        if (getNotificationStyle(notify, notificationSettings) === NotificationStyleDisabled) {
                            isDisabled = true;
                        }

                        return (
                            <Row key={`notificationTypeMap${notify.type}${index}`}>
                                {
                                    // If it's disabled it's for one of 2 reasons
                                    //
                                    // It's been disabled in the database. There's no UI for setting that,
                                    // it's been done manually in the database for whatever reason.  That's unusual
                                    //
                                    // In the Administrative settings, there are additional emails to receive
                                    // notifications and it's been set to replace the default list with those emails
                                    // instead of appending it to default email list.

                                    isDisabled && (
                                        <React.Fragment>
                                            <span className={'emphasis-text'}>Disabled For Portal</span>
                                            <span className={'extra-small'}>Check Administrative Settings for default notification recipients being replaced</span>
                                        </React.Fragment>
                                    )
                                }
                                {
                                    !isDisabled && (
                                        <React.Fragment>
                                            <span style={{ width: 'fit-content' }}>Off</span>
                                            <Toggle
                                                className={'custom-toggle'}
                                                checked={getNotificationSetting(notify, notificationSettings)}
                                                icons={false}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    onNotificationTypeChange && onNotificationTypeChange(notify.type, event.target.checked);
                                                }}
                                                disabled={isDisabled}
                                                style={{ width: 'fit-content' }}
                                            />
                                            <span style={{ width: 'fit-content' }}>On</span>
                                        </React.Fragment>
                                    )
                                }
                            </Row>
                        );
                    })
                }
            </Col>
        </Row>
    );
}
