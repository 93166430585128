import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    Container,
    Row,
    Col
} from 'reactstrap';

import { ApplicationState } from '../Store';
import {
    GetDynamicTenantSelectionSwitch,
    actionCreators as AppSettingActionCreators,
    GetRawAppSettingsState,
    IAppSettingsState,
} from '../Store/AppSettings';
import { LinkMode } from '../Store/ImportFinancials';

import { TenantActionCreators, GetTenantName, KnownTenants } from '../Store/Tenant';
import { pathConstants } from '../Utils/Constants';

import { BasisOfAccountingPreference } from '../Models/Api/strongbox.financialportal';
import { PortalCreateSubmissionRequestOptions } from '../Models/PortalCreateSubmissionRequestOptions';
import { AccountingCollectionGroup, knownAccountingGroupIds } from '../Models/AccountingCollectionGroup';
import { CollectFinancialsImportParameters, OnOffSettings } from './ImportCustomerFinancials/CollectFinancialsImportParameters';

import { TitleBox } from './TitleBox/TitleBox';
import { MostRecentDate } from '../Utils/MostRecentDate';

type InjectedReduxState = {
    tenantName: string;
    selectionEnabled: boolean;
    rawState?: IAppSettingsState;
};

type InjectedActionCreators = typeof TenantActionCreators & typeof AppSettingActionCreators;

type TenantSelectionProps = {
};

type Props = TenantSelectionProps & InjectedActionCreators & InjectedReduxState;

const TenantSelectionComponent: React.FC<Props> = (props): React.ReactElement => {
    const {
        selectionEnabled,
        SetActiveTenant,
        tenantName,
        rawState,
    } = props;

    const [redirect, setRedirect] = React.useState<boolean>(false);

    const [defaultImportSettings, setDefaultImportSettings] = React.useState<PortalCreateSubmissionRequestOptions | undefined>(undefined);
    const [defaultAnonymousImportSettings, setDefaultAnonymousImportSettings] = React.useState<PortalCreateSubmissionRequestOptions | undefined>(undefined);

    const [accountingGroupsVersion, setAccountingGroupsVersion] = React.useState<number>(1);

    React.useEffect(() => {
        if (!!rawState) {
            setDefaultImportSettings(rawState.defaultPortalSubmissionOptions)
            setDefaultAnonymousImportSettings(rawState.defaultShareableAnonymousSubmissionOptions);
        }

        // This is the dependency list I want, it should run once on load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!selectionEnabled) {
        return (<></>);
    }

    if (redirect) {
        return <Navigate to={pathConstants.login} />
    }

    const tenants = Object.keys(KnownTenants).filter(key => ((key !== KnownTenants.defaultAccountant) && (key !== KnownTenants.defaultLender)));

    const containingDivClass = `full-screen-region borrower-region content-region control-region control-region-lender`;

    const checkBoxSettings = (prompt: string, memberName: string, checkedState: boolean, changedState: Partial<IAppSettingsState>): React.ReactElement | undefined => {
        return (
            <Row
                style={{ marginTop: '5px' }}
                key={memberName}
            >
                <Col>
                    <input
                        type={'checkbox'}
                        id={memberName}
                        name={memberName}
                        checked={checkedState}
                        onChange={e => {
                            props.OverrideAppSettings(changedState);
                        }}
                    >
                    </input>
                    <label
                        htmlFor={memberName}
                        style={{
                            marginLeft: '15px'
                        }}
                    >
                        {prompt}
                    </label>
                </Col>
            </Row>
        );
    }

    const checkBoxSettingsManualChange = (prompt: string, memberName: string, checkedState: boolean, toggle: () => void): React.ReactElement | undefined => {
        return (
            <Row
                style={{ marginTop: '5px' }}
                key={memberName}
            >
                <Col>
                    <input
                        type={'checkbox'}
                        id={memberName}
                        name={memberName}
                        checked={checkedState}
                        onChange={e => toggle()}
                    >
                    </input>
                    <label
                        htmlFor={memberName}
                        style={{
                            marginLeft: '15px'
                        }}
                    >
                        {prompt}
                    </label>
                </Col>
            </Row>
        );
    }

    const listCheckSetting = (
        prompt: string,
        memberName: string,
        settings: string[],
        action: (member: string, checked: boolean) => void
    ): React.ReactElement | undefined => {
        const checked = !!settings.find(setting => setting.toLowerCase() === memberName.toLowerCase());

        return (
            <Row
                style={{ marginTop: '5px' }}
                key={`${memberName}`}
            >
                <Col>
                    <input
                        type={'checkbox'}
                        id={memberName}
                        name={memberName}
                        checked={checked}
                        onChange={e => {
                            action(memberName, !checked)
                        }}
                    >
                    </input>
                    <label
                        htmlFor={memberName}
                        style={{
                            marginLeft: '15px'
                        }}
                    >
                        {prompt}
                    </label>
                </Col>
            </Row>
        );
    }

    const accountingPackageList = (
        prompt: string,
        memberName: string,
        settings: string[],
        action: (memberName: string, checked: boolean) => void,
    ): React.ReactElement | undefined => {
        return (
            <Row
                style={{ marginTop: '5px' }}
                key={`${memberName}`}
            >
                <Col>
                    <TitleBox
                        title={prompt}
                        containerStyle={{
                            paddingLeft: '15px'
                        }}
                        titleStyle={{
                            fontSize: '16px',
                            fontWeight: 'normal',
                            backgroundColor: 'transparent',
                        }}
                    >
                        <React.Fragment>
                            {
                                listCheckSetting(
                                    'QuickBooks Online',
                                    'QuickBooksOnline',
                                    settings,
                                    action,
                                )
                            }
                            {
                                listCheckSetting(
                                    'QuickBooks Desktop',
                                    'QuickBooksDesktop',
                                    settings,
                                    action
                                )
                            }
                            {
                                listCheckSetting(
                                    'Sage Intacct',
                                    'SageIntacct',
                                    settings,
                                    action
                                )
                            }
                            {
                                listCheckSetting(
                                    'Xero',
                                    'Xero',
                                    settings,
                                    action
                                )
                            }
                            {
                                listCheckSetting(
                                    'NetSuite',
                                    'NetSuite',
                                    settings,
                                    action
                                )
                            }
                            {
                                listCheckSetting(
                                    'Microsoft Dynamics 365',
                                    'Dynamics365',
                                    settings,
                                    action
                                )
                            }
                            {
                                listCheckSetting(
                                    'DEX',
                                    'Example',
                                    settings,
                                    action
                                )
                            }
                            {
                                listCheckSetting(
                                    'MYOBBusiness',
                                    'MYOBBusiness',
                                    settings,
                                    action
                                )
                            }
                            {
                                listCheckSetting(
                                    'FreeAgent',
                                    'FreeAgent',
                                    settings,
                                    action
                                )
                            }
                            {
                                listCheckSetting(
                                    'Reckon',
                                    'Reckon',
                                    settings,
                                    action
                                )
                            }
                        </React.Fragment>
                    </TitleBox>
                </Col>
            </Row>
        );
    }

    const updateAccountingCollectionGroup = (
        idPrefix: 'default' | 'defaultAnonymous',
        id: string,
        options: PortalCreateSubmissionRequestOptions
    ): void => {
        const modify = options.accountingCollectionGroups.get(id);
        if (!!modify) {
            const accountingCollectionGroups = new Map<string, AccountingCollectionGroup>(options.accountingCollectionGroups);
            accountingCollectionGroups.set(modify.id, { ...modify, collect: !modify.collect });
            if (idPrefix === 'default') {
                setDefaultImportSettings({
                    ...defaultImportSettings!,
                    accountingCollectionGroups,
                });
                props.OverrideAppSettings({
                    defaultPortalSubmissionOptions: {
                        ...rawState!.defaultPortalSubmissionOptions!,
                        accountingCollectionGroups,
                    }
                });
            }
            if (idPrefix === 'defaultAnonymous') {
                setDefaultAnonymousImportSettings({
                    ...defaultAnonymousImportSettings!,
                    accountingCollectionGroups,
                });
                props.OverrideAppSettings({
                    defaultShareableAnonymousSubmissionOptions: {
                        ...rawState!.defaultShareableAnonymousSubmissionOptions!,
                        accountingCollectionGroups,
                    }
                });
            }
            setAccountingGroupsVersion(accountingGroupsVersion + 1);
        }
    }

    const getDefaultSettings = (
        idPrefix: 'default' | 'defaultAnonymous',
        options: PortalCreateSubmissionRequestOptions,
        maxCollectionPeriods: number,
        onMostRecentMonthChange: (newValue: MostRecentDate) => void,
        onPeriodsToCollectChanged: (newValue: number) => void,
        onToggleOnOffSetting: (setting: OnOffSettings) => void,
        onBasisOfAccountingChanged: (newValue: BasisOfAccountingPreference) => void,
        replaceCalendarWithMonthEndOrToday?: boolean,
        onCollectThroughChanged?: (newValue: 'Today' | 'LastFullMonth') => void
    ): React.ReactElement => {
        if (!(rawState && rawState?.defaultPortalSubmissionOptions && rawState?.defaultShareableAnonymousSubmissionOptions)) {
            return (
                <React.Fragment />
            );
        }
        return (
            <React.Fragment>
                <Row>
                    <Col>
                        <CollectFinancialsImportParameters
                            showShareableLinkParameters={true}
                            directLink={false}
                            parameters={options}
                            maxCollectionPeriods={5}
                            onMostRecentMonthChange={onMostRecentMonthChange}
                            onPeriodsToCollectChanged={onPeriodsToCollectChanged}
                            onToggleOnOffSetting={onToggleOnOffSetting}
                            onBasisOfAccountingChanged={onBasisOfAccountingChanged}
                            numberOfMonthsInDropdown={rawState!.numMonthsInImportYTDDropdown}
                            idPrefix={idPrefix}
                            linkMode={LinkMode.shareable}
                            replaceCalendarWithMonthEndOrToday={replaceCalendarWithMonthEndOrToday}
                            onCollectThroughChanged={onCollectThroughChanged}
                        />
                    </Col>
                </Row>
                <Row
                    style={{ marginTop: '25px' }}
                    key={'financialImportCollectionGroupOptions'}
                >
                    <Col>
                        <TitleBox
                            title={'Accounting groups'}
                            containerStyle={{
                                paddingLeft: '15px'
                            }}
                            titleStyle={{
                                fontSize: '16px',
                                fontWeight: 'normal',
                                backgroundColor: 'transparent',
                            }}
                        >
                            <React.Fragment>
                                {checkBoxSettingsManualChange(
                                    'Collect transactions',
                                    `${idPrefix}-collectFinancialImportTransactions`,
                                    !!options.accountingCollectionGroups.get(knownAccountingGroupIds.transactions)?.collect,
                                    () => {
                                        updateAccountingCollectionGroup(idPrefix, knownAccountingGroupIds.transactions, options)
                                    }
                                )}
                                {checkBoxSettingsManualChange(
                                    'Collect payables',
                                    `${idPrefix}-collectFinancialImportPayables`,
                                    !!options.accountingCollectionGroups.get(knownAccountingGroupIds.accountsPayable)?.collect,
                                    () => {
                                        updateAccountingCollectionGroup(idPrefix, knownAccountingGroupIds.accountsPayable, options)
                                    }
                                )}
                                {checkBoxSettingsManualChange(
                                    'Collect receivables',
                                    `${idPrefix}-collectFinancialImportReceivables`,
                                    !!options.accountingCollectionGroups.get(knownAccountingGroupIds.accountsReceivable)?.collect,
                                    () => {
                                        updateAccountingCollectionGroup(idPrefix, knownAccountingGroupIds.accountsReceivable, options)
                                    }
                                )}
                                {checkBoxSettingsManualChange(
                                    'Collect financial statements',
                                    `${idPrefix}-collectFinancialImportFinancialStatements`,
                                    !!options.accountingCollectionGroups.get(knownAccountingGroupIds.financialStatements)?.collect,
                                    () => {
                                        updateAccountingCollectionGroup(idPrefix, knownAccountingGroupIds.financialStatements, options)
                                    }
                                )}
                            </React.Fragment>
                        </TitleBox>
                    </Col>
                </Row>
            </React.Fragment>
        )
    }

    return (
        <div className={containingDivClass}>
            <Container
                style={{ paddingLeft: '20px', paddingTop: '20px', justifyContent: 'left', alignItems: 'start' }}
                key={`tenant-settings`}
                fluid
            >
                <Row>
                    <Col xs={6}>
                        <label htmlFor={'tenants'}>Select a new tenant:</label>
                        <select
                            style={{ height: 'fit-content', padding: '5px', marginLeft: '15px' }}
                            name={'tenants'} id={'tenants'}
                            onChange={(event) => {
                                SetActiveTenant(event.target.value);
                                setRedirect(true);
                            }}
                            value={tenantName}
                        >
                            {
                                tenants.map(tenant => {
                                    return (
                                        <option
                                            value={tenant}
                                            key={`tenant-select-${tenant}`}
                                        >
                                            {tenant}
                                        </option>
                                    );
                                })
                            }
                        </select>
                    </Col>
                </Row>
                <Row
                    style={{
                        marginTop: '25px',
                        width: '100%'
                    }}
                >
                    <Col xs={6}>
                        {rawState && (
                            <React.Fragment>
                                {checkBoxSettings(
                                    'Account Mapping Beta Active',
                                    'financialStatementsEnabled',
                                    rawState.financialStatementsEnabled,
                                    { financialStatementsEnabled: !rawState.financialStatementsEnabled }
                                )}
                                {checkBoxSettings(
                                    'Account Mapping Release Active',
                                    'financialStatementsReleaseEnabled',
                                    rawState.financialStatementsReleaseEnabled,
                                    { financialStatementsReleaseEnabled: !rawState.financialStatementsReleaseEnabled }
                                )}
                                {checkBoxSettings(
                                    'Risk calc enabled',
                                    'riskCalcEnabled',
                                    rawState.riskCalcEnabled,
                                    { riskCalcEnabled: !rawState.riskCalcEnabled }
                                )}
                                {checkBoxSettings(
                                    'Allow excel file upload',
                                    'allowExcelFileUpload',
                                    rawState.allowExcelFileUpload,
                                    { allowExcelFileUpload: !rawState.allowExcelFileUpload }
                                )}
                                {checkBoxSettings(
                                    'Disable add new workspaces',
                                    'disableNewWorkspaces',
                                    rawState.disableNewWorkspaces,
                                    { disableNewWorkspaces: !rawState.disableNewWorkspaces }
                                )}
                                {checkBoxSettings(
                                    'Anonymous borrower flow: no additional document upload in anonymous',
                                    'disableAnonymousDocUpload',
                                    rawState.disabledDataCollectionOptions.disableAnonymousDocUpload,
                                    {
                                        disabledDataCollectionOptions: {
                                            ...rawState.disabledDataCollectionOptions,
                                            disableAnonymousDocUpload: !rawState.disabledDataCollectionOptions.disableAnonymousDocUpload,
                                        }
                                    }
                                )}
                                {checkBoxSettings(
                                    'New Data Collection: No direct connections',
                                    'disableDirectLinking',
                                    rawState.disableDirectLinking,
                                    { disableDirectLinking: !rawState.disableDirectLinking }
                                )}
                                {checkBoxSettings(
                                    'New Data Collection: don\'t allow changing additional document upload setting',
                                    'disableAdditionalDocUpload',
                                    rawState.disabledDataCollectionOptions.disableAdditionalDocUpload,
                                    {
                                        disabledDataCollectionOptions: {
                                            ...rawState.disabledDataCollectionOptions,
                                            disableAdditionalDocUpload: !rawState.disabledDataCollectionOptions.disableAdditionalDocUpload,
                                        }
                                    }
                                )}
                                {checkBoxSettings(
                                    'New Data Collection: don\'t allow changing setting for providing user copy',
                                    'disableProvideUserCopy',
                                    rawState.disabledDataCollectionOptions.disableProvideUserCopy,
                                    {
                                        disabledDataCollectionOptions: {
                                            ...rawState.disabledDataCollectionOptions,
                                            disableProvideUserCopy: !rawState.disabledDataCollectionOptions.disableProvideUserCopy,
                                        }
                                    }
                                )}
                                {checkBoxSettings(
                                    'New Data Collection: don\'t allow changing anonymization setting',
                                    'disableAnonymizeResults',
                                    rawState.disabledDataCollectionOptions.disableAnonymizeResults,
                                    {
                                        disabledDataCollectionOptions: {
                                            ...rawState.disabledDataCollectionOptions,
                                            disableAnonymizeResults: !rawState.disabledDataCollectionOptions.disableAnonymizeResults,
                                        }
                                    }
                                )}
                                {checkBoxSettings(
                                    'New Data Collection: don\'t allow changing the basis of accounting',
                                    'disableBasisOfAccounting',
                                    rawState.disabledDataCollectionOptions.disableBasisOfAccounting,
                                    {
                                        disabledDataCollectionOptions: {
                                            ...rawState.disabledDataCollectionOptions,
                                            disableBasisOfAccounting: !rawState.disabledDataCollectionOptions.disableBasisOfAccounting,
                                        }
                                    }
                                )}
                                {checkBoxSettings(
                                    'New Data Collection: Allow any import through date',
                                    'noTimeRestrictionForDataCollection',
                                    rawState.noTimeRestrictionForDataCollection,
                                    { noTimeRestrictionForDataCollection: !rawState.noTimeRestrictionForDataCollection }
                                )}
                                {checkBoxSettings(
                                    'Borrower flow: show intro borrower banner',
                                    'showBorrowerIntroBanner',
                                    rawState.showBorrowerIntroBanner,
                                    { showBorrowerIntroBanner: !rawState.showBorrowerIntroBanner }
                                )}
                                {checkBoxSettings(
                                    'Borrower flow: always collect borrower business info',
                                    'alwaysCollectBorrowerInfo',
                                    rawState.alwaysCollectBorrowerBusinessInfo,
                                    { alwaysCollectBorrowerBusinessInfo: !rawState.alwaysCollectBorrowerBusinessInfo }
                                )}
                                {checkBoxSettings(
                                    'Borrower flow: allow multiple imports',
                                    'allowMultipleBorrowerImports',
                                    rawState.allowMultipleBorrowerImports,
                                    { allowMultipleBorrowerImports: !rawState.allowMultipleBorrowerImports }
                                )}
                                <Row
                                    style={{ marginTop: '5px' }}
                                    key={'numMonthsInImportYTDDropdown'}
                                >
                                    <Col>
                                        <label
                                            htmlFor={'numMonthsInImportYTDDropdown'}
                                            style={{
                                                marginRight: '15px'
                                            }}
                                        >
                                            Number of periods in month drop down
                                        </label>
                                        <input
                                            type={'number'}
                                            id={'numMonthsInImportYTDDropdown'}
                                            name={'numMonthsInImportYTDDropdown'}
                                            onChange={e => props.OverrideAppSettings({ numMonthsInImportYTDDropdown: parseInt(e.currentTarget.value) })}
                                            value={rawState.numMonthsInImportYTDDropdown}
                                        >
                                        </input>
                                    </Col>
                                </Row>
                                <Row
                                    style={{ marginTop: '10px' }}
                                    key={'sessionMaxLifetimeInSeconds'}
                                >
                                    <Col>
                                        <label
                                            htmlFor={'sessionMaxLifetimeInSeconds'}
                                            style={{
                                                marginRight: '15px'
                                            }}
                                        >
                                            Max Auth0 cached login life in seconds
                                        </label>
                                        <input
                                            type={'number'}
                                            id={'sessionMaxLifetimeInSeconds'}
                                            name={'sessionMaxLifetimeInSeconds'}
                                            onChange={e => props.OverrideAppSettings({ sessionMaxLifetimeInSeconds: parseInt(e.currentTarget.value) })}
                                            value={rawState.sessionMaxLifetimeInSeconds}
                                        >
                                        </input>
                                    </Col>
                                </Row>
                            </React.Fragment>
                        )}
                    </Col>
                    <Col
                        xs={6}
                        lg={2}
                        style={{
                            minWidth: '350px'
                        }}
                    >
                        <Row>
                            <Col>
                                {accountingPackageList(
                                    'Accounting Packages',
                                    'accountingPackages',
                                    rawState?.accountingPackages || [],
                                    props.SetAccountingPackage
                                )}
                            </Col>
                        </Row>
                        <Row
                            style={{
                                marginTop: '20px'
                            }}
                        >
                            <Col>
                                {accountingPackageList(
                                    'Accounting Package Blacklist',
                                    'accountingPkgBlackList',
                                    rawState?.accountingPkgBlackList || [],
                                    props.SetAccountingPackageBlacklist
                                )}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {(!!defaultImportSettings || defaultAnonymousImportSettings) && rawState && (
                    <Row style={{marginTop: '40px'}}>
                        {!!defaultImportSettings && (
                            <Col xs={6} key={`defaults-${accountingGroupsVersion.toString()}`}>
                                <TitleBox
                                    title={'Default settings'}
                                    containerStyle={{
                                        paddingLeft: '15px'
                                    }}
                                    titleStyle={{
                                        fontSize: '16px',
                                        fontWeight: 'normal',
                                        backgroundColor: 'transparent',
                                    }}
                                >
                                    {getDefaultSettings(
                                        'default',
                                        defaultImportSettings,
                                        rawState.numMonthsInImportYTDDropdown,
                                        (newValue) => {
                                            const newSettings = {
                                                ...defaultImportSettings,
                                            }
                                            newSettings.mostRecentPeriodMonth = newValue.month;
                                            newSettings.mostRecentPeriodYear = newValue.year;
                                            newSettings.mostRecentPeriodDay = newValue.day;
                                            setDefaultImportSettings(newSettings);
                                            props.OverrideAppSettings({
                                                defaultPortalSubmissionOptions: newSettings
                                            });
                                        },
                                        (newValue) => {
                                            const newSettings = {
                                                ...defaultImportSettings,
                                            }
                                            newSettings.fullFiscalYearsToCollect = newValue;
                                            setDefaultImportSettings(newSettings);
                                            props.OverrideAppSettings({
                                                defaultPortalSubmissionOptions: newSettings
                                            });
                                        },
                                        (setting) => {
                                            const newSettings = {
                                                ...defaultImportSettings,
                                            }
                                            switch (setting) {
                                                case OnOffSettings.allowUserUpload:
                                                    newSettings.allowUserUpload = !newSettings.allowUserUpload;
                                                    break;
                                                case OnOffSettings.anonymizeCustomersAndVendors:
                                                    newSettings.anonymizeCustomersAndVendors = !newSettings.anonymizeCustomersAndVendors;
                                                    break;
                                                case OnOffSettings.userGetsCopy:
                                                    newSettings.provideUserCopy = !newSettings.provideUserCopy;
                                                    break;
                                            }
                                            setDefaultImportSettings(newSettings);
                                            props.OverrideAppSettings({
                                                defaultPortalSubmissionOptions: newSettings
                                            });
                                        },
                                        (newValue) => {
                                            const newSettings = {
                                                ...defaultImportSettings,
                                            }
                                            newSettings.basisOfAccountingPreference = newValue;
                                            setDefaultImportSettings(newSettings);
                                            props.OverrideAppSettings({
                                                defaultPortalSubmissionOptions: newSettings
                                            });
                                        }
                                    )}
                                </TitleBox>
                            </Col>
                        )}
                        {!!defaultAnonymousImportSettings &&  (
                            <Col xs={6}>
                                <TitleBox
                                    title={'Default anonymous settings'}
                                    containerStyle={{
                                        paddingLeft: '15px'
                                    }}
                                    titleStyle={{
                                        fontSize: '16px',
                                        fontWeight: 'normal',
                                        backgroundColor: 'transparent',
                                    }}
                                >
                                    {getDefaultSettings(
                                        'defaultAnonymous',
                                        defaultAnonymousImportSettings,
                                        rawState.numMonthsInImportYTDDropdown,
                                        (newValue) => {
                                            const newSettings = {
                                                ...defaultAnonymousImportSettings,
                                            }
                                            newSettings.mostRecentPeriodMonth = newValue.month;
                                            newSettings.mostRecentPeriodYear = newValue.year;
                                            newSettings.mostRecentPeriodDay = newValue.day;
                                            setDefaultAnonymousImportSettings(newSettings);
                                            props.OverrideAppSettings({
                                                defaultShareableAnonymousSubmissionOptions: newSettings
                                            });
                                        },
                                        (newValue) => {
                                            const newSettings = {
                                                ...defaultAnonymousImportSettings,
                                            }
                                            newSettings.fullFiscalYearsToCollect = newValue;
                                            setDefaultAnonymousImportSettings(newSettings);
                                            props.OverrideAppSettings({
                                                defaultShareableAnonymousSubmissionOptions: newSettings
                                            });
                                        },
                                        (setting) => {
                                            const newSettings = {
                                                ...defaultAnonymousImportSettings,
                                            }
                                            switch (setting) {
                                                case OnOffSettings.allowUserUpload:
                                                    newSettings.allowUserUpload = !newSettings.allowUserUpload;
                                                    break;
                                                case OnOffSettings.anonymizeCustomersAndVendors:
                                                    newSettings.anonymizeCustomersAndVendors = !newSettings.anonymizeCustomersAndVendors;
                                                    break;
                                                case OnOffSettings.userGetsCopy:
                                                    newSettings.provideUserCopy = !newSettings.provideUserCopy;
                                                    break;
                                            }
                                            setDefaultAnonymousImportSettings(newSettings);
                                            props.OverrideAppSettings({
                                                defaultShareableAnonymousSubmissionOptions: newSettings
                                            });
                                        },
                                        (newValue) => {
                                            const newSettings = {
                                                ...defaultAnonymousImportSettings,
                                            }
                                            newSettings.basisOfAccountingPreference = newValue;
                                            setDefaultAnonymousImportSettings(newSettings);
                                            props.OverrideAppSettings({
                                                defaultShareableAnonymousSubmissionOptions: newSettings
                                            });
                                        },
                                        true,
                                        (newValue) => {
                                            const newSettings = {
                                                ...defaultAnonymousImportSettings,
                                            }
                                            newSettings.endOfImport = newValue;
                                            newSettings.mostRecentPeriodDay = undefined;
                                            newSettings.mostRecentPeriodMonth = undefined;
                                            newSettings.mostRecentPeriodYear = undefined;
                                            setDefaultAnonymousImportSettings(newSettings);
                                            props.OverrideAppSettings({
                                                defaultShareableAnonymousSubmissionOptions: newSettings
                                            });
                                        }
                                    )}
                                </TitleBox>
                            </Col>
                        )}
                    </Row>
                )}
            </Container>
        </div>
    );
}

export const TenantSelection = connect<InjectedReduxState, InjectedActionCreators, TenantSelectionProps, ApplicationState>(
    (appState: ApplicationState) => {
        return {
            selectionEnabled: GetDynamicTenantSelectionSwitch(appState),
            tenantName: GetTenantName(appState),
            rawState: GetRawAppSettingsState(appState),
        };
    },
    dispatch => bindActionCreators(
        {
            ...TenantActionCreators,
            ...AppSettingActionCreators,
        },
        dispatch
    )
)(TenantSelectionComponent);
