import '../Main.scss';

import * as React from 'react';
import ReactInlineSvg from 'react-inlinesvg';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ReactHtmlParser from 'react-html-parser'

import {
    Row,
    Col,
} from 'reactstrap';
import {
    GetDefaultSubmissionOptions,
    GetShowBorrowerIntroBanner,
    GetAlwaysCollectBorrowerBusinessInfo,
} from '../../Store/AppSettings';
import { actionCreators as ImportFinancialsActions } from '../../Store/ImportFinancials';
import { ExtractUrlParameters } from '../../Utils/PathUtils';
import { ApplicationState } from '../../Store';
import {
    GetBorrowerAnonymousState,
    BorrowerActionCreators,
} from '../../Store/Borrower';
import {
    GetRequestedOptions,
    RetrievingShareableLinkOptions,
    RetrieveShareableLinkOptionsFailed
} from '../../Store/NewDataCollectionParameters';
import {
    TenantActionCreators,
    ResourceName,
    RetrieveResource,
    TenantParagraphSet,
    GetBasicSettings
} from '../../Store/Tenant';
import { EnterWorkspaceInfo } from '../EnterWorkspaceInfo/EnterWorkspaceInfo';

import { SelectAccountingSystem } from '../SelectAccountingSystem/SelectAccountingSystem';
import { AccountingPkgPresentation } from '../SelectAccountingSystem/AccountingPkgPresentation';

import { PortalCreateSubmissionRequestOptions } from '../../Models/PortalCreateSubmissionRequestOptions';

import { BorrowerPortalChildProps } from './BorrowerPortal';

import { MetricsService } from '../../Utils/Metrics';
import { LogMessage, SeverityLevel } from '../../Utils/Logging';
import { DefaultStrongboxInfoLink } from '../../Utils/Constants';
import { EntitySafeDetailResponse } from '../../Models/Api/strongbox.financialportal';
import StrongboxLogo from '../../images/StrongboxLogo.svg';

import { GetWorkspaceSafeDetails } from '../../Services/WorkspaceService';

type InjectedReduxState = {
    tenantWelcomeParagraphs: TenantParagraphSet;
    tenantEnterBusinessInfoParagraphs: TenantParagraphSet;
    tenantDocsPrompt: string;
    tenantLinkText: string;
    tenantBorrowIntroBannerText: TenantParagraphSet;
    anonymousLink: boolean;
    shareableLinkOptions: PortalCreateSubmissionRequestOptions | undefined;
    retrievingShareableLinkOptions: boolean;
    retrievingSharedLinkOptionsFailed: boolean;
    defaultAnonymousSubmissionOptions: PortalCreateSubmissionRequestOptions;
    strongboxInfoLink: string;
    showIntroBanner: boolean;
    alwaysCollectBorrowerBusinessInfo: boolean;
};

type InjectedActionCreators = typeof BorrowerActionCreators & typeof ImportFinancialsActions & typeof TenantActionCreators;

type Props = InjectedReduxState & InjectedActionCreators & BorrowerPortalChildProps;

export const PortalSubmissionComponent: React.FC<Props> = (props): React.ReactElement => {
    const {
        alwaysCollectBorrowerBusinessInfo,
        anonymousLink,
        borrowerId,
        createSubmissionAndRedirectDocs,
        defaultAnonymousSubmissionOptions,
        importAccountingPkg,
        importAccountingPkgAnonymous,
        retrievingShareableLinkOptions,
        retrievingSharedLinkOptionsFailed,
        SetBorrowerId,
        SetBorrowerAcceptedBusinessInfo,
        SetBorrowerAnonymous,
        shareableLinkOptions,
        showIntroBanner,
        strongboxInfoLink,
        submitWorkspaceInformationAndRedirectToDocs,
        tenantDocsPrompt,
        tenantEnterBusinessInfoParagraphs,
        tenantBorrowIntroBannerText,
        tenantLinkText,
        tenantWelcomeParagraphs,
        working,
    } = props;

    const location = useLocation();

    const [workspaceInfoValid, setWorkspaceInfoValid] = React.useState<boolean>(false);
    const [hasAcceptedWorkspaceInfo, setHasAcceptedWorkspaceInfo] = React.useState<boolean>(false);
    const [showingIntroBanner, setShowingIntroBanner] = React.useState<boolean>(false);
    const [collectBorrowerBusinessInfo, setCollectBorrowerBusinessInfo] = React.useState<boolean>(false);
    const [clickedPackage, setClickedPackage] = React.useState<AccountingPkgPresentation | undefined>(undefined);

    // static displayName = Home.name;

    const isValidBorrowerId = (borrowerId: string): boolean => {
        return (!!borrowerId) && (borrowerId.trim() !== '');
    }

    const setBorrowerIdWrapper = (id: string, wsDetails?: EntitySafeDetailResponse): void => {
        SetBorrowerId(id, wsDetails);
        if(!!id) {
            MetricsService.identifyUser('portal', id);
        }
    }

    const extractParameters = (): void => {
        let borrowerId = '';

        ExtractUrlParameters(
            location.search,
            (key: string, value: string): void => {
                switch (key.toLowerCase()) {
                    case 'id':
                    case 'eid':
                        borrowerId = unescape(value);
                        break;
                }
            }
        );

        if (!isValidBorrowerId(borrowerId)) {
            SetBorrowerAnonymous(true);
            setBorrowerIdWrapper(borrowerId);
        } else {
            GetWorkspaceSafeDetails(borrowerId)
                .then(response => {
                    setBorrowerIdWrapper(borrowerId, response);
                })
                .catch(e => {
                    setBorrowerIdWrapper(borrowerId);
                });
        }
    }

    React.useEffect(() => {
        extractParameters();

        if (showIntroBanner) {
            setShowingIntroBanner(true);
        }

        // I want this to execute equivalent to componentDidMount so this is appropriate
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        const shouldCollect = anonymousLink || alwaysCollectBorrowerBusinessInfo
        setCollectBorrowerBusinessInfo(shouldCollect);
    }, [anonymousLink, alwaysCollectBorrowerBusinessInfo]);

    const renderParagraphs = (tenantParagraphs: TenantParagraphSet, bolded ?: boolean): React.ReactNode => {
        const paragraphs: React.ReactNode[] = [];
        let keyVal = 1;

        if (tenantParagraphs && tenantParagraphs.paragraphs) {
            tenantParagraphs.paragraphs.forEach(paragraph => {
                paragraphs.push(
                    !!bolded ?
                        (
                            <div key={`${keyVal}`} className={'p2'}>
                                <h2>{ReactHtmlParser(paragraph)}</h2>
                            </div>
                        ) :
                        (
                            <div key={`${keyVal}`} className={'p2'}>
                                <h3>{ReactHtmlParser(paragraph)}</h3>
                            </div>
                        )
                );
                keyVal++;
            });
        }

        return (
            <>
                {paragraphs}
            </>
        );
    }

    React.useEffect(() => {
        if (!!clickedPackage) {
            if (anonymousLink) {
                importAccountingPkgAnonymous(clickedPackage, workspaceInfoValid);
            } else {
                importAccountingPkg(clickedPackage);
            }
            setClickedPackage(undefined);
        }

        // This is the dependency list I actually want
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clickedPackage]);

    const accountingPkgClickHandler = (accountingPkg: AccountingPkgPresentation): void => {
        LogMessage(
            `User clicked an accounting package in the borrower flow ${accountingPkg.descriptor}`,
            SeverityLevel.Information,
            {
                ...accountingPkg
            }
        );
        setClickedPackage(accountingPkg);
    }

    const accountingPackageButtons =  (tabIndexBase: number) => {
        // isWorking is set when the user has chosen to go to the end without linking with an accounting package.
        //
        // hasAcceptedWorkspaceInfo will be false if we are in anonymous mode and the user has not clicked to accept
        // the workspace info they entered. workspaceInfoValid will be true only if there is valid workspace information
        // in the entry fields, it can be false when hasAcceptedWorkspaceInfo is true and they changed the information.
        //
        // showingDialog will be true when the user has clicked to link an accounting package.  At that point an async
        // process to get the connection (that includes a delegatedAccessToken) is started.  connection will be undefined 
        // for a small time between clicking the accounting package button and actually having the connection information 
        // available.

        const buttonsDisabled =
            (working || retrievingShareableLinkOptions || retrievingSharedLinkOptionsFailed ||
                (hasAcceptedWorkspaceInfo && !workspaceInfoValid))

        return (<SelectAccountingSystem
            buttonsDisabled={buttonsDisabled}
            onLink={accountingPkgClickHandler}
            rowClass={'borrower-content-row'}
            tabIndexBase={tabIndexBase}
        />);
    }

    const setWorkspaceInputInvalid = (): void => {
        setWorkspaceInfoValid(false);
    }

    const setWorkspaceInputValid = (businessName: string, emailAddress: string): void => {
        setWorkspaceInfoValid(true);

        SetBorrowerAcceptedBusinessInfo(businessName, emailAddress);
    }

    const executeAdditionalDocsRedirect = (): void => {
        if (anonymousLink) {
            submitWorkspaceInformationAndRedirectToDocs();
        } else {
            createSubmissionAndRedirectDocs(borrowerId);
        }
    }

    const docText = (
        <p>{`${tenantDocsPrompt} `}
            <span
                onClick={(e) => executeAdditionalDocsRedirect()}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        executeAdditionalDocsRedirect();
                    }
                }}
                role={'link'}
                tabIndex={0}
                aria-label={tenantDocsPrompt}
            >
                {tenantLinkText}
            </span>
        </p>
    );

    const showBorrowerCollectionInfo = React.useMemo(() => {
        return (collectBorrowerBusinessInfo) && !(alwaysCollectBorrowerBusinessInfo && hasAcceptedWorkspaceInfo);
    }, [collectBorrowerBusinessInfo, alwaysCollectBorrowerBusinessInfo, hasAcceptedWorkspaceInfo]);

    const borrowerInfoTabStops = React.useMemo(() => {
        return showBorrowerCollectionInfo ? 2 : 0;
    }, [showBorrowerCollectionInfo])

    const commonContent = React.useMemo(() => {
        return (
            <React.Fragment>
                <Row className={'borrower-content-row'}>
                    <Col>
                        {accountingPackageButtons(borrowerInfoTabStops + 1)}
                    </Col>
                </Row>
                {
                    // We used to check to ensure that the disableFileUpload flag was not enabled, however,
                    // it is conceivable and configuration supports that the tenant could choose to disable
                    // the ability to CHANGE the allow additional file upload setting while also setting the
                    // default value to true.   
                    //
                    // So the ability to see and change the setting is untethered from what the setting 
                    // actually is.

                    (
                        (!anonymousLink && (
                            (
                                (!shareableLinkOptions) ||
                                (shareableLinkOptions.allowUserUpload)
                            ) &&
                            (!retrievingSharedLinkOptionsFailed)
                        )) ||
                        (anonymousLink && workspaceInfoValid && defaultAnonymousSubmissionOptions.allowUserUpload)
                    ) && (
                        <Row
                            style={{ marginTop: '15px' }}
                            className={'borrower-content-row'}
                        >
                            <Col className={'regular-paragraph-text-grouping'}>
                                {docText}
                            </Col>
                        </Row>
                    )
                }
            </React.Fragment>
        )

        // This is the dependency list I actually want
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shareableLinkOptions, retrievingSharedLinkOptionsFailed, docText])

    const startImportFragment = React.useMemo(() => {
        if (showingIntroBanner) {
            return undefined;
        }
        return (
            <>
                {
                    !showBorrowerCollectionInfo && (
                        <React.Fragment>
                            <Row className={'borrower-content-row'}>
                                <Col>
                                    {renderParagraphs(tenantWelcomeParagraphs)}
                                </Col>
                            </Row>
                            {commonContent}
                        </React.Fragment>
                    )
                }
                {
                    showBorrowerCollectionInfo && (
                        <>
                            <Row className={'borrower-content-row'}>
                                <Col>
                                    {renderParagraphs(tenantEnterBusinessInfoParagraphs, true)}
                                </Col>
                            </Row>

                            <Row className={'borrower-content-row'}>
                                <Col
                                    style={{
                                        marginTop: '30px',
                                    }}
                                >
                                    <EnterWorkspaceInfo
                                        onInputValid={setWorkspaceInputValid}
                                        onInputInvalid={setWorkspaceInputInvalid}
                                        autoFocus={true}
                                        tabIndexBase={1}
                                    />
                                </Col>
                            </Row>

                            {!hasAcceptedWorkspaceInfo && (
                                <Row className={'borrower-content-row'}>
                                    <Col>
                                        <button
                                            type="button"
                                            className={`small regular-button`}
                                            disabled={!workspaceInfoValid || working}
                                            onClick={() => {
                                                LogMessage(
                                                    'User has entered business information in borrower flow introduction page and chosen to continue',
                                                    SeverityLevel.Information
                                                );
                                                setHasAcceptedWorkspaceInfo(true);
                                            }}
                                            style={{
                                                marginTop: '20px',
                                            }}
                                            tabIndex={0}
                                        >
                                            Continue
                                        </button>
                                    </Col>
                                </Row>
                            )}

                            {hasAcceptedWorkspaceInfo && commonContent}
                        </>
                    )
                }
                <Row className={`borrower-content-row basic-height-separator`}>
                    <Col className={'know-more'}>
                        <p>{'Would you like to know more about Strongbox and what it does? '}
                            <a
                                href={strongboxInfoLink}
                                className={'normal-link-color'}
                                rel={'noopener noreferrer'}
                                target={'_blank'}
                                aria-label={'To learn more about Strongbox and what it does'}
                            >
                                Click here
                            </a>
                        </p>
                    </Col>
                </Row>
            </>
        );

        // This is the dependency list I actually want
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showingIntroBanner, workspaceInfoValid, working, hasAcceptedWorkspaceInfo, collectBorrowerBusinessInfo]);

    const bannerFragment = React.useMemo(() => {
        if (!showingIntroBanner) {
            return undefined;
        }
        return (
            <div className={'control-region'}>
                <Row className={'borrower-content-row'}>
                    <Col>
                        <ReactInlineSvg
                            src={StrongboxLogo}
                            className={'strongbox-logo'}
                        />
                    </Col>
                </Row>
                <Row
                    className={'borrower-content-row intro-banner-content'}
                    style={{
                        marginTop: '9%',
                    }}
                >
                    <Col>
                        {renderParagraphs(tenantBorrowIntroBannerText)}
                    </Col>
                </Row>
                <Row
                    className={'borrower-content-row'}
                    style={{
                        marginTop: '20%'
                    }}
                >
                    <Col>
                        <button
                            onClick={() => {
                                LogMessage(`User advancing past introduction banner in borrower flow`, SeverityLevel.Information);
                                setShowingIntroBanner(false);
                            }}
                            className={'focus-button'}
                            autoFocus={true}
                            tabIndex={1}
                        >
                            Continue to Strongbox
                        </button>
                    </Col>
                </Row>
            </div>
        )

        // This is the dependency list I actually want
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showingIntroBanner]);

    return (
        <React.Fragment>
            {!!startImportFragment && startImportFragment}
            {!!bannerFragment && bannerFragment}
        </React.Fragment>
    );
}

export const PortalSubmission = connect<InjectedReduxState, InjectedActionCreators, BorrowerPortalChildProps, ApplicationState>(
    (appState: ApplicationState) => {
        const anonymousLink = GetBorrowerAnonymousState(appState);
        const basicSettings = GetBasicSettings(appState);

        const result = {
            tenantWelcomeParagraphs: RetrieveResource(appState, ResourceName.tenantWelcomeText) || { paragraphs: [] },
            tenantEnterBusinessInfoParagraphs: RetrieveResource(appState, ResourceName.connectEnterBusinessInfo) || { paragraphs: [] },
            tenantDocsPrompt: RetrieveResource(appState, ResourceName.connectTenantDocsUploadPrompt) || '',
            tenantLinkText: RetrieveResource(appState, ResourceName.connectTenantUploadLink) || '',
            anonymousLink,
            shareableLinkOptions: GetRequestedOptions(appState),
            retrievingShareableLinkOptions: RetrievingShareableLinkOptions(appState),
            retrievingSharedLinkOptionsFailed: RetrieveShareableLinkOptionsFailed(appState),
            defaultAnonymousSubmissionOptions: GetDefaultSubmissionOptions(appState, 'shareableanonymous', true),
            strongboxInfoLink: (basicSettings && basicSettings.StrongboxSecurityInfoLink) || DefaultStrongboxInfoLink,
            showIntroBanner: GetShowBorrowerIntroBanner(appState),
            tenantBorrowIntroBannerText: RetrieveResource(appState, ResourceName.tenantBorrowerIntroBannerText),
            alwaysCollectBorrowerBusinessInfo: GetAlwaysCollectBorrowerBusinessInfo(appState),
        };

        return result;
    },
    dispatch => bindActionCreators(
        {
            ...BorrowerActionCreators,
            ...ImportFinancialsActions,
            ...TenantActionCreators,
        },
        dispatch
    )
)(PortalSubmissionComponent);
