// Credit https://davidwalsh.name/javascript-debounce-function
// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export function debounce<T extends Function>(func: T, wait: number, immediate: boolean = true): T {
    let timeout: number | undefined;

    return (function (this: Function) {
        var context = this,
            args = arguments;

        let later: TimerHandler = function () {
            timeout = undefined;
            if (!immediate) func.apply(context, args);
        };

        var callNow = immediate && !timeout;
        clearTimeout(timeout);

        timeout = setTimeout(later, wait);

        if (callNow) {
            func.apply(context, args);
        }
    }) as any;
};

/**
 * A utility function that returns a new function wrapping 'handleStateChange' that can be used in-place of the original function.
 * The wrapper function can be called every time an event occurs in the UI that potentially changes the state that will be sent to the server.
 * The wrapper function's job is to call the server exactly once with the latest state only after the state has stopped changing for at least 'settleTime'. IE, the server call is delayed until the user stops making edits.
 * @param handleStateChange A function that actually makes a server call. This function should accept a single parameter, which is the state that we are using to make the server call.
 * @param settleTime The amount of time that must pass before we consider things to have settled down in the UI. IE, the user has stopped updating the state that we want to store on the server.
 * @param hasStateChanged A function that takes two parameters, prior state and next state, and returns true if the state has changed (IE the user is still making edits that have changed the state that will be sent to the server). Please be aware that the first parameter passed to this function may be undefined the first time it is invoked.
 */
export function debounceStateChanges<StateT>(
    handleStateChange: (updatedState: StateT | undefined) => void ,
    settleTime: number,
    hasStateChanged: (priorState: StateT | undefined, currentState: StateT) => boolean): (s: StateT) => void {

    let invocationTimerHandle: number | undefined = undefined;
    let knownState: StateT | undefined = undefined;

    return function (
        this: Function,
        currentState: StateT) {
        if (hasStateChanged(knownState as StateT, currentState)) {

            knownState = currentState;

            // delay invocation of handleStateChange by settleTime relative to now, at which point it will
            // be invoked with the newState (unless cancelled before that time if the state changes again too rapidly)

            // the first step is to cancel the pending call to handleStateChange if it's pending
            if (invocationTimerHandle !== undefined) {
                clearTimeout(invocationTimerHandle);
            }

            // now schedule the new invocation of handleStateChange
            invocationTimerHandle = setTimeout(() => handleStateChange.apply(this, [knownState]), settleTime, currentState);
        } else {
            // state has not changed. nothing to do with the current state
        }   
    };
}