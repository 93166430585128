import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

import { useAuth0 } from "@auth0/auth0-react";

import { Theme, ThemeProvider } from '@mui/material/styles';
import { CreateStdTheme } from '../Utils/Style';

import { PortalHeader } from './PortalHeader';
import { PortalFooter } from './PortalFooter';

import { ApplicationState } from '../Store';
import { actionCreators as AppSettingsActions } from '../Store/AppSettings';
import {
    actionCreators as ImportFinancialsActions,
    CustomerFinancialsTarget,
    DirectLinkParameters,
    GetImportCustomerFinancialsTarget,
    GetImportingDirect,
    GetImportMap,
    ImportMap,
} from '../Store/ImportFinancials';
import { actionCreators as UserActions } from '../Store/User'
import { BrandConfig, GetBrandConfig } from '../Store/Tenant';
import { actionCreators as UIStateActions, GetPortalBusyState } from '../Store/UIState';

import { pathConstants, retryConnectForImport } from '../Utils/Constants';

import { AccountingPackage } from '../Models/Api/AccountingPackages';

import { FinancialsImport } from './FinancialsImport/FinancialsImport';
import { ErrorBanner, ErrorState } from './ErrorBanner/ErrorBanner';

import './Main.scss';

type InjectedReduxState = {
    portalBusy: boolean;
    financialsTarget: CustomerFinancialsTarget | undefined;
    directLinkParameters: DirectLinkParameters | undefined;
    importMap: ImportMap[];
    brandConfig: BrandConfig;
};

type InjectedActionCreators = typeof AppSettingsActions & typeof UserActions & typeof UIStateActions & typeof ImportFinancialsActions;

type LenderPortalProps = {
    hideMenu?: boolean;
    hideLoggedInUser?: boolean;
    children?: any;
    hideFooter?: boolean;
}

type Props = LenderPortalProps & InjectedActionCreators & InjectedReduxState;

const LenderPortalComponent: React.FC<Props> = (props): React.ReactElement => {
    const {
        directLinkParameters,
        financialsTarget,
        importMap,
        hideFooter
    } = props;

    const navigate = useNavigate();

    const { logout } = useAuth0();

    const [muiTheme] = React.useState<Theme>(() => {
        return CreateStdTheme(props.brandConfig);
    });

    const [errorState, setErrorState] = React.useState<ErrorState | undefined>(undefined);

    const setErrorStateOnImport = (errorState: ErrorState | undefined, accountingPackage: AccountingPackage, connectionId?: string): void => {
        if ((!errorState) || (!(props.financialsTarget && props.directLinkParameters))) {
            setErrorState(undefined);
        } else {
            setErrorState({
                ...errorState,
                actions: [
                    {
                        text: 'Dismiss',
                        id: 'skiprelink',
                        onAction: (id: string) => {
                            setErrorState(undefined);
                        }
                    },
                    {
                        text: retryConnectForImport,
                        id: 'startrelink',
                        onAction: (id: string) => {
                            const map = props.importMap.find(
                                mapEntry => {
                                    if (connectionId !== undefined) {
                                        return connectionId === mapEntry.importRequest.connectionId;
                                    } else {
                                        return (mapEntry.workspaceId === props.financialsTarget!.workspaceId && mapEntry.accountingPackage.toLowerCase() === accountingPackage.toLowerCase());
                                    }
                                }
                            );
                            if (!!map) {
                                props.RetryImportRequest(map);
                            }
                            setErrorState(undefined);
                        }
                    }
                ]
            });
        }
    }

    const controlClass = `control-region control-region-lender`;
    const mainDivClass =
        `free-content-region ${controlClass}`;

    const errorActions = React.useMemo(() => {
        if (!(financialsTarget && directLinkParameters)) {
            return undefined;
        } else {
            return (
                [
                    {
                        text: 'Dismiss',
                        id: 'dismissaction',
                        onAction: (actionId: string) => {
                            setErrorState(undefined);
                        }
                    },
                    {
                        text: retryConnectForImport,
                        id: 'entercredsandcontinue',
                        onAction: (id: string) => {
                            let keyAccountingPkg = directLinkParameters?.accountingPkg;
                            let connectionIdKey = directLinkParameters?.connectionId;

                            if (!!(keyAccountingPkg || connectionIdKey)) {
                                const map = importMap.find(
                                    mapEntry => {
                                        if (connectionIdKey !== undefined) {
                                            return mapEntry.importRequest.connectionId === connectionIdKey;
                                        } else {
                                            return mapEntry.workspaceId === financialsTarget!.workspaceId &&
                                                mapEntry.accountingPackage.toLowerCase() === keyAccountingPkg?.toLowerCase();
                                        }
                                    }
                                );
                                if (!!map) {
                                    props.RetryImportRequest(map);
                                }
                            }
                            setErrorState(undefined);
                        }
                    }
                ]                
            )
        }
        // These are the state that actually affect the execution.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [financialsTarget, directLinkParameters, importMap]);

    return (
        <div>
            <ThemeProvider theme={muiTheme}>
                <PortalHeader
                    hideMenu={props.hideMenu}
                    logout={() => {
                        props.LogUserOut(logout);
                    }}
                    settings={() => {
                        navigate(pathConstants.settingsDefault);
                    }}
                    dataCollectionReport={() => {
                        navigate(pathConstants.dataCollectionReport);
                    }}
                    notificationSettings={() => {
                        navigate(pathConstants.settingsNotifications);
                    }}
                    showLoggedInUser={props.hideLoggedInUser !== true}
                />
                {
                    !!errorState && (
                        <div style={{ width: '100%' }} className={mainDivClass}>
                            <div style={{ height: '100%' }} className={'contained-content'}>
                                <ErrorBanner
                                    errorState={{...errorState, actions: errorActions}}
                                    onDefaultActionButton={() => {
                                        setErrorState(undefined);
                                    }}
                                />
                            </div>
                        </div>
                    )
                }
                {
                    props.children
                }
                {
                    hideFooter !== true && (<PortalFooter />)
                }
                <FinancialsImport
                    onSetErrorState={(errorState) => {
                        if (!!props.directLinkParameters) {
                            setErrorStateOnImport(
                                errorState,
                                props.directLinkParameters.accountingPkg,
                                props.directLinkParameters.connectionId
                            );
                        } else {
                            setErrorState(errorState);
                        }
                    }}
                    onSetImporting={(working) => working ? props.SetPortalWorking('FinancialsImportProperty') : props.SetPortalIdle('FinancialsImportProperty')  }
                />
            </ThemeProvider>
        </div>
    );
}

export const LenderPortal = connect<InjectedReduxState, InjectedActionCreators, LenderPortalProps, ApplicationState>(
    (appState: ApplicationState) => {
        const result = {
            portalBusy: GetPortalBusyState(appState),
            financialsTarget: GetImportCustomerFinancialsTarget(appState),
            directLinkParameters: GetImportingDirect(appState),
            importMap: GetImportMap(appState),
            brandConfig: GetBrandConfig(appState),
        };

        return result;
    },
    {
        ...AppSettingsActions,
        ...UserActions,
        ...UIStateActions,
        ...ImportFinancialsActions
    }
)(LenderPortalComponent);
