import * as React from 'react';

import './AddModifyWorkspace.scss';

import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from 'reactstrap';

import { EntityResponse, UserResponse, } from '../../Models/Api/strongbox.financialportal';

import { UserAccessControl } from '../UserAccessControl/UserAccessControl';
import { ConfirmModal } from '../ConfirmModal/ConfirmModal';

import { FullUserName } from '../../Utils/UserUtils';
import { GetUnsafeContentMessage } from '../../Utils/FormUtils';
import { maxDisplayNameLength, maxEngagementCodeLength } from '../../Utils/Constants';
import { AddModifyWorkspaceData } from '../LenderHome/WorkspaceList/WorkspaceList';

export type AddModifyWorkspaceErrorState = 'nameRequired' | 'unsecureWorkspaceName' | 'unsecureEngagementCode';

/**
 * This modal can be used to either add or modify an existing workspace.
 * @member{workspace} is an optional value.  If present, the modal is used for modifying the users on an existing worksapce.  If it is not
 * present, it's used for adding an entirely new workspace.
 * */

type Props = {
    workspaceData?: AddModifyWorkspaceData;
    open: boolean;
    errorState?: AddModifyWorkspaceErrorState;
    onCancel: () => void;
    onSubmit: (userIds: string[]) => Promise<void>;
    allUsers?: UserResponse[];
    loggedOnUser?: UserResponse;
    workspace?: EntityResponse;
    currentUsers?: UserResponse[];
    onWorkspaceDataChange?: (workspaceData: AddModifyWorkspaceData) => void;
};

export const AddModifyWorkspace: React.FC<Props> = (props): React.ReactElement => {
    const {
        allUsers,
        currentUsers,
        errorState,
        loggedOnUser,
        onCancel,
        onSubmit,
        open,
        workspace,
        workspaceData,
        onWorkspaceDataChange
    } = props;

    const [includedUsers, setIncludedUsers] = React.useState<UserResponse[]>([]);
    const [excludedUsers, setExcludedUsers] = React.useState<UserResponse[]>([]);
    const [filteredUsers, setFilteredUsers] = React.useState<UserResponse[]>([]);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [confirmLoggedInUserRemove, setConfirmLoggedInUserRemove] = React.useState<UserResponse | undefined>(undefined);
    const [errMsg, setErrMsg] = React.useState<string | undefined>(undefined);

    React.useEffect(() => {
        let includedUsers: UserResponse[] = [];
        let excludedUsers: UserResponse[] = [];

        if (!!currentUsers) {
            includedUsers = currentUsers.slice();
            // Build the excluded users list, i.e. for each use in allUsers,
            // see if they are in the included users list and if not, add them to
            // excluded users.
            if (!!allUsers) {
                allUsers.forEach(user => {
                    if (!includedUsers.find(existingUser => existingUser.id === user.id)) {
                        excludedUsers.push({
                            ...user
                        });
                    }
                });
            }
        } else {
            includedUsers = !!loggedOnUser ? [loggedOnUser] : [];
            excludedUsers = allUsers?.slice() || [];

            if (!!loggedOnUser) {
                const iDelete = excludedUsers.findIndex(user => user.id === loggedOnUser!.id);
                if (iDelete !== -1) {
                    excludedUsers = excludedUsers.slice(0, iDelete).concat(excludedUsers.slice(iDelete + 1));
                }
            }
        }

        setIncludedUsers(includedUsers);
        setExcludedUsers(excludedUsers);

        setFilteredUsers(allUsers?.slice() || []);
    }, [allUsers, currentUsers, loggedOnUser])

    React.useEffect(() => {
        let newErrMsg: string | undefined = undefined;

        if (!!errorState) {
            switch (errorState) {
                case 'nameRequired': {
                    newErrMsg = 'Please enter a workspace name';
                    break;
                }
                case 'unsecureWorkspaceName': {
                    newErrMsg = GetUnsafeContentMessage('workspace name');
                    break;
                }
                case 'unsecureEngagementCode': {
                    newErrMsg = GetUnsafeContentMessage('engagement code');
                    break;
                }
            }
        }

        setErrMsg(newErrMsg);
    }, [errorState])

    const handleSubmission = (): void => {
        if (submitting) {
            return;
        }

        setSubmitting(true);

        const users = includedUsers.map(user => user.id);

        onSubmit(users)
            .finally(() => {
                setSubmitting(false);
            });
    }

    const onUserStatusChange = (userKey: UserResponse, include: boolean, overrideLoggedInCheck?: boolean): void => {
        if ((overrideLoggedInCheck !== true) && (!!loggedOnUser) && (!include)) {
            if (userKey.id === loggedOnUser.id) {
                setConfirmLoggedInUserRemove(loggedOnUser);
                return;
            }
        }
        if (include) {
            const iExclude = excludedUsers.findIndex(user => user.id === userKey.id);
            if (iExclude !== -1) {
                setIncludedUsers(includedUsers.concat(userKey));
                setExcludedUsers(excludedUsers.slice(0, iExclude).concat(excludedUsers.slice(iExclude + 1)));
            }
        } else {
            const iInclude = includedUsers.findIndex(user => user.id === userKey.id);
            if (iInclude !== -1) {
                setIncludedUsers(includedUsers.slice(0, iInclude).concat(includedUsers.slice(iInclude + 1)));
                setExcludedUsers(excludedUsers.concat(userKey));
            }
        }
    }

    const onSelectAll = (selected: boolean): void => {
        if (selected) {
            setIncludedUsers(includedUsers.slice().concat(excludedUsers));
            setExcludedUsers([]);
        } else {
            const newIncludedUsers: UserResponse[] = [];
            let newExcludedUsers = excludedUsers.slice().concat(includedUsers);

            if (!!loggedOnUser) {
                newIncludedUsers.push({
                    ...loggedOnUser
                });
                newExcludedUsers = newExcludedUsers.filter(user => loggedOnUser!.id === user.id ? undefined : user);
            }
            setIncludedUsers(newIncludedUsers);
            setExcludedUsers(newExcludedUsers);
        }
    }

    const requiredDataPresent = (): boolean => {
        const usersAreSelected = includedUsers.length > 0;
        // No existent workspace then we need a valid name and selected users
        if (!workspace) {
            return !!workspaceData?.displayName && usersAreSelected;
        } else {
            return usersAreSelected;
        }
    }

    if (!open) {
        return (<></>);
    }

    return (
        <Modal
            className={`flight-modal`}
            isOpen={true}
            toggle={onCancel}
            backdrop={'static'}
        >
            <ModalHeader toggle={onCancel} >{!!workspace ? 'Update Workspace Users' : 'Add Workspace'}</ModalHeader>
            <ModalBody>
                <div className={`control-region control-region-lender`}>
                    {
                        !!confirmLoggedInUserRemove && (
                            <ConfirmModal
                                msg={`You are removing access to this workspace for yourself (${confirmLoggedInUserRemove.displayName}). Are you sure you want to do this?`}
                                title={'Warning'}
                                onTerminalButton={(yes?: boolean) => {
                                    if (!!yes) {
                                        onUserStatusChange(confirmLoggedInUserRemove!, false, true);
                                    }
                                    setConfirmLoggedInUserRemove(undefined);
                                }}
                            />
                        )
                    }

                    {(submitting && (<h3>Saving workspace...</h3>))}

                    {(!submitting && (<form onSubmit={handleSubmission}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            {!!workspace && (
                                <label>{`Workspace Name: ${workspace.displayName}`}</label>
                            )}
                            {!workspace && (
                                <>
                                    <label htmlFor="workspaceName">Workspace Name:</label>
                                    <input
                                        onChange={e => !!onWorkspaceDataChange && onWorkspaceDataChange({ ...workspaceData, displayName: e.target.value })}
                                        name={'workspaceName'}
                                        type={'text'}
                                        autoFocus={true}
                                        placeholder={'Enter workspace name'}
                                        value={workspaceData?.displayName === undefined ? '' : workspaceData?.displayName}
                                        maxLength={maxDisplayNameLength}
                                    />                                   
                                    <label style={{ marginTop: '1em' }} htmlFor="engagementCode">Engagement Code:</label>
                                    <input
                                        onChange={e => !!onWorkspaceDataChange && onWorkspaceDataChange({ ...workspaceData, engagementCode: e.target.value })}
                                        name={'engagementCode'}
                                        type={'text'}
                                        autoFocus={true}
                                        placeholder={'Enter engagement code'}
                                        value={workspaceData?.engagementCode === undefined ? '' : workspaceData?.engagementCode}
                                        maxLength={maxEngagementCodeLength}
                                    />
                                    {!!errMsg && (
                                        <span
                                            className={'add-workspace-error-text'}
                                            style={{ marginTop: '10px' }}
                                        >
                                            {errMsg}
                                        </span>
                                    )}
                                </>
                            )}
                            {
                                !!allUsers && (
                                    <>
                                        <label style={{ marginTop: '1em' }}>Users with access:</label>
                                        <div
                                            className={`content-summary-container`}
                                        >
                                            <div className={`data-container`}>
                                                <UserAccessControl
                                                    includedUsers={includedUsers}
                                                    excludedUsers={excludedUsers}
                                                    allUsers={filteredUsers}
                                                    onUserStatusChange={onUserStatusChange}
                                                    onSelectAll={onSelectAll}
                                                    narrow
                                                    maxTableHeight={'300px'}
                                                    loggedInUser={loggedOnUser}
                                                    filterUsers={(filter) => {
                                                        if (!!allUsers) {
                                                            if (filter.length <= 0) {
                                                                setFilteredUsers(allUsers.slice());
                                                            } else {
                                                                setFilteredUsers(allUsers.filter(user =>
                                                                    FullUserName(user).toLowerCase().indexOf(filter.toLowerCase()) !== -1
                                                                ));
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        </div>
                    </form>))}
                </div>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="secondary"
                    onClick={() => onCancel()}
                    disabled={submitting}
                >
                    Cancel
                </Button>
                <Button
                    color="primary"
                    onClick={handleSubmission}
                    disabled={submitting || !requiredDataPresent()}
                >
                    Done
                </Button>
            </ModalFooter>
        </Modal>
    );
}
