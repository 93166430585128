import * as React from 'react';

import '../Main.scss';

import {
    Col,
    Container,
    Row
} from 'reactstrap';

import { LoadingIndicator } from '../LoadingIndicator/LoadingIndicator';

type Props = {
    msg: string;
    centerIndicator?: boolean;
}

export const LoadingMessageWithWaitIndicator: React.FC<Props> = (props): React.ReactElement => {
    const {
        centerIndicator,
        msg
    } = props;

    return (
        <Container fluid className={'sticky-message'}>
            <Row>
                <Col xs={'auto'}>
                    <LoadingIndicator active={true} centerIndicator={centerIndicator} />
                </Col>
                <Col className={'vcentered-content-col'}>
                    <h3 style={{ marginLeft: '15px' }}>{msg}</h3>
                </Col>
            </Row>
        </Container>
    )
}
