import { Reducer } from 'redux';

import { KnownAction, SubmissionDetailsActions } from './Actions';

import { SubmissionDetail, SubmissionSearchResult } from '../../Models/Api/strongbox.financialportal';
import { allRange, currentMonthRange, GetAllSubmissionHistoryRange, GetCurrentMonthSubmissionHistoryRange, SubmissionHistoryRange, SubmissionHistoryRangeSelectionType } from '../../Utils/SubmissionHistoryRange';

export const statusFilterComplete = 'completesubmissions';
export const statusFilterIncomplete = 'incompletesubmissions';
export const statusFilterDeleted = 'deletedsubmissions';

export const sourceFilterShareable = 'shareablelinkimports';
export const sourceFilterDirect = 'directimports';
export const sourceFilterAnonymous = 'anonymousimports';

export type SubmissionSearchResultWithDate = {
    ssr: SubmissionSearchResult;
    submissionCreationTime?: Date;
}

export type ActiveSubmissionDetails = {
    submissionSearchResult: SubmissionSearchResult;
    submissionDetails: SubmissionDetail;
};

export type SubmissionFilter = {
    prompt: string;
    on: boolean;
    id: string;
};

export type SubmissionHistoryType = 'workspace' | 'global';

type SubmissionHistoryParameters = {
    range: SubmissionHistoryRange;
    selectionType: SubmissionHistoryRangeSelectionType;
};

export type DownloadableFileState = {
    selected: boolean;
    fileId: string;
}

export type TemporarySubmissionState = {
    submissionId: string;
    downloadableFiles: DownloadableFileState[];
}

export interface ISubmissionHistoryState {
    details: ActiveSubmissionDetails[];
    currentSearchResults: SubmissionSearchResultWithDate[];
    workspaceIdOfCurrentSearchResults?: string;
    currentScrollPos: number;
    workspaceRangeParameters: SubmissionHistoryParameters;
    globalRangeParameters: SubmissionHistoryParameters;
    sortColumn: number;
    sortOrder: string;
    statusFilters: SubmissionFilter[];
    sourceFilters: SubmissionFilter[];
    temporarySubmissionState: TemporarySubmissionState[];
}

export const defaultActiveSubmissionState: ISubmissionHistoryState = {
    details: [],
    currentSearchResults: [],
    currentScrollPos: 0,
    workspaceRangeParameters: {
        range: GetAllSubmissionHistoryRange(),
        selectionType: allRange,
    },
    globalRangeParameters: {
        range: GetCurrentMonthSubmissionHistoryRange(),
        selectionType: currentMonthRange
    },
    sortColumn: 1,
    sortOrder: 'descending',
    statusFilters: [
        {
            prompt: 'Financial data available',
            id: statusFilterComplete,
            on: true
        },
        {
            prompt: 'Financial data not available',
            id: statusFilterIncomplete,
            on: true
        },
        {
            prompt: 'Deleted',
            id: statusFilterDeleted,
            on: false
        }
    ],
    sourceFilters: [
        {
            prompt: 'Secure url sent to data owner',
            id: sourceFilterShareable,
            on: true
        },
        {
            prompt: 'Connecting directly from the portal',
            id: sourceFilterDirect,
            on: true
        },
        {
            prompt: 'Submitted anonymously',
            id: sourceFilterAnonymous,
            on: true
        }
    ],
    temporarySubmissionState: [],
}

export function MapSubmissionSearchResult(ssr: SubmissionSearchResult): SubmissionSearchResultWithDate {
    return {
        ssr,
        submissionCreationTime: !!ssr.submission.creationTime ? new Date(ssr.submission.creationTime) : undefined
    };
}

const UpdateFilter = (filters: SubmissionFilter[], id: string, state: boolean): SubmissionFilter[] => {
    const iFilter = filters.findIndex(filter => filter.id === id);
    if (iFilter === -1) {
        return filters;
    }
    return filters.slice(0, iFilter).concat([{ ...filters[iFilter], on: state }]).concat(filters.slice(iFilter + 1));
}

export const reducer: Reducer<ISubmissionHistoryState, KnownAction> = (state: ISubmissionHistoryState | undefined, action: KnownAction): ISubmissionHistoryState => {
    let newState: ISubmissionHistoryState | undefined = undefined;

    switch (action.type) {
        case SubmissionDetailsActions.SetActiveSubmission:
            {
                if (!!action.submissionDetails) {
                    newState = {
                        ...((!!state && state) || defaultActiveSubmissionState),
                    }
                    newState.details = newState.details.slice();

                    const iExisting = newState.details.findIndex(ad => ad.submissionSearchResult.submission.id === action.submissionDetails!.submissionSearchResult.submission.id);
                    if (iExisting !== -1) {
                        newState.details[iExisting] = action.submissionDetails
                    } else {
                        newState.details.push(action.submissionDetails);
                    }
                }
                break;
            }
        case SubmissionDetailsActions.SetCurrentScrollPos:
            newState = {
                ...(state || defaultActiveSubmissionState),
                currentScrollPos: action.scrollPos,
            }
            break;
        case SubmissionDetailsActions.SetCurrentResults:
            newState = {
                ...(state || defaultActiveSubmissionState),
                currentSearchResults: action.results.slice(),
                workspaceIdOfCurrentSearchResults: action.workspaceId,
            }
            break;
        case SubmissionDetailsActions.SetCurrentRangeType:
            newState = {
                ...(state || defaultActiveSubmissionState),
            }
            switch (action.historyType) {
                case 'workspace':
                    newState!.workspaceRangeParameters = {
                        ...newState!.workspaceRangeParameters,
                        selectionType: action.rangeType,
                    }
                    break;
                case 'global':
                    newState!.globalRangeParameters = {
                        ...newState!.globalRangeParameters,
                        selectionType: action.rangeType,
                    }
                    break;
                default:
                    console.error(`invalid history type seen setting current range type ${action.historyType}`);
                    break;
            }
            break;
        case SubmissionDetailsActions.SetCurrentHistoryRange:
            newState = {
                ...(state || defaultActiveSubmissionState),
            }
            switch (action.historyType) {
                case 'workspace':
                    newState!.workspaceRangeParameters = {
                        ...newState!.workspaceRangeParameters,
                        range: action.currentRange
                    }
                    break;
                case 'global':
                    newState!.globalRangeParameters = {
                        ...newState!.globalRangeParameters,
                        range: action.currentRange
                    }
                    break;
                default:
                    console.error(`invalid history type seen setting current range ${action.historyType}`);
                    break;
            }
            break;
        case SubmissionDetailsActions.SetSortOrder:
            newState = {
                ...(state || defaultActiveSubmissionState),
                sortOrder: action.order,
            }
            break;
        case SubmissionDetailsActions.SetSortColumn:
            newState = {
                ...(state || defaultActiveSubmissionState),
                sortColumn: action.column,
            }
            break;
        case SubmissionDetailsActions.SetStatusFilter:
            newState = {
                ...(state || defaultActiveSubmissionState),
                statusFilters: UpdateFilter((state && state.statusFilters) || defaultActiveSubmissionState.statusFilters, action.id, action.state)
            }
            break;
        case SubmissionDetailsActions.SetSourceFilter:
            newState = {
                ...(state || defaultActiveSubmissionState),
                sourceFilters: UpdateFilter((state && state.sourceFilters) || defaultActiveSubmissionState.sourceFilters, action.id, action.state)
            }
            break;
        case SubmissionDetailsActions.UpdateWorkspaceNameInResults:
            newState = {
                ...(state || defaultActiveSubmissionState)
            }
            if (newState.workspaceIdOfCurrentSearchResults !== action.id) {
                newState = undefined;
            } else {
                newState.currentSearchResults = newState.currentSearchResults.map(sr => {
                    return {
                        ...sr,
                        ssr: {
                            ...sr.ssr,
                            entity: {
                                ...sr.ssr.entity,
                                displayName: action.newName
                            }
                        }
                    }
                })
            }
            break;
        case SubmissionDetailsActions.TempSubmissionDetailsStateClear:
            newState = {
                ...(state || defaultActiveSubmissionState)
            }
            newState.temporarySubmissionState = [];
            break;
        case SubmissionDetailsActions.TempSubmissionDetailsStateSetSelectedFiles: {
            if (!!state) {
                const iSubmissionDetails = state.temporarySubmissionState.findIndex(sub => sub.submissionId === action.submissionId);
                if (iSubmissionDetails !== -1) {
                    newState = {
                        ...state,
                        temporarySubmissionState: state.temporarySubmissionState.slice(),
                    }
                    if (!!action.preserveSelectionState) {
                        newState.temporarySubmissionState[iSubmissionDetails].downloadableFiles =
                            action.files.slice().map(file => {
                                const iExisting = state.temporarySubmissionState[iSubmissionDetails].downloadableFiles.findIndex(df => df.fileId === file.fileId);
                                return {
                                    ...file,
                                    selected: iExisting === -1 ? file.selected : state.temporarySubmissionState[iSubmissionDetails].downloadableFiles[iExisting].selected,
                                }
                            });
                    } else {
                        newState.temporarySubmissionState[iSubmissionDetails].downloadableFiles = action.files.slice();
                    }
                    if (!!action.preserveSelectionState) {
                        newState.temporarySubmissionState[iSubmissionDetails].downloadableFiles.forEach(file => {

                        });
                    }
                } else {
                    newState = {
                        ...state,
                        temporarySubmissionState: state.temporarySubmissionState.slice(),
                    }
                    newState.temporarySubmissionState.push({
                        submissionId: action.submissionId,
                        downloadableFiles: action.files.slice(),
                    });
                }
            }
            break;
        }
        case SubmissionDetailsActions.TempSubmissionDetailsStateToggleSelectedFile: {
            if (!!state) {
                const iSubmissionDetails = state.temporarySubmissionState.findIndex(sub => sub.submissionId === action.submissionId);
                if (iSubmissionDetails !== -1) {
                    const iFileIndex = state.temporarySubmissionState[iSubmissionDetails].downloadableFiles.findIndex(file => file.fileId === action.fileId);
                    if (iFileIndex !== -1) {
                        newState = {
                            ...state,
                            temporarySubmissionState: state.temporarySubmissionState.slice(),
                        }
                        newState.temporarySubmissionState[iSubmissionDetails].downloadableFiles =
                            state.temporarySubmissionState[iSubmissionDetails].downloadableFiles.slice();

                        newState.temporarySubmissionState[iSubmissionDetails].downloadableFiles[iFileIndex].selected =
                            !state.temporarySubmissionState[iSubmissionDetails].downloadableFiles[iFileIndex].selected;
                    }
                }
            }

            break;
        }
    }

    if (newState) {
        return newState;
    } else if (state) {
        return state;
    } else {
        let defaultCopy: ISubmissionHistoryState = {
            ...defaultActiveSubmissionState,
        };
        return defaultCopy;
    }
}
