import * as React from 'react';

import './SubmissionVerification.scss';

import { Submission } from '../../Models/Api/strongbox.financialportal';

type Props = {
    submission?: Submission;
    style?: any;
};

export const SubmissionVerification: React.FC<Props> = (props): React.ReactElement => {
    const {
        submission,
        style
    } = props;

    const verified = React.useMemo(() => {
        if (!submission) {
            return false;
        } else {
            return submission.isVerifiedContent;
        }

    }, [submission])

    return (
        <span
            className={`file-group-verification-status ${verified ? 'file-group-verified' : 'file-group-unverified'}`}
            style={style}
        >
            {verified ? 'Verified' : 'Unverified'}
        </span>
    )
}
