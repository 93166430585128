import { Reducer } from 'redux';

import {
    ImportFinancialsActions,
    KnownAction,
} from './Actions';

import { AccountingPackage } from '../../Models/Api/AccountingPackages';
import { PortalCreateSubmissionRequestOptions } from '../../Models/PortalCreateSubmissionRequestOptions';

import { StrongboxImportRequest } from '@finagraph/strongbox-finconnect-react';

export type DirectLinkParameters = {
    accountingPkg: AccountingPackage;
    connectionId?: string;
};

export type CustomerFinancialsTarget = {
    workspaceId: string;
    workspaceName: string;
};

export type ImportMap = {
    workspaceId: string;
    accountingPackage: AccountingPackage;
    importRequest: StrongboxImportRequest;
}

/**
 * LinkMode defines how the user (Lender, CPA, whoever is using portal) will link the customer's financials.
 * */

export enum LinkMode {
    anonymous,

    /**
     * @member {shareable} - The user wants to create a shareable link and transmit it to their customer.
     * */
    shareable,
    /**
     * @member {direct} - The user has credentials for the customer's accounting system and wants to connect directly 
     * using roughly the same experience the borrower would see.
     * */
    direct,
    /**
     * @member {excel} - The user wants to upload an excel file.
     * */
    excel,
};

export type ConnectionOptions = {
    workspaceId: string;
    workspaceName: string;
    linkMode: LinkMode;
    chooseAccountingSystem: boolean;
    options: PortalCreateSubmissionRequestOptions;
}

export interface IImportFinancialsState {
    showingImportCustomerFinancials: boolean;
    customerFinancialsTarget: CustomerFinancialsTarget | undefined;
    showingBorrowerParameters: boolean;
    importHasStarted: boolean;
    directLinkParameters?: DirectLinkParameters;
    connectionOptions?: ConnectionOptions;
    importMap: ImportMap[]
    retryImport?: ImportMap;
    borrowerFlowImportAccountingPkg?: string;
}

const defaultState: IImportFinancialsState = {
    showingImportCustomerFinancials: false,
    customerFinancialsTarget: undefined,
    showingBorrowerParameters: false,
    importHasStarted: false,
    directLinkParameters: undefined,
    connectionOptions: undefined,
    importMap: [],
}

export const reducer: Reducer<IImportFinancialsState, KnownAction> = (state: IImportFinancialsState | undefined, action: KnownAction): IImportFinancialsState => {
    let newState: IImportFinancialsState | undefined = undefined;

    switch (action.type) {
        case ImportFinancialsActions.SetImportCustomerFinancials: {
            // when importingDirect is undefined, the user can select either shareable link or direct mode.
            newState = {
                ...(state ? state : defaultState),
                showingImportCustomerFinancials: action.isActive,
                customerFinancialsTarget: action.target,
                directLinkParameters: action.importingDirect,
            };
            break;
        }
        case ImportFinancialsActions.ToggleImportCustomerFinancials: {
            newState = {
                ...(state ? state : defaultState),
            };
            newState.showingImportCustomerFinancials = !newState.showingImportCustomerFinancials;
            break;
        }
        case ImportFinancialsActions.SetBorrowerParameters: {
            newState = {
                ...(state ? state : defaultState),
                showingBorrowerParameters: action.isActive,
            };
            break;
        }
        case ImportFinancialsActions.ToggleBorrowerParameters: {
            newState = {
                ...(state ? state : defaultState),
            };
            newState.showingBorrowerParameters = !newState.showingBorrowerParameters;
            break;
        }
        case ImportFinancialsActions.ImportHasStarted: {
            newState = {
                ...(state ? state : defaultState),
                importHasStarted: true,
            }
            break;
        }
        case ImportFinancialsActions.SetConnectionOptions: {
            newState = {
                ...(state ? state : defaultState),
                connectionOptions: !!action.connectionOptions ?
                    { ...action.connectionOptions } : undefined,
            }
            break;
        }
        case ImportFinancialsActions.SaveImportParameters: {
            newState = {
                ...(state ? state : defaultState),
            };
            newState.importMap = newState.importMap.slice();
            const iExisting = newState.importMap.findIndex(
                mapEntry => (mapEntry.workspaceId === action.workspaceId && mapEntry.accountingPackage === action.accountingPackage)
            );
            if (iExisting === -1) {
                newState.importMap.push({
                    accountingPackage: action.accountingPackage,
                    workspaceId: action.workspaceId,
                    importRequest: action.options
                });
            } else {
                newState.importMap[iExisting].importRequest = action.options;
            }
            break;
        }
        case ImportFinancialsActions.SetRetryImportRequest: {
            newState = {
                ...(state ? state : defaultState),
                retryImport: action.importMap
            };
            break;
        }
        case ImportFinancialsActions.ClearRetryImportRequest: {
            newState = {
                ...(state ? state : defaultState),
                retryImport: undefined,
            };
            break;
        }
        case ImportFinancialsActions.SetBorrowerFlowAccountingPkg: {
            newState = {
                ...(state ? state : defaultState),
                borrowerFlowImportAccountingPkg: action.pkg.toLowerCase(),
            };
        }
    }

    if (newState) {
        return newState;
    } else if (state) {
        return state;
    } else {
        let defaultCopy: IImportFinancialsState = {
            ...defaultState,
        };
        return defaultCopy;
    }
}