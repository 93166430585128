import { Submission } from '../../Models/Api/strongbox.financialportal';

export enum SubmissionActions {
    LoadSubmission = 'SUBMISSION/LoadSubmission',
    LoadSubmissionComplete = 'SUBMISSION/LoadSubmissionComplete',
    UpdateSubmissionMetadata = 'SUBMISSION/UpdateMetadata',
}

export interface ILoadSubmission {
    type: SubmissionActions.LoadSubmission;
    workspaceId: string;
    submissionId: string;
    metadata?: Map<string, any>;
}

export interface ILoadSubmissionComplete {
    type: SubmissionActions.LoadSubmissionComplete;
    workspaceId: string;
    submissionId: string;
    msg?: string;
    exceptionMsg?: string;
    submission?: Submission;
    metadata?: Map<string, any>;
}

export interface IUpdateMetadata {
    type: SubmissionActions.UpdateSubmissionMetadata;
    workspaceId: string;
    submissionId: string;
    metadata?: Map<string, any>;
}

export type KnownAction =
    ILoadSubmission |
    ILoadSubmissionComplete |
    IUpdateMetadata;
