import { Reducer } from 'redux';

import { KnownAction, RunningImportsStateActions } from './Actions';

import { LogException } from '../../Utils/Logging';
import { FinancialImportStatus } from '../../Models/Api/strongbox.financialportal';

const runningImportsListKey = 'runningImportsState';
const dismissedJobsListKey = 'dismissedImportsState';

export type RunningImport = {
    submissionId: string;
    submissionRequestId: number;
    datasourceNameId: string;
    financialRecordId: string;
    workspaceId: string;
    workspaceName?: string;
    status?: FinancialImportStatus;
    connectionId?: string;
    hasShareableLink: boolean;
    canViewWorkspace: boolean;
    canManageWorkspaceUsers: boolean;
}

export type RunningImportsState = {
    imports: RunningImport[];
    version: number;
}

export type DismissedImport = {
    financialRecordId: string;
    workspaceId: string;
}

export type DismissedImportsState = {
    imports: DismissedImport[];
    version: number;
}

export type IRunningImportsState = {
    runningImports: RunningImportsState;
    dismissedImports: DismissedImportsState;
};


const defaultState: IRunningImportsState = {
    runningImports: {
        imports: [],
        version: 1,
    },
    dismissedImports: {
        imports: [],
        version: 1,
    }
};

const dismissAJob = (state: IRunningImportsState, financialRecordId: string, workspaceId: string): void => {
    const iJob = state.runningImports.imports.findIndex(job => (job.financialRecordId === financialRecordId) && (job.workspaceId === workspaceId));
    if (iJob !== -1) {
        state.runningImports.imports = state.runningImports.imports.slice(0, iJob).concat(state.runningImports.imports.slice(iJob + 1));
        state.runningImports.version += 1;
    }
    // These two checks could probably be part of the same operation, i.e. if the record is in the dismissed list than
    // it SHOULD NOT be in the list of running jobs (state.jobs) so this is guarding against error.
    const iDismissed = state.dismissedImports.imports.findIndex(dismissed => (dismissed.financialRecordId === financialRecordId) && (dismissed.workspaceId === workspaceId));
    if (iDismissed === -1) {
        state.dismissedImports.imports.push({
            financialRecordId: financialRecordId,
            workspaceId: workspaceId,
        });
        state.dismissedImports.version += 1;
    }
}

export const reducer: Reducer<IRunningImportsState, KnownAction> = (state: IRunningImportsState | undefined, action: KnownAction): IRunningImportsState => {
    let newState: IRunningImportsState | undefined = undefined;

    switch (action.type) {
        case RunningImportsStateActions.DismissJob: {
            newState = GetRunningJobsFromBrowserStorage() || defaultState;

            dismissAJob(newState, action.financialRecordId, action.workspaceId);

            break;
        }

        case RunningImportsStateActions.DismissMultipleJobs: {
            newState = GetRunningJobsFromBrowserStorage() || defaultState;

            action.jobs.forEach(job => {
                dismissAJob(newState!, job.financialRecordId, job.workspaceId);
            });

            break;
        }

        case RunningImportsStateActions.UpdateJobStatus: {
            newState = GetRunningJobsFromBrowserStorage() || defaultState;

            action.updates.forEach(update => {
                const iJob = newState!.runningImports.imports.findIndex(job => (job.workspaceId === update.workspaceId) && (job.financialRecordId === update.financialRecordId));
                if (iJob !== -1) {
                    newState!.runningImports.imports[iJob].status = update.status;
                }
            });
            newState.runningImports.version += 1;

            break;
        }

        case RunningImportsStateActions.ListPendingJobs:
            // Nothing really to do here yet.  Provided for completeness
            break;
        case RunningImportsStateActions.ListPendingJobsComplete:
            newState = GetRunningJobsFromBrowserStorage() || defaultState;

            // There could have been an error retrieving pending submissions. In that case
            // pendingSubmissionResults would be undefined.

            if (!!action.pendingSubmissionResults) {
                newState.runningImports.version = newState.runningImports.version + 1;
                action.pendingSubmissionResults.forEach((submission, i) => {
                    // First see if it's been dismissed
                    const iDismissed = newState!.dismissedImports.imports.findIndex(dismissed => (dismissed.financialRecordId === submission.financialRecordId) && (dismissed.workspaceId === submission.entityId));
                    if (iDismissed === -1) {
                        // Try to find the submission.  If it isn't there then add it otherwise update it with current 
                        // information.  It's possible it changed.
                        const iJob = newState!.runningImports.imports.findIndex(existing => (existing.financialRecordId === submission.financialRecordId) && (existing.workspaceId === submission.entityId));
                        let jobStatus: FinancialImportStatus = {
                            outcome: 'Pending',
                        };
                        if (submission.financialRecordStatus === 'Created') {
                            jobStatus.outcome = 'Success';
                        } else if (submission.financialRecordStatus === 'ImportError') {
                            jobStatus.outcome = 'Error';
                        }

                        if (iJob === -1) {
                            newState!.runningImports.imports.push({
                                submissionId: submission.submissionId,
                                datasourceNameId: submission.datasourceNameId || '',
                                financialRecordId: submission.financialRecordId!,
                                workspaceId: submission.entityId,
                                status: jobStatus,
                                workspaceName: submission.entityDisplayName,
                                canViewWorkspace: !!submission.loggedInUserCanViewWorkspace,
                                canManageWorkspaceUsers: !!submission.loggedInUserCanManageWorkspaceUsers,
                                hasShareableLink: !!submission.hasShareableLink,
                                submissionRequestId: !!submission.submissionRequestId ? submission.submissionRequestId : 0,
                            });
                        } else {
                            newState!.runningImports.imports[iJob] = {
                                ...newState!.runningImports.imports[iJob],
                                datasourceNameId: submission.datasourceNameId || '',
                                status: jobStatus
                            };
                        }
                    } else {
                        // We found it in the list of dismissed jobs, get rid of it.
                        const iJob = newState!.runningImports.imports.findIndex(job => (job.financialRecordId === submission.financialRecordId) && (job.workspaceId === submission.entityId));
                        if (iJob !== -1) {
                            newState!.runningImports.imports = newState!.runningImports.imports.slice(0, iJob).concat(newState!.runningImports.imports.slice(iJob + 1));
                        }
                    }
                });

                // If all are not being requested, filter out results belonging to submissions to which the user
                // does not actually have access. We keep results in local session storage because results the user
                // may have received would go out of the pending jobs list but should still be shown since they 
                // may have been watching the progress.

                if (!action.returnAll) {
                    newState.runningImports.imports = newState.runningImports.imports.filter(job => job.canViewWorkspace);
                }

                // Housekeeping: keep the list of dismissed jbos from going infinitely. Check the dismissed jobs against 
                // the list of pending jobs.If there are jobs in dismissed that aren't in the list of pending jobs, remove 
                // them, they no longer need to be included in the list of dismissed jobs.

                const newDismissedImports: DismissedImportsState = {
                    imports: [],
                    version: newState.dismissedImports.version + 1,
                }

                // Stated as the converse of the above, we go through each import in dismissed imports.  If we find it in the list
                // of pending results, we add it to a revised list of dismissed imports thereby removing any that are NOT in the
                // current list of pending results.
                newState.dismissedImports.imports.forEach(di => {
                    if (!!action.pendingSubmissionResults!.find(ps => ps.financialRecordId === (di.financialRecordId) && (ps.entityId === di.workspaceId))) {
                        newDismissedImports.imports.push(di);
                    }
                });

                newState.dismissedImports = newDismissedImports;
            }

            break;

        case RunningImportsStateActions.UpdateLoggedInUserAccess:
            newState = GetRunningJobsFromBrowserStorage() || defaultState;

            newState.runningImports.imports.forEach(job => {
                if (job.workspaceId === action.workspaceId) {
                    job.canViewWorkspace = action.hasAccess;
                }
            });
            newState.runningImports.version += 1;

            break;
    }

    // Eventually the call to list pending jobs will see jobs complete and no longer show up. For that reason, we don't rely strictly on
    // the results of that call.  To maintain jobs the user saw but may disappear from redux on a refresh, we keep a list in local
    // session storage so that if they are refreshing without ending their session the list will be maintained.

    if (newState) {
        const runningImportsString = JSON.stringify(newState.runningImports);
        const dismissedImportsString = JSON.stringify(newState.dismissedImports);

        window.sessionStorage.setItem(runningImportsListKey, runningImportsString);
        window.localStorage.setItem(dismissedJobsListKey, dismissedImportsString);
    }

    if (newState) {
        return newState;
    } else if (state) {
        return state;
    } else {
        let defaultCopy: IRunningImportsState = {
            ...defaultState,
        };
        return defaultCopy;
    }
}

function GetRunningJobsFromBrowserStorage(): IRunningImportsState | undefined {
    try {
        let runningImports: RunningImportsState = {
            imports: [],
            version: 1,
        }
        let dismissedImports: DismissedImportsState = {
            imports: [],
            version: 1,
        }

        const runningImportsString = window.sessionStorage.getItem(runningImportsListKey);
        if (runningImportsString !== null) {
            runningImports = JSON.parse(runningImportsString);
        }

        const dismissedImportsString = window.localStorage.getItem(dismissedJobsListKey);
        if (dismissedImportsString !== null) {
            dismissedImports = JSON.parse(dismissedImportsString);
        }

        const result = {
            runningImports,
            dismissedImports,
        };

        return result;
    } catch (exception) {
        const msg = 'Exception thrown retrieving running imports from browser Session storage';
        LogException(msg, exception);
        console.error(msg);
        return undefined;
    }
}
