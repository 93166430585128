import * as React from 'react';
import { connect } from 'react-redux';

import '../Main.scss';

import { PostLoginUserResponse } from '../../Models/Api/strongbox.financialportal';

import { ApplicationState } from '../../Store';
import { GetLoggedInUser } from '../../Store/User';
import { GetTenantName } from '../../Store/Tenant';

type InjectedReduxState = {
    loggedInUser?: PostLoginUserResponse;
    tenantStyleClass: string;
}

type InjectedActionCreators = {};

type LoggedInUserProps = {
};

type Props = LoggedInUserProps & InjectedActionCreators & InjectedReduxState;

export const LoggedInUserComponent: React.FC<Props> = (props): React.ReactElement => {
    const userDisplayName = React.useMemo(() => {
        if (!props.loggedInUser) {
            return '';
        }
        if (!!props.loggedInUser.displayName) {
            return props.loggedInUser.displayName;
        }
        if (!!(props.loggedInUser.firstName || props.loggedInUser.lastName)) {
            return `${props.loggedInUser.firstName ? props.loggedInUser.firstName + '' : ''}${props.loggedInUser.lastName || ''}`;
        }
        return props.loggedInUser.emailAddress;
    }, [props.loggedInUser]);

    if (!props.loggedInUser) {
        return (<></>);
    }

    return (
        <div className={'header-user-name'}>
            <span>{userDisplayName}</span>
        </div>
    );
}

export const LoggedInUser = connect<InjectedReduxState, InjectedActionCreators, LoggedInUserProps, ApplicationState>(
    (appState: ApplicationState) => {
        const result = {
            loggedInUser: GetLoggedInUser(appState),
            tenantStyleClass: GetTenantName(appState),
        };

        return result;
    }
)(LoggedInUserComponent);
