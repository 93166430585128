import { PortalCreateSubmissionRequestOptions } from '../../Models/PortalCreateSubmissionRequestOptions';
import { AppCookieData } from '../../Models/CookieData';
import { TenantAttributedParagraphSet } from '../Tenant';

export enum NewDataCollectionParametersActions {
    SetOptions = 'NDCP/SetOptions',
    Initialize = 'NDCP/Initialize',
    BeginInitializeFromURL = 'NDCP/BeginInitializeFromURL',
    EndInitializeFromURL = 'NDCP/EndInitializeFromURL',
    InitializeCollectionGroupings = 'NDCP/InitializeCollectionGroupings',
}

export interface ISetOptionsAction {
    readonly type: NewDataCollectionParametersActions.SetOptions;
    readonly options: PortalCreateSubmissionRequestOptions;
    readonly fiscalYearsToCollect: number;
}

export interface IInitialize {
    readonly type: NewDataCollectionParametersActions.Initialize;
    readonly appData: AppCookieData;
}

export interface IInitializeCollectionGroupings {
    readonly type: NewDataCollectionParametersActions.InitializeCollectionGroupings;
    readonly collectionGroupDescriptions: TenantAttributedParagraphSet;
    readonly dynamicTenantSelectionEnabled: boolean;
}

export interface IBeginInitializeFromURL {
    readonly type: NewDataCollectionParametersActions.BeginInitializeFromURL;
    readonly url: string;
}

// If there is an error retrieving options, optionsId should be set to -1.

export interface IEndInitializeFromURL {
    readonly type: NewDataCollectionParametersActions.EndInitializeFromURL;
    readonly url: string;
    readonly optionsId: number | undefined;
    readonly options: PortalCreateSubmissionRequestOptions | undefined;
    readonly initializationException?: any;
}

export type KnownAction =
    ISetOptionsAction |
    IInitialize |
    IInitializeCollectionGroupings |
    IBeginInitializeFromURL |
    IEndInitializeFromURL;
