import * as React from 'react';
import {
    Col,
    Row,
} from 'reactstrap';

import { BasicUserErrorMessages, BasicUserDetails } from './BasicUserDetails';
import { UserRoleSelection } from './UserRoleSelection';

import {
    CreateUserRequest,
    RoleResponse,
    ResourceRole,
} from '../../../Models/Api/strongbox.financialportal';
import { PermissionsPopoverTarget } from './PermissionsPopover';

export type CollectUserInfoPermissionsProps = {
    availableRoles: RoleResponse[];
    currentRoles: ResourceRole[];
    permissionsPopoverTarget?: PermissionsPopoverTarget;
    showDescriptionPopover: (role: RoleResponse, target: string) => void;
    onRoleChanged: (role: RoleResponse, checked: boolean) => void;
}

type Props = {
    user: CreateUserRequest;
    errorMessages?: BasicUserErrorMessages;
    onUpdateUser: (user: CreateUserRequest) => void;
    permissionProps?: CollectUserInfoPermissionsProps;
    emailDomainsForTenant: string[];
    updateUserProps: (newUserInfo: CreateUserRequest) => void;
    emailWellFormed: boolean;
    emailErrorMessage: boolean;
    focusRef: any;
}

export const EditRequiredUserInfo: React.FC<Props> = (props): React.ReactElement => {
    const {
        emailDomainsForTenant,
        errorMessages,
        permissionProps,
        user,
        updateUserProps,
        emailWellFormed,
        emailErrorMessage,
        focusRef,
    } = props;

    return (
        <React.Fragment>
            <Row className={'std-form-row'}>
                <h4>Enter information for new users</h4>
            </Row>
            <Row>
                <Col>
                    <BasicUserDetails
                        errorMessages={errorMessages}
                        user={{
                            firstName: user.firstName,
                            lastName: user.lastName,
                            emailAddress: user.emailAddress,
                        }}
                        onChange={(newValue) => {
                            updateUserProps({
                                ...user,
                                lastName: newValue.lastName,
                                firstName: newValue.firstName,
                                emailAddress: newValue.emailAddress,
                            })
                        }}
                        focusRef={focusRef}
                        stackLabels={true}
                        emailDomainsForTenant={emailDomainsForTenant}
                        showEmailDomainError={emailErrorMessage}
                        emailValid={emailWellFormed && !emailErrorMessage}
                    />
                </Col>
                {!!permissionProps && (
                    <Col>
                        <UserRoleSelection
                            availableRoles={permissionProps.availableRoles}
                            currentRoles={permissionProps.currentRoles}
                            disabled={false}
                            disableDescriptions={!!permissionProps.permissionsPopoverTarget}
                            showDescriptionPopover={permissionProps.showDescriptionPopover}
                            onChanged={permissionProps.onRoleChanged}
                        />
                    </Col>
                )}
            </Row>
        </React.Fragment>
    );
}
