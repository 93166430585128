import * as React from 'react';

import {
    Container,
    Col,
    Row,
    Table,
    Input
} from 'reactstrap';

import '../Main.scss';

import { UserResponse } from '../../Models/Api/strongbox.financialportal';
import { IndeterminateStates, IndeterminateCheckBox } from '../IndeterminateCheckBox/IndeterminateCheckBox';

import { FullUserName } from '../../Utils/UserUtils';

import { darkGray } from '../../Utils/Constants';

/**
 * @member {narrow} if provided sets a narrower width in the user name column for
 * use in a smaller area like a modal.  Default will be treated as false.
 * @member {lockedOwner} identifies an owner of the list that you want to show 
 * in the list of people available to be added to the workspace but cannot be toggled,
 * i.e. there's a checkbox but it's disabled.  If not provided, this does not happen.  If
 * it is provided and the user is also in either of the other lists, they will only
 * be shown locked.
 * @member {lockedOwnerSuffix} will be appended to the locked owner name in the list.  If this is
 * not provided the default value '(logged in user)' is used.
 * @member {loggedInUser} identifies a user in the list want to show
 * in the list of people available to be added to the workspace but deserves special consideration
 * If it is provided and the user is also in either of the other lists, they will be shown with
 * loggedInUserSuffix if provided
 * @member {loggedInUserSuffix} will be appended to the logged in user name in the list.  If this is
 * not provided the default value '(logged in user)' is used.
 * */

type Props = {
    includedUsers: UserResponse[];
    excludedUsers: UserResponse[];
    allUsers: UserResponse[];
    narrow?: boolean;
    style?: any;
    onUserStatusChange?: (user: UserResponse, include: boolean) => void;
    onSelectAll?: (selected: boolean) => void;
    lockedOwner?: UserResponse;
    lockedOwnerSuffix?: string;
    loggedInUser?: UserResponse;
    loggedInUserSuffix?: string;
    maxTableHeight?: string;
    filterUsers: (filter: string) => void;
}

export const UserAccessControl: React.FC<Props> = (props): React.ReactElement => {
    const {
        lockedOwner,
        lockedOwnerSuffix,
        loggedInUser,
        loggedInUserSuffix,
        narrow,
        excludedUsers,
        includedUsers,
        allUsers,
        style,
        onUserStatusChange,
        onSelectAll,
        maxTableHeight,
        filterUsers
    } = props;

    const [allUsersSorted, setAllUsersSorted] = React.useState<UserResponse[]>([]);

    const [globalSelectType, setGlobalSelectType] = React.useState<IndeterminateStates>('unchecked');
    const [searchValue, setSearchValue] = React.useState<string>('');

    const compareUserNames = (user1: UserResponse, user2: UserResponse): number => {
        // first is a sanity check, this should never happen.
        if ((user1.id === loggedInUser?.id) && (user2.id === loggedInUser?.id)) {
            return 0;
        }
        if (user1.id === loggedInUser?.id) {
            return -1;
        }
        if (user2.id === loggedInUser?.id) {
            return 1;
        }
        const fullUserName1 = FullUserName(user1).toLowerCase();
        const fullUserName2 = FullUserName(user2).toLowerCase();

        return fullUserName1 === fullUserName2 ? 0 : fullUserName1 < fullUserName2 ? -1 : 1;
    }

    React.useEffect(() => {
        const removeLockedOwner = (users: UserResponse[]): UserResponse[] => {
            if (!lockedOwner) {
                return users;
            }
            const iLocked = users.findIndex(user => user.id === lockedOwner.id);
            if (iLocked === -1) {
                return users;
            }
            return users.slice(0, iLocked).concat(users.slice(iLocked + 1));
        }

        setAllUsersSorted(removeLockedOwner(allUsers).sort(compareUserNames));
        // no need to include compareUserNames
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [allUsers, lockedOwner]);

    // Effect for debouncing the search field
    React.useEffect(() => {
        const idTimer = setTimeout(() => {
            filterUsers(searchValue);
        }, 1000);
        return () => { clearTimeout(idTimer); }
        // no need to include filterUsers
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [searchValue]);

    const nameColumnWidth = React.useMemo(() => {
        return !!narrow ? '60%' : '85%';
    }, [narrow])

    const displayName = (user: UserResponse): string => {
        let name = FullUserName(user);
        if (!!loggedInUser && loggedInUser.id === user.id) {
            name += !!loggedInUserSuffix ? loggedInUserSuffix : ' (logged in user)';
        }
        return name;
    }

    let tableRowStyle: any = { marginTop: '1em' };
    if (maxTableHeight !== undefined) {
        tableRowStyle = {
            ...tableRowStyle,
            maxHeight: maxTableHeight,
            overflowY: 'scroll',
            border: `1px solid ${darkGray}`
        }
    }

    React.useEffect(() => {
        let globalCheckState: IndeterminateStates = 'indeterminate';

        if (excludedUsers.length === 0) {
            globalCheckState = 'checked';
        } else if (includedUsers.length === 0) {
            globalCheckState = 'unchecked';
        }

        setGlobalSelectType(globalCheckState);
    }, [includedUsers, excludedUsers]);

    return (
        <Container
            fluid
            style={style}
        >
            <Row className={'portal-header-region'}>
                <Col xs={12}>
                    <Input
                        id={'userSearchField'}
                        name={'userSearchField'}
                        key={'userSearchField'}
                        type={'text'}
                        placeholder={'Search users by name...'}
                        value={searchValue}
                        onChange={event => setSearchValue(event.currentTarget.value)}
                    />
                </Col>
            </Row>
            {includedUsers.length <= 0 && (
                <Row>
                    <Col>
                        <h2 className={'error-text'}>At least one user must have access to the workspace.</h2>
                    </Col>
                </Row>
            )}
            <Row style={tableRowStyle}>
                <Col>
                    <Table className={'workspace-user-list'}>
                        <thead className={'no-header-borders'}>
                            <tr className={'column-headers'} key={'0000'}>
                                <th className={'centered slim'}>
                                    {!!onSelectAll && (
                                        <IndeterminateCheckBox
                                            status={globalSelectType}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                onSelectAll(event.currentTarget.checked);
                                                if (event.currentTarget.checked) {
                                                    setGlobalSelectType('checked');
                                                } else {
                                                    setGlobalSelectType('unchecked');
                                                }
                                            }}
                                            className={'form-check-input'}
                                        />
                                    )}
                                    {!onSelectAll && 'Has access'}
                                </th>
                                <th className={'slim'} style={{ width: nameColumnWidth }}>Users</th>
                            </tr>
                        </thead>
                        <tbody className={'centered-header no-row-borders'}>
                            {!!lockedOwner && (
                                <tr
                                    key={`lockedOwner57`}
                                    id={`lockedOwner57`}
                                >
                                    <th className={'workspace-user-list-cell'}>
                                        <Input
                                            type={'checkbox'}
                                            checked={true}
                                            disabled
                                        />
                                    </th>
                                    <td className={'workspace-user-list-cell'}>{`${displayName(lockedOwner)} ${lockedOwnerSuffix || '(logged in user)'}`}</td>
                                </tr>
                            )}
                            {
                                allUsersSorted.map((user, index) => {
                                    const itemChecked = !!includedUsers.find(included => included.id === user.id);

                                    return (
                                        <tr
                                            key={`user${index.toString()}`}
                                            id={`user${index.toString()}`}
                                        >
                                            <th className={'workspace-user-list-cell'}>
                                                <Input
                                                    type={'checkbox'}
                                                    className={'large'}
                                                    checked={itemChecked}
                                                    onChange={(event) => {
                                                        onUserStatusChange && onUserStatusChange(user, event.currentTarget.checked);
                                                    }}
                                                />
                                            </th>
                                            <td
                                                className={'clickable-item workspace-user-list-cell'}
                                                onClick={() => {
                                                    onUserStatusChange && onUserStatusChange(user, !itemChecked);
                                                }}
                                            >
                                                {displayName(user)}
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    )
}
