import '../Main.scss';

import * as React from 'react';
import { connect } from 'react-redux';

import { ApplicationState } from '../../Store';
import { GetDefaultSubmissionOptions } from '../../Store/AppSettings';
import {
    actionCreators as ImportFinancialsActionCreators,
    GetBorrowerParametersActive,
} from '../../Store/ImportFinancials';
import {
    GetRequestedOptions,
    GetRequestedOptionsId,
} from '../../Store/NewDataCollectionParameters';
import {
    BrandConfig,
    GetBrandConfig,
    GetBasicSettings,
    ResourceName,
    RetrieveResource,
    TenantAttributedParagraphSet,
} from '../../Store/Tenant';

import { AccountingPkgPresentation } from '../SelectAccountingSystem/AccountingPkgPresentation';
import { BorrowerParametersModal } from './BorrowerParametersModal';
import { PortalCreateSubmissionRequestOptions } from '../../Models/PortalCreateSubmissionRequestOptions';
import { AccountingCollectionGroup } from '../../Models/AccountingCollectionGroup';

type InjectedReduxState = {
    brandConfig: BrandConfig;
    tenantVisibleName: string;
    active: boolean;
    parameters: PortalCreateSubmissionRequestOptions | undefined;
    parametersId: number | undefined;
    reportsList: TenantAttributedParagraphSet;
    securityBullets: TenantAttributedParagraphSet;
};

type InjectedActionCreators = typeof ImportFinancialsActionCreators;

type BorrowerParametersContainerProps = {
    accountingPkg?: AccountingPkgPresentation;
    dismissModal: (cancelled: boolean) => void;
};

type Props = BorrowerParametersContainerProps  & InjectedActionCreators & InjectedReduxState;

const BorrowerParametersContainerComponent: React.FC<Props> = (props): React.ReactElement => {
    const {
        parameters,
        reportsList
    } = props;

    const reportListParagraphs = React.useMemo(() => {
        const result: string[] = [];

        if (!(reportsList && reportsList.paragraphs)) {
            return result;
        }

        reportsList.paragraphs.forEach(pg => {
            let content: string | undefined = pg.content;

            const requiresAttr = pg.attributes.find(attr => attr.name === 'requires-group');

            if (!!requiresAttr) {
                const ag = parameters?.accountingCollectionGroups.get(requiresAttr.value);
                if (!(ag && ag.collect)) {
                    content = undefined;
                }
            }
            if (!!content) {
                result.push(content);
            }
        });

        return result;
    }, [parameters, reportsList])

    if (!(props.active && parameters)) {
        return (<></>);
    }

    return (
        <BorrowerParametersModal
            lenderName={props.tenantVisibleName}
            dismissModal={props.dismissModal}
            parameters={parameters}
            brandConfig={props.brandConfig}
            reportsList={reportListParagraphs}
            accountingPkg={props.accountingPkg}
            securityBullets={props.securityBullets}
        />
    );
}

export const BorrowerParametersContainer = connect<InjectedReduxState, InjectedActionCreators, BorrowerParametersContainerProps, ApplicationState>(
    (appState: ApplicationState) => {
        const basicSettings = GetBasicSettings(appState);

        const result: InjectedReduxState = {
            active: GetBorrowerParametersActive(appState),
            tenantVisibleName: (basicSettings && basicSettings.visibleName) || '',
            parameters: GetRequestedOptions(appState),
            parametersId: GetRequestedOptionsId(appState),
            brandConfig: GetBrandConfig(appState),
            reportsList: RetrieveResource(appState, ResourceName.tenantReportList),
            securityBullets: RetrieveResource(appState, ResourceName.tenantBorrowerParametersBullets),
        };

        if (!result.parameters) {
            const defaultAnonymous = GetDefaultSubmissionOptions(appState, 'shareableanonymous', true);

            result.parameters = {
                basisOfAccountingPreference: defaultAnonymous.basisOfAccountingPreference,
                mostRecentPeriodMonth: defaultAnonymous.mostRecentPeriodMonth,
                mostRecentPeriodYear: defaultAnonymous.mostRecentPeriodYear,
                mostRecentPeriodDay: defaultAnonymous.mostRecentPeriodDay,
                fullFiscalYearsToCollect: defaultAnonymous.fullFiscalYearsToCollect,
                anonymizeCustomersAndVendors: defaultAnonymous.anonymizeCustomersAndVendors,
                provideUserCopy: defaultAnonymous.provideUserCopy,
                allowUserUpload: defaultAnonymous.allowUserUpload,
                hasShareableLink: false,
                accountingCollectionGroups: new Map<string, AccountingCollectionGroup>(defaultAnonymous.accountingCollectionGroups),
                endOfImport: defaultAnonymous.endOfImport,
            }
        }

        return result;
    },
    {
        ...ImportFinancialsActionCreators
    }
)(BorrowerParametersContainerComponent);
