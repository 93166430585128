import '../Main.scss';

import * as React from 'react';

import {
    Col,
    Input,
    Label,
    Row
} from 'reactstrap';

import { NewDataCollectionContentGrouping } from '../../Store/NewDataCollectionParameters';
import { TenantAttributedParagraphSet } from '../../Store/Tenant';

type Props = {
    collectionContentGroups: NewDataCollectionContentGrouping[];
    onContentEnablementChange: (key: string, enabled: boolean) => void;
    additionalMessaging?: TenantAttributedParagraphSet;
}

export const SelectImportDataItems: React.FC<Props> = (props): React.ReactElement => {
    const {
        additionalMessaging,
        collectionContentGroups,
        onContentEnablementChange,
    } = props;

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <p>Select the accounting data you want to include in the data collection.</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <table className={'data-items-table'}>
                        <tbody>
                            {collectionContentGroups.map((group) => {
                                return !group.collectionGroup.hidden && (
                                    <tr key={`select-import-data-item-${group.collectionGroup.id}`}>
                                        <td className={'selection-cell'}>
                                            <Input
                                                type="checkbox"
                                                name={group.collectionGroup.id}
                                                id={group.collectionGroup.id}
                                                key={group.collectionGroup.id}
                                                checked={group.selected}
                                                onChange={(e) => onContentEnablementChange(group.collectionGroup.id, e.currentTarget.checked)}
                                                disabled={!!group.collectionGroup.readOnly}
                                            />
                                            <Label for={group.collectionGroup.id}>{group.collectionGroup.name}</Label>
                                        </td>
                                        <td className={'description-cell'}>
                                            {
                                                group.description.map((pg, pgIndex) => {
                                                    let style: React.CSSProperties = {};
                                                    if (pg.bold) {
                                                        style['fontWeight'] = 'bold';
                                                    }
                                                    if (pg.italic) {
                                                        style['fontStyle'] = 'italic';
                                                    }
                                                    return (
                                                        <p
                                                            key={`content-paragraph-${group.collectionGroup.id}-${pgIndex}`}
                                                            style={style}
                                                        >
                                                            {pg.description}
                                                        </p>
                                                    );
                                                })
                                            }
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </Col>
            </Row>
            {!!additionalMessaging && (
                <Row className={'extra-row-spacing-small'}>
                    <Col>
                        {additionalMessaging.paragraphs.map((p, i) => {
                            let style: React.CSSProperties = {};
                            if (!!p.attributes.find(a => a.name === 'bold')) {
                                style['fontWeight'] = 'bold';
                            }
                            return (
                                <p style={style} key={`additional-messaging-content-${i}`}>{p.content}</p>
                            )
                        })}
                    </Col>
                </Row>
            )}
        </React.Fragment>
    );
}

