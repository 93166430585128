import * as React from 'react';

import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';

import MenuIcon from '@mui/icons-material/Menu';

import '../Main.scss';

type Props = {
    logout?: () => void;
    settings?: () => void;
    dataCollectionReport?: () => void;
    notificationSettings?: () => void;
    canAccessSettings: boolean;
    canAccessGlobalSubmissions: boolean;
};

export const FinPortalDropdown: React.FC<Props> = (props): React.ReactElement => {
    const [dropdownOpen, setDropdownOpen] = React.useState<boolean>(false);

    return (
        <Dropdown
            className={'portal-options-dropdown'}
            isOpen={dropdownOpen}
            toggle={() => {
                setDropdownOpen(!dropdownOpen);
            }}
        >
            <DropdownToggle>
                <MenuIcon />
            </DropdownToggle>
            <DropdownMenu
                className={'portal-options-dropdown-menu'}
                end
            >
                {
                    props.canAccessSettings && (
                        <>
                            <DropdownItem
                                onClick={() => { props.settings && props.settings() }}
                            >
                                User management
                            </DropdownItem>
                        </>
                    )
                }
                {
                    props.canAccessGlobalSubmissions && (
                        <>
                            <DropdownItem
                                onClick={() => { props.dataCollectionReport && props.dataCollectionReport() }}
                            >
                                Data collection report
                            </DropdownItem>
                        </>
                    )
                }
                {
                    (props.canAccessGlobalSubmissions || props.canAccessSettings) && (
                        <DropdownItem
                            className={'portal-options-dropdown-divider'}
                            divider
                        />
                    )
                }
                <DropdownItem
                    onClick={() => { props.notificationSettings && props.notificationSettings() }}
                >
                    Notification settings
                </DropdownItem>
                <DropdownItem
                    onClick={() => { props.logout && props.logout() }}
                >
                    Logout
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
}
