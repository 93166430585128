import { StrongboxImportRequest } from '@finagraph/strongbox-finconnect-react';
import { AccountingPackage } from '../../Models/Api/AccountingPackages';

import { ApplicationState } from '../index';
import {
    ConnectionOptions,
    CustomerFinancialsTarget,
    DirectLinkParameters,
    ImportMap,
} from './Reducer';

export function GetImportCustomerFinancialsActive(state: ApplicationState): boolean {
    return !!(state && state.importFinancials && state.importFinancials.showingImportCustomerFinancials);
}

export function GetImportCustomerFinancialsTarget(state: ApplicationState): CustomerFinancialsTarget | undefined {
    return state && state.importFinancials && state.importFinancials.customerFinancialsTarget;
}

export function GetBorrowerParametersActive(state: ApplicationState): boolean {
    return !!(state && state.importFinancials && state.importFinancials.showingBorrowerParameters);
}

export function ImportHasStarted(state: ApplicationState): boolean {
    return !!(state && state.importFinancials && state.importFinancials.importHasStarted);
}

export function GetImportingDirect(state: ApplicationState): DirectLinkParameters | undefined {
    return state && state.importFinancials && state.importFinancials.directLinkParameters;
}

export function GetConnectionOptions(state: ApplicationState): ConnectionOptions | undefined {
    const result = state && state.importFinancials && state.importFinancials.connectionOptions;
    return result;
}

export function GetImportData(state: ApplicationState, workspaceId: string, accountingPackage: AccountingPackage): StrongboxImportRequest | undefined {
    if (!(state && state.importFinancials)) {
        return undefined;
    }
    const result = state.importFinancials.importMap.find(
        importRecord => (importRecord.workspaceId === workspaceId && importRecord.accountingPackage === accountingPackage)
    );
    return result && result.importRequest;
}

export function GetImportMap(state: ApplicationState): ImportMap[] {
    if (!(state && state.importFinancials)) {
        return [];
    }
    return state.importFinancials.importMap;
}

export function GetImportRetry(state: ApplicationState): ImportMap | undefined {
    return state && state.importFinancials && state.importFinancials.retryImport;
}

export function GetBorrowerFlowAccountingPkg(state: ApplicationState): string | undefined {
    return state && state.importFinancials && state.importFinancials.borrowerFlowImportAccountingPkg;
}