import { FetchMethod, fetchWrapper } from '../Utils/FetchWrapper';

import {
    ConnectionsList
} from '../Models/Api/strongbox.financialportal';

import { LogMessage, LogException, SeverityLevel } from '../Utils/Logging';

export async function ListWorkspaceConnections(workspaceId: string): Promise<ConnectionsList> {
    LogMessage(
        'ListWorkspaceConnections',
        SeverityLevel.Information,
        {
            workspaceId
        }
    );

    const url = `/api/workspaces/${workspaceId}/connections`;

    try {
        const res = await fetchWrapper(url,
            {
                method: FetchMethod.Get,
            },
            true,
            true
        );

        if (res.ok) {
            let result: ConnectionsList = await res.json();

            return result;
        }

        throw new Error(`Unable to list connections for workspace ${workspaceId} - ${res.statusText}`);

    } catch (exception) {
        LogException(
            'ListWorkspaceConnections failed',
            exception,
            {
                workspaceId
            }
        );

        console.error('Exception listing connections in WorkspaceConnectionsService');
        console.error(exception);

        throw exception;
    }
}

export async function DeleteWorkspaceConnection(workspaceId: string, connectionId: string): Promise<void> {
    LogMessage(
        'DeleteWorkspaceConnection',
        SeverityLevel.Information,
        {
            workspaceId,
            connectionId
        }
    );

    const url = `/api/workspaces/${workspaceId}/connections/${connectionId}`;

    try {
        const res = await fetchWrapper(url,
            {
                method: FetchMethod.Delete,
            },
            true,
            true
        );

        if (!res.ok) {
            throw new Error(`Unable to delete connection for workspace ${workspaceId} connection: ${connectionId} - ${res.statusText}`);
        }

    } catch (exception) {
        LogException(
            'DeleteWorkspaceConnection failed',
            exception,
            {
                workspaceId,
                connectionId
            }
        );

        console.error('Exception deleting connection in WorkspaceConnectionsService');
        console.error(exception);

        throw exception;
    }
}
