import * as React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import './Main.scss';
import ReactInlineSvg from 'react-inlinesvg';

import StrongboxLogo from '../images/StrongboxLogo.svg';

import { idStrongboxPortalHeaderWindow } from '../Utils/Constants';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';

import { FinPortalDropdown } from './FinPortalDropdown/FinPortalDropdown';
import { LoggedInUser } from './LoggedInUser/LoggedInUser';
import { ApplicationState } from '../Store';
import { GetBasicSettings, GetTenantName } from '../Store/Tenant';
import { UserCanNavigateHome } from '../Store/UIState';
import { GetAuth0Id } from '../Store/User';
import { LoggedInUserHasAccess } from '../Store/User';
import { assetBlobContainer, pathConstants } from '../Utils/Constants';

type InjectedReduxState = {
    showStrongboxLogo: boolean;
    tenantName: string;
    auth0Id: string;
    canAccessUserManagement: boolean;
    canAccessGlobalSubmissions: boolean;
    canNavigateHome: boolean;
    visiblePortalName: string;
};

type InjectedActionCreators = {
};

type PortalHeaderProps = {
    hideMenu?: boolean;
    logout?: () => void;
    settings?: () => void;
    dataCollectionReport?: () => void;
    notificationSettings?: () => void;
    showLoggedInUser?: boolean;
    content?: React.ReactElement;
    borrowerFlow?: boolean;
    containingStyle?: any;
    blockNavigateHome?: boolean;
};

type Props = InjectedReduxState & InjectedActionCreators & PortalHeaderProps;

export const PortalHeaderComponent: React.FC<Props> = (props): React.ReactElement => {
    const {
        borrowerFlow,
        canAccessUserManagement,
        canAccessGlobalSubmissions,
        canNavigateHome,
        content,
        hideMenu,
        logout,
        settings,
        dataCollectionReport,
        notificationSettings,
        showLoggedInUser,
        showStrongboxLogo,
        tenantName,
        visiblePortalName,
    } = props;

    const logoStyle = {
        'marginLeft': '30px',
    };

    const rightDivClass = `portal-header-region control-region control-region-lender`;

    const navigate = useNavigate();

    return (
        <header>
            <Container
                fluid className={`banner-div ${!!borrowerFlow ? 'banner-div-borrower' : 'banner-div-lender'}`}
                id={idStrongboxPortalHeaderWindow}
            >
                <Row className={`spaced-row ${!!borrowerFlow ? 'banner-row-borrower' : 'banner-row'}`}>
                    {!!content && (
                        <Col className={'full-height-column top-content'} xs={6} md={7} lg={8}>
                            {content}
                        </Col>)
                    }
                    <Col className={`full-height-column ${!!borrowerFlow ? 'right-aligned-col right-fading-column' : ''}`}>
                        <ReactInlineSvg
                            className={`${canNavigateHome ? 'navigable' : ''} top-content banner-image`}
                            src={`${assetBlobContainer}/brandassets/${tenantName}/Banner.svg`}
                            title={'home'}
                            onClick={() => { canNavigateHome && navigate(pathConstants.home) }}
                            aria-label={visiblePortalName}
                        />
                    </Col>

                    {!content && (
                        <Col className={`full-height-column ${rightDivClass}`}>
                            <Row className={'header-right-contents'}>
                                {(showLoggedInUser === true) && (<Col><LoggedInUser /></Col>)}
                                {(hideMenu !== true) && (
                                    <Col>
                                        <FinPortalDropdown
                                            canAccessSettings={canAccessUserManagement}
                                            canAccessGlobalSubmissions={canAccessGlobalSubmissions}
                                            logout={logout}
                                            settings={settings}
                                            dataCollectionReport={dataCollectionReport}
                                            notificationSettings={notificationSettings}
                                        />
                                    </Col>
                                )}
                                {showStrongboxLogo && (
                                    <Col>
                                        <ReactInlineSvg
                                            className={`${canNavigateHome ? 'navigable' : ''}`}
                                            style={logoStyle}
                                            src={StrongboxLogo}
                                            title={'home'}
                                            onClick={() => { canNavigateHome && navigate(pathConstants.home) }}
                                            aria-label={'Strongbox Portal Logo'}
                                        />
                                    </Col>

                                )}
                            </Row>
                        </Col>
                    )}
                </Row>
            </Container>
        </header>
    );
}

export const PortalHeader = connect<InjectedReduxState, InjectedActionCreators, PortalHeaderProps, ApplicationState>(
    (appState: ApplicationState, props: PortalHeaderProps) => {
        const basicSettings = GetBasicSettings(appState);

        const result = {
            showStrongboxLogo: !!(basicSettings && basicSettings.showStrongboxLogo),
            tenantName: GetTenantName(appState),
            auth0Id: GetAuth0Id(appState),
            canAccessUserManagement: LoggedInUserHasAccess(appState, 'CanManageUsers'),
            canNavigateHome: !!props.blockNavigateHome ? false : UserCanNavigateHome(appState),
            canAccessGlobalSubmissions: LoggedInUserHasAccess(appState, 'CanViewGlobalSubmissions'),
            visiblePortalName: basicSettings?.visibleName || 'Strongbox Portal',
        };

        return result;
    }
)(PortalHeaderComponent);

