import * as React from 'react';

import {
    Row,
    Col
} from 'reactstrap';

import { FSModels } from '@finagraph/financial-statement-editor';

import { FinancialStatementLineItemsTable, LineItemSelectionTracking } from './FinancialStatementLineItemsTable';

import { FinancialStatementRenderData } from './UtilTypes';

type Props = {
    financialStatement: FSModels.FinancialStatementViewModel;
    statementType: FSModels.FinancialStatementType;
    stylesBelowEdit: boolean;
    onUpdateCaptionIndent: (lineItem: FSModels.FinancialStatementLineItemViewModel, newIndent: number) => void;
    containerProps: React.CSSProperties;
    onShowInfo: (lineItem: FSModels.FinancialStatementLineItemViewModel) => void;
    onMultiSelect: (lineItem: FSModels.FinancialStatementLineItemViewModel, selected: boolean) => void;
    onHoverMultiSelect: (lineItem: FSModels.FinancialStatementLineItemViewModel, entering: boolean) => void;
    renderData: FinancialStatementRenderData;
    onTempLock: (lineItem: FSModels.FinancialStatementLineItemViewModel) => void;
    onDecrementLineItemSortOrder: (lineItem: FSModels.FinancialStatementLineItemViewModel) => void;
    onIncrementLineItemSortOrder: (lineItem: FSModels.FinancialStatementLineItemViewModel) => void;
    onMoveLineItemToNewSection: (lineItem: FSModels.FinancialStatementLineItemViewModel) => void;
    onAddSubsection: (lineItem: FSModels.FinancialStatementLineItemViewModel | undefined) => void;
    onEditLineItem: (lineItem: FSModels.FinancialStatementLineItemViewModel, targetId: string) => void;
    onFilterLineItems: () => void;
    onToggleReportedAs: (sectionId: string, updatedValue: FSModels.CreditOrDebit) => void;
    filterLineItemsDropdownActive: boolean;
    lockedLineItem: FSModels.FinancialStatementLineItemViewModel | undefined;
    onLineItemMoreOptions: (lineItem: FSModels.FinancialStatementLineItemViewModel, targetId: string) => void;
    onEditNumberFormatting: (targetId: string) => void;
    onSortAlpha: () => void;
    onSortAccountNo: () => void;
    onSortByAccountingPeriod: (accountingPeriodId: string) => void;
    numberFormattingDropped: boolean;
    toggleColumnOrdering: () => void;
    excelNumberFormatters: Map<string, (n: number) => string>;
    selectedLineItems: Map<string, LineItemSelectionTracking> | undefined;
    onClearLineItemSelections: () => void;
}

export const FinancialStatementTable: React.FC<Props> = (props): React.ReactElement => {
    const {
        financialStatement,
        statementType,
        onUpdateCaptionIndent,
        containerProps,
        onShowInfo,
        onMultiSelect,
        onTempLock,
        onDecrementLineItemSortOrder,
        onIncrementLineItemSortOrder,       
        onMoveLineItemToNewSection,
        renderData,
        onAddSubsection,
        onEditLineItem,
        onFilterLineItems,
        filterLineItemsDropdownActive,
        lockedLineItem,
        onLineItemMoreOptions,
        onEditNumberFormatting,
        onSortAccountNo,
        onSortAlpha,
        onSortByAccountingPeriod,
        onToggleReportedAs,
        numberFormattingDropped,
        toggleColumnOrdering,
        excelNumberFormatters,
        selectedLineItems,
        onHoverMultiSelect,
        onClearLineItemSelections,
    } = props;

    const containerId = React.useMemo(() => {
        return `financial-statement-table-container-${statementType}`;
    }, [statementType]);

    return (
        <div
            className={'financial-statement-table-container'}
            id={containerId}
            style={containerProps}
        >
            <Row className={'g-0'}>
                <Col>
                    <FinancialStatementLineItemsTable
                        financialStatement={financialStatement}
                        statementType={statementType}
                        pxPerIndentLevel={10}
                        captionPadding={15}
                        stylesBelowEdit={props.stylesBelowEdit}
                        onUpdateIndent={onUpdateCaptionIndent}
                        onShowInfo={onShowInfo}
                        onMultiSelect={onMultiSelect}
                        onHoverMultiSelect={onHoverMultiSelect}
                        onTempLock={onTempLock}
                        onDecrementLineItemSortOrder={onDecrementLineItemSortOrder}
                        onIncrementLineItemSortOrder={onIncrementLineItemSortOrder}
                        onMoveLineItemToNewSection={onMoveLineItemToNewSection}
                        onAddSubsection={onAddSubsection}
                        renderData={renderData}
                        onEditLineItem={onEditLineItem}
                        onFilterLineItems={onFilterLineItems}
                        filterDropdownActive={filterLineItemsDropdownActive}
                        lockedLineItem={lockedLineItem}
                        onLineItemMoreOptions={onLineItemMoreOptions}
                        onSortAccountNo={onSortAccountNo}
                        onSortAlpha={onSortAlpha}
                        onSortByAccountingPeriod={onSortByAccountingPeriod}
                        onToggleReportedAs={onToggleReportedAs}
                        toggleColumnOrdering={toggleColumnOrdering}
                        onEditNumberFormatting={onEditNumberFormatting}
                        numberFormattingDropped={numberFormattingDropped}
                        excelNumberFormatters={excelNumberFormatters}
                        tableContainerId={containerId}
                        selectedLineItems={selectedLineItems}
                        onClearLineItemSelections={onClearLineItemSelections}
                    />
                </Col>
            </Row>
        </div>
    )
}
