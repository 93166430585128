import {
    FinancialImportStatus,
    PendingSubmissionResult,
} from '../../Models/Api/strongbox.financialportal';

export enum RunningImportsStateActions {
    UpdateJobStatus = 'RUNNINGIMPORTSTATE/UpdateJobStatus',
    DismissJob = 'RUNNINGIMPORTSTATE/DismissJob',
    DismissMultipleJobs = 'RUNNINGIMPORTSTATE/DismissMultipleJobs',
    ListPendingJobs = 'RUNNINGIMPORTSTATE/ListPendingJobs',
    ListPendingJobsComplete = 'RUNNINGIMPORTSTATE/ListPendingJobsComplete',
    UpdateLoggedInUserAccess = 'RUNNINGIMPORTSTATE/UpdateUserAccess',
}

export type JobUpdateRecord = {
    readonly status: FinancialImportStatus | undefined;
    readonly workspaceId: string;
    readonly financialRecordId: string;
};

export interface IUpdateJobStatus {
    readonly type: RunningImportsStateActions.UpdateJobStatus;
    readonly updates: JobUpdateRecord[];
}

export interface IDismissJob {
    readonly type: RunningImportsStateActions.DismissJob;
    readonly financialRecordId: string;
    readonly workspaceId: string;
}

export interface IDismissMultipleJobs {
    readonly type: RunningImportsStateActions.DismissMultipleJobs;
    readonly jobs: {
        financialRecordId: string,
        workspaceId: string,
    }[];
}

export interface IListPendingJobs {
    readonly type: RunningImportsStateActions.ListPendingJobs;
    readonly returnAll: boolean;
}

export interface IListPendingJobsComplete {
    readonly type: RunningImportsStateActions.ListPendingJobsComplete;
    readonly pendingSubmissionResults?: PendingSubmissionResult[];
    readonly returnAll: boolean;
}

export interface IUpdateLoggedInUserAccess {
    type: RunningImportsStateActions.UpdateLoggedInUserAccess;
    hasAccess: boolean;
    workspaceId: string;
}

export type KnownAction =
    IUpdateJobStatus |
    IDismissJob |
    IDismissMultipleJobs |
    IListPendingJobs | 
    IListPendingJobsComplete | 
    IUpdateLoggedInUserAccess;
