import { FetchError, FetchMethod, fetchWrapper } from '../Utils/FetchWrapper';

import {
    CreateUserRequest,
    DetailedUserResponse,
    RoleResponse,
    UpdateUserRoleRequest,
    UserResponse,
} from '../Models/Api/strongbox.financialportal';

import { LogMessage, LogException, SeverityLevel } from '../Utils/Logging';

export async function ListUsers(noAlphaSort?: boolean): Promise<UserResponse[]> {
    LogMessage(
        'ListUsers',
        SeverityLevel.Information,
        {
            noAlphaSort
        }
    );

    const url = `/api/users`;

    try {
        const res = await fetchWrapper(url,
            {
                method: FetchMethod.Get,
            },
            true,
            true
        );

        if (res.ok) {
            let resultList: UserResponse[] = await res.json();

            if (noAlphaSort !== false) {
                resultList = resultList.sort((r1, r2) => {
                    const name1 = !!r1.displayName ? r1.displayName : !!r1.lastName ? r1.lastName : '';
                    const name2 = !!r2.displayName ? r2.displayName : !!r2.lastName ? r2.lastName : '';
                    return name1.localeCompare(name2, undefined, { sensitivity: 'accent' });
                });
            }
            return resultList;
        }

        LogException(
            'ListUsers failed',
            new Error(res.statusText),
            {
                noAlphaSort
            }
        );

        throw new Error(`Unable to list users - ${res.statusText}`);

    } catch (exception) {
        LogException(
            'ListUsers failed',
            exception,
            {
                noAlphaSort
            }
        );

        console.error('Exception listing users in UserService');
        console.error(exception);

        throw exception;
    }
}

export async function ListDetailedUsers(noAlphaSort?: boolean): Promise<DetailedUserResponse[]> {
    LogMessage(
        'ListDetailedUsers',
        SeverityLevel.Information,
        {
            noAlphaSort
        }
    );

    const url = `/api/detailedUsers`;

    try {
        const res = await fetchWrapper(url,
            {
                method: FetchMethod.Get,
            },
            true,
            true
        );

        if (res.ok) {
            let resultList: DetailedUserResponse[] = await res.json();

            if (noAlphaSort !== false) {
                resultList = resultList.sort((r1, r2) => {
                    const name1 = !!r1.displayName ? r1.displayName : !!r1.lastName ? r1.lastName : '';
                    const name2 = !!r2.displayName ? r2.displayName : !!r2.lastName ? r2.lastName : '';
                    return name1.localeCompare(name2, undefined, { sensitivity: 'accent' });
                });
            }
            return resultList;
        }

        LogException(
            'ListDetailedUsers failed',
            new Error(res.statusText),
            {
                noAlphaSort
            }
        );

        throw new Error(`Unable to list detailed users - ${res.statusText}`);

    } catch (exception) {
        LogException(
            'ListDetailedUsers failed',
            exception,
            {
                noAlphaSort
            }
        );

        console.error('Exception listing detailed users in UserService');
        console.error(exception);

        throw exception;
    }
}

/**
 * Delete a user
 * 
 * @param userId id of the user to be deleted
 * @returns true if the user is successfully deleted
 */

export async function DeleteUser(userId: string): Promise<boolean> {
    LogMessage(
        'DeleteUser',
        SeverityLevel.Information,
        {
            userId
        }
    );

    const url = `/api/users/${userId}`;

    try {
        const res = await fetchWrapper(url,
            {
                method: FetchMethod.Delete,
            },
            true,
            true
        );
        if (res.ok) {
            LogException(
                'DeleteUser failed',
                new Error(res.statusText),
                {
                    userId
                }
            );
        }

        return res.ok;
    } catch (exception) {
        LogException(
            'DeleteUser failed',
            exception,
            {
                userId
            }
        );

        console.error(`Exception deleting user ${userId} UserService`);
        console.error(exception);

        return false;
    }
}

/**
 * Update editable fields for a user.  These include firstName, lastName and resourceRoles.
 * 
 * @param user contains the information to update. user.id must be valid
 * @returns the updated user value if successful or undefined if a failure occurs.
 */

export async function UpdateUser(user: DetailedUserResponse, updateRoles: boolean): Promise<DetailedUserResponse | undefined> {
    LogMessage(
        'UpdateUser',
        SeverityLevel.Information,
        {
            id: user.id,
            externalId: user.externalAccountId
        }
    );

    let url = `/api/users/${user.id}`;

    if (!(user.resourceRoles && user.resourceRoles.length > 0)) {
        LogException(
            'UpdateUser failed',
            new Error('resource roles cannot be undfined or empty'),
            {
                id: user.id,
                externalId: user.externalAccountId
            }
        );

        console.error('UserService: UpdateUser - resourceRoles cannot be undefined or empty');
        return undefined;
    }

    let patches = [
        { 'op': 'replace', 'path': '/firstName', 'value': user.firstName },
        { 'op': 'replace', 'path': '/lastName', 'value': user.lastName },
        { 'op': 'replace', 'path': '/metadata', 'value': user.metadata }
    ];

    try {
        const res = await fetchWrapper(url,
            {
                method: FetchMethod.Patch,
                body: JSON.stringify(patches),
            },
            true,
            true,
            undefined,
            true
        );

        if (!res.ok) {
            LogException(
                'UpdateUser failed',
                new Error(res.statusText),
                {
                    id: user.id,
                    externalId: user.externalAccountId
                }
            );

            return undefined;
        }
        if (!updateRoles) {
            return await res.json();
        }

        // Now update roles for the user.

        url = url + '/roles';

        // Already checked that resourceRoles is defined and greater than 0 length
        const updateRoleRequest: UpdateUserRoleRequest = {
            roleIds: user.resourceRoles.map(role => role.roleId),
        }

        const updateResult = await fetchWrapper(url,
            {
                method: FetchMethod.Put,
                body: JSON.stringify(updateRoleRequest),
            },
            true,
            true
        );

        if (!updateResult.ok) {
            LogException(
                'UpdateUser failed',
                new Error(res.statusText),
                {
                    id: user.id,
                    externalId: user.externalAccountId
                }
            );
            return undefined;
        }

        return await updateResult.json();

    } catch (exception) {
        LogException(
            'UpdateUser failed',
            exception,
            {
                id: user.id,
                externalId: user.externalAccountId
            }
        );

        console.error('caught error in UserService: UpdateUser');
        console.error(exception);

        return undefined;
    }
}

/**
 * @member {boolean} ok - was this user created successfully.
 * @member {string} emailAddress - the email of the user that was added or failed. Will be set regardless.
 * @member {string} errorMsg - if ok is false, this will be a string describing what went wrong.
 * @member {UserResponse} user - if the user was creatd successfully, the user response.
 * */

export type CreateUserResult = {
    ok: boolean;
    emailAddress: string;
    errorMsg?: string;
    user?: DetailedUserResponse;
}

/**
 * Create 1 or more users in the SB database and generate invitations for them (via auth0)
 * 
 * @param users Array of users to be added
 * @result A result for each user.  It is possible for a user to be created and for the invitation
 * to fail so it's possible an entry in the array could have ok be false and have a valid user.  The
 * converse (ok is true and user is valid) should never happen.
 */

export async function CreateAndInviteUsers(
    users: CreateUserRequest[],
    defaultRole?: RoleResponse,
): Promise<CreateUserResult[]> {
    LogMessage(
        'CreateAndInviteUsers',
        SeverityLevel.Information,
        {
            userCount: users.length
        }
    );

    try {
        const url = `/api/users`;

        let createResult: CreateUserResult[] = []

        const handleCreateResult = (ok: boolean, emailAddress: string, user: DetailedUserResponse | undefined, errorMsg: string | undefined): void => {
            const newResult: CreateUserResult = {
                ok,
                emailAddress,
                errorMsg,
                user,
            };

            createResult = createResult.concat([newResult]);
        }

        // Use a for loop because await functions are only allowed at the top level, i.e.
        // not in a foreach handler.

        for (var userIndex = 0; userIndex < users.length; userIndex++) {
            const user = users[userIndex];

            const defaultRoleId = !!defaultRole ? defaultRole.id : '';

            const userRequest: CreateUserRequest = {
                displayName: `${user.firstName} ${user.lastName}`,
                firstName: user.firstName,
                lastName: user.lastName,
                emailAddress: user.emailAddress,
                roleIds: !!user.roleIds ? user.roleIds : [defaultRoleId],
                metadata: user.metadata,
            }

            try {
                const res = await fetchWrapper(url,
                    {
                        method: FetchMethod.Post,
                        body: JSON.stringify(userRequest),
                    },
                    true,
                    true
                );

                let addedUser: DetailedUserResponse | undefined = undefined;

                if (res.ok) {
                    addedUser = await res.json();
                }

                handleCreateResult(res.ok, user.emailAddress, addedUser, `Failed creating user - ${res.statusText}`);
            } catch (error) {
                LogException(
                    'CreateAndInviteUsers failed',
                    error
                );

                console.error('caught error in UserService creating user: CreateAndInviteUsers');
                console.error(error);

                const fe: FetchError = error as FetchError;

                // 409 means the user was already created
                if (fe.status === 409) {
                    handleCreateResult(false, user.emailAddress, undefined, `A user with this email address ${user.emailAddress} has already been created`);
                } else if (!!fe.errorMessage) {
                    handleCreateResult(false, user.emailAddress, undefined, `Failed creating user - ${fe.errorMessage}`);
                } else {
                    handleCreateResult(false, user.emailAddress, undefined, `Failed creating user`);
                }
            }
        };

        // At this point, createResult has an entry for every entry in the users parameter.
        // Some of those could be errors, i.e. ok is false and/or user is undefined, though
        // it shouldn't actually be possible for ok to be true and user undefined.
        //
        // So at this point we can iterate through users and invite those that were successfully
        // created.
        let finalResult: CreateUserResult[] = []

        const handleInviteResult = (ok: boolean, emailAddress: string, user: DetailedUserResponse | undefined, errorMsg: string | undefined): void => {
            const newResult: CreateUserResult = {
                ok,
                emailAddress,
                errorMsg,
                user,
            };

            finalResult = finalResult.concat([newResult]);
        }

        for (let userIndex = 0; userIndex < createResult.length; userIndex++) {
            const createdUser = createResult[userIndex];

            // Process those entries successfully created.  Put already failed entries
            // into the list immediately and continue.
            if (!(createdUser.ok && !!createdUser.user)) {
                finalResult = finalResult.concat([createdUser]);
            } else {
                const inviteUrl = `api/users/${createdUser.user.id}/invite`;

                try {
                    const res = await fetchWrapper(inviteUrl,
                        {
                            method: FetchMethod.Post,
                        },
                        true,
                        true
                    );

                    if (res.ok) {
                        handleInviteResult(true, createdUser.emailAddress, createdUser.user, undefined);
                    } else {
                        LogException(
                            'CreateAndInviteUsers failed',
                            new Error(res.statusText)
                        );

                        handleInviteResult(
                            false,
                            createdUser.emailAddress,
                            createdUser.user,
                            `User was created but could not be invited, please try to reinvite later ${!!res.statusText && ` - ${res.statusText}`}`
                        );
                    }
                } catch (error) {
                    LogException(
                        'CreateAndInviteUsers failed',
                        error
                    );

                    console.error('caught error in UserService inviting user: CreateAndInviteUsers');

                    handleInviteResult(
                        false,
                        createdUser.emailAddress,
                        createdUser.user,
                        `User was created but could not be invited, please try to reinvite later`
                    );
                }
            }
        }

        return finalResult;
    } catch (exception) {
        LogException(
            'CreateAndInviteUsers failed',
            exception
        );

        throw exception;
    }
}

/**
 * Reinvite an existing user
 * 
 * @param userId id of the user to send the invitation to.
 * @result success or failure of the operation. True on success
 */

export async function ReinviteUser(userId: string): Promise<boolean> {
    LogMessage(
        'ReinviteUser',
        SeverityLevel.Information,
        {
            userId
        }
    );

    const inviteUrl = `api/users/${userId}/invite`;

    try {
        const res = await fetchWrapper(inviteUrl,
            {
                method: FetchMethod.Post,
            },
            true,
            true
        );
        if (!res.ok) {
            LogException(
                'ReinviteUser failed',
                new Error(res.statusText),
                {
                    userId
                }
            );
        }

        return res.ok;
    } catch (error) {
        LogException(
            'ReinviteUser failed',
            error,
            {
                userId
            }
        );

        console.error('caught error in UserService: ReinviteUser');
        console.error(error);

        return false;
    }
}
