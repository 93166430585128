import { EntitySafeDetailResponse } from '../../Models/Api/strongbox.financialportal';

export enum BorrowerActions {
    BorrowerSetId = 'Borrower/SET_ID',
    BorrowerSetSubmissionId = 'Borrower/SET_SUBMISSION_ID',
    BorrowerAnonymousState = 'Borrower/ANONYMOUS_STATE',
    BorrowerSetAcceptedBusinessInfo = 'Borrower/SET_ACCEPTED_BUSINESS_INFO',
}

export interface ISetBorrowerId {
    readonly type: BorrowerActions.BorrowerSetId;
    readonly details?: EntitySafeDetailResponse;
    readonly id: string;
}

export interface ISetBorrowerSubmissionId {
    readonly type: BorrowerActions.BorrowerSetSubmissionId;
    readonly id: string;
}

export interface ISetBorrowerAnonymousState {
    readonly type: BorrowerActions.BorrowerAnonymousState;
    readonly anonymous: boolean;
}

export interface ISetAcceptedBusinessInfo {
    type: BorrowerActions.BorrowerSetAcceptedBusinessInfo;
    businessName: string;
    businessEmail: string;
}

export type KnownAction =
    ISetBorrowerId |
    ISetBorrowerSubmissionId |
    ISetBorrowerAnonymousState |
    ISetAcceptedBusinessInfo;
