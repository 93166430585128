import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

import {
    Row,
    Col,
} from 'reactstrap';

import {
    ResourceName,
    RetrieveResource,
    TenantParagraphSet,
} from '../../Store/Tenant';

import { ApplicationState } from '../../Store';
import { ExtractUrlParameters } from '../../Utils/PathUtils';

import { LoadingIndicator } from '../LoadingIndicator/LoadingIndicator';

import { BorrowerPortalChildProps } from './BorrowerPortal';

type InjectedReduxState = {
    downloadText: TenantParagraphSet;
    notExistingText: TenantParagraphSet;
}

type InjectedActionCreators = {
};

type Props = BorrowerPortalChildProps & InjectedActionCreators & InjectedReduxState;

const DownloadUserCopyComponent: React.FC<Props> = (props): React.ReactElement => {
    const {
        downloadText,
        notExistingText
    } = props;

    const location = useLocation();

    const [borrowerId, setBorrowerId] = React.useState<string | undefined>(undefined);
    const [financialRecordId, setFinancialRecordId] = React.useState<string | undefined>(undefined);

    const [paragraphs, setParagraphs] = React.useState<React.ReactElement[]>([]);
    const [fileValidated, setFileValidated] = React.useState<boolean | undefined>(undefined);

    const preValidateCustomerDataDownloadAsync = async (url: string) => {
        try {
            let response = await fetch(url);
            return response.status !== 404;
        } catch (error) {
            console.error(error);
            return false;
        }

    }

    React.useEffect(() => {
        ExtractUrlParameters(
            location.search,
            (key: string, value: string): void => {
                switch (key.toLowerCase()) {
                    case 'eid':
                        setBorrowerId(unescape(value));
                        break;
                    case 'frid':
                        setFinancialRecordId(unescape(value));
                        break;
                }
            }
        );
        // I want this to execute equivalent to componentDidMount so this is appropriate
        // eslint-disable-next-line react-hooks/exhaustive-deps       
    }, []);

    React.useEffect(() => {
        const newParagraphs: React.ReactElement[] = [];

        if (downloadText && !!fileValidated) {
            downloadText.paragraphs.forEach((paragraph) => {
                newParagraphs.push((
                    <h2 key={Math.random()} style={{ marginTop: '20px' }}>{paragraph}</h2>
                ));
            });
        }

        if (notExistingText && fileValidated === false) {
            notExistingText.paragraphs.forEach((paragraph) => {
                newParagraphs.push((
                    <h2 key={Math.random()} style={{ marginTop: '20px' }}>{paragraph}</h2>
                ));
            });
        }

        setParagraphs(newParagraphs);

        // fileValidated is the only relevant property that needs to be looked at here
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileValidated]);

    React.useEffect(() => {
        if (borrowerId && financialRecordId) {
            preValidateCustomerDataDownloadAsync(`/api/${borrowerId}/financialrecords/${financialRecordId}/customerdata`)
                .then(result => {
                    setFileValidated(result);
                });
        }
    }, [borrowerId, financialRecordId])

    return (
        <div className={`control-region control-region-lender`}>
            <LoadingIndicator active={!financialRecordId || !borrowerId || fileValidated === undefined} />
            <Row className={'borrower-content-row'}>
                <Col>
                    {paragraphs}
                </Col>
            </Row>
            <Row>
                <Col className={'centered-col'}>

                    {(financialRecordId && borrowerId && fileValidated !== undefined) && (
                        <a
                            style={{ color: 'transparent' }}
                            download
                            href={`/api/${borrowerId}/financialrecords/${financialRecordId}/customerdata`}
                        >
                            <button style={{ marginTop: '25px' }} disabled={!fileValidated}>Download Now</button>
                        </a>
                    )}
                </Col>
            </Row>
        </div>
    );
}

export const DownloadUserCopy = connect<InjectedReduxState, InjectedActionCreators, BorrowerPortalChildProps, ApplicationState>(
    (appState: ApplicationState) => {
        return {
            downloadText: RetrieveResource(appState, ResourceName.tenantDownloadUserCopy),
            notExistingText: RetrieveResource(appState, ResourceName.tenantDownloadNotExisting)
        };
    }
)(DownloadUserCopyComponent);
