import {
    GlobalUserSettings
} from './Reducer';

import {
    EditUserNotificationSettings,
    ListWorkspaceNotificationSubscriptions,
    AdministrativeTenantNotificationSettings,
    NotificationMethod,
    AdministrativeNotificationSetting,
    NotificationDefaultRecipientAction,
} from '../../Models/Api/strongbox.financialportal';

export enum UserSettingsActions {
    LoadGlobalUserSettings = 'USERSETTINGS/LoadGlobalUserSettings',
    LoadGlobalUserSettingsComplete = 'USERSETTINGS/LoadGlobalUserSettingsComplete',
    UpdateGlobalSetting = 'USERSETTINGS/UpdateGlobalSetting',
    UpdateGlobalSettingComplete = 'USERSETTINGS/UpdateGlobalSettingComplete',
    LoadWorkspaceUserSettings = 'USERSETTINGS/LoadWorkspaceUserSettings',
    LoadWorkspaceUserSettingsComplete = 'USERSETTINGS/LoadWorkspaceUserSettingsComplete',
    UpdateWorkspaceUserSetting = 'USERSETTINGS/UpdateWorkspaceUserSetting',
    UpdateWorkspaceUserSettingComplete = 'USERSETTINGS/UpdateWorkspaceUserSettingComplete',
    BatchUpdateWorkspaceUserSetting = 'USERSETTINGS/BatchUpdateWorkspaceUserSetting',
    BatchUpdateWorkspaceUserSettingComplete = 'USERSETTINGS/BatchUpdateWorkspaceUserSettingComplete',
    LoadTenantAdminSettings = 'USERSETTINGS/LoadTenantAdminSettings',
    LoadTenantAdminSettingsComplete = 'USERSETTINGS/LoadTenantAdminSettingsComplete',
    AddTenantAdminNotificationRecipient = 'USERSETTINGS/AddTenantAdminNotificationRecipient',
    AddTenantAdminNotificationRecipientComplete = 'USERSETTINGS/AddTenantAdminNotificationRecipientComplete',
    RemoveTenantAdminNotificationRecipient = 'USERSETTINGS/RemoveTenantAdminNotificationRecipient',
    RemoveTenantAdminNotificationRecipientComplete = 'USERSETTINGS/RemoveTenantAdminNotificationRecipientComplete',
    UpdateNotificationSettingAction = 'USERSETTINGS/UpdateNotificationSettingAction',
    UpdateNotificationSettingActionComplete = 'USERSETTINGS/UpdateNotificationSettingActionComplete',
}

export interface ILoadGlobalUserSettingsAction {
    readonly type: UserSettingsActions.LoadGlobalUserSettings;
}

export interface ILoadGlobalUserSettingsCompleteAction {
    readonly type: UserSettingsActions.LoadGlobalUserSettingsComplete;
    readonly settings?: GlobalUserSettings;
}

export interface IUpdateGlobalSetting {
    readonly type: UserSettingsActions.UpdateGlobalSetting;
    readonly notificationId: number;
    readonly notificationType: string;
    readonly turnedOn: boolean;
}

export interface IUpdateGlobalSettingComplete {
    readonly type: UserSettingsActions.UpdateGlobalSettingComplete;
    readonly settings?: EditUserNotificationSettings;
    readonly successful: boolean;
}

export interface ILoadWorkspaceUserSettings {
    readonly type: UserSettingsActions.LoadWorkspaceUserSettings;
    readonly userId: string;
}

export interface ILoadWorkspaceUserSettingsComplete {
    readonly type: UserSettingsActions.LoadWorkspaceUserSettingsComplete;
    readonly subscriptions?: ListWorkspaceNotificationSubscriptions;
    readonly userId: string;
    readonly successful: boolean;
}

export interface IUpdateWorkspaceUserSetting {
    readonly type: UserSettingsActions.UpdateWorkspaceUserSetting;
    readonly userId: string;
    readonly turnedOn: boolean;
    readonly workspaceId: string;
    readonly notificationId: number;
    readonly notificationType: string;
}

export interface IUpdateWorkspaceUserSettingComplete {
    readonly type: UserSettingsActions.UpdateWorkspaceUserSettingComplete;
    readonly userId: string;
    readonly turnedOn: boolean;
    readonly workspaceId: string;
    readonly notificationId: number;
    readonly notificationType: string;
    readonly successful: boolean;
}

export interface IBatchUpdateWorkspaceUserSetting {
    readonly type: UserSettingsActions.BatchUpdateWorkspaceUserSetting;
    readonly userId: string;
    readonly turnedOn: boolean;
    readonly workspaceIds: string[];
    readonly notificationId: number;
    readonly notificationType: string;
}

export interface IBatchUpdateWorkspaceUserSettingComplete {
    readonly type: UserSettingsActions.BatchUpdateWorkspaceUserSettingComplete;
    readonly userId: string;
    readonly turnedOn: boolean;
    readonly workspaceIds: string[];
    readonly notificationId: number;
    readonly notificationType: string;
    readonly successful: boolean;
}

export interface ILoadTenantAdminSettings {
    type: UserSettingsActions.LoadTenantAdminSettings;
}

export interface ILoadTenantAdminSettingsComplete {
    type: UserSettingsActions.LoadTenantAdminSettingsComplete;
    settings?: AdministrativeTenantNotificationSettings;
}

export interface IAddTenantAdminNotificationRecipient {
    type: UserSettingsActions.AddTenantAdminNotificationRecipient;
    recipient: string;
    notificationId: number;
    notificationMethod: NotificationMethod;
}

export interface IAddTenantAdminNotificationRecipientComplete {
    type: UserSettingsActions.AddTenantAdminNotificationRecipientComplete;
    setting?: AdministrativeNotificationSetting;   // undefined on error
}


export interface IRemoveTenantAdminNotificationRecipient {
    type: UserSettingsActions.RemoveTenantAdminNotificationRecipient;
    recipient: string;
    setting: AdministrativeNotificationSetting;
}

export interface IRemoveTenantAdminNotificationRecipientComplete {
    type: UserSettingsActions.RemoveTenantAdminNotificationRecipientComplete;
    setting?: AdministrativeNotificationSetting;   // undefined on error
}

export interface IUpdateNotificationSettingAction {
    type: UserSettingsActions.UpdateNotificationSettingAction;
    action: NotificationDefaultRecipientAction;
    notificationId: number;
    notificationMethod: NotificationMethod;
}

export interface IUpdateNotificationSettingActionComplete {
    type: UserSettingsActions.UpdateNotificationSettingActionComplete;
    setting?: AdministrativeNotificationSetting;  // undefined on errorr
}

export type KnownAction =
    ILoadGlobalUserSettingsAction |
    ILoadGlobalUserSettingsCompleteAction |
    IUpdateGlobalSetting |
    IUpdateGlobalSettingComplete |
    ILoadWorkspaceUserSettings |
    ILoadWorkspaceUserSettingsComplete |
    IUpdateWorkspaceUserSetting |
    IUpdateWorkspaceUserSettingComplete |
    IBatchUpdateWorkspaceUserSetting |
    IBatchUpdateWorkspaceUserSettingComplete |
    ILoadTenantAdminSettings |
    ILoadTenantAdminSettingsComplete |
    IAddTenantAdminNotificationRecipient |
    IAddTenantAdminNotificationRecipientComplete |
    IRemoveTenantAdminNotificationRecipient |
    IRemoveTenantAdminNotificationRecipientComplete |
    IUpdateNotificationSettingAction |
    IUpdateNotificationSettingActionComplete;
