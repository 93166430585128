import * as React from 'react';

import '../../Main.scss';
import './FinancialStatements.scss';

import CloseIcon from '@mui/icons-material/Close';

import {
    Container,
    Col,
    Popover,
    PopoverBody,
    PopoverHeader,
    Row,
} from 'reactstrap';

import { LineItemSelectionTracking } from './FinancialStatementLineItemsTable';

import { FSModels } from '@finagraph/financial-statement-editor';

type Props = {
    financialStatement: FSModels.FinancialStatementViewModel;
    lineItem: FSModels.FinancialStatementLineItemViewModel;
    statementType: FSModels.FinancialStatementType;
    target: string;
    onToggle: (on: boolean) => void;
    onSelected: (lineItem: FSModels.FinancialStatementLineItemViewModel | undefined, sectionId: string | undefined) => void;  
    moveToLocationOptions: FSModels.MoveLineItemOption[];
    multiSelections: Map<string, LineItemSelectionTracking> | undefined;
}
export const MoveToNewSectionMenu: React.FC<Props> = (props): React.ReactElement => {
    const {
        lineItem,
        target,
        onToggle,
        onSelected,
        moveToLocationOptions,
        multiSelections,
    } = props;

    const optionalMultiContent: { menuTitleText: React.ReactElement, isMultiSelected: boolean } = React.useMemo(() => {
        let isMultiSelected = false;

        if (!!multiSelections) {
            const selectionInfo = multiSelections.get(lineItem.id);
            if (!!selectionInfo) {
                isMultiSelected = selectionInfo.selected;
            }
        }

        if (!isMultiSelected) {
            return {
                menuTitleText: (<div>Move <span className={'bolded'}>{lineItem.captionText}</span> to</div>),
                isMultiSelected,
            };
        } else {
            return {
                menuTitleText: (<div>Move selected line items to</div>),
                isMultiSelected,
            };
        }
    }, [multiSelections, lineItem]);

    return (
        <div className={`control-region control-region-lender`}>
            <Popover
                placement={'right'}
                isOpen={true}
                className={'financial-statement-move-lineitem-to-new-section-popover'}
                target={target}
                key={'fs-move-line-item-popover'}
                toggle={() => onToggle && onToggle(false)}
                trigger={'legacy'}
            >
                <PopoverHeader>
                    <div className={'portal-header-region spaced-row'}>
                        {optionalMultiContent.menuTitleText}
                        <CloseIcon
                            color={'primary'}
                            className={'basic-list-icon actionable-icon'}
                            onClick={() => onToggle(false)}
                            id={'closepopover'}
                        />
                    </div>
                </PopoverHeader>
                <PopoverBody className={`move-lineitem-body`}>
                    <Container fluid>
                        {moveToLocationOptions.map((s, i) => (
                            <Row className={s.canMoveToThisLocation ? 'move-option-enabled' : 'move-option-disabled'} key={i} 
                                onClick={e => {
                                    s.canMoveToThisLocation && onSelected(optionalMultiContent.isMultiSelected ? undefined : lineItem, s.sectionId);
                                }}>
                                <Col>
                                    <span className={s.sectionId ? '' : 'move-option-top-level'}>
                                        {s.locationDisplayName}
                                    </span>
                                </Col>
                            </Row>
                        ))}
                    </Container>
                </PopoverBody>
            </Popover>
        </div>
    )
}