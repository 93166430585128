import * as React from 'react';

type Props = {
    token?: string;
    onTimeout: () => void;
    onSlidingTimeout: () => void;
    absoluteExpiration?: Date;
    slidingExpiration?: Date;
}

export const AuthTimer: React.FC<Props> = (props: Props): React.ReactElement => {
    const {
        onSlidingTimeout,
        onTimeout,
        absoluteExpiration,
        slidingExpiration,
    } = props;

    const [timerKey, setTimerKey] = React.useState<number>(1);

    React.useEffect(() => {
        // Timer will be stopped because of our return value (if it's actually running) so we're done.
        if (!absoluteExpiration) {
            setTimerKey(timerKey + 1);
            return;
        }

        const millisecondsToExpirationFromEpoch = absoluteExpiration.getTime();
        let millisecondsFromEpoch = Date.now();

        // Compute number of milliseconds to expiration minus 1 minute.  On the off chance it's
        // really small or negative set it to 100
        const millisecondsToExpiration = Math.max(100, millisecondsToExpirationFromEpoch - millisecondsFromEpoch - 60000)

        const timerHandle = setTimeout(() => {
            onTimeout && onTimeout();
        }, millisecondsToExpiration);

        setTimerKey(timerKey + 1);

        return () => {
            clearTimeout(timerHandle);
        }

        // Don't want timerKey in list of dependencies
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [absoluteExpiration, onTimeout]);

    React.useEffect(() => {
        if (!slidingExpiration) {
            setTimerKey(timerKey + 1);
            return;
        }

        const millisecondsToExpirationFromEpoch = slidingExpiration.getTime();
        let millisecondsFromEpoch = Date.now();

        // Compute number of milliseconds to expiration minus 1 minute.  On the off chance it's
        // really small or negative set it to 100
        const millisecondsToExpiration = Math.max(100, millisecondsToExpirationFromEpoch - millisecondsFromEpoch - 60000)

        const timerHandle = setTimeout(() => {
            onSlidingTimeout && onSlidingTimeout();
        }, millisecondsToExpiration);

        setTimerKey(timerKey + 1);

        return () => {
            clearTimeout(timerHandle);
        }
        // Don't want timerKey in list of dependencies
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [slidingExpiration]);

    return (
        <div key={timerKey.toString()} >
        </div>
    );
}
