import * as React from 'react';

import '../Main.scss';

import { maxFileUploadSize } from '../../Utils/FileUtils';
import { FetchMethod, FetchError, fetchWrapper } from '../../Utils/FetchWrapper';

import { LoadingIndicator } from '../LoadingIndicator/LoadingIndicator';
import Dropzone, { IFileWithMeta, IInputProps, ILayoutProps, StatusValue } from 'react-dropzone-uploader';
import ReactInlineSvg from 'react-inlinesvg';

import FileAddImg from '../../images/fileadd.svg';

type FileErrorDescription = {
    filename: string,
    reason: string,
};

/**
 * @member{accepts} is a comma separated list of extensions or mime types. If provided, prior to upload files supplied will be
 * checked against this to validate they are an accepted type.  For additional information check the documentation for HTML 
 * 'input' tag where type is 'file'.  This value is passed straight through to the accept property on that tag.
 * @member{submissionDocumentType} is an optional value that gets passed on file upload to the controller for identifying
 * what type of document this is. For example, it's used where we allow a lender to upload a modified version of the workbook
 * that we use for improving their account classification
 * */
type Props = {
    submissionId: string;
    workspaceId: string;
    onAllDone?: () => void;
    excludeAllDoneButton?: boolean;
    singleFileOnly?: boolean;
    accepts?: string;
    submissionDocumentType?: string;
    onWorking?: (working: boolean) => void;
    invalidFileTypeMsg?: string;
    dark?: boolean;
}

export const FileSelectUpload: React.FC<Props> = (props): React.ReactElement => {
    const {
        excludeAllDoneButton,
        submissionDocumentType,
        submissionId,
        workspaceId,
        onAllDone,
        onWorking,
        singleFileOnly,
        accepts,
        invalidFileTypeMsg
    } = props;

    const normalButtonHeight = '53px';

    const [uploadingFiles, setUploadingFilesInternal] = React.useState<boolean>(false);
    const setUploadingFiles = (uploading: boolean): void => {
        setUploadingFilesInternal(uploading);
        onWorking && onWorking(uploading);
    }
    const [generalUploadError, setGeneralUploadError] = React.useState<boolean>(false);
    const [errorFiles, setErrorFiles] = React.useState<FileErrorDescription[]>([]);
    const [completionState, setCompletionState] = React.useState<boolean>(false);
    const [preparing] = React.useState<IFileWithMeta[]>([])

    const [renderKey, setRenderKey] = React.useState<number>(1);

    const layoutDropzone = ({ input, previews, submitButton, dropzoneProps, files, extra: { maxFiles } }: ILayoutProps) => {
        /* Add {previews} right below the first div to show previews. */

        return (
            <div className={`file-drop-target`}>
                <div {...dropzoneProps}>
                    {input}
                </div>
            </div>
        );
    }

    const uploadingContent = () => {
        return (
            <div className={'dzu-input'}>
                <LoadingIndicator
                    active={true}
                    overlayText={(<h1>Uploading files</h1>)}
                />
            </div>
        );
    }

    const tryAgainButton = (
        <input
            type="button"
            value="Try again"
            className={`dropzone-label`}
            onClick={(e: React.MouseEvent) => {
                setGeneralUploadError(false);
                setErrorFiles([]);
                setCompletionState(false);
            }} />
    )

    const allDoneButton = (
        <input
            type="button"
            value="I'm all done"
            className={`dropzone-label`}
            style={{
                marginLeft: '20px',
                height: normalButtonHeight,
            }}
            onClick={(e: React.MouseEvent) => {
                onAllDone && onAllDone();
            }}
            role={'link'}
            aria-label={"I'm all done"}
            tabIndex={2}
        />
    )

    const errorContent = () => {
        return (
            <div className={'dzu-input'}>
                <h1>Sorry, there was an error uploading 1 or more of your files</h1>
                {tryAgainButton}
            </div>
        );
    }

    const specificErrorContent = () => {
        // sanity check, this is impossible.  
        if (errorFiles.length <= 0) {
            return errorContent();
        }
        return (
            <div className={'dzu-input'}>
                <h1>Sorry, the following file(s) could not be uploaded</h1>
                <div className={`file-error-list`}>
                    <table>
                        <tr className={'column-headers'}>
                            <th>File Name</th>
                            <th style={{ paddingLeft: '20px' }}>Reason</th>
                        </tr>
                        {errorFiles.map(file => {
                            return (
                                <tr className={'normal-rows'}>
                                    <td title={file.filename} className={'name'}>{file.filename}</td>
                                    <td style={{ paddingLeft: '20px' }} title={`${file.filename}${file.reason}`}>{file.reason}</td>
                                </tr>
                            );
                        })}
                    </table>
                </div>
                {tryAgainButton}
            </div>
        )
    }
    const doneUploading = (generalError?: boolean): void => {
        setUploadingFiles(false);
        setCompletionState(true);
        setGeneralUploadError(!!generalError)
    }

    const setErrorFileState = (files: FileErrorDescription[]): void => {
        files.forEach(fileError => errorFiles.push(fileError));
        setRenderKey(renderKey + 1);
    }

    const acceptableFile = (file: File): boolean => {
        if (!accepts) {
            return true;
        }
        const fileTypes = accepts.split(',');

        const result = !!fileTypes.find(ft => file.type === ft );
        if (!result) {
            const newError: FileErrorDescription = {
                filename: file.name,
                reason: invalidFileTypeMsg || `file type not supported`,
            }
            setErrorFileState([newError]);
        }
        return result;
    }

    const uploadFile = (files: File[], indexUploading: number): void => {
        let url = `api/documents/${workspaceId}?submissionId=${submissionId}`;

        if (!files) {
            doneUploading();
            return;
        }

        const formData = new FormData();
        let foundFile = false;
        let filesTooLarge: FileErrorDescription[] | undefined = undefined;

        while ((!foundFile) && (indexUploading < files.length)) {
            if (files[indexUploading].size >= maxFileUploadSize().value) {
                const newError: FileErrorDescription = {
                    filename: files[indexUploading].name,
                    reason: `File too large, size is greater than ${maxFileUploadSize().description}`,
                }
                if (!filesTooLarge) {
                    filesTooLarge = [newError];
                } else {
                    filesTooLarge.push(newError);
                }
                indexUploading++;
            } else {
                formData.append('content', files[indexUploading]);
                foundFile = true;
            }
        }

        if (!!submissionDocumentType) {
            url += `&submissionDocumentType=${submissionDocumentType}`;
        }

        if (filesTooLarge) {
            setErrorFileState(filesTooLarge);
        }

        if (indexUploading >= files.length) {
            doneUploading();
            return;
        }

        const errorOnUpload = (status: number, msg: string) => {
            const newError: FileErrorDescription = {
                filename: files[indexUploading].name,
                reason: `Status code: ${status ? status : 'unknown'} message: ${msg ? msg : 'not provided'}`,
            }
            setErrorFileState([newError]);
        }

        const nextFile = () => {
            let indexNext = indexUploading + 1;

            while (indexNext < files.length) {
                if (acceptableFile(files[indexNext])) {
                    break;
                }
                indexNext++;
            }
            if (indexNext >= files.length) {
                doneUploading();
            } else {
                uploadFile(files, indexNext);
            }
        }

        fetchWrapper(url,
            {
                method: FetchMethod.Post,
                body: formData,
                headers: {
                    "accept": "application/json"
                }
            },
            false,
            true
        )
            .then(res => {
                if (!(res.ok)) {
                    errorOnUpload(res.status, res.statusText);
                }

                nextFile();
            })
            .catch((reason: FetchError) => {
                let errorMsg = reason.message || 'File upload failed';

                if (reason.status === 415) {
                    errorMsg = reason.errorMessage || 'File type is unsupported';
                }

                errorOnUpload(reason.status, errorMsg);

                nextFile();
            });
    }

    const uploadFiles = (files: File[]): void => {
        setUploadingFiles(true);

        let startIndex = 0;

        while (startIndex < files.length) {
            if (acceptableFile(files[startIndex])) {
                break;
            }
            startIndex++;
        }
        if (startIndex >= files.length) {
            doneUploading();
            return;
        }

        uploadFile(files, startIndex);  
    }

    const uploadFilesFromButton = (files: FileList): void => {
        const fileList: File[] = [];

        for (let indexFile = 0; indexFile < files.length; indexFile++) {
            fileList.push(files[indexFile]);
        }

        uploadFiles(fileList);
    }

    const standardContent = () => {
        const congratsContent = completionState && (
            <h1
                style={{
                    marginBottom: '40px',
                }}
            >
                Your files were successfully uploaded!
            </h1>
        );

        return (
            <div
                className={`dzu-input`}
            >
                {congratsContent}
                <ReactInlineSvg src={FileAddImg} />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <label
                        className={`dropzone-label`}
                        aria-label={'Browse files'}
                        role={'link'}
                        tabIndex={1}
                    >
                        Browse files
                        <input
                            style={{ display: 'none' }}
                            type="file"
                            multiple={singleFileOnly !== true}
                            onChange={e => {
                                if (e.target.files && e.target.files.length > 0) {
                                    uploadFilesFromButton(e.target.files);
                                }
                            }}
                            accept={!!accepts ? accepts : '*'}
                        />
                    </label>
                    {excludeAllDoneButton !== true && allDoneButton}
                </div>
                <h1>{singleFileOnly === true ? 'Or drag a file here' : 'Or drag files here'}</h1>
            </div>
        );
    }

    // Fired when files are dropped.  Does not file when user uses button to select files
    const changeStatus = (file: IFileWithMeta, status: StatusValue, allFiles: IFileWithMeta[]): void => {
        if (status === "preparing") {
            preparing.push(file);
        } else if (status === "done") {
            if (preparing && preparing.length > 0) {
                const files: File[] = [];

                preparing.forEach(f => files.push(f.file));
                uploadFiles(files);

                preparing.length = 0;
            }
        }
    }

    const dropzoneContent = ({ accept, onFiles, files, getFilesFromEvent }: IInputProps) => {
        if (uploadingFiles) {
            return uploadingContent();
        } else if (generalUploadError) {
            return errorContent();
        } else if (errorFiles.length > 0) {
            return specificErrorContent();
        } else {
            return standardContent();
        }
    }

    return (
        <Dropzone
            LayoutComponent={layoutDropzone}
            InputComponent={dropzoneContent}
            onChangeStatus={changeStatus}
            key={renderKey.toString()}
        />
    )
}
