import '../Main.scss';

import * as React from 'react';
import { connect } from 'react-redux';

import {
    Col,
    Container,
    Table,
    Row
} from 'reactstrap';

import { ApplicationState } from '../../Store';
import {
    ResourceName,
    RetrieveResource,
    TenantParagraphSet
} from '../../Store/Tenant';

import { AccountingPkgPresentation } from './AccountingPkgPresentation';

type InjectedReduxState = {
    supplementalParagraphs: React.ReactElement[];
}

type InjectedActionCreators = {

}

type AccountingSystemGroupProps = {
    id: string;
    name?: string;
    accountingSystems: AccountingPkgPresentation[];
    disabled?: boolean;
    tabIndexBase: number;
};

type Props = AccountingSystemGroupProps & InjectedActionCreators & InjectedReduxState;

const AccountingSystemGroupComponent: React.FC<Props> = (props): React.ReactElement => {
    const {
        accountingSystems,
        disabled,
        id,
        name,
        supplementalParagraphs,
        tabIndexBase,
    } = props;

    return (
        <Container className={'button-group-container'} key={`accountingSystemGroup${id}`}>
            <Row>
                <Col>
                    <Table key={`accountingSystemGroupTable${id}`}>
                        { !!name && (
                            <thead>
                                <tr>
                                    <th key={`accountingSystemGroupHeader${id}1`}><span className={'group-name'}>{name}</span></th>
                                </tr>
                            </thead>
                          )
                        }
                        <tbody>
                            {
                                accountingSystems.map((pkg, pkgIndex) => {
                                    let pkgClass = !!disabled ? 'accounting-package disabled' : 'accounting-package';
                                    return (
                                        <tr key={`accountingSystemGroupItemRow${pkg.featureName}`}>
                                            <td
                                                key={`accountingSystemGroupItemColumn${pkg.featureName}`}
                                                className={pkgClass}
                                            >
                                                <span
                                                    className={pkgClass}
                                                    onClick={() => pkg.onPkgSelected(pkg)}
                                                    role={'link'}
                                                    tabIndex={tabIndexBase + pkgIndex}
                                                    onKeyPress={(e) => {
                                                        if (e.key === 'Enter') {
                                                            pkg.onPkgSelected(pkg);
                                                        }
                                                    }}
                                                >
                                                    {pkg.descriptor}{pkg.tag && (<sup>&nbsp;{pkg.tag}</sup>)}
                                                </span>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </Col>
            </Row>
            {!!supplementalParagraphs && (
                <Row className={'supplemental-row extra-row-spacing-medium'}>
                    <Col>
                        {
                            supplementalParagraphs.map((p, i) => {
                                return (
                                    <p
                                        className={'supplemental'}
                                        key={`select-accounting-system-supplemental-${i}`}
                                    >
                                        {p}
                                    </p>
                                )
                            })
                        }
                    </Col>
                </Row>
            )}
        </Container>
    );
}

export const AccountingSystemGroup = connect<InjectedReduxState, InjectedActionCreators, AccountingSystemGroupProps, ApplicationState>(
    (appState: ApplicationState, props: AccountingSystemGroupProps) => {
        const tenantSupplementalParagraphs: TenantParagraphSet = RetrieveResource(appState, ResourceName.tenantSelectAccountingSystemSupplemental);

        const supplementalParagraphs: React.ReactElement[] = [];

        if (!!tenantSupplementalParagraphs) {
            tenantSupplementalParagraphs.paragraphs.forEach((p, pi) => supplementalParagraphs.push((<span key={`select-accounting-system-supplemental-${pi}`}>{p}</span>)));
        }

        const result = {
            supplementalParagraphs,
        };

        return result;
    }
)(AccountingSystemGroupComponent);
