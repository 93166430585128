import * as React from 'react';
import { connect } from 'react-redux';

import {
    Col,
    Container,
    Row
} from 'reactstrap';

import { PortalHeader } from '../PortalHeader';

import { ApplicationState } from '../../Store';
import {
    GetBackgroundImageUrl
} from '../../Store/Tenant';

import { StrongboxLogo } from '../Utils/StrongboxLogo';

type InjectedReduxState = {
    backgroundImage?: string;
};

type InjectedActionCreators = {
};

type BasicStrongboxContentContainerProps = {
    children?: React.ReactNode;
};

type Props = BasicStrongboxContentContainerProps & InjectedActionCreators & InjectedReduxState;

const BasicStrongboxContentContainerComponent: React.FC<Props> = (props): React.ReactElement => {
    const {
        backgroundImage,
        children,
    } = props;

    const containingDivClass = `full-screen-region lender-region content-region control-region control-region-lender`;

    const backgroundStyle = React.useMemo(() => {
        return {
            backgroundImage: `url('${backgroundImage}')`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'

        }

        // This is the dependency list I actually want.
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [props.backgroundImage]);

    return (
        <div>
            <PortalHeader hideMenu={true} />
            <div className={containingDivClass} style={backgroundStyle} >
                <Container className={'strongbox-basic-content-container'}>
                    <Row>
                        <Col>
                            <StrongboxLogo />
                        </Col>
                    </Row>
                    {children}
                </Container>
            </div>
        </div>
    );
}

export const BasicStrongboxContentContainer = connect<InjectedReduxState, InjectedActionCreators, BasicStrongboxContentContainerProps, ApplicationState>(
    (appState: ApplicationState) => {
        const result = {
            backgroundImage: GetBackgroundImageUrl(appState),
        };

        return result;
    }
)(BasicStrongboxContentContainerComponent);
