import * as React from 'react';

import {
    Row,
    Col,
    Container
} from 'reactstrap';
import { AccountingPkgPresentation } from '../SelectAccountingSystem/AccountingPkgPresentation';

import { SelectAccountingSystem } from '../SelectAccountingSystem/SelectAccountingSystem';
import { BorrowerPortalChildProps } from './BorrowerPortal';

type Props = {
    borrowerPortalProps: BorrowerPortalChildProps;
    importFinancials: (accountingPkg: AccountingPkgPresentation) => void;
    tabIndexBase: number;
}

export const NewImportWidget: React.FC<Props> = (props): React.ReactElement => {
    const {
        borrowerPortalProps,
        importFinancials,
        tabIndexBase,
    } = props;

    return (
        <Container fluid>
            <Row>
                <Col xs={12} className={'outlined-column-content centered-col'}>
                    <SelectAccountingSystem
                        buttonsDisabled={borrowerPortalProps.working}
                        onLink={importFinancials}
                        rowClass={'borrower-content-row'}
                        noBorder={true}
                        tabIndexBase={tabIndexBase}
                    />
                </Col>
            </Row>
        </Container>
    )
}
