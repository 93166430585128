import * as React from 'react';
import { connect } from 'react-redux';

import '../../Main.scss';

import { FSModels } from '@finagraph/financial-statement-editor';

import {
    Button,
    Col,
    Container,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from 'reactstrap';

import { FinancialStatementRenderData } from './UtilTypes';
import { fsSectionPresentationName, fsSubSectionPresentationName } from './FSConstants';

import { ApplicationState } from '../../../Store';
import {
    GetValidLocationToMoveSectionContents
} from '../../../Store/FinancialStatements';

type InjectedReduxState = {
    validDestinations: FSModels.MoveLineItemOption[];
}

type InjectedActionCreators = {};

type DeleteNonEmptySectionModalProps = {
    lineItem: FSModels.FinancialStatementLineItemViewModel;
    onClose: (destinationSectionId?: string) => void;
    renderData: FinancialStatementRenderData;
}

type Props = DeleteNonEmptySectionModalProps & InjectedReduxState & InjectedActionCreators;

const DeleteNonEmptySectionModalComponent: React.FC<Props> = (props): React.ReactElement => {
    const {
        lineItem,
        onClose,
        validDestinations
    } = props;

    const [selectedDestination, setSelectedDestination] = React.useState<{
        id: string;
        label: string;
    } | undefined> (undefined);

    return (
        <Modal
            className={`flight-modal`}
            isOpen={true}
            backdrop={'static'}
        >
            <ModalHeader toggle={() => onClose()}>{`Delete ${lineItem.captionText}`}</ModalHeader>
            <ModalBody>
                <Container className={`control-region control-region-lender`}>
                    <Row>
                        <Col>
                            <p>{`A ${fsSectionPresentationName.toLowerCase()} cannot be deleted unless it is empty`}</p>
                            <p>{`Choose another ${fsSectionPresentationName.toLowerCase()} from the list below to move items ` + 
                                `into enabling deletion`}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h1>{`Other ${fsSectionPresentationName}s:`}</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col
                            className={'move-financial-statement-valid-destination-list'}
                        >
                            {
                                validDestinations
                                    .filter(potentialDestination => potentialDestination.canMoveToThisLocation)
                                    .map((destination, index) => {
                                        return (
                                            <Row
                                                key={`section-destination-${destination.sectionId || ''}-${index}`}
                                                className={`selectable-row${(!!selectedDestination && destination.sectionId === selectedDestination.id) ? ' selected-row' : ''}`}
                                                onClick={() => {
                                                    setSelectedDestination({
                                                        id: destination.sectionId || '',
                                                        label: destination.locationDisplayName
                                                    });
                                                }}
                                            >
                                                <Col>
                                                    <span>{destination.locationDisplayName}</span>
                                                </Col>
                                            </Row>
                                        )
                                    })
                            }
                        </Col>
                    </Row>
                    {
                        !!selectedDestination && (
                            <Row style={{
                                marginTop: '25px'
                            }}>
                                <Col>
                                    <p>{`Move accounts and ${fsSubSectionPresentationName}s to `}<b>{selectedDestination.label}</b> and delete <b>{lineItem.captionText}</b></p>
                                </Col>
                            </Row>
                        )
                    }
                </Container>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="secondary"
                    onClick={() => onClose()}
                >
                    Cancel
                </Button>
                <Button
                    color="primary"
                    onClick={() => onClose(selectedDestination!.id)}
                    disabled={!selectedDestination}
                >
                    Move and Delete
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export const DeleteNonEmptySectionModal = connect<InjectedReduxState, InjectedActionCreators, DeleteNonEmptySectionModalProps, ApplicationState>(
    (appState: ApplicationState, props: DeleteNonEmptySectionModalProps) => {
        return {
            validDestinations: GetValidLocationToMoveSectionContents(appState, props.lineItem.id)
        }
    }
)(DeleteNonEmptySectionModalComponent);
