import * as React from 'react';

import {
    Container, Row, Col
} from 'reactstrap';

import {
    TenantLoginDestination,
} from '../../Store/Tenant';

type Props = {
    loginDestinations: TenantLoginDestination[];
    thisPortal?: TenantLoginDestination
    includeThisPortal: boolean;
    executeLogin: (url: string) => void;
}

export const MultiTenantLoginListContainer: React.FC<Props> = (props): React.ReactElement => {
    const {
        executeLogin,
        includeThisPortal,
        loginDestinations,
        thisPortal
    } = props;

    const elementContainer = React.useMemo(() => {
        const selectOptions: React.ReactElement[] = [];

        loginDestinations.forEach((login, index) => {
            if ((includeThisPortal) || (!!thisPortal && thisPortal.id !== login.id)) {
                selectOptions.push((
                    <li
                        key={`team-selection-item-${index}`}
                        id={login.id}
                        onClick={() => executeLogin(login.url)}
                    >
                        <div>
                            <img
                                src={require('../../images/StrongboxLock-Small.svg')}
                                alt={'Strongbox logo'}
                            />
                            <div className={'list-text'}>
                                <span>{login.name}</span>
                            </div>
                        </div>
                    </li>
                ))
            }
        })

        return (
            <Container fluid>
                <Row
                    className={'login-team-selection-container'}
                >
                    <Col className={'login-content-column'} key={`login-team-selection-container-teams-col`}>
                        <ul>
                            {selectOptions}
                        </ul>
                    </Col>
                </Row>
            </Container>
        )

        // These are the only dependencies I actually want.
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [loginDestinations, thisPortal, includeThisPortal]);

    return elementContainer;
}
