import dayjs from 'dayjs';

import { LogException } from './Logging';

const localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);

export const formatStringFullMonth = 'MMMM';
export const formatStringFullYear = 'YYYY';
export const formatStringMDYFullMonth = 'MMMM D, YYYY';
export const formatStringMYFullMonth = 'MMMM, YYYY'
export const formatStringMDYShort = 'L';

export const formatStringWorkspaceTime = 'MMM D, YYYY'
export const formatStringWorkspaceTimeFull = 'MMMM D, YYYY hh:mm a'

export const simpleTime = 'hh:mm';
export const simpleTimeAMPM = 'hh:mm A'

/**
 * Get the string for a month identified by number
 * 
 * @param { Date} d - The date to format
 * @param { string } format - The format string to use for formatting the date. 
 * See dayjs jor moment for details on the format strings. 
 * 
 * @returns - Formatted date string
 */

export function FormatDate(d: Date, format: string): string {
    return dayjs(d.toString()).format(format);
}


export function GenerateDeltaFromPresentDateString(date: string): string {
    let result = '';

    try {
        let compare = dayjs().diff(date, 'years');

        if (compare > 0) {
            if (compare === 1) {
                result = '1 year ago';
            } else {
                result = `${compare} years ago`;
            }
        } else {
            compare = dayjs().diff(date, 'months');
            if (compare > 0) {
                if (compare === 1) {
                    result = '1 month ago';
                } else {
                    result = `${compare} months ago`;
                }
            } else {
                compare = dayjs().diff(date, 'days');
                if (compare > 0) {
                    if (compare === 1) {
                        result = '1 day ago';
                    } else {
                        result = `${compare} days ago`;
                    }
                } else {
                    result = 'Last 24 Hours';
                }
            }
        }
    } catch (e) {
        LogException(
            `Exception attempting to generate delta from present string`,
            e,
            {
                date
            }
        );
        console.error('Error converting date to period ago string');
        console.error(e);

        return date;
    }

    return result;
}

/**
 * return a day in a month where the day is based on a different month.  Or, if day is
 * undefined return the day that is the last day of month and year combined.  If day is 
 * greater than the possible last day of that month, return the actual last day of the
 * month, else return the day.
 * 
 * @param month  0 based month
 * @param day 1 base day
 * @param year full year, e.g. 2021
 */

export const getUsableDay = (month: number, day: number | undefined, year: number): number => {
    // month + 1 and day 0 will give us the last day of month
    const endOfMonth = new Date(year, month + 1, 0);

    let lastDayOfMonth = endOfMonth.getDate();

    const today = new Date();

    if ((month === today.getMonth()) && (year === today.getFullYear())) {
        lastDayOfMonth = today.getDate();
    }

    // day can be 0 so compare directly to undefined.  If there's no day just give the last day of the selecte
    // month.
    if (day === undefined) {
        return lastDayOfMonth;
    }

    const result = (day > lastDayOfMonth) ? lastDayOfMonth : day;

    return result;
}

