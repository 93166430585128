import './EnterWorkspaceInfo.scss';

import * as React from 'react';

import '../../Utils/FormUtils';
import { TestEmail } from '../../Utils/FormUtils';

import { Row, Col } from 'reactstrap';

type Props = {
    onInputValid: (workspaceName: string, emailAddress: string) => void;
    onInputInvalid: () => void;
    autoFocus: boolean;
    tabIndexBase: number;
};

export const EnterWorkspaceInfo: React.FC<Props> = (props): React.ReactElement => {
    const [workspaceInfo, setWorkspaceInfo] = React.useState({
        name: '',
        email: '',
        emailValid: false,
    });

    const idBusinessNameInput = 'business-name-input';

    const [setFocusTo, setSetFocusTo] = React.useState<string | undefined>(undefined);

    React.useEffect(() => {
        if (props.autoFocus) {
            setSetFocusTo(idBusinessNameInput);
        }

        // I want this to execute equivalent to componentDidMount so this is appropriate
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (!!setFocusTo) {
            const inputField = document.getElementById(setFocusTo);
            if (inputField !== null) {
                inputField.focus();
            }
            setSetFocusTo(undefined);
        }
    }, [setFocusTo]);

    return (
        <Row className={`enter-workspace-info-container control-region control-region-lender`}>
            <Col>
                <Row>
                    <Col
                        xs={12}
                        lg={6}
                        className={'input-column'}
                    >
                        <label htmlFor={'input-workspace-name'}>Business name:</label>
                        <input 
                            className={'large workspace-info-input focus-input'}
                            name={'input-workspace-name'}
                            id={idBusinessNameInput}
                            aria-label={'Enter business name'}
                            type={'text'}
                            placeholder={'Business name'}
                            value={workspaceInfo.name}
                            tabIndex={props.tabIndexBase}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                const newName = event.currentTarget.value;

                                setWorkspaceInfo({
                                    ...workspaceInfo,
                                    name: newName,
                                });
                                if ((workspaceInfo.emailValid) && (newName.length !== 0)) {
                                    props.onInputValid(newName, workspaceInfo.email);
                                } else {
                                    props.onInputInvalid();
                                }
                            }}
                        />
                    </Col>
                    <Col
                        xs={12}
                        lg={6}
                        className={'input-column'}
                    >
                        <label htmlFor={'input-email-address'}>Email address:</label>
                        <input
                            className={'large workspace-info-input focus-input'}
                            name={'input-email-address'}
                            aria-label={'Enter business email address'}
                            placeholder={'Email address'}
                            type={'email'}
                            value={workspaceInfo.email}
                            tabIndex={props.tabIndexBase + 1}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                const newEmail = event.currentTarget.value;

                                const valid = (newEmail !== '') && TestEmail(newEmail);

                                setWorkspaceInfo({
                                    ...workspaceInfo,
                                    email: newEmail,
                                    emailValid: valid,
                                });

                                if (valid && (workspaceInfo.name.length !== 0)) {
                                    props.onInputValid(workspaceInfo.name, newEmail);
                                } else {
                                    props.onInputInvalid();
                                }
                            }}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
