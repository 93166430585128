import * as React from 'react';

import './AnimatedEllipsis.scss';

/**
 * @member(maxDots) maximum characters in animation.  Defaults to 3.
 * @member(leftMargin) distance to the right from the character next to the ellipse.  Default is 10px;
 * @member(spacing) distance between 'dots', default is 7px
 * */

export type Props = {
    maxDots?: number;
    leftMargin?: number;
    spacing?: number;
}

const defaultSpacing = '7px';
const defaultLeftMargin = '10px'
const defaultMaxDots = 3;
const defaultMovementInterval = 500;

export const AnimatedEllipsis: React.FC<Props> = (props): React.ReactElement => {
    const {
        maxDots,
        leftMargin,
        spacing,
    } = props;

    const [showingContent, setShowingContent] = React.useState<React.ReactElement[]>([]);

    const usingEllipse = React.useMemo(() => {
        return (
            <div
                className={'animated-ellipsis-default-character'}
                key={`animated-ellipse-character-${showingContent.length}`}
                style={{
                    marginRight: spacing !== undefined ? spacing : defaultSpacing,
                }}
            />
        );
    }, [spacing, showingContent])

    const usingMaxDots = React.useMemo(() => {
        return maxDots !== undefined ? maxDots : defaultMaxDots;
    }, [maxDots])

    React.useEffect(() => {
        const idTimer = setInterval(() => {
            let result = showingContent.slice();
            result.push(usingEllipse);

            if (showingContent.length >= usingMaxDots) {
                result = [];
            }
            setShowingContent(result);
        }, defaultMovementInterval, [showingContent]);

        return (() => { clearInterval(idTimer) });
    }, [showingContent, usingEllipse, usingMaxDots]);

    return <div
        style={{
            marginLeft: leftMargin !== undefined ? leftMargin : defaultLeftMargin
        }}
        className={'animated-ellipsis'}
    >
        {showingContent}
    </div>
}
