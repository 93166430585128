import * as React from 'react';

import {
    Col,
    Container,
    Input,
    Label,
    Row
} from 'reactstrap';

import '../../Main.scss';

import {
    NotificationDefaultRecipientAction,
} from '../../../Models/Api/strongbox.financialportal';

type Props = {
    idPrefix: string;
    action: NotificationDefaultRecipientAction;
    onChange: (newAction: NotificationDefaultRecipientAction) => void;
    disabled?: boolean;
}

export const RecipientListActionSelector: React.FC<Props> = (props): React.ReactElement => {
    const {
        action,
        idPrefix,
        onChange,
        disabled,
    } = props;

    return (
        <Container fluid className={'selector-container p-0'}>
            <Row>
                <Col>
                    <Input
                        checked={action === 'Append'}
                        type='checkbox'
                        name={`${idPrefix}-append-recipients`}
                        id={`${idPrefix}-append-recipients`}
                        onChange={() => action !== 'Append' && onChange('Append')}
                        disabled={disabled}
                    />
                    <Label check for={`${idPrefix}-append-recipients`}>Append emails above to default notification recipients</Label>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Input
                        checked={action === 'Replace'}
                        type='checkbox'
                        name={`${idPrefix}-replace-recipients`}
                        id={`${idPrefix}-replace-recipients`}
                        onChange={() => action !== 'Replace' && onChange('Replace')}
                        disabled={disabled}
                    />
                    <Label check for={`${idPrefix}-replace-recipients`}>Replace default notification recipients with the list above</Label>
                </Col>
            </Row>
        </Container>
    );
}
