import './LoadingIndicator.scss';

import * as React from 'react';
import { PropsWithChildren } from 'react';

import { connect } from 'react-redux';

import CircularProgress from '@mui/material/CircularProgress';

import { ApplicationState } from '../../Store';
import { BrandConfig, GetBrandConfig } from '../../Store/Tenant';
import { GetStdThemeColors } from '../../Utils/Style';

type InjectedReduxState = {
    brandConfig: BrandConfig;
}

type InjectedActionCreators = {}

enum OverlayType {
    None = 'none',
    Dark = 'dark',
    Light = 'light',
}

interface ILoadingIndicatorProps {
    active?: boolean;
    overlayStyle?: React.CSSProperties;
    overlayText?: React.ReactElement;
    overlayTextClassName?: string;
    overlayType?: OverlayType;
    showSpinner?: boolean;
    size?: number;
    style?: React.CSSProperties;
    thickness?: number;
    centerIndicator?: boolean;
}

type Props = InjectedReduxState & InjectedActionCreators & ILoadingIndicatorProps;

const LoadingIndicatorComponent: React.FC<Props & PropsWithChildren> = (props): React.ReactElement => {
    const {
        active,
        brandConfig,
        centerIndicator,
        children,
        overlayStyle,
        overlayText,
        overlayType,
        overlayTextClassName,
        showSpinner,
        size,
        style,
        thickness,
    } = props;

    const [className, setClassName] = React.useState<string>('loadingOverlay');

    React.useEffect(() => {
        let newClassName = 'loadingOverlay';

        if (overlayType !== undefined) {
            if (overlayType === OverlayType.Light) {
                newClassName += ' light';
            } else if (overlayType === OverlayType.Dark) {
                newClassName += ' dark';
            }
        }

        if (centerIndicator === true) {
            newClassName += ' loading-overlay-relative-positioning';
        }

        setClassName(newClassName);
    }, [overlayType, centerIndicator]);

    const [spinnerStyle, setSpinnerStyle] = React.useState<React.CSSProperties | undefined>(undefined);

    React.useEffect(() => {
        // Not crazy about setting the color through inline style here. Ideally you'd use the mui 
        // Theming concepts. With a recent change, unfortunately any inherited styles on <span 
        // take precedence over whatever is in the theme, even specific settings for CircularProgress.
        // As that implies the CircularProgress is actually encapsulated in a <span> and we define 
        // default color for span in the control-region class.  
        // I spent a fair bit of time trying to avoid this but gave up and here we are.

        const colors = GetStdThemeColors(brandConfig)
        if (!!style) {
            setSpinnerStyle({
                color: colors.primaryColor,
                ...style
            });
        } else {
            setSpinnerStyle({
                color: colors.primaryColor
            })
        }
    }, [brandConfig, style])

    return (
        <div className={className} style={active ? overlayStyle : { display: 'none' }}>
            {!!overlayText && (
                <div className={overlayTextClassName || ''} style={{ marginBottom: 8 }}>
                    {overlayText}
                </div>
            )}
            {(showSpinner !== false) && (
                <CircularProgress
                    style={spinnerStyle}
                    thickness={thickness || 6}
                    size={size || 40}
                />
            )}
            {children}
        </div>
    );
}

export const LoadingIndicator = connect<InjectedReduxState, InjectedActionCreators, ILoadingIndicatorProps, ApplicationState>(
    (appState: ApplicationState) => {
        return {
            brandConfig: GetBrandConfig(appState)
        };
    }
)(LoadingIndicatorComponent);

export default LoadingIndicator;
