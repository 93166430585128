// The purpose of this store.
//
// This keeps track of the current state of options as we represent them in the UI, i.e. the modals the
// user interacts with to create a new import.  This is different than the back end represents them 
// because the UI is simpler than what the back end supports. That may change someday.
// 
// The values for defaults that come from the cookie reflect that back end model and are used to
// initiate these values but are basically unused from that point on.  The values in this store represent
// the current state of import options and will be saved between invocation, so if you use the modal once
// it will have the same settings when you come back to it. Within a single Redux invocation of course,
// that will reset if the browser is refreshed.

import { PortalCreateSubmissionRequestOptions } from '../../Models/PortalCreateSubmissionRequestOptions';

import { Reducer } from 'redux';

import {
    KnownAction,
    NewDataCollectionParametersActions,
} from './Actions';

import { ReadOverriddenAppSettingsValues } from '../AppSettings';

import {
    TenantAttributedParagraphSet,
} from '../Tenant';

import { LogMessage, SeverityLevel } from '../../Utils/Logging';

import { AccountingCollectionGroup } from '../../Models/AccountingCollectionGroup';
import { CookieSROtoPortalSRO } from '../../Models/PortalCreateSubmissionRequestOptions';


// In SelectImportDataItems, get rid of the stuff that defines this, i.e. CollectionGroupContent.
//
// Move all that logic here.

export type NewDataCollectionContentGroupingDescription = {
    description: string;
    bold: boolean;
    italic: boolean;
}

export type NewDataCollectionContentGrouping = {
    collectionGroup: AccountingCollectionGroup
    selected: boolean;
    description: NewDataCollectionContentGroupingDescription[];
}

export interface INewDataCollectionParametersState {
    retrieving: boolean;
    creationOptions: PortalCreateSubmissionRequestOptions | undefined;
    fiscalYearsToCollect: number;
    requestedOptions: PortalCreateSubmissionRequestOptions | undefined;
    requestedOptionsId: number | undefined;
    initializationException?: any;
    newDataCollectionGroupings: NewDataCollectionContentGrouping[];
}

export const defaultState: INewDataCollectionParametersState = {
    retrieving: false,
    creationOptions: undefined,
    requestedOptions: undefined,
    requestedOptionsId: undefined,
    fiscalYearsToCollect: 3,
    newDataCollectionGroupings: [],
};

function MergeOverriddenCollectionGroups(
    appStateOptions?: PortalCreateSubmissionRequestOptions,
    overrideOptions?: PortalCreateSubmissionRequestOptions
): Map<string, AccountingCollectionGroup> {
    if (!appStateOptions) {
        // This is really a sanity check, shouldn't happen.  The result will be
        // alright if it occurs.
        return new Map<string, AccountingCollectionGroup>();
    }
    if (!overrideOptions) {
        return appStateOptions.accountingCollectionGroups;
    }

    const result = new Map<string, AccountingCollectionGroup>();
    appStateOptions.accountingCollectionGroups.forEach(group => {
        const temp = overrideOptions.accountingCollectionGroups.get(group.id);
        result.set(group.id, {
            ...group,
            ...temp
        });
    })

    return result;
}

function InitializeCollectionGroupings(
    collectionGroupDescriptions: TenantAttributedParagraphSet,
    ourState: INewDataCollectionParametersState,
    dynamicTenantSelectionEnabled: boolean
): NewDataCollectionContentGrouping[] {
    const result: NewDataCollectionContentGrouping[] = [];

    let accountingCollectionGroups = ourState.creationOptions?.accountingCollectionGroups;

    const overriddenState = ReadOverriddenAppSettingsValues(dynamicTenantSelectionEnabled);
    if (!!overriddenState) {
        accountingCollectionGroups = MergeOverriddenCollectionGroups(ourState.creationOptions, overriddenState?.defaultPortalSubmissionOptions);
    }

    if (!accountingCollectionGroups) {
        const msg = ('Unexpected error, there are no known collection groups in InitializeCollectionGroupings');
        console.error(msg);
        LogMessage(msg, SeverityLevel.Error);
        return [];
    }

    // This should preserve the ordering that comes in, so ultimately whatever order the groups are defined as
    // in appsettings.json should be how they get presented.
    accountingCollectionGroups.forEach(group => {
        result.push({
            collectionGroup: {
                ...group
            },
            selected: group.collect,
            description: [],
        })
    });

    if (!collectionGroupDescriptions) {
        const msg = ('Warning, collectionGroupDescriptions is undefined in InitializeCollectionGroupings (this is normal immediately after startup)');
        console.warn(msg);
        LogMessage(msg, SeverityLevel.Error);
        return [];
    }

    // Go through each paragraph of description text.
    collectionGroupDescriptions.paragraphs.forEach(pg => {
        // Within each attributed paragraph there should be an id attribute.  If there isn't skip this paragraph it's invalid
        const attr = pg.attributes.find(a => a.name === 'id');
        if (!!attr) {
            // Now look through our possible values for content in result.  If we find the
            // id attribute already there use it, otherwise it was left out of the values that came up
            // from appsettings.json via cookie, ignore it.
            const key = result.find(c => c.collectionGroup.id === attr.value)
            if (!!key) {
                const isBold = !!pg.attributes.find(a => a.name === 'bold');
                const isItalic = !!pg.attributes.find(a => a.name === 'italic');

                key.description.push({ description: pg.content, bold: isBold, italic: isItalic });
            } else {
                const msg = `Ignoring collection grouping paragraph, id not found as a valid group descriptor in InitializeCollectionGroupings. ${attr.value}`;
                console.error(msg);
                console.error(pg.content);
                LogMessage(msg, SeverityLevel.Warning, { ...pg });
            }
        } else {
            const msg = `Unexpected error, expecting attributed paragraph to have 'id' attribute in InitializeCollectionGroupings.`;
            console.error(msg);
            console.error(pg.content);
            LogMessage(msg, SeverityLevel.Error, { ...pg });
        }
    });

    return result;
}

export const reducer: Reducer<INewDataCollectionParametersState, KnownAction> = (
    state: INewDataCollectionParametersState | undefined,
    action: KnownAction
): INewDataCollectionParametersState => {
    let newState: INewDataCollectionParametersState | undefined = undefined;

    switch (action.type) {
        case NewDataCollectionParametersActions.SetOptions: {
            newState = state ? { ...state } : { ...defaultState };

            let groups = new Map<string, AccountingCollectionGroup>();

            if (!!(newState.creationOptions?.accountingCollectionGroups)) {
                groups = new Map<string, AccountingCollectionGroup>(newState.creationOptions.accountingCollectionGroups);
            }

            newState.creationOptions = {
                ...action.options,
            };
            newState.creationOptions.accountingCollectionGroups = groups;

            newState.fiscalYearsToCollect = action.fiscalYearsToCollect;

            break;
        }
        case NewDataCollectionParametersActions.Initialize: {
            newState = state ? { ...state } : { ...defaultState };

            const overriddenState = ReadOverriddenAppSettingsValues(action.appData.dynamicTenantSelection);

            if (!!overriddenState) {
                newState.creationOptions = overriddenState.defaultPortalSubmissionOptions;
            } else {
                newState.creationOptions = CookieSROtoPortalSRO(action.appData.defaultPortalSubmissionOptions);
            }

            break;
        }
        case NewDataCollectionParametersActions.InitializeCollectionGroupings: {
            newState = state ? { ...state } : { ...defaultState };
            newState.newDataCollectionGroupings = InitializeCollectionGroupings(action.collectionGroupDescriptions, newState, action.dynamicTenantSelectionEnabled);
            break;
        }
        case NewDataCollectionParametersActions.BeginInitializeFromURL: {
            newState = state ? { ...state } : { ...defaultState };

            newState.retrieving = true;
            break;
        }
        case NewDataCollectionParametersActions.EndInitializeFromURL: {
            newState = state ? { ...state } : { ...defaultState };

            newState.retrieving = false;
            newState.requestedOptions = undefined;
            newState.requestedOptionsId = action.optionsId;
            newState.initializationException = action.initializationException;

            if (action.options) {
                newState.requestedOptions = {
                    ...action.options,
                }
            }

            break;
        }
    }

    if (newState) {
        return newState;
    } else if (state) {
        return state;
    } else {
        let defaultCopy: INewDataCollectionParametersState = {
            ...defaultState,
        };
        return defaultCopy;
    }
}
