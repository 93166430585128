import {
    ApplyFinancialStatementTemplateParameters,
    FinancialImportStatus,
    FinancialStatementTemplateDescriptor,
    WorkbookRevisionDescriptor,
    FinancialStatementTemplatesList,
    WorkbookRevisionStatus
} from '../Models/Api/strongbox.financialportal';
import { FetchMethod, fetchWrapper } from '../Utils/FetchWrapper';

import { LogMessage, SeverityLevel } from '../Utils/Logging';

import dayjs from 'dayjs';

export async function GetFinancialStatements(
    workspaceId: string,
    financialRecordId: string): 
    Promise<object> {
    LogMessage(
        `GetFinancialStatements`,
        SeverityLevel.Information,
        {
            workspaceId: workspaceId,
            financialRecordId,
        }
    );

    let url = `/api/Workspaces/${workspaceId}/financialrecords/${financialRecordId}/financialstatements`;

    try {
        const res = await fetchWrapper(url,
            {
                method: FetchMethod.Get,
            },
            true,
            true,
        );

        if (!res.ok) {
            throw new Error(`Unexpected status code from server ${res.status}`);
        }

        return res.json();
    } catch (error) {
        console.error('Failure getting financial statements for financial record id');
        console.error(`${workspaceId}: Financial record id: ${ financialRecordId }`)
        console.error(error);

        throw error;
    }
}

export async function PutFinancialStatements(
    workspaceId: string,
    financialRecordId: string,
    financialStatements: object
): Promise<void> {
    LogMessage(
        `PutFinancialStatements`,
        SeverityLevel.Information,
        {
            workspaceId: workspaceId,
            financialRecordId,
        }
    );

    let url = `/api/Workspaces/${workspaceId}/financialrecords/${financialRecordId}/financialstatements`;

    try {
        const res = await fetchWrapper(url,
            {
                method: FetchMethod.Put,
                body: JSON.stringify(financialStatements),
            },
            true,
            true,
        );

        if (!res.ok) {
            throw new Error(`Unexpected status code from server ${res.status}`);
        }
    } catch (error) {
        console.error('Failure updating financial statements for financial record id');
        console.error(`${workspaceId}: Financial record id: ${ financialRecordId }`)
        console.error(error);

        throw error;
    }
}

export async function ExtractFinancialStatementTemplate(
    displayName: string,
    description: string,
    financialStatementsContext: object
): Promise<FinancialStatementTemplateDescriptor> {
    LogMessage(
        `ExtracFinancialStatementTemplate`,
        SeverityLevel.Information,
        {
            displayName,
            description
        }
    );

    let url = `/FinancialStatementTemplates/ExtractTemplate`;

    try {
        const res = await fetchWrapper(url,
            {
                method: FetchMethod.Post,
                body: JSON.stringify({
                    displayName,
                    description,
                    financialStatementsContext
                }),
            },
            true,
            true,
        );

        if (!res.ok) {
            throw new Error(`Unexpected status code from server ${res.status}`);
        }

        return res.json();
    } catch (error) {
        console.error('Failure extracting financial statement template');
        console.error(`${displayName}  ${description}`);
        console.error(error);

        throw error;
    }
}

export async function ResetFinancialStatements(
    workspaceId: string,
    financialRecordId: string): 
    Promise<object> {
    LogMessage(
        `ResetFinancialStatements`,
        SeverityLevel.Information,
        {
            workspaceId: workspaceId,
            financialRecordId,
        }
    );

    let url = `/api/Workspaces/${workspaceId}/financialrecords/${financialRecordId}/financialstatements/reset`;

    try {
        const res = await fetchWrapper(url,
            {
                method: FetchMethod.Post,
            },
            true,
            true,
        );

        if (!res.ok) {
            throw new Error(`Unexpected status code from server ${res.status}`);
        }

        return res.json();
    } catch (error) {
        console.error('Failure resetting financial statements for financial record id');
        console.error(`${workspaceId}: Financial record id: ${ financialRecordId }`)
        console.error(error);

        throw error;
    }
}

export async function ApplyTemplateToFinancialStatements(
    workspaceId: string,
    financialRecordId: string,
    financialStatementTemplateId: string): 
    Promise<object> {
    LogMessage(
        `ApplyTemplateToFinancialStatements`,
        SeverityLevel.Information,
        {
            workspaceId: workspaceId,
            financialRecordId,
            financialStatementTemplateId,
        }
    );

    let url = `/api/Workspaces/${workspaceId}/financialrecords/${financialRecordId}/financialstatements/ApplyTemplate`;

    try {
        const res = await fetchWrapper(url,
            {
                method: FetchMethod.Post,
                body: JSON.stringify({
                    financialStatementTemplateId,
                } as ApplyFinancialStatementTemplateParameters),
            },
            true,
            true,
        );

        if (!res.ok) {
            throw new Error(`Unexpected status code from server ${res.status}`);
        }

        return res.json();
    } catch (error) {
        console.error('Failure applying template to financial statements for financial record id');
        console.error(`${workspaceId}: Financial record id: ${ financialRecordId }`)
        console.error(error);

        throw error;
    }
}

export async function GetImportStatus(
    orgId: string,
    financialRecordId: string
): Promise<FinancialImportStatus | undefined> {
    LogMessage(
        `GetImportStatus`,
        SeverityLevel.Information,
        {
            workspaceId: orgId,
            financialRecordId
        }
    );
    const url = `/api/${orgId}/financialrecords/${financialRecordId}/importstatus`;

    try {
        const res = await fetchWrapper(url,
            {
                method: FetchMethod.Get,
            },
            true,
            true
        );

        if (!res.ok) {
            return undefined;
        }

        return res.json();
    } catch (error) {
        // The general usage here is that we'll ping until we get a status so this isn't really an error.
        // Don't log anything here, it just clutters the log with garbage.

        return undefined;
    }
}

export function GetWorkbookURL(businessId: string, finanancialRecordId: string, variantId: string = 'FullAnalysis', financialRecordCreated: string, revisionNumber: string): string {
    return `/api/${businessId}/financialrecords/${finanancialRecordId}/workbooks/download/${variantId}/${revisionNumber}`;
}

export const GetDocumentURL = (businessId: string, submissionId: string, documentId: string): string => {
    return `/api/documents/${businessId}/${submissionId}/${documentId}`;
}

export const GetDocTime = (fullTime: string | undefined): string => {
    return !!fullTime ? dayjs(fullTime).format('hh:mm A') : '';
}

export async function RegenerateWorkbooks(
    orgId: string,
    financialRecordId: string
): Promise<WorkbookRevisionDescriptor | undefined> {
    LogMessage(
        `RegenerateWorkbooks`,
        SeverityLevel.Information,
        {
            workspaceId: orgId,
            financialRecordId
        }
    );
    const url = `/api/${orgId}/FinancialRecords/${financialRecordId}/workbooks/revisions`;

    try {
        const res = await fetchWrapper(url,
            {
                method: FetchMethod.Post,
            },
            true,
            true
        );

        if (!res.ok) {
            return undefined;
        }

        return res.json();
    } catch (error) {
        console.error('Failure regenerating workbooks');
        console.error(`${orgId}: Financial record id: ${financialRecordId}`)
        console.error(error);

        throw error;
    }
}

export async function ListFinancialStatementTemplates(): Promise<FinancialStatementTemplatesList | undefined> {
    LogMessage(`ListFinancialStatementTemplates`);
    const url = `/FinancialStatementTemplates`;

    try {
        const res = await fetchWrapper(url,
            {
                method: FetchMethod.Get,
            },
            true,
            true
        );

        if (!res.ok) {
            console.error(`Listing financial statement templates failed with no result`);
            console.error(res);
           
            return undefined;
        }

        return res.json();
    } catch (error) {
        console.error('Failure listing financial statement templates');
        console.error(error);

        throw error;
    }
}

export async function DeleteFinancialStatementTemplate(templateId: string): Promise<void> {
    LogMessage(`DeleteFinancialStatementTemplate`);
    const url = `/FinancialStatementTemplates/${templateId}`;

    try {
        const res = await fetchWrapper(url,
            {
                method: FetchMethod.Delete,
            },
            true,
            true
        );

        if (!res.ok) {
            console.error(`Deleting financial statement template failed with no result`);
            console.error(res);
        }
    } catch (error) {
        console.error('Failure deleting financial statement template');
        console.error(error);

        throw error;
    }
}

export async function GetWorkbookRevisionStatus(workspaceId: string, financialRecordId: string, revisionNumber: number): Promise<WorkbookRevisionStatus> {
    let url = `/api/${workspaceId}/financialrecords/${financialRecordId}/workbooks/${revisionNumber}/status`;

    try {
        const res = await fetchWrapper(url,
            {
                method: FetchMethod.Get,
            },
            true,
            true,
        );

        if (!res.ok) {
            throw new Error(`Unexpected status code from server ${res.status}`);
        }

        return res.json();
    } catch (error) {
        console.error('Failure getting workbook revision status');
        console.error(`${workspaceId}: Financial record id: ${financialRecordId} Revision number: ${revisionNumber}`);
        console.error(error);

        throw error;
    }
}