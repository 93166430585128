import * as React from 'react';

import {
    Col,
    Row,
    Table,
    Input
} from 'reactstrap';

import '../../Main.scss';

import {
    GlobalUserSettings,
    UserWorkspaceSettings,
    WorkspaceNotificationsByWorkspace
} from '../../../Store/UserSettings';

import { LogMessage, SeverityLevel } from '../../../Utils/Logging';

import {
    NotificationMethod,
    UserNotificationSetting,
    UserNotificationMethodSubscriptionStyle
} from '../../../Models/Api/strongbox.financialportal';

import { IndeterminateStates, IndeterminateCheckBox } from '../../IndeterminateCheckBox/IndeterminateCheckBox';

import { fixedNotificationType } from '../../../Utils/Constants';

/**
 * @parameter filter  if present, only workspaces whose name contains filter will be present. Comparisons are made
 * against lowercase version of the workspace name so filter MUST be lowercase.
 * */

type Props = {
    globalUserSettings: GlobalUserSettings | undefined;
    workspaceSettings: UserWorkspaceSettings | undefined;
    filter?: string;
    disabledNotifications?: { setting: UserNotificationSetting, notify: UserNotificationMethodSubscriptionStyle }[]
    onToggle?: (turnedOn: boolean, workspaceId: string, notificationId: number, notificationType: NotificationMethod) => void;
    onBulkToggle?: (turnedOn: boolean, workspaceIds: string[], notificationId: number, notificationType: NotificationMethod) => void;
}

export const WorkspaceOverrides: React.FC<Props> = (props): React.ReactElement => {
    const {
        disabledNotifications,
        filter,
        globalUserSettings,
        onBulkToggle,
        onToggle,
        workspaceSettings
    } = props;


    const getColumnIndeterminateState = (index: number): IndeterminateStates => {
        if (!workspaceSettings) {
            return 'indeterminate';
        }
        if ((index < 0) || (index >= workspaceSettings.settings.length)) {
            return 'indeterminate';
        }

        if (!(globalUserSettings && globalUserSettings.notificationData && globalUserSettings.notificationData.settings)) {
            // Sanity check, shouldn't be possible to get here.
            return 'indeterminate';
        }
        const notificationId = globalUserSettings.notificationData.settings[index].id;

        let allChecked = true;
        let allUnchecked = true;

        workspaceSettings.settings.forEach(setting => {
            // We don't rely on the data coming back being complete or identical for each
            // workspace, i.e. the set of notifications for two workspaces could be different.

            const iNotificationIndex = setting.notifications.findIndex(setting => setting.notificationId === notificationId);
            if (iNotificationIndex !== -1) {
                if (
                    !!setting.notifications[iNotificationIndex].notificationMethods &&
                    !!setting.notifications[iNotificationIndex].notificationMethods![0]
                ) {
                    if (setting.notifications[iNotificationIndex].notificationMethods![0].enabled) {
                        allUnchecked = false;
                    } else {
                        allChecked = false;
                    }
                }
            }
        })

        if (allChecked) {
            return 'checked';
        } else if (allUnchecked) {
            return 'unchecked';
        } else {
            return 'indeterminate';
        }
    }

    const changeAllColumnSettings = (index: number, checked: boolean): void => {
        if (!onBulkToggle) {
            return;
        }

        const workspaceIds: string[] = [];

        if (!workspaceSettings) {
            return;
        }

        // These are all sanity checks, none of these should be possible.
        if (!(globalUserSettings && globalUserSettings.notificationData && globalUserSettings.notificationData.settings)) {
            console.error('Somehow attempting to update all workspace settings but globalUserSettings is not in a coherent state');
            return;
        }
        if ((index < 0) || (index >= globalUserSettings.notificationData.settings.length)) {
            console.error(`index passed to changeAllColumnSettings is invalid ${index}`);
            return;
        }
        const notificationId = globalUserSettings.notificationData.settings[index].id;
        if (!notificationId) {
            console.error(`notification ID is undefined in changeAllColumnSettings based on the content of globalUserSettings`);
            return;
        }

        workspaceSettings.settings.forEach(setting => {
            if (!!setting.notifications[index].workspaceId) {
                workspaceIds.push(setting.notifications[index].workspaceId!);
            }
        });

        // This should be a sanity check, should never happen
        if (workspaceIds.length < 1) {
            const msg = `No workspaceIds found changing all workspace notifications, index=${index}`;

            console.error(msg);
            LogMessage(msg, SeverityLevel.Warning);
            return;
        }

        onBulkToggle(checked, workspaceIds, notificationId, fixedNotificationType);
    }

    const renderWorkspaceRow = (workspaceSetting: WorkspaceNotificationsByWorkspace, index: number): React.ReactElement[] => {
        // We shouldn't get here if this is the case so sanity check
        if (!(
            globalUserSettings &&
            globalUserSettings.notificationData.settings
        )) {
            return [(<></>)];
        }
        const result: React.ReactElement[] = [];

        globalUserSettings.notificationData.settings.forEach((notificationType, settingIndex) => {
            const inputKey = `workspaceNotification${workspaceSetting.workspaceId}:${notificationType.id}`;
            let inputControl = (
                <Input
                    type={'checkbox'}
                    className={'large'}
                    checked={false}
                    disabled={true}
                    key={inputKey}
                >
                </Input>
            )
            const controlClass = 'xs';

            const notificationSetting = workspaceSetting.notifications.find(notification => notification.notificationId === notificationType.id);
            if (!!notificationSetting && notificationSetting.notificationMethods && !!notificationType.notificationMethods) {
                const iFixedNotification = notificationSetting.notificationMethods.findIndex(method => method.type === fixedNotificationType);
                if (iFixedNotification !== -1) {
                    let notificationDisabled = false;

                    if (disabledNotifications !== undefined) {
                        if (!!disabledNotifications.find(notify => {
                            return notify.setting.id === notificationSetting.notificationId &&
                                notify.notify.type === notificationSetting.notificationMethods![iFixedNotification].type;
                        })) {
                            notificationDisabled = true;
                        }
                    }

                    const settingEnabled = notificationSetting.notificationMethods[iFixedNotification].enabled;
                    inputControl = (
                        <Input
                            type={'checkbox'}
                            className={'large'}
                            checked={settingEnabled && !notificationDisabled}
                            disabled={notificationDisabled}
                            key={inputKey}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                onToggle && onToggle(
                                    event.currentTarget.checked,
                                    notificationSetting.workspaceId || '',
                                    !!notificationSetting.notificationId ? notificationSetting.notificationId : -1,
                                    fixedNotificationType
                                )
                            }}
                        >
                        </Input>
                    );
                }
            }
            result.push((
                <td
                    key={`workspace-notification-setting-${index}-${settingIndex}`}
                    className={controlClass}
                >
                    {inputControl}
                </td>
            ))
        })

        result.push((
            <td
                className={'remainder'}
                key={`workspace-notification-name-${index}`}
            >
                <span>{workspaceSetting.workspaceName}</span>
            </td>
        ))
        return result;
    }

    const notificationHeaderDisabled = (notify: UserNotificationSetting): boolean => {
        if (!disabledNotifications) {
            return false;
        }
        return !!disabledNotifications.find(disabledNotify => disabledNotify.setting.id === notify.id);

    }

    return (
        <Row>
            <Col>
                {!!globalUserSettings && !!workspaceSettings && (
                    <Table key={'workspace-notifications-table'} className={'normal-list'}>
                        <thead>
                            <tr className={'litehilite'} key={'headerrow'}>
                                {
                                    globalUserSettings.notificationData.settings!.map((notify, index) => {
                                        return (
                                            <th
                                                key={`workspaceNotificationColumn${index}`}
                                                className={'xs'}
                                            >
                                                <div
                                                    style={{alignItems: 'center'}}
                                                >
                                                    {
                                                        notificationHeaderDisabled(notify) && (
                                                            <React.Fragment>
                                                                <span className={'xsmall'}>{notify.title}</span>
                                                                <span className={'xsmall emphasis-text'}>disabled</span>
                                                            </React.Fragment>
                                                        )
                                                    }
                                                    {!notificationHeaderDisabled(notify) && (
                                                        <React.Fragment>
                                                            <span className={'xsmall'}>{notify.title}</span>
                                                            <IndeterminateCheckBox
                                                                status={getColumnIndeterminateState(index)}
                                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                    changeAllColumnSettings(
                                                                        index,
                                                                        event.currentTarget.checked
                                                                    );
                                                                }}
                                                                className={'form-check-input'}
                                                            />
                                                        </React.Fragment>
                                                    )}
                                                </div>
                                            </th>
                                        );
                                    })
                                }
                                <th
                                    key={'workspaceNotificationColumnWorkspace'}
                                    className={'remainder'}
                                >
                                    <div>
                                        <span className={'xsmall'}>Workspace Name</span>
                                        <IndeterminateCheckBox
                                            status={'indeterminate'}
                                            key={'workspace-title-spacer'}
                                            style={{ visibility: 'hidden' }}
                                            className={'form-check-input'}
                                        />
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        {
                            workspaceSettings.settings && (
                                <tbody>
                                    {
                                        workspaceSettings.settings.map((workspaceSetting, index) => {
                                            if (!!filter) {
                                                if (!workspaceSetting.workspaceName.toLowerCase().includes(filter)) {
                                                    return undefined;
                                                }
                                            }
                                            return (
                                                <tr key={`workspaceNotificationSettings${index}`}>
                                                    {renderWorkspaceRow(workspaceSetting, index)}
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            )
                        }
                    </Table>
                )}
            </Col>
        </Row>
    )
}
