import * as React from 'react';

import {
    Col,
    Row
} from 'reactstrap';

import { BasicUserErrorMessages, BasicUserDetails } from './BasicUserDetails';

import {
    DetailedUserResponse,
    ResourceRole,
    RoleResponse,
    TenantUserMetadataSetting,
} from '../../../Models/Api/strongbox.financialportal';

import { PermissionsDetailPopover, PermissionsPopoverTarget } from './PermissionsPopover';

import { UserRoleSelection } from './UserRoleSelection';
import { AddUsersMetaDataItem } from './CommonUser';
import { EditUserMetadata } from './EditUserMetadata';

import '../../Main.scss';

type VisibleRole = {
    role: RoleResponse;
    expanded: boolean;
}

/**
 * @member { DetailedUserResponse } user is the currently selected from a list of users.
 * @member { string } loggedInUserExternalId is the external id (auth0) of the logged in user
 * */

type Props = {
    errorMessages?: BasicUserErrorMessages;
    availableRoles: RoleResponse[];
    user: DetailedUserResponse;
    loggedInUserExternalId: string;
    onUserChanged: (newValue: DetailedUserResponse) => void;
    userMetadataSettingsForTenant: TenantUserMetadataSetting[];
    metadataValues: Map<string, AddUsersMetaDataItem>;   // keyed on metadata value name
    onMetadataValueChanged: (name: string, newValue: string) => void;
}

export const UserDetails: React.FC<Props> = (props): React.ReactElement => {
    const {
        availableRoles,
        loggedInUserExternalId,
        userMetadataSettingsForTenant,
        user,
        metadataValues,
        onMetadataValueChanged,
    } = props;

    const [permissionsPopoverTarget, setPermissionsPopoverTarget] = React.useState<PermissionsPopoverTarget | undefined>(undefined);

    React.useEffect(() => {
        user.resourceRoles = user.resourceRoles.filter(resourceRole => {
            if (!!availableRoles.find(availableRole => availableRole.id === resourceRole.roleId)) {
                return resourceRole;
            }
            return undefined;
        })
    }, [availableRoles, user]);

    const buildRoles = (user: DetailedUserResponse, resourceId: string, turningOn: boolean, roleId: string): ResourceRole[] => {
        const result: ResourceRole[] = [];
        let foundRole = false;

        user.resourceRoles.forEach(role => {
            if (roleId !== role.roleId) {
                result.push(role);
            } else if (turningOn) {
                result.push(role);
                foundRole = true;
            }
        });

        if (turningOn && !foundRole) {
            result.push({
                resourceId,
                resourceType: 'Tenants',
                roleId
            });
        }

        return result;
    }

    return (
        <React.Fragment>
            <Row>
                <Col sm={12} md={6} style={{ paddingRight: '35px' }}>
                    <BasicUserDetails
                        errorMessages={props.errorMessages}
                        stackLabels={true}
                        excludeEmail={true}
                        disableEdit={user.externalAccountId === loggedInUserExternalId}
                        user={{
                            lastName: user.lastName,
                            firstName: user.firstName,
                            emailAddress: user.emailAddress,
                        }}
                        onChange={(newValue) => {
                            props.onUserChanged({
                                ...user,
                                lastName: newValue.lastName,
                                firstName: newValue.firstName,
                                emailAddress: newValue.emailAddress
                            });
                        }}
                        showEmailDomainError={false}
                        emailValid={true}
                    />
                </Col>
                <Col lg={12} xl={6} className={'border-container'} style={{ paddingLeft: '35px' }}>
                    <UserRoleSelection
                        availableRoles={availableRoles}
                        currentRoles={user.resourceRoles}
                        disabled={(loggedInUserExternalId === user.externalAccountId)}
                        disableDescriptions={!!permissionsPopoverTarget}
                        onChanged={(role, checked) => {
                            props.onUserChanged({
                                ...user,
                                resourceRoles: buildRoles(
                                    user,
                                    'not required',  // will be filled in by back-end but is required in api model.
                                    checked,
                                    role.id
                                )
                            });
                        }}
                        showDescriptionPopover={(role, target) => setPermissionsPopoverTarget({
                                target: `showpermissions${role.id}`,
                                role: role
                            })
                        }
                    />
                </Col>
            </Row>
            {
                permissionsPopoverTarget && (
                    <PermissionsDetailPopover
                        onToggle={() => { setPermissionsPopoverTarget(undefined); }}
                        target={permissionsPopoverTarget}
                    />
                )
            }
            {
                !!userMetadataSettingsForTenant && (
                    <div
                        style={{
                            marginTop: '15px'
                        }}
                    >
                        <EditUserMetadata
                            userMetadataSettingsForTenant={userMetadataSettingsForTenant}
                            metadataValues={metadataValues}
                            onValueChange={(name, value) => onMetadataValueChanged(name, value)}
                        />
                    </div>
                )
            }
        </React.Fragment>
    )
}
