import * as React from 'react';
import { connect } from 'react-redux';

import {
    Col,
    Container,
    Row
} from 'reactstrap';

import '../Main.scss';

import { BusyWindowWrapper } from '../LoadingMessageWithIndicator/BusyWindowWrapper';
import { SubmissionHistory } from '../SubmissionHistory/SubmissionHistory';

import { ApplicationState } from '../../Store';
import { GetPortalBusyState } from '../../Store/UIState';

import { breadCrumbContent, pathConstants } from '../../Utils/Constants';

import { BreadCrumbs } from '../BreadCrumbs/BreadCrumbs';

type InjectedReduxState = {
    portalBusy: boolean;
};

type InjectedActionCreators = {};

type DataCollectionReportProps = {
}

type Props = DataCollectionReportProps & InjectedActionCreators & InjectedReduxState;

const DataCollectionReportComponent: React.FC<Props> = (props): React.ReactElement => {
    return (
        <div
            style={{
                position: 'absolute',
                width: '100%',
                minWidth: '20px'
            }}
            className={`free-content-region`}
        >
            <div
                style={{
                    minWidth: '20px',
                    height: '100%'
                }}
                className={`contained-content control-region control-region-lender`}
            >
                <div style={{ height: '100%' }}>
                    <Container className={'portal-container-settings'} fluid>
                        <Row style={{ marginBottom: '1em' }} >
                            <Col>
                                <BreadCrumbs
                                    crumbs={[
                                        {
                                            content: breadCrumbContent.workspaceList,
                                            link: pathConstants.home,
                                        }
                                    ]}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <BusyWindowWrapper
                                    portalBusy={props.portalBusy}
                                >
                                    <SubmissionHistory
                                        submissionDetailsBase={pathConstants.allSubmissionDetails}
                                    />
                                </BusyWindowWrapper>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    );
}

export const DataCollectionReport = connect<InjectedReduxState, InjectedActionCreators, DataCollectionReportProps, ApplicationState>(
    (appState: ApplicationState) => {
        const result = {
            portalBusy: GetPortalBusyState(appState),
        };

        return result;
    }
)(DataCollectionReportComponent);

