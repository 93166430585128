import * as React from 'react';

import { DownloadableFile, WorkbookToDownloadableFile } from '../../Utils/FileUtils';

import { FileGroup } from './FileGroup';

import {
    ResourceRetentionPolicySummary,
    Submission,
    SubmissionDetail,
    SubmissionRequestSummary,
    SubmissionSearchEntitySummary
} from '../../Models/Api/strongbox.financialportal';

import { GetDocTime, GetDocumentURL } from '../../Services/FinancialRecordsService';

import { GroupedFiles } from '../../Utils/FileUtils';

export const NO_SUBMISSION_ID = 'NONE';

export type FileDownloadData = {
    downloading?: DownloadableFile[];
    downloadingIndex: number;
}

export const emptyFileDownloadData = {
    downloading: undefined,
    downloadingIndex: -1,
    downloadingGroup: undefined,
};

export type Props = {
    fileDownloadingData?: FileDownloadData;
    businessId: string;
    submissionDetail: SubmissionDetail;
    entityInfo: SubmissionSearchEntitySummary;
    retrievingFiles: boolean;
    noFilesAvailableMsg: string;
    loadedUserDisplayName?: string;
    containerClass?: string;
    getSubmission: (submissionId: string) => Submission | undefined;
    getSubmissionDetails: (submissionId: string) => SubmissionDetail | undefined;
    onDownloading?: (fileDownloadingData: FileDownloadData) => void;
    onClearDownloadingData?: () => void;
    onEmptyFile?: (file: DownloadableFile) => void;
    onSubmissionFilesNeeded?: (submissionId: string) => void;
    augmentTitleWithLatestRevision?: boolean;
}

export const FileGroupContainer: React.FC<Props> = (props): React.ReactElement => {
    const {
        businessId,
        containerClass,
        fileDownloadingData,
        getSubmission,
        getSubmissionDetails,
        loadedUserDisplayName,
        onClearDownloadingData,
        onDownloading,
        onEmptyFile,
        submissionDetail,
        entityInfo,
        augmentTitleWithLatestRevision,
    } = props;

    const getSubmittedBy = (submissionId: string): string | undefined => {
        const submission = getSubmission(submissionId);

        return submission && submission.submissionEmail;
    }

    const getSubmissionRequest = (submissionId: string): SubmissionRequestSummary | undefined => {
        const submission = getSubmission(submissionId);

        return submission && submission.submissionRequest;
    }

    const getSubmissionRetentionPolicy = (submissionId: string): ResourceRetentionPolicySummary | undefined => {
        const submission = getSubmissionDetails(submissionId);

        return submission && submission.retentionPolicy;
    }

    const isSubmissionVerified = (submissionId: string): boolean => {
        if (submissionId === NO_SUBMISSION_ID) {
            // for legacy data, no submission will have been created. all legacy submissions are 'verified'
            return true;
        }

        const submission = getSubmission(submissionId);

        return !!submission && (submission.isVerifiedContent || false);
    }

    const submitFiles = (files: DownloadableFile[], i: number): void => {
        if (i >= files.length) {
            onClearDownloadingData && onClearDownloadingData();
            return;
        }

        // Shouldn't have url's undefined here but just in case, don't crash.
        while (!(files[i].url)) {
            i++;
            if (i >= files.length) {
                onClearDownloadingData && onClearDownloadingData();
                return;
            }
        }

        var a = document.createElement('a');

        // ts-ignore is okay below, see the while loop above where we skip any url's that are undefined.
        // @ts-ignore
        a.href = files[i].url;
        a.target = '_parent';
        // Use a.download if available, it prevents plugins from opening.
        if ('download' in a) {
            a.download = files[i].filename;
        }
        // Add a to the doc for click to work.
        (document.body || document.documentElement).appendChild(a);
        if (a.click) {
            a.click(); // The click method is supported by most browsers.
        }
        // Delete the temporary link.
        if (a.parentNode) {
            a.parentNode.removeChild(a);
        }

        // Download the next file with a small timeout. The timeout is necessary
        // for IE, which will otherwise only download the first file.
        setTimeout(() => {
            submitFiles(files, i + 1);
        }, 500);


        onDownloading && onDownloading({
            ...fileDownloadingData,
            downloadingIndex: i + 1,
        });
    }

    const downloadFiles = async (files: DownloadableFile[]): Promise<void> => {
        submitFiles(files, 0);
        onClearDownloadingData && onClearDownloadingData();
    }


    const documentList = React.useMemo((): DownloadableFile[] => {
        if (!(submissionDetail && submissionDetail.documents)) {
            return [];
        }

        return submissionDetail.documents.map(doc => {
            return {
                filename: doc.fileName || 'Unnamed file',
                isDeleted: doc.isDeleted === true,
                id: doc.id,
                time: GetDocTime(doc.uploadTime),
                url: !!doc.id ?
                    GetDocumentURL(
                        entityInfo.id,
                        submissionDetail.id,
                        doc.id
                    ) : ''
            }
        });
    }, [submissionDetail, entityInfo]);

    const workbookList = React.useMemo((): GroupedFiles[] => {
        const result: GroupedFiles[] = [];
        if (!(submissionDetail.workbooks && entityInfo)) {
            return result;
        }

        submissionDetail.workbooks.forEach(wb => {
            if (!!wb) {
                let revisionGroup = result.find(group => wb.revisionNumber === group.revisionNumber);
                if (!revisionGroup) {
                    revisionGroup = {
                        revisionNumber: wb.revisionNumber,
                        revisionDate: new Date(wb.creationTime),
                        supportingDocuments: []
                    }
                    result.push(revisionGroup);
                }

                const newWb = WorkbookToDownloadableFile(
                    entityInfo.id,
                    submissionDetail.financialRecordId,
                    submissionDetail.financialRecordCreated,
                    wb
                );

                if (!!newWb) {
                    revisionGroup.supportingDocuments.push(newWb);
                }
            }
        });

        return result.sort((group1, group2) => {
            // We don't add anything to the array with a revision number that's undefined
            // when we build the array above so they will be defined.
            const compare = group1.revisionNumber! - group2.revisionNumber!;
            return compare === 0 ? 0 : compare < 0 ? 1 : -1;
        });

    }, [submissionDetail, entityInfo]);

    return (
        <FileGroup
            businessId={businessId}
            key={submissionDetail.id}
            uploadDate={props.submissionDetail.creationTime}
            documents={documentList}
            documentGroups={workbookList}
            retrievingFiles={props.retrievingFiles}
            noFilesAvailableMsg={props.noFilesAvailableMsg}
            verified={isSubmissionVerified(submissionDetail.id || '')}
            submittedBy={getSubmittedBy(submissionDetail.id || '')}
            submission={getSubmission(submissionDetail.id || '')}
            submissionMetadata={submissionDetail.submissionMetadata}
            submissionRequestSummary={getSubmissionRequest(submissionDetail.id || '')}
            retentionPolicySummary={getSubmissionRetentionPolicy(submissionDetail.id || '')}
            containerClass={containerClass}
            loadedUserDisplayName={loadedUserDisplayName}
            onDownload={(files, resultsGroup) => downloadFiles(files)}
            onEmptyFile={(file) => onEmptyFile && onEmptyFile(file)}
            augmentWithLatestRevision={augmentTitleWithLatestRevision}
        />
    );
}
