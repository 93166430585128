/* TODO:  
 * the loading state on workspace can get messed up in theory.   There's a single loading status, that needs to switch to a semaphore
 * that is not set when it's at 0.
 * 
 * This isn't practically an issue at the moment since the apis aren't used in a way where that happens, but it should be done.
 * Just takes a  little thought between the different kinds of objects that have loading flags.
 */

import { Reducer } from 'redux';

import { KnownAction, WorkspacesActions } from './Actions';

import { ConnectionsList, EntityDetailResponse, EntityResponse, UserCapability, UserResponse } from '../../Models/Api/strongbox.financialportal';

import { formatStringWorkspaceTime, formatStringWorkspaceTimeFull, FormatDate } from '../../Utils/DateUtils';

/**
 * @member {id} id of the workspace to which these details apply
 * @member {loading} are the details for this workspace currently loading
 * @member {users} list of the users with access to this workspace
 **/

export type WorkspacePlusUser = {
    id: string;
    connections?: ConnectionsList;
    details?: EntityDetailResponse;
    loading: boolean;
    users: UserResponse[];
    initialUserState: UserResponse[];
};

export type WorkspaceWithMeta = EntityResponse & {
    displayDateFull: string;
    displayDate: string;
    lastSyncDisplayDateFull: string;
    lastSyncDisplayDate: string;
};

export type WorkspacePageInfo = {
    workspacesPerPage: number;
    totalWorkspaces: number;
    currentPage: number;
    searchTerm: string;
}

export const uninitializedPageInfo: WorkspacePageInfo = {
    workspacesPerPage: -1,
    totalWorkspaces: -1,
    currentPage: -1,
    searchTerm: ''
}

/**
 * @member{inactiveWorkspaces} We load workspaces per page, so the active workspace page is kept in workspaces.   
 * When a new page is loaded, we take whatever workspaces are not in that new list and put them in inactiveWorkspaces
 * i.e., inactiveWorkspaces should only contain workspaces that aren't in the current active list (showing in the workspaces tab)
 * */

export interface IWorkspacesState {
    loading: boolean;
    workspaces: WorkspaceWithMeta[];
    inactiveWorkspaces: WorkspaceWithMeta[];
    pageInfo: WorkspacePageInfo;
    details: WorkspacePlusUser[];
}

export const defaultWorkspacesState: IWorkspacesState = {
    loading: false,
    workspaces: [],
    inactiveWorkspaces: [],
    pageInfo: uninitializedPageInfo,
    details: [],
}

export function MappedWorkspace(workspace: EntityResponse): WorkspaceWithMeta {
    const displayDateFull = FormatDate(new Date(workspace.lastActivityTime), formatStringWorkspaceTimeFull);
    const displayDate = FormatDate(new Date(workspace.lastActivityTime), formatStringWorkspaceTime);
    const lastSyncDisplayDateFull = workspace.lastSyncTime ? FormatDate(new Date(workspace.lastSyncTime), formatStringWorkspaceTimeFull) : '';
    const lastSyncDisplayDate = workspace.lastSyncTime ? FormatDate(new Date(workspace.lastSyncTime), formatStringWorkspaceTime) : '';

    return {
        ...workspace,
        displayDate,
        displayDateFull,
        lastSyncDisplayDate,
        lastSyncDisplayDateFull,
    };
}

function SetWorkspaceLoadingStatus(state: IWorkspacesState | undefined, id: string, status: boolean): IWorkspacesState | undefined {
    let newState = {
        ...(state ? state : defaultWorkspacesState),
    }
    const iDetail = newState.details.findIndex(dets => dets.id === id);
    if (iDetail === -1) {
        return undefined;
    }

    newState.details = newState.details.slice();
    newState.details[iDetail].loading = true;

    return newState;
}

function buildInactiveWorkspaces(curState: IWorkspacesState, newWorkspaces: WorkspaceWithMeta[]): WorkspaceWithMeta[] {
    // This concat will generate a list without duplicates as we make sure that 
    // the two lists don't contain duplicates.
    const temp = curState.workspaces.concat(curState.inactiveWorkspaces);

    // Filter all the members out of the concatenated result from above that
    // exist in the list of new workspaces
    return temp.filter(workspace => {
        return !!newWorkspaces.find(newWorkspace => newWorkspace.id === workspace.id) ? undefined : workspace;
    });
}

export const reducer: Reducer<IWorkspacesState, KnownAction> = (state: IWorkspacesState | undefined, action: KnownAction): IWorkspacesState => {
    let newState: IWorkspacesState | undefined = undefined;

    switch (action.type) {
        case WorkspacesActions.LoadWorkspaces: {
            if (!(!!state && state.loading)) {
                newState = {
                    ...(state ? state : defaultWorkspacesState),
                    loading: true,
                }
            }
            break;
        }
        case WorkspacesActions.LoadWorkspacesComplete: {
            newState = {
                ...(state ? state : defaultWorkspacesState),
                loading: false,
                pageInfo: action.pageInfo
            }
            newState.inactiveWorkspaces = buildInactiveWorkspaces(newState, action.workspaces);
            newState.workspaces = action.workspaces;
            break;
        }
        case WorkspacesActions.DeleteWorkspace: {
            break;
        }
        case WorkspacesActions.DeleteWorkspaceComplete: {
            newState = {
                ...(state ? state : defaultWorkspacesState),
            }
            const iWorkspace = newState.workspaces.findIndex(ws => ws.id === action.workspaceId);
            if (iWorkspace !== -1) {
                newState.workspaces = newState.workspaces.slice(0, iWorkspace).concat(newState.workspaces.slice(iWorkspace + 1));
            }
            break;
        }
        case WorkspacesActions.AddLoadedWorkspace: {
            newState = {
                ...(state ? state : defaultWorkspacesState),
            }
            const iWorkspace = newState.workspaces.findIndex(ws => ws.id === action.workspace.id);
            if (iWorkspace === -1) {
                newState.workspaces = newState.workspaces.slice(0, iWorkspace).concat(newState.workspaces.slice(iWorkspace + 1));
                newState.workspaces.push(MappedWorkspace(action.workspace));
            }
            break;
        }
        case WorkspacesActions.LoadWorkspaceDetails: {
            newState = {
                ...(state ? state : defaultWorkspacesState),
            }
            const iDetail = newState.details.findIndex(dets => dets.id === action.id);
            if (iDetail !== -1) {
                newState.details = newState.details.slice();
                newState.details[iDetail].loading = true;
            } else {
                newState.details = newState.details.concat({
                    id: action.id,
                    details: undefined,
                    loading: true,
                    users: [],
                    initialUserState: []
                });
            }
            break;
        }
        case WorkspacesActions.LoadWorkspaceDetailsComplete: {
            if (action.userDetails && action.workspaceDetails) {
                newState = {
                    ...(state ? state : defaultWorkspacesState),
                }

                const iDetail = newState.details.findIndex(dets => dets.id === action.id);
                if (iDetail !== -1) {
                    newState.details = newState.details.slice();
                    newState.details[iDetail] = {
                        ...newState.details[iDetail],
                        details: {
                            ...action.workspaceDetails
                        },
                        users: action.userDetails.users.slice(),
                        loading: false,
                        initialUserState: action.userDetails.users.slice()
                    }
                } else {
                    // This shouldn't actually happen but just in case.
                    newState.details = newState.details.concat({
                        id: action.id,
                        details: {
                            ...action.workspaceDetails
                        },
                        users: action.userDetails.users.slice(),
                        loading: false,
                        initialUserState: action.userDetails.users.slice()
                    });
                }
            }
            break;
        }
        // These both basically find the workspace and change it's loading status to true.
        case WorkspacesActions.SaveWorkspaceUsers:
        case WorkspacesActions.ChangeWorkspaceUserStatus: {
            newState = SetWorkspaceLoadingStatus(state, action.id, true);
            break;
        }
        case WorkspacesActions.SaveWorkspaceUsersComplete: {
            newState = {
                ...(state ? state : defaultWorkspacesState),
            }
            const iDetail = newState.details.findIndex(dets => dets.id === action.id);
            if (iDetail === -1) {
                newState = undefined;
            } else {
                newState.details = newState.details.slice();
                newState.details[iDetail].initialUserState = newState.details[iDetail].users.slice();
                newState.details[iDetail].loading = false;
            }
            break;
        }
        case WorkspacesActions.ChangeWorkspaceUserStatusComplete: {
            newState = {
                ...(state ? state : defaultWorkspacesState),
            }
            const iDetail = newState.details.findIndex(dets => dets.id === action.id);
            if (iDetail === -1) {
                newState = undefined;
            } else {
                newState.details = newState.details.slice();

                const iUser = newState.details[iDetail].users.findIndex(user => user.id === action.user.id);
                if (action.include) {
                    // If it's NOT already included, include it.
                    if (iUser === -1) {
                        // Make the actual entry for this detail change from the top down.
                        newState.details = newState.details.slice();
                        newState.details[iDetail] = {
                            ...newState.details[iDetail]
                        };
                        newState.details[iDetail].users = newState.details[iDetail].users.concat(action.user);
                    }
                } else {
                    // If it's actually in the list, remove it.
                    if (iUser !== -1) {
                        // Make the actual entry for this detail change from the top down.
                        newState.details = newState.details.slice();
                        newState.details[iDetail] = {
                            ...newState.details[iDetail]
                        };
                        newState.details[iDetail].users =
                            newState.details[iDetail].users.slice(0, iUser).concat(
                                newState.details[iDetail].users.slice(iUser + 1)
                            );
                    }
                }
                newState.details[iDetail].loading = false;
            }
            break;
        }
        case WorkspacesActions.BulkRemoveAllWorkspaceUsers: {
            newState = {
                ...(state ? state : defaultWorkspacesState),
            }
            const iDetail = newState.details.findIndex(dets => dets.id === action.id);
            if (iDetail === -1) {
                newState = undefined;
            } else {
                newState.details = newState.details.slice();
                newState.details[iDetail] = {
                    ...newState.details[iDetail]
                };
                newState.details[iDetail].loading = true;
            }
            break;
        }
        case WorkspacesActions.BulkRemoveAllWorkspaceUsersComplete: {
            newState = {
                ...(state ? state : defaultWorkspacesState),
            }
            const iDetail = newState.details.findIndex(dets => dets.id === action.id);
            if (iDetail === -1) {
                newState = undefined;
            } else {
                newState.details = newState.details.slice();
                newState.details[iDetail] = {
                    ...newState.details[iDetail]
                };
                newState.details[iDetail].users = !!action.exclude ? action.exclude.slice() : [];
                newState.details[iDetail].loading = false;
            }
            break;
        }
        case WorkspacesActions.BulkAddWorkspaceUsers: {
            newState = {
                ...(state ? state : defaultWorkspacesState),
            }
            const iDetail = newState.details.findIndex(dets => dets.id === action.id);
            if (iDetail === -1) {
                newState = undefined;
            } else {
                newState.details = newState.details.slice();
                newState.details[iDetail] = {
                    ...newState.details[iDetail]
                };
                newState.details[iDetail].loading = true;
            }
            break;
        }
        case WorkspacesActions.BulkAddWorkspaceUsersComplete: {
            newState = {
                ...(state ? state : defaultWorkspacesState),
            }
            const iDetail = newState.details.findIndex(dets => dets.id === action.id);
            if (iDetail === -1) {
                newState = undefined;
            } else {
                newState.details = newState.details.slice();
                newState.details[iDetail] = {
                    ...newState.details[iDetail]
                };
                newState.details[iDetail].users = newState.details[iDetail].users.concat(action.users);
                newState.details[iDetail].loading = false;
            }
            break;
        }
        case WorkspacesActions.ResetWorkspaceUsers: {
            newState = {
                ...(state ? state : defaultWorkspacesState),
            }
            const iDetail = newState.details.findIndex(dets => dets.id === action.id);
            if (iDetail === -1) {
                newState = undefined;
            } else {
                newState.details = newState.details.slice();
                newState.details[iDetail] = {
                    ...newState.details[iDetail],
                    users: newState.details[iDetail].initialUserState.slice()
                }
            }
            break;
        }
        case WorkspacesActions.UpdateLoadedWorkspaceDetails: {
            newState = {
                ...(state ? state : defaultWorkspacesState),
            }
            const iWorkspace = newState.workspaces.findIndex(ws => ws.id === action.id);
            if (iWorkspace !== -1) {
                newState.workspaces = newState.workspaces.slice();
                newState.workspaces[iWorkspace].displayName = action.newName;
            }
            const iDetailed = newState.details.findIndex(ws => ws.id === action.id);
            if ((iDetailed !== -1) && (!!newState.details[iDetailed].details)) {
                newState.details = newState.details.slice();
                newState.details[iDetailed].details!.displayName = action.newName;
                newState.details[iDetailed].details!.engagementCode = action.newEngagementCode;
            }

            break;
        }
        case WorkspacesActions.LoadWorkspaceConnections: {
            newState = {
                ...(state ? state : defaultWorkspacesState),
            }
            const iDetail = newState.details.findIndex(ws => ws.id === action.id);
            if (iDetail === -1) {
                newState = undefined;
            } else {
                newState.details = newState.details.slice();
                newState.details[iDetail] = {
                    ...newState.details[iDetail]
                };
                newState.details[iDetail].loading = true;
            }
            break;
        }
        case WorkspacesActions.LoadWorkspaceConnectionsComplete: {
            newState = {
                ...(state ? state : defaultWorkspacesState),
            }
            const iDetail = newState.details.findIndex(ws => ws.id === action.id);
            if (iDetail === -1) {
                newState = undefined;
            } else {
                newState.details = newState.details.slice();
                newState.details[iDetail] = {
                    ...newState.details[iDetail],
                    loading: false,
                    connections: action.connections
                };
            }
            break;
        }
        case WorkspacesActions.DeleteWorkspaceConnection: {
            // Intentionally don't do anything here yet.
            break;
        }
        case WorkspacesActions.DeleteWorkspaceConnectionComplete: {
            if (action.success) {
                newState = {
                    ...(state ? state : defaultWorkspacesState),
                }
                const iDetail = newState.details.findIndex(ws => ws.id === action.id);
                if (iDetail === -1) {
                    newState = undefined;
                } else {
                    if (!newState.details[iDetail].connections) {
                        newState = undefined;
                    } else {
                        const iConnection = newState.details[iDetail].connections!.connections.findIndex(connection => connection.id === action.connectionId);
                        if (iConnection === -1) {
                            newState = undefined;
                        } else {
                            newState.details = newState.details.slice();
                            // Basically all we're doing here is constructing a new version of this detail set, i.e. for one
                            // workspace that does not have the connection being removed.
                            newState.details[iDetail] = {
                                ...newState.details[iDetail],
                                connections: {
                                    ...newState.details[iDetail].connections,
                                    connections:
                                        newState.details[iDetail].connections!.connections.slice(0, iConnection)
                                        .concat(newState.details[iDetail].connections!.connections.slice(iConnection + 1))
                                }
                            }
                        }
                    }
                }
            }
            break;
        }
        case WorkspacesActions.OverrideWorkspaceAccessibilityForUser: {
            newState = {
                ...(state ? state : defaultWorkspacesState),
            }
            const iWorkspace = newState.workspaces.findIndex(ws => ws.id === action.id);
            if (iWorkspace === -1) {
                newState = undefined;
            } else {
                newState.workspaces = newState.workspaces.slice();
                let iCapability = -1
                let capabilities: UserCapability[] = [];

                if (!!newState.workspaces[iWorkspace].workspaceUserCapabilities) {
                    capabilities = newState.workspaces[iWorkspace]!.workspaceUserCapabilities!;
                    iCapability = capabilities.findIndex(cap => cap === 'CanViewWorkspace');
                }
                if (action.allow) {
                    if (iCapability === -1) {
                        capabilities.push('CanViewWorkspace');
                    }
                } else {
                    if (iCapability !== -1) {
                        capabilities = capabilities.slice(0, iCapability).concat(capabilities.slice(iCapability + 1));
                    }
                }
                newState.workspaces[iWorkspace] = {
                    ...newState.workspaces[iWorkspace],
                    workspaceUserCapabilities: capabilities,
                }
            }
            break;
        }

    }

    if (newState) {
        return newState;
    } else if (state) {
        return state;
    } else {
        let defaultCopy: IWorkspacesState = {
            ...defaultWorkspacesState,
        };
        return defaultCopy;
    }
}
