import { LogoutOptions } from "@auth0/auth0-react";

import { AppCookieData } from '../../Models/CookieData';
import { store } from '../../index';

import { defaultAuth0RedirectLogout } from './Reducer';

import {
    UserActions,
    ILoadUsersAction,
    ILoadUsersCompleteAction,
    ISetLoggedInUser,
    IInitializeAction,
    IAppLogUserOut,
    IAppSetSessionInfo,
    ILoadDetailedUsersCompleteAction,
} from './Actions';

import { ListDetailedUsers, ListUsers } from '../../Services/UserService';

import { DetailedUserResponse, PostLoginUserResponse, UserResponse } from '../../Models/Api/strongbox.financialportal';

import { FetchMethod, fetchWrapper } from '../../Utils/FetchWrapper';
import { GetFullPathRelativeToCurrentWindow } from '../../Utils/PathUtils';

function LoadUsers(): ILoadUsersAction {
    return {
        type: UserActions.LoadUsers,
    };
}

function LoadUsersComplete(users: UserResponse[]): ILoadUsersCompleteAction {
    return {
        type: UserActions.LoadUsersComplete,
        users,
    };
}

export async function LoadAllUsers(onError?: (msg: string) => void): Promise<UserResponse[]> {
    store.dispatch(LoadUsers());

    let users: UserResponse[] = [];
    try {
        users = await ListUsers();
    } catch (exception) {
        onError && onError(exception.toString());
    }

    store.dispatch(LoadUsersComplete(users));

    return users;
}

function LoadDetailedUsersComplete(detailedUsers: DetailedUserResponse[]): ILoadDetailedUsersCompleteAction {
    return {
        type: UserActions.LoadDetailedUsersComplete,
        detailedUsers,
    };
}

export async function LoadAllDetailedUsers(onError?: (msg: string) => void): Promise<DetailedUserResponse[]> {
    store.dispatch(LoadUsers());

    let users: DetailedUserResponse[] = [];
    try {
        users = await ListDetailedUsers();
    } catch (exception) {
        onError && onError(exception.toString());
    }

    store.dispatch(LoadDetailedUsersComplete(users));

    return users;
}

function SetLoggedInUser(user?: PostLoginUserResponse): ISetLoggedInUser {
    return {
        type: UserActions.SetLoggedInUser,
        user,
    };
}

function Initialize(appData: AppCookieData): IInitializeAction {
    return {
        type: UserActions.Initialize,
        appData,
    };
}


/**
 * Set authorization information for a user.
 * 
 * @param token - auth0 token returned from getAccessTokenSilently.
 * @param userRoleIds - array of role ids associatd with this user.
 */
function SetSessionInfo(token: string | undefined, userRoleIds: string[]): IAppSetSessionInfo {
    return {
        type: UserActions.SetSessionInfo,
        token,
    };
}

function LogUserOut(auth0Logout: (options?: LogoutOptions) => void, returnToUrl?: string): IAppLogUserOut {
    fetchWrapper('/api/auth/signout',
        {
            method: FetchMethod.Post,
        },
        true,
        true)
        .finally(() => {
            const options: LogoutOptions = {
                returnTo: returnToUrl || GetFullPathRelativeToCurrentWindow(defaultAuth0RedirectLogout),
            };

            auth0Logout(options);
        });

    return { type: UserActions.LogUserOut }
}

export const actionCreators = {
    LoadUsers: LoadUsers,
    LoadUsersComplete: LoadUsersComplete,
    SetLoggedInUser: SetLoggedInUser,
    UsersInitialize: Initialize,
    SetSessionInfo: SetSessionInfo,
    LogUserOut: LogUserOut,
};
