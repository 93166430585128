import { ApplicationState } from '../index';

import { SubmissionRecord } from './Reducer';

export function GetSubmission(state: ApplicationState, submissionId: string): SubmissionRecord | undefined {
    if (!(state && state.submission && state.submission.submissions.has(submissionId))) {
        return undefined;
    }
    return state.submission.submissions.get(submissionId);
}
