import * as React from 'react';
import { connect } from 'react-redux';

import { Navigate } from 'react-router-dom';

import {
    Button,
    Container,
    Col,
    Row
} from 'reactstrap';

import { ApplicationState } from '../../Store';
import { actionCreators as UserActions, GetAuth0LoggingOut } from '../../Store/User';

import { pathConstants } from '../../Utils/Constants';

import {
    ResourceName,
    RetrieveResource,
    RetrieveStyle,
    TenantParagraphSet,
} from '../../Store/Tenant';

type InjectedReduxState = {
    h1Style: any;
    loggingOut: boolean;
    notificationParagraphs: TenantParagraphSet;
};

type InjectedActionCreators = typeof UserActions;

type SlidingTimeoutProps = {
};

type Props = SlidingTimeoutProps & InjectedReduxState & InjectedActionCreators;

const SlidingTimeoutComponent: React.FC<Props> = (props): React.ReactElement => {
    const {
        h1Style,
        loggingOut,
        notificationParagraphs
    } = props;

    const [returnToLogin, setReturnToLogin] = React.useState<boolean>(false);

    const paragraphs: React.ReactNode[] = React.useMemo(() => {
        const result: React.ReactNode[] = [];

        if (!!notificationParagraphs) {
            notificationParagraphs.paragraphs.forEach((paragraph, index) => {
                result.push((
                    <h3 key={`slidingWindowTimedOutParagraph${index}`} style={{ marginTop: '20px' }}>{paragraph}</h3>
                ));
            });
        }

        return result;
    }, [notificationParagraphs])

    if (returnToLogin) {
        return <Navigate to={pathConstants.login} />
    }

    return (
        <>
            {
                !!loggingOut && (
                    <div style={{ position: 'absolute', width: '100%' }} className={`free-content-region control-region control-region-lender`}>
                        <div style={{ height: '100%' }} className={'contained-content'}>
                            <h1 style={h1Style}>{'Logging Out...'}</h1>
                        </div>
                    </div>
                )
            }
            {
                !loggingOut && (
                    <div className={`full-screen-region lender-region content-region control-region control-region-lender`}>
                        <Container fluid>
                            {
                                paragraphs.map(pg => (
                                    <Row>
                                        <Col>
                                            {pg}
                                        </Col>
                                    </Row>
                                ))
                            }
                            <Row style={{ marginTop: '40px' }} >
                                <Col>
                                    <Button
                                        className={'small'}
                                        onClick={() => {
                                            setReturnToLogin(true);
                                        }}
                                    >
                                        Return to Login
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                )
            }
        </>
    );
}

export const SlidingTimeout = connect<InjectedReduxState, InjectedActionCreators, SlidingTimeoutProps, ApplicationState>(
    (appState: ApplicationState) => {
        return {
            loggingOut: GetAuth0LoggingOut(appState),
            h1Style: RetrieveStyle(appState, 'h1-login'),
            notificationParagraphs: RetrieveResource(appState, ResourceName.tenantSlidingWindowTimedOut),
        };
    },
    {
        ...UserActions
    }
)(SlidingTimeoutComponent);

