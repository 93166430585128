import { FormatDate, formatStringMDYFullMonth, formatStringMYFullMonth } from './DateUtils';

import { PortalCreateSubmissionRequestOptions } from '../Models/PortalCreateSubmissionRequestOptions';

export function GetCollectionRangeSummary(requestSummary: PortalCreateSubmissionRequestOptions): string {
    // Can't just do a ! here, month can be 0.  If we don't actually have a mostRecentPeriodDay which is possible for
    // older collections, the value won't be undefined, it'll be null so this if statement will not trigger.

    if ((requestSummary.mostRecentPeriodMonth === undefined) ||
        (requestSummary.mostRecentPeriodYear === undefined) ||
        (requestSummary.mostRecentPeriodDay === undefined)) {
        return '';
    }

    // Originally we didn't track the mostRecentPeriodDay value so for older requests, it can be undefined here.  Fall
    // back to that original handling.

    let d: Date;
    let formatString: string;

    if (requestSummary.mostRecentPeriodDay === null) {
        d = new Date(requestSummary.mostRecentPeriodYear, requestSummary.mostRecentPeriodMonth);
        formatString = formatStringMYFullMonth;
    } else {
        d = new Date(requestSummary.mostRecentPeriodYear, requestSummary.mostRecentPeriodMonth, requestSummary.mostRecentPeriodDay);
        formatString = formatStringMDYFullMonth;
    }

    if (requestSummary.fullFiscalYearsToCollect !== 0) {
        const fullYears = requestSummary.fullFiscalYearsToCollect.toString();

        return `Financial information provided for ${fullYears} full year${requestSummary.fullFiscalYearsToCollect !== 1 ? 's' : ''} and year-to-date through ${FormatDate(d, formatString)}`;
    } else {
        return `Financial information provided year-to-date through ${FormatDate(d, formatString)}`;
    }
}

export function GetBasisOfAccountingDescription(requestSummary: PortalCreateSubmissionRequestOptions): string {
    if (!!requestSummary.basisOfAccountingPreference) {
        if (requestSummary.basisOfAccountingPreference === 'CashAndAccrual') {
            return 'Cash and Accrual'
        } else {
            return requestSummary.basisOfAccountingPreference;
        }
    } else {
        return 'Not available';
    }
}

export function GetTypicalAccountingPkgImportDuration(accountingPkg: string): string | undefined {
    let range: string | undefined = undefined;

    switch (accountingPkg) {
        case 'quickbooksdesktop':
            range = '2-9 minutes';
            break;

        case 'quickbooksonline':
            range = '3-5 minutes';
            break;

        case 'xero':
            range = '2-10 minutes';
            break;

        case 'sageintacct':
            range = '3-12 minutes';
            break;
        case 'netsuite':
            range = '5-15 minutes';
            break;
        case 'myobbusiness':
            range = '5-15 minutes';
            break;
        case 'dynamics365':
            range = '5-15 minutes';
            break;
    }
    return range;
}
