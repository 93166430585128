import * as React from 'react';
import { connect } from 'react-redux';
import {
    Button,
    Col,
    Container,
    Modal,
    ModalBody,
    ModalFooter,
    Row
} from 'reactstrap';

import { CollectNewUserInfo } from './CollectNewUserInfo';
import { InvitedUserList } from './InvitedUserList';

import { LoadingIndicator } from '../../LoadingIndicator/LoadingIndicator';

import {
    CreateUserRequest,
    RoleResponse,
    ResourceRole,
    DetailedUserResponse,
    TenantUserMetadataSetting,
    UserMetadataItem,
} from '../../../Models/Api/strongbox.financialportal';

import { ApplicationState } from '../../../Store';
import { actionCreators as UIStateActionCreators } from '../../../Store/UIState';
import { actionCreators as UserActionCreators } from '../../../Store/User';
import { GetDefaultRole } from '../../../Store/UserRoles';
import { GetUnsafeContentMessage, InputStringContainsDangerousContent, TestEmail } from '../../../Utils/FormUtils';
import {
    GetEmailDomains,
    GetUserMetadataSettings,
} from '../../../Store/Tenant';

import { CreateAndInviteUsers, CreateUserResult } from '../../../Services/UserService';

import { PermissionsDetailPopover, PermissionsPopoverTarget } from './PermissionsPopover';

import { GetRoles } from '../../../Store/UserRoles';

import { AddUsersMode, AddUsersMetaDataItem, IsMetadataInputValid } from './CommonUser';

import '../../Main.scss';

type InjectedReduxState = {
    defaultRole: RoleResponse | undefined;
    availableRoles: RoleResponse[];
    emailDomainsForTenant: string[];
    userMetadataSettings: TenantUserMetadataSetting[];
};

type InjectedActionCreators = typeof UIStateActionCreators & typeof UserActionCreators;

type AddUsersModalProps = {
    dismissModal: () => void;
    existingUsers: DetailedUserResponse[];
};

type Props = AddUsersModalProps & InjectedActionCreators & InjectedReduxState;

const emptyUser: CreateUserRequest = {
    lastName: '',
    firstName: '',
    emailAddress: '',
    displayName: '',
}

const AddUsersModalComponent: React.FC<Props> = (props): React.ReactElement => {
    const {
        availableRoles,
        defaultRole,
        dismissModal,
        emailDomainsForTenant,
        existingUsers,
        userMetadataSettings,
    } = props;

    const [currentInputUser, setCurrentInputUser] = React.useState<CreateUserRequest>(emptyUser);
    const [newUsers, setNewUsers] = React.useState<CreateUserRequest[]>([]);
    const [invitingUsers, setInvitingUsers] = React.useState<boolean>(false);
    const [errorList, setErrorList] = React.useState<CreateUserResult[] | undefined>(undefined);

    const [dangerousContent, setDangerousContent] = React.useState<{ firstName?: string, lastName?: string, email?: string }>({});
    const [permissionsPopoverTarget, setPermissionsPopoverTarget] = React.useState<PermissionsPopoverTarget | undefined>(undefined);
    const [currentRoles, setCurrentRoles] = React.useState<ResourceRole[]>([]);

    const [editMode, setEditMode] = React.useState<AddUsersMode>('editrequired');
    const [userMetadata, setUserMetadata] = React.useState<Map<string, AddUsersMetaDataItem>>(new Map<string, AddUsersMetaDataItem>());

    const roleResponseToResourceRole = (roleResponse: RoleResponse): ResourceRole => {
        return { resourceId: '', resourceType: 'Users', roleId: roleResponse.id }
    }

    React.useEffect(() => {
        let newCurrentRoles: ResourceRole[] = [];

        if (!!availableRoles) {
            const defaultRole = availableRoles.find(role => {
                if (!role.tags) {
                    return false;
                }
                return !!role.tags.find(tag => tag.toLowerCase() === 'default');
            })
            if (!!defaultRole) {
                newCurrentRoles = [roleResponseToResourceRole(defaultRole)];
            }
        }
        setCurrentRoles(newCurrentRoles);
    }, [availableRoles])

    const addUserToNewList = (): void => {
        if (!(
            currentInputUser.firstName &&
            TestEmail(currentInputUser.emailAddress)
        )) {
            return;
        }

        let safeContent = true;
        let firstNameError: string | undefined = undefined;
        let lastNameError: string | undefined = undefined;
        let emailError: string | undefined = undefined;

        if (InputStringContainsDangerousContent(currentInputUser.firstName)) {
            firstNameError = GetUnsafeContentMessage('first name');
            safeContent = false;
        }
        if (!!currentInputUser.lastName && InputStringContainsDangerousContent(currentInputUser.lastName)) {
            lastNameError = GetUnsafeContentMessage('last name');
            safeContent = false;
        }
        if (InputStringContainsDangerousContent(currentInputUser.emailAddress)) {
            emailError = GetUnsafeContentMessage('email address');
            setDangerousContent({ ...dangerousContent, email: GetUnsafeContentMessage('email address') });
            safeContent = false;
        }
        if (!safeContent) {
            setDangerousContent({
                lastName: lastNameError,
                firstName: firstNameError,
                email: emailError
            });
            return;
        }

        const existingUser = existingUsers.find(currentUser =>
            currentUser.emailAddress.toLowerCase() === currentInputUser.emailAddress.toLowerCase()
        );

        if (!!existingUser) {
            setErrorList([{
                ok: false,
                emailAddress: currentInputUser.emailAddress,
                errorMsg: `This email address ${currentInputUser.emailAddress} has already been used by ${existingUser.displayName}`,
            }])
            return;
        }

        const newlyAddedUser = newUsers.find(currentUser =>
            currentUser.emailAddress.toLowerCase() === currentInputUser.emailAddress.toLowerCase()
        );

        if (!!newlyAddedUser) {
            setErrorList([{
                ok: false,
                emailAddress: currentInputUser.emailAddress,
                errorMsg: `This email address ${currentInputUser.emailAddress} has already been used by ${newlyAddedUser.displayName}`,
            }])
            return;
        }

        const metadata: UserMetadataItem[] = [];

        userMetadata.forEach(m => {
            metadata.push({ ...m.metaDataItem });
        });

        const userToAdd: CreateUserRequest = {
            ...currentInputUser,
            roleIds: currentRoles.map(cr => cr.roleId),
            metadata,
        }

        setNewUsers(newUsers.slice().concat([userToAdd]));
        setCurrentInputUser(emptyUser);
        setUserMetadata(new Map<string, AddUsersMetaDataItem>());
    }

    const createAndInviteUsers = (): void => {
        if (newUsers.length <= 0) {
            return;
        }

        CreateAndInviteUsers(newUsers, defaultRole)
            .then((perUserResults: CreateUserResult[]) => {
                setInvitingUsers(false);

                let newUserResponses: DetailedUserResponse[] = [];
                let containsErrors = false;

                // You could do this with map but linting doesn't like it.
                // This is the cleanest way to get lint to understand there's
                // a pure array of UserResponse as opposed to an array of UserResponse
                // or undefined.  ok can be false and user can be valid if the user
                // was created but the invitation failed.

                perUserResults.forEach(newUser => {
                    // newUser.user could be valid if the user was created but not invited.  In that
                    // case we want to add them to the store but we will still treat it as an error
                    if (!!newUser.user) {
                        newUserResponses = newUserResponses.concat([newUser.user]);
                    }
                    if (!(newUser.ok && newUser.user)) {
                        containsErrors = true;
                    }
                })

                if (!containsErrors) {
                    dismissModal();
                } else {
                    const errorEntries = perUserResults.filter(newUser => !(newUser.ok && !!newUser.user));

                    // As above there could be entries that are not okay but do have a valid user because
                    // the invite failed, we remove those from the current new users list as the user has been
                    // created and can be acted on later in the users list.
                    setNewUsers(
                        newUsers.filter(current => {
                            const errorEntry = errorEntries.find(error => error.emailAddress === current.emailAddress);
                            return errorEntry && !errorEntry.user;
                        }
                    ));
                    setErrorList(errorEntries);
                }
            });

        setInvitingUsers(true);
    }

    const errorContent = () => {
        return (
            <React.Fragment>
                <Row>
                    <Col>
                        <p className={'error-text'}>There was an error creating/inviting 1 or more new users.  Please try again later.</p>
                    </Col>
                </Row>
                {
                    // We check for errorList being valid before calling this method so
                    // sanity check.
                    errorList && errorList.map(newUserError => {
                        return (
                            <Row key={newUserError.emailAddress} >
                                <Col xs={4}>
                                    {newUserError.emailAddress}
                                </Col>
                                <Col xs={8}>
                                    {newUserError.errorMsg || ''}
                                </Col>
                            </Row>
                        );
                    })
                }
                <Row style={{ marginTop: '2em' }}>
                    <Col>
                        <Button className={'small'} color={'primary'} onClick={() => setErrorList(undefined)}>Dismiss</Button>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }

    const updateMetadataValue = (name: string, value: string): void => {
        const setting = userMetadataSettings.find(s => s.name === name);
        if (!setting) {
            // sanity check, shouldn't be possible
            console.error(`Attempting to set unknown metadata value ${name}`);
            return;
        }

        const contentIsValid = IsMetadataInputValid(value, setting.type);

        const existing = userMetadata.get(name);
        if (!!existing) {
            userMetadata.set(name, {
                ...existing,
                metaDataItem: {
                    ...existing.metaDataItem,
                    value,
                },
                contentIsValid,
            });
        } else {
            userMetadata.set(name, {
                metaDataItem: {
                    name,
                    value,
                    type: setting.type
                },
                contentIsValid,
            });
        }

        // Event though set modifies the object in place, if you don't do this, a re-render won't get triggered
        // because userMetadata hasn't changed.  This is a low cost way to trigger that change.

        setUserMetadata(new Map<string, AddUsersMetaDataItem>(userMetadata));
    }

    const regularContent = () => {
        return (
            <Row>
                <Col md={12} lg={5} xl={7}>
                    <CollectNewUserInfo
                        emailDomainsForTenant={emailDomainsForTenant}
                        userMetadataSettingsForTenant={userMetadataSettings}
                        editMode={editMode}
                        onChangeEditMode={setEditMode}
                        metadataValues={userMetadata}
                        onMetadataValueChange={updateMetadataValue}
                        errorMessages={{
                            firstName: dangerousContent.firstName,
                            lastName: dangerousContent.lastName,
                            emailAddress: dangerousContent.email,
                        }}
                        user={currentInputUser}
                        onUpdateUser={(user) => {
                            setDangerousContent({});
                            setCurrentInputUser(user);
                        }}
                        onInviteUser={addUserToNewList}
                        permissionProps={{
                            availableRoles: availableRoles,
                            currentRoles,
                            permissionsPopoverTarget,
                            showDescriptionPopover: (role, target) => setPermissionsPopoverTarget({ role, target }),
                            onRoleChanged: (role, checked) => {
                                let newCurrentRoles = currentRoles.slice();
                                const existingIndex = newCurrentRoles.findIndex(currentRole => currentRole.roleId === role.id);
                                if (checked) {
                                    if (existingIndex === -1) {
                                        newCurrentRoles.push(roleResponseToResourceRole(role));
                                    }
                                } else {
                                    if (existingIndex !== -1) {
                                        newCurrentRoles = newCurrentRoles.slice(0, existingIndex).concat(newCurrentRoles.slice(existingIndex + 1));
                                    }
                                }
                                setCurrentRoles(newCurrentRoles);
                            }
                        }}
                    />
                </Col>
                <Col className={'std-summary-column'} md={12} lg={7} xl={5}>
                    <InvitedUserList
                        availableRoles={availableRoles}
                        editMode={editMode}
                        users={newUsers}
                        removeUser={(userIndex) => {
                            if (userIndex >= newUsers.length) {
                                return;
                            }

                            const modifiedNewUsers = newUsers.slice(0, userIndex).concat(newUsers.slice(userIndex + 1));

                            setNewUsers(modifiedNewUsers);
                        }}
                    />
                </Col>
            </Row>
        )
    }

    return (
        <Modal
            className={`flight-modal basic-layout-container`}
            isOpen={true}
            backdrop={'static'}
            style={{
                width: '60%',
            }}
        >
            <LoadingIndicator active={invitingUsers} centerIndicator={true} />
            <ModalBody>
                <Container
                    className={`control-region control-region-lender add-user-container`}
                    fluid
                >
                    {!!errorList && errorContent()}
                    {!errorList && regularContent()}
                    {
                        permissionsPopoverTarget && (
                            <PermissionsDetailPopover
                                onToggle={() => { setPermissionsPopoverTarget(undefined); }}
                                target={permissionsPopoverTarget}
                            />
                        )
                    }
                </Container>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={() => dismissModal()}>Cancel</Button>
                <Button
                    color="primary"
                    onClick={createAndInviteUsers}
                    disabled={(newUsers.length <= 0) || (!!errorList)}
                >
                    Invite New Users
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export const AddUsersModal = connect<InjectedReduxState, InjectedActionCreators, AddUsersModalProps, ApplicationState>(
    (appState: ApplicationState) => {
        const result: InjectedReduxState = {
            defaultRole: GetDefaultRole(appState),
            availableRoles: GetRoles(appState),
            emailDomainsForTenant: GetEmailDomains(appState),
            userMetadataSettings: GetUserMetadataSettings(appState),
        };

        return result;
    },
    {
        ...UIStateActionCreators,
        ...UserActionCreators,
    }
)(AddUsersModalComponent);
