export type UndoOperationType = 'add' | 'remove';

export type UndoOperation<OpDataType> = {
    operation: UndoOperationType;
    opData: OpDataType;
}

export class UndoStack<OpDataType> {
    stack: UndoOperation<OpDataType>[];

    constructor() {
        this.stack = [];
    }

    public isEmpty(): boolean {
        return this.stack.length === 0;
    }

    public push(operation: UndoOperationType, opData: OpDataType): void {
        this.stack.push({
            operation,
            opData
        });
    }

    public pop(): UndoOperation<OpDataType> {
        if (this.isEmpty()) {
            throw new Error('Invalid operation, stack is empty');
        }
        const stackLength = this.stack.length;
        const result = this.stack[stackLength - 1];
        this.stack = this.stack.slice(0, stackLength - 1);  // non inclusive so this is correct;
        return result;
    }
}
