import * as React from 'react';

import {
    Container,
    Row,
    Col
} from 'reactstrap';

import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material';

import { FSModels } from '@finagraph/financial-statement-editor';

type Props = {
    onUpdate: (newValue: FSModels.ReportingPeriodTypes) => void;
    reportingPeriod: FSModels.ReportingPeriodTypes | undefined;
}

export const FinancialStatementReportingPeriod: React.FC<Props> = (props): React.ReactElement => {
    const {
        onUpdate,
        reportingPeriod
    } = props;

    return (
        <Container fluid>
            <Row>
                <Col>
                    <div className={'right-aligned-col'}>
                        <FormControl fullWidth>
                            <InputLabel id="reporting-period-select-label">Reporting Period</InputLabel>
                            {reportingPeriod && (
                                <Select
                                    labelId={"reporting-period-select-label"}
                                    label="Reporting Period"
                                    value={reportingPeriod}
                                    // @ts-ignore TS2345   this will have a correct value
                                    onChange={e => onUpdate(e.target.value)}
                                    MenuProps={{
                                        sx: {
                                            '& div': {
                                                borderRadius: 0
                                            }
                                        }
                                    }}
                                    sx={{ borderRadius: '0px' }}
                                >
                                    <MenuItem value={FSModels.ReportingPeriodTypes.FiscalYearsAndYTD}>Fiscal Year</MenuItem>
                                    <MenuItem value={FSModels.ReportingPeriodTypes.FiscalQuartersAndQTD}>Fiscal Quarter</MenuItem>
                                    <MenuItem value={FSModels.ReportingPeriodTypes.MonthsAndMTD}>Months</MenuItem>
                                </Select>
                            )}
                        </FormControl>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
