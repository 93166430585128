import { ApplicationState } from '../index';

import { FSModels } from '@finagraph/financial-statement-editor';

import {
    FinancialStatementLineItemFilter,
    FinancialStatementError,
    FinancialStatementWorkbookRegenerationTracking,
    NumberFormattingOption
} from './Reducer';

import {
    FinancialStatementTemplatesList
} from '../../Models/Api/strongbox.financialportal';

export function GetFinancialStatement(state: ApplicationState): FSModels.FinancialStatementViewModel | undefined {
    return state.financialStatements?.currentView;
}

export function GetReportingPeriod(state: ApplicationState): FSModels.ReportingPeriodTypes | undefined {
    return state.financialStatements?.reportingPeriod;
}

export function GetReportingPeriodSortOrder(state: ApplicationState): FSModels.ChronologicalSortOrder | undefined {
    return state.financialStatements?.reportingPeriodSortOrder;
}

export function GetExcelNumberFormatters(state: ApplicationState): Map<string, (n: number) => string> {
    return state.financialStatements?.excelNumberFormatters;
}

export function GetValidLocationsToMoveLineItemFunc(state: ApplicationState) {
    return (x: string) => state.financialStatements?.financialStatementFacade?.getValidLocationsToMoveLineItem(x) || [];
}

export function GetFilterDescriptions(state: ApplicationState, statementType: FSModels.FinancialStatementType): FinancialStatementLineItemFilter[] {
    return state.financialStatements.lineItemFilterState.get(statementType) || [];
}

export function GetNumberFormattingOptions(state: ApplicationState): NumberFormattingOption[] {
    return state.financialStatements.numberFormattingOptions || [];
}

export function GetErrorState(state: ApplicationState): FinancialStatementError | undefined {
    return state.financialStatements.errorState;
}

export function IsModelDirty(state: ApplicationState): boolean {
    // A reasonable dirty state if financialStatements is undefined is false
    return !state.financialStatements ? false : state.financialStatements.dirty;
}

export function GetTemplatesList(state: ApplicationState): FinancialStatementTemplatesList | undefined {
    return state.financialStatements.financialStatementTemplates;
}

export function GetWorkbookRegenerationTracking(state: ApplicationState): FinancialStatementWorkbookRegenerationTracking[] {
    if (!state.financialStatements) {
        return [];
    }
    return state.financialStatements.trackingRevisionNumbers;
}

export function GetValidLocationToMoveSectionContents(state: ApplicationState, lineItemId: string): FSModels.MoveLineItemOption[] {
    if (!(state.financialStatements && state.financialStatements.financialStatementFacade)) {
        return [];
    }
    return state.financialStatements.financialStatementFacade
        .getValidLocationsToMoveLineItem(lineItemId)
        .filter(location => location.sectionId !== null);
}

export function GetFinancialStatementType(state: ApplicationState): FSModels.FinancialStatementType {
    return state.financialStatements?.financialStatementType || FSModels.FinancialStatementType.BalanceSheet;
}
