import * as React from 'react';
import { connect } from 'react-redux';

import '../Main.scss';

import IconButton from '@mui/material/IconButton';
import ExpandMore from '@mui/icons-material/ArrowDropDownOutlined';
import ExpandLess from '@mui/icons-material/ArrowDropUpOutlined';

import { ApplicationState } from '../../Store';

type InjectedReduxState = {
};

type InjectedActionCreators = {};

type DropDownCaretProps = {
    expanded: boolean;
    expandDescriptor: string;
    contractDescriptor: string;
    clicked: () => void;
    style?: any;
    text?: string;
    id?: string;
    hidden?: boolean;
}

type Props = DropDownCaretProps & InjectedReduxState & InjectedActionCreators;

export const DropDownCaretComponent: React.FC<Props> = (props): React.ReactElement => {
    const [iconButtonStyle, setIconButtonStyle] = React.useState<React.CSSProperties | undefined>({
        ...props.style
    });

    const caretContent = (buttonStyle?: React.CSSProperties, id?: string): React.ReactElement => {
        return (
            props.expanded ?
                (
                    <IconButton
                        id={id}
                        style={buttonStyle}
                        aria-label={`Hide ${props.contractDescriptor}`}
                        onClick={props.clicked}
                        className={'icon-buttons'}
                    >
                        <ExpandLess />
                    </IconButton>
                ) :
                (
                    <IconButton
                        id={id}
                        style={buttonStyle}
                        aria-label={`Show ${props.expandDescriptor}`}
                        onClick={props.clicked}
                        className={'icon-buttons'}
                    >
                        <ExpandMore />
                    </IconButton>
                )
        );
    }

    React.useEffect(() => {
        let newStyle: React.CSSProperties = {
        };

        if (!props.text) {
            newStyle = {
                ...props.style,
                ...newStyle
            }
        }

        setIconButtonStyle(newStyle);
    }, [props.style, props.text])

    if (!!props.hidden) {
        return (
            <span
                style={iconButtonStyle}
                className={'basic-icon-size'}
            />
        )
    } else if (!!props.text) {
        return (
            <div
                id={props.id}
                style={props.style}
                className={`caret`}
            >
                {props.text}
                {caretContent(iconButtonStyle)}
            </div>
        )
    } else {
        return caretContent(iconButtonStyle);
    }
}

export const DropDownCaret = connect<InjectedReduxState, InjectedActionCreators, DropDownCaretProps, ApplicationState>(
    (appState: ApplicationState) => {
        return {
        }
    }
)(DropDownCaretComponent);
