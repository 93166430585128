import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';

import '../Main.scss';

import {
    Nav,
    NavItem,
} from 'reactstrap';

import { SettingsTab } from './SettingsTypes';

type Props = {
    tabs: SettingsTab[];
};

export const SettingsNav: React.FC<Props> = (props): React.ReactElement => {
    const location = useLocation();

    return (
        <Nav
            tabs
            className={`portal-tabs`}
        >
            {props.tabs.map((tab, index) => {
                let navItemClass = '';
                let navLinkClass = '';

                if (location && location.pathname === tab.path) {
                    navItemClass = `portal-nav-item-active`;
                    navLinkClass = 'portal-nav-link-active';
                } else {
                    navItemClass = `portal-nav-item-inactive`;
                    navLinkClass = '';
                }
                return (
                    <NavItem
                        id={tab.tabId}
                        key={tab.tabId}
                        className={`portal-nav-item ${navItemClass}`}
                    >
                        <Link
                            className={`portal-nav-link ${navLinkClass}`}
                            id={tab.tabId}
                            to={tab.path}
                        >
                            {tab.tabLabel}
                        </Link>
                    </NavItem>
                )
            })}
        </Nav>
    );
}

