import { Reducer } from 'redux';

import { DetailedUserResponse, PostLoginUserResponse, RoleResponse, UserResponse } from '../../Models/Api/strongbox.financialportal';

import {
    UserActions,
    KnownAction,
} from './Actions';

import jwtDecode from 'jwt-decode';
import { JwtPayload } from 'jwt-decode';

import { pathConstants } from '../../Utils/Constants';

import { LogException } from '../../Utils/Logging';

export const defaultAuth0Scope = 'read:current_user update:current_user_metadata';
export const defaultAuth0RedirectLogon = pathConstants.auth0LoginRedirect;
export const defaultAuth0RedirectLogout = pathConstants.login;

export const SessionStorageAuth0TokenName = 'Auth0Token';
export const SessionStorageRoleIdsName = 'RI';

export interface Auth0UserSettings {
    clientId: string;
    domain: string;
    scope: string;
    audience: string;
    redirectLogin: string;
}

export const SessionStorageLoggedInUserName = 'LoggedInUserInfo';

export type UserRolesInfo = {
    roles: RoleResponse[];
    externalId: string;
}

export const emptyUserRolesInfo = {
    roles: [],
    externalId: '',
}

export interface IUserState {
    users: UserResponse[];
    loading: boolean;
    loggedInUser?: PostLoginUserResponse;
    initialized: boolean;
    auth0: Auth0UserSettings;
    auth0LoggingOut: boolean;
    auth0Token: string | undefined;   // auth0Token and loggedInUserRoles are stored in sessionStorage. In
    auth0Id: string | undefined;
    maxSessionLifetimeMS: number;
    detailedUsers: DetailedUserResponse[];
}

export const defaultUserState: IUserState = {
    initialized: false,
    users: [],
    loading: false,
    loggedInUser: undefined,
    auth0: {
        audience: '',
        clientId: '',
        domain: '',
        redirectLogin: defaultAuth0RedirectLogon,
        scope: defaultAuth0Scope,
    },
    auth0LoggingOut: false,
    auth0Token: undefined,
    auth0Id: undefined,
    maxSessionLifetimeMS: 0,
    detailedUsers: []
}

const getAuth0ID = (auth0Token: string): string | undefined => {
    try {
        const decoded = jwtDecode<JwtPayload>(auth0Token);

        return (!!(decoded && decoded.sub)) ? decoded.sub : undefined;
    } catch (decodeException) {
        LogException(
            `Error decoding auth0 login token to retrieve auth0 ID`,
            decodeException
        );
        console.error('error decoding login token');
        console.error(decodeException);

        return undefined;
    }
}

export const reducer: Reducer<IUserState, KnownAction> = (state: IUserState | undefined, action: KnownAction): IUserState => {
    let newState: IUserState | undefined = undefined;

    switch (action.type) {
        case UserActions.Initialize: {
            newState = {
                ...(state ? state : defaultUserState),
                initialized: true,
                maxSessionLifetimeMS: action.appData.sessionMaxLifeTimeMilliseconds
            }

            const newAuth0State: Auth0UserSettings = {
                clientId: action.appData && action.appData.auth0ClientId,
                domain: action.appData && action.appData.auth0Domain,
                scope: defaultAuth0Scope,
                audience: action.appData && action.appData.auth0Audience,
                redirectLogin: defaultAuth0RedirectLogon,
            }

            const auth0Token = (window && window.sessionStorage && window.sessionStorage.getItem(SessionStorageAuth0TokenName)) || (undefined);

            let auth0Id = undefined;

            if (!!auth0Token) {
                auth0Id = getAuth0ID(auth0Token);
            }

            newState.auth0 = newAuth0State;
            newState.auth0Token = auth0Token;
            newState.auth0Id = auth0Id;

            if (window && window.sessionStorage) {
                let loggedInUser: PostLoginUserResponse | undefined = undefined;

                const loggedInUserString = window.sessionStorage.getItem(SessionStorageLoggedInUserName);
                if (!!loggedInUserString) {
                    try {
                        loggedInUser = JSON.parse(loggedInUserString);
                    } catch (e) {
                        LogException(
                            `Failed converting definition of logged in user in session storage to a user`,
                            e
                        );
                        console.error(`Error converting session storage user definition to user ${loggedInUserString}`);
                        console.error(e);
                    }
                }

                newState.loggedInUser = loggedInUser;
            }
            break;
        }
        case UserActions.LoadUsers: {
            if (!!state && state.loading) {
                break;
            }
            newState = {
                ...(state ? state : defaultUserState),
                loading: true,
            }
            break;
        }
        case UserActions.LoadUsersComplete: {
            newState = {
                ...(state ? state : defaultUserState),
                users: action.users,
                loading: false,
            };
            break;
        }
        case UserActions.LoadDetailedUsersComplete: {
            newState = {
                ...(state ? state : defaultUserState),
                detailedUsers: action.detailedUsers,
                loading: false,
            };
            break;
        }
        case UserActions.SetLoggedInUser: {
            if (window && window.sessionStorage) {
                if (!!action.user) {
                    window.sessionStorage.setItem(SessionStorageLoggedInUserName, JSON.stringify(action.user));
                } else {
                    window.sessionStorage.removeItem(SessionStorageLoggedInUserName);
                }
            }

            newState = {
                ...(state ? state : defaultUserState),
                loggedInUser: action.user,
            }
            break;
        }

        case UserActions.SetSessionInfo: {
            if (window && window.sessionStorage) {
                window.sessionStorage.setItem(SessionStorageAuth0TokenName, action.token || '');
            }

            newState = {
                ...(state ? state : defaultUserState),
                auth0Token: action.token,
                auth0Id: action.token && getAuth0ID(action.token),
            };
            break;
        }

        case UserActions.LogUserOut: {
            if (window && window.sessionStorage) {
                window.sessionStorage.setItem(SessionStorageAuth0TokenName, '');
                window.sessionStorage.setItem(SessionStorageRoleIdsName, btoa(JSON.stringify([])));
                window.sessionStorage.removeItem(SessionStorageLoggedInUserName);
            }

            // auth0LoggingOut will be reset to false when logout is complete.  The action creator
            // for this action calls the auth0 logout method with a return URL of our login page.
            // When that page loads, the init action (above) gets generated and everything is reset
            // including auth0LoggingOut.

            newState = {
                ...(state ? state : defaultUserState),
                auth0Token: undefined,
                auth0Id: undefined,
                auth0LoggingOut: true,
                loggedInUser: undefined,
            };
        }
    }

    if (newState) {
        return newState;
    } else if (state) {
        return state;
    } else {
        let defaultCopy: IUserState = {
            ...defaultUserState,
        };
        return defaultCopy;
    }
}
