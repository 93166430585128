import * as React from 'react';

import '../Main.scss';

import UndoIcon from '@mui/icons-material/Undo';

import {
    Tooltip
} from '@mui/material';

import { UndoStack, UndoOperation } from '../../Utils/UndoStack';


type Props<OpDataType> = {
    undoStack: UndoStack<OpDataType>;
    showWhenEmpty?: boolean;
    style?: React.CSSProperties;
    disabled?: boolean;
    undo: (op: UndoOperation<OpDataType>) => void;
    tooltipPrompt?: string;
}

export const UndoButton: <OpDataType>(p: React.PropsWithChildren<Props<OpDataType>>) => React.ReactElement = props => {
    const {
        disabled,
        showWhenEmpty,
        style,
        tooltipPrompt,
        undoStack,
        undo
    } = props;

    if (undoStack.isEmpty() && !showWhenEmpty) {
        return (<React.Fragment></React.Fragment>);
    }

    return (
        <Tooltip title={disabled !== false ? (tooltipPrompt || 'Undo the last action') : ''}>
            <span>
                <button
                    style={style}
                    onClick={(e) => {
                        const op = undoStack.pop();
                        undo(op);
                    }}
                    disabled={undoStack.isEmpty() || !!disabled}
                >
                    <UndoIcon />
                </button>
            </span>
        </Tooltip>
    );
}
