import { UserResponse } from '../Models/Api/strongbox.financialportal';

export function FullUserName(user: UserResponse) {
    if (!!user.displayName) {
        return user.displayName;
    }

    const firstName = user.firstName || '';
    const lastName = user.lastName || '';

    return `${firstName} ${lastName}`;
}

export function FullUserNameFromComponents(displayName: string | undefined, firstName: string | undefined, lastName: string | undefined)
{
    if (!!displayName) {
        return displayName;
    }

    return `${firstName || ''} ${lastName || ''}`;
}
