import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
    Col,
    Container,
    Row,
    UncontrolledDropdown
} from 'reactstrap';

import {
    Tooltip
} from '@mui/material';

import { ApplicationState } from '../../../Store';
import {
    actionCreators as FinancialStatementActions,
    GetFinancialStatement,
    FinancialStatementLineItemFilter,
    GetFilterDescriptions,
    FinancialStatementError,
    GetErrorState,
    GetReportingPeriod,
    GetExcelNumberFormatters,
    GetValidLocationsToMoveLineItemFunc,
    IsModelDirty,
    FinancialStatementWorkbookRegenerationTracking,
    GetWorkbookRegenerationTracking,
    NumberFormattingOption
} from '../../../Store/FinancialStatements';

import { idStrongboxPortalHeaderWindow } from '../../../Utils/Constants';
import { SanitizeDOMSelector } from '../../../Utils/DOMUtils';
import { LineItemInfoPopover } from './LineItemInfoPopover';
import { MoveToNewSectionMenu } from './MoveToNewSectionMenu';
import { lineItemCaptionId } from './FinancialStatementLineItemCaption';
import { AddNewSectionModal } from './AddNewSectionModal';
import { NameAndStylePopover } from './NameAndStylePopover';
import { FilterDropdown } from './FilterDropdown';
import { LineItemMoreOptions } from './LineItemMoreOptions';
import { EditTemplateParameters, EditTemplateParametersModal } from './EditTemplateParameters';
import { GetStatementTypeTitle } from './Utilities';
import { DeleteNonEmptySectionModal } from './DeleteNonEmptySectionModal';

import { FSModels } from '@finagraph/financial-statement-editor';

import { CaptionTextEditOption, CaptionTextEditOptionType } from './NameAndStylePopover';

import { FinancialStatementTable } from './FinancialStatementTable';
import { LineItemSelectionTracking } from './FinancialStatementLineItemsTable';
import { FinancialStatementReportingPeriod } from './FinancialStatementReportingPeriod';
import { FinancialStatementType } from './FinancialStatementType';
import { FinancialStatementRenderData } from './UtilTypes';
import { fsSectionPresentationName } from './FSConstants';
import { TemplateMgmtModal } from './TemplateMgmtModal';
import { FinancialStatementWorkbookRegenerationStatusList } from './FinancialStatementWorkbookRegenerationStatusList';

import { ErrorBanner, ErrorState, ErrorAction } from '../../ErrorBanner/ErrorBanner';
import { EditNumberFormattingPopover } from './EditNumberFormattingPopover';

import { ConfirmModal, ConfirmModalType } from '../../ConfirmModal/ConfirmModal';

type LineItemWithTarget = {
    lineItem: FSModels.FinancialStatementLineItemViewModel;
    targetId: string;
}

type InjectedReduxState = {
    financialStatement?: FSModels.FinancialStatementViewModel;
    reportingPeriod: FSModels.ReportingPeriodTypes | undefined;
    excelNumberFormatters: Map<string, (n: number) => string>;

    getValidLocationsToMoveLineItem: (lineItemId: string) => FSModels.MoveLineItemOption[];
    lineItemFilters: FinancialStatementLineItemFilter[];
    errorState?: FinancialStatementError;

    workbookRegenerationTracking: FinancialStatementWorkbookRegenerationTracking[];

    modelIsDirty: boolean;
};

type InjectedActionCreators = typeof FinancialStatementActions;

type FinancialStatementViewProps = {
    autoSaving: boolean;
    financialRecordId: string;
    financialStatementType: FSModels.FinancialStatementType;
    onUpdateFinancialStatementType: (newType: FSModels.FinancialStatementType) => void;
    orgId: string;
    stylesBelowEdit: boolean;
    onStylesBelowEditUpdate: (newValue: boolean) => void;
    isDataModified: boolean;
    numberFormattingOptions: NumberFormattingOption[];
    onGenerateWorkbooks: () => void;
    onSaveFinancialStatement: (onComplete: () => void) => void;   // onComplete is called if the financial statement is SUCCESSFULLY saved.
    onSaveAsTemplate: (parameters: EditTemplateParameters) => void;
    onApplyTemplate: (templateId: string) => void;
    clearRegenerationStatusTracking: () => void;
    userMsg?: {
        msg: ErrorState;
        clear: () => void;
    }
    showRegenerationStatusTracking: boolean;
    onNavToLatestRevision: () => void;
}

type Props = FinancialStatementViewProps & InjectedActionCreators & InjectedReduxState;

type MultiSelectData = {
    lineItems: Map<string, LineItemSelectionTracking>
    sectionId: string;
    lowIndex: number;
    highIndex: number;
}

type TrackMultiSelectHover = {
    lowIndex: number;
    highIndex: number;
}

const FinancialStatementViewComponent: React.FC<Props> = (props): React.ReactElement => {
    const {
        financialStatement,
        financialStatementType,
        onUpdateFinancialStatementType,
        reportingPeriod,
        ResetFinancialStatements,
        ChangeReportingPeriod,
        UpdateLineItemCaptions,
        SetFilterState,
        ToggleReportingPeriodSortOrder,
        //UpdateLineItemIndent, // TODO FSD
        stylesBelowEdit,
        lineItemFilters,
        IncrementLineItemSortOrder,
        DecrementLineItemSortOrder,
        MoveLineItems,
        SortLineItems,
        AddClassificationSection,
        DeleteClassificationSection,
        SetReportedAs,
        DeleteLineItems,
        UsePredefinedNumberStyle,
        errorState,
        SetFinancialStatementErrorState,
        numberFormattingOptions,
        onGenerateWorkbooks,
        onSaveFinancialStatement,
        excelNumberFormatters,
        modelIsDirty,
        onSaveAsTemplate,
        userMsg,
        getValidLocationsToMoveLineItem,
        onApplyTemplate,
        workbookRegenerationTracking,
        clearRegenerationStatusTracking,
        showRegenerationStatusTracking,
        orgId,
        financialRecordId,
        onNavToLatestRevision,
    } = props;

    const [lockedLineItem, setLockedLineItem] = React.useState<FSModels.FinancialStatementLineItemViewModel | undefined>(undefined);

    // In the unexpected event we don't actually find the container window which is pretty
    // unlikely the default value of 200 is roughly the correct value.
    const [containerTop, setContainerTop] = React.useState<{ top: number; isSet: boolean }>({ top: 270, isSet: false });
    const [tableProps, setTableProps] = React.useState<React.CSSProperties>({});

    const [showInfoFor, setShowInfoForInternal] = React.useState<FSModels.FinancialStatementLineItemViewModel | undefined>(undefined);
    const setShowInfoFor = (li: FSModels.FinancialStatementLineItemViewModel | undefined): void => {
        setShowInfoForInternal(li);
        setLockedLineItem(li);
    }
    const [moveToNewSection, setMoveToNewSectionInternal] = React.useState<FSModels.FinancialStatementLineItemViewModel | undefined>(undefined);
    const setMoveToNewSection = (li: FSModels.FinancialStatementLineItemViewModel | undefined): void => {
        setMoveToNewSectionInternal(li);
        setLockedLineItem(li);
    }
    const [addNewSubsection, setAddNewSubsectionInternal] = React.useState<{ lineItem?: FSModels.FinancialStatementLineItemViewModel } | undefined>(undefined);

    const setAddNewSubsection = (li: { lineItem?: FSModels.FinancialStatementLineItemViewModel } | undefined): void => {
        setAddNewSubsectionInternal(li);
        setLockedLineItem(li?.lineItem);
    }
    const [lineItemMoreOptionsMenu, setLineItemMoreOptionsMenuInternal] = React.useState<LineItemWithTarget | undefined>(undefined);
    const setLineItemMoreOptionsMenu = (liWithTarget: LineItemWithTarget | undefined): void => {
        setLineItemMoreOptionsMenuInternal(liWithTarget);
        setLockedLineItem(!!liWithTarget ? liWithTarget.lineItem : undefined);
    }

    const [filterLineItemsDropped, setFilterLineItemsDropped] = React.useState<boolean>(false);

    const [editingLineItem, setEditingLineItemInternal] = React.useState<LineItemWithTarget | undefined>(undefined);
    const setEditingLineItem = (liWithTarget: LineItemWithTarget | undefined): void => {
        setEditingLineItemInternal(liWithTarget);
        setLockedLineItem(!!liWithTarget ? liWithTarget.lineItem : undefined);
    }

    const [targetIdEditNumberFormatting, setTargetIdEditNumberFormatting] = React.useState<string | undefined>(undefined);

    const [checkUserSave, setCheckUserSave] = React.useState<{
        type: 'switchtemplates';
        action: () => void;
    } | undefined>(undefined);
    const [switchTemplates, setSwitchTemplates] = React.useState<boolean>(false);
    const [editTemplateParameters, setEditTemplateParameters] = React.useState<boolean>(false);

    // Multi-Select settings. These are settings that have actually been selected as opposed to the next value 
    const [multiSelectingLineItems, setMultiSelectingLineItems] = React.useState<MultiSelectData | undefined>(undefined);
    // These represent the combined set of line items that are actually multi selected and those that may or may not be 
    // selected but are temporarily highlighted with a 'shift+button hover' action.
    const [combinedMultiSelectingLineItems, setCombinedMultiSelectingLineItems] = React.useState<MultiSelectData | undefined>(undefined);
    const [trackMultiSelectHover, setTrackMultiSelectHover] = React.useState<TrackMultiSelectHover | undefined>(undefined);
    const [shiftKeyDown, setShiftKeyDown] = React.useState<boolean>(false);

    // clearMultiLineTracking should be used when an operation occurs that will change indexes in financialStatements.lineItems
    // like deleting an item.  The multi line tracking stuff relies on those indexes so just clear them out when any of this changes.
    const clearMultiLineTracking = (): void => {
        setMultiSelectingLineItems(undefined);
        setCombinedMultiSelectingLineItems(undefined);
        setTrackMultiSelectHover(undefined);
    }

    const idContainer = `financial-statement-view-container-${financialStatementType}`

    const [confirmDeleteSectionData, setConfirmDeleteSectionData] =
        React.useState<{
            sectionType: 'sectionheader' | 'section'
            sectionId: string,
            sectionTitle: string
        } | undefined>(undefined);
    const [deleteNonEmptySectionData, setDeleteNonEmptySectionData] = React.useState<FSModels.FinancialStatementLineItemViewModel | undefined>(undefined);

    const [confirmReset, setConfirmReset] = React.useState<boolean>(false);

    function HandleWindowResize(e: UIEvent) {
        setTableProps({ height: `${window.innerHeight - containerTop.top}px` });
    }

    // After a line item is moved in the table (up or down) its position changes and we possibly scroll the window so it
    // is still in view.  After that move, so the user can see what happens, lock the line item so it appears selected
    // for a small amount of time to give the user that context about where it moved to.
    const [tempLock, setTempLock] = React.useState<FSModels.FinancialStatementLineItemViewModel | undefined>(undefined);

    React.useEffect(() => {
        if (!tempLock) {
            return;
        }
        setLockedLineItem(tempLock);
        setTimeout(() => { setLockedLineItem(undefined) }, 1500);
        setTempLock(undefined);
    }, [tempLock]);

    React.useEffect(() => {
        setTableProps({ height: `${window.innerHeight - containerTop.top}px` });

        // The financial statement table needs to know how big it should be
        // based on our client rect. HandleWindowResize gets that value and 
        // stores the value so we can pass it down to the table.
        window.addEventListener('resize', HandleWindowResize, true);

        return () => { window.removeEventListener('resize', HandleWindowResize, true); }

        // This is in fact the dependency list I want, HandleWindowResize doesn't change.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [containerTop]);

    // There's a warning here because setContainerTop is called but isn't in the dependency
    // list.  This is alright because the code path that results in setContainerTop being
    // called is such that it can only be called once when the actual dependencies change
    // which relate to the bounding rect.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useLayoutEffect(() => {
        const containerWindow = document.getElementById(idContainer);
        if (containerWindow === null) {
            return;
        }
        const portalHeader = document.getElementById(idStrongboxPortalHeaderWindow);
        let headerHeight = 100; // This is the actual default
        if (portalHeader !== null) {
            headerHeight = portalHeader.getBoundingClientRect().height;
        }

        const boundingRect = containerWindow.getBoundingClientRect();

        const newTop = boundingRect.y + headerHeight;

        if ((!containerTop.isSet) || (containerTop.top !== newTop)) {
            setContainerTop({ top: newTop, isSet: true });
        }

        // I want this to execute equivalent to componentDidMount so this is appropriate
        // eslint-disable-next-line react-hooks/exhaustive-deps
    });

    React.useEffect(() => {
        if (!financialStatement?.lineItems) {
            setCombinedMultiSelectingLineItems(undefined);
            return;
        }
        if (!(multiSelectingLineItems || trackMultiSelectHover)) {
            setCombinedMultiSelectingLineItems(undefined);
            return;
        }

        if (!!multiSelectingLineItems) {
            const newCombined: MultiSelectData = {
                lineItems: new Map<string, LineItemSelectionTracking>(multiSelectingLineItems.lineItems),
                sectionId: '',
                lowIndex: multiSelectingLineItems.lowIndex,
                highIndex: multiSelectingLineItems.highIndex,
            };
            if (!!trackMultiSelectHover) {
                for (let i = trackMultiSelectHover.lowIndex; i <= trackMultiSelectHover.highIndex; i++) {
                    const lineItem = financialStatement.lineItems[i];
                    if ((lineItem.lineItemType === FSModels.FinancialStatementLineItemType.DetailAccount) &&
                        (!isFixedPositionDetailAccount(lineItem)) &&
                        (!newCombined.lineItems.get(lineItem.id))) {
                        newCombined.lineItems.set(
                            lineItem.id,
                            {
                                lineItem,
                                lineItemIndex: i,
                                selected: false,
                                highlighted: shiftKeyDown,
                            }
                        );
                    }
                }
            }

            setCombinedMultiSelectingLineItems(newCombined);
        }

        // This is in fact the dependency list I want
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [financialStatement, multiSelectingLineItems, shiftKeyDown, trackMultiSelectHover]);

    const renderData: FinancialStatementRenderData | undefined = React.useMemo(() => {
        if (!!financialStatement) {
            const accountLookup = !!financialStatement
                ? new Map(financialStatement.detailAccounts.map(a => [a.accountId, a]))
                : new Map<string, FSModels.DetailAccountDescriptor>();
            const captionStyleLookup = financialStatement
                ? new Map(financialStatement.captionStyleClasses.map(a => [a.nameId, a]))
                : new Map<string, FSModels.CaptionFormatting>();
            const monetaryDataStyleLookup = financialStatement
                ? new Map(financialStatement.monetaryDataStyleClasses.map(a => [a.nameId, a]))
                : new Map<string, FSModels.NumericStyleClass>();
            const formulasById = financialStatement
                ? new Map(financialStatement.formulas.map(f => [f.id, f]))
                : new Map<string, FSModels.FinancialStatementFormula>();

            const matchedCaptionLookup = new Map<string, CaptionTextEditOption[]>();

            const sectionsById = new Map(financialStatement.classificationSections.map(s => [s.id, s]));

            const subsectionOptions = financialStatement.classificationSections
                .filter(s => s.canAddSubsection)
                .map(s => ({ sectionId: s.id, reportedAs: s.reportedAs, label: s.footerCaptionText }));

            financialStatement.lineItems.forEach((li, index) => {
                const result: CaptionTextEditOption[] = [];

                switch (li.lineItemType) {
                    case FSModels.FinancialStatementLineItemType.SectionHeader:
                    case FSModels.FinancialStatementLineItemType.SectionFooter:
                        const section = sectionsById.get(li.sectionId)!;

                        
                        if (section.headerLineItemId) {
                            result.push({ descriptor: 'Header Text', lineItemId: section.headerLineItemId, captionText: section.headerCaptionText!, type: CaptionTextEditOptionType.header });
                        }
                        result.push({ descriptor: 'Footer Text', lineItemId: section.footerLineItemId, captionText: section.footerCaptionText, type: CaptionTextEditOptionType.footer });
                        break;

                    default:
                        result.push({
                            descriptor: 'Caption Text',
                            lineItemId: li.id,
                            captionText: li.captionText,
                            type: li.lineItemType === FSModels.FinancialStatementLineItemType.DetailAccount ? CaptionTextEditOptionType.detail : CaptionTextEditOptionType.unknown
                        });
                        break;
                }

                matchedCaptionLookup.set(li.id, result);
            });

            return {
                accountLookup,
                captionStyleLookup,
                formulasById,
                monetaryDataStyleLookup,
                matchedCaptionLookup,
                sectionsById,
                subsectionOptions
            };
        }
        else {
            return undefined;
        }
    }, [financialStatement]);

    const financialStatementTypeDescriptor = React.useMemo(() => {
        return GetStatementTypeTitle(financialStatementType);
    }, [financialStatementType])

    const filteredMovedToDestinations: FSModels.MoveLineItemOption[] = React.useMemo(() => {
        if (!moveToNewSection) {
            return [];
        }
        if (!renderData) {
            // Really shouldn't happen 
            return getValidLocationsToMoveLineItem(moveToNewSection.id);
        }
        return getValidLocationsToMoveLineItem(moveToNewSection.id).filter((option) => {
            if (!option.sectionId) {
                return true;
            }

            const sectionId = option.sectionId.toLowerCase();
            if ((sectionId === 'cash') || (sectionId === 'accountsreceivable') || (sectionId === 'accountspayable')) {
                return false;
            }

            const optionSection = renderData.sectionsById.get(option.sectionId);
            if (!optionSection) {
                return false;
            }
            return optionSection.subsectionOf.length > 0;
        });

        // This is in fact the dependency list I want, getValidLocations is a function, won't change.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [moveToNewSection, renderData])

    const updateCaptionIndent = (li: FSModels.FinancialStatementLineItemViewModel, newIndentLevel: number): void => {
        // FSD TODO!! UpdateLineItemIndent(orgId, financialRecordId, financialStatementType, reportingPeriod, li.id, newIndentLevel);
        console.warn('updateCaptionIndent not implemented');
    }

    const updateRelatedCaptions = (newHeader: string, newFooter: string | undefined): void => {
        if (!editingLineItem) {
            return;
        }

        const captionTextOptions = renderData!.matchedCaptionLookup.get(editingLineItem.lineItem.id);

        const edits: FSModels.EditLineItemCaptionAction[] = [];

        if (!!captionTextOptions) {
            let targetType: CaptionTextEditOptionType = CaptionTextEditOptionType.unknown;

            if (editingLineItem.lineItem.lineItemType === FSModels.FinancialStatementLineItemType.SectionHeader) {
                targetType = CaptionTextEditOptionType.header;
            } else if (editingLineItem.lineItem.lineItemType === FSModels.FinancialStatementLineItemType.SectionFooter) {
                targetType = CaptionTextEditOptionType.footer;
            } else if (editingLineItem.lineItem.lineItemType === FSModels.FinancialStatementLineItemType.DetailAccount) {
                targetType = CaptionTextEditOptionType.detail;
            }

            const iPrimary = captionTextOptions.findIndex(cto => cto.type === targetType);
            if (iPrimary !== -1) {
                if (newHeader !== captionTextOptions[iPrimary].captionText) {
                    edits.push({
                        lineItemId: captionTextOptions[iPrimary].lineItemId,
                        updatedCaptionText: newHeader
                    })
                }
            }
        }

        if (editingLineItem.lineItem.lineItemType === FSModels.FinancialStatementLineItemType.SectionHeader) {
            if (!!newFooter && !!captionTextOptions) {
                // Update the text for the footer as the user types
                const iFooter = captionTextOptions.findIndex(cto => cto.type === CaptionTextEditOptionType.footer);
                if (iFooter !== -1) {
                    if (newFooter !== captionTextOptions[iFooter].captionText) {
                        edits.push({
                            lineItemId: captionTextOptions[iFooter].lineItemId,
                            updatedCaptionText: newFooter
                        })
                    }
                }
            }
        }

        if (edits.length > 0) {
            UpdateLineItemCaptions(edits);
        }
    }

    const captionFormatting: FSModels.CaptionFormatting = React.useMemo(() => {
        if (!(renderData && editingLineItem)) {
            return {};
        }
        return renderData.captionStyleLookup.get(editingLineItem.lineItem.captionStyleClasses[0]) ?? {};
    }, [renderData, editingLineItem]);

    const onSwitchTemplate = async (): Promise<void> => {
        if (modelIsDirty) {
            setCheckUserSave({ type: 'switchtemplates', action: () => setSwitchTemplates(true) });
        } else {
            setSwitchTemplates(true);
        }
    }

    const renderErrorBanner = (noDismiss: boolean): React.ReactElement | undefined => {
        // Did we fail on initialization. If yes, then provide a reset button.
        // Unlike the other reset button, skip the confirmation step and just
        // go straight to reset as the context should be clear here.
        let extraInformation: string | undefined = undefined;
        let resetActions: ErrorAction[] | undefined = undefined;

        if (!financialStatement) {
            extraInformation = 'Resetting financial statements to their original state will often resolve this issue';
            resetActions = [
                {
                    text: 'Reset To Default',
                    id: 'resetdefault',
                    onAction: (id) => {
                        ResetFinancialStatements(orgId, financialRecordId, financialStatementType);
                    }
                }
            ]
        }

        return !!errorState ? (
            <Row>
                <Col className={'error-state-col'}>
                    <Row>
                        <Col>
                            <ErrorBanner
                                errorState={{
                                    severity: 'Error',
                                    summaryMessage: errorState.errorMessage,
                                    extraInformation,
                                    actions: resetActions
                                }}
                                onDefaultActionButton={() => {
                                    SetFinancialStatementErrorState(undefined);
                                }}
                                defaultActionText={'Dismiss'}
                                noDismiss={noDismiss}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        ) : undefined;
    }

    const renderRegenerationStatus = (): React.ReactElement | undefined => {
        if (!showRegenerationStatusTracking) {
            return undefined;
        }
        return (
            <Row>
                <Col className={'status-monitor-col'}>
                    <Row style={{ marginBottom: '10px' }}>
                        <Col className={'spaced-row'}>
                            <h1>New workbooks are being generated</h1>
                            <button
                                className={'small'}
                                onClick={() => {
                                    clearRegenerationStatusTracking();
                                }}
                            >
                                Dismiss
                            </button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FinancialStatementWorkbookRegenerationStatusList
                                regenerationTrackingList={workbookRegenerationTracking}
                                onNavToLatestRevision={onNavToLatestRevision}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }

    const isFixedPositionDetailAccount = (lineItem: FSModels.FinancialStatementLineItemViewModel): boolean => {
        const sectionId = lineItem.sectionId.toLowerCase();

        return ((sectionId === 'cash') || (sectionId === 'accountsreceivable') || (sectionId === 'accountspayable'));
    }

    const setMultiSelectForLineItem = (lineItem: FSModels.FinancialStatementLineItemViewModel, selected: boolean): void => {
        if (!selected && (multiSelectingLineItems === undefined)) {
            // Shouldn't really happen, at any rate if it does, there's nothing to do.
            return;
        }
        if (!financialStatement) {
            // sanity check, shouldn't be able to get to this callback at all unless financialStatement is defined;
            console.error('Unexpected error, financialStatement is undefined in setMultiSelectForLineItem');

            return;
        }

        let startChangeIndex;
        let endChangeIndex;

        if ((!trackMultiSelectHover) || (!shiftKeyDown)) {
            const iLineItem = financialStatement.lineItems.findIndex(li => li.id === lineItem.id);
            if (iLineItem < 0) {
                // Also shouldn't be able to get here with a lineItem that's not from the list

                console.error('Unexpected error, cannot find lineItem in setMultiSelectForLineItem');

                return;
            }

            startChangeIndex = iLineItem;
            endChangeIndex = iLineItem;
        } else {
            startChangeIndex = trackMultiSelectHover.lowIndex;
            endChangeIndex = trackMultiSelectHover.highIndex;
        }

        let newSelectedItems;
        let selectedSectionId = lineItem.sectionId;

        if (multiSelectingLineItems === undefined) {
            newSelectedItems = new Map<string, LineItemSelectionTracking>();
        } else {
            newSelectedItems = new Map<string, LineItemSelectionTracking>(multiSelectingLineItems.lineItems);
        }

        for (let iLineItem = startChangeIndex; iLineItem <= endChangeIndex; iLineItem++) {
            const lineItem = financialStatement.lineItems[iLineItem];

            if (lineItem.lineItemType === FSModels.FinancialStatementLineItemType.DetailAccount) {
                if (!isFixedPositionDetailAccount(lineItem)) {
                    if (selected) {
                        // If it's not already in the list, add it.
                        newSelectedItems.set(
                            lineItem.id,
                            {
                                lineItem,
                                lineItemIndex: iLineItem,
                                selected: true,
                                highlighted: true
                            }
                        );
                    } else {
                        // If it's in the list, remove it.
                        newSelectedItems.delete(lineItem.id);
                    }
                }
            }
        }

        if (newSelectedItems.size <= 0) {
            setMultiSelectingLineItems(undefined);
        } else {
            let highIndex = 0;
            let lowIndex = financialStatement.lineItems.length + 1;

            newSelectedItems.forEach(li => {
                if (li.lineItemIndex > highIndex) {
                    highIndex = li.lineItemIndex;
                }
                if (li.lineItemIndex < lowIndex) {
                    lowIndex = li.lineItemIndex;
                }
            });

            setMultiSelectingLineItems({
                lineItems: newSelectedItems,
                sectionId: selectedSectionId,
                lowIndex,
                highIndex,
            });
        }
        setTrackMultiSelectHover(undefined);
    }

    const hoverMultiSelectLineItem = (lineItem: FSModels.FinancialStatementLineItemViewModel, entering: boolean): void => {
        if (!entering) {
            setTrackMultiSelectHover(undefined);
            return;
        }

        if (!financialStatement) {
            // sanity check, shouldn't be able to get to this callback at all unless financialStatement is defined;
            console.error('Unexpected error, financialStatement is undefined in hoverMultiSelectLineItem');

            return;
        }

        const iLineItem = financialStatement.lineItems.findIndex(li => li.id === lineItem.id);
        if (iLineItem < 0) {
            // Also shouldn't be able to get here with a lineItem that's not from the list

            console.error('Unexpected error, cannot find lineItem in hoverMultiSelectLineItem');

            return;
        }

        let lowIndex: number;
        let highIndex: number;

        // If nothing is selected then tracking, low and high indexes are iLineItem
        if (!multiSelectingLineItems) {
            lowIndex = iLineItem;
            highIndex = iLineItem;
        } else {
            // If we have selections then the low and high indexes in
            // multiSelectingLineItems will wrap those selections (whether)
            // there are unselected elements between them or not. 
            //
            // If iLineItem is below that selection then the track values should be the low
            // index to iLineItem.  If iLineItem is beyond that selection that track
            // values should be from beyond the high index to iLineItem
            lowIndex = multiSelectingLineItems!.lowIndex;
            highIndex = multiSelectingLineItems!.highIndex;
            if (iLineItem < multiSelectingLineItems!.lowIndex) {
                lowIndex = iLineItem;
                // Shouldn't happen but guard against high index being less than 0.
                highIndex = Math.max(multiSelectingLineItems!.lowIndex - 1, 0);
            } else if (iLineItem > multiSelectingLineItems!.highIndex) {
                lowIndex = multiSelectingLineItems!.highIndex + 1;
                highIndex = iLineItem;
            }
        }

        setTrackMultiSelectHover({
            lowIndex,
            highIndex,
        });
    }

    if (!!financialStatement) {
        return (
            <React.Fragment>
                <Container
                    fluid
                    className={'financial-statement-editor'}
                    id={idContainer}
                    onKeyDown={(e) => {
                        if (e.shiftKey !== shiftKeyDown) {
                            setShiftKeyDown(e.shiftKey);
                        }
                    }}
                    onKeyUp={(e) => {
                        if (e.shiftKey !== shiftKeyDown) {
                            setShiftKeyDown(e.shiftKey);
                        }
                    }}
                >
                    {renderErrorBanner(false)}
                    {!!userMsg && (
                        <Row>
                            <Col>
                                <div className={'error-state-col'}>
                                    <ErrorBanner
                                        errorState={userMsg.msg}
                                        onDefaultActionButton={() => userMsg.clear()}
                                    />
                                </div>
                            </Col>
                        </Row>
                    )}
                    {renderRegenerationStatus()}
                    <Row className={'top-row'}>
                        <Col>
                            <div>
                                <Tooltip title={'Regenerate workbooks for this data collection'}>
                                    <button
                                        className={'small'}
                                        onClick={() => onGenerateWorkbooks()}
                                    >
                                        Generate workbooks
                                    </button>
                                </Tooltip>


                                <UncontrolledDropdown
                                    className={'dropdown-button'}
                                    group
                                >
                                    <button
                                        color={'primary'}
                                        className={'small'}
                                        onClick={() => onSaveFinancialStatement(() => { })}
                                        disabled={!modelIsDirty}
                                    >
                                        Save
                                    </button>
                                    <DropdownToggle
                                        className={'dropdown-toggle'}
                                        caret
                                        color={'primary'}
                                        style={{
                                            padding: "0px 0px 0px 4px",
                                            width: "32px",
                                        }}
                                    />
                                    <DropdownMenu
                                        color={'primary'}
                                        className={'dropdown-menu'}
                                    >
                                        <DropdownItem
                                            className={'small'}
                                            onClick={() => setEditTemplateParameters(true)}
                                        >
                                            Save as template
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <Tooltip title={'Reset this financial statement to default'}>
                                    <button
                                        className={'small'}
                                        onClick={() => setConfirmReset(true)}
                                    >
                                        Reset
                                    </button>
                                </Tooltip>
                                <Tooltip title={'Manage and use financial statement templates'}>
                                    <button
                                        className={'small'}
                                        onClick={() => onSwitchTemplate()}
                                    >
                                        Templates
                                    </button>
                                </Tooltip>
                            </div>
                        </Col>
                        <Col xs={4} md={2}>
                            <FinancialStatementReportingPeriod
                                reportingPeriod={reportingPeriod}
                                onUpdate={ChangeReportingPeriod}
                            />
                        </Col>
                        <Col xs={4} md={2}>
                            <FinancialStatementType
                                statementType={financialStatementType}
                                onUpdate={onUpdateFinancialStatementType}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <FinancialStatementTable
                                financialStatement={financialStatement}
                                statementType={financialStatementType}
                                stylesBelowEdit={stylesBelowEdit}
                                onUpdateCaptionIndent={updateCaptionIndent}
                                containerProps={tableProps}
                                onShowInfo={setShowInfoFor}
                                onMultiSelect={setMultiSelectForLineItem}
                                onHoverMultiSelect={hoverMultiSelectLineItem}
                                onClearLineItemSelections={clearMultiLineTracking}
                                onTempLock={l => setTempLock(l)}
                                onDecrementLineItemSortOrder={l => DecrementLineItemSortOrder(l.id)}
                                onIncrementLineItemSortOrder={l => IncrementLineItemSortOrder(l.id)}
                                onMoveLineItemToNewSection={setMoveToNewSection}
                                renderData={renderData!}
                                onAddSubsection={(li) => setAddNewSubsection({ lineItem: li })}
                                onEditLineItem={(lineItem, targetId) => {
                                    setEditingLineItem({
                                        lineItem,
                                        targetId
                                    });
                                }}
                                onFilterLineItems={() => setFilterLineItemsDropped(!filterLineItemsDropped)}
                                onSortAccountNo={() => SortLineItems(FSModels.OrderLineItemsBy.AccountNumberThenCaptionText, FSModels.OrderSubsections.PreserveCurrentSortOrder)}
                                onSortAlpha={() => SortLineItems(FSModels.OrderLineItemsBy.CaptionText, FSModels.OrderSubsections.PreserveCurrentSortOrder)}
                                onSortByAccountingPeriod={(accountingPeriodId: string) => SortLineItems(FSModels.OrderLineItemsBy.AmountDescending, FSModels.OrderSubsections.PreserveCurrentSortOrder, undefined, accountingPeriodId)}
                                onToggleReportedAs={SetReportedAs}
                                filterLineItemsDropdownActive={filterLineItemsDropped}
                                lockedLineItem={lockedLineItem}
                                onLineItemMoreOptions={(lineItem, targetId) => setLineItemMoreOptionsMenu({ lineItem, targetId })}
                                onEditNumberFormatting={(targetId) => {
                                    setTargetIdEditNumberFormatting(targetId);
                                }}
                                numberFormattingDropped={!!targetIdEditNumberFormatting}
                                toggleColumnOrdering={ToggleReportingPeriodSortOrder}
                                excelNumberFormatters={excelNumberFormatters}
                                selectedLineItems={combinedMultiSelectingLineItems?.lineItems}
                            />
                        </Col>
                    </Row>
                </Container>
                {!!showInfoFor && (
                    <LineItemInfoPopover
                        target={lineItemCaptionId(showInfoFor, financialStatementType)}
                        onToggle={(on) => {
                            if (!on) {
                                setShowInfoFor(undefined)
                            }
                        }}
                        lineItem={showInfoFor}
                        statementType={financialStatementType}
                        renderData={renderData!}
                    />
                )}
                {!!moveToNewSection && (
                    <MoveToNewSectionMenu
                        target={lineItemCaptionId(moveToNewSection, financialStatementType)}
                        onToggle={(on) => {
                            if (!on) {
                                setMoveToNewSection(undefined)
                            }
                        }}
                        lineItem={moveToNewSection}
                        statementType={financialStatementType}
                        moveToLocationOptions={filteredMovedToDestinations}
                        onSelected={(lineItem, sectionId) => {
                            if (!!lineItem) {
                                MoveLineItems([{
                                    lineItemId: lineItem.id,
                                    parentSectionId: sectionId,
                                }]);
                            } else {
                                if (!!multiSelectingLineItems) {
                                    const lineItemsToMove: FSModels.MoveLineItemAction[] = [];

                                    multiSelectingLineItems.lineItems.forEach(selectionInfo => {
                                        if (selectionInfo.selected) {
                                            lineItemsToMove.push({
                                                lineItemId: selectionInfo.lineItem.id,
                                                parentSectionId: sectionId,
                                            });
                                        }
                                    });
                                    MoveLineItems(lineItemsToMove);
                                    clearMultiLineTracking();
                                } else {
                                    // Shouldn't be possible.
                                    console.error('multiSelectingLineItems is undefined attempting to move selected line items in FinancialStatementView.tsx');
                                }
                            }

                            setMoveToNewSection(undefined);
                        }}
                        financialStatement={financialStatement}
                        multiSelections={multiSelectingLineItems && multiSelectingLineItems.lineItems}
                    />
                )}
                {!!addNewSubsection && (
                    <AddNewSectionModal
                        lineItem={addNewSubsection.lineItem}
                        onClose={(data) => {
                            if (!!data) {
                                AddClassificationSection({
                                    reportedAs: data.positiveTxn,
                                    parentSectionId: data.subsectionOf,
                                    footerCaptionText: data.footer,
                                    headerCaptionText: data.header,
                                })
                            }
                            setAddNewSubsection(undefined);
                        }}
                        renderData={renderData!}
                    />
                )}
                {!!editingLineItem && (
                    <NameAndStylePopover
                        financialStatement={financialStatement}
                        formatting={captionFormatting}
                        uniqueId={SanitizeDOMSelector(editingLineItem.lineItem.id)}
                        target={SanitizeDOMSelector(editingLineItem.targetId)}
                        onToggle={(active: boolean) => {
                            if (!active) {
                                setEditingLineItem(undefined);
                            }
                        }}
                        renderData={renderData!}
                        lineItem={editingLineItem.lineItem}
                        onUpdateFormatting={(formatting, styleid) => {
                            // TODO: Actually change the formatting for the line item.
                        }}
                        onUpdateCaptionText={updateRelatedCaptions}
                        onCancel={() => setEditingLineItem(undefined)}
                    />
                )}
                {filterLineItemsDropped && (
                    <FilterDropdown
                        onToggle={(active) => setFilterLineItemsDropped(active)}
                        filters={lineItemFilters}
                        statementType={financialStatementType}
                        onFilterStateChange={(filter, newState) => SetFilterState(financialStatementType, filter, newState)}
                    />
                )}
                {!!targetIdEditNumberFormatting && (
                    <EditNumberFormattingPopover
                        onToggle={(active) => {
                            if (!active) {
                                setTargetIdEditNumberFormatting(undefined);
                            }
                        }}
                        statementType={financialStatementType}
                        targetId={targetIdEditNumberFormatting}
                        onStateChange={(option) => {
                            UsePredefinedNumberStyle(option.style);
                        }}
                        options={numberFormattingOptions}
                    />
                )}
                {lineItemMoreOptionsMenu && (
                    <LineItemMoreOptions
                        sectionsById={renderData?.sectionsById || new Map()}
                        lineItem={lineItemMoreOptionsMenu.lineItem}
                        targetId={lineItemMoreOptionsMenu.targetId}
                        onToggle={(active) => setLineItemMoreOptionsMenu(undefined)}
                        onDeleteSection={() => {
                            const deletingSectionId = lineItemMoreOptionsMenu.lineItem.sectionId;
                            const sectionToDelete = renderData!.sectionsById.get(deletingSectionId)!;
                            if (sectionToDelete.isEmpty) {
                                setConfirmDeleteSectionData({
                                    sectionId: deletingSectionId,
                                    sectionTitle: lineItemMoreOptionsMenu.lineItem.captionText,
                                    sectionType: 'section'
                                });
                            } else {
                                setDeleteNonEmptySectionData(lineItemMoreOptionsMenu.lineItem);
                            }
                            setLineItemMoreOptionsMenu(undefined);
                        }}
                        onSortAlpha={() => {
                            SortLineItems(
                                FSModels.OrderLineItemsBy.CaptionText, 
                                FSModels.OrderSubsections.PreserveCurrentSortOrder,
                                lineItemMoreOptionsMenu.lineItem.sectionId);

                            setLineItemMoreOptionsMenu(undefined);
                        }}                        
                        onSortAccountNo={() => {
                            SortLineItems(
                                FSModels.OrderLineItemsBy.AccountNumberThenCaptionText, 
                                FSModels.OrderSubsections.PreserveCurrentSortOrder,
                                lineItemMoreOptionsMenu.lineItem.sectionId);

                            setLineItemMoreOptionsMenu(undefined);
                        }}
                        onDeleteSectionHeader={() => {
                            setConfirmDeleteSectionData({
                                sectionId: lineItemMoreOptionsMenu.lineItem.id,
                                sectionTitle: lineItemMoreOptionsMenu.lineItem.captionText,
                                sectionType: 'sectionheader'
                            });
                            setLineItemMoreOptionsMenu(undefined);
                        }}
                    />
                )}
                {
                    confirmReset && (
                        <ConfirmModal
                            msg={`Are you sure you want to reset the financial statements to their initial state?`}
                            title={'Confirm reset'}
                            modalType={ConfirmModalType.yesno}
                            onTerminalButton={(yes?: boolean) => {
                                if (!!yes) {
                                    ResetFinancialStatements();
                                }
                                setConfirmReset(false);
                            }}
                        />
                    )
                }
                {
                    !!confirmDeleteSectionData && (
                        <ConfirmModal
                            msg={`Are you sure you want to delete ${fsSectionPresentationName.toLowerCase()} ${confirmDeleteSectionData.sectionTitle}?`}
                            title={'Confirm delete'}
                            modalType={ConfirmModalType.yesno}
                            onTerminalButton={(yes?: boolean) => {
                                if (!!yes) {
                                    if (confirmDeleteSectionData.sectionType === 'sectionheader') {
                                        DeleteLineItems([{
                                            lineItemId: confirmDeleteSectionData.sectionId
                                        }]);
                                    } else {
                                        // We don't get here unless we know the section is empty so undefined is okay
                                        // for the second parameter.
                                        DeleteClassificationSection(confirmDeleteSectionData.sectionId, undefined);
                                    }

                                    clearMultiLineTracking();
                                }
                                setConfirmDeleteSectionData(undefined);
                            }}
                        />
                     )
                }
                {
                    !!deleteNonEmptySectionData && (
                        <DeleteNonEmptySectionModal
                            lineItem={deleteNonEmptySectionData}
                            onClose={(moveToSectionId) => {
                                if (!!moveToSectionId) {
                                    DeleteClassificationSection(deleteNonEmptySectionData.sectionId, moveToSectionId);

                                    clearMultiLineTracking();
                                }
                                setDeleteNonEmptySectionData(undefined);
                            }}
                            renderData={renderData!}
                        />
                    )
                }
                {switchTemplates && (
                    <TemplateMgmtModal
                        onClose={(templateId) => {
                            if (!!templateId) {
                                onApplyTemplate(templateId);
                            }
                            setSwitchTemplates(false);
                        }}
                    />
                )}
                {
                    editTemplateParameters && (
                        <EditTemplateParametersModal
                            onClose={(parameters) => {
                                if (!!parameters) {
                                    onSaveAsTemplate(parameters);
                                }
                                setEditTemplateParameters(false);
                            }}
                        />
                    )
                }
                {
                    !!checkUserSave && (
                        <ConfirmModal
                            msg={`You have unsaved changes. Would you like to save those before managing templates?`}
                            title={'Unsaved Changes'}
                            modalType={ConfirmModalType.yesnocancel}
                            onTerminalButton={(yes?: boolean) => {
                                if (!!yes) {
                                    onSaveFinancialStatement(() => {
                                        checkUserSave.action();
                                    });
                                } else if (yes !== undefined) {
                                    checkUserSave.action();
                                }
                                setCheckUserSave(undefined);
                            }}
                        />
                    )
                }
            </React.Fragment>
        );
    } else {
        if (!!errorState) {
            return (
                <React.Fragment>
                    <Container
                        fluid
                        className={'financial-statement-editor'}
                        id={idContainer}
                    >
                        {renderErrorBanner(true)}
                    </Container>
                </React.Fragment>
            );
        } else {
            return (
                <>
                    <span>{`Loading ${financialStatementTypeDescriptor}...`}</span>
                </>
            );
        }
    }
}

export const FinancialStatementView = connect<InjectedReduxState, InjectedActionCreators, FinancialStatementViewProps, ApplicationState>(
    (appState: ApplicationState, props: FinancialStatementViewProps) => {
        const result =  {
            financialStatement: GetFinancialStatement(appState),
            getValidLocationsToMoveLineItem: GetValidLocationsToMoveLineItemFunc(appState),
            reportingPeriod: GetReportingPeriod(appState),
            excelNumberFormatters: GetExcelNumberFormatters(appState),
            lineItemFilters: GetFilterDescriptions(appState, props.financialStatementType),
            errorState: GetErrorState(appState),
            modelIsDirty: IsModelDirty(appState),
            workbookRegenerationTracking: GetWorkbookRegenerationTracking(appState)
        }
        return result;
    },
    dispatch => bindActionCreators(
        {
            ...FinancialStatementActions,
        },
        dispatch
    )
)(FinancialStatementViewComponent);
