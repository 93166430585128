import * as React from 'react';
import {
    FormFeedback,
    Input,
} from 'reactstrap';

import { AddUsersMetaDataItem } from './CommonUser';

import {
    TenantUserMetadataSetting,
} from '../../../Models/Api/strongbox.financialportal';

import { GetUnsafeContentMessage } from '../../../Utils/FormUtils';

import { UserMetadataConstants } from '../../../Utils/Constants';

type Props = {
    userMetadataSetting: TenantUserMetadataSetting;
    onValueChange: (name: string, value: string) => void;
    metadataValues: Map<string, AddUsersMetaDataItem>;   // keyed on metadata value name
}

export const UserMetadataInput: React.FC<Props> = (props): React.ReactElement => {
    const {
        userMetadataSetting,
        onValueChange,
        metadataValues,
    } = props;

    const inputValue = React.useMemo(() => {
        const metadata = metadataValues.get(userMetadataSetting.name);
        if (!metadata) {
            return '';
        }
        return metadata.metaDataItem.value;
        // metadataValues is the only dependency actually needed
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [metadataValues]);

    const inputValid = React.useMemo(() => {
        const metadata = metadataValues.get(userMetadataSetting.name);
        if (!metadata) {
            return true;
        }
        return metadata.contentIsValid;
        // metadataValues is the only dependency actually needed
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [metadataValues]);

    const feedbackMsg = React.useMemo(() => {
        if (inputValid) {
            return '';
        }
        return GetUnsafeContentMessage(userMetadataSetting.label);
        // inputValid is the only dependency actually needed
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [inputValid])

    return (
        <div>
            <Input
                type={'text'}
                id={userMetadataSetting.name}
                maxLength={UserMetadataConstants.MaxValueLength}
                placeholder={userMetadataSetting.placeholder || ''}
                onChange={(e) => {
                    onValueChange(userMetadataSetting.name, e.currentTarget.value);
                }}
                value={inputValue}
                invalid={!inputValid}
            />
            <FormFeedback tooltip>{feedbackMsg}</FormFeedback>
        </div>
    );
}
