import * as React from 'react';
import { connect } from 'react-redux';

import '../Main.scss';

import { ApplicationState } from '../../Store';
import { BrandConfig, GetBrandConfig } from '../../Store/Tenant';
import { GetStdThemeColors } from '../../Utils/Style';

import ShareableIcon from '@mui/icons-material/ShareOutlined';

import { LinkMode } from '../../Store/ImportFinancials';

import {
    Tooltip
} from '@mui/material';

type InjectedReduxState = {
    brandConfig: BrandConfig;
};

type InjectedActionCreators = {};

type SubmittedByIconProps = {
    linkType?: LinkMode;
    iconStyle?: React.CSSProperties;
    iconId: string;
};

type Props = SubmittedByIconProps & InjectedActionCreators & InjectedReduxState;

const SubmittedByIconComponent: React.FC<Props> = (props): React.ReactElement => {
    const {
        brandConfig,
        iconId,
        iconStyle,
        linkType,
    } = props;

    const content = React.useMemo(() => {
        const colors = GetStdThemeColors(brandConfig);

        switch (linkType) {
            case LinkMode.anonymous:
                return (
                    <Tooltip title={`Anonymous data collection`}>
                        <span
                            className={'submissions-icon submissions-icon-anonymous-user'}
                            id={`anonymousIcon${iconId}`}
                            style={{
                                ...iconStyle,
                                background: colors.primaryColor,
                            }}
                        />
                    </Tooltip>
                );
            case LinkMode.shareable:
                return (
                    <Tooltip title={`Secure url sent to data owner`}>
                        <ShareableIcon
                            color={'primary'}
                            className={'basic-list-icon'}
                            id={`shareableIcon${iconId}`}
                            onClick={() => { }}
                            style={iconStyle}
                        />
                    </Tooltip>
                );
            case LinkMode.direct:
                return (
                    <Tooltip title={`Data collected directly from Portal`}>
                        <span
                            className={'submissions-icon submissions-icon-direct-import'}
                            id={`directIcon${iconId}`}
                            style={{
                                ...iconStyle,
                                background: colors.primaryColor,
                            }}
                        />
                    </Tooltip>
                )
        }
        return (<></>);

        // linkType is the only dependency on want.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [linkType])

    return content;
}


export const SubmittedByIcon = connect<InjectedReduxState, InjectedActionCreators, SubmittedByIconProps, ApplicationState>(
    (appState: ApplicationState) => {
        return {
            brandConfig: GetBrandConfig(appState),
        };
    }
)(SubmittedByIconComponent);
