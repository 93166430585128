import * as React from 'react';
import { connect } from 'react-redux';

import {
    Col,
    Container,
    Row
} from 'reactstrap';

import '../Main.scss';

import { ApplicationState } from '../../Store';
import { actionCreators as UIActionCreators, GetAddUsers } from '../../Store/UIState';
import { GetDetailedUsers, GetUsers, LoadAllDetailedUsers } from '../../Store/User';

import { ActiveSettingsContainer } from './ActiveSettingsContainer';
import { SettingsNav } from './SettingsNav';
import { settingsTabs } from './SettingsTypes';
import { AddUsersModal } from './Users/AddUsersModal';

import { DetailedUserResponse, UserResponse } from '../../Models/Api/strongbox.financialportal';

import { BreadCrumbs } from '../BreadCrumbs/BreadCrumbs';
import { breadCrumbContent, pathConstants } from '../../Utils/Constants';

type InjectedReduxState = {
    showingAddUsers: boolean;
    users: UserResponse[];
    detailedUsers: DetailedUserResponse[];
};

type InjectedActionCreators = typeof UIActionCreators;

type SettingsContainerProps = {
}

type Props = SettingsContainerProps & InjectedActionCreators & InjectedReduxState;

const SettingsContainerComponent: React.FC<Props> = (props): React.ReactElement => {

    const onAddUserDismiss = (): void => {
        props.SetAddUsers(false);
        LoadAllDetailedUsers((errorMsg: string) => {})
    }

    return (
        <div
            style={{
                position: 'absolute',
                height: '90%',
                width: '100%',
                minWidth: '20px'
            }}
            className={`free-content-region`}
        >
            <div
                style={{
                    minWidth: '20px',
                    height: '100%'
                }}
                className={`contained-content control-region control-region-lender`}
            >
                <div style={{ height: '100%' }}>
                    <Container className={'portal-container-settings'} fluid>
                        <Row style={{ marginBottom: '1em' }} >
                            <Col>
                                <BreadCrumbs
                                    crumbs={[
                                        {
                                            content: breadCrumbContent.workspaceList,
                                            link: pathConstants.home,
                                        }
                                    ]}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <SettingsNav
                                    tabs={settingsTabs}
                                />
                            </Col>
                        </Row>
                        <Row className={'portal-settings-row'} >
                            <Col
                                className={'active-settings-column'}
                            >
                                <ActiveSettingsContainer />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

            {props.showingAddUsers &&
                <AddUsersModal
                    dismissModal={onAddUserDismiss}
                    existingUsers={props.detailedUsers}
                />
            }
        </div>
    );
}

export const SettingsContainer = connect<InjectedReduxState, InjectedActionCreators, SettingsContainerProps, ApplicationState>(
    (appState: ApplicationState) => {
        const result: InjectedReduxState = {
            showingAddUsers: GetAddUsers(appState),
            users: GetUsers(appState),
            detailedUsers: GetDetailedUsers(appState)
        };

        return result;
    },
    {
        ...UIActionCreators,
    }
)(SettingsContainerComponent);

