import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Navigate } from 'react-router-dom';

import { ApplicationState } from '../../Store';
import {
    actionCreators as ImportFinancialsActions,
    GetImportMap,
    ImportMap,
} from '../../Store/ImportFinancials';

import { GetImportStatus } from '../../Services/FinancialRecordsService';

import { LogException } from '../../Utils/Logging';

import {
    Button,
    Col,
    Container,
    Row,
} from 'reactstrap';

import {
    actionCreators as RunningImportsActions,
    GetRunningJobs,
    JobUpdateRecord,
    RunningImport
} from '../../Store/RunningImports';
import {
    GetPortalBusyState,
} from '../../Store/UIState';

import { RunningImportsJobItem } from './RunningImportsJobItem';

type InjectedReduxState = {
    jobs: RunningImport[];
    importMap?: ImportMap[];
    portalBusy: boolean;
};

type InjectedActionCreators = typeof RunningImportsActions & typeof ImportFinancialsActions;

type RunningImportsListProps = {
    key?: string;
    onJobDismiss?: (financialRecordId: string, workspaceId: string, fromNav: boolean) => void;
    scopeWorkspaceId?: string;
    onManageWorkspaceUsers?: (workspaceId: string, submissionId: string) => void;
    hideWorkspaceInfo?: boolean;
    showExplanatoryRow?: boolean;
};

type Props = RunningImportsListProps & InjectedReduxState & InjectedActionCreators;

export const RunningImportsListComponent: React.FC<Props> = (props): React.ReactElement => {
    const jobStatusPollingTimer = 5000; // 5 seconds

    const outcomePending = 'Pending';
    const outcomeSuccess = 'Success';

    const {
        DismissJob,
        DismissMultipleJobs,
        jobs,
        UpdateJobs,
        onJobDismiss,
        hideWorkspaceInfo,
        importMap,
        scopeWorkspaceId,
        onManageWorkspaceUsers,
        portalBusy,
        showExplanatoryRow,
    } = props;

    const [redirectTo, setRedirectTo] = React.useState<string | undefined>(undefined);

    React.useEffect(() => {
        if (!!redirectTo) {
            setRedirectTo(undefined);
        }
    }, [redirectTo]);

    React.useEffect(() => {
        const getRunningJobsStatus = async (): Promise<void> => {
            if (!jobs) {
                return;
            }

            const updates: JobUpdateRecord[] = [];

            for (let index = 0; index < jobs.length; index++) {
                const job = jobs[index];

                if ((!(job && job.status)) || (job.status.outcome === outcomePending)) {
                    const status = await GetImportStatus(job.workspaceId, job.financialRecordId);
                    if (!!status) {
                        updates.push({
                            workspaceId: job.workspaceId,
                            financialRecordId: job.financialRecordId,
                            status,
                        });
                    }
                    // Don't do anything on the else clause (i.e. error).  !status is expected and will flood the log with garbage.
                } else if (job.status.outcome !== outcomeSuccess) {
                    updates.push({
                        workspaceId: job.workspaceId,
                        financialRecordId: job.financialRecordId,
                        status: job.status,
                    });
                }
            }

            // We can get here and updates.length can be 0 if all the jobs are comlete and we are just showing
            // a dismiss button.  No need to update those statuses.
            if (updates.length !== 0) {
                UpdateJobs(updates);
            }
        }

        if (!jobs) {
            return;
        }

        const idTimer = setInterval(getRunningJobsStatus, jobStatusPollingTimer);

        return () => { clearInterval(idTimer); }
    }, [jobs, UpdateJobs]);

    const runJobDismissal = React.useCallback((financialRecordId: string, workspaceId: string, fromNav: boolean, redirectLink?: string) => {
        DismissJob(financialRecordId, workspaceId);
        onJobDismiss && onJobDismiss(financialRecordId, workspaceId, fromNav);
        if (!!redirectLink) {
            setRedirectTo(redirectLink);
        }
    }, [DismissJob, onJobDismiss])

    const dismissAllJobs = (): void => {
        const allJobs = jobs.map(j => { return { financialRecordId: j.financialRecordId, workspaceId: j.workspaceId }; });
        DismissMultipleJobs(allJobs);
    }

    const runJobRetryConnect = React.useCallback((financialRecordId: string, workspaceId: string) => {
        const thisJob = jobs.find(job => job.financialRecordId === financialRecordId);
        if (!thisJob) {
            const errMsg = `Failed finding job in list of jobs trying to run a reconnect on a job ${financialRecordId}`;
            console.error(errMsg);
            LogException(
                errMsg,
                new Error(errMsg),
                {
                    financialRecordId,
                    workspaceId,
                }
            );
            return;
        }
        if (!importMap) {
            const errMsg = `importMap is undefined in runJobRetryConnect ${financialRecordId}`;
            console.error(errMsg);
            LogException(
                errMsg,
                new Error(errMsg),
                {
                    financialRecordId,
                    workspaceId,
                }
            );
            return;
        }

        let keyAccountingPkg = thisJob.datasourceNameId;
        let keyConnectionId = thisJob.connectionId;

        if (!!keyAccountingPkg) {
            const map = importMap.find(
                mapEntry => {
                    if (keyConnectionId !== undefined) {
                        return mapEntry.importRequest.connectionId === keyConnectionId;
                    } else {
                        return mapEntry.workspaceId === workspaceId &&
                            mapEntry.accountingPackage.toLowerCase() === keyAccountingPkg?.toLowerCase()
                    }
                }
            );
            if (!map) {
                props.UpdateJobs([{
                    financialRecordId,
                    workspaceId,
                    status: {
                        errorDescription: 'Internal error, unable to find information about this data collection.  Use \'New Data Collection\' to start a new data collection.',
                        errorCode: 'InternalError',
                        outcome: 'Error'
                    }
                }])
            } else {
                props.RetryImportRequest(map);
                DismissJob(financialRecordId, workspaceId);
            }
        }
        // The state that actually matters to how this function is included.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [DismissJob, importMap, jobs])

    const jobContent = React.useMemo(() => {
        const result: React.ReactNode[] = [];
        let lastIndex = jobs.length - 1;

        if (!!scopeWorkspaceId) {
            // for some reason the lastIndexOf method doesn't take a lamba comparator rather it uses
            // === which doesn't really work where we want the last of a bunch of different 
            // running job list items that have the workspace id we're scoped to.

            lastIndex = -1;
            jobs.forEach((job, index) => {
                if (job.workspaceId === scopeWorkspaceId) {
                    lastIndex = index;
                }
            })
        }

        for (let index = 0; index < jobs.length; index++) {
            if ((!scopeWorkspaceId) || (scopeWorkspaceId === jobs[index].workspaceId)) {
                result.push((
                    <Row key={`job-row-${index}`} className={`job-row ${index < lastIndex ? 'job-row-margined' : ''}`} >
                        <Col>
                            <RunningImportsJobItem
                                job={jobs[index]}
                                onDismiss={runJobDismissal}
                                onRetryConnect={runJobRetryConnect}
                                hideWorkspaceInfo={hideWorkspaceInfo}
                                onManageWorkspaceUsers={onManageWorkspaceUsers}
                            />
                        </Col>
                    </Row>
                ));
            }
        }

        return result;

        // getRowHeader will not be changing so does not need to be in the deps list
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobs, runJobDismissal, scopeWorkspaceId]);

    if (!!redirectTo) {
        return (<Navigate to={redirectTo} />)
    }

    if (!jobs)  {
        return (<></>);
    } else {
        return (
            <Container
                key={props.key}
                style={{ marginTop: '5px' }}
                className={`running-jobs-container`}
            >
                {!!showExplanatoryRow && (
                    <Row
                        style={{
                            marginBottom: '25px',
                        }}
                    >
                        <Col
                            style={{
                                paddingLeft: '25px',
                            }}
                            className={'spaced-row-centered'}
                        >
                            <span>Showing data collections for workspaces to which you presently have access.</span>
                            {
                                jobs.length > 2 && (
                                    <Button
                                        className={'extrasmall'}
                                        onClick={() => dismissAllJobs()}
                                        disabled={portalBusy}
                                        style={{ marginLeft: '15px', marginRight: '25px' }}
                                    >
                                        Dismiss All
                                    </Button>
                                )
                            }
                        </Col>
                    </Row>
                )}
                {jobContent}
            </Container>
        );
    }
}

export const RunningImportsList = connect<InjectedReduxState, InjectedActionCreators, RunningImportsListProps, ApplicationState>(
    (appState: ApplicationState) => {

        const result = {
            jobs: GetRunningJobs(appState),
            importMap: GetImportMap(appState),
            portalBusy: GetPortalBusyState(appState),
        };

        /* leaving in for testing if you want a job that's static in a certain state
        result.jobs.push({
            submissionId: 'testid',
            submissionRequestId: 5,
            datasourceNameId: 'quickbooksonline',
            financialRecordId: 'anid',
            workspaceId: '820E3336-8E56-4314-8E0E-FBBDE4E140AC',
            workspaceName: 'Project ABC',
            status: {
                errorCode: 'None',
                outcome: 'Success',
            },
            connectionId: 'connectionid',
            hasShareableLink: false,
            canViewWorkspace: true,
            canManageWorkspaceUsers: true,
        });
        */

        return result;
    },
    dispatch => bindActionCreators(
        {
            ...RunningImportsActions,
            ...ImportFinancialsActions,
        },
        dispatch
    )
)(RunningImportsListComponent);
