import * as React from 'react';

import '../Main.scss';

import {
    Col,
    Container,
    Row
} from 'reactstrap';

import { ImportModeSelector } from './ImportModeSelector';
import { LinkMode } from '../../Store/ImportFinancials';

type Props = {
    importType: LinkMode;
    excelImportEnabled: boolean;
    onChangeImportType: (descriptor: LinkMode) => void;
};

export const ChooseImportModeModal: React.FC<Props> = (props): React.ReactElement => {
    const {
        excelImportEnabled,
        importType,
        onChangeImportType,
    } = props;

    return (
        <Container fluid>
            <Row>
                <Col>
                    <ImportModeSelector
                        id={'shareable-link-selector'}
                        selected={importType === LinkMode.shareable}
                        linkType={LinkMode.shareable}
                        onSelected={() => onChangeImportType(LinkMode.shareable)}
                    />
                </Col>
            </Row>
            <Row className={'extra-row-spacing-small'}>
                <Col>
                    <ImportModeSelector
                        id={'direct-link-selector'}
                        selected={importType === LinkMode.direct}
                        linkType={LinkMode.direct}
                        onSelected={() => onChangeImportType(LinkMode.direct)}
                    />
                </Col>
            </Row>
            {excelImportEnabled && (
                <Row className={'extra-row-spacing-small'}>
                    <Col>
                        <ImportModeSelector
                            id={'upload-excel-selector'}
                            selected={importType === LinkMode.excel}
                            linkType={LinkMode.excel}
                            onSelected={() => onChangeImportType(LinkMode.excel)}
                        />
                    </Col>
                </Row>
            )}
        </Container>
    )
}
