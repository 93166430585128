import { BasisOfAccountingPreference, SubmissionRequestSummary, SubmissionRequest } from '../Models/Api/strongbox.financialportal';
import { AccountingCollectionGroup, knownAccountingGroupIds } from './AccountingCollectionGroup';

export const PortalSubmissionRequestDefaultFullFiscalYears = 2;
export const PortalDefaultBasisOfAccounting = 'Accrual';

export type CookieCreateSubmissionRequestOptions = {
    basisOfAccountingPreference: BasisOfAccountingPreference;
    fullFiscalYearsToCollect: number;
    anonymizeCustomersAndVendors: boolean;
    provideUserCopy: boolean;
    allowUserUpload: boolean;
    endOfImport: 'Today' | 'LastFullMonth';
    accountingCollectionGroups: AccountingCollectionGroup[];
    hasShareableLink?: boolean;
}

export type PortalCreateSubmissionRequestOptions = {
    basisOfAccountingPreference: BasisOfAccountingPreference;
    fullFiscalYearsToCollect: number;
    anonymizeCustomersAndVendors: boolean;
    provideUserCopy: boolean;
    allowUserUpload: boolean;
    endOfImport: 'Today' | 'LastFullMonth';
    accountingCollectionGroups: Map<string, AccountingCollectionGroup>;
    mostRecentPeriodMonth?: number;
    mostRecentPeriodYear?: number;
    mostRecentPeriodDay?: number;
    hasShareableLink?: boolean;
}

export function CookieSROtoPortalSRO(
    cookieData: CookieCreateSubmissionRequestOptions,
    mostRecentPeriodMonth?: number,
    mostRecentPeriodYear?: number,
    mostRecentPeriodDay?: number,
    hasSharedLink?: boolean
): PortalCreateSubmissionRequestOptions {
    const result: PortalCreateSubmissionRequestOptions = {
        basisOfAccountingPreference: cookieData.basisOfAccountingPreference,
        fullFiscalYearsToCollect: cookieData.fullFiscalYearsToCollect,
        anonymizeCustomersAndVendors: cookieData.anonymizeCustomersAndVendors,
        provideUserCopy: cookieData.provideUserCopy,
        allowUserUpload: cookieData.allowUserUpload,
        endOfImport: cookieData.endOfImport,
        accountingCollectionGroups: new Map<string, AccountingCollectionGroup>(),
        mostRecentPeriodMonth: mostRecentPeriodMonth,
        mostRecentPeriodYear: mostRecentPeriodYear,
        mostRecentPeriodDay: mostRecentPeriodDay,
        hasShareableLink: hasSharedLink,
    }

    cookieData.accountingCollectionGroups.forEach(group => {
        result.accountingCollectionGroups.set(group.id, group)
    });

    return result;
}

export function PortalSubmissionRequestFromSubmissionRequestSummary(sr: SubmissionRequestSummary): PortalCreateSubmissionRequestOptions {
    const accountingCollectionGroups = new Map<string, AccountingCollectionGroup>();

    accountingCollectionGroups.set(
        knownAccountingGroupIds.financialStatements,
        {
            collect: !!sr.financialStatementsYearsToCollect,
            id: knownAccountingGroupIds.financialStatements,
        }
    );
    accountingCollectionGroups.set(
        knownAccountingGroupIds.accountsPayable,
        {
            collect: !!sr.payablesYearsToCollect,
            id: knownAccountingGroupIds.accountsPayable,
        }
    );
    accountingCollectionGroups.set(
        knownAccountingGroupIds.accountsReceivable,
        {
            collect: !!sr.receivablesYearsToCollect,
            id: knownAccountingGroupIds.accountsReceivable,
        }
    );
    accountingCollectionGroups.set(
        knownAccountingGroupIds.transactions,
        {
            collect: !!sr.transactionsYearsToCollect,
            id: knownAccountingGroupIds.transactions,
        }
    );

    // At the moment, the user can only set 1 value for number of fiscal years to collect and whether or not the various
    // groups are collected or not.  When we come in here, options.<group>ToCollect for groups that are collected will
    // all have the same value so we just need to get the first one that has a value. In the future this might change but
    // for purposes of setting fullFiscalYearsToCollect below, we can use this value.
    //
    // Also, the values in the database for the SR are based on what we pass in the api which is fiscal years to collect
    // as opposed to full fiscal years to collect which is the syntax we're using with the user so we subtract one from 
    // the value we get from the DB if we find it.
    let fullFiscalYearsCollected =
        sr.payablesYearsToCollect ||
        sr.receivablesYearsToCollect ||
        sr.transactionsYearsToCollect ||
        sr.financialStatementsYearsToCollect;

    if (fullFiscalYearsCollected) {
        fullFiscalYearsCollected--;
    } else {
        fullFiscalYearsCollected = PortalSubmissionRequestDefaultFullFiscalYears;
    }

    return {
        basisOfAccountingPreference: sr.basisOfAccountingPreference,
        fullFiscalYearsToCollect: fullFiscalYearsCollected,
        anonymizeCustomersAndVendors: sr.anonymizeCustomersAndVendors,
        provideUserCopy: sr.provideUserCopy,
        allowUserUpload: sr.allowUserUpload,
        endOfImport: 'Today',
        accountingCollectionGroups,
        mostRecentPeriodMonth: sr.mostRecentPeriodMonth,
        mostRecentPeriodYear: sr.mostRecentPeriodYear,
        mostRecentPeriodDay: sr.mostRecentPeriodDay,
        hasShareableLink: sr.hasShareableLink,
    };
}

export function PortalSubmissionRequestFromSubmissionRequest(sr: SubmissionRequest): PortalCreateSubmissionRequestOptions {
    const accountingCollectionGroups = new Map<string, AccountingCollectionGroup>();

    accountingCollectionGroups.set(
        knownAccountingGroupIds.financialStatements,
        {
            collect: !!sr.financialStatementsYearsToCollect,
            id: knownAccountingGroupIds.financialStatements,
        }
    );
    accountingCollectionGroups.set(
        knownAccountingGroupIds.accountsPayable,
        {
            collect: !!sr.payablesYearsToCollect,
            id: knownAccountingGroupIds.accountsPayable,
        }
    );
    accountingCollectionGroups.set(
        knownAccountingGroupIds.accountsReceivable,
        {
            collect: !!sr.receivablesYearsToCollect,
            id: knownAccountingGroupIds.accountsReceivable,
        }
    );
    accountingCollectionGroups.set(
        knownAccountingGroupIds.transactions,
        {
            collect: !!sr.transactionsYearsToCollect,
            id: knownAccountingGroupIds.transactions,
        }
    );

    // At the moment, the user can only set 1 value for number of fiscal years to collect and whether or not the various
    // groups are collected or not.  When we come in here, options.<group>ToCollect for groups that are collected will
    // all have the same value so we just need to get the first one that has a value. In the future this might change but
    // for purposes of setting fullFiscalYearsToCollect below, we can use this value.
    //
    // Also, the values in the database for the SR are based on what we pass in the api which is fiscal years to collect
    // as opposed to full fiscal years to collect which is the syntax we're using with the user so we subtract one from 
    // the value we get from the DB if we find it.

    let fullFiscalYearsToCollect =
            sr.payablesYearsToCollect ||
            sr.receivablesYearsToCollect ||
            sr.transactionsYearsToCollect ||
            sr.financialStatementsYearsToCollect;

    if (fullFiscalYearsToCollect) {
        fullFiscalYearsToCollect--;
    } else {
        fullFiscalYearsToCollect = PortalSubmissionRequestDefaultFullFiscalYears;
    }

    return {
        basisOfAccountingPreference: sr.basisOfAccountingPreference || PortalDefaultBasisOfAccounting,
        fullFiscalYearsToCollect: fullFiscalYearsToCollect,
        anonymizeCustomersAndVendors: sr.anonymizeCustomersAndVendors,
        provideUserCopy: sr.provideUserCopy,
        allowUserUpload: sr.allowUserUpload,
        endOfImport: 'Today',
        accountingCollectionGroups,
        mostRecentPeriodMonth: sr.mostRecentPeriodMonth,
        mostRecentPeriodYear: sr.mostRecentPeriodYear,
        mostRecentPeriodDay: sr.mostRecentPeriodDay,
        hasShareableLink: sr.hasShareableLink,
    };
}
