
import * as React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { useAuth0 } from '@auth0/auth0-react';

import { AuthTimerController } from './AuthTimerController';

import { FetchMethod, fetchWrapper } from '../Utils/FetchWrapper';
import { PortalHeader } from './PortalHeader';
import { ApplicationState } from '../Store';
import {
    actionCreators as AppSettingsActions,
} from '../Store/AppSettings'
import {
    IsLenderDisabled,
    RetrieveStyle,
    TenantActionCreators,
} from '../Store/Tenant';
import { pathConstants } from '../Utils/Constants';
import { CombinePathComponents } from '../Utils/PathUtils';
import { ErrorAction, ErrorBanner, ErrorState, stdErrorAction } from './ErrorBanner/ErrorBanner';

import { PostLoginUserResponse, UserCapability } from '../Models/Api/strongbox.financialportal';
import {
    actionCreators as UserActions,
    Auth0UserSettings,
    defaultAuth0RedirectLogout,
    GetAuth0Configuration,
    GetAuth0LoggingOut,
    GetAuth0Token,
    GetAuth0Id,
    LoggedInUserHasAccess,
} from '../Store/User';
import {
    actionCreators as UIStateActions,
    UIRedirectSearchParams
} from '../Store/UIState';

import { LogException, LogMessage, SeverityLevel } from '../Utils/Logging';
import { GetFullPathRelativeToCurrentWindow } from '../Utils/PathUtils';
import { fetchWrapperOptions } from '../Utils/FetchWrapper';

const epicFailureMsg = `We're sorry, a fatal error has occurred. ${stdErrorAction}`;
const loginFailureMsg = 'Login failed';
const loginFailureExtraMsg = 'This most commonly happens when the email address you used for login has not been invited to this Strongbox Portal.' +
                        '  Please contact your Strongbox Administrator and ask them to make sure you have been added to Strongbox.';

type InjectedReduxState = {
    auth0Config: Auth0UserSettings | undefined;
    auth0LoggingOut: boolean;
    auth0Token: string | undefined;
    auth0Id: string;
    h1Style: Object;
    hasRequiredAccess: boolean;
    isTenantDisabled: boolean;
}

type InjectedActionCreators = typeof AppSettingsActions & typeof UserActions & typeof UIStateActions & typeof TenantActionCreators;

type AuthorizedWindowWrapperProps = {
    children?: React.ReactNode;
    securedOp?: UserCapability;
    onPostLoginComplete: () => void;
    mainDivKey: string;
}

type Props = InjectedReduxState & InjectedActionCreators & AuthorizedWindowWrapperProps;

const AuthorizedWindowWrapperComponent: React.FC<Props> = (props): React.ReactElement => {
    const { getAccessTokenSilently, logout, isAuthenticated, isLoading, getIdTokenClaims } = useAuth0();

    const {
        auth0Config,
        auth0Token,
        hasRequiredAccess,
        isTenantDisabled,
        LogUserOut,
        mainDivKey,
        onPostLoginComplete,
        SetSessionInfo,
        SetLoggedInUser,
        SetRedirectAfterLogin,
    } = props;

    const [errorState, setErrorState] = React.useState<ErrorState | undefined>(undefined);
    const [authTimedOutState, setAuthTimedOutState] = React.useState<boolean>(false);

    const defaultAction: ErrorAction = {
        id: 'ok',
        text: 'Ok',
        onAction: (_: string) => {
            logout();
            setErrorState(undefined);
        },
    };

    // There is a window where we might be fetching the auth0 token and calling postlogin
    // during which a re-render could possibly trigger duplicating the logic that gets
    // the token and calls postlogin.  This is used to guard against that condition
    const [loggingIn, setLoggingIn] = React.useState<boolean>(false);
    const [postLoginPause, setPostLoginPause] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (postLoginPause) {
            const timerHandle = setTimeout(() => {
                setPostLoginPause(false);
            }, 5000);

            return () => { clearTimeout(timerHandle) };
        }
    }, [postLoginPause]);

    const processLoginResponse = async (token: string, response: Response): Promise<void> => {
        try {
            const user: PostLoginUserResponse = await response.json();

            LogMessage(
                `starting processLoginResponse userId: ${user.id}`,
                SeverityLevel.Information,
                {
                    userId: user.id,
                }
            );

            const userRoleIds = user.resourceRoles.map(resourceRole => resourceRole.roleId);

            SetSessionInfo(token, userRoleIds);

            SetLoggedInUser(user);
        } catch (exception) {
            console.error('Unable to process postLogin response in AuthorizedWindowWrapper: processLoginResponse');
            console.error(exception);

            LogException(
                `failure processing login response for`,
                exception,
                { rawResponse: response }
            );

            setErrorState({
                summaryMessage: loginFailureMsg,
                extraInformation: loginFailureExtraMsg,
                actions: [defaultAction]
            });
        } finally {
            setLoggingIn(false);
            onPostLoginComplete();
            setPostLoginPause(true);
        }
    }


    const executePostLogin = async (): Promise<void> => {
        if (!auth0Config) {
            // Shouldn't actually happen.
            return;
        }

        LogMessage(
            `Executing postLogin sequence`,
            SeverityLevel.Information,
            {
                audience: auth0Config.audience,
                scope: auth0Config.scope
            }
        );

        setLoggingIn(true);  // this is cleared in processLoginResponse
        setErrorState(undefined);

        try {
            const token = await getAccessTokenSilently(
                {
                    audience: auth0Config.audience,
                    scope: auth0Config.scope,
                }
            );

            const idTokenClaims = await getIdTokenClaims();
            let idpUserProperties: { [key: string]: string } = {}

            if (!!(idTokenClaims?.sub)) {
                try {
                    const userInfoFetchUrl = CombinePathComponents(`https://${auth0Config.domain}`, 'api/v2/users', encodeURIComponent(idTokenClaims!.sub));
                    const userInfo = await fetch(userInfoFetchUrl,
                        {
                            mode: 'cors',
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );

                    idpUserProperties = await userInfo.json();
                } catch (idpPropsFailure) {
                    LogException(
                        `Failed during postlogin processing, unable to get logged in user properties from identity provider`,
                        idpPropsFailure
                    );

                    console.error(idpPropsFailure);
                }
            }

            // We have to pass in the bearer token since within fetchWrapper it uses the value from
            // the redux store to get the bearer token if you pass true for requireAuth.

            const fetchResponse = await fetchWrapper('/api/auth/postlogin',
                {
                    method: FetchMethod.Post,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        emailAddress: idTokenClaims?.email || '',
                        idpUserProperties
                    })
                },
                true,
                false
            );

            processLoginResponse(token, fetchResponse);
        }
        catch (failureReason)
        {
            LogException(
                `Failed executing post login sequence`,
                failureReason
            );

            console.error(failureReason);
            setErrorState({
                summaryMessage: epicFailureMsg,
                extraInformation: undefined,
                actions: [defaultAction],
            });
            setLoggingIn(false);
        }
    }

    React.useEffect(() => {
        // When does postLogin actually get called.  It's not everytime the component is
        // mounted, e.g. React.useEffect(() => {}, []).   The auth0Token is stored in session
        // storage.  Thus, on the if statement below, we may be mounting and auth0Token may
        // be defined as it was read out of session storage.  So the if condition would NOT 
        // trigger and post-login is not called but we have all the information we need

        // props.auth0Config should only change when the app is initializing and it's
        // going from the unauthorized state to initialized.  After that it should remain
        // static. 
        //
        // isAuthenticated  could change at any time.
        //
        // The auth0Token could change on multiple conditions. It can go undefined in the
        // the following conditions.
        //      - We set a timer to 1 minute prior to the expiration of the JWT we get from 
        //        auth0. If that timer expires, it will be set undefined.  
        //      - It will be set undefined if the user presses the logout button.  
        //      - Lastly, it will be set undefined if we see isAuthenticated go false.
        //
        // The only place that auth0Token would get a value is within this effect where we
        // set it below after retrieving it.  So there will potentially be render cycles 
        // where it is undefined prior to finishing the sequence of fetch

        if ((!!auth0Config) &&
            (isAuthenticated) &&
            (!auth0Token) &&
            (!loggingIn)) {
            executePostLogin();
        } else if ((!!auth0Config) &&
            (isAuthenticated) &&
            (!!auth0Token)) {
        }
    },
        // See comment above for when these might change.  
        // Lint will complain about loggingIn not be present in the dependency list but because of
        // the way it is used in async handlers above that ends up being a very bad thing to do as
        // if there actually is a failure, the value of logging in cycles between true and false, 
        // thereby going into an infinite loop of executing this function between each change.

        // eslint-disable-next-line react-hooks/exhaustive-deps 
        [auth0Config, isAuthenticated]
    );

    if (isTenantDisabled) {
        return <Navigate to={pathConstants.tenantLenderDisabled} />
    }

    if (authTimedOutState) {
        return <Navigate to={pathConstants.authTimeout} />
    }

    fetchWrapperOptions.errorHandler = async error => {
        if(!errorState && error.status === 401) {
            setErrorState({
                summaryMessage: error.errorMessage || 'Your login session has expired. Please login to continue.',
                actions: [{
                    id: 'ok',
                    text: 'Ok',
                    onAction: (_) => {
                        SetLoggedInUser();
                        SetSessionInfo(undefined, []);
                        window.location.href = '/';
                    }
                }]                
            });
        }
    };  

    const mainDivClass =
        `free-content-region control-region control-region-lender`;

    // If we're in the error state then show that msg and allow the user to return to the login screen
    // 
    // Currently, the errorState only happens in the main useEffect above that retrieves the JWT and
    // calls postLogin.

    if (!!errorState) {
        return (
            <div key={mainDivKey}>
                <PortalHeader showLoggedInUser={true} />
                <div style={{ position: 'absolute', width: '100%' }} className={mainDivClass}>
                    <div style={{ height: '100%' }} className={'contained-content'}>
                        <ErrorBanner
                            errorState={errorState}
                        />
                    </div>
                </div>
            </div>
        )
    }

    // isLoading comes from auth0. Auth0 is in an indeterminate state if it's true.
    if (isLoading) {
        return (<div key={mainDivKey}></div>);
    }

    // If there's no auth0Token in props and we are authenticated that means we were redirected 
    // here from login.  The useEffect above will have triggered and we are currently retrieving 
    // an access token.

    if (((!(auth0Config && auth0Token)) || (props.auth0LoggingOut)) && (isAuthenticated)) {
        return (
            <div key={mainDivKey}>
                <PortalHeader />
                <div style={{ position: 'absolute', width: '100%' }} className={mainDivClass}>
                    <div style={{ height: '100%' }} className={'contained-content'}>
                        <h1 style={props.h1Style}>{props.auth0LoggingOut ? 'Logging Out...' : 'Initializing...'}</h1>
                    </div>
                </div>
            </div>
        );
    }

    // We were in a logged in state and came out of it or someone tried to navigate here directly with
    // a url and isn't logged in.  The latter scenario can happen if they click a deep link from an email to
    // grant a user permission to access a workspace and aren't presently logged in.
    // 
    // Immediately set the auth0Token to undefined and redirect to the login page.

    if (!isAuthenticated) {
        SetLoggedInUser();
        SetSessionInfo(undefined, []);

        let searchParameters: UIRedirectSearchParams | undefined = undefined;

        if (!!window.location.search) {
            const sp = new URLSearchParams(window.location.search);

            searchParameters = {
                searchParams: []
            }

            // on the forEach call, index will be the actual name of the search
            // parameter, param is the actual value.
            sp.forEach((param, index) => {
                searchParameters!.searchParams.push({
                    key: index,
                    value: param
                })
            });
        }

        // Set the path to redirect to after login.  This will address the deep link 
        // scenario above.
        SetRedirectAfterLogin(window.location.pathname, searchParameters);

        return (<Navigate to={pathConstants.login} />);
    }

    // Finally, if nothing else generated a different UI above, if the user doesn't
    // have permissions for this page, access denied.

    if (!hasRequiredAccess) {
        return (
            <p>Access Denied</p>
        )
    } 

    return (
        <div key={mainDivKey}>
            {props.children}
            {!postLoginPause && !loggingIn && (
                <AuthTimerController
                    auth0Token={auth0Token}
                    mainDivKey={mainDivKey}
                    onTimeout={() => {
                        setAuthTimedOutState(true);
                    }}
                    onSlidingTimeout={() => {
                        const returnTo = GetFullPathRelativeToCurrentWindow(`${defaultAuth0RedirectLogout}?${pathConstants.queryParamInactivityTimeout}=true`);
                        LogUserOut(logout, returnTo);
                    }}
                />
            )}
        </div>
    );
}

export const AuthorizedWindowWrapper = connect<InjectedReduxState, InjectedActionCreators, AuthorizedWindowWrapperProps, ApplicationState>(
    (appState: ApplicationState, props: AuthorizedWindowWrapperProps) => {
        const result = {
            auth0Config: GetAuth0Configuration(appState),
            auth0LoggingOut: GetAuth0LoggingOut(appState),
            auth0Token: GetAuth0Token(appState),
            h1Style: RetrieveStyle(appState, 'h1-login'),
            auth0Id: GetAuth0Id(appState),
            hasRequiredAccess: !!props.securedOp ? LoggedInUserHasAccess(appState, props.securedOp) : true,
            isTenantDisabled: IsLenderDisabled(appState),
        };

        return result;
    },
    dispatch => bindActionCreators(
        {
            ...AppSettingsActions,
            ...UserActions,
            ...UIStateActions,
            ...TenantActionCreators,
        },
        dispatch
    )
)(AuthorizedWindowWrapperComponent);

