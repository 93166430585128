import { createTheme, Theme } from '@mui/material/styles';
import {
    strongboxPrimary,
    strongboxSecondary,
    strongboxPrimaryBorrower,
    strongboxSecondaryBorrower,
} from './Constants';
import { BrandConfig } from '../Store/Tenant';

export function GetStdThemeColors(brandConfig: BrandConfig): { primaryColor: string, secondaryColor: string } {
    let primaryColor = strongboxPrimary;
    let secondaryColor = strongboxSecondary;

    if (!!brandConfig && !!brandConfig.themeSettings) {
        if (!!brandConfig.themeSettings.palette) {
            primaryColor = brandConfig.themeSettings.palette.primaryColor || primaryColor;
            secondaryColor = brandConfig.themeSettings.palette.secondaryColor || secondaryColor;
        }
    }

    return {
        primaryColor,
        secondaryColor
    };
}

export function GetBorrowerThemeColors(tenant: string): { primaryColor: string, secondaryColor: string } {
    let primaryColor = strongboxPrimaryBorrower;
    let secondaryColor = strongboxSecondaryBorrower;

    return {
        primaryColor,
        secondaryColor
    };
}


export function CreateStdTheme(brandConfig: BrandConfig): Theme {
    const colors = GetStdThemeColors(brandConfig);

    const result = createTheme({
        palette: {
            primary: {
                main: colors.primaryColor
            },
            secondary: {
                main: colors.secondaryColor
            }
        }
    });

    return result;
}

export function CreateBorrowerTheme(tenant: string): Theme {
    const colors = GetBorrowerThemeColors(tenant);

    return (createTheme({
        palette: {
            primary: {
                main: colors.primaryColor,
            },
            secondary: {
                main: colors.secondaryColor,
            }
        }
    }));
}

/**
 * Combines several classnames into a single class name, ignoring values
 * which are falsey.
 * @param classNames Class names to combine.
 */
export function combineClassNames(...classNames: string[]): string {
    if (!classNames || !classNames.length) {
        return '';
    }

    return classNames.map(c => c && c.trim()).filter(c => !!c).join(' ');
}
