import * as React from 'react';

import '../../Main.scss';

import { FSModels } from '@finagraph/financial-statement-editor';

import { FinancialStatementRenderData } from './UtilTypes';
import { InfoPopover, InfoPopoverItem } from '../../InfoPopover/InfoPopover';

type Props = {
    lineItem: FSModels.FinancialStatementLineItemViewModel;
    statementType: FSModels.FinancialStatementType;
    target: string;
    onToggle: (on: boolean) => void;
    renderData: FinancialStatementRenderData;
}

export const LineItemInfoPopover: React.FC<Props> = (props): React.ReactElement => {
    const {
        target,
        onToggle,
        lineItem,
        renderData
    } = props;

    const account: FSModels.DetailAccountDescriptor | undefined = React.useMemo(() => {
        return lineItem.detailAccountReference ? renderData.accountLookup.get(lineItem.detailAccountReference.accountId) : undefined;
        // This is in fact the dependency list I want
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lineItem]);

    const supplementalFields: InfoPopoverItem[] = React.useMemo(() => {
        if (!account?.supplementalFieldValues) {
            return [];
        }
        return account.supplementalFieldValues.map(a => {
            return {
                type: 'titlevalue',
                title: a.id,
                value: a.value || ''
            }
        });
    }, [account])

    const getRowContent = (): InfoPopoverItem[] => {
        const result: InfoPopoverItem[] = [
            {
                title: 'Account Id',
                value: account?.accountId || ''
            },
            {
                title: 'Account No/Code',
                value: account?.accountNo || ''
            },
            {
                title: 'Short Name',
                value: account?.shortName || ''
            },
            {
                title: 'Fully Qualified Name (FQN)',
                value: account?.hierarchicalName || ''
            },
            {
                title: 'Description',
                value: account?.description || ''
            },
            {
                title: 'Created On',
                value: account?.creationDate || ''
            },
            {
                title: 'Last Modified On',
                value: account?.lastModifiedDate || ''
            }
        ];
        return result.concat(supplementalFields);
    }

    return (
        <InfoPopover
            target={target}
            onToggle={onToggle}
            title={'Detail Account Information'}
            rows={getRowContent()}
            additional={{
                placement: 'top',
                content: (
                    <div
                        style={{
                            marginBottom: '10px',
                            paddingBottom: '10px'
                        }}
                        className={'separator-line-top'}
                    >
                        <h1>{lineItem.hierarchicalLocation.map(l => l.displayName).join(' > ')}</h1>
                    </div>
                )
            }}
        />
    )
}
