import { FSModels } from '@finagraph/financial-statement-editor';

import {
    WorkbookRevisionStatus
} from '../../../Models/Api/strongbox.financialportal';

export function GetStatementTypeTitle(financialStatementType: FSModels.FinancialStatementType, capitalize?: boolean): string {
    let result = 'financial statement';

    switch (financialStatementType) {
        case FSModels.FinancialStatementType.BalanceSheet:
            result = !!capitalize ? 'Balance sheet' : 'balance sheet';
            break;
        case FSModels.FinancialStatementType.IncomeStatement:
            result = !!capitalize ? 'Income statement' : 'income statement';
            break;
        case FSModels.FinancialStatementType.CashFlowStatement:
            result = !!capitalize ? 'Cashflow statement' : 'cashflow statement';
            break;
    }

    return result;
}

export function GetTrackingStatusText(status: WorkbookRevisionStatus): string {
    if (status.outcome === 'Success') {
        return 'Successfully completed';
    } else if (status.outcome === 'Pending') {
        return 'Generating workbooks';
    }
    return status.outcome;
}