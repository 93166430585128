import * as React from 'react';

import '../Main.scss';

import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

/**
 * @member(selectsEnd) Is this picker selecting the end date.  Default is false (undefined)
 * @member(selectsStart) Is this picker selecting the start date.  Default is false (undefined)
 * @member(maxDate) The highest date that can be selected.  Default is no maximum
 * @member(minDate) The lowest date that can be selected. Default is no minimum
 * */

type Props = {
    id: string;
    selected: Date | null;
    start: Date | null;
    end: Date | null;
    monthScrollBehavior: 'processChange' | 'hideSelection';
    maxDate?: Date;
    minDate?: Date;
    onSetDate?: (date: Date) => void;
    selectsEnd?: boolean;
    selectsStart?: boolean;
    open?: boolean;
    adjustDateOnChange?: boolean;
};

export const DatePicker: React.FC<Props> = (props): React.ReactElement => {
    const {
        adjustDateOnChange,
        end,
        id,
        maxDate,
        minDate,
        monthScrollBehavior,
        onSetDate,
        open,
        selected,
        selectsEnd,
        selectsStart,
        start
    } = props;

    const [datePickerTransparentSelection, setDatePickerTransparentSelection] = React.useState<boolean>(false);

    return (
        <div className={`date-picker-container ${datePickerTransparentSelection ? 'date-picker-invisible-selection' : ''}`}>
            <ReactDatePicker
                id={id}
                selected={selected}
                maxDate={maxDate}
                minDate={minDate}
                onChange={(date: Date) => {
                    setDatePickerTransparentSelection(false);
                    onSetDate && onSetDate(date);
                }}
                onCalendarClose={() => {
                    setDatePickerTransparentSelection(false);
                }}
                onCalendarOpen={() => {
                    setDatePickerTransparentSelection(false);
                }}
                onMonthChange={(date) => {
                    if (monthScrollBehavior === 'hideSelection') {
                        setDatePickerTransparentSelection(true);
                    } else {
                        onSetDate && onSetDate(date);
                    }
                }}
                adjustDateOnChange={adjustDateOnChange}
                startDate={start}
                endDate={!!end ? end : null}
                selectsEnd={selectsEnd}
                selectsStart={selectsStart}
                open={open}
            />
        </div>
    );
}
