import '../Main.scss';

import * as React from 'react';
import { connect } from 'react-redux';

import {
    Col,
    Row
} from 'reactstrap';

import ReactInlineSvg from 'react-inlinesvg';

import { ApplicationState } from '../../Store';
import { GetTenantName, GetBasicSettings } from '../../Store/Tenant';
import { assetBlobContainer } from '../../Utils/Constants';

import NoSelectionImage from '../../images/Portal Unselected.svg';

type InjectedReduxState = {
    tenantStyleClass: string;
    visiblePortalName: string;
}

type InjectedActionCreators = {

}

type ImportGraphicProps = {
};

type Props = ImportGraphicProps & InjectedActionCreators & InjectedReduxState;

const ImportGraphicComponent: React.FC<Props> = (props): React.ReactElement => {
    const {
        tenantStyleClass,
        visiblePortalName,
    } = props;

    return (
        <Row className={'instruction-row'}>
            <Col className={'instructions'}>
                <ReactInlineSvg
                    className={'no-selection-image'}
                    src={NoSelectionImage}
                    aria-label={'Strongbox Accounting Systems'}
                />
                <ReactInlineSvg
                    className={`top-content banner-image`}
                    src={`${assetBlobContainer}/brandassets/${tenantStyleClass}/Banner.svg`}
                    aria-label={visiblePortalName}
                />
            </Col>
        </Row>
    );
}

export const ImportGraphic = connect<InjectedReduxState, InjectedActionCreators, ImportGraphicProps, ApplicationState>(
    (appState: ApplicationState) => {
        const basicSettings = GetBasicSettings(appState);

        const result = {
            tenantStyleClass: GetTenantName(appState),
            visiblePortalName: basicSettings?.visibleName || 'Strongbox Portal',
        };

        return result;
    }
)(ImportGraphicComponent);
