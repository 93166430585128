import * as React from 'react';

import {
    Col,
    Container,
    Input,
    Label,
    Row
} from 'reactstrap';

import {
    Tooltip
} from '@mui/material';

import {
    ResourceRole,
    RoleResponse
} from '../../../Models/Api/strongbox.financialportal';

import IconButton from '@mui/material/IconButton';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

type VisibleRole = {
    role: RoleResponse;
    expanded: boolean;
}

type Props = {
    availableRoles: RoleResponse[];
    currentRoles?: ResourceRole[];
    disabled: boolean;
    disableDescriptions: boolean;
    onChanged: (role: RoleResponse, checked: boolean) => void;
    showDescriptionPopover: (role: RoleResponse, target: string) => void;
}

export const UserRoleSelection: React.FC<Props> = (props): React.ReactElement => {
    const {
        availableRoles,
        currentRoles,
        disabled,
        disableDescriptions,
        onChanged,
        showDescriptionPopover
    } = props;

    const [visibleRoles, setVisibleRoles] = React.useState<VisibleRole[]>([]);

    React.useEffect(() => {
        setVisibleRoles(availableRoles.map(availableRole => {
            const result: VisibleRole = {
                role: availableRole,
                expanded: false,
            };
            return result;
        }))
    }, [availableRoles])

    return (
        <Container fluid>
            {
                visibleRoles.map(role => {
                    const roleEnabled = !role.role.tags?.find(tag => tag.toLowerCase() === 'default');
                    return (
                        <Row key={role.role.id} className={'settings-role'}>
                            <Col>
                                <Row>
                                    <Col>
                                        <div className={'form-check'}>
                                            <Input
                                                checked={!!currentRoles?.find(userRole => userRole.roleId === role.role.id)}
                                                type={'checkbox'}
                                                id={`userrole${role.role.id}`}
                                                name={`userrole${role.role.id}`}
                                                value={role.role.id}
                                                key={`userrole${role.role.id}`}
                                                disabled={(disabled || (!roleEnabled))}
                                                onChange={(event) => {
                                                    const selectedRole = visibleRoles.find(compare => compare.role.id === role.role.id);
                                                    if (!!selectedRole) {
                                                        onChanged(role.role, event.currentTarget.checked);
                                                    }
                                                }}
                                            />
                                            <Label key={`roleLabel${role.role.id}`} for={`userrole${role.role.id}`}>{role.role.displayName}</Label><br />
                                        </div>
                                    </Col>
                                    <Col>
                                        <IconButton
                                            className={'simple-icon'}
                                            aria-label={'Show permission details'}
                                            onClick={() => showDescriptionPopover(role.role, `showpermissions${role.role.id}`)}
                                            id={`showpermissions${role.role.id}`}
                                            disabled={disableDescriptions}
                                        >
                                            <Tooltip title={'Show permission details'}>
                                                <ArrowRightIcon
                                                    color={'primary'}
                                                />
                                            </Tooltip>
                                        </IconButton>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    )
                })
            }
        </Container>
    )
}

