import * as React from 'react';
import { connect } from 'react-redux';

import {
    Col,
    Row
} from 'reactstrap';

import { ApplicationState } from '../../Store';
import {
    TenantFlowDisabledContent,
    GetBackgroundImageUrl,
    GetLenderDisabledContent,
    GetBorrowerDisabledContent,
    GetTenantLoginTextStyle,
} from '../../Store/Tenant';

import { LogMessage, SeverityLevel } from '../../Utils/Logging';

type InjectedReduxState = {
    lenderContent?: TenantFlowDisabledContent,
    borrowerContent?: TenantFlowDisabledContent,
    backgroundImage?: string;
};

type InjectedActionCreators = {
};

type TenantDisabledComponentProps = {
    flow: 'lender' | 'borrower',
};

type Props = TenantDisabledComponentProps & InjectedActionCreators & InjectedReduxState;

const TenantDisabledComponent: React.FC<Props> = (props): React.ReactElement => {
    const {
        lenderContent,
        borrowerContent,
        flow,
    } = props;

    const content = React.useMemo(() => {
        switch (flow) {
            case 'lender':
                return lenderContent;
            case 'borrower':
                return borrowerContent;
        }

        // All the cases are actually handled above, but maybe someday they're not.

        const msg = `Unrecognized flow option ${flow} encountered in TenantDisabled.tsx`;
        console.error(msg);
        LogMessage(msg, SeverityLevel.Warning);

        return undefined;

        // flow is the only parameter that matters
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [flow]);

    return (
        <React.Fragment>
            {!!content && content.paragraphs.map(p => {
                const style = GetTenantLoginTextStyle(p);
                return (
                    <Row className={'tenant-disabled-container'}>
                        <Col>
                            <p style={style}>{p.content}</p>
                        </Col>
                    </Row>
                );
            })}
        </React.Fragment>

    );
}

export const TenantDisabled = connect<InjectedReduxState, InjectedActionCreators, TenantDisabledComponentProps, ApplicationState>(
    (appState: ApplicationState) => {
        const result = {
            backgroundImage: GetBackgroundImageUrl(appState),
            lenderContent: GetLenderDisabledContent(appState),
            borrowerContent: GetBorrowerDisabledContent(appState),
        };

        return result;
    }
)(TenantDisabledComponent);
