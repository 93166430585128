import { StrongboxImportRequest } from '@finagraph/strongbox-finconnect-react';
import { AccountingPackage } from '../../Models/Api/AccountingPackages';

import {
    ConnectionOptions,
    CustomerFinancialsTarget,
    DirectLinkParameters,
    ImportMap
} from './Reducer';

export enum ImportFinancialsActions {
    SetImportCustomerFinancials = 'IMPORTFINANCIALSSTATE/SetImportCustomerFinancialsParams',
    ToggleImportCustomerFinancials = 'IMPORTFINANCIALSSTATE/ToggleImportCustomerFinancialsParams',
    SetBorrowerParameters = 'IMPORTFINANCIALSSTATE/SetBorrowerParameters',
    ToggleBorrowerParameters = 'IMPORTFINANCIALSSTATE/ToggleBorrowerParameters',
    ImportHasStarted = 'IMPORTFINANCIALSSTATE/ImportHasStarted',
    SaveImportParameters = 'IMPORTFINANCIALSTATE/SaveImportParameters',
    SetConnectionOptions = 'IMPORTFINANCIALSSTATE/SetConnectionOptions',
    SetRetryImportRequest = 'IMPORTFINANCIALSSTATE/RetryImportRequest',
    ClearRetryImportRequest = 'IMPORTFINANCIALSSTATE/ClearRetryRequest',
    SetBorrowerFlowAccountingPkg = 'IMPORTFINANCIALSSTATE/SetBorrowerFlowAccountingPkg',
}

export interface ISetImportCustomerFinancialsAction {
    readonly type: ImportFinancialsActions.SetImportCustomerFinancials;
    readonly isActive: boolean;
    readonly target: CustomerFinancialsTarget;
    readonly importingDirect?: DirectLinkParameters;
}

export interface IToggleImportCustomerFinancialsAction {
    readonly type: ImportFinancialsActions.ToggleImportCustomerFinancials;
}

export interface ISetBorrowerParametersAction {
    readonly type: ImportFinancialsActions.SetBorrowerParameters;
    readonly isActive: boolean;
}

export interface IToggleBorrowerParametersAction {
    readonly type: ImportFinancialsActions.ToggleBorrowerParameters;
}

export interface IImportHasStarted {
    readonly type: ImportFinancialsActions.ImportHasStarted;
}

export interface ISetConnectionOptions {
    readonly type: ImportFinancialsActions.SetConnectionOptions;
    readonly connectionOptions?: ConnectionOptions;
}

export interface ISaveImportParameters {
    readonly type: ImportFinancialsActions.SaveImportParameters;
    readonly workspaceId: string;
    readonly accountingPackage: AccountingPackage;
    readonly options: StrongboxImportRequest;
}

export interface ISetRetryImportRequest {
    readonly type: ImportFinancialsActions.SetRetryImportRequest;
    readonly importMap: ImportMap;
}

export interface IClearRetryImportRequest {
    readonly type: ImportFinancialsActions.ClearRetryImportRequest;
}

export interface ISetBorrowerFlowAccountingPkg {
    readonly type: ImportFinancialsActions.SetBorrowerFlowAccountingPkg;
    readonly pkg: string;
}

export type KnownAction =
    ISetImportCustomerFinancialsAction |
    IToggleImportCustomerFinancialsAction |
    ISetBorrowerParametersAction |
    IToggleBorrowerParametersAction |
    IImportHasStarted |
    ISetConnectionOptions |
    ISaveImportParameters |
    ISetRetryImportRequest |
    IClearRetryImportRequest |
    ISetBorrowerFlowAccountingPkg;
