import * as React from 'react';

import CopyIcon from '@mui/icons-material/FileCopyRounded';
import { CopyToClipboard } from '../../Utils/ClipboardUtils';

type Props = {
    copyLinkOnOpen: boolean;
    sharedLink: string;
    workspaceName: string;
    linkExpiration: string | undefined;
};

export const ShowLink: React.FC<Props> = (props): React.ReactElement => {
    const [linkJustCopied, setLinkJustCopied] = React.useState<boolean>(false);

    const hideLinkCopiedMessage = (): void => {
        setLinkJustCopied(false);
    }

    const copyLinkToClipboard = (): void => {
        if (!!props.sharedLink) {
            CopyToClipboard(props.sharedLink);

            setLinkJustCopied(true);
        }
    }

    React.useEffect(() => {
        if (props.copyLinkOnOpen && !!props.sharedLink) {
            copyLinkToClipboard();
        }
        // copyLinkToClipboard can change on frequently when it really isn't changing, so disabling the warning
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.copyLinkOnOpen, props.sharedLink]);

    React.useEffect(() => {
        if (linkJustCopied) {
            const timerId = setTimeout(() => hideLinkCopiedMessage(), 5000);
            return (() => {
                clearTimeout(timerId);
            });
        }
    }, [linkJustCopied]);

    return (
        <div>
            <p style={{ marginBottom: '0', fontSize: 'small' }}>
                <b>Important</b> - Anyone with this secure url will be able to submit new financial data to the <b>{props.workspaceName}</b> workspace.
                            {(props.linkExpiration &&
                    (<span> The secure url must be used before it expires on {new Date(props.linkExpiration).toLocaleDateString()}.</span>)
                )}
            </p>
            <br />
            <table style={{ width: '100%', backgroundColor: '#ddd', borderRadius: '0' }}>
                <tbody>
                    <tr>
                        <td style={{ padding: '10px' }}>
                            <u><a target={'_blank'} rel={'noopener noreferrer'} href={props.sharedLink} style={{ wordBreak: 'break-all' }}>{props.sharedLink}</a></u>
                        </td>
                        <td style={{ padding: '10px' }} onClick={() => copyLinkToClipboard() }>
                            <CopyIcon fontSize={'large'} titleAccess={'Copy to your clipboard'} style={{ cursor: 'pointer' }} />
                        </td>
                    </tr>
                </tbody>
            </table>
            <div style={{ height: 'auto', width: '100%', textAlign: 'right' }}>
                {(linkJustCopied ? 'Copied to your clipboard' : '')}
            </div>
        </div>
    );
}
