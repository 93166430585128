import * as React from 'react';
import { useCookies } from 'react-cookie';

import { connect } from 'react-redux';

import {
    Button,
    Col,
    Container,
    Row
} from 'reactstrap';

import '../Main.scss';

import { ApplicationState } from '../../Store';

import { BannerMessage } from '../../Models/Api/strongbox.financialportal';

import WarningIcon from '@mui/icons-material/WarningOutlined';
import InformationIcon from '@mui/icons-material/InfoOutlined';

type InjectedReduxState = {
};

type InjectedActionCreators = {};

type ConfigurableMessageBannerProps = {
    message: BannerMessage;
    keyIndex: number;
};

type Props = ConfigurableMessageBannerProps & InjectedActionCreators & InjectedReduxState;

export const ConfigurableMessageBannerComponent: React.FC<Props> = (props): React.ReactElement => {
    const {
        keyIndex,
        message,
    } = props;

    const [cookies, setCookie] = useCookies([message.hideMessageCookieName || '']);

    const showNotification = React.useMemo(() => {
        if (!!message.inactive) {
            return false;
        }

        if (!!message.hideMessageCookieName) {
            if (!!cookies[message.hideMessageCookieName]) {
                return false;
            }
        }

        return true;

        // List of dependencies I actually want, loggedInUser won't change during a run.
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [cookies, message])

    const paragraphStyles = React.useMemo(() => {
        const result: any[] = Array(message.messageParagraphs.length);

        message.messageParagraphs.forEach((pg, pgIndex) => {
            result[pgIndex] = {};
            if (!!pg.bold) {
                result[pgIndex]['fontWeight'] = 'bold';
            }
            if (!!pg.italic) {
                result[pgIndex]['fontStyle'] = 'italic';
            }
            if (!!pg.fontSize) {
                result[pgIndex]['fontSize'] = `${pg.fontSize}px`;
            }
        });

        return result;
    }, [message])

    const paragraphContent = React.useMemo(() => {
        const result: React.ReactElement[] = Array(message.messageParagraphs.length);

        message.messageParagraphs.forEach((pg, pgIndex) => {
            if (!message.hyperlinks) {
                result[pgIndex] = (
                    <p
                        key={ `configurable-message-pg-${keyIndex}-${pgIndex}` }
                        style={paragraphStyles[pgIndex]}
                    >
                        {pg.content}
                    </p>
                );
            } else {
                let htmlContent: React.ReactElement[] = [];
                let prefixStringStartIndex = 0;

                const matches = [...pg.content.matchAll(/(<hyperlink>)(\w*)(<\/hyperlink>)/gi)];
                matches.forEach((match, matchIndex) => {    
                    if ((match.index !== undefined) && (match.input !== undefined)) {
                        if (match.index > 0) {
                            const prefixString = match.input.substring(prefixStringStartIndex, match.index);
                            htmlContent = htmlContent.concat([(<React.Fragment key={`configurable-message-prefix-${keyIndex}-${pgIndex}-${matchIndex}`}>{prefixString}</React.Fragment>)])
                        }

                        // match[0] is the full matched text, match[1] is the first hyperlink and match[2] will be the id
                        const linkId = match[2];
                        const hyperlink = message.hyperlinks!.find(link => link.id === linkId);
                        if (!!hyperlink) {
                            htmlContent = htmlContent.concat([
                                <a 
                                    key={`configurable-message-hyperlink-${keyIndex}-${pgIndex}-${matchIndex}`}
                                    href={hyperlink.href}
                                    target={'_blank'}
                                    rel={'noopener noreferrer'}
                                >
                                    {hyperlink.prompt}
                                </a>
                            ]);
                        } else {
                            htmlContent = htmlContent.concat([(<React.Fragment key={`configurable-message-unmatched-hyperlink-${keyIndex}-${pgIndex}-${matchIndex}`}>{match[0]}</React.Fragment>)]);
                        }

                        prefixStringStartIndex = match.index + match[0].length;
                    }
                });

                const postfixString = pg.content.substring(prefixStringStartIndex);
                htmlContent = htmlContent.concat([(<React.Fragment key={`configurable-message-postfix-${keyIndex}-${pgIndex}`}>{postfixString}</React.Fragment>)])

                result[pgIndex] = (
                    <p
                        key={ `configurable-message-pg-${keyIndex}-${pgIndex}` }
                        style={paragraphStyles[pgIndex]}
                    >
                       {htmlContent.map(content => content)}
                    </p>
                );
            }
        });

        return result;
        
        // List of dependencies I actually want
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [message, paragraphStyles]);

    // daysToExpiration is only used if hideMessageCookieName is not undefined. It is used
    // as the expiration period on the cookie that gets set if the dismiss button is
    // pressed

    const daysToExpiration = React.useMemo(() => {
        return message.expiresInDays !== undefined ? message.expiresInDays : 7;
    }, [message]);

    // dismissButtonText is only used if hideMessageCookieName is not undefined. 
    const dismissButtonText = React.useMemo(() => {
        if (!message.hideMessageCookieName) {
            return "";
        }
        if (daysToExpiration === -1) {
            return message.hideForeverButtonText !== undefined ? message.hideForeverButtonText : 'Don\'t show this again';
        } else {
            return `Dismiss for ${daysToExpiration} day${daysToExpiration > 1 ? 's' : ''}`
        }
    }, [message, daysToExpiration])

    if (!showNotification) {
        return <></>;
    }

    return (
        <Container
            className={`configurable-message-banner control-region`}
            fluid
        >
            <Row>
                {
                    !!message.level && (
                        <Col className={'icon-column'} xs={5} md={3} xl={2}>
                            {
                                message.level === 'Warning' ? (<WarningIcon color={'primary'} />) : (<InformationIcon color={ 'primary'} />)
                            }
                        </Col>
                    )
                }
                <Col>
                    { !!message.headerText && (
                        <Row className={'header-row'}>
                            <Col>
                                <h2>{message.headerText}</h2>
                            </Col>
                        </Row>
                    )}
                    <Row>
                        <Col>
                            {
                                paragraphContent.map((p) => p)
                            }
                        </Col>
                    </Row>
                    {
                        (!!message.hideMessageCookieName) && ( // TODO: handle hyperlinks || !!message.moreInfo ) && (
                            <Row>
                                <Col className={'footer-row'}>
                                    {!!message.hideMessageCookieName && (
                                        <div className={'button-row'}>
                                            <Button
                                                className={'small'}
                                                onClick={() => {
                                                    let expires: Date | undefined = undefined;

                                                    if (daysToExpiration > 0) {
                                                        expires = new Date();
                                                        expires.setDate(expires.getDate() + daysToExpiration);
                                                    } else {
                                                        expires = new Date();
                                                        expires.setMonth(expires.getMonth() + 1);
                                                    } 

                                                    setCookie(
                                                        message.hideMessageCookieName!,
                                                        'yes',
                                                        { expires }
                                                    );
                                                }}
                                            >
                                                {dismissButtonText}
                                            </Button>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        )}
                </Col>
            </Row>

        </Container>
    );
}

export const ConfigurableMessageBanner = connect<InjectedReduxState, InjectedActionCreators, ConfigurableMessageBannerProps, ApplicationState>(
    (appState: ApplicationState, props: ConfigurableMessageBannerProps) => {
        return {
        };
    }
)(ConfigurableMessageBannerComponent);
