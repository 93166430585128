import {
    FinancialWorkbook,
} from '../Models/Api/strongbox.financialportal';
import { GetDocTime, GetWorkbookURL } from '../Services/FinancialRecordsService';

export type DownloadableFile = {
    filename: string;
    isDeleted: boolean; // flag stating whether the file has been deleted due to retention policy
    url?: string;
    time?: string;
    id?: string;       // optional id that has meaning to the user of this class.
    separator?: boolean;   // optional value that marks this as a separator.  filename is descriptive text that will be underlined.
    fileSize?: number; // optional value containing the file size in bytes
};

export type GroupedFiles = {
    summaryDocument?: DownloadableFile;
    revisionNumber?: number;
    revisionDate?: Date;
    supportingDocuments: DownloadableFile[];
}

export type MaxUploadSizes = {
    value: number;
    description: string;
};

export const maxFileUploadSize = (): MaxUploadSizes => {
    return {
        value: 68157440,
        description: '65Mb',
    };
};

export function WorkbookToDownloadableFile(
    workspaceId: string,
    financialRecordId: string | undefined,
    financialRecordCreated: string | undefined,
    workbook: FinancialWorkbook | undefined
): DownloadableFile | undefined {
    if (!workbook) {
        return undefined;
    }
    return {
        filename: workbook.filename || 'Unnamed file',
        isDeleted: false,
        id: workbook.variantId,
        time: GetDocTime(workbook.creationTime),
        fileSize: workbook.fileSize,
        url: GetWorkbookURL(
            workspaceId,
            financialRecordId || '',
            workbook.variantId,
            financialRecordCreated || '',
            workbook.revisionNumber.toString()
        )
    };
}

