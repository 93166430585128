import '../Main.scss';

import * as React from 'react';

import {
    Col,
    FormGroup,
    Input,
    Label,
    Row
} from 'reactstrap';

import { BasisOfAccountingPreference } from '../../Models/Api/strongbox.financialportal';
import { PortalSubmissionRequestDefaultFullFiscalYears, PortalCreateSubmissionRequestOptions } from '../../Models/PortalCreateSubmissionRequestOptions';
import { FinancialsImportDatePicker } from './FinancialsImportDatePicker';

import { getUsableDay } from '../../Utils/DateUtils';
import { DefaultLookBackMonths } from '../../Utils/Constants';
import { MostRecentDate } from '../../Utils/MostRecentDate';

import { LinkMode } from '../../Store/ImportFinancials';

export enum OnOffSettings {
    anonymizeCustomersAndVendors,
    userGetsCopy,
    allowUserUpload
};

type Props = {
    showShareableLinkParameters: boolean;
    directLink: boolean;
    disableFileUpload?: boolean;
    disableProvideUserCopy?: boolean;
    disableAnonymizedResults?: boolean;
    disableBasisOfAccounting?: boolean;
    allUserOptionsDisabled?: boolean;
    parameters: PortalCreateSubmissionRequestOptions;
    maxCollectionPeriods: number;
    onMostRecentMonthChange?: (newValue: MostRecentDate) => void;
    onPeriodsToCollectChanged?: (newValue: number) => void;
    onToggleOnOffSetting?: (setting: OnOffSettings) => void;
    onBasisOfAccountingChanged?: (newValue: BasisOfAccountingPreference) => void;
    onCollectThroughChanged?: (newValue: 'Today' | 'LastFullMonth') => void;
    numberOfMonthsInDropdown?: number;
    idPrefix?: string;
    noTimeRestrictionForDataCollection?: boolean;
    linkMode: LinkMode;
    replaceCalendarWithMonthEndOrToday?: boolean;
}

export const CollectFinancialsImportParameters: React.FC<Props> = (props): React.ReactElement => {
    const notUsedDirectMessage = 'Setting only enabled when shareable link is selected as the Method of Collection.';

    const {
        noTimeRestrictionForDataCollection,
        directLink,
        disableFileUpload,
        disableProvideUserCopy,
        disableBasisOfAccounting,
        disableAnonymizedResults,
        allUserOptionsDisabled,
        onToggleOnOffSetting,
        onBasisOfAccountingChanged,
        onMostRecentMonthChange,
        onPeriodsToCollectChanged,
        parameters,
        maxCollectionPeriods,
        numberOfMonthsInDropdown,
        idPrefix,
        showShareableLinkParameters,
        linkMode,
        replaceCalendarWithMonthEndOrToday,
        onCollectThroughChanged,
    } = props;

    const {
        allowUserUpload,
        anonymizeCustomersAndVendors,
        basisOfAccountingPreference,
        fullFiscalYearsToCollect,
        mostRecentPeriodMonth,
        mostRecentPeriodYear,
        mostRecentPeriodDay,
        provideUserCopy,
    } = props.parameters;

    const [fullFYearsToCollect, setFullFYearsToCollect] = React.useState<number>(() => {
        return fullFiscalYearsToCollect !== 0 ? fullFiscalYearsToCollect : PortalSubmissionRequestDefaultFullFiscalYears;
    });
    const [currentYearOnly, setCurrentYearOnly] = React.useState<boolean>(false);

    React.useEffect(() => {
        setCurrentYearOnly(fullFiscalYearsToCollect === 0)

        // These are the dependencies I actually want.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fullFiscalYearsToCollect]);

    const shareableLinkContent = React.useMemo(() => {
        return (
            <>
                <FormGroup className={'modal-group-header-row'}>
                    {disableProvideUserCopy !== true && (
                        <Row>
                            <Col>
                                <FormGroup check>
                                    <Input
                                        checked={parameters && provideUserCopy}
                                        disabled={(!parameters) || (directLink)}
                                        type='checkbox'
                                        name={`${idPrefix}-CopyForUser`}
                                        id={`${idPrefix}-copy-for-user`}
                                        onChange={() => {
                                            onToggleOnOffSetting && onToggleOnOffSetting(OnOffSettings.userGetsCopy);
                                        }}
                                    />
                                    <Label check for={`${idPrefix}-copy-for-user`}>Give user a copy of data collected (not analysis)</Label>
                                </FormGroup>
                            </Col>
                        </Row>
                    )}
                    {disableProvideUserCopy !== true && (
                        <Row>
                            <Col xs='1'></Col>
                            <Col>
                                <span className={'faded'}>
                                    {
                                        (!directLink) ?
                                            'Provides transparency to user of data they are sharing.' :
                                            notUsedDirectMessage
                                    }
                                </span>
                            </Col>
                        </Row>
                    )}
                </FormGroup>
                <FormGroup className={'modal-group-header-row'}>
                    {disableFileUpload !== true && (
                        <Row>
                            <Col>
                                <FormGroup check>
                                    <Input
                                        checked={parameters && allowUserUpload}
                                        disabled={(!parameters) || (directLink)}
                                        type='checkbox'
                                        name={`${idPrefix}-AllowAdditionalDocs`}
                                        id={`${idPrefix}-allow-additional-docs`}
                                        onChange={() => {
                                            onToggleOnOffSetting && onToggleOnOffSetting(OnOffSettings.allowUserUpload);
                                        }}
                                    />
                                    <Label check for={`${idPrefix}-allow-additional-docs`}>Allow user to upload additional documents (xlsx, pdf, etc.)</Label>
                                </FormGroup>
                            </Col>
                        </Row>
                    )}
                    {(disableFileUpload !== true) && (
                        <Row>
                            <Col xs='1'></Col>
                            <Col>
                                <span className={'faded'}>
                                    {
                                        (!directLink) ?
                                            'Allows user to upload additional documents that may or may not be directly related to the data collected from their accounting package.' :
                                            notUsedDirectMessage
                                    }
                                </span>
                            </Col>
                        </Row>
                    )}
                </FormGroup>
            </>
        );

        // These are the dependencies I actually want.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parameters, directLink, disableFileUpload, disableProvideUserCopy, onToggleOnOffSetting]);

    // The date picker is finicky about re-rendering.  We add a numeric element to the key for the
    // div containing the date picker that gets incremened every time the selected date changes and it gets updated when it's supposed to.

    const [datePickerKey, setDatePickerKey] = React.useState<number>(1);

    const datePickerParameters = React.useMemo(() => {
        if ((mostRecentPeriodMonth === undefined) ||
            (mostRecentPeriodYear === undefined) ||
            (mostRecentPeriodDay === undefined) ||
            (numberOfMonthsInDropdown === undefined)) {
            return undefined;
        }

        const today = new Date();
        const earliestDate = new Date(today.getFullYear(), today.getMonth() - numberOfMonthsInDropdown + 1, 1);

        let currentDay: Date;

        if (!!noTimeRestrictionForDataCollection) {
            currentDay = new Date(
                mostRecentPeriodYear,
                mostRecentPeriodMonth,
                mostRecentPeriodDay
            );
        } else {
            currentDay = new Date(
                mostRecentPeriodYear,
                mostRecentPeriodMonth,
                getUsableDay(mostRecentPeriodMonth, mostRecentPeriodDay, mostRecentPeriodYear)
            );
        }

        const result = {
            minDate: earliestDate,
            maxDate: today,
            importDay: currentDay
        }

        setDatePickerKey(datePickerKey + 1);

        return result;

        // These are the dependencies I actually want.  You'll get an infinite re-rendering issue if you have datePickerKey as a dependency
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mostRecentPeriodMonth, mostRecentPeriodYear, mostRecentPeriodDay, numberOfMonthsInDropdown]);

    const excelImportParameters = (): React.ReactElement => {
        return (
            <FormGroup className={'modal-group-header-row'}>
                <Row className={'extra-row-spacing-medium'}>
                    <Col>
                        <h1>Please note:</h1>
                        <p><b>The upload template will be produced based on dates selected above and are required for a successful upload.</b></p>
                    </Col>
                </Row>
            </FormGroup>
        );
    }

    const accountingPkgImportParameters = (): React.ReactElement => {
        return (
            <React.Fragment>
                {
                    ((disableBasisOfAccounting !== true) || (allUserOptionsDisabled !== true)) && (
                        <hr />
                )}
                {
                    disableBasisOfAccounting !== true && (
                        <FormGroup className={'modal-group-header-row'}>
                            <Row>
                                <Col xs={12} md={5}>Select your preferred Basis of Accounting</Col>
                                <Col style={{ marginRight: '15px' }} xs='auto'>
                                    <FormGroup check>
                                        <Input
                                            checked={props.parameters && (!basisOfAccountingPreference || basisOfAccountingPreference === 'Accrual')}
                                            disabled={!props.parameters}
                                            type='radio'
                                            name={`${idPrefix}-Accrual`}
                                            id={`${idPrefix}-id-Accrual`}
                                            onChange={() => {
                                                onBasisOfAccountingChanged && onBasisOfAccountingChanged('Accrual');
                                            }}
                                        />
                                        <Label
                                            check
                                            for={`${idPrefix}-id-accrual`}
                                        >
                                            Accrual
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col style={{ marginRight: '15px' }} xs='auto'>
                                    <FormGroup check>
                                        <Input
                                            checked={props.parameters && basisOfAccountingPreference === 'Cash'}
                                            disabled={!props.parameters}
                                            type='radio'
                                            name={`${idPrefix}-Cash`}
                                            id={`${idPrefix}-id-Cash`}
                                            onChange={() => {
                                                onBasisOfAccountingChanged && onBasisOfAccountingChanged('Cash');
                                            }}
                                        />
                                        <Label
                                            check
                                            for={`${idPrefix}-id-cash`}
                                        >
                                            Cash
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </FormGroup>
                    )
                }
                {
                    allUserOptionsDisabled !== true && (
                        <React.Fragment>
                            {disableBasisOfAccounting !== true && (
                                <hr />
                            )}
                            <FormGroup className={'modal-group-header-row'}>
                                {disableAnonymizedResults !== true && (
                                    <React.Fragment>
                                        <Row>
                                            <Col>
                                                <FormGroup check>
                                                    <Input
                                                        checked={props.parameters && anonymizeCustomersAndVendors}
                                                        disabled={!props.parameters}
                                                        type='checkbox'
                                                        name={`${idPrefix}-AnonCustomersAndVendors`}
                                                        id={`${idPrefix}-anon-customers-and-vendors`}
                                                        onChange={() => {
                                                            onToggleOnOffSetting && onToggleOnOffSetting(OnOffSettings.anonymizeCustomersAndVendors);
                                                        }}
                                                    />
                                                    <Label check for={`${idPrefix}-anon-customers-and-vendors`}>Anonymize customers and vendors</Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs='1'></Col>
                                            <Col xs='auto'><span className={'faded'}>Replaced with unique ID for each customer and vendor.</span></Col>
                                        </Row>
                                    </React.Fragment>
                                )}
                            </FormGroup>
                        </React.Fragment>
                    )
                }
                {showShareableLinkParameters && shareableLinkContent}
            </React.Fragment>
        );
    }

    return (
        <>
            <FormGroup>
                <Row style={{ marginTop: '15px' }}>
                    <Col>
                        <span>Select the date to collect data through:</span>
                        <Row>
                            {!!datePickerParameters && (replaceCalendarWithMonthEndOrToday !== true) && (
                                <Col xs={12} sm={5} lg={4}>
                                    <div key={`financials-import-date-picker-key-${datePickerKey.toString()}`} className={'standalone-date-picker-container'}>
                                        <FinancialsImportDatePicker
                                            selectedDate={datePickerParameters?.importDay || new Date()}
                                            maxDate={!!noTimeRestrictionForDataCollection ? undefined : datePickerParameters.maxDate}
                                            minDate={!!noTimeRestrictionForDataCollection ? undefined : datePickerParameters.minDate}
                                            maxLookBackMonths={numberOfMonthsInDropdown || DefaultLookBackMonths}
                                            keyPrefix={idPrefix}
                                            onSetDate={(d) => onMostRecentMonthChange && onMostRecentMonthChange({
                                                year: d.getFullYear(),
                                                month: d.getMonth(),
                                                day: d.getDate()
                                            })}
                                        />
                                    </div>
                                </Col>
                            )}
                            {(replaceCalendarWithMonthEndOrToday === true) && (
                                <Col xs={12} sm={5} lg={4}>
                                    <FormGroup check>
                                        <Row className={'extra-row-spacing-medium'}>
                                            <Col className={'vcentered-content-col'}>
                                                <div className={'form-check form-check-no-padding'}>
                                                    <Input
                                                        checked={!!parameters && parameters.endOfImport === 'Today'}
                                                        disabled={!props.parameters}
                                                        type='radio'
                                                        name={`${idPrefix}-collect-through-today`}
                                                        id={`${idPrefix}-collect-through-today`}
                                                        onChange={(e) => {
                                                            if (!!e.currentTarget.checked) {
                                                                onCollectThroughChanged && onCollectThroughChanged('Today');
                                                            }
                                                        }}
                                                    />
                                                    <Label
                                                        check
                                                        for={`${idPrefix}-collect-through-today`}
                                                    >
                                                        {'Collect through current day'}
                                                    </Label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className={'extra-row-spacing-small'}>
                                            <Col className={'vcentered-content-col'}>
                                                <div className={'form-check form-check-no-padding'}>
                                                    <Input
                                                        checked={!!parameters && parameters.endOfImport === 'LastFullMonth'}
                                                        disabled={!props.parameters}
                                                        type='radio'
                                                        name={`${idPrefix}-collect-through-last-full-month`}
                                                        id={`${idPrefix}-collect-through-last-full-month`}
                                                        onChange={(e) => {
                                                            if (!!e.currentTarget.checked) {
                                                                onCollectThroughChanged && onCollectThroughChanged('LastFullMonth');
                                                            }
                                                        }}
                                                    />
                                                    <Label
                                                        check
                                                        for={`${idPrefix}-collect-through-last-full-month`}
                                                    >
                                                        {'Collect through last full month'}
                                                    </Label>
                                                </div>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                            )}
                            <Col>
                                <FormGroup check>
                                    <Row className={'extra-row-spacing-medium'}>
                                        <Col className={'vcentered-content-col'}>
                                            <div className={'form-check form-check-no-padding'}>
                                                <Input
                                                    checked={currentYearOnly}
                                                    disabled={!props.parameters}
                                                    type='radio'
                                                    name={`${idPrefix}-fullFiscalYear`}
                                                    id={`${idPrefix}-id-full-fiscal-year`}
                                                    onChange={() => {
                                                        setCurrentYearOnly(true);
                                                        onPeriodsToCollectChanged && onPeriodsToCollectChanged(0);
                                                    }}
                                                />
                                                <Label
                                                    check
                                                    for={`${idPrefix}-id-full-fiscal-year`}
                                                >
                                                    {'Fiscal year to date only (through date above)'}
                                                </Label>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className={'extra-row-spacing-small'}>
                                        <Col className={'vcentered-content-col'}>
                                            <div className={'form-check form-check-no-padding'}>
                                                <Input
                                                    checked={!currentYearOnly}
                                                    disabled={!props.parameters}
                                                    type='radio'
                                                    name={`${idPrefix}-fullFiscalYearPlus`}
                                                    id={`${idPrefix}-id-full-fiscal-year-plus`}
                                                    onChange={() => {
                                                        setCurrentYearOnly(false);
                                                        onPeriodsToCollectChanged && onPeriodsToCollectChanged(fullFYearsToCollect);
                                                    }}
                                                />
                                                <Label
                                                    check
                                                    for={`${idPrefix}-id-full-fiscal-year-plus`}
                                                >
                                                    {'Fiscal year to date plus:'}
                                                </Label>
                                            </div>
                                            <Input
                                                type={'number'}
                                                disabled={!props.parameters || currentYearOnly}
                                                name={`${idPrefix}-countFullFiscalYears`}
                                                id={`${idPrefix}-count-full-fiscal-years`}
                                                value={fullFYearsToCollect}
                                                style={{
                                                    width: '4em',
                                                    marginLeft: '10px',
                                                    marginRight: '10px'
                                                }}
                                                onChange={(event) => {
                                                    const fullFYears = parseInt(event.currentTarget.value);
                                                    if (!isNaN(fullFYears)) {
                                                        if ((fullFYears > 0) && (fullFYears <= maxCollectionPeriods)) {
                                                            setFullFYearsToCollect(fullFYears);
                                                            onPeriodsToCollectChanged && onPeriodsToCollectChanged(fullFYears);
                                                        }
                                                    }
                                                }}
                                            />
                                            <Label
                                                check
                                            >
                                                {fullFYearsToCollect === 1 ? 'fiscal year' : 'fiscal years'}
                                            </Label>
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {(linkMode !== LinkMode.excel) && accountingPkgImportParameters()}
                {(linkMode === LinkMode.excel) && excelImportParameters()}
            </FormGroup>
        </>
    );
}
