import * as React from 'react';

import '../../Main.scss';
import './FinancialStatements.scss';

import {
    Col,
    Popover,
    PopoverBody,
    Row
} from 'reactstrap';

import { NameAndStyleEditor, DangerousInputItems, NameAndStyleEditorInputFields } from './NameAndStyleEditor';

import { FSModels } from '@finagraph/financial-statement-editor';

import { FinancialStatementRenderData } from './UtilTypes';

import {
    DangerousInputForm,
    DangerousInputFormChildProps
} from '../../DangerousInputForm';
import { SanitizeDOMSelector } from '../../../Utils/DOMUtils';

export enum CaptionTextEditOptionType {
    unknown,
    detail,
    header,
    footer
}

export type CaptionTextEditOption = {
    captionText: string;
    lineItemId: string;
    descriptor: string;
    type: CaptionTextEditOptionType;
}

type Props = {
    lineItem: FSModels.FinancialStatementLineItemViewModel;
    financialStatement: FSModels.FinancialStatementViewModel;
    formatting: FSModels.CaptionFormatting;
    uniqueId: string;
    target: string;
    onToggle: (active: boolean) => void;
    renderData: FinancialStatementRenderData;
    onUpdateCaptionText: (newHeader: string, newFooter: string | undefined) => void;
    onUpdateFormatting: (formatting: FSModels.CaptionFormatting, styleId: string | undefined) => void;
    onCancel: () => void;
}

export const NameAndStylePopover: React.FC<Props> = (props): React.ReactElement => {
    const {
        financialStatement,
        formatting,
        uniqueId,
        target,
        onToggle,
        lineItem,
        renderData,
        onUpdateCaptionText,
        onCancel
    } = props;

    const [editContent, setEditContent] = React.useState<NameAndStyleEditorInputFields>(() => {
        if (lineItem.lineItemType === FSModels.FinancialStatementLineItemType.SectionFooter) {
            return {
                headerText: lineItem.captionText,
                footerText: undefined
            }
        }

        let headerText: string = lineItem.captionText;
        let footerText: string | undefined = undefined;

        const mappedCaption = renderData.matchedCaptionLookup.get(lineItem.id);

        if (!!mappedCaption) {
            mappedCaption.forEach(mc => {
                if (mc.type === CaptionTextEditOptionType.header) {
                    headerText = mc.captionText;
                } else if (mc.type === CaptionTextEditOptionType.footer) {
                    footerText = mc.captionText;
                }
            })
        }

        return {
            headerText,
            footerText
        }
    })

    return (
        <Popover
            placement={'right-start'}
            hideArrow={true}
            isOpen={true}
            className={'financial-statement-popover'}
            target={target}
            key={'fs-line-item-info-popover'}
            toggle={() => onToggle(false)}
            trigger={'legacy'}
        >
            <PopoverBody className={'popover-body'}>
                <div className={`control-region control-region-lender`}>
                    <DangerousInputForm
                        onClose={(contentAccepted) => {
                            if (!contentAccepted) {
                                onCancel();
                            } else {
                                onUpdateCaptionText(editContent.headerText, editContent.footerText);
                                // TODO  -  update styling
                                onToggle(false);
                            }
                        }}
                        formData={editContent}
                        dangerousInputFields={DangerousInputItems}
                        hasCancel
                        controlButtonClassName={'regular-control'}
                    >
                        {(childProps: DangerousInputFormChildProps) => (
                            <Row className={'financial-statement-editor'}>
                                <Col>
                                    <div
                                        className={`name-and-style-editor name-and-style-editor-labels`}
                                        id={`name-style-editor-${uniqueId}`}
                                    >
                                        <NameAndStyleEditor
                                            lineItem={lineItem}
                                            formatting={formatting}
                                            uniqueId={SanitizeDOMSelector(lineItem.id)}
                                            formData={editContent}
                                            onCancel={onCancel}
                                            onContentChange={(newHeader, newFooter) => {
                                                setEditContent({
                                                    headerText: newHeader,
                                                    footerText: newFooter
                                                });
                                            }}
                                            rightAlign={false}
                                            renderData={renderData}
                                            financialStatement={financialStatement}
                                            dangerousInputFormProps={childProps}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </DangerousInputForm>
                </div>
            </PopoverBody>
        </Popover>
    )
}
