import * as React from 'react';
import { useCookies } from 'react-cookie';

import { AuthTimer } from './AuthTimer';

import { CookieSessionData } from '../Models/CookieSessionData';
import { LogException } from '../Utils/Logging';
import { auls_info_cookie_name } from '../Utils/Constants';

type Props = {
    mainDivKey: string;
    auth0Token: string | undefined;
    onTimeout: () => void;
    onSlidingTimeout: () => void;
}

export const AuthTimerController: React.FC<Props> = (props): React.ReactElement => {
    const {
        auth0Token,
        mainDivKey,
        onSlidingTimeout,
        onTimeout
    } = props;

    const [cookies] = useCookies([auls_info_cookie_name]);

    const [sessionExpirationTimes, setSessionExpirationTimes] = React.useState<{
        absolute: Date,
        absoluteRaw: string,
        sliding: Date,
        slidingRaw: string,
    } | undefined>(undefined);

    // Execution of sessionExpirationTimes is controlled by only running when the actual
    // value differs from what we have stored.  Otherwise, I do want it to run on every render.
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    React.useEffect(() => {
        try {
            const cookieSessionData = cookies[auls_info_cookie_name] as CookieSessionData;

            if (!!cookieSessionData) {
                if (!sessionExpirationTimes ||
                    (sessionExpirationTimes.slidingRaw !== cookieSessionData.slidingExpirationTime) ||
                    (sessionExpirationTimes.absoluteRaw !== cookieSessionData.absoluteExpirationTime)
                ) {
                    if (!(
                        cookieSessionData.absoluteExpirationTime && cookieSessionData.slidingExpirationTime
                    )) {
                        setSessionExpirationTimes(undefined);
                    } else {
                        setSessionExpirationTimes({
                            absolute: new Date(cookieSessionData.absoluteExpirationTime),
                            absoluteRaw: cookieSessionData.absoluteExpirationTime,
                            sliding: new Date(cookieSessionData.slidingExpirationTime),
                            slidingRaw: cookieSessionData.slidingExpirationTime,
                        });
                    }
                }
            } else {
                setSessionExpirationTimes(undefined);
            }
        } catch (exception) {
            LogException(
                'Failed retrieving session data from cookie',
                exception
            );
        }
    });

    return (
        <div key={mainDivKey}>
            <AuthTimer
                absoluteExpiration={sessionExpirationTimes && sessionExpirationTimes.absolute}
                slidingExpiration={sessionExpirationTimes && sessionExpirationTimes.sliding}
                token={auth0Token}
                onTimeout={onTimeout}
                onSlidingTimeout={onSlidingTimeout}
            />
        </div>
    );
}
