import * as React from 'react';

import {
    Col,
    Row
} from 'reactstrap';

import '../../Main.scss';

import {
    GlobalUserSettings,
    UserWorkspaceSettings
} from '../../../Store/UserSettings';
import { WorkspaceNotification } from './WorkspaceNotification';
import { WorkspaceOverrides } from './WorkspaceOverrides';

import { NotificationStyleDisabled } from '../../../Utils/Constants';

import { NotificationMethod, UserNotificationMethodSubscriptionStyle, UserNotificationSetting } from '../../../Models/Api/strongbox.financialportal';

type Props = {
    containerKey?: string;
    globalSettings: GlobalUserSettings | undefined;
    workspaceSettings: UserWorkspaceSettings | undefined;
    onNotifyTypeToggled: (notificationId: number, notificationType: NotificationMethod, checked: boolean) => void;
    onWorkspaceSettingToggled: (turnedOn: boolean, workspaceId: string, notificationId: number, notificationType: NotificationMethod) => void;
    onSetAllWorkspaces: (turnedOn: boolean, workspaceIds: string[], notificationId: number, notificationType: NotificationMethod) => void;
    busy: boolean;
}

export const NotificationSettings: React.FC<Props> = (props): React.ReactElement => {
    const {
        containerKey,
        globalSettings,
        onNotifyTypeToggled,
        onSetAllWorkspaces,
        onWorkspaceSettingToggled,
        workspaceSettings,
    } = props;

    const [disabledNotifications, setDisabledNotifications] = React.useState<{ setting: UserNotificationSetting, notify: UserNotificationMethodSubscriptionStyle }[]>([]);

    const [searchWord, setSearchWord] = React.useState<string>('');
    const [activeSearchWord, setActiveSearchWord] = React.useState<string>('');

    React.useEffect(() => {
        const newSettings: { setting: UserNotificationSetting, notify: UserNotificationMethodSubscriptionStyle }[] = [];

        if ((!!globalSettings) && (!!globalSettings.notificationData) && (!!globalSettings.notificationData.settings)) {
            globalSettings.notificationData.settings.forEach(setting => {
                if (!!setting.notificationMethods) {
                    setting.notificationMethods.forEach(notifyMethod => {
                        if (notifyMethod.style === NotificationStyleDisabled) {
                            newSettings.push({
                                setting,
                                notify: notifyMethod
                            });
                        }
                    })
                }
            });
        }

        setDisabledNotifications(newSettings);
    }, [globalSettings]);

    React.useEffect(() => {
        const debounceTimer = setTimeout(() => {
            setActiveSearchWord(searchWord);
        }, 1000);
        return () => {
            clearTimeout(debounceTimer);
        }

        // lint will complain about initialized not in dependency list, but based on how I use that, I do not want that.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchWord]);

    const searchOnChange = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        if (e.target.value !== '') {
            setSearchWord(e.target.value);
        } else {
            setSearchWord('');
        }
    }

    return (
        <React.Fragment>
            <Row
                key={containerKey}
                className={'bordered-region user-settings-container'}
            >
                <Col>
                    <Row
                        key={'new-workspaces-preferences'}
                    >
                        <Col>
                            <h1 className={'underlined-text'}>Default Settings For New Workspaces</h1>
                        </Col>
                    </Row>
                    <Row className={'extra-row-spacing-medium'} />
                    {
                        globalSettings && globalSettings.notificationData.settings &&
                        globalSettings.notificationData.settings.map((subSetting, index) => {
                            return (
                                <Row
                                    className={'settings-group'}
                                    key={`workspace-notification-container-${index}`}
                                >
                                    <Col>
                                        <WorkspaceNotification
                                            containerClass={index < globalSettings.notificationData.settings!.length - 1 ? 'bottom-bordered-region' : ''}
                                            notificationDescription={subSetting.description || ''}
                                            notificationTitle={subSetting.title || ''}
                                            containerKey={`workspace-notification-${index}`}
                                            notificationSettings={subSetting.notificationMethods || []}
                                            notificationTypes={globalSettings.notificationTypes}
                                            style={index > 0 ? { marginTop: '15px' } : undefined}
                                            onNotificationTypeChange={(notificationType: NotificationMethod, checked: boolean) => onNotifyTypeToggled(!!subSetting.id ? subSetting.id : -1, notificationType, checked)}
                                        />
                                    </Col>
                                </Row>
                            );
                        })
                    }
                </Col>
            </Row>

            <Row
                className={'bordered-region user-settings-container'}
                style={{ marginTop: '30px' }}
            >
                <Col>
                    <Row className={'spaced-row-centered basic-vertical-spacing-thin'}>
                        <Col xs={3}> 
                            <h1>Workspace Settings</h1>
                        </Col>
                        <Col xs={9}>
                            <input
                                type={'text'}
                                onChange={
                                    (event) => { event.persist(); searchOnChange(event) }}
                                placeholder={'Search by name...'}
                                inputMode={'search'}
                                style={{ width: '250px' }}
                                value={searchWord}
                                disabled={props.busy}
                            >
                            </input>
                        </Col>
                    </Row>
                    <Row className={'settings-group'}>
                        <Col>
                            <WorkspaceOverrides
                                globalUserSettings={globalSettings}
                                workspaceSettings={workspaceSettings}
                                onToggle={onWorkspaceSettingToggled}
                                onBulkToggle={onSetAllWorkspaces}
                                disabledNotifications={disabledNotifications}
                                filter={activeSearchWord.toLowerCase()}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </React.Fragment>
    )
}
