import { ApplicationState } from '../index';

import { WorkspaceWithMeta, WorkspacePageInfo, WorkspacePlusUser } from './Reducer';

/*
 * Returns true if workspaces are activebly being loaded
 */

export function LoadingWorkspaces(state: ApplicationState): boolean {
    if (!(state && state.workspaces)) {
        return false;
    }
    return state.workspaces.loading;
}

export function GetWorkspaceInfo(state: ApplicationState): { workspaces: WorkspaceWithMeta[], pageInfo: WorkspacePageInfo } | undefined {
    if (!(state && state.workspaces)) {
        return undefined;
    }
    return {
        workspaces: state.workspaces.workspaces,
        pageInfo: state.workspaces.pageInfo
    };
}

export function GetDetailedWorkspaces(state: ApplicationState): WorkspacePlusUser[] {
    if (!(state && state.workspaces && state.workspaces.details)) {
        return [];
    }

    return state.workspaces.details;
}

export function GetAllLoadedWorkspaces(state: ApplicationState): WorkspaceWithMeta[] | undefined {
    if (!(state && state.workspaces)) {
        return undefined;
    }
    return state.workspaces.workspaces.concat(state.workspaces.inactiveWorkspaces);
}
