import {
    AppThunkAction
} from '../index';

import {
    ISetActiveTenant,
    ISetResource,
    ISetBasicTenantSettings,
    ISetBrandConfig,
    TenantActions,
    TenantBasicSettings,
    TenantResourceList,
    KnownAction,
} from './Actions';
import {
    BrandConfig,
} from './Reducer';

import { KnownAction as uiKnownActions, actionCreators as uiStateActions } from '../UIState';

import { GetTenantEmailDomains, GetTenantUserMetadataSettings, GetTenantBannerMessages } from '../../Services/Tenant';
import { LogException } from '../../Utils/Logging';

import { BannerMessage, TenantUserMetadataSetting } from '../../Models/Api/strongbox.financialportal';

function SetResource(list: TenantResourceList): ISetResource {
    return {
        type: TenantActions.TenantSetResource,
        list,
    };
}

function SetActiveTenant(tenant: string): ISetActiveTenant {
    return {
        type: TenantActions.TenantSetActiveTenant,
        tenant,
    };
}

function SetBasicTenantSettings(settings: TenantBasicSettings): ISetBasicTenantSettings {
    return {
        type: TenantActions.TenantSetBasicSettings,
        settings,
    };
}

function SetBrandConfig(brandconfig: BrandConfig): ISetBrandConfig {
    return {
        type: TenantActions.TenantSetBrandConfig,
        brandconfig,
    };
}

const GetEmailDomains = (): AppThunkAction<string[], unknown, KnownAction | uiKnownActions> =>
    async (dispatch, getState): Promise<string[]> => {
        const workingName = `FetchingEmailDomains`;

        dispatch(uiStateActions.SetPortalWorking(workingName));

        try {
            dispatch({ type: TenantActions.TenantListEmailDomains })

            const domains = await GetTenantEmailDomains();

            dispatch({
                type: TenantActions.TenantListEmailDomainsComplete,
                domains,
            });

            return domains;
        } catch (e) {
            const errorMsg = 'Listing email domains action creator failed';

            console.error(errorMsg);
            console.error(e);

            LogException(errorMsg, e);

            dispatch({
                type: TenantActions.TenantListEmailDomainsComplete,
                domains: [],
            })

            return [];
        } finally {
            dispatch(uiStateActions.SetPortalIdle(workingName));
        }
    }


const GetUserMetadataSettings = (): AppThunkAction<TenantUserMetadataSetting[], unknown, KnownAction | uiKnownActions> =>
    async (dispatch, getState): Promise<TenantUserMetadataSetting[]> => {
        const workingName = `FetchingUserMetadataSettings`;

        dispatch(uiStateActions.SetPortalWorking(workingName));

        try {
            dispatch({ type: TenantActions.TenantListUserMetadataSettings })

            const metadataSettings = await GetTenantUserMetadataSettings();

            dispatch({
                type: TenantActions.TenantListUserMetadataSettingsComplete,
                metadataSettings,
            });

            return metadataSettings;
        } catch (e) {
            const errorMsg = 'Listing user metadata settings action creator failed';

            console.error(errorMsg);
            console.error(e);

            LogException(errorMsg, e);

            dispatch({
                type: TenantActions.TenantListUserMetadataSettingsComplete,
                metadataSettings: [],
            })

            return [];
        } finally {
            dispatch(uiStateActions.SetPortalIdle(workingName));
        }
    }

const FetchBannerMessages = (): AppThunkAction<BannerMessage[], unknown, KnownAction | uiKnownActions> =>
    async (dispatch, getState): Promise<BannerMessage[]> => {
        const workingName = `FetchingBannerMessages`;

        dispatch(uiStateActions.SetPortalWorking(workingName));

        try {
            dispatch({ type: TenantActions.TenantGetBannerMessages })

            const bannerMessages = await GetTenantBannerMessages();

            dispatch({
                type: TenantActions.TenantGetBannerMessagesComplete,
                messages: bannerMessages,
            });

            return bannerMessages;
        } catch (e) {
            const errorMsg = 'Retrieving portal banner messages action creator failed';

            console.error(errorMsg);
            console.error(e);

            LogException(errorMsg, e);

            dispatch({
                type: TenantActions.TenantGetBannerMessagesComplete,
                messages: [],
            })

            return [];
        } finally {
            dispatch(uiStateActions.SetPortalIdle(workingName));
        }
    }


export const TenantActionCreators = {
    FetchBannerMessages,
    GetEmailDomains,
    GetUserMetadataSettings,
    SetActiveTenant,
    SetResource,
    SetBasicTenantSettings,
    SetBrandConfig,
};
