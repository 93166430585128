import * as React from 'react';

import {
    Button,
    Col,
    Container,
    Row
} from 'reactstrap';

import { DatePicker } from '../DatePicker/DatePicker';

import {
    SubmissionHistoryRange
} from '../../Utils/SubmissionHistoryRange';

type Props = {
    searchButtonText: string;
    submissionHistoryRange: SubmissionHistoryRange;
    onNewRange: (newRange: SubmissionHistoryRange) => void;
    onExecute: (range: SubmissionHistoryRange) => void;
}

export const CustomRangeSelection: React.FC<Props> = (props): React.ReactElement => {
    const {
        onExecute,
        onNewRange,
        searchButtonText,
        submissionHistoryRange
    } = props;
    
    return (
        <Container fluid>
            <Row>
                <Col xs={4}>
                    <Row>
                        <Col>
                            <label htmlFor={'submissionDateStart'}>Requests starting:</label>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <DatePicker
                                id={'submissionDateStart'}
                                monthScrollBehavior={'hideSelection'}
                                selected={submissionHistoryRange.start}
                                start={submissionHistoryRange.start}
                                end={null}
                                onSetDate={(date: Date) => {
                                    onNewRange({
                                        ...submissionHistoryRange,
                                        start: date
                                    })
                                }}
                                selectsStart
                                maxDate={submissionHistoryRange.end || undefined}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col xs={4}>
                    <Row>
                        <Col>
                            <label htmlFor={'submissionDateEnd'}>through:</label>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <DatePicker
                                id={'submissionDateEnd'}
                                monthScrollBehavior={'hideSelection'}
                                selected={submissionHistoryRange.end}
                                start={null}
                                end={submissionHistoryRange.end}
                                onSetDate={(date: Date) => {
                                    onNewRange({
                                        ...submissionHistoryRange,
                                        end: date
                                    })
                                }}
                                selectsEnd
                                minDate={submissionHistoryRange.start || undefined}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col xs={4} className={'bottom-aligned-column'}>
                    <Button
                        className={'small'}
                        onClick={() => onExecute(submissionHistoryRange)}
                    >
                        {searchButtonText}
                    </Button>
                </Col>
            </Row>
        </Container>
    );
}
