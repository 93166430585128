import * as React from 'react';

import {
    Container,
    Row,
    Col
} from 'reactstrap';

import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material';

import { FSModels } from '@finagraph/financial-statement-editor';

type Props = {
    onUpdate: (newValue: FSModels.FinancialStatementType) => void;
    statementType: FSModels.FinancialStatementType;
}

export const FinancialStatementType: React.FC<Props> = (props): React.ReactElement => {
    const {
        onUpdate,
        statementType
    } = props;

    const labelId = 'financial-statement-type-select-label';

    return (
        <Container fluid>
            <Row>
                <Col>
                    <div className={'right-aligned-col'}>
                        <FormControl fullWidth>
                            <InputLabel id={labelId}>Statement Type</InputLabel>
                            <Select
                                labelId={labelId}
                                label={'Statement Type'}
                                value={statementType}
                                // @ts-ignore TS2345   this will have a correct value
                                onChange={e => onUpdate(e.target.value)}
                                MenuProps={{
                                    sx: {
                                        '& div': {
                                            borderRadius: 0
                                        }
                                    }
                                }}
                                sx={{ borderRadius: '0px' }}
                            >
                                <MenuItem value={FSModels.FinancialStatementType.BalanceSheet}>Balance Sheet</MenuItem>
                                <MenuItem value={FSModels.FinancialStatementType.IncomeStatement}>Income Statement</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
