import { ApplicationState } from '../index';

import {
    PortalDefaultBasisOfAccounting,
    PortalSubmissionRequestDefaultFullFiscalYears,
    PortalCreateSubmissionRequestOptions
} from '../../Models/PortalCreateSubmissionRequestOptions';
import { DefaultAccountingCollectionGroup } from '../../Models/AccountingCollectionGroup';

import { NewDataCollectionContentGrouping } from './Reducer';

import { LogMessage, SeverityLevel } from '../../Utils/Logging';

export function GetCreationOptions(state: ApplicationState): PortalCreateSubmissionRequestOptions {
    if (!!(state && state.newDataCollectionParameters && state.newDataCollectionParameters.creationOptions)) {
        return state.newDataCollectionParameters.creationOptions;
    }

    // This really is a stopgap.  There shouldn't be a scenario where we have to fall back to this.

    const msg = 'Unexpected error in NewDataCollectionParameters::GetCreationOptions, store value is undefined. Returning reasonable defaults';
    LogMessage(msg, SeverityLevel.Error);
    console.error(msg);

    const today = new Date();
    return {
        basisOfAccountingPreference: PortalDefaultBasisOfAccounting,
        fullFiscalYearsToCollect: PortalSubmissionRequestDefaultFullFiscalYears,
        anonymizeCustomersAndVendors: true,
        provideUserCopy: false,
        allowUserUpload: false,
        endOfImport: 'Today',
        accountingCollectionGroups: DefaultAccountingCollectionGroup(),
        mostRecentPeriodMonth: today.getMonth(),
        mostRecentPeriodYear: today.getFullYear(),
        mostRecentPeriodDay: today.getDate(),
        hasShareableLink: false,
    };
}

export function GetRequestedOptions(state: ApplicationState): PortalCreateSubmissionRequestOptions | undefined {
    return state && state.newDataCollectionParameters && state.newDataCollectionParameters.requestedOptions;
}

export function GetRequestedOptionsId(state: ApplicationState): number | undefined {
    return state && state.newDataCollectionParameters && state.newDataCollectionParameters.requestedOptionsId;
}

export function RetrievingShareableLinkOptions(state: ApplicationState): boolean {
    return state && state.newDataCollectionParameters && state.newDataCollectionParameters.retrieving;
}

export function RetrieveShareableLinkOptionsFailed(state: ApplicationState): boolean {
    return !!state && !!state.newDataCollectionParameters && !!state.newDataCollectionParameters.initializationException;
}

export function GetCollectionGroupings(state: ApplicationState): NewDataCollectionContentGrouping[] {
    return (!!state && !!state.newDataCollectionParameters && state.newDataCollectionParameters.newDataCollectionGroupings) || [];
}