import { ApplicationState } from '../index';

import {
    AdministrativeTenantNotificationSettings,
} from '../../Models/Api/strongbox.financialportal';

import {
    GlobalUserSettings,
    UserWorkspaceSettings
} from './Reducer';

export function GetUserSettingsGlobal(state: ApplicationState): GlobalUserSettings | undefined {
    if (!(state && state.userSettingsState)) {
        return undefined;
    }
    return state.userSettingsState.global;
}

export function GetUserWorkspaceSettings(state: ApplicationState, userId: string): UserWorkspaceSettings | undefined {
    if (!(state && state.userSettingsState)) {
        return undefined;
    }
    return state.userSettingsState.workspaceSettings.find(wsSettings => wsSettings.userId === userId);
}

export function GetAdminTenantSettings(state: ApplicationState): AdministrativeTenantNotificationSettings | undefined {
    return state && state.userSettingsState && state.userSettingsState.adminTenantSettings;
}