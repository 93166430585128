import * as React from 'react';
import { connect } from 'react-redux';

import {
    Col,
    Container,
    Row
} from 'reactstrap';

import '../Main.scss';

import { ApplicationState } from '../../Store';
import {
    AddAdminNotificationRecipient,
    BatchUpdateWorkspaceUserSetting,
    GetUserSettingsGlobal,
    GetUserWorkspaceSettings,
    GlobalUserSettings,
    LoadGlobalUserSettings,
    LoadWorkspaceUserSettings,
    LoadTenantAdminSettings,
    UpdateGlobalUserSetting,
    UserWorkspaceSettings,
    UpdateWorkspaceUserSetting,
    GetAdminTenantSettings,
    RemoveAdminNotificationRecipient,
    UpdateNotificationSettingAction,
} from '../../Store/UserSettings';

import { NotificationSettings } from './Notifications/Notifications';
import {
    NotificationAdminSettings,
    AddingNotificationAdminContent,
    NotificationUndoStack,
    NotificationRecipientUndo,
} from './Notifications/NotificationAdminSettings';
import { ConfirmModal, ConfirmModalType } from '../ConfirmModal/ConfirmModal';
import { LoggedInUserHasAccess } from '../../Store/User';
import { UndoStack, UndoOperationType, UndoOperation } from '../../Utils/UndoStack';

import {
    actionCreators as UIActionCreators,
    GetPortalBusyState
} from '../../Store/UIState';

import {
    AdministrativeNotificationSetting,
    AdministrativeTenantNotificationSettings,
    NotificationDefaultRecipientAction,
    NotificationMethod,
} from '../../Models/Api/strongbox.financialportal';

import { GetLoggedInUserExternalId } from '../../Store/User';

import { BreadCrumbs } from '../BreadCrumbs/BreadCrumbs';
import { breadCrumbContent, pathConstants } from '../../Utils/Constants';

import { BusyWindowWrapper } from '../LoadingMessageWithIndicator/BusyWindowWrapper';
import { ErrorBanner, ErrorState } from '../ErrorBanner/ErrorBanner';

import { LogException } from '../../Utils/Logging';

import { SettingsNav } from '../Settings/SettingsNav'
import { SettingsTab, SettingsTabId } from '../Settings/SettingsTypes';

type InjectedReduxState = {
    portalBusy: boolean;
    globalUserSettings: GlobalUserSettings | undefined;
    currentUserId: string;
    workspaceSettings: UserWorkspaceSettings | undefined;
    containerKey: string;
    canChangeAdminNotificationSettings: boolean;
    adminTenantSettings: AdministrativeTenantNotificationSettings | undefined;
};

type InjectedActionCreators = typeof UIActionCreators;

export enum UserSettingsSubPage {
    notifications,
    admin
}

type UserSettingsContainerProps = {
    subPage: UserSettingsSubPage;
}

type Props = UserSettingsContainerProps & InjectedActionCreators & InjectedReduxState;

type QueryApplyAll = {
    notificationId: number;
    notificationType: NotificationMethod;
    checked: boolean;
}

const UserSettingsContainerComponent: React.FC<Props> = (props): React.ReactElement => {
    const {
        adminTenantSettings,
        containerKey,
        currentUserId,
        globalUserSettings,
        portalBusy,
        SetPortalIdle,
        SetPortalWorking,
        subPage,
        workspaceSettings,
        canChangeAdminNotificationSettings,
    } = props;

    const [errorState, setErrorState] = React.useState<ErrorState | undefined>(undefined);
    const [queryApplyAll, setQueryApplyAll] = React.useState<QueryApplyAll | undefined>(undefined);

    const [addingNewContent, setAddingNewContent] = React.useState<AddingNotificationAdminContent | undefined>(undefined);

    const [undoStacks, setUndoStacks] = React.useState<NotificationUndoStack[]>([]);

    const notificationSettingsTabs = React.useMemo(() => {
        const result: SettingsTab[] = [
            {
                tabLabel: 'My Notification Settings',
                tabId: SettingsTabId.notificationSettings,
                path: pathConstants.settingsNotifications,
            }
        ]

        if (canChangeAdminNotificationSettings) {
            result.push({
                tabLabel: 'Administrative Settings',
                tabId: SettingsTabId.notificationAdminSettings,
                path: pathConstants.settingsAdminNotifications,
            });
        }

        return result;
    }, [canChangeAdminNotificationSettings])

    React.useEffect(() => {
        const InitialLoadError = (errMsg: string) => {
            setErrorState({
                summaryMessage: errMsg,
                severity: 'Error',
            });
        }

        SetPortalWorking('UserSettings:LoadGlobalSettings');
        SetPortalWorking('UserSettings:LoadUserWorkspaceSettings');

        LoadGlobalUserSettings(InitialLoadError)
            .then(settings => {
                SetPortalIdle('UserSettings:LoadGlobalSettings');
            });

        LoadWorkspaceUserSettings(currentUserId, InitialLoadError)
            .then(workspaceSettings => {
                SetPortalIdle('UserSettings:LoadUserWorkspaceSettings');
            });

        if (canChangeAdminNotificationSettings) {
            SetPortalWorking('UserSettings:LoadAdminTenantSettings');
            LoadTenantAdminSettings(InitialLoadError)
                .then(settings => {
                    if (!!settings && !!settings.settings) {
                        const undoStacks: NotificationUndoStack[] = [];

                        settings.settings.forEach(s => {
                            if ((s.id !== undefined) && (s.type !== undefined)) {
                                undoStacks.push({
                                    notificationId: s.id,
                                    notificationMethod: s.type,
                                    undoStack: new UndoStack<NotificationRecipientUndo>(),
                                });
                            }
                        })
                        setUndoStacks(undoStacks);
                    }
                    SetPortalIdle('UserSettings:LoadAdminTenantSettings');
                });
        }

        // I want this to execute equivalent to componentDidMount so this is appropriate
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const notifyTypeToggled = (notificationId: number, type: NotificationMethod, checked: boolean): void => {
        SetPortalWorking('UserSettings:UpdatingSetting');
        UpdateGlobalUserSetting(
            notificationId,
            type,
            checked,
            (msg) => {
                setErrorState({
                    summaryMessage: msg,
                    severity: 'Error',
                });
            }
        )
            .then((success) => {
                if (success) {
                    setQueryApplyAll({
                        notificationId,
                        notificationType: type,
                        checked
                    });
                }
            })
            .catch((exception) => { })
            .finally(() => {
                SetPortalIdle('UserSettings:UpdatingSetting');
            });
    }

    const workspaceSettingToggled = (turnedOn: boolean, workspaceId: string, notificationId: number, notificationType: NotificationMethod): void => {
        SetPortalWorking('UserSettings:UpdateWorkspaceSetting');
        UpdateWorkspaceUserSetting(
            currentUserId,
            turnedOn,
            workspaceId,
            notificationId,
            notificationType,
            (msg) => {
                setErrorState({
                    summaryMessage: msg,
                    severity: 'Error',
                });
            }
        )
            .catch((exception) => { })
            .finally(() => {
                SetPortalIdle('UserSettings:UpdateWorkspaceSetting');
            });
    }

    const applyNotificationToAll = (queryApplyAll: QueryApplyAll): void => {
        // Sanity check
        if (!workspaceSettings) {
            return;
        }
        const workspaceIds = workspaceSettings.settings.map(ws => ws.workspaceId);
        SetPortalWorking('UserSettings:UpdateAllWorkspaceSettings');

        BatchUpdateWorkspaceUserSetting(
            currentUserId,
            queryApplyAll.checked,
            workspaceIds,
            queryApplyAll.notificationId,
            queryApplyAll.notificationType,
            (msg) => {
                setErrorState({
                    summaryMessage: msg,
                    severity: 'Error',
                });
            }
        )
            .catch((exception) => {
                console.error('Failed updating workspace notification settings');
                console.error(exception);

                LogException(
                    `Batch update of workspace notification settings failed`,
                    exception
                );
            })
            .finally(() => {
                SetPortalIdle('UserSettings:UpdateAllWorkspaceSettings');
            });
    }

    const bulkUpdateWorkspaces = (turnedOn: boolean, workspaceIds: string[], notificationId: number, notificationType: NotificationMethod): void => {
        SetPortalWorking('UserSettings:BulkUpdateWorskpaces');

        BatchUpdateWorkspaceUserSetting(
            currentUserId,
            turnedOn,
            workspaceIds,
            notificationId,
            notificationType,
            (msg) => {
                setErrorState({
                    summaryMessage: msg,
                    severity: 'Error',
                });
            }
        )
            .catch((exception) => {
                console.error('Failed updating workspace notification settings');
                console.error(exception);

                LogException(
                    `Bulk update of workspace notification settings failed`,
                    exception
                );
            })
            .finally(() => {
                SetPortalIdle('UserSettings:BulkUpdateWorskpaces');
            });
    }

    const getPreOpState = (notificationId: number, notificationMethod: NotificationMethod): AdministrativeNotificationSetting | undefined => {
        if (!(adminTenantSettings?.settings)) {
            // Sanity check, really shouldn't be possible.
            console.error('Unexpected error state, adminSettings is undefined in UserSetting:getPreOpState');
            return undefined;
        }

        const setting = adminTenantSettings.settings.find(s => s.id === notificationId && s.type === notificationMethod);
        if (!setting) {
            // Sanity check, really shouldn't be possible.
            console.error('Unexpected error state, setting not found in UserSetting:getPreOpState');
            return undefined;
        }
        return {
            ...setting
        }
    }

    const addToUndoStack = (operation: UndoOperationType, recipient: string, setting: AdministrativeNotificationSetting, notificationId: number, notificationMethod: NotificationMethod): void => {
        const stack = undoStacks.find(s => (s.notificationId === notificationId) && (s.notificationMethod === notificationMethod));
        if (!stack) {
            return;
        }
        stack.undoStack.push(operation, {
            recipient,
            preopState: setting,
        });
    }

    const addAdminGlobalSettings = (newContent: string, notificationId: number, notificationMethod: NotificationMethod, suppressUndo?: boolean): void => {
        const preOpState = getPreOpState(notificationId, notificationMethod);
        if (!preOpState) {
            return;
        }

        SetPortalWorking('UserSettings:UpdateAdminNotificationRecipients');
        AddAdminNotificationRecipient(
            newContent,
            notificationId,
            notificationMethod,
            (errorMsg) => {
                setErrorState({
                    summaryMessage: errorMsg,
                    severity: 'Error',
                });
            }
        )
            .then((result) => {
                if ((!!result) && (!suppressUndo)) {
                    addToUndoStack('add', newContent, preOpState, notificationId, notificationMethod)
                }
            })
            .finally(() => {
                SetPortalIdle('UserSettings:UpdateAdminNotificationRecipients');
            });
    }

    const changeNotificationSettingAction = (newAction: NotificationDefaultRecipientAction, notificationId: number, notificationMethod: NotificationMethod): void => {
        SetPortalWorking('UserSettings:ChangeNotificationSettingAction');
        UpdateNotificationSettingAction(
            newAction,
            notificationId,
            notificationMethod,
            (errorMsg) => {
                setErrorState({
                    summaryMessage: errorMsg,
                    severity: 'Error',
                });
            }
        )
            .finally(() => {
                SetPortalIdle('UserSettings:ChangeNotificationSettingAction');

                const loadError = (errMsg: string) => {
                    setErrorState({
                        summaryMessage: errMsg,
                        severity: 'Error',
                    });
                }

                SetPortalWorking('UserSettings:LoadGlobalSettings');
                SetPortalWorking('UserSettings:LoadUserWorkspaceSettings');

                LoadGlobalUserSettings(loadError)
                    .finally(() => {
                        SetPortalIdle('UserSettings:LoadGlobalSettings');
                    });

                LoadWorkspaceUserSettings(currentUserId, loadError)
                    .finally(() => {
                        SetPortalIdle('UserSettings:LoadUserWorkspaceSettings');
                    });
            });
    }

    const removeAdminGlobalSettings = (recipient: string, notificationId: number, notificationMethod: NotificationMethod, suppressUndo?: boolean): void => {
        const preOpState = getPreOpState(notificationId, notificationMethod);
        if (!preOpState) {
            return;
        }

        SetPortalWorking('UserSettings:RemoveAdminNotificationRecipients');
        RemoveAdminNotificationRecipient(
            recipient,
            { ...preOpState },
            (errorMsg) => {
                setErrorState({
                    summaryMessage: errorMsg,
                    severity: 'Error',
                });
            }
        )
            .then((result) => {
                if ((!!result) && (!suppressUndo)) {
                    addToUndoStack(
                        'remove',
                        recipient,
                        preOpState,
                        notificationId,
                        notificationMethod
                    )
                }
            })
            .finally(() => {
                SetPortalIdle('UserSettings:RemoveAdminNotificationRecipients');
            });
    }

    const handleUndo = (notificationId: number, notificationMethod: NotificationMethod, op: UndoOperation<NotificationRecipientUndo>): void => {
        if (op.operation === 'remove') {
            addAdminGlobalSettings(op.opData.recipient, notificationId, notificationMethod, true);
        } else if (op.operation === 'add') {
            removeAdminGlobalSettings(op.opData.recipient, notificationId, notificationMethod, true);
        }
    }

    return (
        <div
            style={{
                position: 'absolute',
                height: '90%',
                width: '100%',
                minWidth: '20px'
            }}
            className={`free-content-region`}
        >
            <div
                style={{
                    minWidth: '20px'
                }}
                className={`contained-content control-region control-region-lender`}
            >
                <Container className={'portal-container-settings'} fluid>
                    <Row style={{ marginBottom: '1em' }} >
                        <Col>
                            <BreadCrumbs
                                crumbs={[
                                    {
                                        content: breadCrumbContent.workspaceList,
                                        link: pathConstants.home,
                                    }
                                ]}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <SettingsNav
                                tabs={notificationSettingsTabs}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ErrorBanner
                                errorState={errorState}
                                onDefaultActionButton={() => {
                                    setErrorState(undefined);
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <BusyWindowWrapper
                                portalBusy={portalBusy}
                            >
                                {
                                    !!globalUserSettings && (subPage === UserSettingsSubPage.notifications) && (
                                        <NotificationSettings
                                            containerKey={containerKey}
                                            globalSettings={globalUserSettings}
                                            workspaceSettings={workspaceSettings}
                                            onNotifyTypeToggled={notifyTypeToggled}
                                            onWorkspaceSettingToggled={workspaceSettingToggled}
                                            onSetAllWorkspaces={bulkUpdateWorkspaces}
                                            busy={portalBusy}
                                        />
                                    )
                                }
                                {
                                    !!globalUserSettings && (subPage === UserSettingsSubPage.admin) && (
                                        <NotificationAdminSettings
                                            notificationSettings={globalUserSettings?.notificationData}
                                            containerKey={containerKey}
                                            adminTenantSettings={adminTenantSettings}
                                            busy={portalBusy}
                                            undoStacks={undoStacks}
                                            addNewItem={(notificationId: number) => {
                                                setAddingNewContent({
                                                    containerId: notificationId,
                                                    content: '',
                                                })
                                            }}
                                            updateNewContent={(content) => {
                                                if (!!addingNewContent) {
                                                    setAddingNewContent({
                                                        ...addingNewContent,
                                                        content
                                                    });
                                                }
                                            }}
                                            addNewContent={(notificationMethod: NotificationMethod) => {
                                                if (!!addingNewContent) {
                                                    addAdminGlobalSettings(addingNewContent.content, addingNewContent.containerId, notificationMethod)
                                                }
                                                setAddingNewContent(undefined);
                                            }}
                                            removeContent={(recipient, notificationId, notificationMethod) => {
                                                removeAdminGlobalSettings(recipient, notificationId, notificationMethod)
                                            }}
                                            cancelNewContent={() => setAddingNewContent(undefined)}
                                            addingContent={addingNewContent}
                                            undo={handleUndo}
                                            onChangeAction={changeNotificationSettingAction}
                                        />
                                    )
                                }
                            </BusyWindowWrapper>
                        </Col>
                    </Row>
                </Container>
            </div>
            {
                queryApplyAll && (
                    <ConfirmModal
                        msg={'Do you want to apply this setting to all workspaces to which you have access?'}
                        title={'Apply to all workspaces'}
                        modalType={ConfirmModalType.yesno}
                        onTerminalButton={(applyToAll?: boolean) => {
                            if (!!applyToAll) {
                                applyNotificationToAll(queryApplyAll);
                            }
                            setQueryApplyAll(undefined);
                        }}
                    />
                )
            }
        </div>
    );
}

export const UserSettingsContainer = connect<InjectedReduxState, InjectedActionCreators, UserSettingsContainerProps, ApplicationState>(
    (appState: ApplicationState) => {
        const loggedInUserId = GetLoggedInUserExternalId(appState);

        const result = {
            portalBusy: GetPortalBusyState(appState),
            globalUserSettings: GetUserSettingsGlobal(appState),
            currentUserId: loggedInUserId,
            workspaceSettings: GetUserWorkspaceSettings(appState, loggedInUserId),
            containerKey: Math.floor(Math.random() * 100000).toString(),
            canChangeAdminNotificationSettings: LoggedInUserHasAccess(appState, 'CanManageTenantAdministrativeNotificationSettings'),
            adminTenantSettings: GetAdminTenantSettings(appState),
        };

        return result;
    },
    {
        ...UIActionCreators,
    }
)(UserSettingsContainerComponent);

