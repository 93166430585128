import * as React from 'react';
import { PropsWithChildren } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    GetBrandConfig,
    GetBasicSettings,
    TenantActionCreators,
} from '../Store/Tenant';
import {
    tenantResourceLists
} from '../Utils/BrandUtils';

import { CreateStdTheme } from '../Utils/Style';
import { Theme, ThemeProvider } from '@mui/material/styles';

import { GetTenantName } from '../Store/Tenant';

import { ApplicationState } from '../Store';

import { assetBlobContainer } from '../Utils/Constants';

type InjectedReduxState = {
    favicon: string;
    faviconTitle: string;
    activeTheme: Theme,
};

type InjectedActionCreators = typeof TenantActionCreators;

type PortalHeaderProps = {
};

type Props = InjectedReduxState & InjectedActionCreators & PortalHeaderProps;

const WrapperComponent: React.FC<Props & PropsWithChildren> = (props): React.ReactElement => {
    const {
        favicon,
        faviconTitle,
        SetResource
    } = props;

    React.useEffect(() => {
        tenantResourceLists.forEach(tenantResource => {
            SetResource(tenantResource);
        })

        let link = document.querySelector('link[rel="shortcut icon"]') ||
            document.querySelector('link[rel="icon"]');

        if (link) {
            const attr = document.createAttribute("href");
            attr.value = favicon;
            link.attributes.setNamedItem(attr);
        }
    }, [favicon, SetResource]);

    React.useEffect(() => {
            let titleComponent = document.querySelector('title');
            if (titleComponent) {
                titleComponent.innerText = faviconTitle;
                titleComponent.innerHTML = faviconTitle;
            }
        },
        [faviconTitle]
    );

    return (
        <div>
            <ThemeProvider theme={props.activeTheme} >
                {props.children}
            </ThemeProvider>
        </div>
    );
}

export const Wrapper = connect<InjectedReduxState, InjectedActionCreators, PortalHeaderProps, ApplicationState>(
    (appState: ApplicationState,) => {
        const basicSettings = GetBasicSettings(appState);
        const tenant = GetTenantName(appState);
        const brandConfig = GetBrandConfig(appState);

        return {
            favicon: `${assetBlobContainer}/brandassets/${tenant}/favicon-32x32.png`,
            faviconTitle: (basicSettings && basicSettings.favIconTitle) || '',
            activeTheme: CreateStdTheme(brandConfig),
        };
    },
    dispatch => bindActionCreators(
        {
            ...TenantActionCreators,
        },
        dispatch
    )
)(WrapperComponent);
