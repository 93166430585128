import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
    Col,
    Container,
    Row,
} from 'reactstrap';

import { ApplicationState } from '../../../Store';
import {
    actionCreators as FinancialStatementActions,
    FinancialStatementWorkbookRegenerationTracking,
    GetNumberFormattingOptions,
    GetFinancialStatementType,
    NumberFormattingOption
} from '../../../Store/FinancialStatements';
import { GetMinMappingDate } from '../../../Store/GlobalSettings';

import { FormatDate, formatStringMDYFullMonth } from '../../../Utils/DateUtils';

import { EditTemplateParameters } from './EditTemplateParameters';

import { FinancialStatementView } from './FinancialStatementView';

import { FSModels } from '@finagraph/financial-statement-editor';

import { ErrorBanner, ErrorState } from '../../ErrorBanner/ErrorBanner';

// 2 minutes
// const autoSaveTimeout = 120000;

type InjectedReduxState = {
    numberFormattingOptions: NumberFormattingOption[];
    financialStatementType: FSModels.FinancialStatementType;
    minMappingDate: Date;
}

type InjectedActionCreators = typeof FinancialStatementActions;

type FinancialStatementEditorProps = {
    financialRecordId: string;
    orgId: string;
    revisionTrackingDismissed: boolean;
    setRevisionTrackingDismissed: (dismissed: boolean) => void;
    revisionTracking: FinancialStatementWorkbookRegenerationTracking[];
    submissionSupportsFinancialStatements: boolean;
    onNavToLatestRevision: () => void;
}

type Props = FinancialStatementEditorProps & InjectedActionCreators & InjectedReduxState;

export const FinancialStatementEditorComponent: React.FC<Props> = (props): React.ReactElement => {
    const {
        financialRecordId,
        financialStatementType,
        orgId,
        numberFormattingOptions,
        InitializeFinancialStatement,
        SaveFinancialStatements,
        SaveFinancialStatementsAsTemplate,
        RegenerateWorkbooks,
        ApplyTemplateToFinancialStatements,
        revisionTracking,
        revisionTrackingDismissed,
        setRevisionTrackingDismissed,
        submissionSupportsFinancialStatements,
        minMappingDate,
        onNavToLatestRevision,
    } = props;

    const [stylesBelowEdit, setStylesBelowEdit] = React.useState<boolean>(true);

    // Just cleaning up this warning, setAutoSaving is used in some commented out code so will
    // come back when auto save is added back in.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [autoSaving, setAutoSaving] = React.useState<boolean>(false);

    const [userMsg, setUserMsg] = React.useState<ErrorState | undefined>(undefined);

    React.useEffect(() => {
        InitializeFinancialStatement(orgId, financialRecordId, financialStatementType);

        // I want this to execute equivalent to componentDidMount so this is appropriate
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const executeSave = async (): Promise<boolean> => {
        let result = true;

        await SaveFinancialStatements(() => result = false);
        return result;
    }

    const saveFinancialStatement = (onComplete: () => void): void => {
        executeSave().then((success) => {
            if (success) {
                onComplete()
            }
        })
    }

    const generateWorkbooks = async (): Promise<void> => {
        await SaveFinancialStatements();
        await RegenerateWorkbooks();
        setRevisionTrackingDismissed(false);

        // Regardless of the outcome of RegenerateWorkbooks, state will change to either an error msg
        // being available or the list of workbook statuses to track and that will produce either an
        // error msg or visibility of the workbook status tracking window.
    }

    const saveAsTemplate = async (parameters: EditTemplateParameters): Promise<void> => {
        await SaveFinancialStatements();
        await SaveFinancialStatementsAsTemplate(parameters.displayName, parameters.description);
    }

    /*
    const saveChangesOnTimeout = async (): Promise<void> => {
        setAutoSaving(true);
        setTimeout(() => { setAutoSaving(false) }, 1500);
    }
    */

    const applyTemplate = async (templateId: string): Promise<void> => {
        await ApplyTemplateToFinancialStatements(templateId);
    }

    /*
    React.useEffect(() => {
        if (dataChanged) {
            console.log('setting auto save timer');

            const saveTimerId = setTimeout(saveChangesOnTimeout, autoSaveTimeout);
            return () => {
                console.log('clearing existing timer');
                setDataChanged(false);
                clearTimeout(saveTimerId);
            }
        }
    }, [dataChanged])
    */

    if (!submissionSupportsFinancialStatements) {
        return (
            <React.Fragment>
                <Container
                    fluid
                    className={'financial-statement-editor'}
                    id={'financial-statement-editor-submission-not-supported'}
                >
                    <Row>
                        <Col className={'error-state-col'}>
                            <Row>
                                <Col>
                                    <ErrorBanner
                                        errorState={{
                                            severity: 'Error',
                                            summaryMessage: 'Financial statement editing is not supported for this submission.',
                                            extraInformation: `Editing financial statements for submissions collected prior to ${FormatDate(minMappingDate, formatStringMDYFullMonth)} is not supported. You will need to run a new data collection for this company to use financial statement editing.`,
                                        }}
                                        onDefaultActionButton={() => { }}
                                        noDismiss={true}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        );
    } else {
        return (
            <FinancialStatementView
                financialRecordId={financialRecordId}
                financialStatementType={financialStatementType}
                onUpdateFinancialStatementType={(newType) => {
                    InitializeFinancialStatement(orgId, financialRecordId, newType);
                }}
                orgId={orgId}
                stylesBelowEdit={stylesBelowEdit}
                onStylesBelowEditUpdate={setStylesBelowEdit}
                isDataModified={false}
                numberFormattingOptions={numberFormattingOptions}
                onGenerateWorkbooks={generateWorkbooks}
                onSaveFinancialStatement={saveFinancialStatement}
                onSaveAsTemplate={saveAsTemplate}
                autoSaving={autoSaving}
                userMsg={
                    !!userMsg ? { msg: userMsg, clear: () => setUserMsg(undefined) } : undefined
                }
                onApplyTemplate={applyTemplate}
                clearRegenerationStatusTracking={() => setRevisionTrackingDismissed(true)}
                showRegenerationStatusTracking={!revisionTrackingDismissed && (revisionTracking.length > 0)}
                onNavToLatestRevision={onNavToLatestRevision}
            />
        )
    }
}

export const FinancialStatementEditor = connect<InjectedReduxState, InjectedActionCreators, FinancialStatementEditorProps, ApplicationState>(
    (appState: ApplicationState) => {
        return {
            numberFormattingOptions: GetNumberFormattingOptions(appState),
            financialStatementType: GetFinancialStatementType(appState),
            minMappingDate: GetMinMappingDate(appState) || new Date(),
        }
    },
    dispatch => bindActionCreators(
        {
            ...FinancialStatementActions,
        },
        dispatch
    )
)(FinancialStatementEditorComponent);

