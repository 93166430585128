import * as React from 'react';
import { connect } from 'react-redux';

import { Button } from 'reactstrap';

import {
    Tooltip
} from '@mui/material';

import { AccountingPackageFromDatasourceNameId, AccountingPackage } from '../../Models/Api/AccountingPackages';
import { ConnectionDescriptor  } from '../../Models/Api/strongbox.financialportal';

import {
    actionCreators as ImportFinancialsActions,
    ConnectionOptions,
    GetConnectionOptions
} from '../../Store/ImportFinancials';
import {
    DirectLinkingDisabled,
    GetDefaultSubmissionOptions,
    GetStrongboxUrl,
    GetShareableLinkParamsFlowSwitch
} from '../../Store/AppSettings';
import { ApplicationState } from '../../Store';

import { ErrorState } from '../ErrorBanner/ErrorBanner';

import {
    CreateSubmissionForWorkspaceId,
    OnlineLinkFunc
} from './Common';

import { PortalCreateSubmissionRequestOptions } from '../../Models/PortalCreateSubmissionRequestOptions';
import { FileUploadDataSourceNameId } from '../../Utils/Constants';

import NoAccountingSystemIcon from '@mui/icons-material/BlockOutlined';

export type LinkButtonType = 'large-list-icon' | 'small-button';

type InjectedReduxState = {
    linkParamsActive: boolean;
    strongboxUrl: string;
    connectionOptions?: ConnectionOptions;
    directLinkingDisabled: boolean;
    defaultPortalLinkOptions: PortalCreateSubmissionRequestOptions;
};

type InjectedActionCreators = typeof ImportFinancialsActions;

/**
 * @param buttonType defaults to 'large-list-icon' if undefined
 * */

export type FinancialsRelinkButtonProps = {
    connection?: ConnectionDescriptor;
    datasourceNameId?: string;
    disabled: boolean;
    onSetErrorState: (errorState: ErrorState) => void;
    onSetImporting: (working: boolean) => void;
    workspaceId: string;
    workspaceDisplayName: string;
    submissionId?: string;
    buttonType?: LinkButtonType;
    onMouseEnterSmallButton?: (text: string, iconId: string) => void;
    onMouseLeaveSmallButton?: () => void;
    buttonContentOverride?: {
        buttonContent: React.ReactElement;
        description: string;
        buttonStyle?: any;
    }
}

type Props = FinancialsRelinkButtonProps & InjectedActionCreators & InjectedReduxState;

const FinancialsRelinkButtonComponent: React.FC<Props> = (props): React.ReactElement => {
    const {
        buttonContentOverride,
        buttonType,
        connection,
        datasourceNameId,
        disabled,
        connectionOptions,
        linkParamsActive,
        onSetErrorState,
        onSetImporting,
        strongboxUrl,
        submissionId,
        workspaceId,
        workspaceDisplayName,
        onMouseEnterSmallButton,
        onMouseLeaveSmallButton,
        SetImportCustomerFinancialsActive,
        directLinkingDisabled,
        defaultPortalLinkOptions,
    } = props;

    let linkButtonStyle: any = {
        marginRight: '10px',
    };
    if (buttonType === 'small-button') {
        linkButtonStyle = {
            marginLeft: '10px',
        }
    }

    // Depending on the appsettings switch that defines whether the tenant is able to set linking parameters,
    // either retrieve parameters for linking or just start the link.
    //
    // Most of the time we will retrieve parameters and then invoke the process for linking but if the switch
    // in appsettings ("FeatureEnablement" | "ShareableLinkOptionsFlow") is set false then just go straight to 
    // linking as described above.

    const getSavedLinkParametersOrLink = (pkg: AccountingPackage, workspaceId: string, workspaceDisplayName: string): void => {
        const workspaceName = workspaceDisplayName || workspaceId;

        // linkParamsActive is probably going to be true.  This is a setting that comes from 
        // appsettings and it means the user is able to control the parameters for a shareable or direct
        // link.   It can be set false in which case when we get here we should just go straight to linking
        // with the accounting system.

        if (linkParamsActive) {
            // when importingDirect is set (the 3rd parameter), the user can only set direct mode parameters.
            SetImportCustomerFinancialsActive(
                true,
                { workspaceId, workspaceName },
                {
                    accountingPkg: pkg,
                    connectionId: connection?.id,
                }
            );
        } else {
            // Initialize options to value stored in lenderLinkProps or appropriate 
            // fixed defaults.
            const options =
                (connectionOptions && connectionOptions.options) ||
                defaultPortalLinkOptions;

            // We used to check to ensure that the disableFileUpload flag was not enabled, however,
            // it is conceivable and configuration supports that the tenant could choose to disable
            // the ability to CHANGE the allow additional file upload setting while also setting the
            // default value to true.   
            //
            // So the ability to see and change the setting is untethered from what the setting 
            // actually is.

            CreateSubmissionForWorkspaceId(
                workspaceId,
                options,
                onSetErrorState
            )
                .then(submissionId => {
                    OnlineLinkFunc(
                        pkg,
                        workspaceId,
                        options,
                        submissionId,
                        strongboxUrl,
                        onSetErrorState,
                        onSetImporting,
                        (ci => { })
                    );
                })
                .catch(reason => { })
        }
    }

    const getTitleForButton = (datasourceNameId: string, defaultValue?: string): string => {
        if (datasourceNameId === 'quickbooksonline') {
            return 'Sync financial data from QuickBooks';
        } else if (datasourceNameId === 'xero') {
            return 'Sync financial data from Xero';
        } else if (datasourceNameId === 'quickbooksdesktop') {
            return 'QuickBooks Desktop. Sync not available';
        } else if (datasourceNameId === 'sageintacct') {
            return 'Sync financial data from Sage Intacct';
        } else if (datasourceNameId === 'example') {
            return 'Sync financial data from DEX';
        } else if (datasourceNameId === 'freeagent') {
            return 'Sync financial data from FreeAgent';
        } else if (datasourceNameId === 'netsuite') {
            return 'Sync financial data from NetSuite';
        } else if (datasourceNameId === 'myobbusiness') {
            return 'Sync financial data from MYOB Business';
        } else if (datasourceNameId === 'dynamics365') {
            return 'Sync financial data from Microsoft Dynamics 365';
        } else {
            return defaultValue || 'An accounting system is not connected for this workspace.';
        }
    }

    const tooltipTitle = (disabled: boolean): string => {
        return disabled ? '' : !!datasourceNameId ? getTitleForButton(datasourceNameId, 'Sync financial data') : 'Sync financial data';
    }

    const getLargeListButton = (
        accountingPackage: AccountingPackage,
        classPrefix: string,
        disabled: boolean,
        title: string,
    ): React.ReactElement => {
        const keySuffix = submissionId || workspaceId;

        return (
            <Tooltip title={tooltipTitle(disabled)}>
                <button
                    key={`${classPrefix}-${workspaceId}${keySuffix}`}
                    className={`${classPrefix}${classSuffix}`}
                    disabled={disabled}
                    style={linkButtonStyle}
                    onClick={() => {
                        getSavedLinkParametersOrLink(accountingPackage, workspaceId, workspaceDisplayName);
                    }}
                />
            </Tooltip>
        );
    }

    const getSmallListButton = (
        accountingPackage: AccountingPackage,
        idPrefix: string,
        imgSrc: string,
        text: string,
        disabled?: boolean,
    ): React.ReactElement => {
        const id = `${idPrefix}${workspaceId}${keySuffix}`;
        return (
            <Button
                key={id}
                className={`submissions-button-icon small-accounting-button`}
                id={id}
                disabled={disabled}
                onMouseEnter={() => { onMouseEnterSmallButton && onMouseEnterSmallButton(text, id) }}
                onMouseLeave={() => { onMouseLeaveSmallButton && onMouseLeaveSmallButton() }}
                style={linkButtonStyle}
                onClick={() => {
                    getSavedLinkParametersOrLink(accountingPackage, workspaceId, workspaceDisplayName);
                }}
            >
                <Tooltip title={tooltipTitle(!!disabled)}>
                    <img
                        className={`${classSuffix}`}
                        src={require(`../../images/${imgSrc}`)}
                        alt={text}
                    />
                </Tooltip>
            </Button>
        );
    }

    // Now begin deciding what visual element we shoud return but first
    // check to see if direct linking is disabled and return nothing if it is

    if (directLinkingDisabled) {
        return (<></>);
    }

    let classSuffix = '';
    if (buttonType === 'small-button') {
        classSuffix = ' small-accounting-icon';
    }

    const keySuffix = submissionId || workspaceId;
    let accountingPkg: AccountingPackage | undefined = undefined;
    if (!!datasourceNameId) {
        accountingPkg = AccountingPackageFromDatasourceNameId(datasourceNameId);
    }

    if (datasourceNameId === FileUploadDataSourceNameId) {
        return (<></>);
    }

    if ((!!buttonContentOverride) && (!!accountingPkg)) {
        const id = `linkfinancialsbutton${workspaceId}${keySuffix}`;
        return (
            <Button
                className={'simple-icon-button'}
                id={id}
                key={id}
                disabled={disabled}
                onMouseEnter={() => { onMouseEnterSmallButton && onMouseEnterSmallButton(buttonContentOverride.description, id) }}
                onMouseLeave={() => { onMouseLeaveSmallButton && onMouseLeaveSmallButton() }}
                style={{
                    ...linkButtonStyle,
                    ...buttonContentOverride.buttonStyle
                }}
                onClick={() => {
                    getSavedLinkParametersOrLink(accountingPkg!, workspaceId, workspaceDisplayName);
                }}
            >
                <Tooltip title={tooltipTitle(disabled)}>
                    {buttonContentOverride.buttonContent}
                </Tooltip>
            </Button>
        );
    }

    if ((!buttonType) || (buttonType === 'large-list-icon')) {
        if (datasourceNameId === 'quickbooksonline') {
            return getLargeListButton(
                AccountingPackage.QuickBooksOnline,
                'qbo-icon',
                disabled,
                getTitleForButton(datasourceNameId)
            );
        } else if (datasourceNameId === 'xero') {
            return getLargeListButton(
                AccountingPackage.Xero,
                'xero-icon',
                disabled,
                getTitleForButton(datasourceNameId)
            );
        } else if (datasourceNameId === 'quickbooksdesktop') {
            return (
                <Tooltip title={tooltipTitle(disabled)}>
                    <button
                        key={`not-connected-icon-${workspaceId}${keySuffix}`}
                        className={`not-connected-icon disabled${classSuffix}`}
                        disabled={true}
                        style={linkButtonStyle}
                    >
                        <NoAccountingSystemIcon style={{ margin: 'auto', pointerEvents: 'none' }} />
                    </button>
                </Tooltip>

            );
        } else if (datasourceNameId === 'sageintacct') {
            return getLargeListButton(
                AccountingPackage.SageIntacct,
                'sage-intacct-icon',
                disabled,
                getTitleForButton(datasourceNameId)
            );
        } else if (datasourceNameId === 'example') {
            return getLargeListButton(
                AccountingPackage.Example,
                'example-icon',
                disabled,
                getTitleForButton(datasourceNameId)
            );
        } else if (datasourceNameId === 'netsuite') {
            return getLargeListButton(
                AccountingPackage.NetSuite,
                'netsuite-icon',
                disabled,
                getTitleForButton(datasourceNameId)
            );
        } else if (datasourceNameId === 'dynamics365') {
            return getLargeListButton(
                AccountingPackage.Dynamics365,
                'dynamics365-icon',
                disabled,
                getTitleForButton(datasourceNameId)
            );
        } else if (datasourceNameId === 'myobbusiness') {
            return getLargeListButton(
                AccountingPackage.MYOBBusiness,
                'myob-icon',
                disabled,
                getTitleForButton(datasourceNameId)
            );
        } else {
            return (
                <Tooltip title={tooltipTitle(disabled)}>
                   <button
                       key={`not-connected-icon-${workspaceId}${keySuffix}`}
                       className={`not-connected-icon disabled${classSuffix}`}
                       disabled={true}
                       style={linkButtonStyle}
                   >
                       <NoAccountingSystemIcon style={{ margin: 'auto', pointerEvents: 'none' }} />
                   </button>
               </Tooltip>
            );
        }
    }
    if (buttonType === 'small-button') {
        if (datasourceNameId === 'quickbooksonline') {
            return getSmallListButton(
                AccountingPackage.QuickBooksOnline,
                'qboIcon',
                'QuickBooks.png',
                getTitleForButton(datasourceNameId),
                disabled
            )
        } else if (datasourceNameId === 'xero') {
            return getSmallListButton(
                AccountingPackage.Xero,
                'xeroIcon',
                'xero.png',
                getTitleForButton(datasourceNameId),
                disabled
            );
        } else if (datasourceNameId === 'quickbooksdesktop') {
            const id = `qbd${workspaceId}${keySuffix}`;
            return (
                <Button
                    className={`submissions-button-icon small-accounting-button`}
                    id={id}
                    disabled={true}
                    onMouseEnter={() => { onMouseEnterSmallButton && onMouseEnterSmallButton(getTitleForButton(datasourceNameId), id) }}
                    onMouseLeave={() => { onMouseLeaveSmallButton && onMouseLeaveSmallButton() }}
                    style={linkButtonStyle}
                >
                    <Tooltip title={tooltipTitle(disabled)}>
                        <NoAccountingSystemIcon className={`not-connected-icon disabled${classSuffix}`} />
                    </Tooltip>
                </Button>
            );
        } else if (datasourceNameId === 'sageintacct') {
            return getSmallListButton(
                AccountingPackage.SageIntacct,
                'sageIntacctIcon',
                'sageIntacct.png',
                getTitleForButton(datasourceNameId),
                disabled
            )
        } else if (datasourceNameId === 'example') {
            return getSmallListButton(
                AccountingPackage.Example,
                'dexIcon',
                'dex.png',
                getTitleForButton(datasourceNameId)
            )
        } else if (datasourceNameId === 'netsuite') {
            return getSmallListButton(
                AccountingPackage.NetSuite,
                'netsuiteIcon',
                'netsuite.png',
                getTitleForButton(datasourceNameId)
            )
        } else if (datasourceNameId === 'dynamics365') {
            return getSmallListButton(
                AccountingPackage.Dynamics365,
                'dynamics365Icon',
                'dynamics365.png',
                getTitleForButton(datasourceNameId)
            )
        } else if (datasourceNameId === 'myobbusiness') {
            return getSmallListButton(
                AccountingPackage.MYOBBusiness,
                'MYOBBusinessIcon',
                'myob.png',
                getTitleForButton(datasourceNameId)
            )
        } else {
            const id = `unknown${workspaceId}${keySuffix}`;
            return (
                <Button
                    key={'unknown-accounting-button'}
                    className={`submissions-button-icon small-accounting-button`}
                    id={id}
                    disabled={true}
                    onMouseEnter={() => { onMouseEnterSmallButton && onMouseEnterSmallButton(getTitleForButton(datasourceNameId || ''), id) }}
                    onMouseLeave={() => { onMouseLeaveSmallButton && onMouseLeaveSmallButton() }}
                    style={linkButtonStyle}
                >
                    <Tooltip title={tooltipTitle(disabled)}>
                        <NoAccountingSystemIcon className={`not-connected-icon disabled${classSuffix}`} />
                    </Tooltip>
                </Button>
            );
        }
    }
    return (<></>);
}

export const FinancialsRelinkButton = connect<InjectedReduxState, InjectedActionCreators, FinancialsRelinkButtonProps, ApplicationState>(
    (appState: ApplicationState) => {
        const result = {
            linkParamsActive: GetShareableLinkParamsFlowSwitch(appState),
            strongboxUrl: GetStrongboxUrl(appState) || '',
            connectionOptions: GetConnectionOptions(appState),
            directLinkingDisabled: DirectLinkingDisabled(appState),
            defaultPortalLinkOptions: GetDefaultSubmissionOptions(appState, 'portal', false),
        };

        return result;
    },
    {
        ...ImportFinancialsActions
    }
)(FinancialsRelinkButtonComponent);

