import * as React from 'react';

import {
    Row,
    Col,
    Container
} from 'reactstrap';

import { FileSelectUpload } from '../FileSelectUpload/FileSelectUpload';

type Props = {
    submissionId: string;
    borrowerId: string;
    onWorking?: (working: boolean) => void;
};


export const UploadAdditionalFilesWidget: React.FC<Props> = (props): React.ReactElement => {
    const {
        borrowerId,
        onWorking,
        submissionId
    } = props;

    return (
        <Container fluid>
            <Row>
                <Col
                    xs={12}
                    className={'file-upload-container'}
                >
                    <FileSelectUpload
                        dark
                        submissionId={submissionId}
                        workspaceId={borrowerId}
                        onAllDone={() => { }}
                        excludeAllDoneButton={true}
                        onWorking={onWorking}
                    />
                </Col>
            </Row>
        </Container>
    )
}
