import { Reducer } from 'redux';

import { RoleResponse } from '../../Models/Api/strongbox.financialportal';

import {
    UserRolesActions,
    KnownAction,
} from './Actions';

export interface IUserRolesState {
    roles: RoleResponse[];
    loading: boolean;
}

const defaultState: IUserRolesState = {
    roles: [],
    loading: false,
}

export const reducer: Reducer<IUserRolesState, KnownAction> = (state: IUserRolesState | undefined, action: KnownAction): IUserRolesState => {
    let newState: IUserRolesState | undefined = undefined;

    switch (action.type) {
        case UserRolesActions.LoadRoles: {
            if (!!state && state.loading) {
                break;
            }
            newState = {
                ...(state ? state : defaultState),
                loading: true,
            };
            break;
        }
        case UserRolesActions.LoadRolesComplete: {
            newState = {
                ...(state ? state : defaultState),
                roles: action.roles,
                loading: false,
            };
            break;
        }
    }

    if (newState) {
        return newState;
    } else if (state) {
        return state;
    } else {
        let defaultCopy: IUserRolesState = {
            ...defaultState,
        };
        return defaultCopy;
    }
}