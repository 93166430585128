import * as React from 'react';

import '../Main.scss';

import {
    Row,
    Col,
    Popover,
    PopoverBody
} from 'reactstrap';

import { DropDownCaret } from '../DropDownCaret/DropDownCaret';

import { SubmissionFilterSet } from './SubmissionFilterSet';
import { SubmissionFilter } from '../../Store/SubmissionHistory';

type Props = {
    statusFilters: SubmissionFilter[];
    sourceFilters: SubmissionFilter[];
    onStatusFilterStateChange?: (id: string, newState: boolean) => void;
    onSourceFilterStateChange?: (id: string, newState: boolean) => void;
    filterConfigOpen: boolean;
    toggleFilterConfigOpen: () => void;
    closeFilterConfigOpen: () => void;
}

export const FilterDropdown: React.FC<Props> = (props): React.ReactElement => {
    const {
        statusFilters,
        sourceFilters,
        onStatusFilterStateChange,
        onSourceFilterStateChange,
        filterConfigOpen,
        toggleFilterConfigOpen,
        closeFilterConfigOpen,
    } = props;

    const clickRef = React.useRef<any>();

    const handleClick = (e: MouseEvent): void => {
        if (!!clickRef && !!clickRef.current) {
            if (!clickRef.current.contains(e.target)) {
                closeFilterConfigOpen();
            }
        }
    }
  
    React.useEffect(() => {
        // add when mounted
        document.addEventListener("mousedown", handleClick);
        // return function to be called when unmounted
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
        // I want this to execute equivalent to componentDidMount so this is appropriate
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            className={`dropdown`}
        >
            <Row>
                <Col xs={'auto'}>
                    <DropDownCaret
                        id={'filter-selection-dropdown'}
                        expanded={filterConfigOpen}
                        expandDescriptor={'Show filters'}
                        contractDescriptor={'Hide filters'}
                        clicked={toggleFilterConfigOpen}
                        text={'Filters'}
                    />
                </Col>
            </Row>
            {filterConfigOpen && (
                <Popover
                    placement={'bottom'}
                    isOpen={true}
                    target={'filter-selection-dropdown'}
                    key={'filter-selection-dropdown'}
                >
                    <PopoverBody>
                        <div
                            className={`control-region control-region-lender dropdown-content-region`}
                            ref={clickRef}
                        >
                            <SubmissionFilterSet
                                title={'Data Collection status'}
                                filters={statusFilters}
                                onFilterStateChange={(id, newState) => {
                                    onStatusFilterStateChange && onStatusFilterStateChange(id, newState);
                                }}
                            />

                            <SubmissionFilterSet
                                style={{ marginLeft: '25px' }}
                                title={'Originating from'}
                                filters={sourceFilters}
                                onFilterStateChange={(id, newState) => {
                                    onSourceFilterStateChange && onSourceFilterStateChange(id, newState);
                                }}
                            />
                        </div>
                    </PopoverBody>
                </Popover>
            )}
        </div>
    )
}
