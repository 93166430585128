import * as React from 'react';

import '../../Main.scss';
import './FinancialStatements.scss';

import FilterIcon from '@mui/icons-material/FilterList';

import {
    Popover,
    PopoverBody,
    Container,
    Row,
    Col,
    Input
} from 'reactstrap';

import {
    Tooltip
} from '@mui/material';

import {
    FinancialStatementLineItemFilter,
} from '../../../Store/FinancialStatements';

import { FSModels } from '@finagraph/financial-statement-editor';

const idFilterAnchor = 'financial-statement-table-line-item-filter-toggle';

type FilterButtonProps = {
    onFilterLineItems: () => void;
    dropdownActive: boolean;
}

export const FilterDropdownButton: React.FC<FilterButtonProps> = (props): React.ReactElement => {
    const {
        dropdownActive,
        onFilterLineItems
    } = props;

    const renderButton = (): React.ReactElement => {
        return (
            <button
                className={'header'}
                onClick={() => onFilterLineItems()}
                id={idFilterAnchor}
            >
                <FilterIcon />
            </button>
        )
    }

    return (
        <Tooltip
            title={!dropdownActive ? 'Filter by line item type' : ''}
        >
            {renderButton()}
        </Tooltip>
    )
}

type Props = {
    onToggle: (active: boolean) => void;
    filters: FinancialStatementLineItemFilter[];
    statementType: FSModels.FinancialStatementType;
    onFilterStateChange: (filterType: FSModels.FinancialStatementLineItemType, newState: boolean) => void;
}

export const FilterDropdown: React.FC<Props> = (props): React.ReactElement => {
    const {
        onToggle,
        filters,
        statementType,
        onFilterStateChange
    } = props;

    const getSelectionRow = (filter: FinancialStatementLineItemFilter): React.ReactElement => {
        return (
            <Row key={`financial-statement-filter-dropdown-${statementType}-${filter.type}`}>
                <Col xs={2}>
                    <Input
                        className={'clickable'}
                        type={'checkbox'}
                        checked={filter.state}
                        onChange={(e) => {
                            onFilterStateChange(filter.type, !filter.state);
                        }}
                    />
                </Col>
                <Col>
                    <span
                        className={'clickable'}
                        onClick={(e) => {
                            onFilterStateChange(filter.type, !filter.state);
                        }}
                    >
                        {filter.description}
                    </span>
                </Col>
            </Row>
        )
    }

    return (
        <div className={`control-region control-region-lender`}>
            <Popover
                placement={'bottom'}
                hideArrow={true}
                isOpen={true}
                className={'financial-statement-popover'}
                target={idFilterAnchor}
                key={'fs-filter-line_items-popover'}
                toggle={() => onToggle && onToggle(false)}
                trigger={'legacy'}
            >
                <PopoverBody className={'popover-body menu-style'}>
                    <Container fluid>
                        {
                            filters.map(f => getSelectionRow(f))
                        }
                    </Container>
                </PopoverBody>
            </Popover>
        </div>
    )
}
