import {
    IInitializeMinMappingDate,
    GlobalSettingsActions
} from './Actions';

function InitializeMinMappingDate(minMappingDate?: Date): IInitializeMinMappingDate {
    return {
        type: GlobalSettingsActions.InitializeMinMappingDate,
        minMappingDate,
    };
}

export const actionCreators = {
    InitializeMinMappingDate: InitializeMinMappingDate,
};
