import {
    UIStateActions,
    ISetAddUsers,
    IAllowHomeNavigate,
    IChangePortalBusy,
    ISetRedirectAfterLogin
} from './Actions';
import { UIRedirectSearchParams } from './Reducer';

function SetAddUsers(isActive: boolean): ISetAddUsers {
    return {
        type: UIStateActions.SetAddUsers,
        isActive,
    };
}

function AllowHomeNavigate(allow: boolean): IAllowHomeNavigate {
    return {
        type: UIStateActions.SetHomeNavigate,
        allow,
    }
}

function SetPortalWorking(source: string): IChangePortalBusy {
    return {
        type: UIStateActions.ChangePortalBusy,
        increment: 1,
        source
    };
}

function SetPortalIdle(source: string): IChangePortalBusy {
    return {
        type: UIStateActions.ChangePortalBusy,
        increment: -1,
        source
    };
}

function SetRedirectAfterLogin(path?: string, searchParams?: UIRedirectSearchParams): ISetRedirectAfterLogin {
    return {
        type: UIStateActions.SetRedirectAfterLogin,
        path,
        searchParams
    };
}

export const actionCreators = {
    SetAddUsers: SetAddUsers,
    SetRedirectAfterLogin: SetRedirectAfterLogin,
    SetPortalWorking: SetPortalWorking,
    SetPortalIdle: SetPortalIdle,
    AllowHomeNavigate: AllowHomeNavigate,
};
