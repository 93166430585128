import * as React from 'react';
import {
    Col,
    Container,
    Row,
    Table
} from 'reactstrap';

import DeleteIcon from '@mui/icons-material/Delete';

import { CreateUserRequest, RoleResponse } from '../../../Models/Api/strongbox.financialportal';
import { FullUserNameFromComponents } from '../../../Utils/UserUtils';
import { AddUsersMode } from './CommonUser';

type Props = {
    availableRoles: RoleResponse[];
    users: CreateUserRequest[];
    removeUser: (userIndex: number) => void;
    editMode: AddUsersMode;
}

export const InvitedUserList: React.FC<Props> = (props): React.ReactElement => {
    const getPermissionsText = (user: CreateUserRequest): string => {
        if (!(user.roleIds && props.availableRoles)) {
            return 'default';
        }
        let result = '';
        let first = true;
        user.roleIds.forEach((roleId, index) => {
            const roleDescription = props.availableRoles.find(roleDesc => roleDesc.id === roleId);
            if (!!roleDescription) {
                result += (first ? `${roleDescription.displayName}` : `, ${roleDescription.displayName}`);
                first = false;
            }
        });
        return result;
    }

    const titlePrompt = React.useMemo(() => {
        return props.editMode === 'editrequired' ? 'Users to invite' : 'Optional User Data';
    }, [props.editMode]);

    const instructionText = React.useMemo(() => {
        return props.editMode === 'editrequired' ?
            'Fill in the fields on the left and press the \'Add\' button to add a new user to the list of ' +
            'users to invite. When you are finished entering new users, press the \'Invite New Users\' ' +
            'button below to add them to the system. Press \'Cancel\' at any time to leave without ' +
            'adding any new users.' :
            'The fields on the left allow you to supply optional information that can be useful for ' +
            'identifying users. When you have finished entering optional user information press the ' +
            '\'Edit Required Fields\' button to return to editing required information for the user. ' +
            'Press \'Cancel\' at any time to leave without adding any new users.';
    }, [props.editMode]);

    return (
        <Container>
            <Row className={'std-form-row'}>
                <h4>{titlePrompt}</h4>
            </Row>
            <Row className={'std-form-row'}>
                {!!props.users.length && (
                    <Col className={'column-list-container'}>
                        <Table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Name</th>
                                    <th>Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.users.map((user, index) => (
                                    <tr key={index.toString()} id={index.toString()}>
                                        <td>
                                            <DeleteIcon
                                                onClick={() => {
                                                    props.removeUser(index);
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <div className={'multiline-cell'}>
                                                <span>{FullUserNameFromComponents(user.displayName, user.firstName, user.lastName)}</span>
                                                <span className={'light'}>{getPermissionsText(user)}</span>
                                            </div>
                                        </td>
                                        <td>{user.emailAddress}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                )}
                {!props.users.length && (
                    <Col>{instructionText}</Col>
                )}
            </Row>
        </Container>
    );
}
