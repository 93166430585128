import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    ImportFinancials,
    LinkerModal,
    StrongboxConnectionRequest,
    StrongboxImportRequest,
    Theme as StrongboxTheme
} from '@finagraph/strongbox-finconnect-react';

import { ImportCustomerFinancials } from '../ImportCustomerFinancials';
import {
    CreateSubmissionForWorkspaceId,
    OnlineLinkFunc
} from './Common';

import {
    actionCreators as ImportFinancialsActions,
    ConnectionOptions,
    CustomerFinancialsTarget,
    DirectLinkParameters,
    GetImportCustomerFinancialsTarget,
    GetImportingDirect,
    GetConnectionOptions,
    GetImportRetry,
    ImportMap,
    LinkMode,
} from '../../Store/ImportFinancials';
import {
    GetBasicSettings,
    GetBrandConfig,
    GetTenantName,
} from '../../Store/Tenant';
import { actionCreators as NewDataCollectionParametersActions } from '../../Store/NewDataCollectionParameters';
import {
    GetStrongboxUrl
} from '../../Store/AppSettings';
import { actionCreators as SubmissionHistoryActions } from '../../Store/SubmissionHistory';
import { actionCreators as RunningImportsActions } from '../../Store/RunningImports';
import {
    GetWorkspaceInfo,
    actionCreators as WorkspacesActions,
    WorkspaceWithMeta,
} from '../../Store/Workspaces';

import {
    PortalDefaultBasisOfAccounting,
    PortalSubmissionRequestDefaultFullFiscalYears,
    PortalCreateSubmissionRequestOptions
} from '../../Models/PortalCreateSubmissionRequestOptions';
import {
    AccountingCollectionGroup
} from '../../Models/AccountingCollectionGroup';

import { ApplicationState } from '../../Store';

import { EntityDetailResponse } from '../../Models/Api/strongbox.financialportal';
import { ConnectionRequestDescriptor, ConsumerMetadata } from '../../Models/Api/strongbox.models';

import { BuildModalThemeForTenant } from '../Brands/BrandThemes';
import { ErrorState } from '../ErrorBanner/ErrorBanner';
import { EventCategory, MetricsService } from '../../Utils/Metrics';

import { LenderLinkModal } from '../LenderLink/LenderLinkModal';

import { GetFinancialsImportOptions } from '../../Utils/LinkUtils';
import { LogException, LogMessage, SeverityLevel } from '../../Utils/Logging';
import { ImportMetadataTags } from '../../Utils/Constants';

import { GetWorkspaceDetails } from '../../Services/WorkspaceService';

type InjectedReduxState = {
    customerFinancialsTarget: CustomerFinancialsTarget | undefined;
    strongboxModalThemes: StrongboxTheme | undefined;
    strongboxUrl: string;
    tenantVisibleName: string;
    workspaces: WorkspaceWithMeta[];
    importingDirect: DirectLinkParameters | undefined;
    connectionOptions?: ConnectionOptions;
    retryImport?: ImportMap;
};

type InjectedActionCreators =
    typeof ImportFinancialsActions &
    typeof NewDataCollectionParametersActions &
    typeof SubmissionHistoryActions &
    typeof RunningImportsActions &
    typeof WorkspacesActions;

type FinancialsImportProps = {
    onSetErrorState: (errorState: ErrorState) => void;
    onSetImporting: (working: boolean) => void;
}

type Props = FinancialsImportProps & InjectedActionCreators & InjectedReduxState;

type ConnectionState = {
    cxnRequest: StrongboxConnectionRequest | undefined;
    importStarted: boolean;
}

const FinancialsImportComponent: React.FC<Props> = (props): React.ReactElement => {
    const {
        connectionOptions,
        importingDirect,
        onSetErrorState,
        onSetImporting,
        retryImport,
        SetConnectionOptions,
        strongboxModalThemes,
        strongboxUrl,
        tenantVisibleName,
        workspaces,
    } = props;


    const [importParameters, setImportParameters] = React.useState<ConnectionState>({
        cxnRequest: undefined,
        importStarted: false
    });

    const setCxnRequest = (cxnRequest: StrongboxConnectionRequest | undefined): void => {
        setImportParameters({
            ...importParameters,
            cxnRequest
        });
    }

    // What's going on here.  renderEmptyOnConnecting is set whenever importParameters gets changed in
    // response to the user beginning an import from the LinkerModal dialog either by choosing
    // to create a new connection or using an existing one.
    // 
    // renderEmptyOnConnecting is used when rendering an "in-between" state inside renderLinkDialog.
    // Generally as the name implies that method renders the linking dialog but 
    // when the user actually starts an import, a couple things happen.  The first is
    // when there's no connection id and importStarted is true.  That means we needed
    // to create a connection ID and we're off doing that.  We shouldn't really render anything.
    //
    // The second state is when we're using an existing connection id but the import isn't started
    // Slightly different flow where we call onConnected with the existing connection ID.  In the
    // case above, we would have called a method to go create a connection and then we'd start the
    // import.  In this case we just want to start the import.
    //
    // In all other cases set renderEmptyOnConnecting to false.   Note this doesn't necessarily mean nothing gets
    // rendered, it means that in that case where we're acting on a button to start an import using
    // either an existing connection or by creating a new one, nothing will be rendered.

    const [renderEmptyOnConnecting, setRenderEmptyOnConnecting] = React.useState<boolean>(true);

    React.useEffect(() => {
        if (!importParameters) {
            setRenderEmptyOnConnecting(true);
        } else {
            if ((!importParameters.cxnRequest) || (importParameters.importStarted)) {
                setRenderEmptyOnConnecting(true);
            } else if (!!importParameters.cxnRequest.existingConnectionId) {
                onConnected(importParameters.cxnRequest);
                setRenderEmptyOnConnecting(true);
            } else {
                setRenderEmptyOnConnecting(false);
            }
        }
        // onConnected won't change and doesn't need to be in the dependency list.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [importParameters])

    const [workspaceDetails, setWorkspaceDetails] = React.useState<{ fetching: boolean; details?: EntityDetailResponse }>({ fetching: false });

    React.useEffect(() => {
        if (!!connectionOptions) {
            setWorkspaceDetails({ fetching: true });
            GetWorkspaceDetails(connectionOptions.workspaceId)
                .then(details => {
                    setWorkspaceDetails({ fetching: false, details });
                })
                .catch(reason => {
                    LogException(
                        `Failure retrieving workspace details`,
                        reason,
                        {
                            workspaceId: connectionOptions.workspaceId
                        }
                    );
                    setWorkspaceDetails({ fetching: false });
                });
        }
    }, [connectionOptions]);

    React.useEffect(() => {
        if (!!(retryImport && connectionOptions)) {
            const ip = {
                cxnRequest: {
                    accountingPackage: retryImport.accountingPackage,
                    delegatedAccessToken: retryImport.importRequest.delegatedAccessToken,
                    strongboxUri: retryImport.importRequest.strongboxUri,
                    orgId: retryImport.workspaceId,
                    submissionId: retryImport.importRequest.submissionId,
                },
                importStarted: false,
            };

            let workspaceName = '';
            // We should find the workspace in the list of workspaces but hardly the end of the world if
            // we don't and default to ''
            const thisWorkspace = workspaces.find(ws => ws.id === retryImport.workspaceId);
            if (!!thisWorkspace) {
                workspaceName = thisWorkspace.displayName;
            }

            // When the financialStatementsPeriod is saved, it's saved with the values that actually get
            // sent with the import request which will be 1 more than the total fiscal year value entered 
            // by the user because we add 1 to the total number of fiscal years collected to account for YTD. 
            // So subtract one from this value.   If it's not present at all (unlikely, this is a sanity check)
            // default of 2 is correct.

            const fiscalYearsToCollect = retryImport.importRequest.lenderManagedOptions?.financialStatementsPeriod?.numberOfPeriods ?
                retryImport.importRequest.lenderManagedOptions.financialStatementsPeriod.numberOfPeriods - 1 :
                PortalSubmissionRequestDefaultFullFiscalYears;

            // The defaults when we set up options below are not likely, should just be sanity values.
            // By the time retryImport is set, all of this should be filled in, on the off chance
            // it's not, we'll use the default instead of crashing.

            const cxnOptions: ConnectionOptions = {
                workspaceId: retryImport.workspaceId,
                workspaceName,
                linkMode: LinkMode.direct,
                chooseAccountingSystem: false,
                options: {
                    basisOfAccountingPreference: retryImport.importRequest.lenderManagedOptions?.financialStatementsPeriod?.basisOfAccountingPreference || PortalDefaultBasisOfAccounting,
                    mostRecentPeriodMonth: retryImport.importRequest.lenderManagedOptions?.mostRecentDate?.month,
                    mostRecentPeriodYear: retryImport.importRequest.lenderManagedOptions?.mostRecentDate?.year,
                    mostRecentPeriodDay: retryImport.importRequest.lenderManagedOptions?.mostRecentDate?.day,
                    anonymizeCustomersAndVendors: retryImport.importRequest.lenderManagedOptions?.anonymizeCustomersAndVendors || false,
                    provideUserCopy: retryImport.importRequest.lenderManagedOptions?.provideUserCopy || false,
                    allowUserUpload: false,
                    hasShareableLink: false,
                    fullFiscalYearsToCollect: fiscalYearsToCollect,
                    accountingCollectionGroups: new Map<string, AccountingCollectionGroup>(connectionOptions.options.accountingCollectionGroups),
                    endOfImport: 'Today',
                },
            };

            setImportParameters(ip);

            props.ClearRetryImportRequest();

            SetConnectionOptions(cxnOptions);
        }
        // The state that matters to the execution of this effect are included
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [retryImport, workspaces, connectionOptions ]);

    const lenderLink = (
        linkMode: LinkMode,
        workspaceId: string,
        workspaceName: string,
        allowAccountingPkgChoice: boolean,
        options: PortalCreateSubmissionRequestOptions
    ) => {
        const cxnOptions: ConnectionOptions = {
            workspaceId,
            workspaceName,
            linkMode,
            chooseAccountingSystem: allowAccountingPkgChoice,
            options,
        };

        // On first selection of shareable link as the option, probably default, should call create Submission request
        SetConnectionOptions(cxnOptions);
    }

    const handleImportSubmit = (linkMode: LinkMode, uiOptions: PortalCreateSubmissionRequestOptions, allowPkgSelection: boolean): void => {
        let activeImportingDirect: DirectLinkParameters | undefined = importingDirect;

        if (!!activeImportingDirect) {
            const activeAccountingPkg = activeImportingDirect.accountingPkg;
            if (props.customerFinancialsTarget) {
                lenderLink(
                    linkMode,
                    props.customerFinancialsTarget.workspaceId,
                    props.customerFinancialsTarget.workspaceName,
                    allowPkgSelection,
                    uiOptions
                );

                onSetImporting && onSetImporting(true);

                CreateSubmissionForWorkspaceId(
                    props.customerFinancialsTarget.workspaceId,
                    uiOptions,
                    onSetErrorState
                )
                    .then(submissionId => {
                        OnlineLinkFunc(
                            activeAccountingPkg,
                            props.customerFinancialsTarget!.workspaceId,
                            uiOptions,
                            submissionId,
                            strongboxUrl,
                            onSetErrorState,
                            onSetImporting,
                            setCxnRequest
                        );
                    })
                    .catch(reason => {
                    })
                    .finally(() => {
                        onSetImporting && onSetImporting(false);
                    });
            }
        } else {
            // This just sets the options in the local redux store. This will result
            // in the same settings being available when the lender re-enters the import
            // dialog.
            if (uiOptions) {
                props.SetNewDataCollectionOptions(uiOptions, uiOptions.fullFiscalYearsToCollect);
            }
            if (props.customerFinancialsTarget) {
                lenderLink(
                    linkMode,
                    props.customerFinancialsTarget.workspaceId,
                    props.customerFinancialsTarget.workspaceName,
                    allowPkgSelection,
                    uiOptions
                );
            }
        }
    }

    const onImportStarted = (financialRecordId: string, importRequest: StrongboxImportRequest): void => {
        const logProperties = {
            workspaceId: importRequest.orgId,
            accountingPackage: importRequest.accountingPackage,
            lenderManagedOptions: {...importRequest.lenderManagedOptions},
            submissionId: importRequest.submissionId,
            connectionId: importRequest.connectionId,
            financialRecordId: financialRecordId,
        };

        LogMessage(
            `onImportStarted invoked`,
            SeverityLevel.Information,
            logProperties,
        );    

        MetricsService.tryTrackEvent(EventCategory.Import, 'JobCreatedWorkspaceList', { package: importRequest.accountingPackage });

        // Check why there's a delay between this and the dialog going away.
        SetConnectionOptions(undefined);
    }

    const onConnected = (cxnRequest: StrongboxConnectionRequest, apiRequestParameters?: ConnectionRequestDescriptor): void => {
        // existingConnectionId should be filled in by the generator of the onConnectedMethod. If it
        // is passed there as undefined it should go get a connection
        if (!!connectionOptions && cxnRequest.existingConnectionId) {

            // Workspace details should be retrieved by the time we get here AND we should have disabled continue buttons
            // in the various modals if we're fetching so the check below on fetching is really just a sanity check.  
            // Shouldn't be possible for it to be true when we get here.
            const initialMetadata: ConsumerMetadata[] = [
                new ConsumerMetadata({
                    label: ImportMetadataTags.sharedLinkCompanyName,
                    value: connectionOptions.workspaceName,
                }),
                new ConsumerMetadata({
                    label: ImportMetadataTags.sharedLinkApplicationId,
                    value: workspaceDetails.fetching ? '' : (workspaceDetails.details?.engagementCode || ''),
                }),
                new ConsumerMetadata({
                    label: ImportMetadataTags.borrowerBusinessName,
                    value: connectionOptions.workspaceName,
                }),
                new ConsumerMetadata({
                    label: ImportMetadataTags.borrowerBusinessEmail,
                    value: workspaceDetails.fetching ? '' : (workspaceDetails.details?.primaryEmail || ''),
                })
            ]

            const importRequest: StrongboxImportRequest = {
                accountingPackage: cxnRequest.accountingPackage,
                lenderManagedOptions: GetFinancialsImportOptions(connectionOptions.options),
                initiator: 'portal',
                delegatedAccessToken: cxnRequest.delegatedAccessToken,
                orgId: cxnRequest.orgId,
                strongboxUri: cxnRequest.strongboxUri,
                submissionId: cxnRequest.submissionId,
                connectionId: cxnRequest.existingConnectionId,
                initialMetadata,
            }

            props.SaveImportParameters(
                cxnRequest.orgId,
                cxnRequest.accountingPackage,
                importRequest
            );

            const logProperties = {
                workspaceId: cxnRequest.orgId,
                accountingPackage: cxnRequest.accountingPackage,
                lenderManagedOptions: {...importRequest.lenderManagedOptions},
                submissionId: cxnRequest.submissionId,
                connectionId: cxnRequest.existingConnectionId,
            };

            LogMessage(
                `ImportFinancials invoked`,
                SeverityLevel.Information,
                logProperties,
            );   

            ImportFinancials(
                importRequest,
                (msg: string, detailedMsg: string) => {

                    LogException(
                        `ImportFinancials error`,
                        new Error(msg + ':' + detailedMsg),
                        logProperties,
                    );    
                    onSetErrorState({
                        summaryMessage: msg,
                        extraInformation: detailedMsg,
                        severity: 'Error'
                    });
                    SetConnectionOptions(undefined);
                },
                onImportStarted
            )
                .finally(() => {
                    setImportParameters({
                        cxnRequest: undefined,
                        importStarted: false
                    });
                    onSetImporting(false);
                })

            setImportParameters({
                ...importParameters,
                importStarted: true
            });
            onSetImporting(true);
        } else {
            console.error('There is no connection id or connectionOptions is undefined in FinancialsImport:onConnected');
            console.error(connectionOptions);
            console.error(cxnRequest);

            LogException(
                'There is no connection id or connectionOptions is undefined in FinancialsImport:onConnected',
                new Error('There is no connection id or connectionOptions is undefined in FinancialsImport:onConnected'),
                {
                    options: {
                        ...connectionOptions
                    },
                    workspace: cxnRequest.orgId,
                    accountingPackage: cxnRequest.accountingPackage,
                    submission: cxnRequest.submissionId
                }
            )

            SetConnectionOptions(undefined);
        }
    }

    const onLinkCompleted = (success: boolean): void => {
        onSetImporting && onSetImporting(false);
        setImportParameters({
            ...importParameters,
            cxnRequest: undefined
        });
    }

    const renderLinkDialog = (useImportParameters: ConnectionState): React.ReactNode => {
        if (renderEmptyOnConnecting) {
            return (<></>);
        }

        return (
            <LinkerModal
                cxnRequest={useImportParameters.cxnRequest}
                theme={strongboxModalThemes}
                onConnected={(cxnRequest: StrongboxConnectionRequest, apiRequestParameters?: ConnectionRequestDescriptor) => {
                    onConnected(cxnRequest, apiRequestParameters);
                }}
                onCompleted={(success: boolean) => {
                    onLinkCompleted(success);
                }}
                checkAuthorizationStatus={true}
                onDisconnection={(success: boolean) => {
                    if (success) {
                        onSetImporting && onSetImporting(false);
                        setImportParameters({
                            ...importParameters,
                            cxnRequest: undefined,
                        });
                    }
                }}
            />
        );
    }

    return (
        <>
            {
                // Ultimately, all requests for linking with the financial package that are generated
                // by the portal come through here whether they are direct links or shareable links.
                // If the request is for a shareable link, this.state.lenderLinkProps.options.hasShareableLink will
                // be true.  When the user chooses a package in LenderLinkModal and continues, we
                // will create a submission request and it will be shareable or not.

                connectionOptions &&
                connectionOptions.chooseAccountingSystem &&
                !importParameters.importStarted && (
                    <LenderLinkModal
                        tenantSelectPkgPrompt={''}
                        linkMode={connectionOptions.linkMode}
                        workspaceName={connectionOptions.workspaceName}
                        workspaceId={connectionOptions.workspaceId}
                        options={connectionOptions.options}
                        partnerName={tenantVisibleName}
                        onComplete={() => {
                            SetConnectionOptions(undefined);
                        }}
                        strongboxModalThemes={strongboxModalThemes}
                        strongboxUrl={strongboxUrl}
                        onAccountingSystemConnected={(workspaceId, workspaceName, cxnRequest, apiRequestParameters) =>
                            onConnected(cxnRequest, apiRequestParameters)
                        }
                        externallyDisabled={workspaceDetails.fetching}
                    />
                )
            }
            {!importParameters.importStarted && (
                <ImportCustomerFinancials
                    directOnly={!!importingDirect}
                    workspaceName={(props.customerFinancialsTarget && props.customerFinancialsTarget.workspaceName) || ''}
                    onSubmit={handleImportSubmit}
                    onCancel={() => { }}
                    externallyDisabled={workspaceDetails.fetching}
                />
            )}
            {renderLinkDialog(importParameters)}
        </>
    )
}

export const FinancialsImport = connect<InjectedReduxState, InjectedActionCreators, FinancialsImportProps, ApplicationState>(
    (appState: ApplicationState) => {
        const tenant = GetTenantName(appState);
        const basicSettings = GetBasicSettings(appState);
        const workspaceInfo = GetWorkspaceInfo(appState);
        const brandconfig = GetBrandConfig(appState);

        const result = {
            customerFinancialsTarget: GetImportCustomerFinancialsTarget(appState),
            tenantStyleClass: tenant,
            strongboxModalThemes: BuildModalThemeForTenant(brandconfig),
            strongboxUrl: GetStrongboxUrl(appState) || '',
            tenantVisibleName: (basicSettings && basicSettings.visibleName) || '',
            workspaces: (!!workspaceInfo) ? workspaceInfo.workspaces : [],
            importingDirect: GetImportingDirect(appState),
            connectionOptions: GetConnectionOptions(appState),
            retryImport: GetImportRetry(appState),
        };

        return result;
    },
    dispatch => bindActionCreators(
        {
            ...ImportFinancialsActions,
            ...NewDataCollectionParametersActions,
            ...SubmissionHistoryActions,
            ...RunningImportsActions,
            ...WorkspacesActions
        },
        dispatch
    )
)(FinancialsImportComponent);

