import { Reducer } from 'redux';

import {
    CookieSROtoPortalSRO,
    PortalCreateSubmissionRequestOptions
} from '../../Models/PortalCreateSubmissionRequestOptions';

import {
    AppSettingsActions,
    KnownAction,
} from './Actions';

import { StgKeyOverrideAppSettings } from '../../Utils/Constants';

export type DefaultSubmissionOptionType = 'portal' | 'shareableanonymous';

export type NewDataCollectionDisabledOptions = {
    disableAdditionalDocUpload: boolean;
    disableAnonymousDocUpload: boolean;
    disableProvideUserCopy: boolean;
    disableAnonymizeResults: boolean;
    disableBasisOfAccounting: boolean;
}

export interface IAppSettingsState {
    financialStatementsEnabled: boolean;
    financialStatementsReleaseEnabled: boolean;
    shareableLinkParamsFlow: boolean;
    dynamicTenantSelection: boolean;
    strongboxUrl: string | undefined;
    accountingPackages: string[];
    accountingPkgBlackList: string[];
    initialized: boolean;
    segmentAnalyticsEnabled: boolean;
    segmentAnalyticsWriteKey: string;
    riskCalcEnabled: boolean;
    showDeletedSubmissions: boolean;
    strongboxSupportEmail: string;
    strongboxHelpCenterLink: string;
    maxYearsInAdditionToFYTDForCollection: number;
    numMonthsInImportYTDDropdown: number;
    disableAppInsights: boolean;
    disableDirectLinking: boolean;
    appInsightsInstrumentationKey: string;
    defaultShareableAnonymousSubmissionOptions?: PortalCreateSubmissionRequestOptions;
    defaultPortalSubmissionOptions?: PortalCreateSubmissionRequestOptions;
    allowMultipleBorrowerImports: boolean;
    noTimeRestrictionForDataCollection: boolean;
    showBorrowerIntroBanner: boolean;
    alwaysCollectBorrowerBusinessInfo: boolean;
    disabledDataCollectionOptions: NewDataCollectionDisabledOptions;
    disableNewWorkspaces: boolean;
    // sessionMaxLifetimeInSeconds is a little special.  AuthorizedApp needs to have this value before
    // the redux store is initialized so the only real use of this value is for overriding the value
    // coming back from the server in the cookie.  AuthorizedApp calls GetSettingFromLocalStorage to
    // try and get this value, if it does, it uses that instead of the value from the cookie.  It never
    // tries to use the value from the redux store.
    sessionMaxLifetimeInSeconds: number;
    allowExcelFileUpload: boolean;
    [key: string]: undefined | number | boolean | string | string[] | NewDataCollectionDisabledOptions | PortalCreateSubmissionRequestOptions;
}

const defaultState: IAppSettingsState = {
    financialStatementsEnabled: false,
    financialStatementsReleaseEnabled: false,
    shareableLinkParamsFlow: false,
    dynamicTenantSelection: false,
    initialized: false,
    strongboxUrl: undefined,
    accountingPackages: [],
    accountingPkgBlackList: [],
    segmentAnalyticsEnabled: false,
    segmentAnalyticsWriteKey: '',
    riskCalcEnabled: false,
    showDeletedSubmissions: false,
    strongboxSupportEmail: '',
    strongboxHelpCenterLink: '',
    maxYearsInAdditionToFYTDForCollection: 5,
    numMonthsInImportYTDDropdown: 7,
    disableAppInsights: false,
    disableDirectLinking: false,
    appInsightsInstrumentationKey: '',
    allowMultipleBorrowerImports: true,
    noTimeRestrictionForDataCollection: false,
    showBorrowerIntroBanner: false,
    alwaysCollectBorrowerBusinessInfo: false,
    disabledDataCollectionOptions: {
        disableAdditionalDocUpload: false,
        disableAnonymousDocUpload: false,
        disableProvideUserCopy: false,
        disableAnonymizeResults: false,
        disableBasisOfAccounting: false,
    },
    sessionMaxLifetimeInSeconds: 86400,
    allowExcelFileUpload: false,
    disableNewWorkspaces: false,
}


function GetEndOfImport(endOfImport: 'Today' | 'LastFullMonth'): { month: number, year: number, day: number } {
    const date = new Date();

    switch (endOfImport) {
        case 'LastFullMonth': {
            date.setDate(0);  // this sets the date to the last day of the prior month
            return {
                month: date.getMonth(),
                year: date.getFullYear(),
                day: date.getDate(),
            };
        }
        default: {
            return {
                month: date.getMonth(),
                year: date.getFullYear(),
                day: date.getDate(),
            };
        }
    }
}

function mapReplacer(k: any, v: any) {
    if (v instanceof Map) {
        return {
            dataType: 'Map',
            value: Array.from(v.entries()), 
        };
    } else {
        return v;
    }
}

function mapReviver(k: any, v: any) {
    if (typeof v === 'object' && v !== null) {
        if (v.dataType === 'Map') {
            return new Map(v.value);
        }
    }
    return v;
}

export function ReadOverriddenAppSettingsValues(dynamicTenantEnabled: boolean): IAppSettingsState | undefined {
    if (!dynamicTenantEnabled) {
        return undefined;
    }

    const overriddenSettingsString = window.localStorage.getItem(StgKeyOverrideAppSettings);

    let overriddenSettings = {};

    if (overriddenSettingsString !== null) {
        try {
            overriddenSettings = JSON.parse(overriddenSettingsString, mapReviver);
            if (overriddenSettings === null) {
                overriddenSettings = {};
            } else {
                return overriddenSettings as IAppSettingsState;
            }
        } catch (exception) {
            console.error('Exception thrown parsing overridden results from local storage');
            console.error(exception);
        }
    }
    return undefined;
}

export const reducer: Reducer<IAppSettingsState, KnownAction> = (state: IAppSettingsState | undefined, action: KnownAction): IAppSettingsState => {
    let newState: IAppSettingsState | undefined = undefined;

    switch (action.type) {
        case AppSettingsActions.Initialize: {
            let endOfImportAnonymous = GetEndOfImport(action.appData.defaultAnonymousSubmissionOptions.endOfImport);
            let endOfImportPortal = GetEndOfImport(action.appData.defaultPortalSubmissionOptions.endOfImport);

            const overriddenState = ReadOverriddenAppSettingsValues(!!action.appData!.dynamicTenantSelection);

            newState = {
                ...(state ? state : defaultState),
                financialStatementsEnabled: !!action.appData!.financialStatementsEnabled,
                financialStatementsReleaseEnabled: !!action.appData!.financialStatementsReleaseEnabled,
                shareableLinkParamsFlow: !!action.appData!.shareableLinkOptionsFlow,
                dynamicTenantSelection: !!action.appData!.dynamicTenantSelection,
                initialized: true,
                strongboxUrl: action.appData!.strongboxUri,
                accountingPackages: action.appData!.accountingPackages,
                accountingPkgBlackList: action.appData!.accountingPkgBlackList,
                segmentAnalyticsEnabled: action.appData!.segmentAnalyticsEnabled,
                riskCalcEnabled: action.appData!.riskCalcEnabled,
                segmentAnalyticsWriteKey: action.appData!.segmentAnalyticsWriteKey,
                strongboxSupportEmail: action.appData.strongboxSupportEmail,
                strongboxHelpCenterLink: action.appData.strongboxHelpCenterLink,
                numMonthsInImportYTDDropdown: action.appData.numMonthsInImportYTDDropdown,
                maxYearsInAdditionToFYTDForCollection: action.appData.maxYearsInAdditionToFYTDForCollection,
                disableAppInsights: action.appData.disableAppInsights,
                disableDirectLinking: action.appData.disableDirectLinking,
                appInsightsInstrumentationKey: action.appData.appInsightsInstrumentationKey,
                defaultShareableAnonymousSubmissionOptions: CookieSROtoPortalSRO(
                    action.appData.defaultAnonymousSubmissionOptions,
                    endOfImportAnonymous.month,
                    endOfImportAnonymous.year,
                    endOfImportAnonymous.day,
                    true
                ),
                defaultPortalSubmissionOptions: CookieSROtoPortalSRO(
                    action.appData.defaultPortalSubmissionOptions,
                    endOfImportPortal.month,
                    endOfImportPortal.year,
                    endOfImportPortal.day,
                    true,
                ),
                allowMultipleBorrowerImports: action.appData.allowMultipleBorrowerImports,
                noTimeRestrictionForDataCollection: action.appData.noTimeRestrictionForDataCollection,
                showBorrowerIntroBanner: action.appData.showBorrowerIntroBanner,
                alwaysCollectBorrowerBusinessInfo: action.appData.alwaysCollectBorrowerBusinessInfo,
                disabledDataCollectionOptions: {
                    disableAdditionalDocUpload: action.appData.disableAdditionalDocUpload,
                    disableAnonymousDocUpload: action.appData.disableAnonymousDocUpload,
                    disableProvideUserCopy: action.appData.disableProvideUserCopy,
                    disableAnonymizeResults: action.appData.disableAnonymizeResults,
                    disableBasisOfAccounting: action.appData.disableBasisOfAccounting,
                },
                allowExcelFileUpload: action.appData.allowExcelFileUpload,
                disableNewWorkspaces: action.appData.disableNewWorkspaces,
                ...overriddenState,
            }

            // don't need to check this above as the member spread will just work since overridden settings
            // is empty if it's not in use.
            if (!!overriddenState) {
                if (!!overriddenState.defaultShareableAnonymousSubmissionOptions) {
                    endOfImportAnonymous = GetEndOfImport(overriddenState.defaultShareableAnonymousSubmissionOptions.endOfImport);
                    newState.defaultShareableAnonymousSubmissionOptions = {
                        ...overriddenState.defaultShareableAnonymousSubmissionOptions
                    }
                    newState.defaultShareableAnonymousSubmissionOptions.mostRecentPeriodYear = endOfImportAnonymous.year;
                    newState.defaultShareableAnonymousSubmissionOptions.mostRecentPeriodMonth = endOfImportAnonymous.month;
                    newState.defaultShareableAnonymousSubmissionOptions.mostRecentPeriodDay = endOfImportAnonymous.day;
                }
                if (!!overriddenState.defaultPortalSubmissionOptions) {
                    endOfImportPortal = GetEndOfImport(overriddenState.defaultPortalSubmissionOptions.endOfImport);
                    newState.defaultPortalSubmissionOptions = {
                        ...overriddenState.defaultPortalSubmissionOptions
                    }
                    newState.defaultPortalSubmissionOptions.mostRecentPeriodYear = endOfImportPortal.year;
                    newState.defaultPortalSubmissionOptions.mostRecentPeriodMonth = endOfImportPortal.month;
                    newState.defaultPortalSubmissionOptions.mostRecentPeriodDay = endOfImportPortal.day;
                }
            }

            break;
        }
        case AppSettingsActions.SetShowDeletedSubmissions: {
            newState = {
                ...(state ? state : defaultState),
                showDeletedSubmissions: action.show,
            }

            break;
        }
        case AppSettingsActions.OverrideSettings: {
            newState = {
                ...(state ? state : defaultState),
                ...action.overrideState,
            }

            if (newState.dynamicTenantSelection) {
                // Shouldn't actually get here if this isn't true.
                window.localStorage.setItem(StgKeyOverrideAppSettings, JSON.stringify(newState, mapReplacer));
            }

            break;
        }
        case AppSettingsActions.SetAccountingPackage: {
            newState = {
                ...(state ? state : defaultState),
            }

            if (newState.dynamicTenantSelection) {
                // Stopgap, we shouldn't be on this operation if dynamicTenantSelection isn't true.

                if (action.on) {
                    newState.accountingPackages = newState.accountingPackages.slice();
                    if (!newState.accountingPackages.find(pkg => pkg.toLowerCase() === action.package.toLowerCase())) {
                        newState.accountingPackages.push(action.package);
                    }
                } else {
                    newState.accountingPackages = newState.accountingPackages.filter(pkg => pkg.toLowerCase() !== action.package.toLowerCase())
                }

                window.localStorage.setItem(StgKeyOverrideAppSettings, JSON.stringify(newState, mapReplacer));
            }

            break;
        }
        case AppSettingsActions.SetAccountingPackageBlacklist: {
            newState = {
                ...(state ? state : defaultState),
            }

            if (newState.dynamicTenantSelection) {
                // Stopgap, we shouldn't be on this operation if dynamicTenantSelection isn't true.

                if (action.include) {
                    newState.accountingPkgBlackList = newState.accountingPkgBlackList.slice();
                    if (!newState.accountingPkgBlackList.find(pkg => pkg.toLowerCase() === action.blacklist.toLowerCase())) {
                        newState.accountingPkgBlackList.push(action.blacklist);
                    }
                } else {
                    newState.accountingPkgBlackList = newState.accountingPkgBlackList.filter(pkg => pkg.toLowerCase() !== action.blacklist.toLowerCase())
                }

                window.localStorage.setItem(StgKeyOverrideAppSettings, JSON.stringify(newState, mapReplacer));
            }

            break;
        }

        case AppSettingsActions.AddAccountingPackageToBlacklist: {
            newState = {
                ...(state ? state : defaultState),
            }
            if (!newState.accountingPkgBlackList.find(pkg => pkg === action.pkg)) {
                newState.accountingPkgBlackList = newState.accountingPkgBlackList.concat([action.pkg]);
            }
            break;
        }
    }

    if (newState) {
        return newState;
    } else if (state) {
        return state;
    } else {
        let defaultCopy: IAppSettingsState = {
            ...defaultState,
        };
        return defaultCopy;
    }
}