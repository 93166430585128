import * as React from 'react';

import { GroupedFiles } from '../../Utils/FileUtils';
import { formatStringMDYFullMonth, FormatDate, simpleTimeAMPM } from '../../Utils/DateUtils';

type Props = {
    groupedFiles?: GroupedFiles;
    fileCount?: number;
}

export const FileGroupDescription: React.FC<Props> = (props): React.ReactElement => {
    const {
        fileCount,
        groupedFiles
    } = props;

    const spacer = (<span style={{ display: 'inline-block', width: '40px' }} />);

    if (!(groupedFiles && !!groupedFiles.revisionNumber)) {
        return (<></>);
    }

    return (
        <h2>
            {!!groupedFiles.revisionDate && (
                <React.Fragment>
                    <b>Created on:</b>&nbsp;{FormatDate(groupedFiles.revisionDate, formatStringMDYFullMonth)}&nbsp;&nbsp;&nbsp;{FormatDate(groupedFiles.revisionDate, simpleTimeAMPM)}
                    {spacer}
                </React.Fragment>
            )}
            <b>Revision number:</b>&nbsp;{groupedFiles.revisionNumber}{spacer}
            <b>{!!fileCount ? fileCount : groupedFiles.supportingDocuments.length}</b>&nbsp;Files
        </h2>
    );
}
