import { ApplicationState } from '../index';

import { RoleResponse } from '../../Models/Api/strongbox.financialportal';

export function GetRoles(state: ApplicationState): RoleResponse[] {
    return (state && state.userRolesState && state.userRolesState.roles) || ([]);
}

export function GetRolesLoading(state: ApplicationState): boolean {
    return (!!state && !!state.userRolesState && state.userRolesState.loading);
}

export function GetDefaultRole(state: ApplicationState): RoleResponse | undefined {
    if (!(state && state.userRolesState)) {
        return undefined;
    }
    if ((state.userRolesState.loading) || (state.userRolesState.roles.length <= 0)) {
        return undefined;
    }

    const defaultRole = state.userRolesState.roles.find(role => {
        return role.tags && role.tags.find(tag => tag.toLowerCase() === 'default');
    })

    return !!defaultRole ? defaultRole : state.userRolesState.roles[0];
}