import {
    NewDataCollectionParametersActions,
    ISetOptionsAction,
    IInitializeCollectionGroupings,
} from './Actions';
import {
    PortalCreateSubmissionRequestOptions,
    PortalSubmissionRequestFromSubmissionRequest,
} from '../../Models/PortalCreateSubmissionRequestOptions';
import { ExtractUrlParameters } from '../../Utils/PathUtils';
import { FetchSubmissionRequest } from '../../Services/SubmissionRequestsService';

import { Sleep } from '../../Utils/Sleep';
import { store } from '../../index';

import { LogException, LogMessage, SeverityLevel } from '../../Utils/Logging';

import { AppCookieData } from '../../Models/CookieData';

import {
    TenantAttributedParagraphSet
} from '../Tenant';

function SetNewDataCollectionOptions(options: PortalCreateSubmissionRequestOptions, fiscalYearsToCollect: number): ISetOptionsAction {
    return {
        type: NewDataCollectionParametersActions.SetOptions,
        options,
        fiscalYearsToCollect,
    };
}

function InitializeCollectionGroupings(
    collectionGroupDescriptions: TenantAttributedParagraphSet,
    dynamicTenantSelectionEnabled: boolean
): IInitializeCollectionGroupings {
    return {
        type: NewDataCollectionParametersActions.InitializeCollectionGroupings,
        collectionGroupDescriptions,
        dynamicTenantSelectionEnabled,
    }
}

async function InitializeFromURL(location: Location, defaultSettings: AppCookieData): Promise<void> {
    let entityId: string = '';
    let submissionRequestId: number = -1;
    let optionsId: number = -1;

    ExtractUrlParameters(
        location.href,
        (key: string, value: string) => {
            const lowerKey = key.toLowerCase();

            if (lowerKey === 'eid') {
                entityId = value;
            } else if (lowerKey === 'rid') {
                const numValue = parseInt(value);
                if (!isNaN(numValue)) {
                    submissionRequestId = numValue;
                }
            } else if (lowerKey === 'optionsid') {
                const numValue = parseInt(value);
                if (!isNaN(numValue)) {
                    optionsId = numValue;
                }
            }
        }
    );
    
    // optionsId is used to 'turn stuff off' downstream. it may or may not appear in the url.
    // entityId and requestId are always available in the URL for submissions started via a shareable link.
    if (optionsId <= 0) {
        store.dispatch({
            type: NewDataCollectionParametersActions.Initialize,
            appData: defaultSettings,
        });

        return;
    }

    LogMessage(
        `Initializing portal for a shared link, entityId: ${entityId} requestId: ${submissionRequestId} optionsId: ${optionsId}`,
        SeverityLevel.Information,
        {
            entityId,
            submissionRequestId,
            optionsId,
        }
    );

    store.dispatch({
        type: NewDataCollectionParametersActions.BeginInitializeFromURL,
        url: location.href,
    });

    let retries = 5;
    let lastException = undefined;

    while (retries > 0) {
        try {
            const options = await FetchSubmissionRequest(entityId, submissionRequestId);

            if (!!lastException) {
                LogMessage(
                    `Failed effort succeeded on retry attempting to retrieve submission request options`,
                    SeverityLevel.Information,
                    {
                        entityId,
                        submissionRequestId,
                        optionsId
                    }
                );
            }

            let uiOptions: PortalCreateSubmissionRequestOptions | undefined = undefined;

            if (!!options) {
                uiOptions = PortalSubmissionRequestFromSubmissionRequest(options);
            }

            store.dispatch({
                type: NewDataCollectionParametersActions.EndInitializeFromURL,
                url: location.href,
                optionsId: optionsId,
                options: uiOptions,
            });

            return;
        } catch (exception) {
            console.error(`Exception caught attempting to retrieve submission request options entityId: ${entityId} submissionRequestId: ${submissionRequestId} optionsId: ${optionsId}`);
            console.error(exception);

            lastException = exception;
            retries--;

            LogException(
                `Exception retrieving request options from submissionRequestId, entityId: ${entityId} requestId: ${submissionRequestId} optionsId: ${optionsId}`,
                exception,
                {
                    entityId,
                    submissionRequestId,
                    optionsId,
                    retries,
                },
            );

            // Delay for 2 seconds
            await Sleep(2000);
        }
    }

    // If we get here, we've retried too many times and nothing was successful.   If
    // we had a successful fetch call, we already dispatched complete and returned.

    store.dispatch({
        type: NewDataCollectionParametersActions.EndInitializeFromURL,
        url: location.href,
        optionsId: -1,
        options: undefined,
        initializationException: lastException,
    });
}

export const actionCreators = {
    SetNewDataCollectionOptions: SetNewDataCollectionOptions,
    InitializeFromURL: InitializeFromURL,
    InitializeCollectionGroupings: InitializeCollectionGroupings,
};
