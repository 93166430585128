import * as React from 'react';

import {
    Col,
    Row
} from 'reactstrap';

import '../../Main.scss';

import {
    AdministrativeNotificationSetting,
    AdministrativeTenantNotificationSettings,
    UserNotificationSettings,
    UserNotificationSetting,
    NotificationMethod,
    NotificationDefaultRecipientAction,
} from '../../../Models/Api/strongbox.financialportal';

import { fixedNotificationType } from '../../../Utils/Constants';

import { RecipientListActionSelector } from './RecipientListActionSelector';

import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';

import {
    Tooltip
} from '@mui/material';

import { RowWithDelete } from '../../RowWithDelete/RowWithDelete';
import { UndoButton } from '../../UndoButton/UndoButton';

import { TestEmail } from '../../../Utils/FormUtils';
import { UndoStack, UndoOperation } from '../../../Utils/UndoStack';

export type NotificationRecipientUndo = {
    recipient: string;
    preopState: AdministrativeNotificationSetting;
}

export type AddingNotificationAdminContent = {
    content: string;
    containerId: number;
}

export type NotificationUndoStack = {
    notificationId: number;
    notificationMethod: NotificationMethod;
    undoStack: UndoStack<NotificationRecipientUndo>;
}

type Props = {
    adminTenantSettings?: AdministrativeTenantNotificationSettings;
    notificationSettings?: UserNotificationSettings;
    busy: boolean;
    containerKey: string;
    style?: any;
    containerClass?: string;
    addNewItem: (containerId: number) => void;
    updateNewContent: (content: string) => void;
    addNewContent: (notificationMethod: NotificationMethod) => void;
    cancelNewContent: () => void;
    addingContent?: AddingNotificationAdminContent;
    removeContent: (recipient: string, notificationId: number, notificationMethod: NotificationMethod) => void;
    undoStacks: NotificationUndoStack[];
    undo: (notificationId: number, notificationMethod: NotificationMethod, op: UndoOperation<NotificationRecipientUndo>) => void;
    onChangeAction: (newAction: NotificationDefaultRecipientAction, settingId: number, notificationMethod: NotificationMethod) => void;
}

export const NotificationAdminSettings: React.FC<Props> = (props): React.ReactElement => {
    const {
        addNewContent,
        addingContent,
        addNewItem,
        adminTenantSettings,
        cancelNewContent,
        notificationSettings,
        containerKey,
        style,
        updateNewContent,
        removeContent,
        undoStacks,
        undo,
        onChangeAction,
    } = props;

    const enterEmailInputId = 'enter-new-email-address-input';

    // id of the element to set focus to.
    const [setFocusTo, setSetFocusTo] = React.useState<string | undefined>(undefined);
    const [setFocus, setSetFocus] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (!!setFocusTo) {
            const focusElement = document.getElementById(setFocusTo);
            if (focusElement !== null) {
                focusElement.focus();
            }
            setSetFocusTo(undefined);
        }
    }, [setFocusTo]);

    const addNewItemField = React.useMemo(() => {
        // addingContent can be an empty string.  Check specifically against undefined because
        // simple '!addingContent' will be true if the string is empty
        if (addingContent === undefined) {
            return undefined;
        }

        const emailValid = TestEmail(addingContent.content);

        if (setFocus) {
            setSetFocusTo(enterEmailInputId);
            setSetFocus(false);
        }

        return (
            <div
                className={'email-input-container'}
                key={'adding-new-notification-recipient-row'}
            >
                <input
                    id={enterEmailInputId}
                    type={'email'}
                    className={'new-email-input'}
                    value={addingContent.content}
                    onChange={(e) => updateNewContent(e.currentTarget.value)}
                    onKeyPress={(e) => {
                        if ((e.key === 'Enter') && (emailValid)) {
                            addNewContent(fixedNotificationType);
                            e.preventDefault();
                        }
                    }}
                    onKeyDown={(e) => {
                        if ((e.key === 'Esc') || (e.key === 'Escape')) {
                            cancelNewContent();
                            e.preventDefault();
                        }
                    }}
                />
                <div>
                    <button
                        disabled={!emailValid}
                        onClick={() => addNewContent(fixedNotificationType)}
                    >
                        <CheckIcon />
                    </button>
                    <button
                        onClick={() => cancelNewContent()}
                    >
                        <CancelIcon />
                    </button>
                </div>
            </div>
        );
        // addingContent is the only dependency I want for this hook
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [addingContent])

    if (!(adminTenantSettings && adminTenantSettings.settings && notificationSettings && notificationSettings.settings)) {
        return (<React.Fragment />)
    }

    const handleAddNewItem = (id?: number): void => {
        addNewItem(id !== undefined ? id : 0);
        setSetFocus(true);
    }

    const handleDeleteItem = (n: UserNotificationSetting, emailAddress: string): void => {
        if (n.id === undefined) {
            // sanity check, shouldn't be possible
            return;
        }
        removeContent(emailAddress, n.id, fixedNotificationType);
    }

    const notificationListActions = (notificationId: number, listTypeForKey: string): React.ReactElement => {
        const undoStack = undoStacks.find(s => (s.notificationId === notificationId) && (s.notificationMethod === fixedNotificationType));

        return (
            <div
                className={'action-buttons'}
                key={`notification-list-actions-${notificationId}-${listTypeForKey}`}
            >
                <Tooltip title={addingContent === undefined ? 'Add a recipient by email address' : ''}>
                    <span>
                        <button
                            onClick={(e) => handleAddNewItem(notificationId)}
                            disabled={!!addingContent}
                        >
                            <AddIcon />
                        </button>
                    </span>
                </Tooltip>
                <UndoButton<NotificationRecipientUndo>
                    undoStack={undoStack?.undoStack || new UndoStack<NotificationRecipientUndo>()}
                    showWhenEmpty={true}
                    style={{ marginLeft: '15px' }}
                    undo={(op) => {
                        undo(notificationId, fixedNotificationType, op);
                    }}
                />
            </div>
        );
    }

    const getTenantSettingsForNotification = (n: UserNotificationSetting): AdministrativeNotificationSetting | undefined => {
        if (!n.notificationMethods) {
            return undefined;
        }
        const email = n.notificationMethods.find(m => m.type === fixedNotificationType);
        if (!email) {
            // Really shouldn't happen, pretty much a sanity check
            return undefined;
        }
        return adminTenantSettings!.settings!.find(ts => ts.id === n.id);
    }

    const notificationListIsEmpty = (ts: AdministrativeNotificationSetting): boolean => {
        return !ts.defaultRecipients || ts.defaultRecipients.length === 0;
    }

    const notificationList = (ts: AdministrativeNotificationSetting, n: UserNotificationSetting, listTypeForKey: string): React.ReactElement => {
        if (notificationListIsEmpty(ts)) {
            return (
                <div
                    className={'notification-container'}
                    key={`notification-container-${n.id}-${listTypeForKey}`}
                >
                    {
                        !!addingContent && addingContent.containerId === n.id && addNewItemField
                    }
                    {
                        (!addingContent || (addingContent.containerId !== n.id)) && (
                            <span>No default recipients for this notification</span>
                        )
                    }
                    {
                        (n.id !== undefined) && notificationListActions(n.id, listTypeForKey)
                    }
                </div>
            );
        }

        return (
            <div
                className={'notification-container'}
                key={`notification-container-${n.id}-${listTypeForKey}`}
            >
                <div
                    className={'recipient-list'}
                    key={`notification-recipient-list-${n.id}-${listTypeForKey}`}
                >
                    {
                        !!addingContent && addingContent.containerId === n.id && addNewItemField
                    }
                    {ts?.defaultRecipients && ts.defaultRecipients.map((r, ri) => {
                        return (
                            <React.Fragment
                                key={`default-notification-recipients-${n.id}-${listTypeForKey}-${ri}`}
                            >
                                <RowWithDelete
                                    content={(
                                        <span>{r}</span>
                                    )}
                                    onDelete={() => handleDeleteItem(n, r)}
                                    hideDelete={!!addingContent}
                                />
                            </React.Fragment>
                        )
                    })}
                </div>
                {
                    (n.id !== undefined) && notificationListActions(n.id, listTypeForKey)
                }
            </div>
        );
    }

    const mainRowStyle = {
        ...style,
        paddingBottom: '20px'
    }

    return (
        <React.Fragment>
            <Row
                key={containerKey}
                className={'bordered-region user-settings-container'}
            >
                <Col>
                    <Row key={'new-workspaces-preferences'}>
                        <Col>
                            <h1>Administrative Notification Settings</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <span>Provide contact email addresses that will receive this notification for all workspaces in addition to or in lieu of users who have access to the workspace</span>
                        </Col>
                    </Row>

                    <Row
                        key={`${containerKey}-setting-row`}
                        style={mainRowStyle}
                        className={`settings-group`}
                    >
                        {notificationSettings.settings.map((n, i) => {
                            const ts = getTenantSettingsForNotification(n);

                            return (
                                <Col
                                    xs={12} lg={6}
                                    className={'settings-group-container'}
                                >
                                    <div>
                                        <Row>
                                            <Col
                                                xs={'12'} lg={'5'}
                                                style={{ marginBottom: '20px' }}
                                                className={'action-group'}
                                            >
                                                <h1>{n.title}</h1>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {!!ts ? notificationList(ts, n, n.id?.toString() || '') : (<React.Fragment />)}
                                            </Col>
                                        </Row>
                                        {!!ts && !!ts.defaultAction && (n.id !== undefined) && (!notificationListIsEmpty(ts)) && (
                                            <Row>
                                                <Col>
                                                    <RecipientListActionSelector
                                                        idPrefix={`${containerKey}-recipient-selector`}
                                                        action={ts.defaultAction}
                                                        onChange={(newAction) => onChangeAction(newAction, n.id!, fixedNotificationType)}
                                                    />
                                                </Col>
                                            </Row>
                                        )}
                                    </div>
                                </Col>
                            )
                        })}
                    </Row>
                </Col>
            </Row>
        </React.Fragment>
    )
}
