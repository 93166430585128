import * as React from 'react';

import {
    Button,
} from 'reactstrap';

import ReactDatePicker from 'react-datepicker';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import '../Main.scss';

import ArrowForward from '@mui/icons-material/ArrowForward';
import ArrowBackward from '@mui/icons-material/ArrowBack';

import { formatStringFullMonth, FormatDate } from '../../Utils/DateUtils';

type Props = {
    keyPrefix?: string;
    selectedDate: Date;
    minDate?: Date;
    maxDate?: Date;
    maxLookBackMonths: number;
    onSetDate: (d: Date) => void;
};

type MonthForList = {
    value: number;
    display: string;
    enabled: boolean;
}

export const FinancialsImportDatePicker: React.FC<Props> = (props): React.ReactElement => {
    const {
        keyPrefix,
        minDate,
        maxDate,
        selectedDate,
        onSetDate,
    } = props;

    const [datePickerTransparentSelection, setDatePickerTransparentSelection] = React.useState<boolean>(false);

    const selectedMonthValue = React.useMemo(() => {
        return selectedDate.getMonth();
    }, [selectedDate]);

    const monthsForPicker: MonthForList[] = React.useMemo(() => {
        const d = new Date(selectedDate);
        let belowMin = false;
        let aboveMax = false;
        const year = d.getFullYear();
        const result: MonthForList[] = [];

        // This is actually the right computation.  We're getting a full complement of 12 months,
        // that will include months that are below the min date, there's an assumption that
        // selectedDate is not below minDate. So if the year from the selected date is less than or
        // equal to the year from the min date then set belowMin to true.  In the loop below, we
        // will set belowMin to false as soon as we process (and prior to determining if it's disabled)
        // a date that is above the month of the min date
        if ((!!minDate) && (year <= minDate.getFullYear())) {
            belowMin = true;
        }

        // Need this based on the way setMonth works. d is created from selected date
        // which is likely to be the end of the month. If you call setMonth (as we do below)
        // and the current day of the month is beyond the end of the new month it bumps you 
        // into the next month, e.g. calling setMonth to 1 (February, it's 0 based) if the date is 
        // 1/31 you get 3/2 (or 3 or whatever)
        d.setDate(1);

        // values for setMonth are 0 based, 0 = January.
        for (let month = 0; month < 12; month++) {
            d.setMonth(month);
            if ((belowMin) && (!!minDate)) {
                if (month >= minDate.getMonth()) {
                    belowMin = false;
                }
            }
            if (!!maxDate && (year >= maxDate.getFullYear()) && (month > maxDate.getMonth())) {
                aboveMax = true;
            }

            const monthString = FormatDate(d, formatStringFullMonth);
            result.push({
                display: monthString,
                value: month,
                enabled: !belowMin && !aboveMax,
            });
        }

        return result;
    }, [minDate, maxDate, selectedDate]);

    const selectedYearValue = React.useMemo(() => {
        return selectedDate.getFullYear();
    }, [selectedDate]);

    const yearsForPicker: number[] = React.useMemo(() => {
        const d = new Date();
        const result: number[] = [];
        const minYear = !!minDate ? minDate.getFullYear() : 2000;

        if (!maxDate) {
            d.setFullYear(d.getFullYear() + 5);
        }

        for (let year = minYear; year <= d.getFullYear(); year++) {
            result.push(year);
        }
        return result;
    }, [minDate, maxDate]);

    const setCurrentDate = (month: number, day: number, year: number): void => {
        let newDate = new Date(year, month, day);
        if (!!minDate && (newDate < minDate)) {
            newDate = new Date(minDate);
        }
        if (!!maxDate && (newDate > maxDate)) {
            newDate = new Date(maxDate);
        }
        onSetDate && onSetDate(newDate);
    }

    const renderHeader = (params: ReactDatePickerCustomHeaderProps): React.ReactNode => {
        return (
            <div
                className={'import-financials-date-picker-custom-header'}
            >
                <Button
                    color={'primary'}
                    onClick={(e) => {
                        params.decreaseMonth();
                    }}
                    disabled={(!!minDate && (selectedDate.getMonth() <= minDate.getMonth()) && (selectedDate.getFullYear() <= minDate.getFullYear()))}
                >
                    <ArrowBackward />
                </Button>
                <select
                    className={'left-spacing'}
                    value={selectedMonthValue}
                    onChange={(v) => {
                        const monthVal = parseInt(v.currentTarget.value);
                        if (isNaN(monthVal)) {
                            // Shouldn't happen
                            return;
                        }

                        // for some reason params.changeMonth doesn't fire a change.  
                        //
                        // Set the date to month + 1, day 0.  This will result in the date
                        // being the last day of month.
                        setCurrentDate(monthVal + 1, 0, selectedDate.getFullYear());
                    }}
                >
                    {
                        monthsForPicker.map((m, mi) => {
                            return (
                                <option
                                    value={m.enabled ? m.value.toString() : ''}
                                    key={`${keyPrefix}-financials-import-month-select-${mi}`}
                                    disabled={!m.enabled}
                                >
                                    {m.display}
                                </option>
                            )
                        })
                    }
                </select>
                <select
                    className={'left-spacing'}
                    value={selectedYearValue}
                    onChange={(v) => {
                        const yearVal = parseInt(v.currentTarget.value);
                        if (isNaN(yearVal)) {
                            // Shouldn't happen
                            return;
                        }

                        // for some reason params.changeYear doesn't fire a change.  
                        //
                        // Set the date to month + 1, day 0.  This will result in the date
                        // being the last day of month. There's a small but non-zero chance
                        // that if the user selected February during a leap year the day would
                        // be 29.  If they change year and we don't do it this way, they end
                        // in March because in a non-leap year, February 29 is March 1.
                        setCurrentDate(selectedDate.getMonth() + 1, 0, yearVal);
                    }}
                >
                    {
                        yearsForPicker.map((y, yi) => {
                            return (
                                <option
                                    value={y.toString()}
                                    key={`${keyPrefix}-financials-import-year-select-${yi}`}
                                >
                                    {y.toString()}
                                </option>
                            );
                        })
                    }
                </select>
                <Button
                    color={'primary'}
                    onClick={(e) => {
                        params.increaseMonth();
                    }}
                    className={'left-spacing'}
                    disabled={(!!maxDate && (selectedDate.getMonth() >= maxDate.getMonth()) && (selectedDate.getFullYear() >= maxDate.getFullYear()))}
                >
                    <ArrowForward />
                </Button>
            </div>
        )
    }

    return (
        <div className={`date-picker-container ${datePickerTransparentSelection ? 'date-picker-invisible-selection' : ''}`}>
            <ReactDatePicker
                selected={selectedDate}
                open={true}
                renderCustomHeader={renderHeader}
                onChange={(date: Date) => {
                    setDatePickerTransparentSelection(false);
                    setCurrentDate(date.getMonth(), date.getDate(), date.getFullYear());
                }}
                onCalendarClose={() => {
                    setDatePickerTransparentSelection(false);
                }}
                onCalendarOpen={() => {
                    setDatePickerTransparentSelection(false);
                }}
                onMonthChange={(date) => {
                    onSetDate && onSetDate(date);
                }}
                maxDate={maxDate}
                minDate={minDate}
            />
        </div>

    );
}
