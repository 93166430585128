import { AccountingPackageFromDatasourceNameId, AccountingPackage } from '../../Models/Api/AccountingPackages';
import { PortalCreateSubmissionRequestOptions } from '../../Models/PortalCreateSubmissionRequestOptions';

import { CreateSubmissionRequest } from '../../Services/SubmissionRequestsService';
import { CreateSubmission } from '../../Services/SubmissionService';
import { ListWorkspaceConnections } from '../../Services/WorkspaceConnectionsService';

import { ErrorState } from '../ErrorBanner/ErrorBanner';
import { EventCategory, MetricsService } from '../../Utils/Metrics';
import { getDelegatedToken, GetFinancialsImportOptions } from '../../Utils/LinkUtils';

import { LogException, LogMessage, SeverityLevel } from '../../Utils/Logging';

import {
    LenderConnectionOptions,
    StrongboxConnectionDescriptor
} from '@finagraph/strongbox-finconnect-react';

export async function CreateSubmissionForWorkspaceId(
    workspaceId: string,
    options: PortalCreateSubmissionRequestOptions,
    onSetError: (errorState: ErrorState) => void
): Promise<string> {
    try {
        LogMessage(
            `Creating a submission for workspace ${workspaceId}`,
            SeverityLevel.Information,
            {
                workspaceId,
                ...options,
            }
        );

        const subRequest = await CreateSubmissionRequest(
            workspaceId,
            options
        );

        if (!!subRequest) {
            LogMessage(
                `Created submission request for workspace ${workspaceId}, submission request id ${subRequest.id}`,
                SeverityLevel.Information,
                {
                    workspaceId
                }
            );
        }

        return await CreateSubmission(workspaceId, subRequest && subRequest.id);
    } catch (reason) {
        LogException(
            `Failed creating submission for ${workspaceId}`,
            reason,
            {
                workspaceId,
                ...options
            }
        );

        console.error('Failed creating submission for the workspace');
        console.error(reason);

        onSetError({
            summaryMessage: 'An error occurred linking customer financials.',
            extraInformation: (!!reason && !!reason.message) ? reason.message :
                'The existing connection may have expired or the owner\'s credentials may have changed.',
            severity: "Error"
        });

        throw reason;
    }
}

// Returns true if an asynchronous process to retrieve the delegated token was begun.
// 
// This means that we should go into the 'isWorking' state.  The processor for the 
// completion handler of the asynchronous event will clear the isWorkingState.

export function RetrieveWorkspaceConnection(
    accountingPackage: AccountingPackage,
    workspaceId: string,
    options: PortalCreateSubmissionRequestOptions,
    submissionId: string,
    strongboxUrl: string,
    onSetError: (errorState: ErrorState) => void,
    onSetWorking: (working: boolean) => void,
    onSetConnectionInfo: (ci: StrongboxConnectionDescriptor | undefined) => void
): void {
    const errorMsg = 'A fatal error has occurred attempting to link financials.  Please try again later.';

    getDelegatedToken(workspaceId)
        .then(token => {
            ListWorkspaceConnections(workspaceId)
                .then(connections => {
                    const connection = connections.connections.find(cxn => {
                        return AccountingPackageFromDatasourceNameId(cxn.datasourceNameId) === accountingPackage;
                    });

                    if (!connection) {
                        onSetError({
                            summaryMessage: 'The connection for this company has expired. Unable to import financials at this time.',
                            extraInformation: undefined,
                        });
                        onSetWorking && onSetWorking(false);
                        onSetConnectionInfo(undefined);
                    } else {
                        let lenderOptions: LenderConnectionOptions | undefined = undefined;

                        if (options) {
                            lenderOptions = GetFinancialsImportOptions(options);
                        }

                        const connectionInfo: StrongboxConnectionDescriptor = {
                            accountingPackage,
                            delegatedAccessToken: token,
                            strongboxUri: strongboxUrl,
                            orgId: workspaceId,
                            existingConnectionId: connection.id,
                            submissionId,
                            initiator: 'portal',
                            lenderManagedOptions: lenderOptions,
                        }

                        onSetWorking && onSetWorking(false);
                        onSetConnectionInfo(connectionInfo);
                    }
                })
                .catch(error => {
                    onSetWorking && onSetWorking(false);
                    onSetConnectionInfo(undefined);
                    onSetError({
                        summaryMessage: errorMsg,
                        extraInformation: error.message,
                    });
                });
        })
        .catch (error => {
            onSetWorking && onSetWorking(false);
            onSetConnectionInfo(undefined);
            onSetError({
                summaryMessage: errorMsg,
                extraInformation: error.message,
            });
        });

    onSetWorking && onSetWorking(true);
}

export function OnlineLinkFunc(
    pkg: AccountingPackage,
    id: string,
    options: PortalCreateSubmissionRequestOptions,
    submissionId: string,
    strongboxUrl: string,
    onSetError: (errorState: ErrorState) => void,
    onSetImporting: (working: boolean) => void,
    onSetConnectionInfo: (ci: StrongboxConnectionDescriptor | undefined) => void,
): void {
    MetricsService.tryTrackEvent(EventCategory.Import, 'BeginWorkspaceList', { package: pkg });

    RetrieveWorkspaceConnection(
        pkg,
        id,
        options,
        submissionId,
        strongboxUrl,
        onSetError,
        onSetImporting,
        onSetConnectionInfo,
    );

    onSetConnectionInfo(undefined);
}

