import * as React from 'react';
import { connect } from 'react-redux';

import { PortalHeader } from './PortalHeader';
import ReactInlineSvg from 'react-inlinesvg';

import { ApplicationState } from '../Store';

import StrongboxLogo from '../images/StrongboxLogo.svg';

type InjectedReduxState = {
};

type InjectedActionCreators = {
};

type PageNotFoundProps = {
};

type Props = PageNotFoundProps & InjectedActionCreators & InjectedReduxState;

const PageNotFoundComponent: React.FC<Props> = (props): React.ReactElement => {
    const containingDivClass = `full-screen-region borrower-region content-region control-region control-region-lender`;

    return (
        <div>
            <PortalHeader hideMenu={true} />
            <div className={containingDivClass} >
                <div>
                    <ReactInlineSvg src={StrongboxLogo} />
                    <h2 style={{ marginTop: '50px' }}>We're sorry, the page you're trying to reach cannot be found.</h2>
                </div>
            </div>
        </div>
    );
}

export const PageNotFound = connect<InjectedReduxState, InjectedActionCreators, PageNotFoundProps, ApplicationState>(
    (appState: ApplicationState) => {
        const result = {
        };

        return result;
    }
)(PageNotFoundComponent);
