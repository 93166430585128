import * as React from 'react';

import {
    Row,
    Col,
    Container
} from 'reactstrap';

import { ErrorState } from '../ErrorBanner/ErrorBanner';

type Props = {
    borrowerEmail: string;
    borrowerEmailChanged: (event: React.ChangeEvent<HTMLInputElement>) => void;
    disabled: boolean;
    submitBorrowerEmail: () => void;
    errorMsg?: ErrorState;
};

export const ReceiveEmailedDocumentWidget: React.FC<Props> = (props): React.ReactElement => {
    const {
        borrowerEmail,
        borrowerEmailChanged,
        disabled,
        errorMsg,
        submitBorrowerEmail
    } = props;

    return (
        <Container fluid>
            {!!errorMsg && (
                <Row>
                    <Col>
                        <p className={'error-text'}>{errorMsg.summaryMessage}</p>
                        <p className={'error-text'}>{errorMsg.extraInformation}</p>
                    </Col>
                </Row>
            )}
            <Row>
                <Col xs={12} style={{ marginTop: '30px' }} className={'outlined-column-content centered-col'}>
                    <Row>
                        <Col>
                            <label
                                htmlFor={'borrowerEmail'}
                                className={'large'}
                            >
                                To receive a copy of the data submitted, enter your email:
                            </label>
                        </Col>
                    </Row>
                    <Row className={'basic-height-separator'}>
                        <Col>
                            <input
                                type={'email'}
                                id={'borrowerEmail'}
                                name={'borrowerEmail'}
                                placeholder={'Email address'}
                                className={'large regular-input'}
                                value={borrowerEmail}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    borrowerEmailChanged(event);
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className={'basic-height-separator'}>
                        <Col>
                            <button
                                type="button"
                                className={`small regular-button`}
                                disabled={disabled}
                                onClick={() => {
                                    submitBorrowerEmail();
                                }}
                            >
                                Confirm and send
                            </button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

