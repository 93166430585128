import * as React from 'react';

import {
    Container, Row, Col
} from 'reactstrap';

import {
    TenantLoginDestination,
} from '../../Store/Tenant';

import LoginButton from './LoginButton';

type Props = {
    loginDestinations: TenantLoginDestination[];
    thisPortal?: TenantLoginDestination
    includeThisPortal: boolean;
    executeLogin: (url: string) => void;
}

export const MultiTenantLoginButtonContainer: React.FC<Props> = (props): React.ReactElement => {
    const {
        executeLogin,
        includeThisPortal,
        loginDestinations,
        thisPortal,
    } = props;

    const elementContainer = React.useMemo(() => {
        const topLevelControls: React.ReactElement[] = [];

        loginDestinations.forEach((login, index) => {
            if ((includeThisPortal) || (!!thisPortal && thisPortal.id !== login.id)) {
                const buttonKey = `tenant-login-button-${index}`;

                topLevelControls.push(
                    <LoginButton
                        key={buttonKey}
                        buttonText={login.buttonText || login.name}
                        onClick={() => executeLogin(login.url)}
                        containerClass={'team-selection-login-button'}
                    />
                )
            }
        });

        return (
            <Container fluid>
                <Row
                    className={'login-team-selection-container'}
                >
                    {topLevelControls.map(control => (<Col className={'login-content-column'}>{control}</Col>))}
                </Row>
            </Container>
        )

        // These are the only dependencies I actually want.
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [loginDestinations, thisPortal, includeThisPortal]);

    return elementContainer;
}
