export type BorrowerBackgroundDirectory = {
    backgroundConfigFiles: string[];
}

export type BorrowerBackgroundSettings = {
    backgroundImageUrl: string;
    xOffsetInPix: string;
    xOffset: number;
    imageWidth: number;
    imageHeight: number;
}

export const defaultBorrowerBackground: BorrowerBackgroundSettings = {
    backgroundImageUrl: 'https://portal.strongbox.link/backgroundassets/DefaultBackground_2.png',
    xOffsetInPix: '-300px',
    xOffset: -300,
    imageWidth: 2500,
    imageHeight: 1406,
}
