import * as React from 'react';

import {
    Input
} from 'reactstrap';

import { SubmissionFilter } from '../../Store/SubmissionHistory';

type Props = {
    filters: SubmissionFilter[];
    title?: string;
    style?: any;
    onFilterStateChange?: (id: string, newState: boolean) => void;
}

export const SubmissionFilterSet: React.FC<Props> = (props): React.ReactElement => {
    const {
        filters,
        style,
        title,
        onFilterStateChange
    } = props;

    return (
        <div style={style}>
            {
                title && (<h4><u>{title}</u></h4>)
            }
            {
                filters.map((filter) => {
                    return (
                        <div key={`filterset-${filter.id}`}>
                            <Input
                                type={'checkbox'}
                                checked={filter.on}
                                style={{ marginRight: '10px' }}
                                id={filter.id}
                                key={filter.id}
                                onChange={e => {
                                    if (onFilterStateChange) {
                                        onFilterStateChange(filter.id, e.currentTarget.checked);
                                    }
                                }}
                            />
                            <label htmlFor={filter.id}>{filter.prompt}</label>
                        </div>
                    )
                })
            }
        </div>
    );
}
