import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { UIRedirectInfo } from '../../Store/UIState';

type Props = {
    redirectInfo?: UIRedirectInfo;
    autoNavToLogin?: boolean;
    style?: React.CSSProperties;
    key?: string;
    buttonText?: string;
    onClick?: () => void;
    containerClass?: string;
}

const LoginButton: React.FC<Props> = (props): React.ReactElement => {
    const { loginWithRedirect } = useAuth0();

    const goLogin = (): void => {
        if (!!props.redirectInfo) {
            let redirectPath = props.redirectInfo.path;

            if (!!props.redirectInfo.searchParams) {
                props.redirectInfo.searchParams.searchParams.forEach((param, index) => {
                    if (index === 0) {
                        redirectPath += '?';
                    } else {
                        redirectPath += '&'
                    }
                    redirectPath += `${param.key}=${param.value}`;
                });
            }

            window.localStorage.setItem(props.redirectInfo.nonce, redirectPath);
        }
        loginWithRedirect({
            appState: props.redirectInfo,
        });
    }

    if (props.autoNavToLogin) {
        goLogin();
        return (<></>);
    }

    return (
        <button
            style={props.style}
            className={`medium ${props.containerClass}`}
            onClick={() => { !!props.onClick ? props.onClick() : goLogin() }}
        >
            {props.buttonText || 'Log In'}
        </button>
    )
};

export default LoginButton;
